import styled from 'styled-components';
import { TextField, Grid,  Box } from "@mui/material";
import { palette } from "../palette";


export const Container = styled.div`
  display: flex;
`;

export const Sidebar = styled.div`
  width: 385px;
  height: 100vh;
  background-color: #f1f1f1;
  padding: 20px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  h4 {
    color: #555454;
    margin-left: 10px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  border-top: 1px solid #ccc;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  padding: 20px;
  margin-top: -30px;
`;

export const EmptyStateBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  color: #888;
`;