import {
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { PageContainer } from "../../../styles/Common/CommonStyles";
import {
  DescriptionClass,
  HeadingClass,
  Step3ContainerStyle,
} from "../../../styles/Courses/CourseBuilderStyles";
import CourseSettingTabs from "./CourseSettingTabs";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import CommonButton from "../../Utilities/CommonButton";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { courseAppearceSetting } from '../../../features/courses/CourseUtilitySlice';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const CoursePlayerAppearenceSetting = () => {
  const [theme, setTheme] = useState("Default");
  const [primaryColor, setPrimaryColor] = useState("Blue");
  const [font, setFont] = useState("arial");
  const navigate = useNavigate();
  const { courseId } = useParams();
  console.log(courseId)
 const dispatch = useDispatch(); 
  const handleSaveAppearnceSetting = async () => {
    console.log(courseId)
    try {
      const data = {
        courseId:courseId , 
        theme,
        primaryColor,
        font,
      };
      console.log(data , "handleSaveAppearnceSetting");
      const result = dispatch(courseAppearceSetting(data)) 
      
      console.log("Course appearance settings saved successfully" , result);
      alert("Course appearance settings saved successfully")
      navigate("/CourseBuilder");
    } catch (error) {
      console.error("Error saving course appearance settings", error);
    }
  };

  const handleThemeChange = (event) => {
    setTheme(event.target.value);
  };

  const handleColorChange = (event) => {
    setPrimaryColor(event.target.value);
  };

  const handleFontChange = (event) => {
    setFont(event.target.value);
  };

  return (
    <PageContainer>
      <CourseSettingTabs />
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '90%', margin: 'auto', marginTop:"45px" }}>
        <Tooltip title="Go Back">
          <IconButton
            size="small"
            onClick={() => navigate("/CourseBuilder")}
          >
            <ArrowBackIosIcon />
          </IconButton>
        </Tooltip>
        <div>
          <CommonButton label="Save setting"  onClick={handleSaveAppearnceSetting} />
        </div>
      </div>
      <Container fluid>
        <Container sx={Step3ContainerStyle}>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={8}>
              <Typography sx={HeadingClass}>Theme </Typography>
              <Typography sx={DescriptionClass}>
                Change the look and feel of the Course Player to suit your
                content
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <FormControl>
                <RadioGroup row value={theme} onChange={handleThemeChange}>
                  <FormControlLabel
                    value="Default"
                    control={<Radio />}
                    label="Default"
                  />
                  <FormControlLabel
                    value="Dark"
                    control={<Radio />}
                    label="Dark"
                  />
                  <FormControlLabel
                    value="Light"
                    control={<Radio />}
                    label="Light"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={8}>
              <Typography sx={HeadingClass}>Primary Color </Typography>
              <Typography sx={DescriptionClass}>
                Set the default color to your Course Player and Community
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <FormControl>
                <Select
                  style={{ width: "200px" }}
                  value={primaryColor}
                  onChange={handleColorChange}
                >
                  <MenuItem value="Blue">Blue</MenuItem>
                  <MenuItem value="Red">Red</MenuItem>
                  <MenuItem value="Green">Green</MenuItem>
                  <MenuItem value="Orange">Orange</MenuItem>
                  <MenuItem value="Yellow">Yellow</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={8}>
              <Typography sx={HeadingClass}>Font </Typography>
              <Typography sx={DescriptionClass}>
                Set the default font for your Course Player and Community
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <FormControl>
                <Select
                  style={{ width: "200px" }}
                  value={font}
                  onChange={handleFontChange}
                >
                  <MenuItem value="arial">Arial</MenuItem>
                  <MenuItem value="sanssarif">Sans Sarif</MenuItem>
                  <MenuItem value="droidsarif">Droid Sarif</MenuItem>
                  <MenuItem value="monospaced">Monospaced</MenuItem>
                  <MenuItem value="garamond">Garamond</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </PageContainer>
  );
};

export default CoursePlayerAppearenceSetting;
