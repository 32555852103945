import React, { useState, useEffect } from 'react';
import { Navigate, NavLink } from 'react-router-dom';
import { PageContainer } from '../../styles/Dashboard/DashboardStyles';
import {
  Card,
  CardContent,
  Grid,
  Link,
  LinearProgress,
  Typography,
  Box,
  DialogTitle,
  Tooltip,
  DialogContent,
  Dialog,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { Calendar, DateObject } from 'react-multi-date-picker';
import moment from 'moment';
import axios from 'axios';
import UpcomingEventCard from './UpcomingEventCard';
const URL = process.env.REACT_APP_URL;
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { palette } from '../../styles/palette';
import {
  AddDiv,
  DialogBoxTitle,
  CloseIconStyle,
} from '../../styles/Calendar/CalendarStyle';
import EventPopup from '../Calendar/EventPopup';

const LearnerDashBoardV1 = ({ users }) => {
  const { t } = useTranslation();
  const [allEvents, setAllEvents] = useState([]);
  const [allNotification, setAllNotification] = useState([]);
  const [displayCourseList, setDisplayCourseList] = useState([]);
  const [courses, setCourses] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const { data } = useSelector((state) => state.calendar);

  function getMyCourses(myCourses) {
    return myCourses.map((course) => {
      return (
        <Card
          style={{
            margin: '10px 50px',
            height: '120px',
            borderRadius: '20px',
            backgroundColor: '#FCEEEE',
            width: '220px',
          }}
          sx={{ width: '45%' }}
        >
          <CardContent>
            <Grid container style={{ justifyContent: 'center' }}>
              <Grid style={{ border: '0px solid red' }}>
                <Typography
                  style={{ fontWeight: 'bolder', fontStyle: 'italic' }}
                  variant="body1"
                >
                  {course.courseTitle}
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ width: '100%', mt: 5 }}>
              <LinearProgress
                variant="determinate"
                value={course.courseProgress}
                style={{ borderRadius: '2px' }}
              />
            </Box>
          </CardContent>
        </Card>
      );
    });
  }

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    return `${month}/${day}`;
  };

  const handleNavigation = () => {
    Navigate('/LearnersEventsNavigator');
  };

  function getTopEvents() {
    return allEvents.map((event) => {
      const eventDay = new Date(event.SDate)
        .toLocaleDateString('en-US', { weekday: 'short' })
        .substring(0, 3);
      const eventDate = formatDate(event.SDate);
      const eventTime = event.STime;
      const eventName = event.EventName;
      const eventDesp = event.EventDesp;
      const eventDelivery = event.Delivery;
      return (
        <UpcomingEventCard
          key={event._id}
          eventDay={eventDay}
          eventDate={eventDate}
          eventTime={eventTime}
          eventName={eventName}
          eventDesp={eventDesp}
          eventDelivery={eventDelivery}
        />
      );
    });
  }

  useEffect(() => {
    const email = users?.user?.userid?.email;
    const userCourses = axios.get(`${URL}/api/dashbaordmodule/${email}`);
    userCourses
      .then((data) => {
        const courseList = data.data.map((element) => {
          return {
            courseTitle:
              element?.generalInformation?.title || 'Course default Title',
            courseProgress: 50,
          };
        });
        setDisplayCourseList(courseList.slice(0, 2));
        setCourses(courseList);
      })
      .catch((error) => {
        console.log(error);
      });

    //all events
    const events = axios.get(`${URL}/api/calendar/fetchAllEvents`);
    events
      .then((data) => {
        const today = moment().startOf('day');
        const eventsUpcoming = data.data.filter(
          (event) => event && moment(event.SDate).isAfter(today, 'day'),
        );
        setAllEvents(eventsUpcoming);
      })
      .catch((error) => {
        console.log(error);
      });
    //all notification
    const allNotification = axios.get(
      `${URL}/api/dashbaordmodule/fetchAllNotification`,
    );
    allNotification
      .then((data) => {
        const notifications = data.data.filter(
          (notification) => notification.isDeleted === false,
        );
        setAllNotification(notifications);
      })
      .catch((error) => {
        console.log(error);
      });

    console.log(data, 'data');
  }, []);

  const highlightedDates = data
    ? data.map((ele) =>
        new DateObject(new Date(ele.SDate)).format('YYYY-MM-DD'),
      )
    : [];

  const mapDays = ({ date }) => {
    let dateString = date.format('YYYY-MM-DD'); // Convert the current date to string
    let isHighlighted = highlightedDates.includes(dateString);

    if (isHighlighted) {
      return {
        style: {
          backgroundColor: palette.buttonHover,
          color: palette.bgColor1,
        },
      };
    }
  };
  const handleDateChange = (date) => {
    setSelectedDate(date.format('YYYY-MM-DD'));
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <PageContainer>
      <Grid container display={'flex'} style={{ margin: '0px', width: '100%' }}>
        <Grid xs={8} style={{ border: '10px solid white' }}>
          <Grid xs={12}>
            <Grid xs={12} display={'flex'}>
              <Grid xs={8} style={{ paddingLeft: '10px' }}>
                <Typography
                  style={{ fontWeight: 'bolder', fontStyle: 'italic' }}
                  variant="body1"
                >
                  {t('dashboard.MyCourses')}
                </Typography>
              </Grid>
              <Grid xs={4} style={{ textAlign: 'right', paddingRight: '10px' }}>
                <Typography variant="body1">
                  <NavLink to="/Modules">{t('dashboard.viewMore')}</NavLink>
                </Typography>
              </Grid>
            </Grid>
            {/* MyCourses */}
            <Grid
              item
              display="flex"
              justifyContent="start"
              flexFlow="wrap"
              style={{
                margin: '0px',
                flexFlow: 'wrap',
                overflow: 'auto',
                height: '150px',
              }}
            >
              {getMyCourses(courses)}
            </Grid>
          </Grid>
          <Card
            style={{
              marginTop: '10px',
              backgroundColor: '#E6E6E6',
              borderRadius: '20px',
            }}
          >
            <CardContent>
              <Grid container display={'flex'}>
                <Grid xs={6}>
                  <Typography
                    style={{ fontWeight: 'bolder', fontStyle: 'italic' }}
                    variant="body1"
                  >
                    {t('dashboard.MyCalender')}
                  </Typography>
                </Grid>
                <Grid
                  xs={6}
                  style={{ textAlign: 'right', paddingRight: '10px' }}
                >
                  <Typography variant="body1">
                    <NavLink to="/LearnersEventsNavigator">
                      {t('dashboard.viewMore')}
                    </NavLink>
                  </Typography>
                </Grid>
              </Grid>
              {/* MyCalender */}
              <Grid display={'flex'} style={{ marginTop: '10px' }}>
                <Grid xs={6}>
                  <Calendar
                    format="YYYY-MM-DD"
                    className="calendar-class"
                    weekDays={['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA']}
                    onChange={handleDateChange}
                    mapDays={mapDays}
                  />
                  <Dialog open={openDialog} maxWidth="md">
                    <AddDiv>
                      <DialogBoxTitle>
                        <DialogTitle sx={{ fontWeight: '400' }}>
                          {t('calender.eventScheduledText')}
                        </DialogTitle>
                      </DialogBoxTitle>

                      <Tooltip title={t('calender.closeLabel')}>
                        <CloseIcon onClick={handleClose} sx={CloseIconStyle} />
                      </Tooltip>
                    </AddDiv>
                    <DialogContent>
                      <EventPopup date={selectedDate} />
                    </DialogContent>
                  </Dialog>
                </Grid>
                {/* MyEvent */}
                <Grid xs={6}>
                  <Card
                    style={{
                      borderRadius: '20px',
                      height: '275px',
                      overflowY: 'auto',
                    }}
                  >
                    <CardContent>
                      <div
                        style={{
                          display: 'flex',
                          gap: '5px',
                          flexDirection: 'column',
                        }}
                      >
                        {getTopEvents()}
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          xs={4}
          style={{
            border: '10px solid white',
          }}
        >
          <Grid xs={12} style={{ border: '10px solid white' }}>
            <Card
              style={{
                height: '150px',
                borderRadius: '20px',
                backgroundColor: '#DDD4D4',
              }}
            >
              <CardContent>
                <Grid container>
                  <Grid xs={6}>
                    <Typography
                      style={{ fontWeight: 'bolder', fontStyle: 'italic' }}
                      variant="body1"
                    >
                      {t('dashboard.ToDoList')}
                    </Typography>
                  </Grid>
                  <Grid
                    xs={6}
                    style={{ textAlign: 'right', paddingRight: '10px' }}
                  >
                    <Typography variant="body1">
                      <Link> {t('dashboard.viewMore')}</Link>
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <Card
              style={{
                marginTop: '20px',
                backgroundColor: '#E6E6E6',
                borderRadius: '20px',
              }}
            >
              <CardContent>
                <Grid container display={'flex'}>
                  <Grid xs={6}>
                    <Typography
                      style={{ fontWeight: 'bolder', fontStyle: 'italic' }}
                      variant="body1"
                    >
                      {t('dashboard.MyNotification')}
                    </Typography>
                  </Grid>
                  <Grid xs={6}>
                    <Typography
                      variant="body1"
                      style={{ textAlign: 'right', paddingRight: '10px' }}
                    >
                      <NavLink to="/LearnerNotification">
                        {t('dashboard.viewMore')}
                      </NavLink>
                    </Typography>
                  </Grid>
                </Grid>

                <div
                  style={{
                    maxHeight: '280px',
                    overflowY: 'auto',
                    width: '100%',
                  }}
                >
                  {allNotification.map((notification) => {
                    return (
                      <Card
                        style={{ margin: '10px', borderRadius: '15px' }}
                        onclick={() => {
                          handleNavigation;
                        }}
                      >
                        <CardContent>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: notification.notificationBody,
                            }}
                            style={{
                              // backgroundColor: "lightgray",
                              overflow: 'hidden',
                              objectFit: 'contain',
                            }}
                          ></div>
                        </CardContent>
                      </Card>
                    );
                  })}
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default LearnerDashBoardV1;
