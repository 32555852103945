import React, { useState, useEffect } from 'react';
import { TextField, MenuItem, Button, Grid, IconButton } from '@mui/material';
import CommonButton from '../../Utilities/CommonButton';
import { ButtonDiv } from '../../../styles/Certificate/CertificateStyle';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useDispatch } from 'react-redux';
import { Createtemplate } from '../../../features/courses/addCourseDetailsSlice';
import { toast } from 'react-toastify';

const CreateTemplate = () => {
  const dispatch = useDispatch();

  const [templateName, settemplateName] = useState('');
  const [templateDescription, settemplateDescription] = useState('');
  const [templateType, setTemplateType] = useState('');
  const [modelType, setModelType] = useState('cmlt'); // Default to CMLT
  const [chapters, setChapters] = useState([
    { modules: [{ lessons: [{ topics: 1 }] }] },
  ]);
  const { t } = useTranslation();

  // Handle the logic based on modelType
  useEffect(() => {
    if (modelType === 'clt') {
      // When CLT is selected, hide "Modules", but keep the structure same as CMLT
      const defaultChapter = { modules: [{ lessons: [{ topics: 1 }] }] };
      setChapters([defaultChapter]);
    }
  }, [modelType]);

  const handleTemplateSave = () => {
    if (!templateName || !templateDescription || !templateType) {
      toast.error('Please fill in all required fields');
      return;
    }

    const templateData = {
      templateName,
      templateDescription,
      templateType,
      modelType,
      chapters,
    };
    dispatch(Createtemplate(templateData));

    console.log('Saving course template:', templateData);
  };

  // Template types
  const templateTypes = [
    { value: 'inPerson', label: t('courses.inPersonClass') },
    { value: 'virtual', label: t('courses.virtualClassWithVerification') },
    { value: 'selfPaced', label: t('courses.selfPacedClass') },
    { value: 'onDemand', label: t('courses.onDemandClass') },
    { value: 'videoReplay', label: t('courses.videoReplayClass') },
    { value: 'meeting', label: t('courses.meetingTemplate') },
  ];

  // Model types for the second dropdown
  const modelTypes = [
    { value: 'cmlt', label: 'CMLT' },
    { value: 'clt', label: 'CLT' },
  ];

  // Add a new chapter
  const addChapter = () => {
    setChapters([...chapters, { modules: [{ lessons: [{ topics: 1 }] }] }]);
  };

  // Remove a chapter
  const removeChapter = (index) => {
    const updatedChapters = chapters.filter((_, i) => i !== index);
    setChapters(updatedChapters);
  };

  // Add a new module to a chapter (for CMLT)
  const addModule = (chapterIndex) => {
    if (modelType === 'cmlt') {
      const updatedChapters = [...chapters];
      updatedChapters[chapterIndex].modules.push({ lessons: [{ topics: 1 }] });
      setChapters(updatedChapters);
    }
  };

  // Remove a module from a chapter (for CMLT)
  const removeModule = (chapterIndex, moduleIndex) => {
    if (modelType === 'cmlt') {
      const updatedChapters = [...chapters];
      updatedChapters[chapterIndex].modules.splice(moduleIndex, 1);
      setChapters(updatedChapters);
    }
  };

  // Add a new lesson to a module (for both CMLT and CLT)
  const addLesson = (chapterIndex, moduleIndex) => {
    const updatedChapters = [...chapters];
    updatedChapters[chapterIndex].modules[moduleIndex].lessons.push({
      topics: 1,
    });
    setChapters(updatedChapters);
  };

  // Remove a lesson from a module (for both CMLT and CLT)
  const removeLesson = (chapterIndex, moduleIndex, lessonIndex) => {
    const updatedChapters = [...chapters];
    updatedChapters[chapterIndex].modules[moduleIndex].lessons.splice(
      lessonIndex,
      1,
    );
    setChapters(updatedChapters);
  };

  // Update number of topics for a specific lesson
  const updateTopicCount = (chapterIndex, moduleIndex, lessonIndex, value) => {
    const updatedChapters = [...chapters];
    updatedChapters[chapterIndex].modules[moduleIndex].lessons[
      lessonIndex
    ].topics = value;
    setChapters(updatedChapters);
  };

  return (
    <div style={{ width: '80%', margin: 'auto' }}>
      <h2>{t('courses.createCourseTemplate')}</h2>

      {/* Template Type and Model Type */}
      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <Grid item xs={6}>
          <TextField
            select
            label={t('courses.templateType')}
            variant="outlined"
            fullWidth
            value={templateType}
            onChange={(e) => setTemplateType(e.target.value)}
          >
            {templateTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {/* Model Type Dropdown */}
        <Grid item xs={6}>
          <TextField
            select
            label="Model Type"
            variant="outlined"
            fullWidth
            value={modelType}
            onChange={(e) => setModelType(e.target.value)}
          >
            {modelTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      {/* Course Name Field */}
      <TextField
        label={t('courses.TemplateName')}
        variant="outlined"
        fullWidth
        value={templateName}
        onChange={(e) => settemplateName(e.target.value)}
        style={{ marginBottom: '20px' }}
      />

      {/* Course Description Field */}
      <TextField
        label={t('courses.TemplateDescription')}
        variant="outlined"
        fullWidth
        multiline
        minRows={3}
        value={templateDescription}
        onChange={(e) => settemplateDescription(e.target.value)}
        style={{ marginBottom: '20px' }}
      />

      {/* Chapters, Modules (conditionally), Lessons, and Topics */}
      {chapters.map((chapter, chapterIndex) => (
        <div
          key={chapterIndex}
          style={{
            marginBottom: '20px',
            border: '1px solid #ddd',
            padding: '10px',
          }}
        >
          <h2>
            {t('courses.chapter')} {chapterIndex + 1}{' '}
            <IconButton onClick={() => removeChapter(chapterIndex)}>
              <RemoveIcon />
            </IconButton>
          </h2>

          {chapter.modules.map((module, moduleIndex) => (
            <div
              key={moduleIndex}
              style={{
                marginBottom: '20px',
                border: '1px solid #ddd',
                padding: '10px',
              }}
            >
              {modelType === 'cmlt' && (
                <h3>
                  {t('courses.module')} {moduleIndex + 1}{' '}
                  <IconButton
                    onClick={() => removeModule(chapterIndex, moduleIndex)}
                  >
                    <RemoveIcon />
                  </IconButton>
                </h3>
              )}

              {module.lessons.map((lesson, lessonIndex) => (
                <div key={lessonIndex} style={{ marginBottom: '10px' }}>
                  <h4>
                    {t('courses.lesson')} {lessonIndex + 1}{' '}
                    <IconButton
                      onClick={() =>
                        removeLesson(chapterIndex, moduleIndex, lessonIndex)
                      }
                    >
                      <RemoveIcon />
                    </IconButton>
                  </h4>

                  {/* Number of Topics for each lesson */}
                  <TextField
                    label={t('courses.topics')}
                    type="number"
                    variant="outlined"
                    fullWidth
                    value={lesson.topics}
                    onChange={(e) =>
                      updateTopicCount(
                        chapterIndex,
                        moduleIndex,
                        lessonIndex,
                        e.target.value,
                      )
                    }
                    style={{ marginBottom: '10px' }}
                  />
                </div>
              ))}

              <Button onClick={() => addLesson(chapterIndex, moduleIndex)}>
                {t('courses.addLesson')}
              </Button>
            </div>
          ))}

          {modelType === 'cmlt' && (
            <Button
              onClick={() => addModule(chapterIndex)}
              variant="contained"
              color="primary"
            >
              {t('courses.addModule')}
            </Button>
          )}
        </div>
      ))}

      <Button onClick={addChapter} variant="contained" color="primary">
        {t('courses.addChapter')}
      </Button>

      {/* Save Button */}
      {/* <ButtonDiv>
        <CommonButton onClick={handleTemplateSave}>
          {t('courses.saveTemplate')}
        </CommonButton>
      </ButtonDiv> */}
      <button onClick={handleTemplateSave}> {t('courses.saveTemplate')}</button>
    </div>
  );
};
export default CreateTemplate;
