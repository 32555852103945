import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { PageContainer } from "../../styles/Common/CommonStyles";
import {
  Content,
  Description,
  HeaderContainer,
  Heading,
} from "../../styles/Courses/CourseBuilderStyles";
import learnerCourseList from "../../store/LearnerCourses.json";
import { ExpandMore } from "@mui/icons-material";

const LearnerCourseDetails = () => {
   
  return (
    <PageContainer>
      <Container fluid style={{ marginTop: "40px" }}>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item sx={6}>
            {learnerCourseList.map((item, index) => {
              return (
                <Grid item sx={4}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      {item.modules.moduleName}
                    </AccordionSummary>
                    <AccordionDetails>
                      Hii , I am Diptesh Sunil Dhangar from Jalgaon.
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              );
            })}
          </Grid>
          <Grid item sx={6}>
            <Card className="course-card" style={{ width: "400px" }}>
              <div className="image-container">
                <Card
                  class="blurbg"
                  style={{
                    background:
                      "url('https://dz8fbjd9gwp2s.cloudfront.net/courses/65606b21e4b07d62151fe16c/65606b21e4b07d62151fe16c_scaled_cover.jpg?v=1')",
                  }}
                ></Card>
              </div>
              <Card>
                <Typography
                  variant="body1"
                  style={{
                    marginLeft: "10px",
                    marginTop: "10px",
                    // fontSize: "20px",
                  }}
                >
                  {/* {item.courseName} */}
                  Mern Stack
                </Typography>
                <Typography variant="body2" style={{ margin: "10px" }}>
                  {/* {item.courseType} */}
                  Free
                </Typography>
              </Card>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </PageContainer>
  );
};
export default LearnerCourseDetails;
