import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';

import React, { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import SidebarMenu from './SidebarMenu';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  MainContainer,
  TopSection,
  Section,
  Icon,
  ImageContainerSidebar,
  ImageOpen,
  ImageClosed,
  LogoProfileContainer,
} from '../../styles/Sidebar/SidebarStyle';
import {
  ProfileHead,
  Initial,
  ProfileContainer,
  Container1,
  ProfileLink,
  ProfilePic,
} from '../../styles/Landing/NavbarElements';

import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import WebIcon from '@mui/icons-material/Web';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ForumIcon from '@mui/icons-material/Forum';
import SummarizeIcon from '@mui/icons-material/Summarize';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AddCardIcon from '@mui/icons-material/AddCard';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { logOutApi } from '../../features/authentication/AuthenticationSlice';

const SideBar = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);
  const [displayProfile, setDisplayProfile] = useState(false);
  const [isHoverMode, setIsHoverMode] = useState(false); // State to manage hover mode
  const navigate = useNavigate();
  const location = useLocation();
  const { users } = useSelector((state) => state.auth);

  const routes = [
    {
      path: '/Dashboard',
      name: 'Dashboard',
      icon: <DashboardCustomizeIcon fontSize="small" />,
      access: ['Admin', 'Instructor', 'Learner'],
    },
    {
      path:
        users.user.userid?.role === 'Admin' ||
        users.user.userid?.role === 'Instructor'
          ? '/CourseBuilder'
          : '/CourseBuilder',
      name:
        users.user.userid?.role === 'Admin' ||
        users.user.userid?.role === 'Instructor'
          ? 'Course Builder'
          : 'Courses',
      icon: <MenuBookIcon fontSize="small" />,
      access: ['Admin', 'Instructor', 'Course Reviewer'],
    },
    {
      path: '/Users',
      name: 'Users',
      icon: <SupervisorAccountIcon fontSize="small" />,
      access: ['Admin', 'Instructor'],
    },
    {
      path:
        users.user.userid?.role === 'Admin'
          ? '/EventsNavigator'
          : '/LearnersEventsNavigator',
      name: 'Events Navigator',
      icon: <CalendarMonthIcon fontSize="small" />,
      access: ['Admin', 'Instructor', 'Learner'],
    },
    {
      path:
        users.user.userid?.role === 'Admin'
          ? '/Certificate'
          : '/LearnerCertificate',
      name: 'Certificates',
      icon: <CardMembershipIcon fontSize="small" />,
      access: ['Admin', 'Instructor', 'Learner'],
    },
    {
      path:
        users.user.userid?.role === 'Admin'
          ? '/Notification'
          : '/LearnerNotification',
      name: 'Notifications',
      icon: <NotificationsIcon fontSize="small" />,
      access: ['Admin', 'Instructor', 'Learner'],
    },

    {
      path: '/Modules',
      name: 'Modules',
      icon: <AddCardIcon fontSize="small" />,
      access: ['Admin', 'Instructor', 'Learner'],
    },
    {
      path: '/LearnerCourses',
      name: 'Quizzes',
      icon: <MenuBookIcon fontSize="small" />,
      access: ['Learner'], // Accessible only to Admin
    },
    {
      path: '/Sitebuilder',
      name: 'Site Builder',
      icon: <DashboardCustomizeIcon fontSize="small" />,
      access: ['SuperAdmin', 'Instructor', 'Admin'],
    },
    {
      path: '/Attendify',
      name: 'Attendify',
      icon: <CheckCircleIcon fontSize="small" />,
      access: ['SuperAdmin', 'Instructor', 'Admin', 'Learner'],
    },
  ];

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      opacity: 1,
      width: 'auto',
      transition: {
        duration: 0.2,
      },
    },
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const isSitebuilderPath = currentPath.startsWith('/Sitebuilder');
    const isSitebuilderBPath = currentPath.startsWith(`/SiteBuilderB`);
    const isCoursebuilderPath = currentPath.startsWith('/CourseBuilder');
    if (isSitebuilderPath || isSitebuilderBPath || isCoursebuilderPath) {
      setIsOpen(false);
      setIsHoverMode(true); // Enable hover mode when on sitebuilder pages
    } else {
      setIsOpen(true);
      setIsHoverMode(false); // Disable hover mode for other pages
    }
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setIsOpen(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const logout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        window.localStorage.removeItem('auth');
        window.localStorage.removeItem('userDetails');

        setDisplayProfile(!displayProfile);
        dispatch(logOutApi())
          .unwrap()
          .then(() => {
            navigate('/');
          })
          .catch((err) => {
            console.error('Logout failed:', err);
          });
      })
      .catch((error) => {
        console.error('Error signing out: ', error);
      });
  };

  return (
    <MainContainer
      userLoggedIn={users?.user?.userid?.email_verified}
      onMouseEnter={() => isHoverMode && setIsOpen(true)} // Open on hover in hover mode
      onMouseLeave={() => isHoverMode && setIsOpen(false)} // Close on mouse leave in hover mode
    >
      <motion.div
        animate={{
          width: isOpen ? 'auto' : '58px',
          transition: {
            type: 'custom',
            duration: 0.3,
            ease: 'linear',
          },
        }}
        className={`sidebar`}
      >
        <TopSection>
          <LogoProfileContainer>
            <ImageContainerSidebar>
              {isOpen ? (
                <ImageOpen
                  src={require('../../images/NoBackground.png')}
                  onClick={toggle}
                />
              ) : (
                <ImageClosed
                  src={require('../../images/SmallBlueBackground.png')}
                  onClick={toggle}
                />
              )}
            </ImageContainerSidebar>
          </LogoProfileContainer>
        </TopSection>

        <Section>
          {routes.map((route, index) => {
            if (route.subRoutes) {
              return (
                <SidebarMenu
                  setIsOpen={setIsOpen}
                  route={route}
                  showAnimation={showAnimation}
                  isOpen={isOpen}
                />
              );
            }

            if (
              route.access === 'Learner' ||
              (Array.isArray(route.access) &&
                route.access.includes(users?.user?.userid?.role))
            ) {
              return (
                <NavLink
                  to={route.path}
                  key={index}
                  className="link"
                  activeClassName="active"
                >
                  <Icon>{route.icon}</Icon>
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="link_text"
                      >
                        {route.name}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
              );
            } else {
              return null;
            }
          })}
        </Section>
      </motion.div>

      <ProfileContainer>
        {displayProfile && (
          <Container1>
            <ProfileLink
              to="/MyProfile"
              onClick={() => setDisplayProfile(!displayProfile)}
            >
              My Profile
            </ProfileLink>
            <ProfileLink onClick={logout}>Logout</ProfileLink>
          </Container1>
        )}

        <ProfileHead onClick={() => setDisplayProfile(!displayProfile)}>
          {users?.user?.userid?.hasOwnProperty('picture') &&
          users?.user?.userid?.picture !== '' ? (
            <ProfilePic src={users?.user?.userid?.picture} />
          ) : (
            <ProfilePic src={require('../../images/user.png')} />
          )}
          {isOpen && <Initial>{users?.user?.userid?.username}</Initial>}
          {!displayProfile && (
            <IconButton style={{ color: 'white' }}>
              {isOpen && (
                <SettingsIcon
                  onClick={() => setDisplayProfile(!displayProfile)}
                />
              )}
            </IconButton>
          )}
          {displayProfile && (
            <IconButton style={{ color: 'white' }}>
              {!isOpen && (
                <SettingsIcon
                  style={{ transform: 'rotate(90deg)' }}
                  onClick={() => setDisplayProfile(!displayProfile)}
                />
              )}
            </IconButton>
          )}
        </ProfileHead>
      </ProfileContainer>
    </MainContainer>
  );
};

export default SideBar;
