import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import {
  IconButton,
  Tooltip,
  Button,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Modal,
  Box,
  CircularProgress,
  TextField,
} from '@mui/material';
import {
  HeaderContainer,
  CreateContent,
  Heading,
  CardCont,
  SearchCont,
  CreatePageBtnCont,
  Container1,
  NotFoundMatchCont,
} from '../../../styles/Courses/CourseBuilderStyles';
import { useNavigate } from 'react-router-dom';
import { PageContainer } from '../../../styles/Common/CommonStyles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { palette } from '../../../styles/palette';
import { Edit, Delete } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { getCoursePages } from '../../../features/sitebuilder/SitebuilderSlice';
import { useDispatch, useSelector } from 'react-redux';
const LandingPageDetails = () => {
  const navigate = useNavigate();
  const { courseId } = useParams();
  const [templates, setTemplates] = useState([]);
  const { id } = useParams();
  const [previewImage, setPreviewImage] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [dropdownSelection, setDropdownSelection] = useState('');
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { coursePages, status, error } = useSelector(
    (state) => state.userTemplates,
  );

  const filteredTemplates = templates.filter((template) => {
    const matchesSearchInput =
      template.title.toLowerCase().includes(searchInput.toLowerCase()) ||
      template.pageName.toLowerCase().includes(searchInput.toLowerCase());
    const matchesDropdownSelection =
      dropdownSelection === '' || template.title === dropdownSelection;
    return matchesSearchInput && matchesDropdownSelection;
  });

  const filteredCoursePageTemplates = coursePages.filter((template) => {
    const matchesSearchInput =
      template.categoryId.toLowerCase().includes(searchInput.toLowerCase()) ||
      template.pageName.toLowerCase().includes(searchInput.toLowerCase()) ||
      template.pageType?.toLowerCase().includes(searchInput.toLowerCase());
    // const matchesDropdownSelection =
    //   dropdownSelection === '' || template.title === dropdownSelection;

    return matchesSearchInput;
  });

  const handlePreviewOpen = (template) => {
    setPreviewImage(template.previewImage);
    setPreviewOpen(true);
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
    setPreviewImage('');
  };

  const fetchTemplates = async () => {
    try {
      const response = await axios.get(
        `http://localhost:8000/api/course/pages/getAllCourseBuilderPages/${id}`,
      );
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data.data)) {
        setTemplates(response.data.data);
      } else {
        console.error('API response is not an array:', response.data);
      }
    } catch (error) {
      console.error('Error fetching templates:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTemplates();
    dispatch(getCoursePages());
  }, [dispatch]);

  const handleEditClick = () => {
    navigate(`/CoursePageBuilder/${courseId}`);
  };

  const handleEditClick2 = (courseId) => {
    navigate(`/CoursePageBuilder/${courseId}`);
  };

  return (
    <PageContainer>
      <HeaderContainer>
        <CreateContent>
          <Tooltip title="Go Back">
            <IconButton
              size="small"
              onClick={() => navigate(-1)}
              sx={{ color: `green` }}
            >
              <ArrowBackIosIcon />
            </IconButton>
          </Tooltip>
          <Heading>COURSE Pages</Heading>
        </CreateContent>
      </HeaderContainer>
      <Container1>
        <SearchCont>
          <TextField
            label="Search Templates"
            variant="outlined"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            style={{ marginRight: '20px', minWidth: 400 }}
          />
          {/* <FormControl variant="outlined" style={{ minWidth: 400 }}>
            <InputLabel>Filter by Title</InputLabel>
            <Select
              value={dropdownSelection}
              onChange={(e) => setDropdownSelection(e.target.value)}
              label="Filter by Title"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {templates.map((template) => (
                <MenuItem key={template._id} value={template.title}>
                  {template.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
        </SearchCont>
        <CreatePageBtnCont>
          <Button
            variant="contained"
            style={{ backgroundColor: `${palette.primary}` }}
            onClick={handleEditClick}
          >
            CREATE PAGE
          </Button>
        </CreatePageBtnCont>
      </Container1>
      <CardCont>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="60vh"
            sx={{ background: 'pink' }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={2}>
            {searchInput.length === 0 ? (
              <Typography variant="h6" style={{ margin: '20px auto' }}>
                <NotFoundMatchCont>Search to find a template</NotFoundMatchCont>
              </Typography>
            ) : (
              filteredCoursePageTemplates.map((template) => (
                <Grid item xs={12} sm={6} md={3} key={template.courseId}>
                  <Card>
                    <CardMedia
                      component="img"
                      height="140"
                      image={template.previewImage}
                      alt={template.pageName}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {template.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {template.pageName}
                      </Typography>
                      <IconButton
                        color="primary"
                        onClick={() => handlePreviewOpen(template)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton
                        color="primary"
                        onClick={() => handleDeleteTemplate(template._id)}
                      >
                        <Delete />
                      </IconButton>
                      <IconButton
                        color="primary"
                        onClick={() => handleEditClick2(template.courseId)}
                      >
                        <Edit />
                      </IconButton>
                    </CardContent>
                  </Card>
                </Grid>
              ))
            )}
          </Grid>
        )}
      </CardCont>

      <Modal open={previewOpen} onClose={handlePreviewClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            overflowY: 'auto',
            maxHeight: '80%',
          }}
        >
          {previewImage && (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
                  Preview
                </Typography>
                <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
                  <CloseIcon
                    style={{
                      color: 'red',
                      fontSize: '25px',
                      cursor: 'pointer',
                    }}
                    onClick={handlePreviewClose}
                  />
                </Typography>
              </div>
              <img
                src={previewImage}
                style={{ width: '100%', height: 'auto' }}
                alt="Template Preview"
              />
            </>
          )}
        </Box>
      </Modal>
    </PageContainer>
  );
};

export default LandingPageDetails;
