import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { ForgetPassContainer } from '../../styles/Users/UsersStyles';
import CommonButton from '../Utilities/CommonButton';
import Grid from '@mui/material/Grid';
import { useState } from 'react';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const handleClick = () => {};

  const handleChange = (event) => {
    const emailID = event.target.value;
    setEmail(emailID);
  };
  return (
    <ForgetPassContainer>
      <Card sx={{ border: '0px solid black', width: '40%' }}>
        <CardContent>
          <Grid>
            <Typography sx={{ mb: 1 }} textAlign="center" variant="h5">
              Forgot Password ?
            </Typography>
          </Grid>
          <Grid>
            <Typography sx={{ mb: 2 }} textAlign="center" variant="body2">
              Enter your registered email and we will send you instructor to
              reset password
            </Typography>
          </Grid>
          <Grid>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              type="email"
              required
              margin="normal"
              value={email}
              onChange={handleChange}
              sx={{ marginBottom: '20px' }}
            />
          </Grid>
          <Grid display="flex" justifyContent="center" alignItems="center">
            <CommonButton label="Reset Password" onClick={handleClick} />
          </Grid>
        </CardContent>
      </Card>
    </ForgetPassContainer>
  );
};
export default ForgotPassword;
