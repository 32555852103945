
import React, { useState,useMemo } from "react";
import { TextField, Tooltip, IconButton, Grid, Typography, InputAdornment, Button, CircularProgress, Box } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { palette } from "../../../styles/palette";
import DeleteIcon from '@material-ui/icons/Delete';
import JoditReact from "jodit-react";
import axios from "axios";
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addItem } from '../../../features/courses/addCourseDetailsSlice';
import { styled } from '@mui/system';
import {EditorContainer,DeleteBox,StyledTextField,ButtonContainer,BackButtonContainer,HeaderContainer} from '../../../styles/Courses/CourseModuleStyles';


const DiscardButton = styled(Button)`
  color: ${palette.primary};
  border-color:  ${palette.gray};
  margin-right: 10px;
  width: 180px;
`;

const SaveButton = styled(Button)`
  background-color: ${palette.primary};
  color: ${palette.white};
`;

const UploadDownload = ({  onClose, selectedItem,lessonId, moduleId , handleDeleteTopic }) => {
  const { id, courseStructure } = useParams();
  const[saved ,setSaved] = useState(true);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    selectedValue: "upload",
    links: selectedItem?.link || [],
    uploadUrls: [],
    moduleId: selectedItem?.moduleId || "",
    lessonId: selectedItem?.lessonId || "",
    title: selectedItem?.title || "",
    textDescription: selectedItem?.textDescription || "",
    id: selectedItem?.id || "",
    courseStructure: selectedItem?.courseStructure || "",
    contentType: selectedItem?.contentType || "",
    fileNames: selectedItem?.fileName || [],
    preview:selectedItem?.preview||false,

  });

  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState(selectedItem?.fileNames ? selectedItem.fileNames : []);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      lessonId: lessonId,
      moduleId: moduleId,
      id: selectedItem?.id ||generateRandomNumber(),
      contentType: 'Download',
      courseStructure: courseStructure,
    }));
  };
  const config = useMemo(() =>({
    placeholder: 'Enter the description',
        readonly: false,
  }),
  [],
);
  const generateRandomNumber = () => {
    const min = 100000; // Minimum 6-digit number
    const max = 999999; // Maximum 6-digit number
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const handleDelete =()=>{
    handleDeleteTopic(selectedItem?.moduleId , selectedItem?.lessonId , selectedItem?.id)
  }

  const handleFilesChange = async (e) => {
    const selectedFiles = Array.from(e.target.files);
    const newFiles = [...files, ...selectedFiles];
    setFiles(newFiles);

    const newFileNames = [...fileNames, ...selectedFiles.map(file => file.name)];
    setFileNames(newFileNames);
    setFormData((prevData) => ({
      ...prevData,
      fileNames: newFileNames
    }));
    await requestUploadUrls(selectedFiles);
  };


  const requestUploadUrls = async (files) => {
    try {
      const urls = await Promise.all(
        files.map(file =>
          axios.post(`${process.env.REACT_APP_URL}/api/course/uploadVideo`, {
            fileName: file.name,
            fileType: file.type,
          })
        )
      );
      const uploadUrls = urls.map(response => response.data.url);
      const links = uploadUrls.map(url => url.split('?')[0]);

      setFormData((prevData) => ({
        ...prevData,
        links: [...prevData.links, ...links],
        uploadUrls: [...prevData.uploadUrls, ...uploadUrls],
      }));
    } catch (error) {
      console.error('Error requesting upload URLs:', error);
    }
  };

  const uploadToS3 = async () => {
    setIsLoading(true);
    try {
      await Promise.all(
        formData.uploadUrls.map((url, index) =>
          axios.put(url, files[index], {
            headers: {
              "Content-Type": files[index].type,
            },
          })
        )
      );
      alert('Files uploaded successfully!');
    } catch (error) {
      console.error('Error uploading files:', error);
    }
    setIsLoading(false);
  };

  const handleFileRemove = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);

    const newFileNames = [...fileNames];
    newFileNames.splice(index, 1);
    setFileNames(newFileNames);

    const newUploadUrls = [...formData.uploadUrls];
    newUploadUrls.splice(index, 1);

    const newLinks = [...formData.links];
    newLinks.splice(index, 1);

    setFormData((prevData) => ({
      ...prevData,
      uploadUrls: newUploadUrls,
      links: newLinks,
    }));
  };

  const handleUploadAll = async () => {
    if (files.length > 0) {
      await uploadToS3();
    }
  };

  const handleOnClose = () => {
    const data = { ...formData, preview: false}
    console.log("abc data from go back",data)
    if(saved && (formData.uploadUrls  || formData.links || formData.fileNames)){
      onClose();

    }
    else{
      dispatch(addItem(data));
      onClose()
    }

   

  };

  const handleSubmit = async () => {
    if(formData.uploadUrls || formData.links|| formData.fileNames)
      {
        
        const data = {...formData, preview:true}
        console.log('abc from  the submit',data);
        
        await dispatch(addItem(data));
      } else{
        
        const data = { ...formData, preview: false}
        console.log("abc data from the save for the draft",data)
    await dispatch(addItem(data));
      } 
    
      setSaved(true);
      onClose();
      };

  return (
    <div>
      <HeaderContainer>
        <BackButtonContainer container alignItems="center" spacing={1} sx={{ mt: 1, mb: 2 }}>
          <Grid item>
            <Tooltip title="Go Back">
              <IconButton
                size="small"
                onClick={handleOnClose}
                sx={{ color: `${palette.THEME_THREE}` }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item sx={{ mt: 1 }}>
            <Typography variant="h5" gutterBottom>
              Download
            </Typography>
          </Grid>
        </BackButtonContainer>

        <ButtonContainer>
        <DiscardButton variant="outlined">Discard Changes</DiscardButton>
{formData.title?        <SaveButton variant="contained" onClick={handleSubmit}>Save</SaveButton>
:<Button disabled>Save</Button>}        </ButtonContainer>
      </HeaderContainer>
      <EditorContainer>
        <StyledTextField
          variant="outlined"
          label="Title"
          name="title"
          value={formData.title}
          onChange={handleInputChange}
          style={{ width: "100%", marginBottom: "10px" }}
        />
        <Typography variant="h6" gutterBottom>
          Description
        </Typography>
        <JoditReact
          value={formData.textDescription}
          config={config}
          onChange={(newContent) => setFormData((prevData) => ({ ...prevData, textDescription: newContent }))}
        />
        <Grid item xs={12} sx={{ mt: 2 }}>
          <TextField
            variant="outlined"
            label="Upload Files"
            helperText="Max size: 200MB each"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    component="label"
                    color="primary"
                    style={{ borderRadius: 4, backgroundColor:` ${palette.primary}`, color:`${palette.white}` }}
                    >
                    Upload Files
                    <input type="file" multiple style={{ display: "none" }} onChange={handleFilesChange} />
                  </Button>
                </InputAdornment>
              ),
            }}
            style={{ width: "100%", marginBottom: "10px" }}
          />
          {fileNames.map((fileName, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <TextField
                variant="outlined"
                value={fileName}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => handleFileRemove(index)} style={{ color: 'red' }}>
                        <DeleteIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                style={{ flexGrow: 1, marginRight: '10px' }}
              />
            </div>
          ))}
          {fileNames.length > 0 && (
            <Button variant="contained" color="primary" onClick={handleUploadAll} style={{ marginTop: '10px' }}>
              {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Upload All Files'}
            </Button>
          )}
        </Grid>
      </EditorContainer>
      
<DeleteBox>
      <h4 style={{ marginRight: '10px' }}>
      Are you sure you want to delete this topic? This action is irreversible.
      </h4><br/>
      <Button
        variant="contained"
        color="error"
        onClick={handleDelete}     
        upload >
        Delete Topic
      </Button>
         </DeleteBox>
    </div>
  );
};

export default UploadDownload;
