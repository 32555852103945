import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { fetchCourseInSlice , fetchCourseById } from '../../../features/courses/addCourseDetailsSlice';
import {
  Container,
  Typography,
  FormControl,
  Box,
  Button,
  Tooltip,
  IconButton,
  Autocomplete,
  TextField
} from '@mui/material';
import { palette } from '../../../styles/palette';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Loader from '../../Utilities/Loader';



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CopyExistingCourse = () => {
  const dispatch = useDispatch();
  const { coursesList, loading } = useSelector((state) => state.addCourseDetails) || {};
  const [selectedCourses, setSelectedCourses] = useState(null);

  const navigate = useNavigate();
  useEffect(() => {
    const fetchCourses = async () => {
      await dispatch(fetchCourseInSlice());
    };
    fetchCourses();
  }, [dispatch]);

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setSelectedCourses(
  //     typeof value === 'string' ? value.split(',') : value,
  //   );
  // };

  const handleCourseSelect = (event, value) => {
    console.log(value.generalInformation.title , "handleCourseSelect")

    setSelectedCourses(value);
    if (value) {
      
    }
  };

  const handleSubmit = async() => {
    console.log('Selected Courses:', selectedCourses , selectedCourses._id);
  const data =  await dispatch(fetchCourseById(selectedCourses._id));
   console.log(data , "handleSubmit for copy existing")
  
    navigate('/CourseDetails', {
      state: data?.payload,
    })
  };

  if (loading) {
    return  < Loader/>;
  }

  return (
    <>
    <div>
     <Tooltip title="Go Back">
           <IconButton
            size="small"
            onClick={() => navigate(-1)}
            sx={{ color: `${palette.THEME_THREE}`,
            left:"22px",
            top:"15px"
  }}
          >
            <ArrowBackIosIcon />
          </IconButton>
        </Tooltip>
        </div>
    <Container
    sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '70vh',
      }}>
      <Typography variant="h5" gutterBottom>
        Copy Existing Course
      </Typography>
      {/* <FormControl sx={{ m: 1, width: 500 }}>
        <InputLabel id="select-multiple-courses-label">Select Courses</InputLabel>
        <Select
          labelId="select-multiple-courses-label"
          id="select-multiple-courses"
          multiple
          value={selectedCourses}
          onChange={handleChange}
          input={<OutlinedInput label="Select Courses" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {coursesList.map((course) => (
            <MenuItem key={course._id} value={course.title}>
              <Checkbox checked={selectedCourses.indexOf(course.title) > -1} />
              <ListItemText primary={course.title} />
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}
       {/* <FormControl fullWidth style={{ marginTop: '16px' }}>
            <Autocomplete
              options={coursesList.map(course => ({ title: course.generalInformation.title, id: course._id , courseStructure: course.generalInformation.courseStructure}))}
              value={selectedCourses}
              onChange={handleCourseSelect}
              getOptionLabel={(option) => option.title}

              renderInput={(params) => (
                <TextField {...params} label="Select Course" variant="outlined" />
              )}
            />
          </FormControl> */}

<FormControl fullWidth style={{ marginTop: '16px' }}>
  <Autocomplete
    options={coursesList}  // Pass the entire course object
    value={selectedCourses}
    onChange={handleCourseSelect}
    getOptionLabel={(option) => option.generalInformation.title}  // Adjust to access the title from the complete object

    renderInput={(params) => (
      <TextField {...params} label="Select Course" variant="outlined" />
    )}
  />
</FormControl>

      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Copy Course
        </Button>
      </Box>
    </Container>
    </>
  );
};

export default CopyExistingCourse;
