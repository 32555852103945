import React, { useState } from 'react';
import {
  TextField,
  Button,
  Grid,
  Box,
  Typography,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const SignupNew = () => {
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [showModal, setShowModal] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [emailTouched, setEmailTouched] = useState(false);
  const navigate = useNavigate();
  const URL = process.env.REACT_APP_URL;

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const handleBlur = (e) => {
    if (e.target.name === 'email') {
      validateEmail(e.target.value);
      setEmailTouched(true);
    }
  };

  const validateEmail = (email) => {
    // Regex for basic email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!regex.test(email)) {
      setEmailError('Invalid email format');
    } else {
      setEmailError('');
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      // Check email validation before submitting
      if (emailError) {
        console.error('Invalid email format');
        return;
      }

      const response = await fetch(`${URL}/api/usermodule/createnewusers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstname: state.firstName,
          lastname: state.lastName,
          email: state.email,
          role: 'Learner',
        }),
      });

      if (response.ok) {
        console.log('Signup successful');
        setShowModal(true);
        setState({
          firstName: '',
          lastName: '',
          email: '',
        });
      } else {
        console.error('Failed to submit the form.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        <Box component="form" onSubmit={handleSignup} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                name="firstName"
                autoComplete="given-name"
                value={state.firstName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="family-name"
                value={state.lastName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={state.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!emailError && emailTouched}
                helperText={emailTouched ? emailError : ''}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              mt: 3,
            }}
          >
            <div style={{ marginTop: 10 }}>
              <span
                onClick={handleLogin}
                style={{ cursor: 'pointer', color: '#007bff' }}
              >
                Already a user? Login from here..
              </span>
            </div>
            <Button type="submit" variant="contained" sx={{ mt: 2 }}>
              Sign Up
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Modal Dialog */}
      <Dialog open={showModal} onClose={handleCloseModal}>
        <DialogTitle>Account Creation Successful</DialogTitle>
        <DialogContent>
          <Typography>
            Your request for account creation has been forwarded to the Admin.
            Kindly check your mailbox after some time to verify your email ID
            and complete the signup process.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default SignupNew;
