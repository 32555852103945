import { hot } from 'react-hot-loader/root';
import LandingHome from './components/Landing/LandingHome';
import { palette } from './styles/palette';

function App() {
  return (
    <div style={{ height: '100%' }}>
      <LandingHome />
      {/* <Footer /> */}
    </div>
  );
}

export default hot(App);
