import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCourseSettings,
  basicSetting,
} from '../../../features/courses/CourseUtilitySlice';

import {
  setSelectedModule,
  setShowCreateModule,
  setShowAddModuleForm,
  setShowAddChapterForm,
  setOpenTopicDialog,
  setShowCreateLesson,
  setSelectedItem,
  setDisplayContent,
} from '../../../features/courses/courseModuleSlice';
import {
  IconButton,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  Paper,
  Collapse,
  Box,
  Typography,
  Button,
  Menu,
  MenuItem,
  Modal,
  ListItemIcon,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import AddIcon from '@mui/icons-material/Add';
import { palette } from '../../../styles/palette';
import AddModuleDialog from './AddModuleDialog';
import AddChapterDialog from './AddChapterDialog';
import LessonTopics from './LessonTopics';
import AddOrCopyTopic from './AddOrCopyTopic';
import CommonButton from '../../Utilities/CommonButton';

import CourseSettings from '../CourseSettings/CourseSettings';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteTopic,
  saveDetails,
  publisCourse,
  fetchCourseDetailsInSlice,
} from '../../../features/courses/addCourseDetailsSlice';
import CreateModule from './CreateModule';
import CreateLesson from './CreateLesson';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { toast } from 'react-toastify';
import { margin, styled } from '@mui/system';
import {
  Header,
  Sidebar,
  SidebarHeader,
  AddModuleButton,
  FixedBottomInner,
  DefaultMessageBox,
  FixedBottom,
} from '../../../styles/Courses/CourseModuleStyles';
import { color } from 'framer-motion';
import CoursePreview from '../CoursePreview/CoursePreview';
import LearnerCoursePreview from '../LearnerCourseBuilder/LearnerCoursePreview';
import PreviewModuleOrLesson from '../LearnerCourseBuilder/PreviewModuleOrLesson';
import Preview from '../preview';
import PreviewModule from '../PreviewModule';
import Footer from '../../Landing/Footer';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import ViewModuleIcon from '@mui/icons-material/ViewModule'; //module icon
import TopicIcon from '@mui/icons-material/Topic'; //lesson icon
import DraftsIcon from '@mui/icons-material/Drafts'; //draft topic
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import VolumeMuteOutlinedIcon from '@mui/icons-material/VolumeMuteOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import MovieOutlinedIcon from '@mui/icons-material/MovieOutlined';
import MmsOutlinedIcon from '@mui/icons-material/MmsOutlined';
import SettingsInputAntennaOutlinedIcon from '@mui/icons-material/SettingsInputAntennaOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import PollOutlinedIcon from '@mui/icons-material/PollOutlined';
import axios from 'axios';

const StyledListItem = styled(ListItem)({
  background: `${palette.white}`,
  marginTop: '10px',
});

const StyledListItemDraft = styled(ListItem)({
  background: `${palette.gray}`,
  marginTop: '10px',
});

const InnerList = styled(List)({
  padding: '20px',
  marginBottom: '10px',
  background: `${palette.lightGray2}`,
});

const LessonItem = styled(ListItem)`
  background: ${palette.white};
  margin-bottom: 10px;
  border: 1px solid ${palette.gray};
  border-radius: 5px;
`;

const CourseModules = () => {
  const [formData, setFormData] = useState({
    contentScheduling: false,
    sequentialLearningPath: false,
    enforceCompleteVideoViewing: false,
    security: {
      disableTextCopying: false,
      showCourseInfo: false,
      allowBookmarking: false,
    },
    completionPercentage: 100,
    ProgressIndicators: false,
    DiscussionForums: false,
    langCode: 'en',
  });

  const navigate = useNavigate();
  const location = useLocation();
  const { courseTitle, coursePublish } = location.state || {};
  const dispatch = useDispatch();
  const [expandedModule, setExpandedModule] = useState(null);
  const [coursePublishUsingUseSte, setcoursePublishUsingUseSte] =
    useState(coursePublish);

  const [expandedLessons, setExpandedLessons] = useState({});
  const [displayContentData, setDisplayContentData] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedModuleId, setSelectedModuleId] = useState(null);
  const [openChapterDialog, setOpenChapterDialog] = useState(false);
  const [openTopicDialog, setOpenTopicDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // State to keep track of selected item
  const [displayContent, setDisplayContent] = useState(false);
  const [showAddModuleForm, setShowAddModuleForm] = useState(false);
  const [showAddChapterForm, setShowAddChapterForm] = useState(false);
  const [showCreateModule, setShowCreateModule] = useState(false);
  const [showCreateLesson, setShowCreateLesson] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [ModuleData, setModuleData] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [Reviewers, setReviewrs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedReviewer, setSelectedReviewer] = useState(null);

  const [button, setButton] = useState(true);

  const { users } = useSelector((state) => state.auth);
  const [showWholePreview, setShowWholePreview] = useState(false);
  const [showParticularPreview, setShowParticularPreview] = useState(false);
  const [previewSelection, setPreviewSelection] = useState(false);
  const [sendingTopicId, setSendingTopicId] = useState(null);
  const [anchorMenuEl, setAnchorMenuEl] = useState(null);
  const { modules, lessons } =
    useSelector((state) => state.addCourseDetails.courseDetails) || {};

  const { id, courseStructure } = useParams();
  console.log(id, courseStructure);

  useEffect(() => {
    const fetchCourseDetails = async (id) => {
      await dispatch(fetchCourseDetailsInSlice(id));
    };

    fetchCourseDetails(id);
  }, [id]);

  const handleAddTopicSubmit = (topicData) => {
    // Handle the submitted topic data here
    console.log('Submitted topic data:', topicData);
    setOpenTopicDialog(false);
  };
  const handleCreateTopicsClick = () => {
    // Close AddTopicDialog if it's open
    setOpenTopicDialog(false);
  };

  const handleModuleOnClick = (moduleId) => {
    setExpandedModule(expandedModule === moduleId ? null : moduleId);
  };
  const handleModuleTextClick = (module) => {
    // setShowDefaultMessage(false); // Hide default message
    setSelectedModule(module);
    setShowCreateModule(true);
    setShowAddModuleForm(false);
    setShowAddChapterForm(false);
    setOpenTopicDialog(false);
    setShowCreateLesson(false);
    setSelectedItem(null);
    setDisplayContent(false);
  };

  const handleLessonClick = (lessonId) => {
    // setShowDefaultMessage(false); // Hide default message
    setExpandedLessons({
      ...expandedLessons,
      [lessonId]: !expandedLessons[lessonId],
    });
  };

  const handleLessonTextClick = (lesson) => {
    // setShowDefaultMessage(false); // Hide default message
    setSelectedLesson(lesson);
    setShowCreateLesson(true);
    setShowAddModuleForm(false);
    setShowAddChapterForm(false);
    setOpenTopicDialog(false);

    setSelectedItem(null);
    setShowCreateModule(false);
    // setShowCreateLesson(false)
  };

  const isModuleExpanded = (moduleId) => {
    return expandedModule === moduleId;
  };

  const isLessonExpanded = (lessonId) => {
    return expandedLessons[lessonId];
  };

  const handleAddModule = () => {
    setOpenDialog(true);
  };

  const handleAddModuleClick = () => {
    setShowAddModuleForm(true);
    setShowAddChapterForm(false);
    setOpenTopicDialog(false);
    setShowCreateLesson(false);
    setSelectedItem(null);
    setShowCreateModule(false);
  };

  const handleCloseAddModuleForm = () => {
    setShowAddModuleForm(false);
  };

  const handleCloseAddChapterForm = () => {
    setShowAddChapterForm(false);
    setSelectedModuleId(null);
  };

  const [courseModule, setCourseModule] = useState({
    module: [],
  });

  const [moduleId, setModuleId] = useState(null);
  const [lessonId, setLessonId] = useState(null);

  const handleCloseDialog = (courseModule) => {
    setCourseModule((prevCourseModule) => ({
      module: [...prevCourseModule?.module, courseModule],
    }));
    // setModuleData(courseModule)
    setOpenDialog(false);
  };

  const handleAddChapter = (moduleId) => {
    setSelectedModuleId(moduleId);
    setShowAddChapterForm(true);
    setShowAddModuleForm(false);
    // setShowAddChapterForm(false)
    setOpenTopicDialog(false);
    setShowCreateLesson(false);
    setSelectedItem(null);
    setShowCreateModule(false);
  };

  const handleCloseChapDialog = (courseLesson) => {
    setCourseModule((prevModuleName) => ({
      module: prevModuleName?.module?.map((mod) => {
        if (mod?.id === moduleId) {
          return {
            ...mod,
            lessons: [...mod?.lessons, courseLesson],
          };
        }
        return mod;
      }),
    }));
    setOpenChapterDialog(false);
  };

  const handleAddTopic = (moduleId, id) => {
    console.log('handleAddTopic', id);
    setLessonId(id);
    setModuleId(moduleId);
    console.log('HAndle add topic');
    setOpenTopicDialog(true);
    setShowAddModuleForm(false);
    setShowAddChapterForm(false);
    // setOpenTopicDialog(false)
    setShowCreateLesson(false);
    // Close CreateTopics if it's open
    setSelectedItem(null);
    setShowCreateModule(false);
  };

  const handleDeleteTopic = async (moduleId, lessonId, id) => {
    console.log('called from video topic');

    await dispatch(deleteTopic({ moduleId, lessonId, id }));

    setOpenTopicDialog(false);
    setShowAddModuleForm(false);
    setShowAddChapterForm(false);
    // setOpenTopicDialog(false)
    setShowCreateLesson(false);
    // Close CreateTopics if it's open
    setSelectedItem(null);
    setShowCreateModule(false);
  };

  const handleSaveCourse = async () => {
    console.log({ modules: modules, courseId: id });
    const result = await dispatch(
      saveDetails({
        modules: modules,
        courseStructure: courseStructure,
        courseId: id,
        lessons: lessons,
      }),
    );
    console.log(result, 'result handleSaveCourse');
    if (result.payload?.data?.success) {
      toast.success(result.payload?.data?.message);
    } else {
      toast.error(result.payload?.data?.message);
    }
  };

  const handleFetchReviewers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/getReviewers`,
      );
      console.log(response.data.data, 'response.data.data');
      setReviewrs(response.data.data);
    } catch (error) {
      console.error('Error fetching Reviewers:', error);
      toast.error('Error fetching reviewers');
    }
  };

  const handlePublishCourse = async () => {
    handleFetchReviewers();
    console.log('handle publish', Reviewers);
    console.log({ courseId: id });
    const result = await dispatch(
      publisCourse({
        courseId: id,
      }),
    );
    console.log(result, 'result handlePublishCourse');

    // Check if the action was fulfilled or rejected
    if (result.type === 'publisCourse/fulfilled') {
      setcoursePublishUsingUseSte(!coursePublishUsingUseSte);
      toast.success('Course published successfully');
    } else if (result.type === 'publisCourse/rejected') {
      // Show the error message from the rejected action
      toast.error(result.payload?.message || 'Error while publishing course');
      console.error('Error details:', result.error); // Log the error for debugging
    } else {
      toast.error('Unexpected error occurred');
    }
  };
  const handleSelectReviewer = async (reviewer) => {
    setSelectedReviewer(reviewer);
    handleClose();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/course/requestReview`,
        {
          courseId: id,
          reviewerId: reviewer.id,
        },
      );

      if (response.status === 200) {
        toast.success('Course sent for review');
      } else {
        toast.error('Error while sending course for review');
      }
    } catch (error) {
      console.error('Error while sending course for review', error);
      toast.error('Error while sending course for review');
    }
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    handleFetchReviewers();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseTopicDialog = (data) => {
    console.log('fgj');
    console.log(data);
    setOpenTopicDialog(false);
    setSelectedItem(null);
  };

  const handleOnContentClick = (item) => {
    console.log('from most parent', item);
    setDisplayContent(true);
    setDisplayContentData(item);
    setOpenTopicDialog(false);
    setShowAddModuleForm(false);
    setShowAddChapterForm(false);
    // setOpenTopicDialog(false)
    setShowCreateLesson(false);
    setSelectedItem(null);
    setShowCreateModule(false);
  };

  const handleCloseLessonTopic = () => {
    setOpenTopicDialog(true);
    setSelectedItem(null);
  };

  // const handleItemClick = (item) => {
  //   setSelectedItem(item);
  //   setShowAddModuleForm(false);
  //   setShowAddChapterForm(false);
  //   setOpenTopicDialog(false);
  //   setShowCreateLesson(false);
  //   setShowCreateModule(false);
  // };
  const handleItemClick = (item) => {
    setSendingTopicId(item.id);

    setSelectedItem(item);
    // Update the selected item when clicked
    setShowAddModuleForm(false);
    setShowAddChapterForm(false);
    setOpenTopicDialog(false);
    setShowCreateLesson(false);
    setShowCreateModule(false);
  };

  const handleLessonTopicsClose = () => {
    setOpenTopicDialog(true);
    setDisplayContent(false);
    setDisplayContentData('');
    setSelectedItem(null);
  };

  const toggleCourseSettings = () => {
    // setShowDefaultMessage(false); // Hide default message

    setSelectedItem(null);
    setShowAddModuleForm(false);
    setShowAddChapterForm(false);
    setOpenTopicDialog(false);
    setShowCreateLesson(false);
    setShowCreateModule(false);
  };

  const getItemTitle = (item) => {
    return item.content || item.title || ''; // Update this based on your JSON structure
  };

  const getItemContent = (item) => {
    return item.text || item.content || ''; // Update this based on your JSON structure
  };

  const handleCloseCreateModule = () => {
    setShowCreateModule(false);
  };

  const handleModuleUpdate = (updatedModule) => {
    dispatch(updateModule(updatedModule));
    setShowCreateModule(false);
  };

  const handleCloseCreateLesson = () => {
    setShowCreateLesson(false);
  };

  const handleLessonUpdate = (updatedLesson) => {
    dispatch(updateLesson(updatedLesson));
    setShowCreateLesson(false);
  };
  const handleCloseCreateTopic = () => {
    setSelectedItem(false);
  };

  const handlePreview = () => {
    setShowWholePreview(true);
    setShowParticularPreview(false);
    handleMenuClose();
  };
  const handleParticularPreview = () => {
    if (sendingTopicId) {
      setShowParticularPreview(true);
      setShowWholePreview(false);
      handleMenuClose();
    } else {
      alert('Please select an item to preview');
      handleParticularPreviewclose();
    }
  };
  const handleParticularPreviewclose = () => {
    setShowParticularPreview(false);
  };

  const handlePreviewSelection = () => {
    setPreviewSelection(!previewSelection);
  };

  const handleOpenModal = () => {};

  const renderDefaultMessage =
    !selectedItem &&
    !openTopicDialog &&
    !displayContent &&
    !showAddModuleForm &&
    !showAddChapterForm &&
    !showCreateModule &&
    !showCreateLesson;

  const handleMenuOpen = (event) => {
    setAnchorMenuEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorMenuEl(null);
  };

  const getContentIcon = (contentType) => {
    switch (contentType) {
      case 'Video':
        return <VideocamOutlinedIcon fontSize="small" />;
      case 'Audio':
        return <VolumeMuteOutlinedIcon fontSize="small" />;
      case 'Text':
        return <TextSnippetOutlinedIcon fontSize="small" />;
      case 'PDF':
        return <PictureAsPdfOutlinedIcon fontSize="small" />;
      case 'Download':
        return <CloudDownloadOutlinedIcon fontSize="small" />;
      case 'Presentation':
        return <MovieOutlinedIcon fontSize="small" />;
      case 'Multimedia':
        return <MmsOutlinedIcon fontSize="small" />;
      case 'Live':
      case 'Scorm':
        return <SettingsInputAntennaOutlinedIcon fontSize="small" />;
      case 'Quiz':
        return <QuizOutlinedIcon fontSize="small" />;
      case 'Assignment':
        return <AssignmentOutlinedIcon fontSize="small" />;
      case 'Exam':
        return <AssessmentOutlinedIcon fontSize="small" />;
      case 'Survey':
      case 'Evaluate':
        return <PollOutlinedIcon fontSize="small" />;
      default:
        return <LibraryBooksOutlinedIcon fontSize="small" />;
    }
  };
  console.log('abc selected data', selectedItem);

  return (
    <>
      {showWholePreview ? (
        <Preview />
      ) : showParticularPreview ? (
        <PreviewModule topicId={sendingTopicId} />
      ) : (
        <Box sx={{}}>
          <Header>
            <CommonButton onClick={handleSaveCourse} label="Save Course" />
            {/* Preview Button */}
            <CommonButton onClick={handleMenuOpen} label="Preview" />

            {/* Dropdown Menu */}
            <Menu
              anchorEl={anchorMenuEl}
              open={Boolean(anchorMenuEl)}
              onClose={handleMenuClose}
            >
              {/* Complete Preview Option */}
              <MenuItem onClick={handlePreview}>From The Beginning</MenuItem>

              {/* Selected Preview Option */}
              <MenuItem onClick={handleParticularPreview}>
                Current Topic
              </MenuItem>
            </Menu>
            {coursePublishUsingUseSte ? (
              <CommonButton onClick={handlePublishCourse} label="Un Publish" />
            ) : (
              <div>
                {users.user.userid?.role === 'Admin' && (
                  <CommonButton
                    label={button ? 'Send for Review' : 'Request for Review'}
                    aria-controls="reviewer-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                  ></CommonButton>
                )}
                <Menu
                  id="reviewer-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {Reviewers.length > 0 ? (
                    Reviewers.map((reviewer) => (
                      <MenuItem
                        key={reviewer.id}
                        onClick={() => handleSelectReviewer(reviewer)}
                      >
                        {`${reviewer.firstname} ${reviewer.lastname}`}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No Reviewers Available</MenuItem>
                  )}
                </Menu>
                          
              </div>
            )}
          </Header>

          <div style={{ display: 'flex' }}>
            <Sidebar>
              <SidebarHeader>
                <Tooltip title="Go Back">
                  <ArrowBackIosIcon
                    sx={{
                      cursor: 'pointer',
                      marginTop: '4px',
                      ':hover': { color: `${palette.primary}` },
                    }}
                    onClick={() => navigate('/CourseBuilder')}
                  />
                </Tooltip>
                <Typography sx={{ fontSize: '24px', paddingLeft: '2px' }}>
                  {courseTitle}
                </Typography>
              </SidebarHeader>
              <List style={{ height: '100%', overflowY: 'auto' }}>
                {courseStructure === 'CMLT' ? (
                  <>
                    {modules?.map((module) => (
                      <div key={module?.id}>
                        <StyledListItem>
                          <ListItemIcon>
                            <ViewModuleIcon style={{ color: `#4f5052` }} />
                          </ListItemIcon>
                          <ListItemText
                            // sx={{ color: `${palette.primary}` }}
                            primary={module?.title}
                            onClick={() => handleModuleTextClick(module)}
                          />
                          <Tooltip title="Add Lesson">
                            <AddIcon
                              onClick={() => handleAddChapter(module?.id)}
                              style={{ cursor: 'pointer' }}
                            />
                          </Tooltip>
                          <Box sx={{ marginRight: '8px' }} />
                          {isModuleExpanded(module?.id) ? (
                            <ExpandLessIcon
                              onClick={() => handleModuleOnClick(module.id)}
                              style={{ cursor: 'pointer' }}
                            />
                          ) : (
                            <ExpandMoreIcon
                              onClick={() => handleModuleOnClick(module.id)}
                              style={{ cursor: 'pointer' }}
                            />
                          )}
                        </StyledListItem>

                        <Collapse
                          in={isModuleExpanded(module?.id)}
                          timeout="auto"
                          unmountOnExit
                        >
                          <InnerList component={Paper}>
                            {module?.lessons?.map((lesson) => (
                              <div key={lesson.id}>
                                <LessonItem>
                                  <ListItemIcon>
                                    <TopicIcon style={{ color: `#4f5052` }} />
                                  </ListItemIcon>
                                  <ListItemText
                                    // sx={{ color: `${palette.secondary}` }}
                                    primary={lesson.title}
                                    onClick={() =>
                                      handleLessonTextClick(lesson)
                                    }
                                  />
                                  <Tooltip title="Add Topic">
                                    <AddIcon
                                      onClick={() =>
                                        handleAddTopic(
                                          lesson.moduleId,
                                          lesson.id,
                                        )
                                      }
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </Tooltip>
                                  <Box sx={{ marginRight: '8px' }} />
                                  {isLessonExpanded(lesson.id) ? (
                                    <ExpandLessIcon
                                      onClick={() =>
                                        handleLessonClick(lesson.id)
                                      }
                                      style={{ cursor: 'pointer' }}
                                    />
                                  ) : (
                                    <ExpandMoreIcon
                                      onClick={() =>
                                        handleLessonClick(lesson.id)
                                      }
                                      style={{ cursor: 'pointer' }}
                                    />
                                  )}
                                </LessonItem>
                                <Collapse
                                  in={isLessonExpanded(lesson.id)}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <List>
                                    {lesson?.items?.map((item, index) =>
                                      item.preview ? (
                                        <ListItem
                                          key={index}
                                          style={{
                                            background:
                                              selectedItem === item
                                                ? '#d0eaff'
                                                : '#f9f9f9', // Highlight if selected
                                            marginBottom: '5px',
                                            paddingLeft: '20px',
                                            cursor: 'pointer', // Add cursor to indicate clickable item
                                          }}
                                          onClick={() => handleItemClick(item)}
                                        >
                                          <ListItemIcon>
                                            {getContentIcon(item.contentType)}
                                          </ListItemIcon>
                                          <ListItemText
                                            primary={getItemTitle(item)}
                                          />
                                        </ListItem>
                                      ) : (
                                        <ListItem
                                          key={index}
                                          style={{
                                            background:
                                              selectedItem === item
                                                ? '#d0eaff'
                                                : `${palette.lightGray}`, // Highlight if selected`
                                            marginBottom: '5px',
                                            paddingLeft: '20px',
                                            cursor: 'pointer', // Add cursor to indicate clickable item
                                          }}
                                          onClick={() => handleItemClick(item)}
                                        >
                                          <ListItemIcon>
                                            {getContentIcon(item.contentType)}
                                          </ListItemIcon>
                                          <ListItemText
                                            primary={getItemTitle(item)}
                                          />

                                          <DraftsIcon
                                            style={{ color: `#4f5052` }}
                                          />
                                        </ListItem>
                                      ),
                                    )}
                                  </List>
                                </Collapse>
                              </div>
                            ))}
                          </InnerList>
                        </Collapse>
                      </div>
                    ))}
                  </>
                ) : (
                  <InnerList component={Paper}>
                    {lessons?.map((lesson) => (
                      <div key={lesson.id}>
                        <LessonItem>
                          <ListItemText
                            sx={{ color: `${palette.secondary}` }}
                            primary={lesson.title}
                            onClick={() => handleLessonTextClick(lesson)}
                          />
                          <Tooltip title="Add Topic">
                            <AddIcon
                              onClick={() =>
                                handleAddTopic(lesson.moduleId, lesson.id)
                              }
                            />
                          </Tooltip>
                          <Box sx={{ marginRight: '8px' }} />
                          {isLessonExpanded(lesson.id) ? (
                            <ExpandLessIcon
                              onClick={() => handleLessonClick(lesson.id)}
                            />
                          ) : (
                            <ExpandMoreIcon
                              onClick={() => handleLessonClick(lesson.id)}
                            />
                          )}
                        </LessonItem>
                        <Collapse
                          in={isLessonExpanded(lesson.id)}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List>
                            {lesson?.items?.map((item, index) => (
                              <ListItem
                                key={index}
                                style={{
                                  background:
                                    selectedItem === item
                                      ? '#d0eaff'
                                      : '#f9f9f9', // Highlight if selected
                                  marginBottom: '5px',
                                  paddingLeft: '20px',
                                  cursor: 'pointer', // Add cursor to indicate clickable item
                                }}
                                onClick={() => handleItemClick(item)}
                              >
                                <ListItemIcon>
                                  {getContentIcon(item.contentType)}
                                </ListItemIcon>
                                <ListItemText primary={getItemTitle(item)} />
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      </div>
                    ))}
                  </InnerList>
                )}

                <FixedBottom>
                  <FixedBottomInner>
                    {courseStructure === 'CMLT' ? (
                      <AddModuleButton
                        // onClick={handleAddModule}
                        onClick={handleAddModuleClick}
                      >
                        Add Module
                      </AddModuleButton>
                    ) : (
                      <AddModuleButton
                        // onClick={handleAddModule}
                        onClick={() => handleAddChapter(module?.id)}
                      >
                        Add Lesson
                      </AddModuleButton>
                    )}
                  </FixedBottomInner>
                </FixedBottom>
              </List>
            </Sidebar>

            {renderDefaultMessage && (
              <DefaultMessageBox>
                <PostAddIcon
                  style={{ fontSize: '56px', marginBottom: '16px' }}
                />
                <Typography variant="h6" align="center">
                  {courseStructure === 'CMLT'
                    ? 'Create or choose module or lesson to get started'
                    : 'Create or choose lesson to get started'}
                </Typography>
              </DefaultMessageBox>
            )}
            {/* Render CourseSettings component */}
            {/* {showCourseSettings && (
        <Box ml={2} width="calc(100% - 500px)">
          <div style={{ marginTop: '65px' }}>
            <CourseSettings />
          </div>
        </Box>
      )} */}

            {/* rendering Display of selected Topic */}
            {selectedItem && !openTopicDialog && (
              <LessonTopics
                // data = {selectedItem.contentType}
                selectedItem={selectedItem}
                onClose={handleCloseLessonTopic}
                lessonId={lessonId}
                moduleId={moduleId}
              />
            )}

            {openTopicDialog && !selectedItem && (
              <Box ml={2} width="calc(100% - 500px)">
                <AddOrCopyTopic
                  open={openTopicDialog}
                  onClose={handleCloseTopicDialog}
                  onCancel={{ handleCloseTopicDialog }}
                  onContentClick={handleOnContentClick}
                />
              </Box>
            )}
            {displayContent && (
              <Box ml={2} width="calc(100% - 500px)">
                <LessonTopics
                  open={displayContent}
                  data={displayContentData}
                  onClose={handleLessonTopicsClose}
                  lessonId={lessonId}
                  moduleId={moduleId}
                />
              </Box>
            )}

            {/* Render JoditEditor component if selectedItem is not null */}
            {selectedItem && (
              <Box ml={2} width="calc(100% - 500px)">
                <LessonTopics
                  data={selectedItem.contentType}
                  selectedItem={selectedItem}
                  onClose={handleCloseLessonTopic}
                  lessonId={lessonId}
                  moduleId={moduleId}
                  handleDeleteTopic={handleDeleteTopic}
                />
              </Box>
            )}

            {showAddModuleForm && (
              <Box ml={2} width="calc(100% - 500px)">
                <AddModuleDialog onClose={handleCloseAddModuleForm} />
              </Box>
            )}

            {showAddChapterForm && (
              <Box ml={2} width="calc(100% - 500px)">
                <AddChapterDialog
                  onClose={handleCloseAddChapterForm}
                  moduleId={selectedModuleId}
                />
              </Box>
            )}

            {showCreateModule && (
              <Box ml={2} width="calc(100% - 500px)">
                <CreateModule
                  open={showCreateModule}
                  module={selectedModule}
                  onSave={handleModuleUpdate}
                  onClose={handleCloseCreateModule}
                />
              </Box>
            )}

            {showCreateLesson && (
              <Box ml={2} width="calc(100% - 500px)">
                <CreateLesson
                  open={showCreateLesson}
                  lesson={selectedLesson}
                  onSave={handleLessonUpdate}
                  onClose={handleCloseCreateLesson}
                />
              </Box>
            )}
          </div>
        </Box>
      )}
      <Modal open={previewSelection} onClose={handlePreviewSelection}>
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            height: '28%',
            width: '35%',
            transform: 'translate(-50%, -50%)',

            backgroundColor: `${palette.lightGray}`,
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Shadow effect
            textAlign: 'center',
          }}
        >
          <h2
            sx={{
              marginBottom: '20px',
              fontSize: '1.5rem',
              color: `${palette.primary}`,
            }}
          >
            Choose Preview Option
          </h2>
          <Button
            onClick={handlePreview}
            sx={{
              textTransform: 'none',
              width: '40%',
              padding: '10px 0',
              margin: '0.5rem',
              backgroundColor: `${palette.primary}`,
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              fontSize: '1rem',
              transition: 'background-color 0.3s ease',
              '&:hover': {
                backgroundColor: `${palette.white}`,
                border: `2px solid  ${palette.primary}`,
                color: `${palette.primary}`,
                fontWeight: 'bold',
              },
            }}
          >
            Complete Preview
          </Button>
          <Button
            onClick={handleParticularPreview}
            sx={{
              textTransform: 'none',
              margin: '0.5rem',
              width: '50%',
              padding: '10px 0',
              backgroundColor: `${palette.primary}`,
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              fontSize: '1rem',
              transition: 'background-color 0.3s ease',
              '&:hover': {
                backgroundColor: `${palette.white}`,
                border: `2px solid  ${palette.primary}`,
                color: `${palette.primary}`,
                fontWeight: 'bold',
              },
            }}
          >
            Selected Preview
          </Button>
        </Box>
      </Modal>
      <Footer />
    </>
  );
};

export default CourseModules;
