// course builder > all courses
import React, { useState, useEffect } from 'react';
import { ButtonCont } from '../../styles/Courses/CourseBuilderStyles';
import { PageContainer } from '../../styles/Common/CommonStyles';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  TextField,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Tooltip,
  Typography,
  Link,
  Divider,
  Tabs,
  Tab,
  Select,
  Box,
  MenuItem,
} from '@mui/material';
import PeopleIcon from '@mui/icons-material/People'; // Replace PersonIcon import with PeopleIcon

import PersonIcon from '@mui/icons-material/Person';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DiscountIcon from '@mui/icons-material/Discount';

import SettingsIcon from '@mui/icons-material/Settings';
import BrushOutlinedIcon from '@mui/icons-material/BrushOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import { truncateText } from '../Utilities/TextAppendEllipsis';
import { palette } from '../../styles/palette';
import '../../assets/coursebuilder.css';
import CommonButton from '../Utilities/CommonButton';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourseInSlice } from '../../features/courses/addCourseDetailsSlice';
import Loader from '../Utilities/Loader';
import MyCourses from './MyCourses';
import { landingPagetemplate } from './landingPageTemplate';
import backgroundImageforBox1 from '../../images/2539_Large.jpg';

// useEffect(() => {
//   if (template) {
//     const populatedTemplate = template
//       // .replace("${pageTitle}", pageTitle)
//       .replace("${companyName}", "leapot technologies")
//       // .replace("${curriculum}", generateCurriculumHTML());

//     setTemplate(populatedTemplate);  // Update the template with dynamic content
//   }
// }, [template]);

const StyledCard = styled(Card)`
  width: 100%;
  height: 400px; // fixed height
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:hover {
    transform: scale(1.05);
  }
`;
const StyledTabs = styled(Tabs)({
  backgroundColor: '#f5f5f5',
  marginTop: '-27px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',

  '& .MuiTab-root': {
    minWidth: 100,
    textTransform: 'none',
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#1890ff',
  },
});

const StyledTab = styled(Tab)({
  textTransform: 'none',
  fontWeight: 'bold',
  '&:hover': {
    color: '#40a9ff',
    opacity: 1,
  },
});
import { fetchMyCourses } from '../../features/courses/addCourseDetailsSlice';
import axios from 'axios';
import { People } from '@material-ui/icons';
import Footer from '../Landing/Footer';

const CourseBuilder = ({ userProfile }) => {
  const [defualtTemplate, setDefaultTemplate] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(false);
  const [hasFetchedCourses, setHasFetchedCourses] = useState(false);
  const [companyName, setCompanyName] = useState('My Company');
  const API_URL = process.env.REACT_APP_URL;
  const { coursesList, loading } =
    useSelector((state) => state.addCourseDetails) || {};
  const handleSelectCourse = () => {
    navigate('/CourseBuilder/SelectCourseCard');
    console.log('hello');
  };
  const { users } = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchCourse = async () => {
      console.log('fetch course calling');
      await dispatch(fetchCourseInSlice(users.user));
      console.log('checking', users.user);
      setHasFetchedCourses(true); // Mark as fetched
    };

    if (coursesList.length === 0 && !hasFetchedCourses) {
      fetchCourse();
    }
  }, [dispatch, coursesList?.length, hasFetchedCourses]);

  useEffect(() => {
    const fetchCourse = async (user) => {
      console.log(user, 'user');
      await dispatch(fetchMyCourses(user));
    };

    fetchCourse(users.user);
  }, []);

  // // Local state for search results
  const [searchResultList, setSearchResultList] = useState([]);
  const [backupCoursesList, setBackupCoursesList] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState('title');

  const open = Boolean(filterAnchorEl);

  useEffect(() => {
    const sortedCoursesList = [...coursesList].sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
    );
    setSearchResultList(sortedCoursesList);
    setBackupCoursesList(sortedCoursesList);
    console.log('checking');
  }, [coursesList]);

  const handleSearchTextChange = (e) => {
    let searchText = e.target?.value?.toLowerCase();

    if (searchText === '') {
      setSearchResultList([...backupCoursesList]);
    } else {
      const filteredCourses = backupCoursesList.filter((course) => {
        if (selectedFilter === 'title') {
          return course?.generalInformation?.title
            ?.toLowerCase()
            .includes(searchText);
        } else if (selectedFilter === 'instructor') {
          return course?.generalInformation?.instructorName?.some(
            (instructor) =>
              `${instructor?.firstname?.toLowerCase()} ${instructor?.lastname?.toLowerCase()}`.includes(
                searchText,
              ),
          );
        } else if (selectedFilter === 'developer') {
          return (
            course?.generalInformation?.developerName &&
            typeof course.generalInformation.developerName === 'string' &&
            course.generalInformation.developerName
              .toLowerCase()
              .includes(searchText)
          );
        } else if (selectedFilter === 'published') {
          return course?.generalInformation?.isPublished;
        } else if (selectedFilter === 'unpublished') {
          return !course?.generalInformation?.isPublished;
        }
        return false;
      });
      setSearchResultList(filteredCourses);
    }
  };
  // In your component or helper function:
  const calculateTimeAgo = (creationDate) => {
    const currentTime = new Date();
    const courseCreatedTime = new Date(creationDate);
    const timeDifference = currentTime - courseCreatedTime;

    const hoursDifference = timeDifference / (1000 * 60 * 60);
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

    if (hoursDifference < 24) {
      return `${Math.floor(hoursDifference)} hours ago`;
    } else if (daysDifference < 30) {
      return `${Math.floor(daysDifference)} days ago`;
    } else {
      const monthsDifference = Math.floor(daysDifference / 30);
      return `${monthsDifference} ${monthsDifference === 1 ? 'month' : 'months'} ago`;
    }
  };
  const calculateDaysAgo = (date) => {
    const currentDate = new Date();
    const createdDate = new Date(date);
    const timeDifference = currentDate - createdDate;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference;
  };

  const handleNavigate = (course) => {
    if (course?.generalInformation?.courseStructure === 'SCORM') {
      navigate(`/DisplayScrom/${course?._id}`, {
        state: { scormUrl: course?.generalInformation.scormUrl },
      });
    } else {
      navigate(
        `/CourseBuilder/CourseModules/${course?._id}/${course?.generalInformation?.courseStructure}`,
        {
          state: {
            courseTitle: course?.generalInformation.title,
            coursePublish: course?.generalInformation?.isPublished,
          },
        },
      );
    }
  };

  if (loading) {
    return <Loader />;
  }

  const filteredCoursesList =
    users?.user?.userid?.role === 'Learner'
      ? searchResultList.filter(
          (course) => course?.generalInformation.isPublished,
        )
      : searchResultList;

  const truncatedTitleFunction = (title) => {
    return truncateText(title, 20);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleFilterChange = (e) => {
    setSelectedFilter(e.target.value);

    if (e.target.value === 'published') {
      setSearchResultList(
        backupCoursesList.filter(
          (course) => course?.generalInformation?.isPublished,
        ),
      );
    } else if (e.target.value === 'unpublished') {
      setSearchResultList(
        backupCoursesList.filter(
          (course) => !course?.generalInformation?.isPublished,
        ),
      );
    }
  };
  const getStatusStyle = (isPublished) => {
    const status = isPublished ? 'P' : 'U';
    const backgroundColor = isPublished ? 'green' : 'red';
    return { status, backgroundColor };
  };

  //--------------------------------------------------------------------------------------------landing design----------------------------------------------------
  const handleDesign = async (course) => {
    const id = course._id;
    console.log('new courseeeeeeeeee', course);
    console.log('new cccccccccccccccccccccccccccccourse id', id);
    try {
      // Check if there is a landing page connected to the course
      const checkResponse = await axios.get(
        `${API_URL}/api/course/${id}/pages`,
      );

      console.log('new Responseeeeeeeeeeeeeeeeeeeeeeeeeeee', checkResponse);
      const { pagesPresent, pages } = checkResponse.data;

      console.log(pagesPresent, 'new page is present? ');

      // Find if there is a page with title "landing page"
      const landingPage = pages.find(
        (page) => page.pageTitle === 'landing page',
      );
      console.log('new landing pageeeeeeeeeeeeeeeeeeeeeeeee', landingPage);
      if (pagesPresent) {
        if (landingPage) {
          console.log('new  template iddddddddddddddddd', pages[0].page_id);
          navigate(`/SiteBuilderB/${pages[0].page_id}`);
        }
        // Navigate to the existing landing page
      } else {
        setLoading(true);
        const companyName = 'Leapot Technologies';
        console.log('new entered into the making');
        const pageName = course.generalInformation.title;
        console.log('new page name', pageName);
        const pageType = 'Course Page';
        const htmlTemplate = landingPagetemplate(companyName, pageName);
        console.log('new html', htmlTemplate);
        const pageTemplate = landingPagetemplate(companyName, pageName);

        console.log('new pagetemplate', pageTemplate);
        const cssTemplate = `body {
  margin: 0;
  padding:0;
}`;
        console.log('new css temp', cssTemplate);

        const template = {
          pageName,
          pageType,
          categoryId: 'courseBuilderLanding',
          pageTemplate: pageTemplate,
          htmlTemplate: htmlTemplate,
          cssTemplate: cssTemplate,
          userName: 'current User',
        };
        console.log('abc new template', template);

        // Create a new landing page

        const createResponse = await axios.post(
          `${API_URL}/api/addTemplates`,
          template,
        );

        console.log('new response from the add page api', createResponse);
        const templateID = createResponse.data.template._id;
        console.log('newly CreatedTemplateID', templateID);

        const pageTitle = 'landing page';
        const sendingData = { pageTitle: pageTitle, page_id: templateID };
        // const createdPage = createResponse.data.updatedCourse.generalInformation.pages.find(page => page.pageTitle === 'landing page');

        // Add the new landing page to the course
        const fetchedResponse = await axios.put(
          `${API_URL}/api/course/${id}/addPages`,
          { pages: [sendingData] },
        );

        console.log(
          ' new fetched response from the saving the page in the courses !',
          fetchedResponse,
        );
        setLoading(false);
        // Navigate to the new landing page
        navigate(`/SiteBuilderB/${templateID}`);
      }
    } catch (error) {
      console.error('Error handling design:', error);
      // Handle the error as needed
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',

          // Ensures the box takes the full viewport height
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <PageContainer>
            <StyledTabs
              value={tabValue}
              onChange={handleTabChange}
              sx={{ marginBottom: 2 }}
            >
              <StyledTab label="All Courses" />
              <StyledTab label="My Courses" />
            </StyledTabs>
            {tabValue === 1 && <MyCourses />}

            {tabValue === 0 && (
              <>
                {!loading || !Loading ? (
                  <section className="course-builder">
                    <Container fluid>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={3}>
                          <Select
                            fullWidth
                            value={selectedFilter}
                            onChange={handleFilterChange}
                          >
                            <MenuItem value="title">
                              Search by Course Title
                            </MenuItem>
                            <MenuItem value="instructor">
                              Search by Instructor Name
                            </MenuItem>
                            <MenuItem value="developer">
                              Search by Course Developer Name
                            </MenuItem>
                            <MenuItem value="published">
                              Display Published Courses
                            </MenuItem>
                            <MenuItem value="unpublished">
                              Display Unpublished Courses
                            </MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <TextField
                            id="outlined-basic"
                            placeholder="Search"
                            variant="outlined"
                            fullWidth
                            onChange={(e) => handleSearchTextChange(e)}
                            disabled={
                              selectedFilter === 'published' ||
                              selectedFilter === 'unpublished'
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          sx={{ display: 'flex', justifyContent: 'flex-end' }}
                          xs={12}
                          sm={2}
                        >
                          {users?.user?.userid?.role === 'Admin' && (
                            <ButtonCont
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                paddingTop: '2px',
                              }}
                            >
                              <CommonButton
                                onClick={handleSelectCourse}
                                label="Create Course"
                              />
                            </ButtonCont>
                          )}
                        </Grid>

                        {filteredCoursesList?.map((course) => {
                          const timeAgo = calculateTimeAgo(course?.createdAt);
                          const instructorNamesArray =
                            course?.generalInformation?.instructorName?.map(
                              (instructor) =>
                                `${instructor.firstname} ${instructor.lastname}`,
                            );
                          const instructorNames =
                            instructorNamesArray.length > 1
                              ? `${instructorNamesArray[0]}...`
                              : instructorNamesArray[0];
                          const { status, backgroundColor } = getStatusStyle(
                            course?.generalInformation?.isPublished,
                          );
                          const tooltipTitle = course?.generalInformation
                            ?.isPublished
                            ? 'Published'
                            : 'Unpublished';
                          return (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={4}
                              lg={4}
                              xl={4}
                              key={course?._id}
                            >
                              <StyledCard
                                className="course-card"
                                sx={{
                                  backdropFilter: 'blur(10px)',
                                  backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                  backgroundImage:
                                    'linear-gradient(to bottom right, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.1))',
                                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                                  borderRadius: '16px',
                                  transition:
                                    'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                                  '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.4)',
                                  },
                                }}
                              >
                                {course.isNew && (
                                  <Box
                                    sx={{
                                      position: 'absolute',
                                      top: 0,
                                      left: 0,
                                      backgroundColor: 'red',
                                      color: 'white',
                                      borderRadius: '0 8px 8px 0',
                                      padding: '4px 8px',
                                      fontWeight: 'bold',
                                      fontSize: '12px',
                                      zIndex: 1,
                                    }}
                                  >
                                    New
                                  </Box>
                                )}

                                {/* Paid, Discount, and Free Labels on Right */}
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                    padding: '4px 8px',
                                    zIndex: 1,
                                  }}
                                >
                                  {/* Paid Label */}
                                  {course?.isPaidCourse && (
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: 'green',
                                        color: 'white',
                                        padding: '4px 6px',
                                        borderRadius: '8px',
                                        fontSize: '12px',
                                      }}
                                    >
                                      <AttachMoneyIcon
                                        sx={{ fontSize: '14px', mr: '4px' }}
                                      />
                                      Paid
                                    </Box>
                                  )}

                                  {/* Discount Label */}
                                  {course?.pricingDetails?.discount > 0 && (
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: 'orange',
                                        color: 'white',
                                        padding: '4px 6px',
                                        borderRadius: '8px',
                                        fontSize: '12px',
                                      }}
                                    >
                                      <DiscountIcon
                                        sx={{ fontSize: '14px', mr: '4px' }}
                                      />
                                      {`${course.pricingDetails.discount}% off`}
                                    </Box>
                                  )}

                                  {/* Free Label for Non-Paid Courses */}
                                  {!course?.isPaidCourse && (
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: 'green', // Changed from blue to green
                                        color: 'white',
                                        padding: '4px 6px',
                                        borderRadius: '8px',
                                        fontSize: '12px',
                                      }}
                                    >
                                      Free
                                    </Box>
                                  )}
                                </Box>

                                {/* Course Image */}
                                <Tooltip title="Click to add Chapter">
                                  <Link onClick={() => handleNavigate(course)}>
                                    <div
                                      className="image-container"
                                      style={{
                                        height: '230px',
                                        borderRadius: '16px 16px 0 0',
                                        overflow: 'hidden',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        background: course?.generalInformation
                                          ?.bannerImage
                                          ? `url(${course?.generalInformation.bannerImage}) no-repeat center center`
                                          : 'skyblue',
                                        backgroundSize: 'cover',
                                      }}
                                    >
                                      {!course?.generalInformation
                                        ?.bannerImage &&
                                        truncatedTitleFunction(
                                          course?.generalInformation.title,
                                        )}
                                    </div>
                                  </Link>
                                </Tooltip>

                                <CardContent sx={{ pb: 0 }}>
                                  <Grid container>
                                    <Grid item xs={10}>
                                      <Link
                                        underline="hover"
                                        onClick={() => handleNavigate(course)}
                                      >
                                        <Tooltip
                                          title={
                                            course?.generalInformation.title
                                          }
                                        >
                                          <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            sx={{ mb: 1 }}
                                          >
                                            {truncatedTitleFunction(
                                              course?.generalInformation.title,
                                            )}
                                          </Typography>
                                        </Tooltip>
                                      </Link>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={2}
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                      }}
                                    >
                                      <Tooltip title={tooltipTitle}>
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: 24,
                                            height: 24,
                                            backgroundColor: backgroundColor,
                                            borderRadius: '50%',
                                            color: `${palette.white}`,
                                          }}
                                        >
                                          <Typography variant="body2">
                                            {status}
                                          </Typography>
                                        </Box>
                                      </Tooltip>
                                    </Grid>
                                  </Grid>

                                  {/* Instructor Information */}
                                  <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                      <Typography
                                        variant="subtitle1"
                                        color="textSecondary"
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          mt: 1,
                                        }}
                                      >
                                        <Tooltip title="Instructor">
                                          <People
                                            color="textSecondary"
                                            sx={{ mr: 1 }}
                                          />
                                        </Tooltip>
                                        {instructorNames}
                                      </Typography>
                                    </Grid>
                                  </Grid>

                                  {/* Enrolled Learners */}
                                  <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                      >
                                        Enrolled Learners:
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={4}
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                      }}
                                    >
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        mb={2}
                                      >
                                        {timeAgo}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </CardContent>

                                <Divider />

                          <CardActions>
                            <Grid container spacing={2}>
                              {(users?.user?.userid?.role === 'Admin' ||
                                users?.user?.userid?.role === 'Instructor' ||
                                users?.user?.userid?.role ===
                                  'Course Reviewer') && (
                                <>
                                  <Grid item xs={2}>
                                    <Tooltip title="Details">
                                      <IconButton
                                        sx={{ color: palette.THEME_THREE }}
                                        size="small"
                                      >
                                        <InfoOutlinedIcon
                                          onClick={() =>
                                            navigate(
                                              `/CourseBuilder/CourseDetails/${course?._id}`,
                                              {
                                                state: course,
                                              },
                                            )
                                          }
                                        />
                                      </IconButton>
                                    </Tooltip>
                                    <Divider orientation="vertical" />
                                  </Grid>
                                  <Grid item xs={2}>
                                    <Tooltip title="Course Settings">
                                      <IconButton
                                        size="small"
                                        sx={{ color: palette.THEME_THREE }}
                                        onClick={() =>
                                          navigate(
                                            `/CourseBuilder/CourseSettings/${course?._id}`,
                                          )
                                        }
                                      >
                                        <SettingsIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <Divider orientation="vertical" flexItem />
                                  </Grid>
                                  <Grid item xs={2}>
                                    <Tooltip title="Landing Page Design">
                                      <IconButton
                                        size="small"
                                        sx={{ color: palette.THEME_THREE }}
                                        onClick={() =>handleDesign(course)
                                          
                                        }
                                      >
                                        <BrushOutlinedIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <Divider orientation="vertical" flexItem />
                                  </Grid>
                                  <Grid item xs={2}>
                                    <Tooltip title="Learners">
                                      <IconButton
                                        size="small"
                                        sx={{ color: palette.THEME_THREE }}
                                        onClick={() =>
                                          navigate(
                                            `/LearnersList/${course?._id}`,
                                          )
                                        }
                                      >
                                        <PeopleOutlineOutlinedIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <Divider orientation="vertical" flexItem />
                                  </Grid>
                                  <Grid item xs={2}>
                                    <Tooltip title="Course Preview">
                                      <IconButton
                                        size="small"
                                        sx={{ color: palette.THEME_THREE }}
                                        onClick={() =>
                                          navigate(
                                            `/CourseBuilder/CourseModules/preview/${course?._id}/${course?.generalInformation?.courseStructure}`,
                                            {
                                              state: {
                                                courseTitle:
                                                  course?.generalInformation
                                                    .title,
                                              },
                                            },
                                          )
                                        }
                                      >
                                        <VisibilityOutlinedIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <Divider orientation="vertical" flexItem />
                                  </Grid>
                                  <Grid item xs={2}>
                                    <Tooltip title="Course Discussions">
                                      <IconButton
                                        size="small"
                                        sx={{ color: palette.THEME_THREE }}
                                        onClick={() =>
                                          navigate(
                                            `/CourseBuilder/CourseDiscussion/${course?._id}`,
                                          )
                                        }
                                      >
                                        <ChatBubbleOutlineOutlinedIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                </>
                              )}

                                    {users?.user?.userid?.role ===
                                      'Learner' && (
                                      <>
                                        <Grid item xs={2}>
                                          <Tooltip title="Details">
                                            <IconButton
                                              sx={{
                                                color: palette.THEME_THREE,
                                              }}
                                              size="small"
                                            >
                                              <InfoOutlinedIcon
                                                onClick={() =>
                                                  navigate('/CourseDetails', {
                                                    state: course,
                                                  })
                                                }
                                              />
                                            </IconButton>
                                          </Tooltip>
                                          <Divider orientation="vertical" />
                                        </Grid>
                                        <Grid item xs={2}>
                                          <Tooltip title="Course Discussions">
                                            <IconButton
                                              size="small"
                                              sx={{
                                                color: palette.THEME_THREE,
                                              }}
                                              onClick={() =>
                                                navigate(
                                                  `/CourseDiscussion/${course?._id}`,
                                                )
                                              }
                                            >
                                              <ChatBubbleOutlineOutlinedIcon />
                                            </IconButton>
                                          </Tooltip>
                                        </Grid>
                                      </>
                                    )}
                                  </Grid>
                                </CardActions>
                              </StyledCard>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Container>
                  </section>
                ) : (
                  <Loader />
                )}
              </>
            )}
          </PageContainer>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userProfile: state.userProfile,
  };
};

export default connect(mapStateToProps)(CourseBuilder);
