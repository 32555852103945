// src/redux/courseModulesSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedModule: null,
  showCreateModule: false,
  showAddModuleForm: false,
  showAddChapterForm: false,
  openTopicDialog: false,
  showCourseSettings: false,
  showCreateLesson: false,
  selectedItem: null,
  displayContent: false,
};

const courseModulesSlice = createSlice({
  name: 'courseModules',
  initialState,
  reducers: {
    setSelectedModule: (state, action) => {
      state.selectedModule = action.payload;
    },
    setShowCreateModule: (state, action) => {
      state.showCreateModule = action.payload;
    },
    setShowAddModuleForm: (state, action) => {
      state.showAddModuleForm = action.payload;
    },
    setShowAddChapterForm: (state, action) => {
      state.showAddChapterForm = action.payload;
    },
    setOpenTopicDialog: (state, action) => {
      state.openTopicDialog = action.payload;
    },
    setShowCourseSettings: (state, action) => {
      state.showCourseSettings = action.payload;
    },
    setShowCreateLesson: (state, action) => {
      state.showCreateLesson = action.payload;
    },
    setSelectedItem: (state, action) => {
      state.selectedItem = action.payload;
    },
    setDisplayContent: (state, action) => {
      state.displayContent = action.payload;
    },
  },
});

export const {
  setSelectedModule,
  setShowCreateModule,
  setShowAddModuleForm,
  setShowAddChapterForm,
  setOpenTopicDialog,
  setShowCourseSettings,
  setShowCreateLesson,
  setSelectedItem,
  setDisplayContent,
} = courseModulesSlice.actions;

export default courseModulesSlice.reducer;
