import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Tooltip, IconButton, Grid, Typography, Button } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { palette } from '../../../styles/palette';
import JoditEditor from 'jodit-react';
import { useDispatch, useSelector } from 'react-redux'; // Correct import for useSelector
import { addItem } from '../../../features/courses/addCourseDetailsSlice';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import {
  DeleteBox,
  StyledTextField,
  ButtonContainer,
  BackButtonContainer,
  HeaderContainer,
} from '../../../styles/Courses/CourseModuleStyles';

const DiscardButton = styled(Button)`
  color: ${palette.primary};
  border-color: ${palette.gray};
  margin-right: 10px;
  width: 180px;
`;

const SaveButton = styled(Button)`
  background-color: ${palette.primary};
  color: ${palette.white};
`;

const EditorContainer = styled('div')`
  margin-right: 10px;
  margin-left: 10px;
`;

const InsertText = ({
  onClose,
  lessonId,
  selectedItem,
  moduleId,
  handleDeleteTopic,
}) => {
  const { id, courseStructure } = useParams();
  const editor = useRef(null);
  const[saved ,setSaved] = useState(true);
  const dispatch = useDispatch();

  // Fetch data from Redux slice
  // const { modules } =
  //   useSelector((state) => state.addCourseDetails.courseDetails) || {};

  // // Find the specific module and lesson
  // const currentModule = modules?.find((module) => module.id === moduleId);
  // const currentLesson = currentModule?.lessons?.find(
  //   (lesson) => lesson.id === lessonId,
  // );
  // const selectedItem = currentLesson?.items?.find(
  //   (item) => item.contentType === 'Text',
  // );

  // Log data to see if it's being pulled correctly
  useEffect(() => {}, []);

  // Initialize formData with values from Redux slice if available
  const [formData, setFormData] = useState({
    moduleId: selectedItem?.moduleId || '',
    lessonId: selectedItem?.lessonId || '',
    title: selectedItem?.title || '',
    textDescription: selectedItem?.textDescription || '',
    id: selectedItem?.id || '',
    courseStructure: courseStructure || '',
    contentType: selectedItem?.contentType || 'Text',
    preview:selectedItem?.preview||false,

  });

  // Update formData once selectedItem is available from Redux slice
  useEffect(() => {
    if (selectedItem) {
      setFormData({
        moduleId: selectedItem?.moduleId || '',
        lessonId: selectedItem?.lessonId || '',
        title: selectedItem?.title || '',
        textDescription: selectedItem?.textDescription || '',
        id: selectedItem?.id || '',
        courseStructure: courseStructure || '',
        contentType: selectedItem?.contentType || 'Text',
        preview:selectedItem?.preview||false,
      });
    }
  }, [selectedItem, courseStructure]);

  // Handle editor content change
  const handleEditorChange = (newContent) => {
    setFormData({ ...formData, textDescription: newContent });
  };

  const config = useMemo(
    () => ({
      placeholder: 'Enter the text description',
      height: 300,
      readonly: false,
    }),
    [],
  );

  const handleOnClose = () => {
    const data = { ...formData, preview: false}
    console.log("abc data from go back",data)
    if(saved && formData.textDescription){
      onClose();

    }
    else{
      dispatch(addItem(data));
      onClose()
    }

   

  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
      lessonId: lessonId,
      moduleId: moduleId,
      id: selectedItem?.id ||generateRandomNumber(),
      courseStructure: courseStructure,
      contentType: 'Text',
    });
  };

  const generateRandomNumber = () => {
    const min = 100000;
    const max = 999999;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const handleDelete = () => {
    handleDeleteTopic(
      selectedItem?.moduleId,
      selectedItem?.lessonId,
      selectedItem?.id,
    );
  };

  const handleSave = async () => {
    if(formData.textDescription)
      {
        
        
        const data = {...formData, preview:true}
        console.log('abc from  the submit',data);
        
        await dispatch(addItem(data));
      } else{
        
        const data = { ...formData, preview: false}
        console.log("abc data from the save for the draft",data)
    await dispatch(addItem(data));
      } 
    
      setSaved(true);
      onClose();
      };

  return (
    <div>
      <HeaderContainer>
        <BackButtonContainer
          container
          alignItems="center"
          spacing={1}
          sx={{ mt: 1, mb: 2 }}
        >
          <Grid item>
            <Tooltip title="Go Back">
              <IconButton
                size="small"
                onClick={handleOnClose}
                sx={{ color: `${palette.THEME_THREE}` }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item sx={{ mt: 1 }}>
            <Typography variant="h5" gutterBottom>
              Text
            </Typography>
          </Grid>
        </BackButtonContainer>
        <ButtonContainer>
          <DiscardButton variant="outlined">Discard Changes</DiscardButton>
          {formData.title? <SaveButton variant="contained" onClick={handleSave}>
            Save
          </SaveButton>:<Button disabled>Save</Button>}
        
        </ButtonContainer>
      </HeaderContainer>
      <EditorContainer>
        <StyledTextField
          variant="outlined"
          label="Title"
          name="title"
          style={{ width: '100%', marginBottom: '10px' }}
          value={formData.title}
          onChange={handleChange}
        />
        <JoditEditor
          ref={editor}
          value={formData.textDescription}
          onChange={handleEditorChange}
          config={config}
        />
      </EditorContainer>
      <DeleteBox>
        <h4 style={{ marginRight: '10px' }}>
          Are you sure you want to delete this topic? This action is
          irreversible.
        </h4>
        <Button variant="contained" color="error" onClick={handleDelete}>
          Delete Topic
        </Button>
      </DeleteBox>
    </div>
  );
};

export default InsertText;
