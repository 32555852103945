import { palette } from '../palette';





import styled from 'styled-components';




import tw from 'twin.macro';


export const MainContainer = styled.div`
  ${tw`flex justify-start items-center`}
  background:${palette.primary};
  z-index:70;
  overflow-x:hidden;
  
  
`;

export const InnerContainer = styled.div`
  ${tw`flex  h-[100%] w-[96vw]`}
  overflow-x:hidden;
 
`;

export const LeftPanel = styled.div`
  ${tw`w-[15%] h-[83vh] p-4 overflow-hidden pl-4`}
border-right:1px solid ${palette.primary};
 position: fixed;
 overflow-y:scroll;
 overflow-x:hidden;
  
  bottom: 0;
  right: 0;
  z-index:50;
background: ${palette.gray}
`;

export const BackButtonCont = styled.div`
  ${tw`flex justify-around items-center cursor-pointer h-10 w-[100%] `}
  
`;

export const SaveBtnCont = styled.div`
  ${tw`flex justify-start items-center`}
`;

export const BackArrow = styled.img`
  ${tw`h-4 cursor-pointer`}
`;

export const BackText = styled.p`
  ${tw`mx-1 mt-4 font-medium text-lg cursor-pointer`}
`;

export const SubContainer1 = styled.div`
  ${tw`h-10 flex justify-between mt-5`}
`;

export const Container1 = styled.div`
  ${tw`relative text-center w-[100%]`}
`;

export const BlockTabs = styled.button`
  ${tw`relative bg-white h-10 w-[100%] rounded-b-none rounded-t text-center cursor-pointer`}
  border:none;

`;

export const Container2 = styled.div`
  ${tw` w-[100%]`}
  background: ${palette.tertiary}
`;

export const EditorCont = styled.div`
  ${tw`w-[99%]`}
  overflow:hidden;
`;

export const BlockImg = styled.img`
  ${tw`w-10 object-contain cursor-pointer`}
`;
export const LayerImg = styled.img`
  ${tw`w-10 object-contain cursor-pointer`}
`;
export const EditImg = styled.img`
  ${tw`w-10 object-contain cursor-pointer`}
`;
export const SettingsImg = styled.img`
  ${tw`w-10 object-contain cursor-pointer`}
`;

export const HoverText = styled.span`
  ${tw`absolute left-0 top-full mt-1 w-max p-1 text-sm text-white bg-black rounded opacity-0 group-hover:opacity-100`}
`;
export const AddBtnCont = styled.div`
  ${tw`flex justify-center items-center flex-col`}
`;

export const AddedPageCont = styled.div`
  ${tw`flex justify-center items-center m-1 w-[92%] rounded `}
  border:1px solid ${palette.primary};
`;

export const AddPageBtn = styled.button`
  ${tw`flex justify-center text-white items-center  flex-row rounded cursor-pointer w-[92%] h-8 m-2`}
  background:${palette.primary};
  border: none;
`;

export const SaveBtn = styled.button`
  ${tw`flex justify-center text-[black] items-center ml-4  flex-row rounded cursor-pointer w-[100px] h-8 m-2`}
  background:${palette.white};
  border: none;
`;
export const AddedPageBtn = styled.button`
  ${tw`flex justify-center items-center flex-row w-[92%] bg-white rounded h-8 font-medium cursor-pointer `}
  border:none;
  color: ${palette.primary};
`;

// popup styling
export const PopupCont = styled.div`
  ${tw`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50`}
`;

export const PopupContSub = styled.div`
  ${tw`bg-white p-6 rounded-md shadow-lg`}
`;

export const PopupHeading = styled.h1`
  ${tw`text-xl font-semibold mb-4`}
`;

export const PopupFrom = styled.form`
  ${tw`space-y-4`}
`;

export const Popuplabel = styled.label`
  ${tw`block`}
`;

export const Popupinput = styled.input`
  ${tw`mt-1 p-2 border rounded w-full`}
`;

export const PopupButton = styled.button`
  ${tw`mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600`}
  border:none;
`;
