import styled from 'styled-components';
import tw from 'twin.macro';
import { palette } from '../palette';

export const Image = styled.img``;

export const TopSection = styled.div`
  background-color: ${palette.primary};
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 10px 5px;
  position: sticky;
  top: 0;
  z-index: 10000;
`;

export const ImageContainerSidebar = styled.div`
  ${tw`ml-0`}
`;

export const ImageOpen = styled.img`
  ${tw`w-40 h-11`}
  cursor: pointer;
`;

export const ImageClosed = styled.img`
  ${tw`w-7 h-7 pr-1`}
  cursor: pointer;
`;

export const Bars = styled.div`
  width: 20px;
`;

export const Section = styled.div`
  margin-top: 1px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: 0;
`;

export const Icon = styled.div`
  width: 15px;
`;

export const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${palette.white};
  padding: 5px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  margin: 0px;
`;

export const MenuTitle = styled.h1`
  display: flex;
  white-space: nowrap;
  cursor: pointer;
  font-size: 18px;
  opacity: 1;
  font-weight: 500;
`;

export const LogoProfileContainer = styled.div`
  background: ${palette.primary};
`;

export const MainContainer = styled.div`
  position: sticky;
  height: 100vh;
  top: 0;
  left: 0;
  background: ${palette.primary};
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .sidebar {
    color: ${palette.white};
    position: sticky;
    top: 0;
    overflow-y: none;
    scrollbar-width: thin;
    scrollbar-color: ${palette.primary} ${palette.lightGray};
  }

  .sidebar::-webkit-scrollbar {
    width: 20px;
    background-color: transparent;
  }

  .sidebar::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .sidebar::-webkit-scrollbar-thumb {
    background: #51c0f0;
    background-image: -webkit-linear-gradient(
      20deg,
      rgba(255, 255, 255, 0.4) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.4) 50%,
      rgba(255, 255, 255, 0.4) 75%,
      transparent 75%
    );
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  .sidebar::-webkit-scrollbar-thumb:hover {
    background-color: #30aee3;
  }

  .logo {
    font-size: 17px;
    line-height: 0;
  }
  .link {
    display: flex;
    color: ${palette.white};
    gap: 20px;
    padding: 6px 8px;

    border-right: 5px solid transparent;
    transition: 0.2s cubic-bezier(0.6, -0.1, 0.1, 0.045);
    text-decoration: none;
  }
  .link:hover {
    background: ${palette.tertiary};
    color: ${palette.white};

    font-weight: 500;

    transition: 0.01s cubic-bezier(0.3, -0.28, 0.1, 0.045);
  }
  .active {
    background: white;
    color: ${palette.primary};
    /* text-decoration: none; */
    font-weight: 600;
  }

  .link_text {
    white-space: nowrap;
    margin-top: -2px;
    cursor: pointer;
    font-size: 18px;
  }

  .menu_container {
    display: flex;
    flex-direction: column;
  }

  main {
    padding: 10px;
    padding-right: 0px;
  }
  .menu_container .link {
    padding-left: 10px;
    border-bottom: white 0.5px solid;
  }
`;
