import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import {
  StyledTab,
  StyledTabs,
} from '../../styles/Certificate/CertificateStyle';
import AllTemplates from './AllTemplates';

const ListofCourses = () => {
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

 

  const handleCardClick = (id) => {
    navigate(`/sitebuilder/${id}`);
  };

  const getBasePath = (path) => {
    const segments = path.split('/');
    return segments.length > 2 ? `/${segments[1]}/${segments[2]}` : path;
  };

  const defaultTabValue = [
    '/Sitebuilder',
    '/Sitebuilder/CoursePageTemp',
    '/Sitebuilder/DefaultPage',
    '/Sitebuilder/UserSavedTemplates',
  ].includes(location.pathname)
    ? location.pathname
    : '/Sitebuilder';

  const { t } = useTranslation();

  return (
    <>
      <StyledTabs value={defaultTabValue}>
        <StyledTab
          // label={t("notification.createLabel")}
          label="All Pages"
          value="/Sitebuilder"
          component={Link}
          to="/Sitebuilder"
        />
        <StyledTab
          label="Course Pages"
          value="/Sitebuilder/CoursePageTemp"
          component={Link}
          to="/Sitebuilder/CoursePageTemp"
        />
        <StyledTab
          label="Default Pages"
          value="/Sitebuilder/DefaultPage"
          component={Link}
          to="/Sitebuilder/DefaultPage"
        />
        <StyledTab
          label="User Saved Pages "
          value="/Sitebuilder/UserSavedTemplates"
          component={Link}
          to="/Sitebuilder/UserSavedTemplates"
        />
      </StyledTabs>
    </>
  );
};

export default ListofCourses;
