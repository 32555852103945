import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLearnersByCourse } from '../../features/courses/LearnersRegistrationSlice';
import { PageContainer } from '../../styles/Common/CommonStyles';
import {
  CreateContent,
  Heading,
} from '../../styles/Courses/CourseBuilderStyles';
import {
  Grid,
  IconButton,
  Tooltip,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Typography,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { palette } from '../../styles/palette';
import TableUtilityLib from '../Utilities/TableUtilityLib'; // Import the reusable Table component
import { useTranslation } from 'react-i18next';
import { CardImg } from '../../styles/Calendar/CalendarStyle';
import Footer from '../Landing/Footer';
import { Box } from '@mui/system';

const LearnersList = () => {
  const navigate = useNavigate();
  const { courseId } = useParams();
  const dispatch = useDispatch();
  const { learners, status } = useSelector((state) => state.learners);
  const { t } = useTranslation();
  const [filteredLearners, setFilteredLearners] = useState([]);
  const [filterType, setFilterType] = useState('total'); // Default to 'total' learners

  useEffect(() => {
    if (courseId) {
      dispatch(fetchLearnersByCourse(courseId));
    }
  }, [courseId, dispatch]);

  useEffect(() => {
    // Update the filtered learners based on the selected filter type
    switch (filterType) {
      case 'total':
        setFilteredLearners(learners?.users?.total || []);
        break;
      case 'active':
        setFilteredLearners(learners?.users?.active || []);
        break;
      case 'pending':
        setFilteredLearners(learners?.users?.pending || []);
        break;
      default:
        setFilteredLearners(learners?.users?.total || []);
    }
  }, [learners, filterType]);

  // Define the columns for the table, excluding 'status' and 'hasPaid'
  const columns = [
    {
      name: t('calender.firstName'), // Translated text for 'First Name'
      selector: (row) => row.firstname,
      sortable: true,
    },
    {
      name: t('calender.lastName'), // Translated text for 'Last Name'
      selector: (row) => row.lastname,
      sortable: true,
    },
    {
      name: t('calender.Email'), // Translated text for 'Email'
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: t('calender.joiningDate'), // Translated text for 'Joining Date'
      selector: (row) => new Date(row.createdAt).toLocaleDateString(),
      sortable: true,
    },
  ];

  return (
    <>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <PageContainer>
            <CreateContent>
              <Tooltip title="Go Back">
                <IconButton
                  size="small"
                  onClick={() => navigate('/CourseBuilder')}
                  sx={{ color: `${palette.THEME_THREE}` }}
                >
                  <ArrowBackIosIcon />
                </IconButton>
              </Tooltip>
              {/* <Heading>
              {t('calender.learnersEnrolledLabel')} (Total learners:{' '}
              {learners?.users?.total?.length || 0})
              </Heading> */}
            </CreateContent>

            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} lg={3.8} sx={{ ml: 2 }}>
                <Tooltip title={t('Count of total registrations', {})}>
                  <Card
                    sx={{
                      display: 'flex',
                      width: '100%',
                      height: 110,
                      backgroundColor: `${palette.cardBgColor}`,
                      cursor: 'pointer',
                      '&:hover': {
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                      },
                    }}
                    onClick={() => setFilterType('total')}
                  >
                    <CardContent sx={{ flex: '0 0 100px', padding: 1 }}>
                      <CardImg src={require('../../images/student.jpg')} />
                    </CardContent>
                    <CardContent sx={{ padding: 1 }}>
                      <Typography
                        sx={{ color: 'blue' }}
                        variant="h5"
                      ></Typography>
                      <Typography variant="body1">
                        {t('calender.totalRegistrationLabel')}
                      </Typography>
                    </CardContent>
                  </Card>
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3.8}>
                <Tooltip title={t('Count of paid and registered', {})}>
                  <Card
                    sx={{
                      display: 'flex',
                      width: '100%',
                      height: 110,
                      backgroundColor: `${palette.cardBgColor}`,
                      cursor: 'pointer',
                      '&:hover': {
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                      },
                    }}
                    onClick={() => setFilterType('active')}
                  >
                    <CardContent sx={{ flex: '0 0 100px', padding: 1 }}>
                      <CardImg src={require('../../images/registered.png')} />
                    </CardContent>
                    <CardContent sx={{ padding: 1 }}>
                      <Typography
                        sx={{ color: 'blue' }}
                        variant="h5"
                      ></Typography>
                      <Typography variant="body1">
                        {t('calender.activeRegistrationLabel')}
                      </Typography>
                    </CardContent>
                  </Card>
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3.8}>
                <Tooltip title={t('Awaiting payment completion', {})}>
                  <Card
                    sx={{
                      display: 'flex',
                      width: '100%',
                      height: 110,
                      backgroundColor: `${palette.cardBgColor}`,
                      cursor: 'pointer',
                      '&:hover': {
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                      },
                    }}
                    onClick={() => setFilterType('pending')}
                  >
                    <CardContent sx={{ flex: '0 0 100px', padding: 1 }}>
                      <CardImg
                        src={require('../../images/paymentpending.png')}
                      />
                    </CardContent>
                    <CardContent sx={{ padding: 1 }}>
                      <Typography
                        sx={{ color: 'blue' }}
                        variant="h5"
                      ></Typography>
                      <Typography variant="body1">
                        {t('calender.pendingRegistrationLabel')}
                      </Typography>
                    </CardContent>
                  </Card>
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <CreateContent>
                  <Tooltip title="Go Back"></Tooltip>
                  {/* <Heading>
              {t('calender.learnersEnrolledLabel')} (Total learners:{' '}
              {learners?.users?.total?.length || 0})
              </Heading> */}
                </CreateContent>
              </Grid>

              {/* Filter Menu */}
              {/* <Grid item xs={12} sx={{ px: 4 }}>
          <ButtonGroup variant="contained" aria-label="outlined button group">
          <Button
          onClick={() => setFilterType('total')}
          variant={filterType === 'total' ? 'contained' : 'outlined'}
          >
          Total Registrations ({learners?.totalRegistrations || 0})
          </Button>
          <Button
          onClick={() => setFilterType('active')}
          variant={filterType === 'active' ? 'contained' : 'outlined'}
          >
          Active Registrations ({learners?.activeRegistrations || 0})
          </Button>
          <Button
          onClick={() => setFilterType('pending')}
          variant={filterType === 'pending' ? 'contained' : 'outlined'}
          >
          Pending for Payment ({learners?.pendingRegistrations || 0})
          </Button>
          </ButtonGroup>
          </Grid> */}

              {/* Learners Table */}
              <Grid item xs={12} sx={{ px: 4 }}>
                {status === 'loading' ? (
                  <div>Loading...</div>
                ) : status === 'failed' ? (
                  <div>Error fetching data</div>
                ) : (
                  <TableUtilityLib
                    title={t('calender.learnersListTitle')} // Dynamic title based on course/event
                    columns={columns}
                    data={filteredLearners} // Pass the filtered learners data to the table
                    selectableRows
                    selectableRowsHighlight
                    customRowStyles={{
                      rows: {
                        style: {
                          backgroundColor: 'transparent',
                          cursor: 'pointer',
                        },
                      },
                      cells: {
                        style: {
                          display: 'none', // Hide checkboxes
                        },
                      },
                    }}
                    onRowClicked={(row) => console.log('Row clicked:', row)}
                  />
                )}
              </Grid>
            </Grid>
          </PageContainer>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default LearnersList;
