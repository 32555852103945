import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchLearnersByCourse = createAsyncThunk(
  'learners/fetchLearnersByCourse',
  async (courseId, thunkAPI) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/course/${courseId}`,
        console.log(' checking fetchlearnerbycourse checking', courseId),
      );
      console.log(' checking fetchlearnerbycourse', response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

const learnersSlice = createSlice({
  name: 'learners',
  initialState: {
    learners: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLearnersByCourse.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLearnersByCourse.fulfilled, (state, action) => {
        state.learners = action.payload;
        state.loading = false;
      })
      .addCase(fetchLearnersByCourse.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default learnersSlice.reducer;
