import styled from 'styled-components';
import tw from 'twin.macro';
import { palette } from '../palette';

export const Details = styled.div`
  ${tw`m-4`}
`;

export const Heading = styled.div`
  ${tw`text-base md:text-xl px-3 text-stone-700`}
`;
export const Head = styled.div``;

export const ImgSection = styled.div`
  ${tw`flex flex-col items-center ml-6 p-16`}
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 10px 0px;
`;

export const Info = styled.div`
  ${tw``}
`;

export const Profile = styled.div`
  ${tw`flex flex-col m-auto justify-between w-11/12 items-center p-4 gap-14 lg:flex-row`}
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
`;

export const SubDetailDiv = styled.div`
  ${tw`py-4 px-5 text-slate-700 rounded m-auto w-11/12`}
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
`;
export const SubDetailDiv2 = styled.div`
  ${tw`pt-2  px-5 text-slate-700 rounded m-auto w-11/12`}
  padding-bottom: 0.9rem;
  box-shadow:
    rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
`;
export const Form = styled.div`
  ${tw`flex flex-col gap-2`}
`;

export const CheckboxContainer = styled.div`
  ${tw``}
`;
export const ImgContainer = styled.div``;

export const DeleteBtn = styled.div`
 color:white,
  background-color: red,
  ${tw`text-lg md:text-base py-2 px-4 rounded border-none transition-all duration-300 cursor-pointer`}
 
`;
