import React from 'react';
import DataTable from 'react-data-table-component';
import { palette } from '../../styles/palette';
import Chip from '@mui/material/Chip';

const TableUtilityLib = (props) => {

  const handleRowClick = (row) => {
    if (props.onRowClicked) {
      props.onRowClicked(row);
    }
  };

  const customStyles = {
    table: {
      style: {
        border: '1px solid #ddd',
        borderRadius: '5px',
      },
    },
    headRow: {
      style: {
        backgroundColor: '#f2f2f2',
        color: palette.primary,
        fontWeight: 'bold',
        fontSize: '15px',
      },
    },
    headCells: {
      style: {
        justifyContent: 'center',
        textAlign: 'center', // Center align header cells
      },
    },
    cells: {
      style: {
        justifyContent: 'center',
        textAlign: 'center', // Center align cells
      },
    },
    highlightOnHover: {
      style: {
        backgroundColor: palette.primary,
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.newlyAdded,
      style: {
        backgroundColor: '#d4d',
        '&:hover': {
          backgroundColor: '#c8e6c9', // Slightly darker green when hovered
        },
      },
    },
  ];

  const columns = props.columns.map((column) => {
    if (column.id === 'verified') {
      return {
        ...column,
        cell: (row) => (
          column.selector(row) === 'Verified' ? (
            <Chip
              size="small"
              label={column.selector(row)}
              variant="outlined"
              style={{
                borderColor: 'green',
                color: 'green',
                width: '100px',
                textTransform: 'capitalize',
                fontSize: '12px',
                textAlign: 'center', // Center align Chip text
              }}
            />
          ) : (
            <Chip
              size="small"
              label={column.selector(row)}
              variant="outlined"
              style={{
                borderColor: 'red',
                color: 'red',
                width: '100px',
                textTransform: 'capitalize',
                fontSize: '12px',
                textAlign: 'center', // Center align Chip text
              }}
            />
          )
        ),
      };
    }
    if (column.id === 'blacklist') {
      return {
        ...column,
        cell: (row) => (
          column.selector(row) === 'Blacklisted' ? (
            <Chip
              size="small"
              label={column.selector(row)}
              variant="outlined"
              style={{
                borderColor: 'red',
                color: 'red',
                width: '110px',
                textTransform: 'capitalize',
                fontSize: '12px',
                textAlign: 'center', // Center align Chip text
              }}
            />
          ) : (
            <Chip
              size="small"
              label={column.selector(row)}
              variant="outlined"
              style={{
                borderColor: '#DBDBDB',
                color: '#000000',
                width: '110px',
                textTransform: 'capitalize',
                fontSize: '12px',
                textAlign: 'center', // Center align Chip text
              }}
            />
          )
        ),
      };
    }
    return column;
  });

  return (
    <DataTable
      title={props.title}
      columns={columns}
      data={props.data}
      highlightOnHover
      pointerOnHover
      selectableRows={props.selectableRows}
      selectableRowsHighlight
      onSelectedRowsChange={props.onSelectedRowsChange} // Added to handle row selection
      customStyles={customStyles}
      contextActions={props.contextActions}
      onRowClicked={(row) => handleRowClick(row)}
      pagination
    />
  );
};

export default TableUtilityLib;
