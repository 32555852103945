import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  StyledTabs,
  StyledTab,
} from '../../styles/Certificate/CertificateStyle';
import { useTranslation } from 'react-i18next';

const CertificateTabs = () => {
  const location = useLocation();
  const defaultTabValue = [
    '/LearnerCertificate',
    '/LearnerCertificate/LearnerEligibleCertificate',
  ].includes(location.pathname)
    ? location.pathname
    : '/LearnerCertificate';

  const { t } = useTranslation();
  return (
    <StyledTabs value={defaultTabValue}>
      <StyledTab
        label={t('certificate.earnedCertificate')}
        value="/LearnerCertificate"
        component={Link}
        to="/LearnerCertificate"
      />
      <StyledTab
        label={t('certificate.eligibleCertificate')}
        value="/LearnerCertificate/LearnerEligibleCertificate"
        component={Link}
        to="/LearnerCertificate/LearnerEligibleCertificate"
      />
    </StyledTabs>
  );
};

export default CertificateTabs;
