import React, { useState } from "react";
import { TextField, Tooltip, IconButton, Grid, Typography, Button, Select,MenuItem } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DeleteIcon from "@mui/icons-material/Delete";
import { palette } from "../../../styles/palette";
import { useNavigate } from 'react-router-dom';
import QuestionUtility from '../CourseModule/QuestionUtility';
import { styled } from '@mui/system';
import {ButtonContainer} from '../../../styles/Courses/CourseModuleStyles';

const DiscardButton = styled(Button)`
  color: ${palette.primary};
  border-color:  ${palette.gray};
  margin-right: 10px;
  width: 180px;
`;

const SaveButton = styled(Button)`
  background-color: ${palette.primary};
  color: ${palette.white};
`;

const InsertSurvey = ({ onClose }) => {
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [selectOpen, setSelectOpen] = useState(false);


  const initialValues = {
    questionType: 'oneAnswer',
    questionText: '',
    choices: [],
    isOptional: false,
    
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleAddQuestionClick = () => {
    setQuestions([...questions, initialValues]);
  };

  const handleRemoveQuestion = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    setQuestions(updatedQuestions);
  };

  const handlePreviewOpen = () => {
    setPreviewOpen(true);
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
  };

  const handleOpenSelect = () => {
    setSelectOpen(true);
  };

  const handleCloseSelect = () => {
    setSelectOpen(false);
  };

  const handleSelectOption = (option) => {
    if (option === "currentLesson") {
     
    window.open(currentLessonURL, "_blank");     } 
    else if (option === "enrolledCourse") {
      // Handle opening enrolled course tab
      window.open("URL_TO_ENROLLED_COURSE", "_blank");
    }
    setSelectOpen(false);
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: '10px' , marginTop:"65px"}}>
        <Grid container alignItems="center" spacing={1} sx={{ mt: 1, mb: 2 }}>
          <Grid item>
            <Tooltip title="Go Back">
              <IconButton
                size="small"
                onClick={handleOnClose}
                sx={{ color: `${palette.THEME_THREE}` }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item sx={{ mt: 1 }}>
            <Typography variant="h5" gutterBottom>
              Survey
            </Typography>
          </Grid>
        </Grid>
        <ButtonContainer>
          <DiscardButton variant="outlined">Discard Changes</DiscardButton>
          <SaveButton variant="contained" >Save</SaveButton>
        </ButtonContainer>
      </div>

      <div style={{ marginRight: "10px", marginLeft: "10px" }}>
        <TextField
          variant="outlined"
          label="Title"

          style={{ width: "100%", marginBottom: "24px" }}
        />
        {questions.map((question, index) => (
          <div key={index}>
            <QuestionUtility initialValues={question} />
            <IconButton color="error" onClick={() => handleRemoveQuestion(index)}>
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Button
            variant="contained"
            component="label"
            color="primary"
            style={{ borderRadius: 4 }}
            onClick={handleAddQuestionClick}
          >
            Add Question
          </Button>
         
        </Grid>
      </div>

    </div>
  );
};


export default InsertSurvey;

