import React, { useState, useMemo } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import ProgressModal from '../../Utilities/ProgressModal';
import { TextField, Tooltip, IconButton, Grid, Typography, InputAdornment, Button, Box } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { palette } from "../../../styles/palette";
import JoditReact from "jodit-react";
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch } from 'react-redux';
import { addItem } from '../../../features/courses/addCourseDetailsSlice';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import {DeleteBox,StyledTextField,ButtonContainer,BackButtonContainer,HeaderContainer} from '../../../styles/Courses/CourseModuleStyles';

const StyledInputAdornment = styled(InputAdornment)`
  display: flex;
  align-items: center;
`;

const StyledAudio = styled.audio`
  width: 100%;
`;

const AudioUpload = ({ isOpen, onClose, selectedItem, onBackButtonClick, lessonId, moduleId, handleDeleteTopic }) => {
  const[saved ,setSaved] = useState(true);
  const handleOnClose = () => {
    const data = { ...formData, preview: false}
    console.log("abc data from go back",data)
    if(saved && (selectedItem.uploadUrl  || selectedItem.link || selectedItem.fileName)){
      onClose();

    }
    else{
      dispatch(addItem(data));
      onClose()
    }

   

  };
  const { id, courseStructure } = useParams();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    selectedValue: "upload",
    link: selectedItem?.link || "",
    uploadUrl: '',
    moduleId: selectedItem?.moduleId || "",
    lessonId: selectedItem?.lessonId || "",
    title: selectedItem?.title || "",
    textDescription: selectedItem?.textDescription || "",
    id: selectedItem?.id || "",
    courseStructure: selectedItem?.courseStructure || "",
    contentType: selectedItem?.contentType || "",
    fileName: selectedItem?.fileName || "",
    preview:selectedItem?.preview || false,
  });

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [videoShow, setVideoShow] = useState(false);

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      lessonId: lessonId,
      moduleId: moduleId,
      id: selectedItem?.id ||generateRandomNumber(),
      contentType: 'Audio',
      courseStructure: courseStructure,
      link: formData?.uploadUrl?.split('?')[0],
    }));
    console.log(formData)
  };

  const config = useMemo(() => ({
    placeholder: 'Enter the audio description',
    readonly: false,
  }), []);

  const generateRandomNumber = () => {
    const min = 100000; // Minimum 6-digit number
    const max = 999999; // Maximum 6-digit number
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const handleDelete = () => {
    handleDeleteTopic(selectedItem?.moduleId, selectedItem?.lessonId, selectedItem?.id)
  }

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFormData((prevData) => ({
      ...prevData,
      fileName: selectedFile?.name
    }));
    setFileName(selectedFile.name);
    await requestUploadUrl(selectedFile);
  };

  const requestUploadUrl = async (file) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/course/uploadVideo`, {
        fileName: file.name,
        fileType: file.type,
      });
      console.log(response.data.url, "audio url")
      setFormData((prevData) => ({
        ...prevData,
        link: response.data.url?.split('?')[0],
        uploadUrl: response.data.url,
      }));
    } catch (error) {
      console.error('Error requesting upload URL:', error);
    }
  };

  const uploadToS3 = async () => {
    setLoading(true);
    setProgress(0);
    setCompleted(false);
    try {
      await axios.put(formData.uploadUrl, file, {
        headers: {
          "Content-Type": file?.type,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        },
      });

      setCompleted(true);
      setLoading(false);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleFileRemove = () => {
    setFile(null);
    setFileName('');
    setFormData((prevData) => ({
      ...prevData,
      uploadUrl: '',
    }));
  };

  const handleUpload = async () => {
    if (file) {
      await uploadToS3();
      toast.success('File Upload successfully!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      setVideoShow(true);
    }
  };

  const handleSubmit = async () => {
    if(formData.uploadUrl || formData.link|| formData.fileName)
      {
        
        
        const data = {...formData, preview:true}
        console.log('abc from  the submit',data);
        
        await dispatch(addItem(data));
      } else{
        
        const data = { ...formData, preview: false}
        console.log("abc data from the save for the draft",data)
    await dispatch(addItem(data));
      } 
    onClose();
      setSaved(true);
      };

  return (
    <>
      <HeaderContainer>
        <BackButtonContainer container alignItems="center" spacing={1}>
          <Grid item>
            <Tooltip title="Go Back">
              <IconButton
                size="small"
                onClick={handleOnClose}
                sx={{ color: `${palette.THEME_THREE}` }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item sx={{mt:1}}>
            <Typography variant="h5" gutterBottom>
              Audio
            </Typography>
          </Grid>
        </BackButtonContainer>
        <ButtonContainer>
          <Button variant="outlined" style={{ color: `${palette.primary}`, borderColor: `${palette.gray}`, marginRight: "10px", width: "180px" }} >Discard Changes</Button>
          {formData.title?<Button variant="contained" style={{ backgroundColor: `${palette.primary}`, color: `${palette.white}` }} onClick={handleSubmit}>Save</Button>:<Button disabled>Save</Button>}
          
        </ButtonContainer>
      </HeaderContainer>
      <div style={{ marginRight: "10px", marginLeft: "10px" }}>
        <StyledTextField
          variant="outlined"
          label="Title"
          name="title"
          style={{ width: "100%",  marginBottom: "10px" }}
          value={formData.title}
          onChange={handleInputChange}
        />
        <JoditReact value={formData.textDescription}
          config={config}
          onChange={newContent => setFormData((prevData) => ({
            ...prevData,
            textDescription: newContent,
          }))} />
        <Grid container spacing={2} sx={{ mt: 1 }} alignItems="center">
          <Grid item xs={10}>
            <StyledTextField
              variant="outlined"
              label="Upload Audio File"
              value={fileName}
              helperText="Max audio size 2GB"
              InputProps={{
                startAdornment: <StyledInputAdornment position="end" />,
                endAdornment: (
                  <StyledInputAdornment position="end">
                    {fileName && (
                      <IconButton onClick={handleFileRemove} style={{ color: 'red' }}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                    {!fileName && (
                      <Button
                        variant="contained"
                        component="label"
                        style={{ borderRadius: 4, backgroundColor:` ${palette.primary}`, color:`${palette.white}` }}
                      >
                        Choose File
                        <input type="file" style={{ display: 'none' }} onChange={handleFileChange} />
                      </Button>
                    )}
                  </StyledInputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', padding: '4px' }}>
            {fileName && (
              <Button variant="contained" color="primary" onClick={handleUpload} fullWidth>
                Upload
              </Button>
            )}
          </Grid>
          <ProgressModal
            open={loading}
            progress={progress}
            completed={completed}
            onClose={() => setLoading(false)}
          />
        </Grid>
        {(videoShow) && (
          <StyledAudio controls>
            <source src={formData?.link} type="audio/mp4" />
            Your browser does not support the audio tag.
          </StyledAudio>
        )}
        {(selectedItem?.fileName) && (
          <StyledAudio controls>
            <source src={selectedItem?.link} type="audio/mp4" />
            Your browser does not support the audio tag.
          </StyledAudio>
        )}
      </div>
      <DeleteBox>
        <h4 style={{ marginRight: '10px' }}>
          Are you sure you want to delete this topic? This action is irreversible.
        </h4>
        <Button
          variant="contained"
          color="error"
          onClick={handleDelete}
        >
          Delete Topic
        </Button>
      </DeleteBox>
    </>
  );
};

export default AudioUpload;
