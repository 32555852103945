// utils/checkvalidateData.js

export const checkvalidateData = (email, mobile, name) => {
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const indiaPhoneRegex = /^[6-9]\d{9}$/; // Indian phone number regex
    const usaPhoneRegex = /^[1-9]\d{9}$/;   // USA phone number regex
    
    let errors = {};
  
    if (!emailRegex.test(email)) {
      errors.email = "Email Id is not Valid";
    }
  
    if (!(indiaPhoneRegex.test(mobile) || usaPhoneRegex.test(mobile))) {
      errors.mobile = "Phone number is not valid";
    }
  
    return errors;
  };
  