import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Typography,
  Switch,
  TextField,
  Button,
  Tooltip,
  IconButton,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { saveNotificationSettings } from '../../../features/courses/CourseUtilitySlice';
import { toast } from 'react-toastify';
import { PageContainer } from '../../../styles/Common/CommonStyles';
import CourseSettingTabs from './CourseSettingTabs';
import CommonButton from '../../Utilities/CommonButton';

const CourseIDVerificationSettings = () => {
  const { courseId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { notificationSettings } = useSelector((state) => state.CourseUtility);

  const [formData, setFormData] = useState({
    enableOTP: false,
    enableQRCode: false,
    otpAtLogin: false,
    durationPrompt: 0,
    frequencyPrompt: 0,
  });

  // Fetch existing notification settings on mount
  useEffect(() => {
    if (notificationSettings) {
      setFormData({
        enableOTP: notificationSettings.enableOTP || false,
        enableQRCode: notificationSettings.enableQRCode || false,
        otpAtLogin: notificationSettings.otpAtLogin || false,
        durationPrompt: notificationSettings.durationPrompt || 0,
        frequencyPrompt: notificationSettings.frequencyPrompt || 0,
      });
    }
  }, [notificationSettings]);

  const handleToggleChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleSaveTemplate = async () => {
    try {
      const payload = {
        courseId,
        notificationSettings: {
          ...formData,
          notifications,
        },
      };

      // Log the notification settings
      console.log('Saving Notification Settings:', payload);

      await dispatch(saveNotificationSettings(payload));
      toast.success('Notification settings saved successfully!');
    } catch (error) {
      toast.error('Failed to save notification settings');
    }
  };

  return (
    <PageContainer>
      <CourseSettingTabs />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '90%',
          margin: 'auto',
          marginTop: '45px',
        }}
      >
        <Tooltip title="Go Back">
          <IconButton size="small" onClick={() => navigate('/CourseBuilder')}>
            <ArrowBackIosIcon />
          </IconButton>
        </Tooltip>
        <CommonButton label="Save Settings" onClick={handleSaveTemplate} />
      </div>
      <Container fluid>
        <Typography variant="h6">Verification Settings</Typography>
        <Grid container spacing={2}>
          {/* Enable OTP Verification */}
          <Grid item xs={8}>
            <Typography>Enable OTP Verification</Typography>
          </Grid>
          <Grid item xs={4}>
            <Switch
              name="enableOTP"
              checked={formData.enableOTP}
              onChange={handleToggleChange}
            />
          </Grid>

          {/* Enable QR Code Verification */}
          <Grid item xs={8}>
            <Typography>Enable QR Code Verification</Typography>
          </Grid>
          <Grid item xs={4}>
            <Switch
              name="enableQRCode"
              checked={formData.enableQRCode}
              onChange={handleToggleChange}
            />
          </Grid>

          {/* OTP Required at First Login */}
          <Grid item xs={8}>
            <Typography>OTP Required at First Login</Typography>
          </Grid>
          <Grid item xs={4}>
            <Switch
              name="otpAtLogin"
              checked={formData.otpAtLogin}
              onChange={handleToggleChange}
            />
          </Grid>

          {/* Duration-Based Prompt */}
          <Grid item xs={6}>
            <TextField
              label="Duration (in minutes)"
              type="number"
              value={formData.durationPrompt}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  durationPrompt: e.target.value,
                }))
              }
              fullWidth
            />
          </Grid>

          {/* Frequency-Based Prompt */}
          <Grid item xs={6}>
            <TextField
              label="Frequency (in modules)"
              type="number"
              value={formData.frequencyPrompt}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  frequencyPrompt: e.target.value,
                }))
              }
              fullWidth
            />
          </Grid>
        </Grid>
      </Container>
    </PageContainer>
  );
};

export default CourseIDVerificationSettings;
