import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import SearchIcon from '@material-ui/icons/Search';
import ShareIcon from '@mui/icons-material/Share';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import { CloudDownload, Visibility } from '@mui/icons-material';
import LearnerTabs from './LearnerTabs';
import TableUtilityLib from '../Utilities/TableUtilityLib';
import {
  WholePage,
  SearchEmail,
  TableDiv,
  TableButtonDiv,
  SeparateTableButton,
} from '../../styles/Certificate/CertificateStyle';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchEligibleCertificateUser,
  downloadCertificate,
} from '../../features/certificate/CertificateSlice';
import CommonButton from '../Utilities/CommonButton';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
} from '@mui/material';
import { requested } from '../../features/RequestSlice';
import { toast } from 'react-toastify';

const LearnerEligibleCertificate = () => {
  const dispatch = useDispatch();

  const [filterValue, setFilterValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [radioValue, setRadioValue] = useState('issue');
  const [selectedRow, setSelectedRow] = useState(null);

  const { data: issuedData } =
    useSelector((state) => state.certificate?.issued) || {};
  const userData = useSelector((state) => state?.auth?.users);

  useEffect(() => {
    if (userData?.user?.userid?.email) {
      dispatch(fetchEligibleCertificateUser(userData.user.userid.email));
    }
  }, [dispatch, userData]);

  useEffect(() => {
    if (issuedData) {
      setFilteredData(issuedData);
    }
  }, [issuedData]);

  const handleOpenDialog = (row) => {
    setSelectedRow(row); // Set the selected row data
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedRow(null); // Reset the selected row data
  };

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  const handleDialogAction = () => {
    const email = userData.user.userid.email;
    const { eventname, eventid } = selectedRow;

    dispatch(requested({ email, action: radioValue, eventname, eventid }))
      .then((response) => {
        const message =
          response.payload?.message || 'Request processed successfully!';
        toast.success(message);
        handleCloseDialog();
      })
      .catch((error) => {
        toast.error('Failed to process request.');
        console.error('Error:', error);
      });

    handleCloseDialog();
  };

  const handleDownload = (row) => {
    const { eventName, eventId } = row;
    console.log('Download action clicked for:', row);
    dispatch(
      downloadCertificate(userData.user.userid.email, eventName, eventId),
    );
  };

  const columns = [
    { name: 'Event Name', selector: (row) => row.eventname, sortable: true },
    { name: 'Event ID', selector: (row) => row.eventid, sortable: true },
    {
      name: 'Action',
      cell: (row) => (
        <>
          <Tooltip title="Issue Certificate">
            <SeparateTableButton>
              <CommonButton
                label={t('certificate.issueLabel')}
                onClick={() => handleOpenDialog(row)} // Pass the row data
              />
            </SeparateTableButton>
          </Tooltip>
        </>
      ),
    },
  ];

  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
  };

  const { t } = useTranslation();

  return (
    <>
      <LearnerTabs />
      <br />
      <WholePage>
        <SearchEmail>
          <TextField
            id="outlined-basic"
            label={t('certificate.searchText')}
            size="small"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleFilterChange}
          />
          <TableButtonDiv>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
              <DialogTitle>{t('certificate.dialogTitle')}</DialogTitle>
              <DialogContent>
                <FormControl component="fieldset">
                  <RadioGroup value={radioValue} onChange={handleRadioChange}>
                    <FormControlLabel
                      value="issue"
                      control={<Radio />}
                      label={t('certificate.issueLabel')}
                    />
                    <FormControlLabel
                      value="reissue"
                      control={<Radio />}
                      label={t('certificate.reissue')}
                    />
                  </RadioGroup>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  {t('certificate.cancelLabel')}
                </Button>
                <Button onClick={handleDialogAction} color="primary">
                  {t('certificate.confirm')}
                </Button>
              </DialogActions>
            </Dialog>
          </TableButtonDiv>
        </SearchEmail>
        <TableDiv>
          <TableUtilityLib columns={columns} data={filteredData} />
        </TableDiv>
      </WholePage>
    </>
  );
};

export default LearnerEligibleCertificate;
