import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import BackButton from '../Utilities/BackButton';
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  Grid,
  DialogActions,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Head,
  Details,
  Info,
  Profile,
  ImgContainer,
  DeleteBtn,
} from '../../styles/Users/UserDetailStyles';

import CommonButton from '../Utilities/CommonButton';
import {
  MainHeading,
  ButtonDiv,
} from '../../styles/Certificate/CertificateStyle';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteUser,
  fetchUserDetails,
  updateUser,
  fetchRole,
  passwordReset,
} from '../../features/userModule/userModuleSlice';
import { toast } from 'react-toastify';
import { EventTitle } from '../../styles/Calendar/CalendarStyle';

// Import country codes JSON
import countryCodes from '../Utilities/countryCodes.json';

// Define validation schema using yup
const validationSchema = yup.object({
  firstname: yup.string().required('First name is required'),
  lastname: yup.string().required('Last name is required'),
  phoneNo: yup
    .string()
    .matches(/^[0-9]{10}$/, 'Phone number is not valid')
    .required('Phone number is required'),
  countryCode: yup.string().required('Country code is required'),
});

const UserDetails = () => {
  const URL = process.env.REACT_APP_URL;
  // const URL = `http://localhost:8000`;

  const navigate = useNavigate();
  const { userId } = useParams();
  const dispatch = useDispatch();

  const { users, userdetails } = useSelector((state) => state.users);
  const { roles } = useSelector((state) => state.users);

  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [uploadUrl, setUploadUrl] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState('');

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserDetails(userId));
    }
    dispatch(fetchRole());
  }, [userId, dispatch]);

  useEffect(() => {
    if (userdetails) {
      const defaultCountryCode = '+91'; // Default to India

      setPreviewImage(userdetails.picture || '');
      setSelectedCountryCode(userdetails.countryCode || '');
      formik.setValues({
        firstname: userdetails.firstname || '',
        lastname: userdetails.lastname || '',
        email: userdetails.email || '',
        role: userdetails.role || '',
        phoneNo: userdetails.phoneNo || '',
        picture: userdetails.picture || '',
        referredBy: userdetails.learnerDetails?.referredBy || '',
        countryCode: userdetails.countryCode || defaultCountryCode,
      });
    }
  }, [userdetails]);

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      role: '',
      phoneNo: '',
      picture: '',
      referredBy: '',
      countryCode: '+91',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (image) {
        await uploadToS3();
      }

      await dispatch(
        updateUser({
          id: userId,
          userdetails: { ...values, picture: uploadUrl?.split('?')[0] },
        }),
      )
        .unwrap()
        .then(() => {
          toast.success('User details saved successfully');
          navigate('/Users');
        })
        .catch((error) => console.error('Failed to save user details:', error));
    },
  });

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      setImage(selectedFile);
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(selectedFile);
      requestUploadUrl(selectedFile);
    }
  };

  const requestUploadUrl = async (file) => {
    try {
      const response = await axios.post(`${URL}/api/uploadUserImages`, {
        fileName: file.name,
        fileType: file.type,
      });
      setUploadUrl(response.data.url);
    } catch (error) {
      console.error('Error requesting upload URL:', error);
    }
  };

  const uploadToS3 = async () => {
    try {
      await axios.put(uploadUrl, image, {
        headers: {
          'Content-Type': image.type,
        },
      });
      const baseUrl = uploadUrl.split('?')[0];
      formik.setFieldValue('picture', baseUrl);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleDelete = () => {
    setOpenDeleteDialog(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteUser(userId))
      .unwrap()
      .then(() => {
        toast.success('User deleted successfully');
        navigate('/Users');
      })
      .catch((error) => {
        console.error('Failed to delete user:', error);
      })
      .finally(() => {
        setOpenDeleteDialog(false);
      });
  };

  const handleCancelDelete = () => {
    setOpenDeleteDialog(false);
  };

  const handleChangePassword = () => {
    dispatch(passwordReset(userdetails.email))
      .unwrap()
      .then(() => {
        toast.success('Password reset email sent successfully');
      })
      .catch((error) => {
        console.error('Failed to send password reset email:', error);
      });
  };

  const getInitials = (name) => {
    return name ? name.charAt(0).toUpperCase() : '';
  };

  return (
    <>
      <Details>
        <EventTitle>
          <Head style={{ marginLeft: '-29px' }}>
            <BackButton style={{ marginTop: '10px', marginLeft: '30px' }} />
          </Head>
          <MainHeading>User Details</MainHeading>
        </EventTitle>
        <Profile>
          <Grid container justifyContent="center" alignItems="center">
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
            >
              <ImgContainer
                style={{
                  border: '1px solid #ccc',
                  width: '60%',
                  paddingTop: '50%',
                  position: 'relative',
                  overflow: 'hidden',
                  cursor: 'pointer',
                  backgroundColor: '#f5f5f5',
                  // fontFamily: 'Roboto, sans-serif',
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleImageChange}
                />
                {previewImage ? (
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundImage: `url(${previewImage ? previewImage : image})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {isHovered && (
                      <div
                        style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          color: 'white',
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                        }}
                      >
                        <Button
                          onClick={() =>
                            document
                              .getElementById('contained-button-file')
                              .click()
                          }
                          variant="contained"
                          color="primary"
                        >
                          Change Image
                        </Button>
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: '#0684FB',
                      color: 'white',
                      fontSize: '6rem',
                      fontWeight: 'bold',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    {getInitials(formik.values.firstname)}
                    <Button
                      style={{
                        position: 'absolute',
                        top: '75%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                      onClick={() =>
                        document.getElementById('contained-button-file').click()
                      }
                      variant="contained"
                      color="primary"
                    >
                      Upload Image
                    </Button>
                  </div>
                )}
              </ImgContainer>
            </Grid>
          </Grid>

          <Info>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                label="First Name"
                variant="outlined"
                margin="normal"
                name="firstname"
                value={formik.values.firstname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.firstname && Boolean(formik.errors.firstname)
                }
                helperText={formik.touched.firstname && formik.errors.firstname}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="Last Name"
                variant="outlined"
                margin="normal"
                name="lastname"
                value={formik.values.lastname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.lastname && Boolean(formik.errors.lastname)
                }
                helperText={formik.touched.lastname && formik.errors.lastname}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                disabled
                label="Email"
                variant="outlined"
                margin="normal"
                name="email"
                value={formik.values.email}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <FormControl variant="outlined" margin="normal" fullWidth>
                <InputLabel id="role-label">Role</InputLabel>
                <Select
                  labelId="role-label"
                  label="Role"
                  name="role"
                  value={formik.values.role}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {Array.isArray(roles.data) &&
                    roles.data.map((role) => (
                      <MenuItem key={role._id} value={role.name}>
                        {role.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" fullWidth margin="normal">
                    <InputLabel id="country-code-label">
                      Country Code
                    </InputLabel>
                    <Select
                      labelId="country-code-label"
                      label="Country Code"
                      name="countryCode"
                      value={formik.values.countryCode}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      {countryCodes.map((country) => (
                        <MenuItem key={country.code} value={country.code}>
                          {country.name} ({country.code})
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField
                    label="Contact Info"
                    variant="outlined"
                    margin="normal"
                    name="phoneNo"
                    value={formik.values.phoneNo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.phoneNo && Boolean(formik.errors.phoneNo)
                    }
                    helperText={formik.touched.phoneNo && formik.errors.phoneNo}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
              {formik.values.role === 'Learner' && (
                <TextField
                  label="Referred By"
                  variant="outlined"
                  margin="normal"
                  name="referredBy"
                  value={formik.values.referredBy}
                  onChange={formik.handleChange}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              <ButtonDiv>
                <CommonButton label="Save" type="submit" />
              </ButtonDiv>
            </form>
          </Info>
        </Profile>

        <MainHeading style={{ marginLeft: '30px', marginTop: '20px' }}>
          Change Password
        </MainHeading>
        <Profile>
          <Typography>
            Do you want to change the password, click on the send mail button to
            receive the email.
          </Typography>
          <ButtonDiv>
            <CommonButton label="Send Mail" onClick={handleChangePassword} />
          </ButtonDiv>
        </Profile>

        <MainHeading style={{ marginLeft: '30px', marginTop: '20px' }}>
          Delete User
        </MainHeading>
        <Profile>
          <Typography>User once deleted cannot be recovered</Typography>
          <ButtonDiv>
            <DeleteBtn
              variant="contained"
              style={{
                color: 'white',
                backgroundColor: 'red',
                cursor: 'pointer',
              }}
              onClick={handleDelete}
            >
              Delete
            </DeleteBtn>
          </ButtonDiv>
        </Profile>
        <br />
        <br />
      </Details>
      <Dialog open={openDeleteDialog} onClose={handleCancelDelete}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this user? This action cannot be
            undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} sx={{ color: 'red' }}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserDetails;
