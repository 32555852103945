import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RequestApi, fetchRequestsApi } from '../api/RequestApi';
// import RequestApi from '../api/RequestApi';

// Async thunk for issuing or reissuing a certificate
export const requested = createAsyncThunk(
  'request',
  async ({ email, action, eventname, eventid }, thunkAPI) => {
    try {
      console.log(
        `checkings Requesting certificate ${eventid} for email: ${email} with ${eventname} action: ${action}`,
      ); // Debugging statement

      // Call the API method based on the action type
      const response = await RequestApi(email, action, eventname, eventid); // Use unified API method

      console.log('checkings API response:', response.data); // Debugging statement
      return response.data;
    } catch (error) {
      console.error('checkings Full error object:', error); // Debugging statement
      if (error.response) {
        console.error('checkings Error response data:', error.response.data); // Debugging statement
      } else {
        console.error('checkings Error message:', error.message); // Debugging statement
      }
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  },
);
export const fetchRequests = createAsyncThunk(
  'requests/fetchRequests',
  async (_, thunkAPI) => {
    try {
      console.log('Fetching requests...'); // Debugging statement

      // Call the API method to fetch requests
      const response = await fetchRequestsApi(); // Adjust according to your API method

      console.log('API response:', response.data); // Debugging statement
      return response.data;
    } catch (error) {
      console.error('Error fetching requests:', error); // Debugging statement
      if (error.response) {
        console.error('Error response data:', error.response.data); // Debugging statement
      } else {
        console.error('Error message:', error.message); // Debugging statement
      }
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  },
);
// Initial state
const initialState = {
  data: null,
  status: 'idle',
  error: null,
};

// Request slice
const requestSlice = createSlice({
  name: 'certificate',
  initialState,
  reducers: {
    // Add synchronous actions here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(requested.pending, (state) => {
        console.log('Request pending...'); // Debugging statement
        state.status = 'loading';
      })
      .addCase(requested.fulfilled, (state, action) => {
        console.log('Request succeeded:', action.payload); // Debugging statement
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(requested.rejected, (state, action) => {
        console.error('Request failed:', action.payload); // Debugging statement
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default requestSlice.reducer;
