import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
// import CertificateTabs from './CertificateTabs';
import CommonButton from '../Utilities/CommonButton';
import TableUtilityLib from '../Utilities/TableUtilityLib';
import Loader from '../Utilities/Loader';
import {
  fetchBlacklistedUsersApi,
  blacklistUser,
  updateBlacklistedUser,
  deleteBlacklistedUser,
} from '../../features/userModule/userModuleSlice';
import { Text } from '../../styles/Common/CommonStyles';
import {
  SearchEmail,
  ButtonDiv,
  WholePage,
} from '../../styles/Certificate/CertificateStyle';
import { useTranslation } from 'react-i18next';
import NotificationTabs from './UserTabs';
import { Typography } from '@mui/material';

const reasonOptions = [
  { value: 'fake', label: 'Fake' },
  { value: 'inactive', label: 'Dormant/inactive' },
  { value: 'deactivated', label: 'Deactivated' },
  { value: 'change', label: 'Changed Email Id' },
];

const Blacklist = () => {
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [email, setEmail] = useState('');
  const [reason, setReason] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});
  const [editingRow, setEditingRow] = useState(null);
  const [editData, setEditData] = useState({});
  const { blacklist, loading } = useSelector((state) => state.users) || {};
  const { t } = useTranslation();

  useEffect(() => {
    if (!blacklist || blacklist.length === 0) {
      dispatch(fetchBlacklistedUsersApi());
    }
  }, [dispatch, blacklist]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setFieldErrors({});
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setFieldErrors({});
  };

  const handleFormSubmit = async () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Validate the email and reason fields
    if (email.trim() === '') {
      errors.email = 'Email is required.';
    } else if (!emailRegex.test(email)) {
      errors.email = 'Invalid email.';
    }

    if (!reason) {
      errors.reason = 'Reason is required.';
    }

    // If there are any validation errors, stop the form submission
    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }

    const blacklistData = {
      email: email,
      reason: reason,
    };

    try {
      // Dispatch the blacklistUser action and await the result
      const resultAction = await dispatch(blacklistUser(blacklistData));

      if (blacklistUser.fulfilled.match(resultAction)) {
        const response = resultAction.payload;

        // Handle different success scenarios
        if (response.statusCode === 200) {
          setEmail('');
          setReason('');
          toast.success(response.message || 'User disqualified successfully');
          dispatch(fetchBlacklistedUsersApi());
          setOpenDialog(false);
        } else if (response.statusCode === 404) {
          toast.error(response.message || 'User not found');
        } else if (response.statusCode === 409) {
          toast.error(
            response.message || 'User already exists in the blacklist',
          );
        }
      } else if (blacklistUser.rejected.match(resultAction)) {
        // Handle rejected action
        const error = resultAction.payload;
        toast.error(
          error?.message || 'An error occurred while blacklisting the user.',
        );
      }
    } catch (error) {
      // Handle any unexpected errors
      toast.error('An unexpected error occurred.');
    }
  };

  const handleEdit = (row) => {
    setEditingRow(row.email);
    setEditData({ email: row.email, reason: row.reason });
  };

  const handleSave = async () => {
    const errors = {};
    console.log('checking set data', editData);

    // Validate the email
    if (!editData.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(editData.email)) {
      errors.email = 'Invalid email.';
    }

    // Validate the reason
    if (!editData.reason) {
      errors.reason = 'Reason is required.';
    }

    // If there are validation errors, set them and return
    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }

    // Prepare the payload
    const { email, reason, disqualifiedBy } = editData;

    try {
      console.log('checking set data', editData);

      console.log('Dispatching updateBlacklistedUser action');
      const result = await dispatch(
        updateBlacklistedUser({
          email,
          updatedData: { reason, disqualifiedBy },
        }),
      );
      dispatch(fetchBlacklistedUsersApi());
      setEditingRow(null);

      console.log('Dispatch result:', result);

      if (result?.error) {
        throw new Error(result.error.message || 'Unknown error occurred');
      }
    } catch (error) {
      console.error('Error updating user:', error);
      const errorMessage =
        error?.message || 'An error occurred while updating the user.';
      toast.error(errorMessage);
    }
  };

  const handleCancel = () => {
    setEditingRow(null);
    setEditData({});
  };

  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
  };
  const handleDelete = async (email) => {
    try {
      await dispatch(deleteBlacklistedUser(email)).unwrap();
      toast.success('User deleted successfully');
      dispatch(fetchBlacklistedUsersApi()); // Refresh blacklist data
    } catch (error) {
      toast.error(
        error?.message || 'An error occurred while deleting the user.',
      );
    }
  };

  const filteredData = blacklist?.data
    ?.filter((row) =>
      row.email.toLowerCase().includes(filterValue.toLowerCase()),
    )
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const columns = [
    {
      name: 'Date',
      selector: (row) =>
        row.createdAt
          ? format(new Date(row.createdAt), 'MM/dd/yyyy, hh:mm a')
          : 'N/A',
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: 'Reason',
      selector: (row) =>
        editingRow === row.email ? (
          <FormControl fullWidth variant="outlined" size="small">
            <Select
              value={editData.reason || row.reason}
              onChange={(e) =>
                setEditData({ ...editData, reason: e.target.value })
              }
              displayEmpty
              style={{ minWidth: 200 }}
            >
              {reasonOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          reasonOptions.find((option) => option.value === row.reason)?.label ||
          'N/A'
        ),
      sortable: true,
    },
    {
      name: 'Disqualified By',
      selector: (row) => row.disqualifiedBy || 'N/A',
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div style={{ display: 'flex', gap: '1px' }}>
          {editingRow === row.email ? (
            <>
              <Tooltip title="Save">
                <IconButton onClick={handleSave}>
                  <SaveIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Cancel">
                <IconButton onClick={handleCancel}>
                  <CancelIcon />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip title="Edit">
                <IconButton onClick={() => handleEdit(row)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton onClick={() => handleDelete(row.email)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <NotificationTabs />
      <WholePage style={{ margin: '2rem 2rem 2rem' }}>
        <Typography>{t('certificate.blacklistLabel')}</Typography>
        <br />
        <SearchEmail>
          <TextField
            id="outlined-basic"
            label={t('certificate.searchText')}
            variant="outlined"
            size="small"
            value={filterValue}
            onChange={handleFilterChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            style={{ minWidth: 200 }} // Adjust width as needed
          />
          <Tooltip title="Disqualify User">
            <ButtonDiv>
              <CommonButton
                label={t('certificate.certificateAdd')}
                onClick={handleOpenDialog}
              />
            </ButtonDiv>
          </Tooltip>
        </SearchEmail>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>{t('certificate.blacklistLabel')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <TextField
                label={t('certificate.cerificateEmail')}
                type="email"
                variant="outlined"
                margin="normal"
                fullWidth
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={Boolean(fieldErrors.email)}
                helperText={fieldErrors.email}
                size="small" // Make the TextField extra small
                style={{ minWidth: 200 }} // Adjust width as needed
              />
              <FormControl
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
              >
                <InputLabel>{t('certificate.reasonLabel')}</InputLabel>
                <Select
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  label={t('certificate.reasonLabel')}
                  error={Boolean(fieldErrors.reason)}
                  style={{ minWidth: 200 }} // Adjust width as needed
                >
                  {reasonOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {fieldErrors.reason && (
                  <div style={{ color: 'red' }}>{fieldErrors.reason}</div>
                )}
              </FormControl>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CommonButton
              label={t('certificate.saveLabel')}
              onClick={handleFormSubmit}
            />
            <CommonButton
              label={t('certificate.cancelLabel')}
              onClick={handleCloseDialog}
            />
          </DialogActions>
        </Dialog>
        {loading ? (
          <Loader />
        ) : (
          <TableUtilityLib
            columns={columns}
            data={filteredData}
            selectableRows={false}
          />
        )}
      </WholePage>
    </>
  );
};

export default Blacklist;
