// course builder> my courses

import React, { useState, useEffect } from 'react';
import { ButtonCont } from '../../styles/Courses/CourseBuilderStyles';
import { PageContainer } from '../../styles/Common/CommonStyles';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  TextField,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Tooltip,
  Typography,
  Link,
  Divider,
  Tabs,
  Tab,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import BrushOutlinedIcon from '@mui/icons-material/BrushOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import { truncateText } from '../Utilities/TextAppendEllipsis';
import { palette } from '../../styles/palette';
import '../../assets/coursebuilder.css';
import CommonButton from '../Utilities/CommonButton';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Utilities/Loader';

const StyledCard = styled(Card)`
  width: 100%;
  height: 400px; // fixed height
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:hover {
    transform: scale(1.05);
  }
`;
const StyledTabs = styled(Tabs)({
  backgroundColor: '#f5f5f5',
  marginTop: '-18px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',

  '& .MuiTab-root': {
    minWidth: 100,
    textTransform: 'none',
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#1890ff',
  },
});

const StyledTab = styled(Tab)({
  textTransform: 'none',
  fontWeight: 'bold',
  '&:hover': {
    color: '#40a9ff',
    opacity: 1,
  },
});

const MyCourses = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [hasFetchedCourses, setHasFetchedCourses] = useState(false); // Ref to track fetch status
  const { myCourseList, loading } =
    useSelector((state) => state.addCourseDetails) || {};
  const handleSelectCourse = () => {
    navigate('/SelectCourseCard');
  };
  const { users } = useSelector((state) => state.auth);

  // // Local state for search results
  const [searchResultList, setSearchResultList] = useState([]);
  const [backupmyCourseList, setBackupmyCourseList] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  // Update local state when myCourseList changes
  useEffect(() => {
    const list = myCourseList || [];
    const sortedmyCourseList = [...list]?.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
    );
    setSearchResultList(sortedmyCourseList);
    setBackupmyCourseList(sortedmyCourseList);
  }, [myCourseList]);

  const handleSearchTextChange = (e) => {
    let searchText = e.target?.value?.toLowerCase();

    if (searchText === '') {
      setSearchResultList([...backupmyCourseList]);
    } else {
      const filteredCourses = backupmyCourseList?.filter((course) =>
        course?.generalInformation?.title?.toLowerCase().includes(searchText),
      );
      setSearchResultList(filteredCourses);
    }
  };

  const calculateDaysAgo = (date) => {
    const currentDate = new Date();
    const createdDate = new Date(date);
    console.log(createdDate);
    const timeDifference = currentDate - createdDate;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference;
  };

  const handleNavigate = (course) => {
    if (course?.generalInformation?.courseStructure === 'SCORM') {
      navigate(`/DisplayScrom/${course?._id}`, {
        state: { scormUrl: course?.generalInformation.scormUrl },
      });
    } else {
      navigate(
        `/CourseModules/${course?._id}/${course?.generalInformation?.courseStructure}`,
        {
          state: {
            courseTitle: course?.generalInformation.title,
          },
        },
      );
    }
  };

  if (loading) {
    return <Loader />;
  }
  // const { users, loading } = useSelector((state) => state.auth);

  const filteredmyCourseList =
    users?.user?.userid?.role === 'Learner'
      ? searchResultList.filter(
          (course) => course?.generalInformation.isPublished,
        )
      : searchResultList;

  const truncatedTitleFunction = (title) => {
    return truncateText(title, 20);
  };

  // const handleTabChange = (event, newValue) => {
  //   setTabValue(newValue);
  // };

  return (
    <div style={{ minHeight: '64vh' }}>
      <PageContainer>
        <>
          {!loading ? (
            <section className="course-builder">
              <Container fluid>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={10}>
                    <TextField
                      id="outlined-basic"
                      placeholder="Search by Course Title"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => handleSearchTextChange(e)}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sx={{ paddingTop: 2, justifyContent: 'flex-end' }}
                  >
                    {users?.user?.userid?.role === 'Admin' && (
                      <ButtonCont>
                        <CommonButton
                          onClick={handleSelectCourse}
                          label="Create Course"
                        />
                      </ButtonCont>
                    )}
                  </Grid>

                  {filteredmyCourseList?.map((course) => {
                    const daysAgo = calculateDaysAgo(course?.createdAt);
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={5}
                        lg={4}
                        xl={3}
                        key={course?._id}
                      >
                        {users?.user?.userid?.role === 'Instructor' && (
                          <StyledCard className="course-card">
                            <Tooltip title="Click to add Chapter">
                              <Link onClick={() => handleNavigate(course)}>
                                <div className="image-container">
                                  <div
                                    className="blurbg"
                                    style={{
                                      background: course?.generalInformation
                                        .bannerImage
                                        ? `url(${course?.generalInformation.bannerImage})`
                                        : 'skyblue',
                                      backgroundSize: 'cover',
                                      backgroundPosition: 'center',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      color: 'white',
                                      fontWeight: 'bold',
                                      fontSize: '1.5em',
                                    }}
                                  >
                                    {!course?.generalInformation.bannerImage &&
                                      truncatedTitleFunction(
                                        course?.generalInformation.title,
                                      )}
                                  </div>
                                </div>
                              </Link>
                            </Tooltip>
                            <CardContent sx={{ pb: 0 }}>
                              <Link
                                underline="hover"
                                onClick={() => handleNavigate(course)}
                              >
                                <Typography variant="body1" fontWeight="bold">
                                  {course?.generalInformation.title}
                                </Typography>
                              </Link>

                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                  ></Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    mb={2}
                                    fontWeight="bold"
                                  >
                                    {course?.generalInformation.isPublished
                                      ? 'Published'
                                      : 'Unpublished'}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    Enrolled Learners:
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    mb={2}
                                  >
                                    {daysAgo === 0
                                      ? 'Today'
                                      : `${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                            <Divider />
                            <CardActions>
                              <Grid container spacing={2}>
                                <Grid item xs={2}>
                                  <Tooltip title="Details">
                                    <IconButton
                                      sx={{ color: `${palette.THEME_THREE}` }}
                                      size="small"
                                    >
                                      <InfoOutlinedIcon
                                        onClick={() =>
                                          navigate('/CourseDetails', {
                                            state: course,
                                          })
                                        }
                                      />
                                    </IconButton>
                                  </Tooltip>
                                  <Divider orientation="vertical" />
                                </Grid>
                                <Grid item xs={2}>
                                  <Tooltip title="Course Settings">
                                    <IconButton
                                      size="small"
                                      sx={{ color: `${palette.THEME_THREE}` }}
                                      onClick={() =>
                                        navigate(
                                          `/CourseSettings/${course?._id}`,
                                        )
                                      }
                                    >
                                      <SettingsIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Divider orientation="vertical" flexItem />
                                </Grid>
                                <Grid item xs={2}>
                                  <Tooltip title="Landing Page Design">
                                    <IconButton
                                      size="small"
                                      sx={{ color: `${palette.THEME_THREE}` }}
                                      onClick={() =>
                                        navigate('/LandingPageDetails')
                                      }
                                    >
                                      <BrushOutlinedIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Divider orientation="vertical" flexItem />
                                </Grid>
                                <Grid item xs={2}>
                                  <Tooltip title="Learners">
                                    <IconButton
                                      size="small"
                                      sx={{ color: `${palette.THEME_THREE}` }}
                                      onClick={() =>
                                        navigate(`/LearnersList/${course?._id}`)
                                      }
                                    >
                                      <PeopleOutlineOutlinedIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Divider orientation="vertical" flexItem />
                                </Grid>
                                <Grid item xs={2}>
                                  <Tooltip title="Course Preview">
                                    <IconButton
                                      size="small"
                                      sx={{ color: `${palette.THEME_THREE}` }}
                                      onClick={() =>
                                        navigate(
                                          `/LearnerCoursePreview/${course?._id}/${
                                            course?.generalInformation
                                              ?.courseStructure
                                          }`,
                                          {
                                            state: {
                                              courseTitle:
                                                course?.generalInformation
                                                  .title,
                                            },
                                          },
                                        )
                                      }
                                    >
                                      <VisibilityOutlinedIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Divider orientation="vertical" flexItem />
                                </Grid>
                                <Grid item xs={2}>
                                  <Tooltip title="Course Discussions">
                                    <IconButton
                                      size="small"
                                      sx={{ color: `${palette.THEME_THREE}` }}
                                      onClick={() =>
                                        navigate(
                                          `/CourseDiscussion/${course?._id}`,
                                        )
                                      }
                                    >
                                      <ChatBubbleOutlineOutlinedIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </CardActions>
                          </StyledCard>
                        )}

                        {(users?.user?.userid?.role === 'Learner' ||
                          users?.user?.userid?.role === 'Admin') && (
                          <StyledCard className="course-card">
                            <Tooltip title="Click to add Chapter">
                              <Link
                                onClick={() =>
                                  navigate(
                                    `/LearnerCoursePreview/${course?._id}/${
                                      course?.generalInformation
                                        ?.courseStructure
                                    }`,
                                    {
                                      state: {
                                        courseTitle:
                                          course?.generalInformation.title,
                                      },
                                    },
                                  )
                                }
                              >
                                <div className="image-container">
                                  <div
                                    className="blurbg"
                                    style={{
                                      background: `url(${course?.generalInformation.bannerImage})`,
                                    }}
                                  ></div>
                                </div>
                              </Link>
                            </Tooltip>
                            <CardContent sx={{ pb: 0 }}>
                              <Link
                                underline="hover"
                                onClick={() => handleNavigate(course)}
                              >
                                <Typography variant="body1" fontWeight="bold">
                                  {course?.generalInformation.title}
                                </Typography>
                              </Link>

                              <Grid container spacing={2}>
                                <Grid item xs={6}></Grid>
                                <Grid
                                  item
                                  xs={6}
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    mb={2}
                                  >
                                    {daysAgo === 0
                                      ? 'Today'
                                      : `${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                            <CardActions>
                              <Grid container spacing={2}>
                                <Grid item xs={2}>
                                  <Tooltip title="Details">
                                    <IconButton
                                      sx={{ color: `${palette.THEME_THREE}` }}
                                      size="small"
                                    >
                                      <InfoOutlinedIcon
                                        onClick={() =>
                                          navigate('/CourseDetails', {
                                            state: course,
                                          })
                                        }
                                      />
                                    </IconButton>
                                  </Tooltip>
                                  <Divider orientation="vertical" />
                                </Grid>
                                <Grid item xs={2}>
                                  <Tooltip title="Course Discussions">
                                    <IconButton
                                      size="small"
                                      sx={{ color: `${palette.THEME_THREE}` }}
                                      onClick={() =>
                                        navigate(
                                          `/CourseDiscussion/${course?._id}`,
                                        )
                                      }
                                    >
                                      <ChatBubbleOutlineOutlinedIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </CardActions>
                          </StyledCard>
                        )}
                      </Grid>
                    );
                  })}
                </Grid>
              </Container>
            </section>
          ) : (
            <Loader />
          )}
          {/* {users?.user?.userid?.role === 'Admin' && (
            <ButtonCont>
              <CommonButton
                onClick={handleSelectCourse}
                label="Create Course"
              />
            </ButtonCont>
          )} */}
        </>
      </PageContainer>
    </div>
  );
};

export default MyCourses;
