import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Container } from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
const URL = process.env.REACT_APP_URL;

const VerifyOTP = () => {
  const [otp, setOtp] = useState('');
  const [error, setError] = useState(false);
  const [email, setEmail] = useState('');
  const [isSendOTPDisabled, setIsSendOTPDisabled] = useState(false);
  const [timer, setTimer] = useState(30);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.email) {
      setEmail(location.state.email);
    } else {
      toast.error('Email address is missing.');
      navigate('/'); // Redirect to home or another appropriate page
    }
  }, [location.state, navigate]);

  useEffect(() => {
    let interval;
    if (isSendOTPDisabled) {
      interval = setInterval(() => {
        setTimer((prev) => {
          if (prev === 1) {
            setIsSendOTPDisabled(false);
            clearInterval(interval);
            return 30; // Reset timer
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isSendOTPDisabled]);

  const handleChange = (e) => {
    setOtp(e.target.value);
    setError(false); // Reset error when user starts typing
  };

  const handleSendOTP = async () => {
    if (!email) {
      toast.error('Email address is required.');
      return;
    }

    try {
      const response = await fetch(`${URL}/api/usermodule/sendotp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const result = await response.json();
      if (response.ok) {
        toast.success(result.message);
        setIsSendOTPDisabled(true);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to send OTP. Please try again later.');
    }
  };

  const handleVerify = async (e) => {
    e.preventDefault();

    if (otp === '') {
      setError(true);
      toast.error('Please enter OTP');
      return;
    }

    try {
      const response = await fetch(`${URL}/api/usermodule/verifyotp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, otp }),
      });

      const result = await response.json();

      if (response.ok) {
        toast.success('OTP verified successfully.');
        navigate('/VerificationSuccess'); // Redirect to dashboard after successful verification
      } else {
        toast.error(result.message || 'Invalid or expired OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to verify OTP. Please try again later.');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
          <Typography component="h1" variant="h5">
              Verify OTP
            </Typography>
        <Box component="form" onSubmit={handleVerify} sx={{ mt: 3 }}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="otp"
            label="Enter OTP"
            name="otp"
            autoComplete="one-time-password"
            value={otp}
            onChange={handleChange}
            error={error}
            helperText={error ? 'Please enter OTP' : ''}
          />
          <Box
            sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}
          >
            <Button
              variant="contained"
              sx={{ mb: 2, mr: 2  }}
              onClick={handleSendOTP}
              disabled={isSendOTPDisabled}
            >
              {isSendOTPDisabled ? `Resend OTP in ${timer}s` : 'Send OTP'}
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{ mb: 2 }}
            >
              Verify OTP
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default VerifyOTP;
