import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Tooltip, TextField } from '@mui/material';
import { palette } from '../../styles/palette';
import { Button, Avatar } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import useDrivePicker from 'react-google-drive-picker';
// import { React } from "react";

const UploadModal = ({
  isOpen,
  onClose,
  children,
  modalWidth,
  modalHeight,
  title,
  onCancel,
  onUpload,
  // onClickGoogle,
  isVisible,
}) => {
  const [openPicker, authResponse] = useDrivePicker();

  const onClickDropbox = () => {
    console.log('Dropbox Button is Clicked');
  };

  const onClickGoogle = () => {
    console.log('Drive Upload Buttons is Clicked');
    openPicker({
      clientId:
        '995150693351-1fkiqv3ia8spkdq62sf6nbveu875knqf.apps.googleusercontent.com',
      developerKey: 'AIzaSyAxR8mD4oUWlngTLDC-Hc9JAGSbLP36hek',
      viewId: 'DOCS',
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      callbackFunction: (data) => {
        if (data.action === 'cancel') {
          console.log('User clicked cancel/close button');
        }
        console.log(data);
      },
    });
  };

  if (!isOpen) return null;
  return (
    <ModalDiv>
      <ModalContainer
        sx={{
          height: modalHeight ? modalHeight : '40vh',
          width: modalWidth ? modalWidth : '40vw',
        }}
      >
        <Header>
          <p style={descStyle}> {title} </p>

          <Tooltip title="Close">
            <CloseIcon onClick={onClose} sx={CloseIconStyle} />
          </Tooltip>
        </Header>
        {children}
        {/* Self created div start */}
        <div name="mainDiv" style={{ visibility: isVisible }}>
          <div
            style={{
              marginLeft: '10px',
              marginRight: '10px',
              marginTop: '15px',
            }}
          >
            {/* Include the code for  */}
            <TextField
              style={{ width: '100%' }}
              name="upload-photo"
              type="file"
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              marginTop: '10px',
              marginRight: '10px',
              '& .MuiButtonBase-root .MuiButton-outlinedSizeSmall': {
                marginRight: '10px',
              },
            }}
          >
            <Button
              variant="outlined"
              style={{ marginRight: '7px' }}
              size="small"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button variant="outlined" size="small" onClick={onUpload}>
              Upload
            </Button>
          </div>
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  flex: 1,
                  backgroundColor: `${palette.primary}`,
                  height: '3px',
                  marginTop: '5px',
                }}
              />

              <p
                style={{
                  margin: '0 10px',
                  color: `${palette.primary}`,
                  marginTop: '5px',
                }}
              >
                OR IMPORT FROM
              </p>

              <div
                style={{
                  flex: 1,
                  backgroundColor: `${palette.primary}`,
                  height: '3px',
                  marginTop: '5px',
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '15px',
              }}
            >
              <Button
                variant="outlined"
                size="small"
                startIcon={
                  <Avatar src={require('../../images/google-drive.png')} />
                }
                sx={{
                  color: `${palette.primary}`,
                  '& .MuiButton-outlined .MuiButton-outlinedSizeSmall': {
                    borderColor: `${palette.primary}`,
                  },
                }}
                onClick={onClickGoogle}
              >
                Google Drive
              </Button>

              <Button
                variant="outlined"
                color={palette.primary}
                size="small"
                style={{ marginLeft: '10px' }}
                startIcon={<Avatar src={require('../../images/dropbox.png')} />}
                onClick={onClickDropbox}
              >
                Dropbox
              </Button>
            </div>
          </div>
        </div>
        {/* Self created Div end */}
        <style>
          {`
            ::-webkit-scrollbar {
              width: 15px;
            }
            ::-webkit-scrollbar-thumb {
              background-color: #6082B6;
              border-radius: 10px; // Set the border radius for the scrollbar thumb
            }

            ::-webkit-scrollbar-track {
              background-color: #a0a0a0;
              border-radius: 10px; // Set the border radius for the scrollbar track
            }
          `}
        </style>
      </ModalContainer>
    </ModalDiv>
  );
};

export default UploadModal;

const CloseIconStyle = {
  background: `${palette.primary}`,
  borderRadius: '50px',
  padding: '2px',
  color: `${palette.white}`,
  cursor: 'pointer',
};

const descStyle = {
  fontSize: '1rem',
  lineHeight: '1.5rem',
  fontWeight: '600',
  color: `${palette.heading}`,
};

const ModalDiv = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  background: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ModalContainer = styled('div')({
  background: 'white',
  margin: 'auto',
  padding: '0.5%',
  border: '2px solid #000',
  borderRadius: '10px',
  boxShadow: '2px solid ',
  justifyContent: 'center',
  overflowY: 'auto', // Only vertical scrollbar
  scrollPadding: '12px',
  overscrollbehavior: 'contain',
});

const Header = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: '15px',
  paddingRight: '15px',
});
