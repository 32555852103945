import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  StyledTabs,
  StyledTab,
} from '../../styles/Certificate/CertificateStyle';

const EventsTabs = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const params = useParams();
  const eventname = params.eventname;

  const [selectedTab, setSelectedTab] = useState('');

  // Function to handle tab change
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  useEffect(() => {
    // Determine which tab should be selected based on the URL
    if (location.pathname.includes('eventdetails')) {
      setSelectedTab('eventdetails');
    } else if (location.pathname.includes('userinfo')) {
      setSelectedTab('userinfo');
    }
  }, [location.pathname]);

  return (
    <StyledTabs value={selectedTab} onChange={handleChange}>
      <StyledTab
        label={t('calender.eventdetailsLabel')}
        value="eventdetails"
        component={Link}
        to={`/EventsNavigator/eventdetails/${eventname}`}
      />

      <StyledTab
        label={t('calender.infoLabel')}
        value="userinfo"
        component={Link}
        to={`/EventsNavigator/userinfo/${eventname}`}
      />
    </StyledTabs>
  );
};

export default EventsTabs;
