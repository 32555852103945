import React, { useState, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  Grid,
  Box,
  Typography,
  IconButton,
  Checkbox,
  FormControlLabel,
  Tooltip,
  InputAdornment,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams } from 'react-router-dom';
import JoditEditor from 'jodit-react';

import AddIcon from '@mui/icons-material/Add';
const QuestionUtility = ({ initialValues = {}, quizType, setQuizType }) => {
  const [questionText, setQuestionText] = useState('');
  const [choices, setChoices] = useState([]);
  const [choiceCount, setChoiceCount] = useState(0); // State to keep track of choice count
  const [isOptional, setIsOptional] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const [answerText, setAnswerText] = useState('');
  const [imageUploadMap, setImageUploadMap] = useState({});
  const [showChoicesCheckbox, setShowChoicesCheckbox] = useState(false);

  const [prompts, setPrompts] = useState([
    { id: 1, text: '' },
    { id: 2, text: '' },
    { id: 3, text: '' },
  ]);
  const [answers, setAnswers] = useState([
    { id: 1, text: '' },
    { id: 2, text: '' },
    { id: 3, text: '' },
  ]);
  const [correctAnswers, setCorrectAnswers] = useState([
    { id: 1, text: '' },
    { id: 2, text: '' },
    { id: 3, text: '' },
  ]);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const handleQuestionTypeChange = (event) => {
    setQuizType(event.target.value);
    setQuestionText(''); // Reset question text when question type changes
    setChoices([]); // Reset choices when question type changes
    setChoiceCount(0);
    setAnswerText(''); // Reset answer text when question type changes
    setImageUploadMap({});
    setShowChoicesCheckbox(false);
    setSelectedAnswer(null);
    if (event.target.value === 'MCQ') {
      setChoices([
        { id: 1, text: '' },
        { id: 2, text: '' },
        { id: 3, text: '' },
        { id: 4, text: '' },
      ]);
    } else if (event.target.value === 'MultipleResponse') {
      setChoices([
        { id: 1, text: '' },
        { id: 2, text: '' },
        { id: 3, text: '' },
        { id: 4, text: '' },
        { id: 5, text: '' },
      ]);
    } else if (event.target.value === 'TrueOrFalse') {
      setChoices([
        { id: 1, text: 'True' },
        { id: 2, text: 'False' },
      ]);
    } else if (event.target.value === 'SelectCorrectImage') {
      setChoices([{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }]);
    } else if (event.target.value === 'MatchThePair') {
      setPrompts([
        { id: 1, text: '' },
        { id: 2, text: '' },
        { id: 3, text: '' },
      ]);
      setAnswers([
        { id: 1, text: '' },
        { id: 2, text: '' },
        { id: 3, text: '' },
        { id: 4, text: '' },
      ]);
      setCorrectAnswers([
        { id: 1, text: '' },
        { id: 2, text: '' },
        { id: 3, text: '' },
      ]);
    }
  };

  const handleAddChoice = () => {
    const newChoiceCount = choiceCount + 1;
    setChoiceCount(newChoiceCount);
    const choiceNumber = choices.length + 1;
    setChoices([...choices, { id: choiceNumber, text: '' }]);
  };

  const handleChoiceTextChange = (id, text) => {
    const updatedChoices = choices.map((choice) =>
      choice.id === id ? { ...choice, text } : choice,
    );
    setChoices(updatedChoices);
  };

  const handleDeleteChoice = (id) => {
    const updatedChoices = choices.filter((choice) => choice.id !== id);
    setChoices(updatedChoices);
  };

  const handleOptionalChange = (event) => {
    setIsOptional(event.target.checked);
  };
  const handleQuestionTextChange = (event) => {
    const text = event.target.value;
    setQuestionText(text);
    setWordCount(text.split(' ').filter(Boolean).length);
  };

  const handleImageUpload = (id, event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file); // Generate image URL
      setImageUploadMap((prev) => ({
        ...prev,
        [id]: imageUrl, // Store image URL instead of file name
      }));
    }
  };
  const handleAddPrompt = () => {
    const newPromptId = prompts.length + 1;
    setPrompts([...prompts, { id: newPromptId, text: '' }]);
  };

  const handleAddAnswer = () => {
    const newAnswerId = answers.length + 1;
    setAnswers([...answers, { id: newAnswerId, text: '' }]);
  };
  const handleAddCorrectAnswer = () => {
    const newCorrectAnswerId = correctAnswers.length + 1;
    setCorrectAnswers([
      ...correctAnswers,
      { id: newCorrectAnswerId, text: '' },
    ]);
  };
  const handlePromptTextChange = (id, text) => {
    const updatedPrompts = prompts.map((prompt) =>
      prompt.id === id ? { ...prompt, text } : prompt,
    );
    setPrompts(updatedPrompts);
  };

  const handleAnswerTextChange = (id, text) => {
    const updatedAnswers = answers.map((answer) =>
      answer.id === id ? { ...answer, text } : answer,
    );
    setAnswers(updatedAnswers);
  };
  const handleCorrectAnswerTextChange = (id, text) => {
    const updatedCorrectAnswers = correctAnswers.map((correctAnswer) =>
      correctAnswer.id === id ? { ...correctAnswer, text } : correctAnswer,
    );
    setCorrectAnswers(updatedCorrectAnswers);
  };
  const handleCheckboxChange = (id) => {
    setSelectedAnswer(selectedAnswer === id ? null : id);
  };
  return (
    <div>
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <TextField
          select
          label="Question Type"
          value={quizType}
          onChange={handleQuestionTypeChange}
        >
          <MenuItem disabled value="select">
            <em>Please select the type of quiz</em>
          </MenuItem>
          <MenuItem value="MCQ">
            Multiple Choice Questions (one correct)
          </MenuItem>
          <MenuItem value="MultipleResponse">
            Multiple Response Quiz(more then one correct)
          </MenuItem>
          <MenuItem value="TrueOrFalse">True or False</MenuItem>
          <MenuItem value="SelectCorrectImage">Select Correct Image</MenuItem>
          <MenuItem value="MatchThePair">Match the Pairs</MenuItem>
        </TextField>
      </FormControl>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Question
          </Typography>

          <JoditEditor
            value={questionText}
            onChange={(newContent) => setQuestionText(newContent)}
            // onChange={handleQuestionTextChange}
            config={{
              placeholder: 'Type your question here', // Specify your desired placeholder text
            }}
          />
        </Grid>

        {quizType === 'SelectCorrectImage' && (
          <Grid
            container
            sx={{ marginTop: '10px', padding: '23px' }}
            spacing={2}
          >
            {choices.map((choice) => (
              <Grid item xs={6} key={choice.id}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px',
                  }}
                >
                  <Tooltip title="This option will be marked as correct">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedAnswer === choice.id}
                          onChange={() => handleCheckboxChange(choice.id)}
                        />
                      }
                      label=""
                      sx={{ marginRight: '8px' }}
                    />
                  </Tooltip>
                  <Box
                    sx={{
                      border: '1px solid #c7c7c7',
                      padding: '8px',
                      width: '24rem',
                      borderRadius: '3px',
                    }}
                  >
                    <input
                      accept="image/*"
                      style={{ display: 'none' }}
                      id={`upload-button-${choice.id}`}
                      type="file"
                      onChange={(e) => handleImageUpload(choice.id, e)}
                    />
                    <label htmlFor={`upload-button-${choice.id}`}>
                      <Button
                        variant="contained"
                        component="span"
                        sx={{ padding: '8px' }}
                      >
                        Img {choice.id}
                      </Button>
                    </label>
                    {imageUploadMap[choice.id] && (
                      <Box
                        component="img"
                        src={imageUploadMap[choice.id]}
                        alt={`Choice ${choice.id}`}
                        sx={{ marginTop: 1, maxWidth: '100%' }}
                      />
                    )}
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}

        {['MCQ', 'MultipleResponse', 'TrueOrFalse'].includes(quizType) && (
          <Grid
            container
            sx={{ marginTop: '10px', padding: '23px' }}
            spacing={2}
          >
            {choices.map((choice) => (
              <Grid item xs={6} key={choice.id}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px',
                  }}
                >
                  {quizType == 'MultipleResponse' && (
                    <Tooltip title="This option will be marked as correct">
                      <FormControlLabel
                        control={<Checkbox />}
                        label=""
                        sx={{ marginRight: '8px' }}
                      />
                    </Tooltip>
                  )}

                  {quizType !== 'MultipleResponse' && (
                    <Tooltip title="This option will be marked as correct">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedAnswer === choice.id}
                            onChange={() => handleCheckboxChange(choice.id)}
                          />
                        }
                        label=""
                      />
                    </Tooltip>
                  )}
                  <TextField
                    placeholder={`Option ${choice.id}`}
                    value={choice.text}
                    onChange={(e) =>
                      handleChoiceTextChange(choice.id, e.target.value)
                    }
                    sx={{ marginRight: 1 }}
                  />
                  {/* <IconButton onClick={() => handleDeleteChoice(choice.id)}>
                  <DeleteIcon />
                </IconButton> */}
                </Box>
              </Grid>
            ))}
            {['MCQ', 'MultipleResponse'].includes(quizType) && (
              <Grid item xs={12}>
                <Button variant="contained" onClick={handleAddChoice}>
                  Add Option
                </Button>
              </Grid>
            )}
          </Grid>
        )}

        {quizType === 'MatchThePair' && (
          <>
            <Grid
              container
              sx={{ padding: '14px', marginTop: '10px' }}
              spacing={2}
            >
              <Grid item xs={4}>
                <Typography variant="h6" gutterBottom>
                  Prompts
                </Typography>
                {prompts.map((prompt, index) => (
                  <TextField
                    key={prompt.id}
                    // label={`Prompt ${index + 1}`}
                    // placeholder={`Prompt ${String.fromCharCode(65 + index)}`}
                    placeholder={`Prompt`}
                    value={prompt.text}
                    onChange={(event) =>
                      handlePromptTextChange(prompt.id, event.target.value)
                    }
                    fullWidth
                    margin="normal"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'left',
                              backgroundColor: '#c0ceff8c',
                              padding: '0px 5px',
                              borderRadius: '4px',
                            }}
                          >
                            {String.fromCharCode(65 + index)}
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                  />
                ))}
                {/* <Button variant="contained" onClick={handleAddPrompt}>
                Add Option
              </Button> */}
                <Tooltip title="Add Prompt">
                  <IconButton
                    onClick={handleAddPrompt}
                    sx={{ backgroundColor: 'white' }}
                  >
                    <AddIcon sx={{ color: '#1976D2' }} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6" gutterBottom>
                  Options
                </Typography>
                {answers.map((answer, index) => (
                  <TextField
                    key={answer.id}
                    // placeholder={`Answer ${index + 1}`}
                    placeholder={`Option`}
                    value={answer.text}
                    onChange={(event) =>
                      handleAnswerTextChange(answer.id, event.target.value)
                    }
                    fullWidth
                    margin="normal"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'left',
                              backgroundColor: '#e1ffc8bf',
                              padding: '0px 7px',
                              borderRadius: '4px',
                            }}
                          >
                            {index + 1}
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                  />
                ))}
                {/* <Button variant="contained" onClick={handleAddAnswer}>
                Add Answer
              </Button> */}
                <Tooltip title="Add Answer">
                  <IconButton
                    color="primary"
                    onClick={handleAddAnswer}
                    sx={{ backgroundColor: 'white' }}
                  >
                    <AddIcon sx={{ color: '#1976D2' }} />
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="h6" gutterBottom>
                  Correct Answer
                </Typography>
                {correctAnswers.map((correctAnswer, index) => (
                  <TextField
                    key={correctAnswer.id}
                    placeholder={`Correct Answer for Prompt${String.fromCharCode(65 + index)}`}
                    value={correctAnswer.text}
                    onChange={(event) =>
                      handleCorrectAnswerTextChange(
                        correctAnswer.id,
                        event.target.value,
                      )
                    }
                    fullWidth
                    margin="normal"
                  />
                ))}
                {/* <Button variant="contained" onClick={handleAddCorrectAnswer}>
                Add Correct Ans
                </Button> */}
                <Tooltip title="Add Correct Answer">
                  <IconButton
                    color="primary"
                    onClick={handleAddCorrectAnswer}
                    sx={{ backgroundColor: 'white' }}
                  >
                    <AddIcon sx={{ color: '#1976D2' }} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid sx={{ padding: '17px' }}>
                <Typography
                  variant="h7"
                  display="block"
                  sx={{ fontWeight: '600' }}
                  gutterBottom
                >
                  Instructions
                </Typography>
                <Typography variant="body2" gutterBottom>
                  1. Prompts:Enter the prompts for the quiz (e.g., "India",
                  "USA"). Each prompt represents an item that needs to be
                  matched.
                </Typography>
                <Typography
                  variant="body2"
                  // sx={{ color: 'red' }}
                  gutterBottom
                >
                  2. Options: Enter the options for the quiz (e.g., "New Delhi",
                  "Washington, D.C."). These are the choices available for
                  matching with the prompts. The number in the green box
                  represents the option's index.
                </Typography>
                <Typography variant="body2" gutterBottom>
                  3. Correct Answer: Enter the correct option index for each
                  prompt. This should be the number corresponding to the correct
                  option for each prompt.
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default QuestionUtility;
