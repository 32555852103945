import axios from 'axios';
const URL = process.env.REACT_APP_URL;

export const RequestApi = async (email, action, eventname, eventid) => {
  try {
    console.log(
      `checkings Requesting certificate ${eventid} for email: ${email} with ${eventname} action: ${action}`,
    ); // Debugging statement
    const response = await axios.post(`${URL}/api/request`, {
      email,
      action,
      eventname,
      eventid,
    });
    console.log('checkings  request response:', response.data); // Debugging statement
    return response;
  } catch (error) {
    console.error(
      'checkings Error requesting certificate:',
      error.response ? error.response.data : error.message,
    ); // Debugging statement
    throw error;
  }
};

export const fetchRequestsApi = async (email, action) => {
  try {
    console.log(); // Debugging statement
    const response = await axios.get(`${URL}/api/request`, {
      email,
      action,
    });
    console.log('checkings  request response:', response.data); // Debugging statement
    return response;
  } catch (error) {
    console.error(
      'checkings Error requesting certificate:',
      error.response ? error.response.data : error.message,
    ); // Debugging statement
    throw error;
  }
};
