import React from 'react';
import { Routes, Route } from 'react-router-dom';
import CertificateDetail from '../Certificates/CertificateDetail';
import CertificateIssued from '../Certificates/CertificateIssued';
import CertificateBlacklisted from '../Certificates/CertificateBlacklisted';
import EditTemplate from '../Certificates/EditCertificate';
import AddTemplate from '../Certificates/DesignCertificate';
import CertificateList from '../Certificates/CertificateList';
import CertificateTemplate from '../Certificates/CertificateTemplate';
import CertificatePage from '../Certificates/CertificatePage';
import SeparateCertificate from '../Certificates/SeparateCertificate';
import EditSettings from '../Certificates/EditSettings';
import { PageContainer } from '../../styles/Common/CommonStyles';

import CertificateRequest from './CertificateRequest';

const Notifications = () => {
  return (
    <PageContainer style={{ marginTop: '35px', minHeight: '71vh' }}>
      <Routes>
        <Route path="/*" element={<CertificatePage />} />
        <Route path="/CertificateList" element={<CertificateList />} />
        <Route path="/CertificateIssued" element={<CertificateIssued />} />
        <Route
          path="/CertificateBlacklisted"
          element={<CertificateBlacklisted />}
        />
        <Route path="/CertificateRequest" element={<CertificateRequest />} />
        <Route path="/CertificateDetail" element={<CertificateDetail />} />
        <Route path="/EditSettings/:id" element={<EditSettings />} />
        <Route
          path="/CertificateTemplate/:id"
          element={<CertificateTemplate />}
        />
        <Route path="/EditTemplate/:id" element={<EditTemplate />} />
        <Route path="/AddTemplate" element={<AddTemplate />} />
        <Route path="/SeparateCertificate" element={<SeparateCertificate />} />
      </Routes>
    </PageContainer>
  );
};

export default Notifications;
