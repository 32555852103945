import React, { useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import JoditReact from 'jodit-react';
import { palette } from '../../../styles/palette';
import { useDispatch } from 'react-redux';
import { addItem } from '../../../features/courses/addCourseDetailsSlice';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/system';
import { DeleteBox } from '../../../styles/Courses/CourseModuleStyles';

const Container = styled('div')`
  margin-top: 63px;
  width: 100%;
`;

const FlexDiv = styled('div')`
  display: flex;
  justify-content: right;
  align-items: center;
  margin-bottom: 10px;
`;

const CloseButton = styled(Button)`
  color: ${palette.primary};
  border-color: ${palette.gray};
  margin-right: 10px;
`;

const SaveButton = styled(Button)`
  background-color: ${palette.primary};
  color: ${palette.white};
  margin-right: 10px;
`;

const StyledGrid = styled(Grid)`
  margin: 20px 20px 20px 0;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: auto;
  max-height: calc(100vh - 180px);
  text-align: justify;
`;
const CreateTopics = ({
  lessonId,
  moduleId,
  selectedItem,
  handleDeleteTopic,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    moduleId: null,
    lessonId: null,
    title: null,
    textDescription: null,
    id: null,
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (selectedItem) {
      setFormData({
        moduleId: selectedItem.moduleId,
        lessonId: selectedItem.lessonId,
        title: selectedItem.title,
        textDescription: selectedItem.textDescription,
        id: selectedItem.id,
      });
    }
  }, [selectedItem]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSave = async () => {
    await dispatch(addItem(formData));
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action) => {
    console.log(action);
    handleMenuClose();
  };
  const handleClose = () => {
    onClose(); // Close the CreateModule component
  };

  return (
    <Container>
      <FlexDiv>
        <div>
          <CloseButton variant="outlined" onClick={handleClose}>
            Close
          </CloseButton>
          <SaveButton variant="contained" onClick={handleSave}>
            Save
          </SaveButton>
          <IconButton onClick={handleMenuClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
            <MenuItem
              onClick={() => handleMenuItemClick('Preview current topic')}
            >
              Preview current topic
            </MenuItem>
            <MenuItem
              onClick={() => handleMenuItemClick('Preview current  module')}
            >
              Preview current module
            </MenuItem>
            <MenuItem
              onClick={() => handleMenuItemClick('Preview complete course')}
            >
              Preview complete course
            </MenuItem>
          </Menu>
        </div>
      </FlexDiv>
      <StyledGrid container justifyContent="center" alignItems="center">
        {formData.title}
      </StyledGrid>

      <TextField
        label="Topic Title"
        value={formData.title}
        onChange={handleChange}
        variant="outlined"
        fullWidth
        name="title"
        style={{ marginBottom: '10px' }}
      />
      <Typography variant="h6" gutterBottom>
        Content
      </Typography>
      <JoditReact
        value={formData.textDescription}
        onChange={(value) =>
          setFormData({ ...formData, textDescription: value })
        }
        name="textDescription"
      />
      <DeleteBox>
        <h4 style={{ marginRight: '10px' }}>
          Are you sure you want to delete this topic? This action is
          irreversible.
        </h4>
        <br />
        <Button
          variant="contained"
          color="error"
          onClick={() => handleDeleteTopic(moduleId, lessonId, selectedItem.id)}
        >
          Delete Topic
        </Button>
      </DeleteBox>
      {/* <div style={{marginTop:"155px"}}></div> */}
    </Container>
  );
};

export default CreateTopics;
