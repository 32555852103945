import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Typography,
  Switch,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Box,
  CircularProgress,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { toast } from 'react-toastify';
import {
  fetchCourseCertificateSettings,
  saveCertificateSettings,
} from '../../../features/courses/CourseUtilitySlice';
import { fetchAllCertificateApi } from '../../../features/certificate/CertificateSlice';
import CourseSettingTabs from './CourseSettingTabs';
import { PageContainer } from '../../../styles/Common/CommonStyles';
import CommonButton from '../../Utilities/CommonButton';
import { Step3ContainerStyle } from '../../../styles/Courses/CourseBuilderStyles';

const CourseCertificateSettings = () => {
  const { courseId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const courseSettings = useSelector(
    (state) =>
      state.CourseUtility?.wholeCourseSetting?.data?.settings
        ?.certificateSettings,
  );

  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    issueType: 'manual',
    completionPercentage: 100,
    Modules: false,
    examPassPercentage: 0,
    assignmentArtifact: false,
    assignmentAutoCorrection: false,
    quizPassPercentage: 0,
    selectedTemplate: '',
  });

  const [selectedTemplateImage, setSelectedTemplateImage] = useState('');

  // Fetching certificate settings and templates
  useEffect(() => {
    const fetchTemplates = async () => {
      setLoading(true); // Start loading
      try {
        const response = await dispatch(fetchAllCertificateApi());
        if (response && response.payload) {
          const fetchedTemplates = response.payload.certificates || [];
          setTemplates(fetchedTemplates);
        } else {
          toast.error('No templates found');
        }
      } catch (error) {
        toast.error('Failed to fetch certificate templates');
      } finally {
        setLoading(false); // Stop loading
      }
    };
    if (courseId) {
      fetchTemplates();
    }
  }, [courseId, dispatch]);

  // Setting form data based on fetched course settings
  useEffect(() => {
    if (courseSettings) {
      setFormData((prev) => ({
        ...prev,
        ...courseSettings,
      }));
    }
  }, [courseSettings]);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSaveCertificateSetting = async () => {
    try {
      await dispatch(saveCertificateSettings({ courseId, formData }));
      toast.success('Certificate settings saved successfully');
    } catch (error) {
      toast.error('Failed to save certificate settings');
    }
  };

  useEffect(() => {
    const selectedTemplate = templates.find(
      (template) => template.certificateName === formData.selectedTemplate,
    );
    setSelectedTemplateImage(
      selectedTemplate ? selectedTemplate.certificateImage : '',
    );
  }, [formData.selectedTemplate, templates]);

  return (
    <>
      <PageContainer>
        <CourseSettingTabs />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '90%',
            margin: 'auto',
            marginTop: '45px',
          }}
        >
          <ArrowBackIosIcon
            style={{ cursor: 'pointer' }}
            onClick={() => navigate('/CourseBuilder')}
          />
          <CommonButton
            label="Save settings"
            onClick={handleSaveCertificateSetting}
          />
        </div>

        <Container sx={Step3ContainerStyle}>
          <Grid container spacing={4}>
            {/* Left side (Certificate settings) */}
            <Grid item xs={12} md={4}>
              {/* Add a scrollable container with a fixed height */}
              <Box
                sx={{
                  maxHeight: '500px', // Adjust the height as per your design
                  overflowY: 'auto',
                  paddingRight: '10px', // Add padding for scrollbar space
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography>Select Certificate Template</Typography>
                    <FormControl fullWidth>
                      <Select
                        name="selectedTemplate"
                        value={formData.selectedTemplate}
                        onChange={handleChange}
                      >
                        {templates.map((template) => (
                          <MenuItem
                            key={template.id}
                            value={template.certificateName}
                          >
                            {template.certificateName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Issue Type</Typography>
                    <FormControl fullWidth>
                      <Select
                        name="issueType"
                        value={formData.issueType}
                        onChange={handleChange}
                      >
                        <MenuItem value="manual">Manual Issue</MenuItem>
                        <MenuItem value="automatic">Automatic Issue</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography>
                      Completion Percentage for Certificate
                    </Typography>
                    <TextField
                      name="completionPercentage"
                      type="number"
                      value={formData.completionPercentage}
                      onChange={handleChange}
                      fullWidth
                      disabled={formData.issueType === 'manual'}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography>Exam Pass Percentage</Typography>
                    <TextField
                      name="examPassPercentage"
                      type="number"
                      value={formData.examPassPercentage}
                      onChange={handleChange}
                      fullWidth
                      disabled={formData.issueType === 'manual'}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography>Modules to be Completed 100%</Typography>
                    <Switch
                      checked={formData.Modules}
                      onChange={handleChange}
                      name="Modules"
                      disabled={formData.issueType === 'manual'}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography>Assignment (No Artifact Correction)</Typography>
                    <Switch
                      checked={formData.assignmentArtifact}
                      onChange={handleChange}
                      name="assignmentArtifact"
                      disabled={formData.issueType === 'manual'}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography>Assignment (Auto Correction)</Typography>
                    <Switch
                      checked={formData.assignmentAutoCorrection}
                      onChange={handleChange}
                      name="assignmentAutoCorrection"
                      disabled={formData.issueType === 'manual'}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography>Quiz Pass Percentage</Typography>
                    <TextField
                      name="quizPassPercentage"
                      type="number"
                      value={formData.quizPassPercentage}
                      onChange={handleChange}
                      fullWidth
                      disabled={formData.issueType === 'manual'}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            {/* Right side (Certificate preview) */}
            <Grid item xs={12} md={8}>
              <Typography>Certificate Preview</Typography>
              {loading ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '500px',
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : selectedTemplateImage ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px solid #ddd',
                    padding: '10px',
                    maxHeight: '500px',
                    maxWidth: '100%',
                    overflow: 'hidden',
                  }}
                >
                  <img
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      objectFit: 'contain',
                    }}
                    src={selectedTemplateImage}
                    alt="Certificate Preview"
                  />
                </Box>
              ) : (
                <Typography>No template selected</Typography>
              )}
            </Grid>
          </Grid>
        </Container>
      </PageContainer>
    </>
  );
};

export default CourseCertificateSettings;
