import React from 'react';
import { useParams } from 'react-router-dom';
import BackButton from '../Utilities/BackButton';
import { TextField } from '@mui/material';
import { HeadingH2 } from '../../styles/Common/CommonStyles';
import { useSelector } from 'react-redux';
import {
  Head,
  Details,
  ImgSection,
  Info,
  Profile,
  AnnImg,
} from '../../styles/Calendar/CalendarStyle';
import { useTranslation } from 'react-i18next';

// Function to format date as MM-DD-YYYY
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
};
const formatTime = (timeObj) => {
  try {
    const date = new Date(timeObj); // Assuming timeObj is the date object you mentioned
    const hours = date.getHours().toString().padStart(2, '0'); // Get hours and pad with leading zero if necessary
    const minutes = date.getMinutes().toString().padStart(2, '0'); // Get minutes and pad with leading zero if necessary
    return `${hours}:${minutes}`; // Return formatted time string
  } catch (error) {
    console.error('Error formatting time:', error);
    return 'Invalid time';
  }
};
const AnnounceDetails = () => {
  const { announcementNo } = useParams();
  const announcement = useSelector((state) => state.calendar.announcement);

  const filteredAnnouncements = announcement?.filter(
    (announcement) => announcement._id === announcementNo,
  );
  console.log(filteredAnnouncements);

  const announcementDetails = filteredAnnouncements.length
    ? filteredAnnouncements[0]
    : null;

  const { t } = useTranslation();
  return (
    <Details>
      <Head>
        <BackButton />
        <HeadingH2>{announcementDetails?.announcementNo}</HeadingH2>
      </Head>
      <Profile>
        <Info>
          <TextField
            label={t('calender.announcementNumb')}
            variant="outlined"
            value={announcementDetails?.announcementNo}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ width: '90%' }}
          />
          <TextField
            label={t('calender.eventNameLabel')}
            variant="outlined"
            value={announcementDetails?.eventName}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ width: '90%' }}
          />
          <TextField
            label={t('calender.startdateLabel')}
            variant="outlined"
            margin="normal"
            value={formatDate(announcementDetails?.eventDate)}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ width: '90%' }}
          />
          <TextField
            label={t('calender.starttimeLabel')}
            variant="outlined"
            margin="normal"
            value={formatTime(announcementDetails?.startTime)}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ width: '90%' }}
          />
          <TextField
            label={t('calender.eventTypeLabel')}
            variant="outlined"
            value={announcementDetails?.type}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ width: '90%' }}
          />
        </Info>
        <ImgSection>
          <AnnImg src={announcementDetails?.image} alt="User Avatar" />
        </ImgSection>
      </Profile>
    </Details>
  );
};

export default AnnounceDetails;
