import React, { useState } from 'react';
import {
  ContactContMain, ContactFormCont, TextFieldStyle, Desc, ContactFormMain, ContactImg,
  SubmitButton, ContactFormCont2, ContactFormMainsub, Title2, Title3, Desc2,
  ContSub2, ContSub2Main, ContactFormCont3, SocialMediaIcon, Desc3, TitleCont,
  TitleLogo, TitleDash2, TitleContB, TextFieldStyle2, ContSub2B, ContSub2MainB,
} from '../../styles/Landing/ContactusStyle';
import MinHeightTextarea from './MinHeightTextarea';
import { TitleB, TitleDash } from '../../styles/Common/CommonStyles';
import TextField from '@mui/material/TextField';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'; // Importing styles for PhoneInput
import ContactUsImg from '../../images/Contactus.jpg';
import linkedin from '../../images/linkedin.png';
import ph from '../../images/telephone.png';
import Em from '../../images/mail.png';
import Ad from '../../images/location.png';
import social from '../../images/socialMedia.png';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const Contactus = () => {
  const [errorState, setErrorState] = useState({
    name: false,
    email: false,
    phone: false,
    message: false,
  });
  const [state, setState] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (value === '') {
      setErrorState((prev) => ({ ...prev, [name]: true }));
    } else if (name === 'email' && !validateEmail(value)) {
      setErrorState((prev) => ({ ...prev, [name]: true }));
    } else if (name === 'phone' && !validatePhone(value)) {
      setErrorState((prev) => ({ ...prev, [name]: true }));
    } else {
      setErrorState((prev) => ({ ...prev, [name]: false }));
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    return typeof phone === 'string' && phone.length > 0;
  };

  const handleSubmit = async () => {
    let hasError = false;

    if (state.name === '' || errorState.name === true) {
      setErrorState((prev) => ({ ...prev, name: true }));
      hasError = true;
    }

    if (state.email === '' || errorState.email === true) {
      setErrorState((prev) => ({ ...prev, email: true }));
      hasError = true;
    }

    if (state.phone === '' || errorState.phone === true) {
      setErrorState((prev) => ({ ...prev, phone: true }));
      hasError = true;
    }

    if (state.message === '' || errorState.message === true) {
      setErrorState((prev) => ({ ...prev, message: true }));
      hasError = true;
    }

    if (hasError) return;

    try {
      const response = await fetch(
        'https://leapot-lms-backend.onrender.com/api/addContactUs',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: state.name,
            email: state.email,
            phone: state.phone,
            message: state.message,
          }),
        },
      );

      if (response.ok) {
        toast.info('Your details have been forwarded to the concerned team.');
        setErrorState({
          name: false,
          email: false,
          phone: false,
          message: false,
        });
        setState({
          name: '',
          email: '',
          phone: '',
          message: '',
        });
      } else {
        toast.error('Failed to submit the form. Please try again later.');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to submit the form. Please try again later.');
    }
  };

  const handleChange = (name, value) => {
    let isError = false;

    if (name === 'phone' && !validatePhone(value)) {
      isError = true;
    }

    setErrorState((prev) => ({ ...prev, [name]: isError }));
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const { t } = useTranslation();
  return (
    <ContactContMain>
      <TitleContB>
        <TitleB> {t("landing.ContactUsLabel")}</TitleB>
        <TitleDash />
      </TitleContB>
      <Desc>{t("landing.questionText")}</Desc>
      <ContactFormMain>
        <ContactFormMainsub>
          <ContactImg src={ContactUsImg} />
          <ContactFormCont>
            <TextField
              required
              label={t("landing.nameTextLabel")}
              type="text"
              name="name"
              id="name"
              variant="outlined"
              placeholder={t("landing.nameTextLabel")}
              sx={TextFieldStyle}
              error={errorState.name}
              helperText={errorState.name && "This field is required"}
              value={state.name}
              onBlur={handleBlur}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
            <TextField
              required
              label={t("landing.emailTextLabel")}
              type="text"
              name="email"
              id="email"
              variant="outlined"
              placeholder={t("landing.emailTextLabel")}
              sx={TextFieldStyle}
              error={errorState.email}
              helperText={errorState.email && (state.email === '' ? "This field is required" : "Invalid email format")}
              value={state.email}
              onBlur={handleBlur}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
            <PhoneInput 
              international
              defaultCountry="IN"
              name="phone"
              value={state.phone}
              onChange={(value) => handleChange('phone', value)}
              error={errorState.phone}
              style={{
                paddingTop: '20px', // Adjust padding as needed
                width: '100%', // Make the input larger
                fontSize: '1.5rem', // Adjust font size as needed
              }}
              className="phone-input"
            />
            <MinHeightTextarea
              error={errorState.message}
              helperText={errorState.message && "This field is required"}
              value={state.message}
              onChange={(newValue) => setState({ ...state, message: newValue })}
            />
            <SubmitButton onClick={handleSubmit}>{t("landing.submitLabel")}</SubmitButton>
          </ContactFormCont>
        </ContactFormMainsub>
      </ContactFormMain>
      <ContactFormCont2>
        <TitleB>{t("landing.ConnectLabel")}</TitleB>
        <TitleDash2 />
        <ContactFormCont3>
          <ContSub2Main>
            <ContSub2>
              <TitleCont>
                <TitleLogo src={ph} />
                <Title3>{t("landing.phoneTextLabel")}</Title3>
              </TitleCont>
              <Desc2>+917038585222</Desc2>
            </ContSub2>
            <ContSub2>
              <TitleCont>
                <TitleLogo src={Em} />
                <Title3>{t("landing.emailTextLabel")}</Title3>
              </TitleCont>
              <Desc2>contact@leapot.in</Desc2>
            </ContSub2>
          </ContSub2Main>
          <ContSub2MainB>
            <ContSub2>
              <TitleCont>
                <TitleLogo src={social} />
                <Title3>{t("landing.socialMedia")}</Title3>
              </TitleCont>
              <Desc3>
                <a href="https://in.linkedin.com/company/leapot-technologies" target="_blank" rel="noopener noreferrer">
                  <SocialMediaIcon src={linkedin} />
                </a>
              </Desc3>
            </ContSub2>
            <ContSub2B>
              <TitleCont>
                <TitleLogo src={Ad} />
                <Title3>{t("landing.addressTextLabel")}</Title3>
              </TitleCont>
              <Desc2>{t("landing.leapotOfficeAddress")}</Desc2>
            </ContSub2B>
          </ContSub2MainB>
        </ContactFormCont3>
      </ContactFormCont2>
      <style>
        {`
          .phone-input__dropdown {
            max-height: 200px;
            overflow-y: auto;
            border: 1px solid #ccc;
            background-color: #fff;
            position: absolute;
            z-index: 1000;
            width: 100%;
            margin-top: 4px;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
          }
        `}
      </style>
    </ContactContMain>
  );
};

export default Contactus;
