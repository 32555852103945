import React, { useState }  from 'react';
import axios from 'axios';
import ScormPlayer from '../CreateCourse/ScromPlayer';
import Modal from '@mui/material/Modal';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import {
  Tooltip,
  IconButton,
  Grid,
  Typography,
  Button,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { palette } from '../../../styles/palette';
import { styled } from '@mui/system';
import {EditorContainer,DeleteBox,StyledTextField,ButtonContainer,BackButtonContainer,HeaderContainer} from '../../../styles/Courses/CourseModuleStyles';


const DiscardButton = styled(Button)`
  color: ${palette.primary};
  border-color:  ${palette.gray};
  margin-right: 10px;
  width: 180px;
`;

const SaveButton = styled(Button)`
  background-color: ${palette.primary};
  color: ${palette.white};
`;

const FileInput = styled('input')({
  padding: '10px',
  fontSize: '16px',
  border: '1px solid #ccc',
  borderRadius: '5px',
  marginRight: '10px',
});

const ProgressModal = ({ open, progress, completed, onClose }) => (
  <Modal open={open} onClose={completed ? onClose : () => { }}>
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="h6" component="h2">
        Uploading files, please wait...
      </Typography>
      <Box sx={{ width: '100%', mt: 2 }}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
      <Typography sx={{ mt: 2 }}>{`${Math.round(progress)}%`}</Typography>
    </Box>
  </Modal>
);

const UploadScorm = ({ onClose, handleDeleteTopic }) => {
  const [files, setFiles] = useState([]);
  const [uploadUrls, setUploadUrls] = useState({});
  const [scormUrl, setScormUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [scormFolderPath, setScormFolderPath] = useState('');
  const [progress, setProgress] = useState(0);
  const [completed, setCompleted] = useState(false);

  const handleOnClose = () => {
    onClose();
  };

  const onDrop = async (e) => {
    const newFiles = Array.from(e.target.files);
    setFiles([...files, ...newFiles]);
    for (const file of newFiles) {
      await requestUploadUrl(file.webkitRelativePath, file.type);
    }
  };

  const requestUploadUrl = async (filePath, fileType) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}api/uploadscrom`, {
        fileName: filePath,
        fileType: fileType,
      });
      const uploadUrl = response.data.url;

      setUploadUrls(prevUrls => ({ ...prevUrls, [filePath]: response.data.url }));

      setScormFolderPath(response.data.path); // Save the folder path

    } catch (error) {
      console.error('Error requesting upload URL:', error);
    }
  };

  const uploadToS3 = async (file, url) => {
    try {
      const data = await axios.put(url, file, {
        headers: {
          'Content-Type': file.type,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(prevProgress => prevProgress + (percentCompleted / files.length));
        },
      });
      console.log('File uploaded successfully', data);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleSendMessage = async () => {
    setLoading(true);
    setProgress(0);
    setCompleted(false);

    for (const file of files) {
      const url = uploadUrls[file.webkitRelativePath];
      if (url) {
        await uploadToS3(file, url);
      }
    }
    const mainScormFile = files.find(file => file.name === 'story.html');
    if (mainScormFile) {
      const baseUrl = new URL(uploadUrls[mainScormFile.webkitRelativePath]).origin;
      const scormUrl = `${baseUrl}/${scormFolderPath}${mainScormFile.webkitRelativePath}`;
      setScormUrl(scormUrl);

    }

    setCompleted(true);
    setLoading(false);

  };

  const handleDelete =()=>{
    handleDeleteTopic(selectedItem?.moduleId , selectedItem?.lessonId , selectedItem?.id)
  }

  console.log("abc formdata scrom",FormData);
  return (
    <div>
      <HeaderContainer>
        {/* Grid container aligned to the left */}
        <BackButtonContainer container alignItems="center" spacing={1} sx={{ mt: 1, mb: 2 }}>
          <Grid item>
            <Tooltip title="Go Back">
              <IconButton
                size="small"
                onClick={handleOnClose}
                sx={{ color: `${palette.THEME_THREE}` }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item sx={{ mt: 1 }}>
            <Typography variant="h5" gutterBottom>
              Scrom
            </Typography>
          </Grid>
        </BackButtonContainer>

        {/* Buttons div aligned to the right */}
        <ButtonContainer>
        <DiscardButton variant="outlined">Discard Changes</DiscardButton>
          <SaveButton variant="contained" >Save</SaveButton>
        </ButtonContainer>
      </HeaderContainer>
      <EditorContainer>
        <StyledTextField
          variant="outlined"
          label="Title"
        />
        <Grid item xs={12} sx={{ mt: 2 }}>
          <FileInput
            type="file"
            webkitdirectory="true"
            directory="true"
            multiple
            onChange={onDrop}
            
          />
          <Button
            variant="contained"
            onClick={handleSendMessage}
            disabled={files.length === 0 || loading}
          >
            Upload
          </Button>
        </Grid>
        <ProgressModal
        open={loading}
        progress={progress}
        completed={completed}
        onClose={() => setLoading(false)}
      />
        {scormUrl && 
        <>
         <Typography variant="h6" style={{marginTop:'5px', marginBottom:'5px'}}>Uploaded Scorm</Typography>
        <ScormPlayer scormUrl={scormUrl} />
        </>
        }
      </EditorContainer>

      <DeleteBox>
      <h4 style={{ marginRight: '10px' }}>
      Are you sure you want to delete this topic? This action is irreversible.
      </h4><br/>
      <Button
        variant="contained"
        color="error"
        onClick={handleDelete}     
         >
        Delete Topic
      </Button>
         </DeleteBox>
    </div>
  );
};

export default UploadScorm;
