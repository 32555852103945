import React from 'react';
import { Grid } from '@mui/material';
import ContentList from '../../../store/ContentList.json';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import VolumeMuteOutlinedIcon from '@mui/icons-material/VolumeMuteOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import MovieOutlinedIcon from '@mui/icons-material/MovieOutlined';
import MmsOutlinedIcon from '@mui/icons-material/MmsOutlined';
import SettingsInputAntennaOutlinedIcon from '@mui/icons-material/SettingsInputAntennaOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import PollOutlinedIcon from '@mui/icons-material/PollOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined'; // New import for the icon
import {
  GridStyle,
  CText,
  Content,
  ContentHeading,
} from '../../../styles/Courses/StepStyles';
import { ManualStyle } from '../../../styles/Courses/CourseBuilderStyles';

const AddTopicContainer = ({ onContentClick }) => {
  const handleContentClick = (item) => {
    console.log('checking', item);
    onContentClick(item);
  };

  return (
    <>
      <Grid
        container
        spacing={3}
        sx={ManualStyle}
        style={{ overflowY: 'auto', height: '470px', width: '100%' }}
      >
        {ContentList.map((item) => {
          return (
            <Grid
              item
              xs={5.7}
              sx={GridStyle}
              onClick={() => handleContentClick(item.Topic)}
            >
              {item.Topic === 'Video' && (
                <VideocamOutlinedIcon fontSize="large" />
              )}
              {item.Topic === 'Audio' && (
                <VolumeMuteOutlinedIcon fontSize="large" />
              )}
              {item.Topic === 'Text' && (
                <TextSnippetOutlinedIcon fontSize="large" />
              )}
              {item.Topic === 'PDF' && (
                <PictureAsPdfOutlinedIcon fontSize="large" />
              )}
              {item.Topic === 'Download' && (
                <CloudDownloadOutlinedIcon fontSize="large" />
              )}
              {item.Topic === 'Presentation' && (
                <MovieOutlinedIcon fontSize="large" />
              )}
              {item.Topic === 'Multimedia' && (
                <MmsOutlinedIcon fontSize="large" />
              )}
              {item.Topic === 'Live' && (
                <SettingsInputAntennaOutlinedIcon fontSize="large" />
              )}
              {/* {item.Topic === 'Coding Test' && (
                <SettingsInputAntennaOutlinedIcon fontSize="large" />
              )} */}
              {item.Topic === 'Scorm' && (
                <SettingsInputAntennaOutlinedIcon fontSize="large" />
              )}
              {item.Topic === 'Quiz' && <QuizOutlinedIcon fontSize="large" />}
              {item.Topic === 'Assignment' && (
                <AssignmentOutlinedIcon fontSize="large" />
              )}
              {item.Topic === 'Exam' && (
                <AssessmentOutlinedIcon fontSize="large" />
              )}

              {item.Topic === 'Survey' && <PollOutlinedIcon fontSize="large" />}

              {item.Topic === 'Attendify' && (
                <VerifiedUserOutlinedIcon fontSize="large" />
              )}

              {item.Topic === 'Evaluate' && (
                <PollOutlinedIcon fontSize="large" />
              )}

              {item.CardIcon}
              <Content>
                <ContentHeading>{item.Topic}</ContentHeading>
                <CText>{item.Desc}</CText>
              </Content>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default AddTopicContainer;
