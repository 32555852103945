import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  TextField,
  Button,
  IconButton,
  Tooltip,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { palette } from '../../../styles/palette';
import {
  updateLesson,
  deleteLesson,
} from '../../../features/courses/addCourseDetailsSlice';
import JoditReact from 'jodit-react';
import { styled } from '@mui/system';
import {
  StyledBox,
  FlexDiv,
  DeleteBox,
} from '../../../styles/Courses/CourseModuleStyles';

const CloseButton = styled(Button)`
  color: ${palette.primary};
  border-color: ${palette.gray};
  margin-right: 10px;
`;

const SaveButton = styled(Button)`
  background-color: ${palette.primary};
  color: ${palette.white};
  margin-right: 8px;
`;

const CreateLesson = ({ lesson, onClose }) => {
  const dispatch = useDispatch();
  const [lessonDetails, setLessonDetails] = useState({ ...lesson });

  useEffect(() => {
    setLessonDetails({ ...lesson });
  }, [lesson]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLessonDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    dispatch(updateLesson(lessonDetails));
    onClose(); // Close the CreateLesson component
  };

  const handleCancel = () => {
    onClose(); // Close the CreateLesson component
  };

  const handleDelete = () => {
    dispatch(deleteLesson({ moduleId: lesson.moduleId, lessonId: lesson.id }));
    onClose(); // Close the CreateLesson component
  };

  return (
    <StyledBox>
      <FlexDiv>
        <Grid item xs={12}>
          <CloseButton variant="outlined" onClick={handleCancel}>
            Close
          </CloseButton>
          <SaveButton variant="contained" onClick={handleSubmit}>
            Save
          </SaveButton>
        </Grid>
      </FlexDiv>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Typography style={{ marginBottom: '8px', marginTop: '15px' }}>
            Lesson Title
          </Typography>
          <TextField
            id="outlined-basic"
            placeholder="Enter Lesson Name"
            variant="outlined"
            fullWidth
            name="title"
            value={lessonDetails.title}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>Description</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="outlined-basic"
            placeholder="Enter Description"
            variant="outlined"
            rows="5"
            multiline
            fullWidth
            name="description"
            value={lessonDetails.description}
            onChange={handleChange}
          />
        </Grid>
        {/* <Grid item xs={6}>
          <Typography>Lesson Number</Typography>
          <TextField
            id="outlined-basic"
            placeholder="Enter Lesson Number"
            variant="outlined"
            fullWidth
            name="number"
            type="number"
            value={lessonDetails.number}
            onChange={handleChange}
            required
          />
        </Grid> */}
        <Grid item xs={6}>
          <Typography>Estimated Time</Typography>
          <TextField
            id="outlined-basic"
            placeholder="Enter Estimated Time"
            variant="outlined"
            fullWidth
            name="EstTime"
            value={lessonDetails.EstTime}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>Learning Objectives</Typography>
        </Grid>
        <Grid item xs={12}>
          <JoditReact value={lessonDetails.objectives} config={{ style: {} }} />
        </Grid>
        <Grid item xs={12}>
          <DeleteBox>
            <h4 style={{ marginRight: '10px' }}>
              Are you sure you want to delete this lesson? This action is
              irreversible.
            </h4>
            <br />
            <Button variant="contained" color="error" onClick={handleDelete}>
              Delete Lesson
            </Button>
          </DeleteBox>
        </Grid>
      </Grid>
    </StyledBox>
  );
};

export default CreateLesson;
