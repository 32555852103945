import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CommonButton from '../Utilities/CommonButton';
import NotificationTabs from './NotificationTabs';
import { useDispatch, useSelector } from 'react-redux';
import JoditEditor from 'jodit-react';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import {
  fetchSingleSentNotification,
  fetchNotificationTemplate,
  sendNotification,
  fetchEmailWithName,
} from '../../features/notification/NotificationSlice';

import { Page } from '../../styles/Notification/NotificationListStyles';

import {
  Tab,
  Tabs,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  MenuItem,
} from '@mui/material';

import { WholePage } from '../../styles/Certificate/CertificateStyle';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const SendNotification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [tabValue, setTabValue] = useState(0);
  const [notificationType, setNotificationType] = useState('');
  const [recipient, setRecipient] = useState([]);
  const [cc, setCc] = useState([]);
  const [subject, setSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [openPreview, setOpenPreview] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const initialLoad = useRef(true);
  const notificationTemplates = useSelector(
    (state) => state.notification.template,
  );
  const [validUserEmails, setValidUserEmails] = useState([]);

  const emails = useMemo(() => {
    return validUserEmails?.map((userData) => userData.email);
  }, [validUserEmails]);

  useEffect(() => {
    dispatch(fetchEmailWithName())
      .then((response) => {
        setValidUserEmails(response.payload.users);
      })
      .catch((error) => {
        console.error('Error fetching user emails:', error);
      });
  }, [dispatch]);

  const config = useMemo(
    () => ({
      height: 400,
      readonly: false,
      placeholder: '',
    }),
    [],
  );

  useEffect(() => {
    dispatch(fetchNotificationTemplate());
  }, [dispatch]);

  useEffect(() => {
    const fetchTemplateContent = async () => {
      try {
        const response = await dispatch(fetchSingleSentNotification(id));
        const notificationData = response.payload[0];
        const { user_recipients, cc, email_Type, email_Subject, email_Body } =
          notificationData;

        setTabValue(user_recipients.length > 1 ? 1 : 0);
        setRecipient(user_recipients);
        setNotificationType(email_Type);
        setCc(cc);
        setSubject(email_Subject);
        setEmailBody(email_Body);
      } catch (error) {
        console.error('Error fetching notification content:', error);
      }
    };
    if (initialLoad.current) {
      fetchTemplateContent();
      initialLoad.current = false;
    }
  }, [dispatch, id]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handlePreview = () => {
    setOpenPreview(true);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const handleRecipientChange = (event, value) => {
    if (tabValue === 0) setRecipient([value]);
    else if (tabValue === 1) {
      setRecipient(value);
    }
  };

  const handleCcChange = (event, value) => {
    setCc(value);
  };

  const handleNotificationTypeChange = (event) => {
    const selectedType = event.target.value;
    const template = notificationTemplates.find(
      (type) => type.notificationType === selectedType,
    );

    setNotificationType(selectedType);
    setSubject(template?.subject || '');
    setEmailBody(template?.notificationBody || '');
  };

  const handleSendNotification = () => {
    if (recipient.length <= 0 || !subject || !emailBody || !notificationType) {
      alert('Please fill in all required fields');
      return;
    }

    setIsSending(true);

    let recipientName = 'User';
    if (tabValue === 0) {
      const recipientObject = validUserEmails?.find(
        (data) => data.email === recipient[0],
      );

      if (recipientObject.firstname && recipientObject.lastname) {
        recipientName =
          recipientObject.firstname + ' ' + recipientObject.lastname;
      } else if (recipientObject.firstname) {
        recipientName = recipientObject.firstname;
      } else {
        recipientName = recipient[0]?.split('@')[0];
      }
    }
    const personalizedEmailBody = emailBody.replace('{Name}', recipientName);

    const notificationData = {
      user_recipients: recipient,
      cc,
      email_Subject: subject,
      email_Body: personalizedEmailBody,
      email_Type: notificationType,
    };

    console.log('Notification Data: ', notificationData);

    dispatch(sendNotification(notificationData))
      .then(() => {
        toast.success('Notification sent successfully!');
        setNotificationType('');
        setRecipient([]);
        setCc([]);
        setSubject('');
        setEmailBody('');
        navigate('/Notification/displaynotification');
      })
      .catch((error) => {
        console.error('Error sending notification:', error);
        toast.error('Failed to send notification. Please try again.');
      })
      .finally(() => {
        setIsSending(false);
      });
  };

  useEffect(() => {
    console.log('Recipient List: ', recipient);
  }, [recipient]);

  const { t } = useTranslation();
  return (
    <>
      <NotificationTabs />
      <WholePage>
        <Page>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label={t('notification.IndividualSendLabel')} />
            <Tab label={t('notification.bulkLabel')} />
          </Tabs>
          {tabValue === 0 && (
            <div>
              <TextField
                select
                label={t('notification.selectNotificationLabel')}
                variant="outlined"
                margin="normal"
                fullWidth
                value={notificationType}
                onChange={handleNotificationTypeChange}
              >
                {notificationTemplates?.map((type) => (
                  <MenuItem
                    key={type.notificationType}
                    value={type.notificationType}
                  >
                    {type.notificationType}
                  </MenuItem>
                ))}
              </TextField>

              <Autocomplete
                fullWidth
                sx={{
                  marginBottom: '15px',
                }}
                disablePortal
                options={emails}
                value={recipient}
                onChange={handleRecipientChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('notification.RecipientLabel')}
                  />
                )}
              />

              <Autocomplete
                multiple
                sx={{
                  marginBottom: '15px',
                }}
                options={emails}
                getOptionLabel={(option) => option}
                value={cc}
                filterSelectedOptions
                onChange={handleCcChange}
                renderInput={(params) => <TextField {...params} label="CC" />}
              />

              <TextField
                label={t('subjectLabel')}
                variant="outlined"
                margin="normal"
                fullWidth
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <JoditEditor
                value={emailBody}
                onChange={(newContent) => setEmailBody(newContent)}
                config={config}
              />
              <Button onClick={handlePreview}>
                {t('notification.previewLabel')}
              </Button>
            </div>
          )}

          {tabValue === 1 && (
            <div>
              <TextField
                select
                label={t('notification.typeNotificationLabel')}
                variant="outlined"
                margin="normal"
                fullWidth
                value={notificationType}
                onChange={handleNotificationTypeChange}
              >
                {notificationTemplates?.map((type) => (
                  <MenuItem
                    key={type.notificationType}
                    value={type.notificationType}
                  >
                    {type.notificationType}
                  </MenuItem>
                ))}
              </TextField>

              <Autocomplete
                multiple
                sx={{
                  marginBottom: '15px',
                }}
                options={emails}
                getOptionLabel={(option) => option}
                value={recipient}
                filterSelectedOptions
                onChange={handleRecipientChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('notification.RecipientLabel')}
                  />
                )}
              />

              <Autocomplete
                multiple
                sx={{
                  marginBottom: '15px',
                }}
                options={emails}
                getOptionLabel={(option) => option}
                value={cc}
                filterSelectedOptions
                onChange={handleCcChange}
                renderInput={(params) => <TextField {...params} label="CC" />}
              />

              <TextField
                label={t('subjectLabel')}
                variant="outlined"
                margin="normal"
                fullWidth
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <JoditEditor
                value={emailBody}
                onChange={(newContent) => setEmailBody(newContent)}
                config={config}
              />
              <Button onClick={handlePreview}>
                {t('notification.previewLabel')}
              </Button>
            </div>
          )}
        </Page>
      </WholePage>

      <Dialog open={openPreview} onClose={handleClosePreview} >
        <DialogTitle>{t('notification.previewLabel')}</DialogTitle>
        <DialogContent  sx={{
      width: '550px',
      height: '800px',
    }}>
          <Typography variant="h6">{subject}</Typography>
          <div dangerouslySetInnerHTML={{ __html: emailBody }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePreview} color="primary">
            {t('notification.closeLabel')}
          </Button>
          <Button onClick={handleSendNotification} color="primary">
            {t('notification.sendLabel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SendNotification;
