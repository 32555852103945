import React from 'react';
import {
  AboutUsMainConatiner,
  HeaderImage,
  MainHeading,
  TitleHeading,
  AboutUsContainer,
  Paragraph,
  AboutContainer,
  InnerContainer,
  InnerSubcontainer,
  Heading,
  Description,
  AboutImage1,
  Container,
  InnerContainer2,
  AboutImage1B,
  Container2,
  InnerContainer2B,
  InnerSubcontainer2B,
  AboutImageSub2,
  HeadingB,
  DescriptionB,
  HeadMain,
} from '../../styles/Landing/AboutusStyle';
import aboutUs from '../../images/prbg1.jpg';
import Reception1 from '../../images/Vision.jpg';
import Reception2 from '../../images/Mission.jpg';

import { TitleB, TitleDash, TitleHeadComCont } from '../../styles/Common/CommonStyles';
import { useTranslation } from 'react-i18next';




// import ScrollToTop from '../ScrollToTop';

export default function AboutUs(scrollToTop, classes, isVisible) {
  const { t } = useTranslation();
  return (
    <>
      <AboutUsMainConatiner>
        <HeadMain>
          <TitleHeadComCont>
            <TitleB
              // data-aos="fade-left"
              // data-aos-easing="ease-in-out"
              // data-aos-duration="500"
              // data-aos-delay="150"
            >
              {"About Us"}
            </TitleB>
            <TitleDash
              // data-aos="fade-left"
              // data-aos-easing="ease-in-out"
              // data-aos-duration="500"
              // data-aos-delay="200"
              />
          </TitleHeadComCont>
        </HeadMain>
        <HeaderImage
          bgImage={aboutUs}
        >
          <MainHeading>
            <TitleHeading
              // data-aos="fade-left"
              // data-aos-easing="ease-in-out"
              // data-aos-duration="500"
              // data-aos-delay="300"
            >
              {t("landing.unveilingVisionLabel")}
              <br/>
              {t("landing.inspirationLabel")}
            </TitleHeading>
          </MainHeading>
        </HeaderImage>
        <AboutUsContainer>
          <TitleHeadComCont>
            <TitleB
              data-aos="fade-left"
              data-aos-easing="ease-in-out"
              data-aos-duration="500"
              data-aos-delay="150"
            >
              {t("landing.aboutLeapotLabel")}
            </TitleB>
            <TitleDash
              data-aos="fade-left"
              data-aos-easing="ease-in-out"
              data-aos-duration="500"
              data-aos-delay="150"
            />
          </TitleHeadComCont>
          <Paragraph>
            {t("landing.welcomeMsgText")}

            <br></br>
            <br></br>
            {t("landing.aboutLeapotText")}
          </Paragraph>
        </AboutUsContainer>
        <AboutContainer>
          <TitleHeadComCont>
            <TitleB
              // data-aos="fade-left"
              // data-aos-easing="ease-in-out"
              // data-aos-duration="500"
              // data-aos-delay="150"
            >
              {t("landing.visionMissionLabel")}
              
            </TitleB>
            <TitleDash
              data-aos="fade-left"
              data-aos-easing="ease-in-out"
              data-aos-duration="500"
              data-aos-delay="150"
            />
          </TitleHeadComCont>
          <Container
          >
            <InnerContainer>
              <AboutImage1 src={Reception1} />
              <InnerSubcontainer>
                <Heading>{t("landing.visionLabel")}</Heading>
                <Description>
                  {t("landing.visionText")}

                </Description>
              </InnerSubcontainer>
            </InnerContainer>
            <InnerContainer2>
              <InnerSubcontainer>
                <Heading>{t("landing.missionLabel")}</Heading>
                <Description>
                  {t("landing.missionText")}

                </Description>
              </InnerSubcontainer>
              <AboutImage1B src={Reception2} />
            </InnerContainer2>
          </Container>
          <Container2>
            <InnerContainer2B>
              <AboutImageSub2 src={Reception1} />
              <InnerSubcontainer2B>
                <HeadingB>{t("landing.visionLabel")}</HeadingB>
                <DescriptionB>
                  {t("landing.visionText")}

                </DescriptionB>
              </InnerSubcontainer2B>
            </InnerContainer2B>
            <InnerContainer2B>
              <AboutImageSub2 src={Reception2} />
              <InnerSubcontainer2B>

                <HeadingB>{t("landing.missionLabel")}</HeadingB>
                <DescriptionB>
                  {t("landing.missionText")}

                </DescriptionB>
              </InnerSubcontainer2B>
            </InnerContainer2B>
          </Container2>
        </AboutContainer>
        {/* <ScrollToTop
          isVisible={isVisible}
          scrollToTop={scrollToTop}
          classes={classes}
        /> */}
      </AboutUsMainConatiner>
    </>
  );
}
