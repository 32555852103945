import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  saveBasicSettingApi,
  courseAppearceSettingApi,
  fetchCourseSettingsApi,
  WholeCourseSettingApi,
  fetchCourseCertificateSettingsApi,
  fetchCourseNotificationApi,
  saveCertificateSettingsApi,
  saveNotificationSettingsApi,
} from '../../api/CoursesApi';

export const basicSetting = createAsyncThunk(
  'basicSetting',
  async ({ courseId, formData }, { rejectWithValue }) => {
    try {
      console.log('checking  basicSetting with courseId:', courseId);
      console.log('checking data being sent:', formData);

      const result = await saveBasicSettingApi({ courseId, formData });
      console.log('checking result:', result);

      return result;
    } catch (error) {
      console.error('Error saving course settings:', error);
      console.log('Error response:', error.response?.data);

      return rejectWithValue(
        error.response?.data || 'Error saving course settings',
      );
    }
  },
);

export const courseAppearceSetting = createAsyncThunk(
  'courseAppearceSetting',
  async (data, { rejectWithValue }) => {
    console.log('hii from redux courseAppearceSetting', data);
    const result = await courseAppearceSettingApi(data);
    return result;
  },
);

export const fetchCourseSettings = createAsyncThunk(
  'course/fetchCourseSettings',
  async (courseId, { rejectWithValue }) => {
    try {
      console.log('checking course id slice', courseId);
      const response = await fetchCourseSettingsApi(courseId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const WholeCourseSetting = createAsyncThunk(
  'courseSettings/WholeCourseSetting',
  async (courseId, { rejectWithValue }) => {
    try {
      const response = await WholeCourseSettingApi(courseId);
      console.log('basic slice', response.data);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchCourseCertificateSettings = createAsyncThunk(
  'courseSettings/fetchCourseCertificateSettings',
  async (courseId, { rejectWithValue }) => {
    try {
      const response = await fetchCourseCertificateSettingsApi(courseId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
export const saveCertificateSettings = createAsyncThunk(
  'courseSettings/saveCertificateSettings',
  async ({ courseId, formData }) => {
    console.log('Saving certificate settings for courseId:', courseId);
    console.log('Form data:', formData);

    try {
      const response = await saveCertificateSettingsApi(courseId, formData);
      console.log('Response from API:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error saving certificate settings:', error);
      throw error; // Rethrow the error to be handled in the extraReducers
    }
  },
);
export const fetchCourseNotification = createAsyncThunk(
  'courseSettings/fetchCourseNotification',
  async (courseId, { rejectWithValue }) => {
    try {
      console.log(
        `Fetching course notification settings for course ID: ${courseId}`,
      );

      const response = await fetchCourseNotificationApi(courseId);

      console.log('Fetched Notification Settings:', response); // Log the response

      return response; // Since response.data is returned in the API function, no need to access response.data again
    } catch (error) {
      console.error(
        `Error fetching notification settings for course ID: ${courseId}`,
      );
      console.error(
        'Error Details:',
        error.response ? error.response.data : error.message,
      ); // Log detailed error

      return rejectWithValue(
        error.response ? error.response.data : error.message,
      ); // Fallback to message if no response data
    }
  },
);
export const saveNotificationSettings = createAsyncThunk(
  'notificationSettings/saveNotificationSettings',
  async ({ courseId, notificationSettings }, { rejectWithValue }) => {
    try {
      // Log the data sent to the API
      console.log('Sending notification settings to API:', {
        courseId,
        notificationSettings,
      });

      const response = await saveNotificationSettingsApi(
        courseId,
        notificationSettings,
      );

      // Log the full response from the API
      console.log('Response from API:', response);

      // Instead of checking for success, check if notificationSettings exist in the response
      if (response && response.notificationSettings) {
        return response; // Return the full response object
      } else {
        console.error('Unexpected API response:', response);
        return rejectWithValue('Failed to save notification settings');
      }
    } catch (error) {
      // Log the error and reject the thunk with an error message
      console.error('Error in saveNotificationSettings thunk:', error);
      return rejectWithValue(error.message || 'An error occurred');
    }
  },
);

const CourseUtilitySlice = createSlice({
  name: 'CourseUtility',
  initialState: {
    basicSetting: {},
    wholeCourseSetting: null,
    certificateSettings: null,
    notificationSettings: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(basicSetting.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(basicSetting.fulfilled, (state, action) => {
        state.basicSetting = action.payload;
        state.loading = false;
      })
      .addCase(basicSetting.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(fetchCourseSettings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCourseSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.basicSetting = action.payload;
      })
      .addCase(fetchCourseSettings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(WholeCourseSetting.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(WholeCourseSetting.fulfilled, (state, action) => {
        state.wholeCourseSetting = action.payload;
        console.log('basic whole st', action.payload);
        state.loading = false;
      })
      .addCase(WholeCourseSetting.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(fetchCourseCertificateSettings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCourseCertificateSettings.fulfilled, (state, action) => {
        state.certificateSettings = action.payload;
        state.loading = false;
      })
      .addCase(fetchCourseCertificateSettings.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(fetchCourseNotification.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCourseNotification.fulfilled, (state, action) => {
        state.notificationSettings = action.payload;
        state.loading = false;
      })
      .addCase(fetchCourseNotification.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(saveNotificationSettings.fulfilled, (state, action) => {
        if (action.payload) {
          state.notificationSettings = action.payload.notificationSettings;
          console.log(
            'Notification settings saved successfully',
            action.payload,
          );
        }
      })
      .addCase(saveNotificationSettings.rejected, (state, action) => {
        console.error('Failed to save notification settings:', action.payload);
        state.error = action.payload; // Capture the error message
      });
  },
});

export default CourseUtilitySlice.reducer;
