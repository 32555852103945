import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CertificateTabs from './CertificateTabs';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import CommonButton from '../Utilities/CommonButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Loader from '../Utilities/Loader';
import {
  Grid,
  Card,
  Typography,
  TextField,
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Box,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCertificateTemplate,
  deleteCertificateTemplate,
  addCertificate,
} from '../../features/certificate/CertificateSlice';
import {
  SearchEmail,
  ButtonDiv,
} from '../../styles/Certificate/CertificateStyle';
import { Text } from '../../styles/Common/CommonStyles';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import backgroundImageforBox1 from '../../images/bgcertificate.jpg';
import { palette } from '../../styles/palette';

function CertificatePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNameDialogOpen, setIsNameDialogOpen] = useState(false);
  const [certificateName, setCertificateName] = useState('');
  const [certificateTemplates, setCertificateTemplates] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState(null);
  const [newTemplate, setNewTemplate] = useState(null);
  const { data, loading } = useSelector((state) => state.certificate) || {};
  const { t } = useTranslation();

  const filteredTemplates = data?.templates
    ? data.templates
        .filter((template) =>
          template.certificateName
            .toLowerCase()
            .includes(searchQuery.toLowerCase()),
        )
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    : [];

  const handleOpenModal = (template) => {
    setSelectedTemplate(template);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSelectClick = (template) => {
    setSelectedTemplate(template);
    setIsNameDialogOpen(true);
  };

  const handleCloseNameDialog = () => {
    setIsNameDialogOpen(false);
  };

  const handleCertificateNameChange = (event) => {
    setCertificateName(event.target.value);
  };

  const handleSubmitName = async () => {
    if (certificateName.trim() === '') {
      alert('Please enter a certificate name.');
      return;
    }
    try {
      const response = await dispatch(
        addCertificate({
          certificateName: certificateName,
          certificateBody: selectedTemplate.certificateBody,
          langCode: 'en',
        }),
      );
      setNewTemplate(response.payload);
      setIsNameDialogOpen(false);
      toast.success('Certificate created successfully!');
      navigate('/Certificate/SeparateCertificate');
    } catch (error) {
      toast.error('Error creating certificate:', error);
    }
  };

  const handleDeleteDialogOpen = (template) => {
    setTemplateToDelete(template);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setTemplateToDelete(null);
  };

  const handleDeleteTemplate = () => {
    dispatch(deleteCertificateTemplate(templateToDelete._id))
      .then(() => {
        const updatedTemplates = certificateTemplates.filter(
          (template) => template._id !== templateToDelete._id,
        );
        setCertificateTemplates(updatedTemplates);
        setDeleteDialogOpen(false);
        setTemplateToDelete(null);
      })
      .catch((error) => {
        toast.error('Error deleting template:', error);
        setDeleteDialogOpen(false);
        setTemplateToDelete(null);
      });
  };

  useEffect(() => {
    dispatch(fetchCertificateTemplate());
  }, [dispatch]);

  return (
    <>
      <CertificateTabs />
      <Box
        sx={{
          backgroundSize: 'cover',
          backgroundBlendMode: 'overlay',
          backgroundColor: 'rgba(255, 255, 255, 0.3)',
          backdropFilter: 'blur(100px)',
          borderRadius: '20px',
          padding: '20px',
          border: '1px solid rgba(255, 255, 255, 0.2)',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
        }}
      >
        <div
          style={{
            width: '95%',
            margin: 'auto',
          }}
        >
          <Text>{t('certificate.certificatePgText')}</Text>
          <SearchEmail>
            <TextField
              label={t('certificate.searchText')}
              size="small"
              variant="outlined"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Tooltip title="Create New Template">
              <ButtonDiv style={{ marginRight: '30px' }}>
                <CommonButton
                  onClick={() => navigate('/Certificate/AddTemplate')}
                  label={t('certificate.certificateCreateLabel')}
                />
              </ButtonDiv>
            </Tooltip>
          </SearchEmail>
          {loading ? (
            <Loader />
          ) : (
            <Grid container spacing={2}>
              {Array.from(filteredTemplates).map((template, index) => (
                <Grid
                  item
                  key={index}
                  xs={12}
                  sm={6}
                  md={4}
                  sx={{ padding: 3 }}
                >
                  <Card
                    sx={{
                      maxWidth: 400,
                      border: '2px black solid',
                      borderRadius: '20px',
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      backdropFilter: 'blur(100px)',
                      boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
                      border: '1px solid rgba(255, 255, 255, 0.18)',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignContent: 'center',
                      }}
                    >
                      <Typography
                        variant="h6"
                        component="div"
                        gutterBottom
                        style={{ textAlign: 'center', marginLeft: '10px' }}
                      >
                        {template.certificateName}
                      </Typography>
                    </div>
                    <div
                      style={{
                        overflow: 'hidden',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '300px',
                        border: '1px solid primary',
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                      }}
                    >
                      <img
                        style={{
                          maxWidth: '100%',
                          maxHeight: '100%',
                          objectFit: 'contain',
                        }}
                        src={template?.certificateImage}
                        alt="certificate image"
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: 'auto',
                        display: 'flex',
                        justifyContent: 'space-evenly',
                      }}
                    >
                      <Tooltip title={t('certificate.certificateSelectLabel')}>
                        <IconButton onClick={() => handleSelectClick(template)}>
                          <CheckCircleIcon
                            style={{
                              color: `${palette.primary}`,
                              fontSize: '1.5rem',
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t('certificate.certificateViewLabel')}>
                        <IconButton onClick={() => handleOpenModal(template)}>
                          <VisibilityIcon
                            style={{
                              color: `${palette.primary}`,
                              fontSize: '1.5rem',
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t('certificate.deleteLabel')}>
                        <IconButton
                          onClick={() => handleDeleteDialogOpen(template)}
                        >
                          <DeleteIcon
                            style={{
                              color: `${palette.primary}`,
                              fontSize: '1.5rem',
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
          <Dialog
            open={isModalOpen}
            onClose={handleCloseModal}
            aria-labelledby="certificate-dialog-title"
            fullWidth
            maxWidth="md"
          >
            <DialogTitle id="certificate-dialog-title">
              {selectedTemplate?.Name}
              <IconButton
                aria-label={t('certificate.closeLabel')}
                onClick={handleCloseModal}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: `${palette.primary}`,
                }}
              >
                <CloseIcon />
              </IconButton>
              <br />
            </DialogTitle>
            <DialogContent
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 0, // Remove default padding
                maxHeight: '80vh', // Limit max height of the modal content
              }}
            >
              {selectedTemplate && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxHeight: '80vh', // Ensure image does not exceed 80% of viewport height
                    maxWidth: '100%', // Image container adapts to the full width
                    overflow: 'hidden', // Prevent overflow and scroll bars
                  }}
                >
                  <img
                    style={{
                      maxWidth: '100%',
                      maxHeight: '70vh', // Ensure image fits within the viewport height
                      objectFit: 'contain', // Maintain aspect ratio without cropping
                    }}
                    src={selectedTemplate?.certificateImage}
                    alt="certificate image"
                  />
                </Box>
              )}
            </DialogContent>

            <DialogActions>
              <Button onClick={handleCloseModal} color="primary">
                {t('certificate.closeLabel')}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={isNameDialogOpen} onClose={handleCloseNameDialog}>
            <DialogTitle>{t('certificate.enterNameLabel')}</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label={t('certificate.enterNameLabel')}
                fullWidth
                variant="outlined"
                value={certificateName}
                onChange={handleCertificateNameChange}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseNameDialog} color="primary">
                {t('certificate.cancelLabel')}
              </Button>
              <Button onClick={handleSubmitName} color="primary">
                {t('landing.submitLabel')}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={deleteDialogOpen}
            onClose={handleDeleteDialogClose}
            aria-labelledby="delete-dialog-title"
          >
            <DialogTitle id="delete-dialog-title">
              {t('certificate.ConfirmDeleteLabel')}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t('certificate.deleteTemQue')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteDialogClose} color="primary">
                {t('certificate.cancelLabel')}
              </Button>
              <Button onClick={handleDeleteTemplate} color="primary">
                {t('certificate.deleteLabel')}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Box>
    </>
  );
}

export default CertificatePage;
