import axios from 'axios';

const URL = process.env.REACT_APP_URL;

export const loginUserApi = async (token) => {
  try {
    console.log(token, 'loginUserApi');
    return await axios.get(`${URL}/api/eventManager/signIn`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
  } catch (err) {
    console.log('Error occurs while running loginUser function', err);
  }
};

export const logInUserWithEmilApi = async (token) => {
  try {
    console.log('logInUserWithEmilApi', token);
    return await axios.post(
      `${URL}/api/loginWithEmail`,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
  } catch (err) {
    throw err;
  }
};

export const logoutApi = async (token, email) => {
  try {
    return await axios.post(
      `${URL}/api/logout`,
      { email: email },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
  } catch (err) {
    throw err;
  }
};
