import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import 'grapesjs/dist/css/grapes.min.css';
import 'grapesjs-preset-webpage';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SaveModal from './SaveModal';
import { palette } from '../../styles/palette';
import '../../styles/Sitebuilder/Sitebuilder.css';
import StoreManager from './StoreManager';
import grapesjs from 'grapesjs';
import pluginCountdown from 'grapesjs-component-countdown';
import plugin from 'grapesjs-video-embed-manager';
import webpagePlugin from 'grapesjs-preset-webpage';
import { Properties, Sector } from 'grapesjs';
import videoEmbedManagerPlugin from 'grapesjs-video-embed-manager';
import {
  updatePageById,
  getPageById,
  publishingPage,
} from '../../features/sitebuilder/SitebuilderSlice';
import { Modal, Box, IconButton, Typography, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useDispatch, useSelector } from 'react-redux';
// Custom styling components (assumed you have these defined in your styles)
import {
  MainContainer,
  InnerContainer,
  LeftPanel,
  BackButtonCont,
  SubContainer1,
  Container1,
  Container2,
  EditorCont,
  BlockTabs,
  HoverText,
} from '../../styles/Sitebuilder/SitebuilderStyle';
import { Result } from 'postcss';
import { toast } from 'react-toastify';

const Sitebuilder = () => {
  const navigate = useNavigate();
  const { resultUrl, isPublished, status, error } = useSelector(
    (state) => state.userTemplates,
  );
  const { id } = useParams();
  const editorRef = useRef(null);
  const [visibleDiv, setVisibleDiv] = useState('blocks');
  const [saveStatus, setSaveStatus] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalPageName, setModalPageName] = useState('');
  const [pageContent, setPageContent] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [isUserSavedPages, setIsUserSavedPages] = useState(false);
  // const [isPublished, setIsPublished] = useState(false);
  const [publishOpen, setPublishOpen] = useState(false);
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const API_URL = process.env.REACT_APP_URL;

  const [saved, setSaved] = useState(false);
  const dispatch = useDispatch();

   // Store the result URL
  const handlePublishOpen = () => setPublishOpen(true);
  const handlePublishClose = () => {setPublishOpen(false)
    console.log('afterclickign',resultUrl)
    const payload = {
      netlifyLink:  resultUrl,//https://leapot-lms.s3.amazonaws.com/website/teams/page-1726316282443.html

    }
    dispatch(updatePageById({id,payload}))
  }

  // ---------------------updating the template------------------------------------
  const saveEditorState = async () => {
    console.log('abc clicked');

    if (editorRef.current) {
      const editor = editorRef.current;
      const components = editor.getComponents();
      const styles = editor.getCss();
      const html = editor.getHtml();
      // Saving to localStorage (optional, if needed)
      localStorage.setItem('editorComponents', JSON.stringify(components));
      localStorage.setItem('editorStyles', styles);
      localStorage.setItem('editorHtml', html);
      console.log('old html',  html);
      console.log('old css',  styles);

      setSaved(true);
    } else {
      console.error('Editor is not initialized.');
      return;
    }

    const payload = {
      pageName: modalPageName,
      title: modalTitle,
      pageTemplate: editorRef.current.getHtml(),
      htmlTemplate: editorRef.current.getHtml(),
      cssTemplate: editorRef.current.getCss(),
      userName: 'currentUser', // Replace with actual user data
      categoryId: 'UserSavedPages', // Ensure the new template is saved under this category
    };
    console.log('abc payload', payload);

    try {
      // Dispatch the updatePageById action
      const result = await dispatch(updatePageById({ id, payload }));
      // Check if the action was fulfilled or rejected
      if (updatePageById.fulfilled.match(result)) {
        console.log(' abc Template updated successfully:', result.payload);
        setSaveStatus('Page saved successfully!');
        toast.success('Template Saved Succesfully!');
      } else {
        console.error(' abc Failed to update template:', result.payload);
        setSaveStatus('Error saving page.');
        toast.error('Error Saving Template');
      }
    } catch (error) {
      console.error(' abc,Error saving page:', error);
      setSaveStatus('Error saving page.');
      toast.error('Error Saving Template');
    }
  };
  // ---------------------updating the template------------------------------------

  const loadEditorState = () => {
    if (editorRef.current) {
      const editor = editorRef.current;
      const components = localStorage.getItem('editorComponents');
      const styles = localStorage.getItem('editorStyles');
      const html = localStorage.getItem('editorHtml');
      if (components) {
        editor.setComponents(JSON.parse(components));
      }
      if (styles) {
        editor.addComponents(html);
        editor.setStyle(styles);
      }
    } else {
      console.error('Editor is not initialized.');
    }
  };
  const handleBackClick = () => {
    navigate('/Sitebuilder');
  };

  // ---------------------------grapeJS initiation---------------------------

  useEffect(() => {
    if (!editorRef.current) {
      const editor = grapesjs.init({
        // Editor initialization options
        container: '#editor',
        fromElement: true,
        storageManager: false,
        plugins: [
          webpagePlugin,
          'grapesjs-tailwind',
          videoEmbedManagerPlugin,
          pluginCountdown,
        ],
        pluginsOpts: {
          pluginCountdown: {
            labelCountdown: 'Countdown', // Customize the label if necessary
            dateInputType: 'datetime-local', // Default input type for the countdown
            endText: 'Countdown Ended', 
            startTime: '2024-09-03T00:00', // Custom start time for the countdown
          },
          'grapesjs-tailwind': { cover: '' },
          'grapesjs-preset-webpage': {},
          'grapesjs-video-embed-manager': {
            resources: {
              youtube: {
                enabled: true,
                options: {
                  // YouTube API Key (if required)
                },
              },
              vimeo: {
                enabled: true,
                options: {
                  // Vimeo API Key (if required)
                },
              },
              local: {
                enabled: true,
                options: {
                  label: 'Local',
                  value: 'local',
                  open: true, // Open the local video dialog by default
                },
              },
              link: {
                enabled: true,
                options: {
                  label: 'Link',
                  value: 'link',
                },
              },
            },
          },
        },
      });
      editor.BlockManager.add('youtube-video', {
        label: 'YouTube Video',
        content: {
          type: 'video',
          tagName: 'iframe',
          attributes: {
            src: 'https://www.youtube.com/embed/m3O1lnPJszk', // Transformed embed link
            frameborder: '0',
            allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
            allowfullscreen: true,
          },
        },
        category: 'Media',
      });

      // Add Back button to the devices panel (where the monitor icons are located)
      editor.Panels.addButton('devices-c', [
        {
          id: 'go-back',
          className: 'fa fa-arrow-left', // Font Awesome back arrow icon
          command: 'go-back',
          attributes: { title: 'Go Back' },
          position: 3, // Adjust position if necessary
        },
      ]);

      // Define custom command for Back button
      editor.Commands.add('go-back', {
        run(editor, sender) {
          navigate(-1); // Navigate to the previous page
          sender.set('active', false);
        },
      });

      // Add Save button
      editor.Panels.addButton('options', [
        {
          id: 'save-editor',
          className: 'fa fa-save',
          command: 'save-editor',
          attributes: { title: 'Save' },
        },
      ]);

      // Add Publish button
      editor.Panels.addButton('options', [
        {
          id: 'publish-page',
          className: 'fa fa-upload',
          command: 'publish-page',
          attributes: { title: 'Publish' },
        },
      ]);

      // Add a button to the block manager for navigation
      editor.BlockManager.add('navigation-button', {
        label: 'Navigate Button',
        content: `<button class="navigate-btn">Navigate to Page</button>`, // Button content
        category: 'Basic',
      });

      // Define command to add a navigation link to the button
      editor.Commands.add('set-navigation', {
        run(editor, sender, options) {
          const url = prompt(
            'Enter the URL to navigate to:',
            'https://example.com',
          );
          if (url) {
            const button = options.button;
            button.addAttributes({ 'data-url': url }); // Add URL as a data attribute
            alert('Navigation set to ' + url);
          }
        },
      });

      // Handle button click to navigate to a different page
      document.addEventListener('click', function (event) {
        if (event.target.classList.contains('navigate-btn')) {
          const url = event.target.getAttribute('data-url');
          if (url) {
            window.location.href = url; // Navigate to the specified URL
          } else {
            alert('No URL defined for this button.');
          }
        }
      });

      // Define custom command for saving
      editor.Commands.add('save-editor', {
        run(editor, sender) {
          saveEditorState(); // Your save logic
          sender.set('active', false);
        },
      });
      // //'grapesjs-preset-webpage': {
      //   blocks: ['button','link-block'],
      // Define custom command for publishing
      editor.Commands.add('publish-page', {
        run(editor, sender) {
          publishPage(); // Your publish logic
          sender.set('active', false);
        },
      });

      editorRef.current = editor;
      console.log('Editor initialized:', editorRef.current);
    }

    return () => {
      if (editorRef.current) {
        editorRef.current = null;
      }
    };
  }, [navigate]);
  // ---------------------------grapeJS initiation---------------------------

  //-----------------------------------fetching template in the editor----------------------
  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        // Dispatch the action and wait for the promise to resolve
        const result = await dispatch(getPageById({ id })).unwrap();
        if (result) {
          // Set the HTML components
          editorRef.current.setComponents(result.htmlTemplate);
          // Set the CSS styles
          editorRef.current.setStyle(result.cssTemplate);
          // Set the modal title
          setModalTitle(result.title);
        }
      } catch (error) {
        console.error('Error fetching template:', error.message);
      }
    };
    fetchTemplate();
  }, [dispatch, id]);
  //-----------------------------------fetching template in the editor----------------------

  //--------------------------------publishing page---------------------

  const publishPage = () => {
    const html = editorRef.current.getHtml();
    const css = editorRef.current.getCss();
    // Combine HTML and CSS
    const completePage = `
  <html>
    <head>
      <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">
      <style>${css}</style>
    </head>
    <body>${html}</body>
  </html>
`;
    // Send this HTML to your server or directly save it as a file
    savePageToServer(completePage);
  };

  const savePageToServer = async (pageContent) => {
    try {




      await dispatch(publishingPage({ pageContent })).unwrap();      // Success notification
      toast.success('Page Published Successfully');
      handlePublishOpen();
      // const response = await fetch(`${API_URL}/api/publishPage`, {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({ content: pageContent }),
      // });
      // if (!response.ok) {
      //   toast.error('error publishing', response.status);
      //   throw new Error('Failed to publish the page in the frontend.', Error);
      // }
      // const result = await response.json();
      // toast.success('Page Published  Successfully');
      // setResultUrl(result.url);
      // 
      // console.log(' abc Page published successfully:', result.url);
      // setIsPublished(true);
    } catch (error) {
      console.error('Error publishing page in the frontend:', error);
      toast.error(`Error: ${error.message}`);
    }

    if (editorRef.current) {
      const editor = editorRef.current;
      const components = editor.getComponents();
      const styles = editor.getCss();
      const html = editor.getHtml();
      // Saving to localStorage (optional, if needed)
      localStorage.setItem('editorComponents', JSON.stringify(components));
      localStorage.setItem('editorStyles', styles);
      localStorage.setItem('editorHtml', html);
      setSaved(true);
    } else {
      console.error('Editor is not initialized.');
      return;
    }
    const payload = {
      pageName: modalPageName,
      title: modalTitle,
      pageTemplate: editorRef.current.getHtml(),
      htmlTemplate: editorRef.current.getHtml(),
      cssTemplate: editorRef.current.getCss(),
      userName: 'currentUser', // Replace with actual user data
      categoryId: 'Published Pages',
      isPublished: true,
    };
    try {
      // Dispatch the updatePageById action
      const result = await dispatch(updatePageById({ id, payload }));
      // Check if the action was fulfilled or rejected
      if (updatePageById.fulfilled.match(result)) {
        console.log(' abc Template updated successfully:', result.payload);
        setSaveStatus('Page saved successfully!');
        handleCloseModal();
      } else {
        console.error(' abc Failed to update template:', result.payload);
        setSaveStatus('Error saving page.');
      }
    } catch (error) {
      console.error(' abc,Error saving page:', error);
      setSaveStatus('Error saving page.');
    }
    console.log('abc page published with publised category');
  };

  //--------------------------------publishing page---------------------

  // useEffect(() => {
  //   const fetchTemplate = async () => {
  //     try {
  //       const result = await dispatch(getPageById({id}));
  //       console.log('Response:', response);

  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       const template = await response.json();
  //       setIsUserSavedPages(template.categoryId);
  //       console.log('Template data:', template);

  //       if (template) {
  //         // Set the HTML components
  //         editorRef.current.setComponents(template.htmlTemplate);

  //         // Set the CSS styles
  //         editorRef.current.setStyle(template.cssTemplate);

  //         // Set the modal title
  //         setModalTitle(template.title);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching template:', error);
  //     }
  //   };

  //   fetchTemplate();
  // }, [id]);

  //-----------------------------------fetching template in the editor----------------------

  const handleImageClick = (divId) => {
    setVisibleDiv(divId);
  };

  const handleModalSubmit = async () => {
    const pageContent = editorRef.current.getHtml();
    const payload = {
      pageName: modalPageName,
      title: modalTitle,
      pageTemplate: pageContent,
      userName: 'currentUser', // Update this to the actual user
      categoryId: 'UserSavedPages', // Ensure the new template is saved under this category
    };

    try {
      const response = await fetch(`${API_URL}/api/addUserSavedTemplate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      setSaveStatus('Page saved successfully!');
      handleCloseModal();
    } catch (error) {
      console.error('Error saving page:', error);
      setSaveStatus('Error saving page.');
    }
  };

  const getTabStyle = (tabName) => {
    return visibleDiv === tabName
      ? { color: 'black', backgroundColor: `${palette.lightGray}` }
      : { color: 'black', backgroundColor: `${palette.gray}` };
  };

  return (
    <MainContainer>
      <InnerContainer>
        <SaveModal
          isModalOpen={isModalOpen}
          handleCloseModal={handleCloseModal}
          modalPageName={modalPageName}
          setModalPageName={setModalPageName}
          modalTitle={modalTitle}
          setModalTitle={setModalTitle}
          handleModalSubmit={handleModalSubmit}
        />
        {/* <LeftPanel>
          <div id="panelsTag"></div>
          <BackButtonCont>
            <ArrowBackIosIcon onClick={handleBackClick} />
          </BackButtonCont>
          <SubContainer1>
            <Container1>
              <BlockTabs
                onClick={() => handleImageClick('blocks')}
                style={getTabStyle('blocks')}
              >
                Blocks
              </BlockTabs>
              <HoverText>blocks</HoverText>
            </Container1>
            <Container1>
              <BlockTabs
                onClick={() => handleImageClick('layer')}
                style={getTabStyle('layer')}
              >
                Layer
              </BlockTabs>
              <HoverText>layers</HoverText>
            </Container1>
            <Container1>
              <BlockTabs
                onClick={() => handleImageClick('edit')}
                style={getTabStyle('edit')}
              >
                Style
              </BlockTabs>
              <HoverText>style</HoverText>
            </Container1>
          </SubContainer1>
          <Container2>
            <div
              id="blocks"
              style={{ display: visibleDiv === 'blocks' ? 'block' : 'none' }}
            ></div>
            <div
              id="layer"
              style={{ display: visibleDiv === 'layer' ? 'block' : 'none' }}
            ></div>
            <div
              id="stylemng"
              style={{ display: visibleDiv === 'edit' ? 'block' : 'none' }}
            ></div>
          </Container2>
        </LeftPanel> */}
        <EditorCont>
          {/* <button onClick={saveEditorState} style={{ padding: '2rem' }}>
            Save
          </button>
          <button onClick={loadEditorState}>Load</button>
          {saved && <p>Editor state saved!</p>} */}
          <div id="editor"></div>
        </EditorCont>
      </InnerContainer>
      <Modal
        open={publishOpen}
        onClose={handlePublishClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography id="modal-title" variant="h6">
              Page Published
            </Typography>
            <IconButton onClick={handlePublishClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Typography id="modal-description" sx={{ mt: 2 }}>
            Your page has been successfully published! Here is the link:
          </Typography>

          <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
            <Typography variant="body1">{resultUrl}</Typography>
            <Tooltip title="Copy to clipboard">
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(resultUrl);
                  toast.success('Link copied to clipboard!');
                }}
              >
                <FileCopyIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Modal>
    </MainContainer>
  );
};

export default Sitebuilder;

// import React, { useEffect, useState, useRef } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
// import grapesjs from 'grapesjs';
// import '../../styles/Sitebuilder/Sitebuilder.css';
// import blocks from '../../images/blocks.png';
// import layer from '../../images/layers.png';
// import edit from '../../images/edit.png';
// import back from '../../images/back.png';
// import settings from '../../images/settings.png';
// import editorConfig from './GrapesjsConfig';
// import AddIcon from '@mui/icons-material/Add';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
// import { palette } from '../../styles/palette';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import { Modal, Box, Button, TextField } from '@mui/material';
// import htmlTemplate from './HtmlTemplate';

// import {
//   MainContainer,
//   InnerContainer,
//   LeftPanel,
//   BackButtonCont,
//   SubContainer1,
//   Container1,
//   Container2,
//   EditorCont,
//   SaveBtn,
//   SaveBtnCont,
//   BlockTabs,
//   HoverText,
// } from '../../styles/Sitebuilder/SitebuilderStyle';
// import SaveModal from './SaveModal';

// const Sitebuilder = () => {
//   const navigate = useNavigate();
//   const { id } = useParams();
//   const editorRef = useRef(null);
//   const [sidebarButtons, setSidebarButtons] = useState([]);
//   const [visibleDiv, setVisibleDiv] = useState('blocks');
//   const [saveStatus, setSaveStatus] = useState('');
//   const [pages, setPages] = useState([
//     { id: 'page-1', name: 'Page 1', content: '' },
//   ]);
//   const [currentPage, setCurrentPage] = useState('page-1');
//   const [editingPageId, setEditingPageId] = useState(null);
//   const [newPageName, setNewPageName] = useState('');
//   const [templateExists, setTemplateExists] = useState(false);
//   const [templateTitle, setTemplateTitle] = useState('');
//   const [userName, setUserName] = useState('');
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [modalPageName, setModalPageName] = useState('');
//   const [modalTitle, setModalTitle] = useState('');
//   const [categoryId, setCategoryId] = useState('');
//   const [isUserSavedPages, setIsUserSavedPages] = useState(false);

//   const handleOpenModal = () => setIsModalOpen(true);
//   const handleCloseModal = () => setIsModalOpen(false);

//   const handleBackClick = () => {
//     navigate('/Sitebuilder');
//   };

//   useEffect(() => {
//     if (!editorRef.current) {
//       editorRef.current = grapesjs.init(editorConfig);

//       editorRef.current.on('load', () => {
//         const iframe = editorRef.current.Canvas.getFrameEl();
//         const iframeDoc = iframe.contentDocument;

//         // Example: Add Tailwind CSS to iframe
//         const link = iframeDoc.createElement('link');
//         link.rel = 'stylesheet';
//         link.href =
//           'https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css';
//         iframeDoc.head.appendChild(link);

//         // Example: Add custom style to iframe
//         const style = iframeDoc.createElement('style');
//         style.innerHTML = `h1 { color: red; }`; // Example style
//         iframeDoc.head.appendChild(style);

//         // Example: Handle click events inside the iframe
//         iframeDoc.addEventListener('click', handleIframeClick);
//       });

//       // Initialize block manager if not already initialized
//       const blockManager = editorRef.current.BlockManager;
//       if (!blockManager.get('image')) {
//         blockManager.add('image', {
//           label: 'Image',
//           content:
//             '<img src="https://via.placeholder.com/150" class="w-full object-contain mx-1 my-1" alt="Image">',
//           category: 'Basic',

//         });
//       }

//       if (!blockManager.get('video')) {
//         blockManager.add('video', {
//           label: 'Video',
//           content: `
//             <div class="video-container">
//               <video controls>
//                 <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4">
//                 Your browser does not support the video tag.
//               </video>
//             </div>`,
//           category: 'Media',
//           type: 'video',
//         });
//       }

//       // Get default sidebar and manage buttons
//       const defaultSidebar = editorRef.current.Panels.getPanel('views');
//       if (defaultSidebar) {
//         const buttons =
//           defaultSidebar.view.el.getElementsByClassName('gjs-pn-btn');
//         setSidebarButtons(Array.from(buttons));
//       }
//     }
//   }, []);

//   // Function to handle iframe click events
//   const handleIframeClick = (event) => {
//     const iframe = editorRef.current.Canvas.getFrameEl();
//     const videoOverlay = event.target.closest('.video-overlay');
//     if (videoOverlay) {
//       videoOverlay.classList.toggle('hidden');
//     }
//   };

//   useEffect(() => {
//     const fetchTemplate = async () => {
//       try {
//         const response = await fetch(
//           `${API_URL}/api/getTemplateById/${id}`,
//         );
//         if (!response.ok) {
//           throw new Error('Network response was not ok');
//         }
//         const template = await response.json();
//         setIsUserSavedPages(template.categoryId); // Example: Set categoryId to state
//         console.log(template.categoryId);

//         // Handle other template data and state updates
//         if (template) {
//           editorRef.current.setComponents(template.pageTemplate);
//           updatePageContent('page-1', template.pageTemplate);
//           updateStyle(template.pageTemplate);
//           setTemplateExists(true);
//           setTemplateTitle(template.title);
//           setUserName(template.userName);
//           console.log('Fetched template:', template.pageTemplate);
//         }
//       } catch (error) {
//         console.error('Error fetching template:', error);
//       }
//     };

//     fetchTemplate();
//   }, [id]);

//   const handleImageClick = (divId) => {
//     setVisibleDiv(divId);

//     const iframe = editorRef.current.Canvas.getFrameEl();
//     iframe.onload = () => {
//       const iframeDoc = iframe.contentDocument;
//       const overlay = iframeDoc.querySelector('.video-overlay');
//       if (overlay) {
//         overlay.classList.remove('hidden');
//       }
//     };
//   };

//   const updatePageContent = (pageId, content) => {
//     setPages(
//       pages.map((page) => (page.id === pageId ? { ...page, content } : page)),
//     );
//   };

//   const updateTemplate = async () => {
//     const pageContent = editorRef.current.getHtml();
//     const payload = {
//       pageTemplate: pageContent,
//       htmlTemplate: pageContent,
//     };

//     try {
//       const response = await fetch(
//         `${API_URL}/api/updateTemplateById/${id}`,
//         {
//           method: 'PUT',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(payload),
//         },
//       );

//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }

//       const responseData = await response.json();
//       console.log('Update successful:', responseData);
//       setSaveStatus('Template updated successfully!');
//     } catch (error) {
//       console.error('Error updating template:', error);
//       setSaveStatus('Error updating template.');
//     }
//   };

//   const handleModalSubmit = async () => {
//     const pageContent = editorRef.current.getHtml();
//     const payload = {
//       pageName: modalPageName,
//       title: modalTitle,
//       pageTemplate: pageContent,
//       htmlTemplate: htmlTemplate,
//       cssTemplate: 'This is css',
//       userName: 'currentUser',
//       categoryId: 'UserSavedPages', // Ensure the new template is saved under this category
//     };

//     try {
//       const response = await fetch(
//         `${API_URL}/api/addUserSavedTemplate`,
//         {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(payload),
//         },
//       );

//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }

//       const responseData = await response.json();
//       console.log('Save successful:', responseData);
//       setSaveStatus('Page saved successfully!');
//       handleCloseModal(); // Close the modal on success

//       // Add the new template to the canvas
//       editorRef.current.setComponents(pageContent);
//     } catch (error) {
//       console.error('Error saving page:', error);
//       setSaveStatus('Error saving page.');
//     }
//   };

//   const getTabStyle = (tabName) => {
//     return visibleDiv === tabName
//       ? { color: 'black', backgroundColor: `${palette.lightGray}` }
//       : { color: 'black', backgroundColor: `${palette.gray}` };
//   };

//   return (
//     <MainContainer>
//       <InnerContainer>
//         <SaveModal
//           isModalOpen={isModalOpen}
//           handleCloseModal={handleCloseModal}
//           modalPageName={modalPageName}
//           setModalPageName={setModalPageName}
//           modalTitle={modalTitle}
//           setModalTitle={setModalTitle}
//           handleModalSubmit={handleModalSubmit}
//         />
//         <LeftPanel>
//           <div id="panelsTag"></div>

//           {saveStatus && <p>{saveStatus}</p>}
//           <div>
//             <SubContainer1>
//               <Container1><BackButtonCont>
//             <ArrowBackIosIcon onClick={handleBackClick} />
//             {/* <SaveBtnCont>
//               {isUserSavedPages === 'UserSavedPages' && (
//                 <SaveBtn onClick={updateTemplate}>Save</SaveBtn>
//               )}
//               <SaveBtn onClick={handleOpenModal}>New Page</SaveBtn>
//             </SaveBtnCont> */}
//           </BackButtonCont></Container1>
//               <Container1>
//                 <BlockTabs
//                   onClick={() => handleImageClick('blocks')}
//                   style={getTabStyle('blocks')}
//                 >
//                   Blocks
//                 </BlockTabs>
//                 <HoverText>blocks</HoverText>
//               </Container1>
//               <Container1>
//                 <BlockTabs
//                   onClick={() => handleImageClick('layer')}
//                   style={getTabStyle('layer')}
//                 >
//                   Layer
//                 </BlockTabs>
//                 <HoverText>layers</HoverText>
//               </Container1>
//               <Container1>
//                 <BlockTabs
//                   onClick={() => handleImageClick('edit')}
//                   style={getTabStyle('edit')}
//                 >
//                   Style
//                 </BlockTabs>
//                 <HoverText>style</HoverText>
//               </Container1>
//             </SubContainer1>
//             <Container2>

//               <div
//                 id="blocks"
//                 style={{ display: visibleDiv === 'blocks' ? 'block' : 'none' }}
//               ></div>
//               <div
//                 id="layer"
//                 style={{ display: visibleDiv === 'layer' ? 'block' : 'none' }}
//               ></div>
//               <div
//                 id="stylemng"
//                 style={{ display: visibleDiv === 'edit' ? 'block' : 'none' }}
//               ></div>
//             </Container2>
//           </div>
//         </LeftPanel>
//         <EditorCont>
//           <div id="editor" ref={editorRef.current}></div>
//         </EditorCont>
//       </InnerContainer>
//     </MainContainer>
//   );
// };

// export default Sitebuilder;
