import React, { useState } from 'react';
import styled from 'styled-components';
import { Tooltip } from '@mui/material';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import VolumeUp from '@material-ui/icons/VolumeUp';
import VolumeDown from '@material-ui/icons/VolumeDown';
import VolumeMute from '@material-ui/icons/VolumeOff';
import Slider from '@material-ui/core/Slider';
import PlayIcon from '../../images/icons/play.png';
import PauseIcon from '../../images/icons/pause.png';
import VolumeIcon from '../../images/icons/volume.png';
import CCIcon from '../../images/icons/cc.png';
import MaximizeIcon from '../../images/icons/computer.png';
import PreviousIcon from '../../images/icons/previous.png';
import NextIcon from '../../images/icons/next.png';
import PlayBackSpeed from '../../images/icons/playBackSpeed.png';
import replay_button from '../../images/icons/replay_button.png';
import { palette } from '../../styles/palette';
import { formatTime } from './LearnerCourseBuilder/LearnerPreviewTopic/TimeUtils';
import { Box } from '@mui/system';

const ControlSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: ${palette.lightGray};
  padding: 8px;
  box-sizing: border-box;

  & > * {
    margin: 0 10px;
    font-size: 20px;
  }
`;

const ControlButton = styled.button`
  background-color: ${(props) => (props.disabled ? '#ccc' : palette.lightGray)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  height: 3rem;
  border: none;

  &:hover {
    background-color: ${(props) =>
      props.disabled ? '#ccc' : palette.buttonHover};
  }
`;

const ButtonSection = styled.button`
  border: none;
  background-color: ${(props) => (props.disabled ? 'none' : palette.lightGray)};
  color: ${(props) => (props.disabled ? '#666' : palette.primary)};
  border-radius: 4px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const ControlIcon = styled.img`
  height: 20px;
  width: 20px;
  margin: 2px;
  filter: ${(props) => (props.disabled ? 'grayscale(100%)' : 'none')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const ProgressBar = styled.input`
  width: 95%;
  cursor: pointer;
  height: 2px;
`;

const VerticalSliderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 5rem;
`;

const VerticalSlider = styled(Slider)`
  height: 100%;
  .MuiSlider-rail {
    background-color: ${palette.primary};
  }
  .MuiSlider-track {
    background-color: ${palette.primary};
  }
  .MuiSlider-thumb {
    margin-top: -5px;
  }
`;

const TimeDisplay = styled.div`
  display: flex;
  justify-content:center;
  align-items:center;
  font-weight:600;
  width: 95%;
  margin-left:0.5rem;
  margin-right:0.5rem;
  
  font-size: 1rem;
`;

const DropdownWrapper = styled.div`
  position: relative;
`;

const AbsoluteDropdownMenu = styled(DropdownMenu)`
  position: absolute;
  top: auto;
  bottom: 100%;
  left: 0;
  z-index: 1000;
`;

const ControlSection = ({
  contentType,
  data,
  volume,
  onStart,
  onPlay,
  onPause,
  onBuffer,
  onSeek,
  onEnded,
  onError,
  played,
  onProgress,
  onDuration,
  onReady,
  onPlaybackRateChange,
  playbackRate,
  dropdownOpen,
  onNext,
  onPrevious,
  onToggleCC,
  onToggleFitToScreen,
  isPlaying,
  duration,
  currentTime,
  textScrollPercentage,
  toggleDropdown,
  handleSetPlaybackRate,
  handleSeekMouseDown,
  handleSeekChange,
  handleSeekMouseUp,
  enableNext,
  onReplay,
  onMute,
  muted,
  onVolumeChange,
  onVolumeSeekDown,
  onSeekMouseDown,
}) => {
  const [showVolumeSlider, setShowVolumeSlider] = useState(false);
  console.log('abc currentTime', currentTime);
  console.log('abc duration', duration);
  const isVideoOrAudio = contentType === 'video' || contentType === 'audio';
  const isText = contentType === 'text';
  const isPPTOrPDF = contentType === 'ppt' || contentType === 'pdf';
  const isVideoOrAudioOrText = isVideoOrAudio || isText;

  return (
    <ControlSectionWrapper>
    
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '0.2rem',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderRadius: '5px',
        }}
      >
        <Tooltip title="Seek Bar">
          <ProgressBar
            type="range"
            min={0}
            max={100}
            value={played * 100}
            onMouseDown={handleSeekMouseDown}
            onChange={handleSeekChange}
            onMouseUp={handleSeekMouseUp}
            disabled={!isVideoOrAudioOrText}
          />
        </Tooltip>


      </Box>
      
      <Box sx={{width:'100%',display:'flex', justifyContent:'space-between', marginTop:'5px'}}>
      <Box sx={{ display:'flex'}}> 
      <Tooltip title="Previous Topic">
        <ControlButton onClick={onPrevious}>
          <ButtonSection>
            <img src={PreviousIcon} style={{ height: '20px', width: '20px' }} />
          </ButtonSection>
        </ControlButton>
      </Tooltip>

      <Tooltip title={isPlaying ? 'Pause' : 'Play'}>
        <ControlButton onClick={isPlaying ? onPause : onPlay}>
          <ButtonSection>
            {isPlaying ? (
              <ControlIcon src={PauseIcon} disabled={!isVideoOrAudio} />
            ) : (
              <ControlIcon src={PlayIcon} disabled={!isVideoOrAudio} />
            )}
          </ButtonSection>
        </ControlButton>
      </Tooltip>
      <ControlButton>
        <ButtonSection onClick={onReplay}>
          <Tooltip title="Replay">
            <ControlIcon src={replay_button} disabled={!isVideoOrAudio} />
          </Tooltip>
        </ButtonSection>
      </ControlButton>
      <TimeDisplay>
          <span className='font-semibold '>{formatTime(currentTime ? currentTime : 0)}</span><span className='font-extrabold'>/</span>
          <span className='font-semibold'>{formatTime(duration ? duration : 0)}</span>
        </TimeDisplay>
      <div style={{ display: 'flex' }}>
        <ControlButton onClick={() => setShowVolumeSlider(true)}>
          <ButtonSection disabled={!isVideoOrAudio}>
            <Tooltip title="Volume">
              <div>
                {muted ? (
                  <VolumeMute />
                ) : volume > 0.5 ? (
                  <VolumeUp />
                ) : (
                  <VolumeDown />
                )}
              </div>
            </Tooltip>
          </ButtonSection>
        </ControlButton>

        <ControlButton>
          <VerticalSliderWrapper>
            {showVolumeSlider && (
              <VerticalSlider
                min={0}
                max={100}
                value={muted ? 0 : volume * 100}
                onChange={onVolumeChange}
                onMouseDown={onSeekMouseDown}
                onChangeCommitted={onVolumeSeekDown}
                disabled={!isVideoOrAudio}
              />
            )}
          </VerticalSliderWrapper>
        </ControlButton>
      </div>
      </Box>
     
<Box sx={{display:'flex'}}>

  
<Tooltip title="Playback Speed">
        <ControlButton>
          <Dropdown
            isOpen={dropdownOpen}
            size="sm"
            direction="up"
            toggle={toggleDropdown}
            disabled={!isVideoOrAudio}
          >
            <DropdownToggle
              caret
              style={{
                backgroundColor: `${palette.lightGray}`,
                color: `${palette.primary}`,
                border: 'none',
              }}
              disabled={!isVideoOrAudio}
            >
              <ButtonSection disabled={!isVideoOrAudio}>
                {`${playbackRate}x`}
              </ButtonSection>
            </DropdownToggle>

            <AbsoluteDropdownMenu>
              {[0.5, 1, 1.5, 2].map((rate) => (
                <button
                  key={rate}
                  value={rate}
                  onClick={(e) =>
                    onPlaybackRateChange(parseFloat(e.currentTarget.value))
                  }
                  style={{
                    border: 'none',
                    color: `${palette.primary}`,
                    backgroundColor: `${palette.white}`,
                    width: '100%',
                    padding: '5px 0',
                  }}
                  disabled={!isVideoOrAudio}
                >
                  {rate} x
                </button>
              ))}
            </AbsoluteDropdownMenu>
          </Dropdown>
        </ControlButton>
      </Tooltip>


{/* cc button */}
      {/* <ControlButton>
        <ButtonSection disabled={!isVideoOrAudio}>
          <Tooltip title={isText ? 'Disable CC' : 'Enable CC'}>
            <ControlIcon
              src={CCIcon}
              style={{ height: '20px', width: '20px', margin: '2px' }}
              onClick={onToggleCC}
              disabled={!isVideoOrAudio}
            />
          </Tooltip>
        </ButtonSection>
      </ControlButton> */}

      <ControlButton onClick={onToggleFitToScreen}>
        <ButtonSection>
          <Tooltip title={isText ? 'Minimize' : 'Maximize'}>
            <img
              src={MaximizeIcon}
              style={{ height: '20px', width: '20px', margin: '2px' }}
            />
          </Tooltip>
        </ButtonSection>
      </ControlButton>

      <ControlButton onClick={onNext}>
        <ButtonSection>
          <Tooltip title="Next Topic">
            <img
              src={NextIcon}
              style={{ height: '20px', width: '20px', margin: '2px' }}
            />
          </Tooltip>
        </ButtonSection>
      </ControlButton>
</Box>
      

     

      </Box>
      {/* first box  */}
      
    </ControlSectionWrapper>
  );
};

export default ControlSection;
