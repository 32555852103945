import React, { useState, useEffect, useRef } from 'react';
import firebase from 'firebase/compat/app';
import { signInWithEmailAndPassword } from 'firebase/auth';
import 'firebase/compat/auth';
import axios from 'axios';
const URL = process.env.REACT_APP_URL;

import {
  LoginContainerMain,
  LoginContainer,
  Header,
  TextFieldStyle,
  LoginButton,
  SignupContainer,
  SignUpText,
  LoginForm,
  LoginImg,
  LoginImgSub,
  LoginButtonGoogle,
  ImgLogin,
  Sepbtn,
} from '../../styles/Landing/LoginStyles';
import TextField from '@mui/material/TextField';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { palette } from '../../styles/palette';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import googleA from '../../images/googleA.png';
import loginbgD from '../../images/loginbg.jpg';
import {
  logInUser,
  logInUserWithEmil,
} from '../../features/authentication/AuthenticationSlice';

import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Loader from '../Utilities/Loader';
import { passwordReset } from '../../features/userModule/userModuleSlice';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { users, loading } = useSelector((state) => state.auth);
  const [state, setState] = useState({
    userName: '',
    password: '',
  });

  const emailRef = useRef(null);

  const [errorState, setErrorState] = useState({
    userName: false,
    password: false,
  });

  const [showPassword, setShowPassword] = useState(false);

  const [showLoginButton, setShowLoginButton] = useState(false);

  const handleBlur = (e) => {
    if (!e.target.value)
      setErrorState({ ...errorState, [e.target.name]: true });
  };

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
    if (!event.target.value) {
      setErrorState({
        ...errorState,
        [event.target.name]: true,
      });
    } else {
      setErrorState({
        ...errorState,
        [event.target.name]: false,
      });
    }
  };

  const handleSignup = () => {
    navigate('/Signup');
  };

  const handleSignIn = async (e) => {
    e.preventDefault();

    try {
      const authData = await signInWithEmailAndPassword(
        firebase.auth(),
        state.userName,
        state.password,
      );
      const token = await authData.user.getIdToken();
      localStorage.setItem('userToken', token);

      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      const userData = await dispatch(logInUserWithEmil(token));

      if (userData?.payload?.statusCode === 200) {
        await toast(userData.payload.message);
        localStorage.setItem('auth', true);
        navigate('/Dashboard');
      } else {
        await toast(userData?.payload?.message);
      }
    } catch (error) {
      if (
        error.response?.data?.error?.code === 400 &&
        error.response?.data?.error?.message === 'INVALID_LOGIN_CREDENTIALS'
      ) {
        await toast('Invalid login credentials. Please try again.');
      } else {
        await toast.error('Invalid login credentials. Please try again.');
      }
    }
  };

  const [auth, setAuth] = useState(
    false || window.localStorage.getItem('auth') === 'true',
  );
  const [token, setToken] = useState('');

  useEffect(() => {
    if (token) {
      dispatch(logInUser(token));
      navigate('/Dashboard');
    }
  }, [token]);

  const loginWithGoogle = () => {
    firebase
      .auth()
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then((result) => {
        const user = result.user;
        if (user) {
          user.getIdToken().then((token) => {
            setToken(token);
            localStorage.setItem('userToken', token);
          });
          navigate('/Dashboard');
        }
      })
      .catch((error) => {
        console.error('Error signing in with Google: ', error);
        toast.error(
          'An error occurred during Google sign-in. Please try again.',
        );
      });
  };

  if (loading) {
    return <Loader />;
  }

  const { t } = useTranslation();

  const handleForgotPassword = () => {
    console.log('Forgot Password', emailRef.current.value);
    dispatch(passwordReset(emailRef.current.value))
      .unwrap()
      .then(() => {
        toast.success('Password reset email sent successfully');
      })
      .catch((error) => {
        console.error('Failed to send password reset email:', error);
      });
  };

  return (
    <>
      <LoginContainerMain>
        <LoginContainer>
          <Header>{t('login.loginWelcomeLabel')}</Header>
          <LoginForm>
            <TextField
              required
              label={t('login.loginEmailLabel')}
              name="userName"
              id="userName"
              variant="outlined"
              sx={TextFieldStyle}
              error={errorState.userName}
              value={state.userName}
              onBlur={handleBlur}
              onChange={handleChange}
              InputProps={{ minLength: 0 }}
              inputRef={emailRef}
            />
            <TextField
              required
              label={t('login.loginPasswordLabel')}
              name="password"
              id="password"
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
              sx={TextFieldStyle}
              error={errorState.password}
              value={state.password}
              onBlur={handleBlur}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: 2,
              }}
            >
              <Button
                variant="contained"
                size="large"
                onClick={handleSignIn}
                sx={{
                  backgroundColor: `${palette.tertiary}`,
                  color: `${palette.white}`,
                  flexGrow: 1,
                  marginRight: 1,
                }}
              >
                {t('login.loginText')}
              </Button>

              <Button
                variant="outlined"
                size="large"
                onClick={loginWithGoogle}
                sx={{
                  flexGrow: 1,
                  marginLeft: 1,
                  borderColor: `${palette.tertiary}`,
                  color: `${palette.tertiary}`,
                }}
              >
                <ImgLogin src={googleA} alt="" style={{ marginRight: 8 }} />
                {t('login.loginGoogleLabel')}
              </Button>
            </Box>

            <Box
              sx={{
                marginTop: 2,
                display: 'flex',
                justifyContent: 'space-between',
                textAlign: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                onClick={handleSignup}
                style={{ cursor: 'pointer', color: '#007bff' }}
                variant="body2"
              >
                New User? Click here to Sign-Up
              </Typography>

              <Typography
                onClick={handleForgotPassword}
                style={{ cursor: 'pointer', color: '#007bff' }}
                variant="body2"
              >
                Forgot Password?
              </Typography>
            </Box>
          </LoginForm>
        </LoginContainer>
        <LoginImg>
          <LoginImgSub src={loginbgD} alt="error" />
        </LoginImg>
      </LoginContainerMain>
    </>
  );
};

export default Login;
