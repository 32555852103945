import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createQuizzes } from '../../api/QuizApi';

export const createQuiz = createAsyncThunk(
  'course/quizzes',
  async (quizData, { rejectWithValue }) => {
    try {
      const response = await createQuizzes(quizData);
      console.log('quizz', response);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

const initialState = {
  data: [],
  quizzes: [],
  loading: false,
  error: null,
};
const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createQuiz.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createQuiz.fulfilled, (state, action) => {
        state.loading = false;
        state.quizzes.push(action.payload);
        state.error = null;
      })
      .addCase(createQuiz.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.quizzes = null;
      });
  },
});

export default quizSlice.reducer;
