import styled from 'styled-components';
import tw from 'twin.macro';
import { palette } from '../palette';
import zIndex from '@mui/material/styles/zIndex';

export const ProfileContainer = styled.div`
  ${tw`h-full flex flex-col w-11/12 self-center `};
`;

export const ProfileContent = styled.div`
  ${tw`2xl:text-6xl lg:text-xs flex pl-5 items-center`}
`;

export const Heading = styled.p`
  ${tw`font-semibold text-lg`}
  color:${palette.secondary}
`;

export const HeaderContent = styled.div`
  ${tw`flex  pl-5 items-center`}
`;
export const ImageContainerSmall = {};

export const BoxStyling = {};

export const GridContainerStyle = styled.div`
  ${tw`justify-center hidden md:block `}
`;

export const ProfileImageCont = styled.div`
  ${tw`flex justify-center items-center ml-10`}
  width: 400px;
  height: 300px;
  border: 3px solid ${palette.primary};
  border-radius: 8px;
  cursor: pointer;
`;

export const ProfileImage = styled.img`
  ${tw` `}
  width: 200px;
  height: 150px;
  z-index: 1;
  cursor: pointer;
`;

export const ProfileForm = styled.div`
  ${tw`flex flex-col w-full p-10 mb-20`}
  background : ${palette.formBg};
  border-radius: 16px;
`;

export const TextFieldContainer = styled.div`
  ${`2xl:text-2xl lg:text-xs`}
`;
export const ImgContainer = styled.div``;
export const TextFieldStyle = {
  '& .MuiInputBase-root ': {
    background: `${palette.white}`,
    width: '500px',
    marginRight: '10px',
  },
};

export const TypographyStyle = {
  fontWeight: '18px',
  marginTop: '10px',
  marginBottom: '10px',
};

export const AddFieldContainer = styled.div`
  ${tw`flex justify-end py-10 pr-10`}
  position: relative;
  white-space: nowrap;
  bottom: 0;
  right: 0;
  color: #0000ee;
  text-decoration: underline;
  cursor: pointer;

  @media (max-width: 768px) {
    ${tw`mb-10`}
  }

  @media (max-width: 480px) {
    ${tw`mb-5`}
  }
`;
export const SubmitBtnStyle = styled.div`
  ${tw` p-6 `}
  margin-left:500px;
  /* position: "relative";
  marginTop:"85px";
  left: "40%";
  zIndex: 1, */
`;
export const idCardBtnStyle = styled.div`
  ${tw` p-6 mx-6`}
  margin-left:500px;
  /* position: "relative";
  
  left: "40%";
  zIndex: 1, */
  width:"100%"
  marginTop:"85px";
`;

export const CloseIconStyle = {
  background: `${palette.primary}`,
  borderRadius: '50px',
  padding: '2px',
  color: `${palette.white}`,
};

export const Description = styled.p`
  ${tw`text-base font-semibold`}
  color : ${palette.heading}
`;

export const AddDiv = styled.div`
  ${tw`flex items-center justify-between`}
`;

export const SubmitButtonStyle = styled.div`
  ${tw` p-4 `}
  margin-left:230px;
`;

/* New profile styling */
export const StyledFormContainer = styled.div`
  background: ${palette.newColor};
`;

export const StyledForm = styled.form`
  ${tw`w-3/4  p-8 rounded-lg bg-white shadow-md  my-8`}
`;

export const StyledBackButton = styled.button`
  ${tw` absolute top-10 bg-transparent border-none cursor-pointer`}
`;

export const StyledInputContainer = styled.div`
  ${tw`flex flex-col lg:flex-row`}
`;

export const StyledInputWrapper = styled.div`
  ${tw`mx-3 mb-3`}
  flex: 1;
`;

export const StyledLabel = styled.label`
  ${tw` block mb-2 `};
  margin-left: 100px;
`;

export const StyledInput = styled.input`
  ${tw` w-full mt-1 border-2 rounded-md h-10 px-2 `}
  border-color: ${palette.primary};
`;
export const StyledSubmitButton = styled.button`
  ${tw` w-1/5 py-2 px-4 rounded-md mt-4 text-white border-none cursor-pointer `}
  background: ${palette.primary};
  margin-left: 300px;
`;

export const StyledImg = styled.img`
  ${tw` cursor-pointer  `}
  border: 2px solid #036ACB;
  border-radius: 80px;
  margin-left: '150px';
`;
