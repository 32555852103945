import React, { useState, useEffect } from 'react';
import { Box,Typography, Grid, TextField, Button, IconButton, Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import { updateModule,  deleteModule} from '../../../features/courses/addCourseDetailsSlice';
import JoditReact from "jodit-react";
import { palette } from '../../../styles/palette';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import {StyledBox, FlexDiv, DeleteBox } from '../../../styles/Courses/CourseModuleStyles';

const CloseButton = styled(Button)`
color: ${palette.primary};
border-color:  ${palette.gray};
  margin-right: 10px;
`;

const SaveButton = styled(Button)`
background-color: ${palette.primary};
color: ${palette.white};
  margin-right: 8px;
`;

const CreateModule = ({ module, onClose }) => {
  const dispatch = useDispatch();
  const [moduleDetails, setModuleDetails] = useState({ ...module });

  useEffect(() => {
    setModuleDetails({ ...module });
  }, [module]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setModuleDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    dispatch(updateModule(moduleDetails));
    onClose(); // Close the CreateModule component
  };

  const handleCancel = () => {
    onClose(); // Close the CreateModule component
  };

  const handleDelete = () => {
    dispatch(deleteModule(module.id));
    onClose(); // Close the CreateModule component
  };

  return (
    <StyledBox>
      <FlexDiv>
          <Grid item xs={12}>
          <CloseButton variant="outlined"  onClick={handleCancel}>
            Close
          </CloseButton>
          <SaveButton variant="contained" onClick={handleSubmit} >
            Save
          </SaveButton>
        </Grid>
        </FlexDiv>
                <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12}>
            <Typography style={{ marginBottom: "8px", marginTop: "15px" }}>
              Module Title
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Enter Module Name"
              variant="outlined"
              fullWidth
              name="title"
              value={moduleDetails.title}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Description</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              placeholder="Enter Description"
              variant="outlined"
              rows="5"
              multiline
              fullWidth
              name="description"
              value={moduleDetails.description}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>
              Module Number
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Enter Module Number"
              variant="outlined"
              fullWidth
              name="number"
              type="number"
              value={moduleDetails.number}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>Estimated Time</Typography>
            <TextField
              id="outlined-basic"
              placeholder="Enter Estimated Time"
              variant="outlined"
              fullWidth
              name="EstTime"
              value={moduleDetails.EstTime}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Learning Objectives</Typography>
          </Grid>
          <Grid item xs={12}>
            <JoditReact
              value={moduleDetails.objectives}
              config={{
                style: {},
              }}
            />
          </Grid>
          <Grid item xs={12}>
      <DeleteBox>
      <h4 style={{ marginRight: '10px' }}>
      Are you sure you want to delete this module? This action is irreversible.
      </h4><br/>
      <Button
        variant="contained"
        color="error"
        onClick={handleDelete}      
      >
        Delete Module
      </Button>
    </DeleteBox>
        </Grid>
        </Grid>
    </StyledBox>
  );
};

export default CreateModule;
