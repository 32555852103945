import styled from 'styled-components';
import tw from 'twin.macro';
import { palette } from '../../styles/palette';

export const AboutUsMainConatiner = styled.div`
  ${tw`m-5  mb-10 p-5 mt-20
  sm:m-10 sm:px-20 sm:mt-20
`}
`;

export const HeaderImage = styled.div`
  ${tw`relative w-[100%] bg-cover bg-no-repeat rounded-lg flex justify-center items-center mb-10`}
  background : ${(props) => `url(${props.bgImage})`};
  min-height: 600px;
  height: 100%;
  background-size: cover;
  border-radius:15px;
  &::before {
    content: '';
    ${tw`absolute inset-0 bg-black opacity-50`}
    border-radius:15px;
  }
`;

export const MainHeading = styled.div`
  ${tw`z-1`}
`;

export const TitleHeading = styled.h1`
  ${tw`flex justify-center items-center text-center text-xl mx-[-200px]
  sm:text-xl mx-[-200px] mr-[-20px]
  md:text-2xl mx-[-20px]
  lg:text-3xl 
  xl:text-4xl
  2xl:text-4xl`}
  color: ${palette.white};
`;

export const AboutContainer = styled.div`
  ${tw`my-10 flex justify-center items-center flex-col`}
`;

export const InnerContainer = styled.div`
  ${tw`flex justify-center flex-col w-[100%] rounded-t-[20px] 
    lg:flex-row
  `}
  background:${palette.primary};
`;

export const InnerContainer2 = styled.div`
  ${tw`flex justify-center flex-col w-[100%] rounded-b-[20px] 
    lg:flex-row
  `}
  background:${palette.primary};
`;

export const InnerContainer2B = styled.div`
  ${tw`flex justify-center flex-col w-[100%]

  `}
  background:${palette.secondary};
`;

export const AboutUsContainer = styled.div`
  ${tw`flex justify-center items-center flex-col`}
`;

export const AboutImage1 = styled.img`
  ${tw`w-[50%] h-auto rounded-tl-[20px]`}
`;

export const AboutImage1B = styled.img`
  ${tw`w-[50%] h-auto rounded-br-[20px]`}
`;

export const AboutImageSub2 = styled.img`
  ${tw`w-[100%] h-[40vh]`}
`;

export const Paragraph = styled.p`
  ${tw`text-justify text-base leading-8
  sm:text-sm sm:leading-8
  md:text-base md:leading-8
  lg:text-lg lg:leading-8
  xl:text-xl xl:leading-10
  2xl:text-xl 2xl:leading-10
  `}
`;

export const Container = styled.div`
  ${tw`hidden lg:block shadow-2xl mt-5`}
`;

export const Container2 = styled.div`
  ${tw`block lg:hidden `}
`;

export const InnerSubcontainer = styled.div`
  ${tw`flex justify-center items-center flex-col w-[50%]
  `}
  color:${palette.white};
`;

export const InnerSubcontainer2B = styled.div`
  ${tw`flex justify-center items-center flex-col w-[100%] mx-2 my-4
  `}
  color:${palette.white};
`;

export const Heading = styled.h1`
  ${tw`flex justify-center items-center text-xl
  sm:text-sm
  md:text-base
  lg:text-lg
  xl:text-xl
  2xl:text-xl`}
`;

export const HeadingB = styled.h1`
  ${tw`flex justify-center items-center text-xl
  sm:text-sm
  md:text-base
  lg:text-lg
  xl:text-xl
  2xl:text-xl`}
`;

export const HeadMain = styled.div`
  ${tw`flex justify-center items-center mb-5 w-[100%] mt-[-10px]`}
`;

export const Description = styled.p`
  ${tw`flex justify-center items-center text-justify text-xl mt-[-8px] w-[35vw] m-2
  sm:text-sm 
  md:text-base 
  lg:text-base  
  xl:text-xl
  2xl:text-xl `}
`;

export const DescriptionB = styled.p`
  ${tw`flex justify-center items-center text-justify text-base w-[90%] h-auto m-2
  sm:text-sm 
  md:text-base 
  lg:text-lg
  xl:text-xl
  2xl:text-xl `}
`;
