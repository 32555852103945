import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  Paper,
  Container,
  IconButton,
  CircularProgress,
  Button,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
const LearnerCourses = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [timer, setTimer] = useState(30);
  const quizData = {
    questions: [
      {
        question:
          'What is the correct syntax to create a function in JavaScript?',
        options: [
          '`function = myFunction()`',
          '`function:myFunction()`',
          '`function myFunction()`',
          '`myFunction(): function`',
        ],
      },
      {
        question: 'Reprehenderit In Voluptate Velit Esse Cillum Dolore',
        options: [
          'Eiusmod Tempor Incididunt Ut',
          'Fugiat Nulla Pariatur',
          'Voluptatem Accusantium Doloremque',
          'Totam Rem Aperiam',
        ],
      },
      {
        question: 'How do you round the number 7.25 to the nearest integer?',
        options: [
          'Math.rnd(7.25)',
          'rnd(7.25)',
          'Math.round(7.25)',
          'round(7.25)',
        ],
      },
      {
        question:
          'What will the following code output: console.log(typeof []);?',
        options: ['Array', 'undefined', 'ArrayObject', 'Object'],
      },
      {
        question: 'Which event occurs when the user clicks on an HTML element?',
        options: ['onchange', 'onclick', 'onmouseclick', 'onmouseover'],
      },
      {
        question: 'Which database is used in the MERN stack?',
        options: ['PostgreSQL', 'MySQL', 'MongoDB', 'SQLite'],
      },
      {
        question:
          'Which HTTP method is typically used to update a resource in RESTful APIs?',
        options: ['GET', 'POST', 'PUT', 'DELETE'],
      },
      {
        question: 'In React, what is the purpose of the useState hook?',
        options: [
          'To manage side effects',
          'To fetch data from an API',
          'To create state variables',
          'To access the context API',
        ],
      },
      {
        question:
          'In a Redux setup, which function is used to send an action to the store?',
        options: ['dispatch', 'send', 'trigger', 'call'],
      },
      {
        question:
          'What is the default port on which a React development server runs?',
        options: ['8080', '3000', '5000', '4000'],
      },

      // Add more questions as needed
    ],
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev) => {
        if (prev > 0) return prev - 1;
        handleNext();
        return 30;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleOptionChange = (option) => {
    setSelectedOptions((prev) => {
      if (prev.includes(option)) {
        return prev.filter((opt) => opt !== option);
      } else {
        return [...prev, option];
      }
    });
  };

  const handleNext = () => {
    if (currentQuestionIndex < quizData.questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedOptions([]);
      setTimer(30);
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setSelectedOptions([]);
      setTimer(30);
    }
  };
  return (
    <>
      <Container
        sx={{ padding: '30px', marginBottom: '60px', marginTop: '50px' }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} display="flex" justifyContent="flex-end">
            <Box
              sx={{
                position: 'absolute',
                top: '10px',
                right: '20px',
                padding: '10px',
                backgroundColor: 'white',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                borderRadius: '8px',
                display: 'flex',
                alignItems: 'center',
                marginTop: '68px',
                marginRight: '20px',
              }}
            >
              <Typography variant="h6" mr={1}>
                Time Remaining:
              </Typography>
              <Box position="relative" display="inline-flex">
                <CircularProgress
                  variant="determinate"
                  value={(timer / 30) * 100}
                  size={60}
                  thickness={4}
                />
                <Box
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    variant="caption"
                    component="div"
                    color="textSecondary"
                  >
                    {`${timer}s`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Paper
              elevation={3}
              style={{
                padding: '20px',
                borderRadius: '5px',
                width: '95%',
                marginTop: '30px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="stretch"
                mb={2}
              >
                {quizData.questions.map((_, index) => (
                  <Box
                    key={index}
                    sx={{
                      width: 30,
                      height: 30,
                      borderRadius: '50%',
                      backgroundColor:
                        index === currentQuestionIndex ? '#96ea7a' : 'grey',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      margin: '0 5px',
                      color: 'white',
                      fontWeight: 'bold',
                      cursor: 'pointer',
                    }}
                    onClick={() => setCurrentQuestionIndex(index)}
                  >
                    {index + 1}
                  </Box>
                ))}
              </Box>
              <Typography variant="h5" gutterBottom>
                {quizData.questions[currentQuestionIndex].question}
              </Typography>
              <Box mt={2}>
                {quizData.questions[currentQuestionIndex].options.map(
                  (option, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        border: '1px solid',
                        borderColor: '#0684FB',
                        borderRadius: '4px',
                        padding: '10px',
                        marginBottom: '8px',
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: 'grey.100',
                        },
                      }}
                      onClick={() => handleOptionChange(option)}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: 'bold',
                          color: '#0684FB',
                          marginRight: '8px',
                        }}
                      >
                        {String.fromCharCode(65 + index)}.
                      </Typography>
                      <Typography variant="body1">{option}</Typography>
                    </Box>
                  ),
                )}
              </Box>
              <Box mt={2} textAlign="center">
                <Button sx={{ backgroundColor: '#0684FB', color: 'white' }}>
                  Submit Answer
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default LearnerCourses;
