import React, { useState, useEffect, useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';
import TextField from '@mui/material/TextField';
import CommonButton from '../Utilities/CommonButton';
import BackButton from '../Utilities/BackButton';
import { useNavigate, useParams } from 'react-router-dom';
import {
  editCertificate,
  fetchSingleCertificate,
} from '../../features/certificate/CertificateSlice';
import { useDispatch } from 'react-redux';
import {
  CertificateForm,
  JoditEditorDiv,
  CertificateHeading,
  ButtonDiv,
  ArrowDiv,
  EditNotificationHeading,
} from '../../styles/Certificate/CertificateStyle';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const EditTemplate = () => {
  const { id } = useParams();
  const [content, setContent] = useState('');
  const [certificateName, setCertificateName] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const editor = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const config = useMemo(
    () => ({
      height: 400,
      readonly: false,
      placeholder: '',
    }),
    [],
  );

  useEffect(() => {
    const fetchCertificateContent = async () => {
      try {
        const response = await dispatch(fetchSingleCertificate(id));
        const certificate = response.payload.certificates[0];
        setContent(certificate.certificateBody);
        setCertificateName(certificate.certificateName);
      } catch (error) {
        console.error('Error fetching certificate content:', error);
      }
    };
    fetchCertificateContent();
  }, [dispatch, id]);

  const onChange = (newContent) => {
    setContent(newContent);
  };

  const updateTemplate = async (e) => {
    e.preventDefault();
    setIsUpdating(true);
    try {
      const object = {
        template: editor.current.value,
      };
      const response = await dispatch(
        editCertificate({ certificateId: id, updatedData: object }),
      );
      if (response.payload.statusCode === 200) {
        setContent(object.template);
        toast.success('Updated certificate template successfully!');
        navigate('/Certificate/SeparateCertificate');
      } else {
        toast.error('Failed to update certificate template.');
      }
    } catch (e) {
      console.log(e);
      toast.error('An error occurred while updating the certificate template.');
    } finally {
      setIsUpdating(false);
    }
  };

  const { t } = useTranslation();

  return (
    <>
      <ArrowDiv>
        <EditNotificationHeading>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <BackButton />
            <CertificateHeading>
              {t('certificate.editTempLabel')}
            </CertificateHeading>
          </div>
          <ButtonDiv>
            <CommonButton
              onClick={updateTemplate}
              label={isUpdating ? 'Updating...' : t('certificate.UpdateLabel')}
              disabled={isUpdating}
            />{' '}
          </ButtonDiv>
        </EditNotificationHeading>
      </ArrowDiv>
      <CertificateForm>
        <TextField
          label={t('certificate.certificateName')}
          variant="outlined"
          fullWidth
          margin="normal"
          disabled
          value={certificateName}
        ></TextField>

        <JoditEditorDiv>
          <JoditEditor
            ref={editor}
            value={content}
            onChange={onChange}
            config={config}
          />
        </JoditEditorDiv>
      </CertificateForm>
    </>
  );
};

export default EditTemplate;
