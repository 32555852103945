export const landingPagetemplate =(companyName , pageName)=>{


 return   `<body><!-- Header --><header class="bg-white shadow-md p-4"><div class="container mx-auto flex justify-between items-center"><!-- Company Name --><div class="text-xl font-bold text-gray-800">
            ${companyName? companyName:"Company Name"}

        </div><!-- Links --><nav class="space-x-6"><a href="#" class="text-gray-600 hover:text-gray-800 font-medium">All Courses</a><a href="#" class="text-gray-600 hover:text-gray-800 font-medium">Sign In</a></nav></div></header><!-- Hero Section --><section class="bg-blue-500 text-white text-center py-20"><h1 class="text-4xl font-bold mb-4">${pageName}</h1><a href="#" class="inline-block bg-white text-blue-500 font-semibold py-3 px-8 rounded-lg shadow-lg hover:bg-gray-200 transition duration-300">
        Enroll for Free
    </a></section><!-- Main Content --><main class="container mx-auto mt-8 p-4"><h1 class="text-3xl font-bold text-gray-800 mb-4">Welcome to ${pageName}</h1><p class="text-gray-600 text-lg">Here you can explore various courses and learn new skills!</p></main><!-- New Section with Two Divs --><section class="container mx-auto mt-12 p-4 grid grid-cols-1 md:grid-cols-2 gap-8"><!-- Div 1: Course Curriculum --><div class="bg-white shadow-lg p-6 rounded-lg"><h2 class="text-2xl font-bold text-gray-800 mb-4">Course Curriculum</h2><div class="space-y-4"><!-- Dropdown 1 --><div class="border border-gray-300 rounded-lg"><button class="w-full text-left p-4 font-medium bg-gray-100 hover:bg-gray-200">
                    Module 1: Introduction to Instructional Design
                </button><div class="p-4 hidden"><p>Lesson 1: What is Instructional Design?</p><p>Lesson 2: Key Concepts</p></div></div><!-- Dropdown 2 --><div class="border border-gray-300 rounded-lg"><button class="w-full text-left p-4 font-medium bg-gray-100 hover:bg-gray-200">
                    Module 2: Learning Theories
                </button><div class="p-4 hidden"><p>Lesson 1: Behaviorism</p><p>Lesson 2: Constructivism</p></div></div><!-- Dropdown 3 --><div class="border border-gray-300 rounded-lg"><button class="w-full text-left p-4 font-medium bg-gray-100 hover:bg-gray-200">
                    Module 3: Designing Effective Courses
                </button><div class="p-4 hidden"><p>Lesson 1: Course Structure</p><p>Lesson 2: Assessment Design</p></div></div><!-- Dropdown 4 --><div class="border border-gray-300 rounded-lg"><button class="w-full text-left p-4 font-medium bg-gray-100 hover:bg-gray-200">
                    Module 4: Course Evaluation
                </button><div class="p-4 hidden"><p>Lesson 1: Measuring Success</p><p>Lesson 2: Improving Outcomes</p></div></div></div></div><!-- Div 2: Course Card --><div class="bg-white shadow-lg rounded-lg overflow-hidden flex"><!-- Image Section --><div class="w-1/2"><img src="https://via.placeholder.com/300" alt="Course Image" class="object-cover w-full h-full"/></div><!-- Course Details Section --><div class="w-1/2 p-6"><h2 class="text-2xl font-bold text-gray-800 mb-4">About the Course</h2><p class="text-lg text-gray-600 mb-2"><i class="fas fa-tag"></i> Free</p><p class="text-lg text-gray-600 mb-2"><i class="fas fa-book"></i> 12 Lessons</p><p class="text-lg text-gray-600"><i class="fas fa-clock"></i> 6 Hours of Content</p></div></div></section><div class="bg-gray-100 py-16 px-6 my-4"><div class="max-w-4xl mx-auto text-center"><h1 class="text-5xl font-bold mb-4">Discover your potential, starting today</h1><button class="bg-white border font-semibold py-2 px-6 mt-6 hover:text-white rounded-full hover:bg-blue-500 text-blue-500 border-blue-500">
  Enroll today
</button></div></div><div class="bg-gray-100 py-16 px-6 flex my-4"><div class="max-w-4xl mx-auto"><h2 class="text-4xl font-bold mb-4">Course starts in</h2><p class="mb-4">Don't miss the opportunity. Enroll now</p><a href="#" class="inline-block bg-white text-blue-500 font-semibold py-3 px-8 rounded-lg shadow-lg hover:bg-gray-200 transition duration-300">Enroll Now</a></div><div id="i1fjnr" class="countdown"><span data-js="countdown" class="countdown-cont"><div class="countdown-block"><div data-js="countdown-day" class="countdown-digit"></div><div class="countdown-label">days</div></div><div class="countdown-block"><div data-js="countdown-hour" class="countdown-digit"></div><div class="countdown-label">hours</div></div><div class="countdown-block"><div data-js="countdown-minute" class="countdown-digit"></div><div class="countdown-label">minutes</div></div><div class="countdown-block"><div data-js="countdown-second" class="countdown-digit"></div><div class="countdown-label">seconds</div></div></span><span data-js="countdown-endtext" class="countdown-endtext"></span></div></div><section class="max-w-4xl mx-auto bg-white p-8 shadow-lg rounded-lg mt-8"><h1 class="text-3xl font-bold text-center mb-6">Instructor(s)</h1><div class="flex justify-center items-center"><!-- Profile Image --><div class="w-32 h-32 rounded-full overflow-hidden shadow-lg mb-4"><img src="https://via.placeholder.com/150" alt="Instructor Image" class="w-full h-full object-cover"/></div></div><!-- Instructor Name --><h2 class="text-2xl font-semibold text-center mb-4">Dr Mahendra Sethi</h2><!-- Instructor Bio --><p class="text-gray-700 text-center leading-relaxed mb-6">
  Dr. Mahendra Sethi is a certified L&amp;D Manager from CAMI USA and has over 18 years of experience in Learning and Development. He has received a Ph.D. Degree in Computer Science &amp; Engineering from Dr. Babasaheb Ambedkar Marathwada University, India. He has developed "An Interactive Dynamic eLearning Framework for Visual and Verbal Learners," which was much appreciated and recognized nationally and internationally. His passion for solving problems with optimal resources makes him a crucial team member.
</p></section><div class="py-10"><!-- Testimonials Section --><div class="w-full text-center"><h2 class="text-4xl font-serif text-gray-800 mb-10">Testimonials</h2><div class="border border-gray-300 rounded-lg p-6 bg-white"><div class="flex items-center"><span class="text-5xl text-blue-500">“</span><p class="text-lg text-gray-700 mx-4">
      Share testimonials about you, your course, or your content. Don’t be afraid to reach out to your network to get some glowing words about the work you’ve done. Most potential students will look to the opinions of others when making purchase decisions online.
    </p></div><div class="flex items-center mt-4"><img src="https://via.placeholder.com/40" alt="Author Image" class="h-10 w-10 rounded-full"/><input type="text" placeholder="Author's name" class="ml-4 border-b-2 border-red-500 focus:outline-none focus:border-blue-500 w-full p-2 text-sm text-gray-600"/></div></div></div><!-- FAQ Section --><div class="mt-10 w-full text-center"><h2 class="text-4xl font-serif text-gray-800 mb-6">FAQ</h2><div class="border border-gray-300 rounded-lg p-6 bg-white"><div class="flex items-center justify-between border-b border-red-500 py-4"><p class="text-lg text-gray-700">Include questions a potential student may have before purchase.</p><span class="text-red-500">▼</span></div><div class="flex items-center justify-between border-b border-red-500 py-4"><p class="text-lg text-gray-700">Include questions a potential student may have before purchase.</p><span class="text-red-500">▼</span></div><div class="flex items-center justify-between py-4"><p class="text-lg text-gray-700">Include questions a potential student may have before purchase.</p><span class="text-red-500">▼</span></div></div></div></div><!-- Footer --><footer class="bg-white shadow-inner p-4 mt-8"><div class="container mx-auto text-center text-gray-600">
        © 2024 CompanyName. All rights reserved.
    </div></footer></body><script>var props = {"i1fjnr":{"startfrom":"","endText":"EXPIRED"}};
      var ids = Object.keys(props).map(function(id) { return '#'+id }).join(',');
      var els = document.querySelectorAll(ids);
      for (var i = 0, len = els.length; i < len; i++) {
        var el = els[i];
        (function (n) {
        var t = n.startfrom,
          e = n.endText,
          o = this,
          a = new Date(t).getTime(),
          c = o.querySelector('[data-js=countdown]'),
          d = o.querySelector('[data-js=countdown-endtext]'),
          s = o.querySelector('[data-js=countdown-day]'),
          l = o.querySelector('[data-js=countdown-hour]'),
          i = o.querySelector('[data-js=countdown-minute]'),
          r = o.querySelector('[data-js=countdown-second]'),
          u = o.__gjsCountdownInterval;
        u && clearInterval(u);
        var p = window.__gjsCountdownIntervals || [],
          v = [];
        p.forEach(function (n) {
          n.isConnected || (clearInterval(n.__gjsCountdownInterval), v.push(n));
        }), p.indexOf(o) < 0 && p.push(o), window.__gjsCountdownIntervals = p.filter(function (n) {
          return v.indexOf(n) < 0;
        });
        var y = function (n, t, e, o) {
            s.innerHTML = "".concat(n < 10 ? '0' + n : n), l.innerHTML = "".concat(t < 10 ? '0' + t : t), i.innerHTML = "".concat(e < 10 ? '0' + e : e), r.innerHTML = "".concat(o < 10 ? '0' + o : o);
          },
          f = function () {
            var n = new Date().getTime(),
              t = a - n,
              s = Math.floor(t / 864e5),
              l = Math.floor(t % 864e5 / 36e5),
              i = Math.floor(t % 36e5 / 6e4),
              r = Math.floor(t % 6e4 / 1e3);
            y(s, l, i, r), t < 0 && (clearInterval(o.__gjsCountdownInterval), d.innerHTML = e, c.style.display = 'none', d.style.display = '');
          };
        a ? (o.__gjsCountdownInterval = setInterval(f, 1e3), d.style.display = 'none', c.style.display = '', f()) : y(0, 0, 0, 0);
      }.bind(el))(props[el.id]);
      }</script>

`


};
    
    





    
