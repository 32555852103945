import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TextField from '@mui/material/TextField';
import TableUtilityLib from '../Utilities/TableUtilityLib';
import CommonButton from '../Utilities/CommonButton';
import {
  UserTable,
  HistoryPage,
  SearchBar,
  SearchBarContainer,
} from '../../styles/Users/UsersStyles';
import {
  WholePage,
  TableButtonDiv,
  SeparateTableButton,
} from '../../styles/Certificate/CertificateStyle';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Select,
  MenuItem,
  Menu,
  Tooltip,
  CircularProgress,
  IconButton,
  Modal,
  Box,
} from '@mui/material';
import ExcelTable from './ExcelTable';
import {
  fetchUser,
  createUser,
  fetchRole,
} from '../../features/userModule/userModuleSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import NotificationTabs from './UserTabs';

const Admins = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedValue, setSelectedValue] = useState('Admin');
  const [selectedRole, setSelectedRole] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [token, setTokan] = useState([]);
  const [userData, setUserData] = useState([]);
  const fileInputRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { users, addusers } = useSelector((state) => state.users);
  const { roles } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  useEffect(() => {
    if (users.length) {
      const sortedUsers = [...users].sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
      );
      setUserData(sortedUsers);
    }
  }, [users]);

  useEffect(() => {
    if (!roles.data || roles.data.length === 0) {
      dispatch(fetchRole());
    }
  }, [dispatch, roles.data]);

  const rolesToSave =
    selectedRole === 'Select All'
      ? [{ role: 'Select All', isEnabled: true }]
      : [{ role: selectedRole, isEnabled: false }];

  console.log(roles.data);

  const handleRowClick = (row) => {
    navigate(`/users/UserDetails/${row._id}`);
  };

  const handleOpenDialog = () => {
    if (selectedValue === 'Admin') {
      data.role = 'Admin';
    } else if (selectedValue === 'Instructor') {
      data.role = 'Instructor';
    } else if (selectedValue === 'Learner') {
      data.role = 'Learner';
    } else if (selectedValue === 'Course Reviewer') {
      data.role = 'Course Reviewer';
    } else if (selectedValue === 'Course Developer') {
      data.role = 'Course Builder';
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setData({
      firstname: '',
      lastname: '',
      email: '',
      role: '',
      password: 'password', // Resetting to default password
    });
    setOpenDialog(false);
  };

  //Download CSV file
  const downloadCSV = () => {
    // Define headers
    const headers = ['firstname', 'lastname', 'email', 'role'];
    // Filter users based on selectedValue
    const filteredUsers = users.filter((user) => user.role === selectedValue);
    // Prepare CSV content
    const csvContent =
      'data:text/csv;charset=utf-8,' +
      [headers.join(',')]
        .concat(
          filteredUsers
            .map((user) => headers.map((header) => user[header]))
            .map((row) => row.join(',')),
        )
        .join('\n');

    // Create and download CSV file
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    var timestamp = new Date().getTime();
    let fileName = 'user_' + selectedValue + '_' + timestamp + '.csv';
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  };

  const logfilesdata = () => {
    navigate(`/users/HistoryofImports`);
  };
  const getFilteredData = (data) => {
    return Array.isArray(data)
      ? data.filter((user) => {
          if (selectedValue === 'Admin') {
            return (
              user.role === 'Admin' &&
              (user?.firstname
                ?.toLowerCase()
                .includes(searchQuery?.toLowerCase()) ||
                user?.lastname
                  ?.toLowerCase()
                  .includes(searchQuery?.toLowerCase()) ||
                user?.email
                  ?.toLowerCase()
                  .includes(searchQuery?.toLowerCase()) ||
                user?.role?.toLowerCase().includes(searchQuery?.toLowerCase()))
            );
          } else if (selectedValue === 'Instructor') {
            return (
              user.role === 'Instructor' &&
              (user?.firstname
                ?.toLowerCase()
                .includes(searchQuery?.toLowerCase()) ||
                user?.lastname
                  ?.toLowerCase()
                  .includes(searchQuery?.toLowerCase()) ||
                user?.email
                  ?.toLowerCase()
                  .includes(searchQuery?.toLowerCase()) ||
                user?.role?.toLowerCase().includes(searchQuery?.toLowerCase()))
            );
          } else if (selectedValue === 'Learner') {
            return (
              user.role === 'Learner' &&
              (user?.firstname
                ?.toLowerCase()
                .includes(searchQuery?.toLowerCase()) ||
                user?.lastname
                  ?.toLowerCase()
                  .includes(searchQuery?.toLowerCase()) ||
                user?.email
                  ?.toLowerCase()
                  .includes(searchQuery?.toLowerCase()) ||
                user?.role?.toLowerCase().includes(searchQuery?.toLowerCase()))
            );
          } else if (selectedValue === 'Course Reviewer') {
            return (
              user.role === 'Course Reviewer' &&
              (user?.firstname
                ?.toLowerCase()
                .includes(searchQuery?.toLowerCase()) ||
                user?.lastname
                  ?.toLowerCase()
                  .includes(searchQuery?.toLowerCase()) ||
                user?.email
                  ?.toLowerCase()
                  .includes(searchQuery?.toLowerCase()) ||
                user?.role?.toLowerCase().includes(searchQuery?.toLowerCase()))
            );
          } else if (selectedValue === 'Course Developer') {
            return (
              user.role === 'Course Developer' &&
              (user?.firstname
                ?.toLowerCase()
                .includes(searchQuery?.toLowerCase()) ||
                user?.lastname
                  ?.toLowerCase()
                  .includes(searchQuery?.toLowerCase()) ||
                user?.email
                  ?.toLowerCase()
                  .includes(searchQuery?.toLowerCase()) ||
                user?.role?.toLowerCase().includes(searchQuery?.toLowerCase()))
            );
          }
        })
      : [];
  };
  const filteredData = getFilteredData(userData);

  const commonColumns = [
    { name: 'First Name', selector: (row) => row.firstname, sortable: true },
    { name: 'Last Name', selector: (row) => row.lastname, sortable: true },
    {
      name: 'Phone Number',
      selector: (row) => {
        const phoneNo = row.phoneNo; // Adjust if lastLogin is nested
        return phoneNo ? phoneNo : 'N/A';
      },
    },
    {
      id: 'verified',
      name: 'Verified Status',
      selector: (row) => (row.email_verified ? 'Verified' : 'Not Verified'),
    },
    {
      name: 'Last Login',
      selector: (row) => {
        const lastLogin = row.lastLogin; // Adjust if lastLogin is nested
        return lastLogin
          ? format(new Date(lastLogin), 'MM/dd/yyyy, hh:mm a')
          : 'N/A';
      },
      sortable: true,
    },

    {
      id: 'blacklist',
      name: 'Blacklist Status',
      selector: (row) => (row.blacklisted ? 'Blacklisted' : 'Not Blacklisted'),
    },
  ];
  // Additional columns for Learner role
  const learnerColumns = [
    {
      name: 'Last Sign In',
      // selector: (row) => row.learnerDetails.lastSignIn,
      selector: (row) => {
        const lastSignIn = row.learnerDetails.lastSignIn;
        return lastSignIn
          ? format(new Date(lastSignIn), 'MM/dd/yyyy, hh:mm a')
          : '';
      },
      sortable: true,
    },
    {
      name: 'Referred By',
      selector: (row) => row.learnerDetails.referredBy,
      sortable: true,
    },
    {
      name: 'Sign In Count',
      selector: (row) => row.learnerDetails.signInCount,
      sortable: true,
    },
    {
      name: 'Active Enrollments',
      selector: (row) => row.learnerDetails.activeEnrollments,
      sortable: true,
    },
  ];

  // Combine columns based on selected role
  const columns =
    selectedValue === 'Learner'
      ? [...commonColumns, ...learnerColumns]
      : commonColumns;

  const handleFormSubmit = (event) => {
    event.preventDefault();
  };

  const handleSelectValue = (e) => {
    const getValue = e.target.value;
    console.log(getValue);
    setSelectedValue(getValue);
  };

  const [data, setData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    role: '',
    password: 'password',
    referredBy: '',
  });

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleValueChange = (e) => {
    const { name, value } = e.target;
    console.log(value);
    if (name === 'email') {
      setIsValid(validateEmail(value));
    }
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddEvent = async () => {
    setIsAddingUser(true);
    data.role = selectedValue;
    console.log('Data to be sent:', data);
    if (!data.firstname || !data.lastname || !data.email || !data.role) {
      toast.warning('Please fill in all required fields.');
      setIsAddingUser(false);
      return;
    }
    try {
      // Check if the email already exists in the users list
      const existingUsers = await dispatch(fetchUser()).then(
        (response) => response.payload,
      );
      if (
        Array.isArray(existingUsers) &&
        existingUsers.some((user) => user.email === data.email)
      ) {
        toast.error(
          `User with the email id "${data.email}" already exists. Please recheck and enter a valid email id.`,
        );
        setIsAddingUser(false);
        return;
      }
      const createdUser = await dispatch(createUser(data));

      console.log(createdUser);

      if (createdUser && !createdUser.message) {
        setUserData((prevUserData) => {
          // Create a new array with the newly created user at the beginning
          const updatedUserData = [
            { ...createdUser.payload, newlyAdded: true },
            ...prevUserData,
          ];
          console.log('User Data before sorting:', updatedUserData);
          // Create a sorted copy of the updated array
          const sortedUserData = [...updatedUserData].sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
          );
          console.log('User Data after sorting:', sortedUserData);
          return sortedUserData;
        });
        setData({
          firstname: '',
          lastname: '',
          email: '',
          role: '',
          password: 'password',
          referredBy: '',
        });

        // Fetch and sort users again to ensure the list is updated and sorted
        dispatch(fetchUser()).then((response) => {
          console.log('Fetched Users before sorting:', response.payload);
          const sortedUsers = [...response.payload].sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
          );
          console.log('Fetched Users after sorting:', sortedUsers);
          setUserData(sortedUsers);
        });

        toast.success('User added successfully!');
        setOpenDialog(false);
      } else if (createdUser.message) {
        toast.error(`${createdUser.message}`);
      } else {
        console.error('Failed to create user (check API response)');
        toast.error('Failed to create user. Please try again.');
      }
    } catch (error) {
      console.error('Failed to create user:', error);
      toast.error(`Failed to create user: ${error.message}`);
    } finally {
      setIsAddingUser(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleChildValueChange = (value) => {
    dispatch(fetchUser()); // Refresh the user data after CSV import
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <>
      <NotificationTabs />
      <WholePage style={{ paddingTop: '3rem' }}>
        <SearchBarContainer
          style={{
            position: 'fixed',
            zIndex: 49,
            width: '100%',
            backgroundColor: '#fff',
            padding: '30px 200px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '-75px',
          }}
        >
          <SearchBar onSubmit={handleFormSubmit}>
            <Select
              size="small"
              variant="outlined"
              style={{
                width: '300px',
                marginBottom: '3px',
                marginLeft: '-180px',
              }}
              onChange={handleSelectValue}
              value={selectedValue}
            >
              {Array.isArray(roles.data) &&
                roles.data.map((role) => (
                  <MenuItem key={role._id} value={role.name}>
                    {role.name}
                  </MenuItem>
                ))}
            </Select>

            <TextField
              label="Search"
              size="small"
              variant="outlined"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{
                width: '300px',
                marginBottom: '3px',
                marginLeft: '170px',
                marginRight: '70px',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />

            <TableButtonDiv>
              <Tooltip title="Click here to add new user">
                <SeparateTableButton>
                  <CommonButton
                    label="Create"
                    onClick={handleOpenDialog}
                    disabled={isLoading}
                    style={{ marginLeft: '30px' }}
                  />
                </SeparateTableButton>
              </Tooltip>
              <ExcelTable onChildValueChange={handleChildValueChange} />

              <SeparateTableButton>
                <IconButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={logfilesdata}>History of Imports</MenuItem>
                  <MenuItem onClick={downloadCSV}>Export Users</MenuItem>
                </Menu>
              </SeparateTableButton>
            </TableButtonDiv>
          </SearchBar>
        </SearchBarContainer>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Add {selectedValue}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <TextField
                label="FirstName"
                variant="outlined"
                fullWidth
                margin="normal"
                name="firstname"
                value={data.firstname}
                onChange={handleValueChange}
              />
              <TextField
                label="LastName"
                variant="outlined"
                fullWidth
                margin="normal"
                name="lastname"
                value={data.lastname}
                onChange={handleValueChange}
              />

              <TextField
                label="Email"
                variant="outlined"
                margin="normal"
                fullWidth
                name="email"
                value={data.email}
                onChange={handleValueChange}
                sx={{ borderColor: isValid ? 'green' : 'red' }}
              />
              {isValid ? null : (
                <span style={{ color: 'red' }}>
                  Please enter a valid email address
                </span>
              )}
              <TextField
                label="Role"
                variant="outlined"
                margin="normal"
                sx={{ display: 'none' }}
                fullWidth
                name="role"
                value={data.role}
                onChange={handleValueChange}
              />

              <TextField
                label="Password"
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                sx={{ display: 'none' }}
                value={data.password}
                onChange={handleValueChange}
              />
              {selectedValue === 'Learner' && (
                <TextField
                  label="Referred By"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="referredBy"
                  value={data.referredBy}
                  onChange={handleValueChange}
                />
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CommonButton label="Add" onClick={handleAddEvent} />
            <CommonButton label="Cancel" onClick={handleCloseDialog} />
          </DialogActions>
        </Dialog>
        <HistoryPage>
          <UserTable>
            <TableUtilityLib
              columns={columns}
              data={filteredData}
              selectableRows={false}
              onRowClicked={(row) => handleRowClick(row)}
            />
          </UserTable>
        </HistoryPage>

        <Modal
          open={isAddingUser}
          aria-labelledby="adding-user-modal-title"
          aria-describedby="adding-user-modal-description"
        >
          <Box sx={modalStyle}>
            <CircularProgress
              style={{ height: '20px', width: '20px', marginRight: '15px' }}
            />
            <Typography>
              Please wait few moments till the user is added...
            </Typography>
          </Box>
        </Modal>
      </WholePage>
    </>
  );
};

export default Admins;
