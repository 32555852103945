import React, { useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  StyledTabs,
  StyledTab,
} from '../../../styles/Certificate/CertificateStyle';
import { WholeCourseSetting } from '../../../features/courses/CourseUtilitySlice';

const CourseSettingTabs = ({ showCertificateTab }) => {
  const location = useLocation();
  const { courseId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(WholeCourseSetting(courseId));
  }, [dispatch, courseId]);

  const { wholeCourseSetting } = useSelector((state) => state.CourseUtility);
  const certificateAvailability =
    wholeCourseSetting?.data?.course?.accessAndEnrollment
      ?.certificateAvailability;

  // Update the defaultTabValue to include course-specific routes
  const defaultTabValue = [
    `/CourseBuilder/CourseSettings/${courseId}`,
    `/CourseBuilder/CoursePlayerAppearenceSetting/${courseId}`,
    `/CourseProgressCompletionSetting/${courseId}`,
    `/CourseBuilder/CourseCertificateSettings/${courseId}`,
    `/CourseBuilder/CourseNotificationSettings/${courseId}`,
    `/CourseBuilder/CourseIDVerificationSettings/${courseId}`,
  ].includes(location.pathname)
    ? location.pathname
    : `/CourseBuilder/CourseSettings/${courseId}`;

  return (
    <StyledTabs value={defaultTabValue}>
      <StyledTab
        label="Basic"
        value={`/CourseBuilder/CourseSettings/${courseId}`}
        component={Link}
        to={`/CourseBuilder/CourseSettings/${courseId}`}
      />
      {/* Uncomment if you want to add Player Appearance tab in the future */}
      {/* <StyledTab
        label="Player Appearance"
        value={`/CourseBuilder/CoursePlayerAppearenceSetting/${courseId}`}
        component={Link}
        to={`/CourseBuilder/CoursePlayerAppearenceSetting/${courseId}`}
      /> */}
      {certificateAvailability && (
        <StyledTab
          label="Certificate"
          value={`/CourseBuilder/CourseCertificateSettings/${courseId}`}
          component={Link}
          to={`/CourseBuilder/CourseCertificateSettings/${courseId}`}
        />
      )}
      <StyledTab
        label="Notification"
        value={`/CourseBuilder/CourseNotificationSettings/${courseId}`}
        component={Link}
        to={`/CourseBuilder/CourseNotificationSettings/${courseId}`}
      />
      <StyledTab
        label="Verification"
        value={`/CourseBuilder/CourseIDVerificationSettings/${courseId}`}
        component={Link}
        to={`/CourseBuilder/CourseIDVerificationSettings/${courseId}`}
      />
    </StyledTabs>
  );
};

export default CourseSettingTabs;
