import axios from 'axios';

const URL = process.env.REACT_APP_URL;

export const saveCourseDetailsApi = async (data) => {
  try {
    console.log(data, 'saveCourseDetailsApi');
    return await axios.post(`${URL}/api/course/addCourseDetails`, data);
    console.log(data, 'saveCourseDetailsApi');
    return await axios.post(`${URL}/api/course/addCourseDetails`, data);
  } catch (err) {
    console.log('Error occurs while saving course Details', err);
    throw err;
  }
};

export const publishCourseApi = async (data) => {
  try {
    console.log(data, 'publishCourseApi');
    const response = await axios.post(`${URL}/api/course/publishCourse`, data);
    return response.data; // Return the successful response
  } catch (err) {
    console.log('Error occurs while publishing course', err);

    // Return a structured error payload with proper message
    const errorMessage = err.response?.data?.message || 'Server error';
    console.log(err.response?.data?.message);

    return Promise.reject({
      message: errorMessage,
    });
  }
};

export const fetchCourseApi = async (user) => {
  try {
    return await axios.get(`${URL}/api/course/fetchCourses`, {
      params: {
        role: user.role,
        userEmail: user.email,
      },
    });
  } catch (err) {
    console.log('Error occurs while fetchCourseApi', err);
    throw err;
  }
};

export const fetchMyCoursesApi = async (data) => {
  try {
    return await axios.get(`${URL}/api/event/fetchRegisterLearnerById`, {
      params: { userid: data?.userid?._id }, // Ensure params is used correctly
    });
  } catch (err) {
    console.log('Error occurs while fetchCourseApi', err);
    throw err;
  }
};

export const fetchMyCoursesForInstructorApi = async (data) => {
  try {
    return await axios.get(
      `${URL}/api/course/getCoursesByInstructor/${data.userid?._id}`,
    );
  } catch (err) {
    console.log('Error occurs while fetchCourseApi', err);
    throw err;
  }
};

export const CreateCourseApi = async (courseInfo) => {
  try {
    return await axios.post(`${URL}/api/course/createCourse`, courseInfo);
  } catch (err) {
    console.log('Error occurs while fetchCourseApi', err);
    throw err;
  }
};

export const createCourseByIdApi = async (id) => {
  try {
    return await axios.post(`${URL}/api/course/createCourseById`, { id: id });
  } catch (err) {
    console.log('Error occurs while createCourseByIdApi', err);
    throw err;
  }
};

export const fetchCourseDetailsApi = async (id) => {
  console.log(id, 'fetchCourseDetailsApi');

  try {
    return await axios.get(`${URL}/api/course/fetchCoursesWithDetails`, {
      params: { id },
    });
  } catch (err) {
    console.log('Error occurs while fetchCourseDetailsApi', err);
    throw err;
  }
};

export const postDiscussionApi = async (data) => {
  try {
    return await axios.post(`${URL}/api/course/addMessage`, data);
  } catch (err) {
    console.log('Error occurs while posting', err);
    throw err;
  }
};

export const fetchDiscussionApi = async (courseId) => {
  try {
    return await axios.get(`${URL}/api/course/messages/${courseId}`);
  } catch (err) {
    console.log('Error occurs while fetching', err);
    throw err;
  }
};

export const fetchRegistrationsApi = async (courseid) => {
  try {
    const response = await axios.get(
      `${URL}/api/event/fetchEventRegistration?courseid=${courseid}`,
    );
    return response;
  } catch (err) {
    console.log('Error occurs while fetching registrations', err);
    throw err;
  }
};

export const saveBasicSettingApi = async ({ courseId, formData }) => {
  try {
    // Log the courseId and formData before making the API call
    console.log('Course ID:', courseId);
    console.log('Form Data:', formData);

    // Make the API call and log the data being sent
    const response = await axios.post(
      `${URL}/api/course/saveOrUpdateCourseBasicSetting/${courseId}`,
      formData,
    );

    // Log the response from the API call
    console.log('API Response:', response);

    return response;
  } catch (err) {
    // Log the error in case the API call fails
    console.error('Error occurs while saving course details:', err);

    // Re-throw the error for further handling
    throw err;
  }
};

export const courseAppearceSettingApi = async (data) => {
  try {
    console.log(data, 'courseAppearceSettingApi');
    return await axios.post(
      `${URL}/api/course/saveOrUpdateCourseAppearanceSetting`,
      data,
    );
  } catch (err) {
    console.log('Error occurs while saving course Details', err);
    throw err;
  }
};
//create course template

export const CreatetemplateApi = async (data) => {
  try {
    console.log('Data being sent to CreatetemplateApi:', data);
    const response = await axios.post(`${URL}/api/course/Createtemplate`, data);
    console.log('Response from CreatetemplateApi:', response);
    return response;
  } catch (err) {
    console.error('Error occurred while creating template:', err);
    throw err;
  }
};

export const gettemplateApi = async () => {
  try {
    console.log('Fetching templates from gettemplateApi');
    const response = await axios.post(`${URL}/api/course/GetTemplate`);
    console.log('Response from gettemplateApi:', response);
    return response.data;
  } catch (err) {
    console.error('Error occurred while fetching templates:', err);
    throw err;
  }
};

export const fetchCourseSettingsApi = async (courseId) => {
  try {
    console.log('cheking course id in api', courseId);

    const response = await axios.get(
      `${URL}/api/course/getCourseBasicSetting/${courseId}`,
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const WholeCourseSettingApi = async (courseId) => {
  try {
    console.log('cheking course id in api', courseId);

    const response = await axios.get(`${URL}/api/course/settings/${courseId}`);
    return response;
  } catch (error) {
    throw error;
  }
};
export const fetchCourseCertificateSettingsApi = async (courseId) => {
  try {
    // Log the course ID and URL
    console.log('Fetching certificate settings for Course ID:', courseId);
    console.log(
      'API URL:',
      `${URL}/api/course/certificate-settings/${courseId}`,
    );

    // Make the API call
    const response = await axios.get(
      `${URL}/api/course/certificate-settings/${courseId}`,
    );

    // Log the response
    console.log('API Response:', response);

    return response;
  } catch (error) {
    // Log the error
    console.error('Error fetching course certificate settings:', error);
    throw error;
  }
};

export const fetchCourseNotificationApi = async (courseId) => {
  try {
    console.log(
      `Starting to fetch notification settings for course ID: ${courseId}`,
    );

    const response = await axios.get(
      `${URL}/api/course/notification-settings/${courseId}`,
    );

    console.log('API Response:', response); // Log full response object
    console.log('Notification Settings Data:', response.data); // Log the data returned

    return response.data; // Return the notification settings data
  } catch (err) {
    console.error(
      `Error occurred while fetching notifications for course ID: ${courseId}`,
    );
    console.error(
      'Error Details:',
      err.response ? err.response.data : err.message,
    ); // Log detailed error
    throw err; // Rethrow the error so it can be handled by the calling function
  }
};

export const saveCertificateSettingsApi = async (courseId, formData) => {
  try {
    console.log(
      `Starting to fetch certificate settings for course ID: ${courseId}`,
    );

    const response = await axios.post(
      `${URL}/api/course/save-certificate-settings/${courseId}`,
      formData,
    );

    console.log('API Response:', response); // Log full response object
    console.log('certificate Settings Data:', response.data); // Log the data returned

    return response.data; // Return the notification settings data
  } catch (err) {
    console.error(
      `Error occurred while fetching certificate for course ID: ${courseId}`,
    );
    console.error(
      'Error Details:',
      err.response ? err.response.data : err.message,
    ); // Log detailed error
    throw err; // Rethrow the error so it can be handled by the calling function
  }
};
export const saveNotificationSettingsApi = async (courseId, formData) => {
  try {
    console.log(
      `Starting to fetch notification settings for course ID: ${courseId}`,
    );

    const response = await axios.post(
      `${URL}/api/course/save-notification-settings/${courseId}`,
      formData,
    );

    console.log('API Response:', response); // Log full response object
    console.log('notification Settings Data:', response.data); // Log the data returned

    return response.data; // Return the notification settings data
  } catch (err) {
    console.error(
      `Error occurred while fetching certificate for course ID: ${courseId}`,
    );
    console.error(
      'Error Details:',
      err.response ? err.response.data : err.message,
    ); // Log detailed error
    throw err; // Rethrow the error so it can be handled by the calling function
  }
};
