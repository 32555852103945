import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  createUserApi,
  fetchUserApi,
  updateUserApi,
  deleteUserApi,
  fetchUserDetailsApi,
  createUserByCSVApi,
  fetchUserRoleApi,
  fetchUserDetailsbyRoleApi,
  passwordResetApi,
  fetchUserHistoryApi,
  fetchBlacklistedUsers,
  updateBlacklistedUserApi,
  deleteBlacklistedUserApi,
  blacklistApi,
} from '../../api/UserModuleApi';
import axios from 'axios';

// Create user
export const createUser = createAsyncThunk(
  'usermodule/createnewusers',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await createUserApi(userData);
      console.log('create response:', response);
      return response;
    } catch (error) {
      console.error('Error in createUser thunk:', error);
      return rejectWithValue(error.message);
    }
  },
);

// Fetch user
export const fetchUser = createAsyncThunk(
  'usermodule/fetchUser',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchUserApi();
      console.log('fetchUserApi response:', response); // Add this line to log the response

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// Create user by CSV
export const createUserByCsv = createAsyncThunk(
  'usermodule/usersCsv',
  async (csvData, { rejectWithValue }) => {
    try {
      const response = await createUserByCSVApi(csvData);
      console.log(response);

      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

// Update user
export const updateUser = createAsyncThunk(
  'usermodule/updateUserProfile',
  async ({ id, userdetails }, { rejectWithValue }) => {
    try {
      const response = await updateUserApi(id, userdetails);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// Fetch user details
export const fetchUserDetails = createAsyncThunk(
  'usermodule/fetchUserDetails',
  async (id, { rejectWithValue }) => {
    try {
      const response = await fetchUserDetailsApi(id);
      console.log('userdetails', response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// Delete user
export const deleteUser = createAsyncThunk(
  'usermodule/deleteUser',
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteUserApi(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// Fetch user details by role
export const fetchUserDetailsbyRole = createAsyncThunk(
  'usermodule/fetchUserDetailsbyRole',
  async (role, { rejectWithValue }) => {
    try {
      const response = await fetchUserDetailsbyRoleApi(role);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// Fetch roles
export const fetchRole = createAsyncThunk(
  '/fetchRole',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchUserRoleApi();
      console.log('roles', response);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// Password reset
export const passwordReset = createAsyncThunk(
  'usermodule/passwordReset',
  async (email, { rejectWithValue }) => {
    try {
      const response = await passwordResetApi(email);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchUserHistory = createAsyncThunk(
  'usermodule/getuserhistory',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchUserHistoryApi();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
export const blacklistUser = createAsyncThunk(
  'usermodule/blacklistUser',
  async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState(); // Access the Redux state
      console.log('Checking State:', state); // Log the entire state

      const disqualifiedBy =
        state.auth?.users?.user.userid?.firstname || 'Unknown';
      console.log('Checking Disqualified By:', disqualifiedBy); // Log the disqualifiedBy value

      const payload = { ...data, disqualifiedBy };
      console.log('Checking Data before sending to API:', payload); // Log the data being sent to the API

      const response = await blacklistApi(data.email, payload);

      console.log('Checking API Response:', response); // Log the API response
      return response.data;
    } catch (error) {
      console.log('Error:', error); // Log the error if any occurs
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);
export const fetchBlacklistedUsersApi = createAsyncThunk(
  '/usermodule/getBlacklistedUsers',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchBlacklistedUsers();
      console.log('checkin response', response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);
export const updateBlacklistedUser = createAsyncThunk(
  'usermodule/updateBlacklistedUser',
  async ({ email, updatedData }, { rejectWithValue }) => {
    try {
      // Log the payload to check what is being sent
      console.log('Payload for updateBlacklistedUser:', { email, updatedData });

      const response = await updateBlacklistedUserApi(email, updatedData);

      console.log('API Response:', response);
      return response.data;
    } catch (error) {
      console.log('Error in updateBlacklistedUser thunk:', error);
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);
export const deleteBlacklistedUser = createAsyncThunk(
  'usermodule/deleteBlacklistedUser',
  async (email, { rejectWithValue }) => {
    try {
      await deleteBlacklistedUserApi(email);
      return email;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

// Define the initial state
const initialState = {
  data: [],
  addusers: [],
  users: [],
  csv: [],
  userdetails: [],
  roles: [],
  password: [],
  userdets: [],
  history: [],
  loading: false,
  isLoading: false,
  progress: 0,
  error: null,
};

const userModuleSlice = createSlice({
  name: 'usermodule',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.data = null;
      })

      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
        state.error = null;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        // state.users = [];
      })

      .addCase(createUserByCsv.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createUserByCsv.fulfilled, (state, action) => {
        state.loading = false;
        state.csv = action.payload;
        state.error = null;
      })
      .addCase(createUserByCsv.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.csv = null;
      })

      .addCase(updateUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
        state.error = null;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.users = null;
      })

      .addCase(fetchUserDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.userdetails = action.payload;
        state.error = null;
      })
      .addCase(fetchUserDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.userdetails = null;
      })

      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.data = null;
      })

      .addCase(fetchUserDetailsbyRole.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserDetailsbyRole.fulfilled, (state, action) => {
        state.loading = false;
        state.userdets = action.payload;
        state.error = null;
      })
      .addCase(fetchUserDetailsbyRole.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.userdets = null;
      })

      .addCase(fetchRole.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRole.fulfilled, (state, action) => {
        state.loading = false;
        state.roles = action.payload;
        state.error = null;
      })
      .addCase(fetchRole.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.roles = null;
      })

      .addCase(passwordReset.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(passwordReset.fulfilled, (state, action) => {
        state.loading = false;
        state.password = action.payload;
        state.error = null;
      })
      .addCase(passwordReset.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.password = null;
      })
      .addCase(fetchUserHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.history = action.payload;
        state.error = null;
      })
      .addCase(fetchUserHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.history = null;
      })
      .addCase(fetchBlacklistedUsersApi.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBlacklistedUsersApi.fulfilled, (state, action) => {
        state.loading = false;
        state.blacklist = action.payload;
        state.error = null;
      })
      .addCase(fetchBlacklistedUsersApi.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.blacklist = null;
      })
      .addCase(updateBlacklistedUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateBlacklistedUser.fulfilled, (state, action) => {
        state.loading = false;
        console.log('checking Update fulfilled payload:', action.payload);
        state.updatedUser = action.payload;
        state.error = null;
      })

      .addCase(updateBlacklistedUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteBlacklistedUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteBlacklistedUser.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteBlacklistedUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default userModuleSlice.reducer;
