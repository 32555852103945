import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import JoditEditor from 'jodit-react';
import { TextField, Tooltip, Typography } from '@mui/material';
import CommonButton from '../Utilities/CommonButton';
import BackButton from '../Utilities/BackButton';
import { addCertificateTemplate } from '../../features/certificate/CertificateSlice';
import {
  CertificateForm,
  JoditEditorDiv,
  CertificateHeading,
  ArrowDiv,
} from '../../styles/Certificate/CertificateStyle';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

function AddTemplate() {
  const config = useMemo(
    () => ({
      height: 400,
      readonly: false,
      uploader: {
        insertImageAsBase64URI: true, // Enable base64 image insertion
      },
      events: {
        beforeFileUpload: (files) => {
          const file = files[0];

          // Check if the file size is larger than 2MB
          if (file.size > 2 * 1024 * 1024) {
            toast.error('Image size should not exceed 2MB.');
            return false; // Prevent the upload
          }

          // Convert the image to base64 and insert it into the editor
          const reader = new FileReader();
          reader.onload = (e) => {
            const base64 = e.target.result;
            const editor = this.jodit;
            editor.s.insertImage(base64);
          };
          reader.readAsDataURL(file);
          return false; // Prevent default upload behavior
        },
      },
    }),
    [],
  );

  const dispatch = useDispatch();
  const [templateName, setTemplateName] = useState('');
  const [content, setContent] = useState('');
  const navigate = useNavigate();
  const [fieldErrors, setFieldErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = () => {
    const errors = {};

    if (templateName.trim() === '') {
      errors.templateName = 'Template Name is required.';
    }

    if (content.trim() === '') {
      alert('Template is required.');
      return;
    }

    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }

    const templateData = {
      certificateName: templateName,
      certificateBody: content,
    };

    setIsSaving(true);

    dispatch(addCertificateTemplate(templateData))
      .then(() => {
        setTemplateName('');
        setContent('');
        toast.success('Template created successfully!');
        navigate('/Certificate/CertificatePage');
      })
      .catch(() => {
        toast.error('Error in creating template');
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const { t } = useTranslation();
  return (
    <>
      <ArrowDiv
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <BackButton />
          <CertificateHeading>
            {t('certificate.designTemplate')}
          </CertificateHeading>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '16px',
          }}
        >
          <Tooltip title="Click here to save" placement="bottom">
            <span>
              <CommonButton
                onClick={handleSubmit}
                label={
                  isSaving
                    ? 'Saving...'
                    : t('certificate.certificateCreateLabel')
                }
                disabled={isSaving}
              />
            </span>
          </Tooltip>
        </div>
      </ArrowDiv>
      <CertificateForm>
        <TextField
          label={t('certificate.TempleteNameLabel')}
          variant="outlined"
          fullWidth
          value={templateName}
          error={Boolean(fieldErrors.templateName)}
          helperText={fieldErrors.templateName}
          onChange={(e) => setTemplateName(e.target.value)}
          style={{ marginBottom: '16px', marginTop: '8px' }}
        />
        <Typography variant="caption" color="textSecondary">
          {t('certificate.PleaseNote')}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {t('certificate.disclaimer1')}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {t('certificate.disclaimer2')}
        </Typography>

        <JoditEditorDiv>
          <JoditEditor
            value={content}
            config={config}
            onChange={(newContent) => setContent(newContent)}
          />
        </JoditEditorDiv>
      </CertificateForm>
    </>
  );
}

export default AddTemplate;
