import React, { useState, useEffect } from 'react';
import { updateCourseInfoInSlice } from '../../features/courses/addCourseDetailsSlice';
import axios from 'axios';

import {
  Container,
  Grid,
  TextField,
  Typography,
  Switch,
  IconButton,
  Tooltip,
  MenuItem,
  FormControl,
  Select,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import {
  CreateContent,
  ContainerStyle,
  ButtonCont,
} from '../../styles/Courses/CourseBuilderStyles';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { PageContainer } from '../../styles/Common/CommonStyles';
import CommonButton from '../Utilities/CommonButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { palette } from '../../styles/palette';
import JoditReact from 'jodit-react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { useDispatch } from 'react-redux';
import { WholeCourseSettingApi } from '../../api/CoursesApi';

const CourseDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { courseId } = useParams();
  // const [courseDets, setCourseDets] = useState(location.state);
  const [courseDets, setCourseDets] = useState(
    location.state ?? {
      generalInformation: {
        title: '',
        description: '',
        courseCode: '',
        courseDuration: '',
        courseLevel: '',
        courseCategory: '',
        prerequisites: '',
        language: '',
        instructorName: [''],
        bannerImage: '',
        courseStructure: '',
        isPublished: false,
      },
      isPaidCourse: false,
      pricingDetails: {
        actualAmount: 0,
        discount: 0,
        discountedAmount: 0,
        taxes: 0,
        totalAmount: 0,
      },
      contentManagement: {
        learningObjectives: '' || null,
        estimatedTimeToComplete: '',
      },
      accessAndEnrollment: {
        enrollmentType: '',
        startDate: '',
        endDate: '',
        startTime: '',
        endTime: '',
        maximumEnrollment: 0,
        accessPermissions: '',
        certificateAvailability: false,
        certificateRequirements: '',
      },
      progressTracking: {
        progressIndicators: false,
        enforceSequentialLearningPath: false,
        assessmentMethods: false,
        gradingPolicy: false,
        completionCriteria: false,
      },
    },
  );
  const [checked, setChecked] = useState(courseDets.paid);
  const [productPrice, setProductPrice] = useState(courseDets.productPrice);
  const [discount, setDiscount] = useState(courseDets.discount);
  const [finalPrice, setFinalPrice] = useState(courseDets.finalPrice);
  // const [image, setImage] = useState(null);
  const [isPaidCourse, setIsPaidCourse] = useState(false);
  const [actualAmount, setActualAmount] = useState('');
  const [discountAmount, setDiscountAmount] = useState('');
  const [discountedAmount, setDiscountedAmount] = useState('');
  const [taxes, setTaxes] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [link, setLink] = useState('');
  const [fileData, setFileData] = useState(null);
  const [canBeEdited, setCanBeEdited] = useState(false);
  // const { users } = useSelector((state) => state.auth);

  const handlePriceSwitchChange = () => {
    setCourseDets((prevState) => ({
      ...prevState,
      isPaidCourse: !prevState.isPaidCourse,
    }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInstructorChange = (event, value) => {
    setCourseDets((prevCourseDets) => ({
      ...prevCourseDets,
      generalInformation: {
        ...prevCourseDets.generalInformation,
        instructorName: value.map((instructor) => ({
          id: instructor._id,
          firstname: instructor.firstname,
          lastname: instructor.lastname,
        })),
      },
    }));
  };

  const handleCheckForEdit = async () => {
    try {
      const response = await WholeCourseSettingApi(courseId);
      console.log(
        'checkings can be edited',
        response.data.data?.course?.canBeEdited,
      );
      setCanBeEdited(response.data.data?.course?.canBeEdited);
    } catch (error) {}
  };

  const handleSwitchChange = () => {
    // setChecked(!checked);

    const { name, checked } = event.target;
    setCourseDets((prevCourseDets) => ({
      ...prevCourseDets,
      progressTracking: {
        ...prevCourseDets.progressTracking,
        [name]: checked,
      },
    }));
  };

  const requestUploadUrl = async (file) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/course/bannerImage`,
        {
          fileName: file?.name,
          fileType: file?.type,
        },
      );
      console.log(response.data.url, 'requestUploadUrl');
      setLink(response.data.url);
      // setFormData((prevData) => ({
      //   ...prevData,
      //   link: response.data.url?.split('?')[0],
      //   uploadUrl: response.data.url,
      // }));
    } catch (error) {
      console.error('Error requesting upload URL:', error);
    }
  };

  const uploadToS3 = async () => {
    console.log('uploadToS3');
    try {
      await axios.put(link, fileData, {
        headers: {
          'Content-Type': fileData?.type,
        },
      });
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const [instructors, setInstructors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedInstructors, setSelectedInstructors] = useState([]);
  const [open, setOpen] = useState(false);

  const handleFetchInstructors = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        'http://localhost:8000/api/getInstructors',
      ); // Adjust the endpoint as needed

      setInstructors(response.data.data);
    } catch (error) {
      console.error('Error fetching instructors:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
    if (instructors.length === 0) {
      handleFetchInstructors();
    }
  };

  useEffect(() => {
    const actualAmountValue = parseFloat(actualAmount) || 0;
    const discountValue = parseFloat(discountAmount) || 0;
    const taxesValue = parseFloat(taxes) || 0;
    const calculatedDiscountedAmount =
      actualAmountValue - (actualAmountValue * discountValue) / 100;
    const calculatedTotalAmount =
      calculatedDiscountedAmount +
      (calculatedDiscountedAmount * taxesValue) / 100;

    setDiscountedAmount(calculatedDiscountedAmount.toFixed(2));
    setTotalAmount(calculatedTotalAmount.toFixed(2));
  }, [actualAmount, discountAmount, taxes]);

  const handleSaveCourse = async () => {
    await uploadToS3();
    console.log('handle saveee ', courseDets);
    const updatedCourseDets = {
      ...courseDets,
      generalInformation: {
        ...courseDets.generalInformation,
        bannerImage: link?.split('?')[0], // Replace with the new image URL or path
      },
    };
    console.log(updatedCourseDets, 'updatedCourseDets');

    const result = await dispatch(updateCourseInfoInSlice(updatedCourseDets));
    console.log(result);
    navigate('/CourseBuilder');
  };

  const config = {
    placeholder: 'Start typing....',
    height: 300,
    readonly: false,
    observer: false,
    shouldWork: true,
  };

  useEffect(() => {
    handleCheckForEdit();
  }, []);

  //Image or Thumbnail
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setCourseDets({ ...courseDets, [name]: value });
  // };

  // const handleImageChange = (event) => {
  //   setImage(URL.createObjectURL(event.target.files[0]));
  // };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFileData(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCourseDets((prevState) => ({
          ...prevState,
          generalInformation: {
            ...prevState.generalInformation,
            bannerImage: reader.result,
          },
        }));
      };
      reader.readAsDataURL(file);
      requestUploadUrl(file);
    }
  };

  const image = courseDets.generalInformation.bannerImage;

  const [certificate, setCertificate] = useState('');
  const [isExam, setIsExam] = useState('');
  const [materials, setMaterials] = useState('');

  const handleCertificateChange = (event) => {
    setCertificate(event.target.value);
  };

  const handleIsExamChange = (event) => {
    setIsExam(event.target.value);
  };
  const handleMaterialChange = (event) => {
    setMaterials(event.target.value);
  };
  // const handleSelectChange = (event) => {
  //   const { name, value } = event.target;
  //   setCourseDets((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    const [category, key] = name.split('.');

    setCourseDets((prevState) => ({
      ...prevState,
      [category]: {
        ...prevState[category],
        [key]: value,
      },
    }));
    console.log(courseDets);
  };
  console.log(courseDets);

  const handleTimeChange = (time, field) => {
    handleSelectChange({ target: { name: field, value: time } });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const [category, field] = name.split('.');

    setCourseDets((prevState) => {
      const updatedPricingDetails = {
        ...prevState.pricingDetails,
        [field]: value,
      };

      // Calculate discounted amount
      if (field === 'actualAmount' || field === 'discount') {
        const actualAmount =
          parseFloat(updatedPricingDetails.actualAmount) || 0;
        const discount = parseFloat(updatedPricingDetails.discount) || 0;
        const discountedAmount = actualAmount - actualAmount * (discount / 100);
        updatedPricingDetails.discountedAmount = discountedAmount.toFixed(2);
      }

      // Calculate total amount
      if (
        field === 'actualAmount' ||
        field === 'discount' ||
        field === 'taxes'
      ) {
        const actualAmount =
          parseFloat(updatedPricingDetails.actualAmount) || 0;
        const discount = parseFloat(updatedPricingDetails.discount) || 0;
        const taxes = parseFloat(updatedPricingDetails.taxes) || 0;
        const discountedAmount = actualAmount - actualAmount * (discount / 100);
        const totalAmount = discountedAmount + discountedAmount * (taxes / 100);
        updatedPricingDetails.totalAmount = totalAmount.toFixed(2);
      }

      return {
        ...prevState,
        pricingDetails: updatedPricingDetails,
      };
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <PageContainer>
        <CreateContent style={{ justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title="Go Back">
              <IconButton
                size="small"
                onClick={() => navigate(-1)}
                sx={{ color: `${palette.THEME_THREE}`, marginleft: '33px' }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Tooltip>
            <h3>Specifications</h3>
          </div>
          <ButtonCont style={{ position: 'fixed', zIndex: 1000, right: 5 }}>
            <CommonButton onClick={handleSaveCourse} label="Save Course" />
          </ButtonCont>
        </CreateContent>
        <Container fluid sx={ContainerStyle}>
          {/* <Grid container  sx={{ mb: 2 }}>
        <h4 >General Information</h4>   
        </Grid> */}
          <Grid container spacing={2} sx={{ mb: 2 }}>
            {/* Title and Description Grid */}
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography>Title</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-basic"
                    placeholder="Enter Course Title"
                    variant="outlined"
                    fullWidth
                    name="generalInformation.title"
                    value={courseDets.generalInformation.title}
                    onChange={handleSelectChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>Description</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-basic"
                    placeholder="Enter Description"
                    variant="outlined"
                    // rows="5"
                    multiline
                    fullWidth
                    name="generalInformation.description"
                    value={courseDets.generalInformation.description}
                    onChange={handleSelectChange}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Upload Thumbnail Grid */}
            <Grid
              item
              xs={6}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                xs={12}
                container
                justifyContent="center"
                alignItems="center"
              >
                <div
                  style={{
                    border: '1px solid #ccc',
                    width: '60%',
                    paddingTop: '50%',
                    position: 'relative',
                    overflow: 'hidden',
                    cursor: 'pointer',
                  }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <input
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleImageChange}
                  />
                  {image ? (
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundImage: `url(${image})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {isHovered && (
                        <div
                          style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            color: '#fff',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                          }}
                        >
                          <CommonButton
                            onClick={() =>
                              document
                                .getElementById('contained-button-file')
                                .click()
                            }
                            label="Change Image"
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    >
                      <CommonButton
                        onClick={() =>
                          document
                            .getElementById('contained-button-file')
                            .click()
                        }
                        label="Upload Image"
                      />
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={6}>
              <Typography>Course Code</Typography>
              <TextField
                id="outlined-basic"
                placeholder="Enter Course Code"
                variant="outlined"
                fullWidth
                name="generalInformation.courseCode"
                value={courseDets.generalInformation.courseCode}
                onChange={handleSelectChange}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>Course Duration</Typography>
              <TextField
                id="outlined-basic"
                placeholder="Enter Course Duration"
                variant="outlined"
                fullWidth
                name="generalInformation.courseDuration"
                value={courseDets.generalInformation.courseDuration}
                onChange={handleSelectChange}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>Course Level</Typography>

              <FormControl variant="outlined" fullWidth>
                <Select
                  id="courseLevel"
                  name="generalInformation.courseLevel"
                  value={courseDets.generalInformation.courseLevel}
                  onChange={handleSelectChange}
                  // label="Course Level *"
                  required
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="Beginner">Beginner</MenuItem>
                  <MenuItem value="Intermediate">Intermediate</MenuItem>
                  <MenuItem value="Advanced">Advanced</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Typography>Course Category </Typography>
              <FormControl variant="outlined" fullWidth>
                <Select
                  id="course-category"
                  name="generalInformation.courseCategory"
                  value={courseDets.generalInformation.courseCategory}
                  onChange={handleSelectChange}
                  required
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="Beginner">Science</MenuItem>
                  <MenuItem value="Intermediate">Management</MenuItem>
                  <MenuItem value="Advanced">Arts</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <Typography>Language* </Typography>
              <FormControl variant="outlined" fullWidth>
                <Select
                  id="language"
                  name="generalInformation.language"
                  value={courseDets.generalInformation.language}
                  onChange={handleSelectChange}
                  required
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="Beginner">English</MenuItem>
                  <MenuItem value="Intermediate">Spanish</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Typography>Course Prerequisite </Typography>
              <TextField
                id="outlined-basic"
                placeholder="Mention Course Prerequisite"
                fullWidth
                name="generalInformation.prerequisite"
                value={courseDets.generalInformation.prerequisite}
                onChange={handleSelectChange}
                required
              />
            </Grid>

            <Grid item xs={6} sx={{ mb: 2 }}>
              <Typography>Instructor Name</Typography>

              <FormControl fullWidth style={{ marginTop: '0px' }}>
                <Autocomplete
                  multiple
                  open={open}
                  onOpen={handleOpen}
                  onClose={handleClose}
                  options={instructors}
                  getOptionLabel={(option) =>
                    `${option?.firstname} ${option?.lastname}`
                  }
                  value={courseDets.generalInformation.instructorName.map(
                    (name) => ({
                      _id: name.id,
                      firstname: name.firstname,
                      lastname: name.lastname,
                    }),
                  )}
                  onChange={handleInstructorChange}
                  loading={loading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // label="Select Instructor"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            {canBeEdited && (
              <Grid item xs={6}>
                <Typography>Course Structure </Typography>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    id="structure"
                    onChange={handleSelectChange}
                    name="generalInformation.courseStructure"
                    value={courseDets.generalInformation.courseStructure}
                    required
                  >
                    <MenuItem value="CMLT">CMLT </MenuItem>
                    <MenuItem value="CLT">CLT</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12}>
              <Grid container sx={{ mb: 2 }}>
                <h3>Pricing Detail</h3>
              </Grid>
              <Switch
                checked={courseDets.isPaidCourse}
                onChange={handlePriceSwitchChange}
              />
              <Typography display="inline"> This is a paid course</Typography>

              {courseDets.isPaidCourse && (
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={6}>
                    <Typography>Actual Amount *</Typography>
                    <TextField
                      name="pricingDetails.actualAmount"
                      placeholder="Enter Actual Amount"
                      variant="outlined"
                      fullWidth
                      value={courseDets.pricingDetails.actualAmount}
                      onChange={handleInputChange}
                      required
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Discount (%) *</Typography>
                    <TextField
                      name="pricingDetails.discount"
                      placeholder="Enter Discount Percentage"
                      variant="outlined"
                      fullWidth
                      value={courseDets.pricingDetails.discount}
                      onChange={handleInputChange}
                      required
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Discounted Amount</Typography>
                    <TextField
                      name="pricingDetails.discountedAmount"
                      placeholder="Discounted Amount"
                      variant="outlined"
                      fullWidth
                      value={courseDets.pricingDetails.discountedAmount}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Taxes (%) *</Typography>
                    <TextField
                      name="pricingDetails.taxes"
                      placeholder="Enter Taxes Percentage"
                      variant="outlined"
                      fullWidth
                      value={courseDets.pricingDetails.taxes}
                      onChange={handleInputChange}
                      required
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Total Amount</Typography>
                    <TextField
                      name="pricingDetails.totalAmount"
                      placeholder="Total Amount"
                      variant="outlined"
                      fullWidth
                      value={courseDets.pricingDetails.totalAmount}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container sx={{ mb: 2 }}>
            <h3>Content Management</h3>
          </Grid>
          {/* Start From Here */}
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <Typography>Learning Objectives</Typography>
            </Grid>
            <Grid item xs={12}>
              {/* <TextField
          id="outlined-basic"
          placeholder="Enter Objectives"
          variant="outlined"
          rows="5"
          multiline
          fullWidth
          value={courseDets.objectives}
        /> */}
              <JoditReact
                value={courseDets.contentManagement.learningObjectives}
                // onChange={handleSelectChange}
                config={{
                  style: {
                    // backgroundColor: `${palette.bgColor3}`
                  },
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography>Estimated Time </Typography>
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                name="contentManagement.estimatedTimeToComplete"
                value={courseDets.contentManagement.estimatedTimeToComplete}
                onChange={handleSelectChange}
                required
              />
            </Grid>
          </Grid>
          <Grid container sx={{ mb: 2 }}>
            <h3>Access and Enrollment</h3>
          </Grid>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={6}>
              <Typography>Enrollment Type </Typography>
              <FormControl variant="outlined" fullWidth>
                <Select
                  id="enrollType"
                  name="accessAndEnrollment.enrollmentType"
                  value={courseDets.accessAndEnrollment.enrollmentType}
                  onChange={handleSelectChange}
                  required
                >
                  <MenuItem value="None">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="Open">Open</MenuItem>
                  <MenuItem value="Restricted">Restricted</MenuItem>
                  <MenuItem value="Invitation">By Invitation</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <Typography>Maximum Enrollment</Typography>
              <TextField
                id="allotment-number"
                placeholder="Enter Allotment Number"
                variant="outlined"
                type="number"
                fullWidth
                name="accessAndEnrollment.maxEnrollment"
                value={courseDets.accessAndEnrollment.maxEnrollment}
                onChange={handleSelectChange}
                required
              />
            </Grid>

            <Grid item xs={6}>
              <Typography>Start Date </Typography>
              <TextField
                id="outlined-basic"
                placeholder="Enter Course Title"
                variant="outlined"
                type="date"
                fullWidth
                name="accessAndEnrollment.startDate"
                value={courseDets.accessAndEnrollment.startDate}
                onChange={handleSelectChange}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>End Date</Typography>
              <TextField
                id="outlined-basic"
                placeholder="Enter Course Title"
                variant="outlined"
                type="date"
                fullWidth
                name="accessAndEnrollment.endDate"
                value={courseDets.accessAndEnrollment.endDate}
                onChange={handleSelectChange}
                required
              />
            </Grid>

            <Grid item xs={6}>
              <Typography>Start Time </Typography>
              <TimePicker
                value={courseDets.accessAndEnrollment.startTime}
                onChange={(time) =>
                  handleTimeChange(time, 'accessAndEnrollment.startTime')
                }
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    required
                    error={false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>End Time</Typography>
              <TimePicker
                value={courseDets.accessAndEnrollment.endTime}
                onChange={(time) =>
                  handleTimeChange(time, 'accessAndEnrollment.endTime')
                }
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    required
                    error={false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} sx={{ mb: 2 }}>
              <Typography>Access Permission </Typography>
              <FormControl variant="outlined" fullWidth>
                <Select
                  id="Permission"
                  name="accessAndEnrollment.accessPermissions"
                  value={courseDets.accessAndEnrollment.accessPermissions}
                  onChange={handleSelectChange}
                  required
                >
                  <MenuItem value="Public">Public</MenuItem>
                  <MenuItem value="Private">Private</MenuItem>
                  <MenuItem value="Institution-specific">
                    Institution-specific
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Typography>Certificate</Typography>
              <FormControl variant="outlined" fullWidth>
                <Select
                  id="Certificate"
                  name="accessAndEnrollment.certificateAvailability"
                  value={courseDets.accessAndEnrollment.certificateAvailability}
                  onChange={handleSelectChange}
                  required
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="true">Available</MenuItem>
                  <MenuItem value="false">Not Available</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Container>
      </PageContainer>
    </LocalizationProvider>
  );
};

export default CourseDetails;
