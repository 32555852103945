import React, { useState } from 'react';
import {
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddTopicContainer from './AddTopicContainer';
import CopyTopicContainer from './CopyTopicContainer';
import { styled } from '@mui/system';

const MainContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '55px',
  width: '100%',
});

const AddOrCopyTopic = ({ open, onClose, onContentClick }) => {
  const [optionSelected, setOptionSelected] = useState('add');
  const [displayLibrary, setDisplayLibrary] = useState({
    video: false,
    audio: false,
    text: false,
    pdf: false,
    download: false,
    presentation: false,
    multimedia: false,
    live: false,
    codingTest: false,
    scorm: false,
    quiz: false,
    assignment: false,
    exam: false,
    survey: false,
    Attendify: false,
  });

  const handleOptionSelect = (event) => {
    console.log('Handle option select checking ', event.target.value);
    setOptionSelected(event.target.value);
  };

  const handleAddContainerClose = (item) => {
    setOptionSelected('');
    onClose(item);
  };

  const handleOnContentClick = (item) => {
    console.log('checking from parent', item);

    onContentClick(item);
  };

  const handleCopyContainerClose = () => {
    setOptionSelected('');
    onClose();
  };

  const handleSelectTopic = (data) => {
    console.log('handleSelectTopic ', data);
  };

  const handleclickCancel = () => {
    onClose(); // Close the CreateModule component
  };

  return (
    <div>
      <MainContainer>
        <FormControl sx={{ margin: '20px' }}>
          <RadioGroup
            name="selectOption"
            value={optionSelected}
            onChange={handleOptionSelect}
            style={{ flexDirection: 'row', margin: '10px' }} // Align radio buttons horizontally
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <FormControlLabel
                  value="add"
                  control={<Radio />}
                  label="Add Topic"
                  style={{ marginRight: '30px' }} // Add some space between the radio buttons
                />
                <FormControlLabel
                  value="copy"
                  control={<Radio />}
                  label="Copy Topic"
                  labelPlacement="end"
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>

        <Tooltip title="Close">
          <IconButton onClick={handleclickCancel} style={{ cursor: 'pointer' }}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </MainContainer>

      {optionSelected === 'add' && (
        <AddTopicContainer
          open={optionSelected === 'add'}
          onClose={handleAddContainerClose}
          onContentClick={handleOnContentClick}
        />
      )}
      {optionSelected === 'copy' && (
        <CopyTopicContainer
          open={optionSelected === 'copy'}
          onClose={handleCopyContainerClose}
        />
      )}
    </div>
  );
};
export default AddOrCopyTopic;
