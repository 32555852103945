import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import JoditEditor from 'jodit-react';
import TextField from '@mui/material/TextField';
import CommonButton from '../Utilities/CommonButton';
import BackButton from '../Utilities/BackButton';
import { useDispatch } from 'react-redux';

import {
  updateNotification,
  fetchSingleTemplate,
} from '../../features/notification/NotificationSlice';

import {
  CertificateForm,
  JoditEditorDiv,
  CertificateHeading,
  ArrowDiv,
  ButtonDiv,
  EditNotificationHeading
} from '../../styles/Certificate/CertificateStyle';

import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
const EditNotification = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [notificationType, setNotificationType] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useDispatch();

  const config = useMemo(
    () => ({
      height: 400,
      placeholder: '',
      readonly: false,
    }),
    [],
  );

  useEffect(() => {
    const fetchTemplateContent = async () => {
      try {
        const response = await dispatch(fetchSingleTemplate(id));
        const template = response.payload[0];
        setNotificationType(template.notificationType);
        setSubject(template.subject);
        setBody(template.notificationBody);
      } catch (error) {
        console.error('Error fetching certificate content:', error);
      }
    };
    fetchTemplateContent();
  }, [dispatch, id]);

  const onChange = (newContent) => {
    setBody(newContent);
  };

  const updateTemplate = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    try {
      const updatedData = {
        notificationType,
        subject,
        notificationBody: body,
      };
      const response = await dispatch(updateNotification({ id, updatedData }));
      if (response.payload.statusCode === 200) {
        toast.success('Template Updated successfully!');
        navigate('/Notification/listnotification');
      } else {
        toast.error('Failed to update template.');
      }
    } catch (e) {
      console.log(e);
      window.toast.error('An error occurred while updating the template.');
    }
    finally {
      setIsSaving(false); // Reset saving state after the update operation
    }
  };

  const { t } = useTranslation();

  return (
    <>
     <ArrowDiv>
      <EditNotificationHeading>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <BackButton />
          <CertificateHeading>{t('notification.editLabel')} {notificationType}</CertificateHeading>
        </div>
        <ButtonDiv>
           <CommonButton
                label={isSaving ? t('notification.savingLabel') : t('notification.saveChangeLabel')}
                onClick={updateTemplate}
                disabled={isSaving} 
              />
        </ButtonDiv>
      </EditNotificationHeading>
    </ArrowDiv>
    <CertificateForm>
        <div>
          <TextField
            disabled
            label={t('notification.subjectLabel')}
            variant="outlined"
            margin="normal"
            fullWidth
            value={subject}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <JoditEditorDiv>
            <JoditEditor value={body} onChange={onChange} config={config} />
          </JoditEditorDiv>
        </div>
      </CertificateForm>
    </>
  );
};

export default EditNotification;
