import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@mui/material/TextField';
import TableUtilityLib from '../Utilities/TableUtilityLib';
import CommonButton from '../Utilities/CommonButton';
import { UserTable } from '../../styles/Users/UsersStyles';
import {
  WholePage,
  TableButtonDiv,
  SeparateTableButton,
  SearchEmail,
} from '../../styles/Certificate/CertificateStyle';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from '../../features/userAdministration/userAdministrationSlice';

const Instructors = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const learnersData = UserData.users.filter(
    (user) => user.role === 'instructor',
  );
  const [searchQuery, setSearchQuery] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const fileInputRef = useRef(null);
  const { users, status } = useSelector((state) => state.users);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleRowClick = (row) => {
    navigate(`/users/UserDetails/${row.id}`);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleImportButtonClick = () => {
    // Trigger file input click when "Import" button is clicked
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log('Selected file:', file);
    // Handle file upload logic here
  };
  useEffect(() => {
    if (!users) {
      dispatch(fetchUser());
    }
  }, []);

  const filteredData = Array.from(users).filter((user) => {
    return (
      user.role === 'Instructor' &&
      (user?.firstname?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
        user?.lastname?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
        user?.email?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
        user?.role?.toLowerCase().includes(searchQuery?.toLowerCase()))
    );
  });

  const columns = [
    { name: 'Firstname', selector: (row) => row.firstname, sortable: true },
    { name: 'Lastname', selector: (row) => row.lastname, sortable: true },
    { name: 'Role', selector: (row) => row.role, sortable: true },
    { name: 'Email', selector: (row) => row.email, sortable: true },
  ];

  const handleFormSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <WholePage>
        <br />
        <SearchEmail onSubmit={handleFormSubmit}>
          <TextField
            label="Search"
            size="small"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ width: '300px', marginBottom: '16px', marginLeft: '44px' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <TableButtonDiv>
            <SeparateTableButton>
              <CommonButton label="Add" onClick={handleOpenDialog} />
            </SeparateTableButton>

            <SeparateTableButton>
              {/* Hidden file input triggered by Import button */}
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
              />
              <CommonButton label="Import" onClick={handleImportButtonClick} />
            </SeparateTableButton>

            <SeparateTableButton>
              <CommonButton label="Export" />
            </SeparateTableButton>
          </TableButtonDiv>
        </SearchEmail>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Add User</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Email"
                variant="outlined"
                margin="normal"
                fullWidth
              />
              <TextField
                label="Mobile Number"
                variant="outlined"
                margin="normal"
                fullWidth
              />
              <TextField
                label="Password"
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CommonButton label="Add" onClick={handleCloseDialog} />
            <CommonButton label="Cancel" onClick={handleCloseDialog} />
          </DialogActions>
        </Dialog>

        <UserTable>
          <TableUtilityLib
            columns={columns}
            data={filteredData}
            onRowClicked={handleRowClick}
          />
        </UserTable>
      </WholePage>
    </>
  );
};

export default Instructors;
