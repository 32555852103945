import React, { useState, useEffect } from 'react';
import { Calendar } from 'react-multi-date-picker';
import { DateObject } from 'react-multi-date-picker';
import CloseIcon from '@mui/icons-material/Close';
import EventPopup from './EventPopup';
import { useSelector } from 'react-redux';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import {
  AddDiv,
  CloseIconStyle,
  DialogBoxTitle,
  CalendarContainer,
} from '../../styles/Calendar/CalendarStyle';
import '../../styles/Calendar/Calendar.css';
import { Tooltip } from '@mui/material';
import { palette } from '../../styles/palette';
import { useTranslation } from 'react-i18next';
import { DisplayText } from '../../styles/Calendar/CalendarStyle';
import Footer from '../Landing/Footer';

const CalendarCompSub = ({ eventsData }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [monthsToShow, setMonthsToShow] = useState(1);
  const { data } = useSelector((state) => state.calendar);

  useEffect(() => {
    const calculateMonthsToShow = (width) => {
      if (width < 768) {
        return 1; // Mobile
      } else if (width >= 768 && width < 1024) {
        return 3; // Tablet
      } else {
        return 4; // Laptop and larger
      }
    };

    const handleResize = () => {
      setMonthsToShow(calculateMonthsToShow(window.innerWidth));
    };

    // Set initial value
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date.format('YYYY-MM-DD'));
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const highlightedDates = data
    ? data.map((ele) =>
        new DateObject(new Date(ele.SDate)).format('YYYY-MM-DD'),
      )
    : [];

  const mapDays = ({ date }) => {
    let dateString = date.format('YYYY-MM-DD'); // Convert the current date to string
    let isHighlighted = highlightedDates.includes(dateString);

    if (isHighlighted) {
      return {
        style: {
          backgroundColor: palette.buttonHover,
          color: palette.bgColor1,
        },
      };
    }
  };

  const { t } = useTranslation();

  return (
    <CalendarContainer>
      <div className="">
        <DisplayText className="text-xl">
          {t('calender.exploreText')}
        </DisplayText>
      </div>
      <Calendar
        style={{ width: '96%' }} // Full width to cover container
        numberOfMonths={monthsToShow}
        onChange={handleDateChange}
        format="YYYY-MM-DD"
        mapDays={mapDays}
        className="calendar-class"
        weekDays={['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA']}
      />

      <Dialog open={openDialog} maxWidth="md">
        <AddDiv>
          <DialogBoxTitle>
            <DialogTitle sx={{ fontWeight: '400' }}>
              {t('calender.eventScheduledText')}
            </DialogTitle>
          </DialogBoxTitle>

          <Tooltip title={t('calender.closeLabel')}>
            <CloseIcon onClick={handleClose} sx={CloseIconStyle} />
          </Tooltip>
        </AddDiv>
        <DialogContent>
          <EventPopup date={selectedDate} />
        </DialogContent>
      </Dialog>
      {/* <Footer /> */}
    </CalendarContainer>
  );
};

CalendarCompSub.defaultProps = {
  eventsData: [],
};

export default CalendarCompSub;
