import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import CourseSettingTabs from '../CourseSettings/CourseSettingTabs';
import BasicSettings from './BasicSettings';
import PlayerAppearanceSettings from './CoursePlayerAppearanceSetting';
import CourseCertificateSettings from './CourseCertificateSettings';
import { WholeCourseSetting } from '../../../features/courses/CourseUtilitySlice';
import CourseNotificationSettings from './CourseNotificationSettings';

const CourseSettings = () => {
  const navigate = useNavigate();
  const { courseId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(WholeCourseSetting(courseId));
    console.log('basic', wholeCourseSetting);
  }, [dispatch]);

  const { wholeCourseSetting } = useSelector((state) => state.CourseUtility);

  const [selectedTab, setSelectedTab] = useState(
    `/CourseBuilder/CourseSettings/${courseId}`,
    `/CourseBuilder/CourseNotificationSettings/${courseId}`,
    `/CourseCertificateSettings/${courseId}`,
  );

  return (
    <div>
      <Grid container alignItems="center" sx={{ ml: '5px', mt: '40px' }}></Grid>
      <div>
        <CourseSettingTabs
          courseId={courseId}
          selectedTab={selectedTab}
          // showCertificateTab={certificateAvailability}
        />
        {selectedTab ===
          `/CourseBuilder/CourseNotificationSettings/${courseId}` && (
          <CourseNotificationSettings />
        )}
        {selectedTab === `/CourseBuilder/CourseSettings/${courseId}` && (
          <BasicSettings />
        )}

        {selectedTab === `/CourseCertificateSettings/${courseId}` && (
          <CourseCertificateSettings />
        )}
      </div>
    </div>
  );
};

export default CourseSettings;
