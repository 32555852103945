import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Tooltip, IconButton, Grid, Typography, Button } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { palette } from '../../../styles/palette';
import JoditEditor from 'jodit-react';
import { useDispatch } from 'react-redux';
import { addItem } from '../../../features/courses/addCourseDetailsSlice';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import {
  DeleteBox,
  ButtonContainer,
  BackButtonContainer,
  HeaderContainer,
} from '../../../styles/Courses/CourseModuleStyles';

const DiscardButton = styled(Button)`
  color: ${palette.primary};
  border-color: ${palette.gray};
  margin-right: 10px;
  width: 180px;
`;

const SaveButton = styled(Button)`
  background-color: ${palette.primary};
  color: ${palette.white};
`;

const EditorContainer = styled('div')`
  margin-right: 10px;
  margin-left: 10px;
`;

const Attendify = ({
  onClose,
  lessonId,
  selectedItem,
  moduleId,
  handleDeleteTopic,
}) => {
  const { id, courseStructure } = useParams();
  const editor = useRef(null);

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    moduleId: '',
    lessonId: '',
    title: '',
    textDescription: `
      <p className="text-gray-600 mb-2">
        1. Don't have an ID card? Download it from the profile section.
      </p>
      <p className="text-gray-600 mb-2">
        2. Validate your identity to access the virtual session.
      </p>
      <p className="text-gray-600 mb-2">
        3. Please ensure that your ID card is clearly visible in front of
        the webcam.
      </p>
      <p className="text-gray-600 mb-4">
        4. Ensure good lighting and that the entire ID card fits within
        the camera frame.
      </p>
      <p className="text-gray-600 mb-4 font-bold italic">
        Click the button below to start the scanning process.
      </p>
      <button style="background-color: #007bff; margin-left:250px; color: white; border: none; padding: 10px 20px; cursor: pointer; border-radius: 5px;">
      Verify Your Identity
    </button>
    `,
    id: '',
    courseStructure: '',
    contentType: selectedItem?.contentType || '',
  });

  const handleEditorChange = (newContent) => {
    setFormData({ ...formData, textDescription: newContent });
  };

  const config = useMemo(
    () => ({
      placeholder: 'Enter the text description',
      height: 400, // Increased height
      readonly: false,
    }),
    [],
  );

  const handleOnClose = () => {
    onClose();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
      lessonId: lessonId,
      moduleId: moduleId,
      id: generateRandomNumber(),
      courseStructure: courseStructure,
      contentType: 'Text',
    });
  };

  useEffect(() => {
    if (editor.current) {
      editor.current.value = formData.textDescription;
    }
  }, [formData.textDescription]);

  const generateRandomNumber = () => {
    const min = 100000;
    const max = 999999;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const handleDelete = () => {
    handleDeleteTopic(
      selectedItem?.moduleId,
      selectedItem?.lessonId,
      selectedItem?.id,
    );
  };

  const handleSave = async () => {
    await dispatch(addItem(formData));
    onClose();
  };

  return (
    <div>
      <HeaderContainer>
        <BackButtonContainer
          container
          alignItems="center"
          spacing={1}
          sx={{ mt: 1, mb: 2 }}
        >
          <Grid item>
            <Tooltip title="Go Back">
              <IconButton
                size="small"
                onClick={handleOnClose}
                sx={{ color: `${palette.THEME_THREE}` }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item sx={{ mt: 1 }}>
            <Typography variant="h5" gutterBottom>
              Verify your Identity
            </Typography>
          </Grid>
        </BackButtonContainer>
        <ButtonContainer>
          <DiscardButton variant="outlined">Discard Changes</DiscardButton>
          <SaveButton variant="contained" onClick={handleSave}>
            Save
          </SaveButton>
        </ButtonContainer>
      </HeaderContainer>
      <EditorContainer>
        <JoditEditor
          ref={editor}
          value={formData.textDescription}
          onChange={handleEditorChange}
          config={config}
        />
      </EditorContainer>
      <DeleteBox>
        <h4 style={{ marginRight: '10px' }}>
          Are you sure you want to delete this topic? This action is
          irreversible.
        </h4>
        <Button variant="contained" color="error" onClick={handleDelete}>
          Delete Topic
        </Button>
      </DeleteBox>
    </div>
  );
};

export default Attendify;
