import React, { useEffect, useState } from 'react';
import {
  NavbarContainer,
  HeaderContainer,
  NavText,
  NavHeading,
} from '../../styles/Landing/NavbarElements';
import { Content } from '../../styles/Common/CommonStyles';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const { t } = useTranslation();
  const navbarContent = [
    {
      pathname: '/Dashboard',
      heading: 'Dashboard',
      description:
        'Navigate Your Success: Real-Time Analytics at Your Fingertips',
    },
    {
      pathname: '/CourseBuilder',
      heading: 'Course Builder',
      description: 'Craft Your Curriculum: Build Courses with Ease',
    },
    {
      pathname: '/CourseBuilder/CourseModules',
      heading: 'Course Modules',
      description: 'Craft Your Curriculum: Build Courses with Ease',
    },
    {
      pathname: '/CourseBuilder/CoursePreview',
      heading: 'Course Preview',
      description: 'Craft Your Curriculum: Build Courses with Ease',
    },
    {
      pathname: '/EventsNavigator',
      heading: 'Event Navigator',
      description: 'Explore and Engage: Navigate Events Effortlessly.',
    },
    {
      pathname: '/Certificate',
      heading: 'Certificate',
      description: 'From Course to Credential: Certify Your Journey.',
    },
    {
      pathname: '/Notification',
      heading: 'Notifications',
      description: 'Stay Informed: Real-Time Alerts at Your Service.',
    },
    {
      pathname: '/Modules',
      heading: 'Modules',
      description: 'Modules planned in the product are as below.',
    },
    {
      pathname: '/Users',
      heading: 'Users',
      description: 'Empower and Organize: Your User Management Hub',
    },
    {
      pathname: '/Sitebuilder',
      heading: 'Sitebuilder',
      description: 'Create and Innovate: Your Web Presence, Your Way.',
    },
  ];

  const matchingItem = navbarContent.find((item) =>
    location.pathname.startsWith(item.pathname),
  );

  return (
    <NavbarContainer>
      <HeaderContainer>
        <Content>
          {/* <NavHeading>
            {
              navbarContent.filter(
                (item) => item.pathname === location.pathname,
              )[0]?.heading
            }
          </NavHeading>
          <NavText>
            {
              navbarContent.filter(
                (item) => item.pathname === location.pathname,
              )[0]?.description
            }
          </NavText> */}
          <NavHeading>{matchingItem?.heading}</NavHeading>
          <NavText>{matchingItem?.description}</NavText>
        </Content>
      </HeaderContainer>
      {/* {!userProfile?.userLoggedIn && (
        <ImageContainer>
          <Image
            src={require("../../images/logo.png")}
            onClick={handleOnLogoClick}
          />
        </ImageContainer>
      )} */}
      {/* <Bars />
      <ProfileContainer>
        <h3>{userProfile.userName}</h3>
        <ProfileHead onClick={() => setDisplayProfile(!displayProfile)}>
          {userProfile?.hasOwnProperty("userImgUrl") &&
          userProfile?.userImgUrl !== "" ? (
            <ProfilePic src={require("../../images/user.png")} />
          ) : (
            <ProfilePic src={require("../../images/women.png")} />
            <ProfilePic src={require("../../images/user.png")} />
          )}
          <Initial>{userProfile?.userName}</Initial>
        </ProfileHead>

        {!displayProfile && (
          <FaAngleDown
            color="#FFF"
            fontSize="x-large"
            style={faCustomStyle}
            onClick={() => setDisplayProfile(!displayProfile)}
          />
        )}
        {displayProfile && (
          <FaAngleUp
            color="#FFF"
            fontSize="x-large"
            style={faCustomStyle}
            onClick={() => setDisplayProfile(!displayProfile)}
          />
        )}
      </ProfileContainer>
      {displayProfile && (
        <Container1>
          <ProfileLink
            to="/MyProfile"
            onClick={() => setDisplayProfile(!displayProfile)}
          >
            My Profile
          </ProfileLink>
          <ProfileLink to="/" onClick={logout}>
            Logout
          </ProfileLink>
        </Container1>
      )} */}
    </NavbarContainer>
  );
};

export default Navbar;
