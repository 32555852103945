import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  Paper,
  Collapse,
  Box,
  Typography,
  ListItemIcon,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { palette } from '../../styles/palette';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveDetails,
  fetchCourseDetailsInSlice,
} from '../../features/courses/addCourseDetailsSlice';

import PreviewModuleOrLesson from './LearnerCourseBuilder/PreviewModuleOrLesson';

import ContentPlayer from './ContentPlayer';
// import CreateTopicText from './LearnerPreviewTopic/CreateTopicText';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import AddIcon from '@mui/icons-material/Add';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import VolumeMuteOutlinedIcon from '@mui/icons-material/VolumeMuteOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import MovieOutlinedIcon from '@mui/icons-material/MovieOutlined';
import MmsOutlinedIcon from '@mui/icons-material/MmsOutlined';
import SettingsInputAntennaOutlinedIcon from '@mui/icons-material/SettingsInputAntennaOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import PollOutlinedIcon from '@mui/icons-material/PollOutlined';
import { styled } from '@mui/system';
import {
  Container,
  Sidebar,
  Header,
  Divider,
  ContentWrapper,
  EmptyStateBox,
} from '../../styles/Courses/CoursePreviewStyles';
import ViewModuleIcon from '@mui/icons-material/ViewModule'; //module icon
import TopicIcon from '@mui/icons-material/Topic';
const StyledList = styled(List)`
  height: 70%;
  overflow-y: auto;
`;

const StyledListItem = styled(ListItem)`
  background: ${palette.white};
  margin-top: 10px;
`;

const CollapseList = styled(List)`
  padding: 20px;
  margin-bottom: 10px;
`;

const NestedListItem = styled(ListItem)`
  background: ${palette.white};
  margin-bottom: 10px;
  border: 1px solid ${palette.gray};
  border-radius: 5px;
`;

const PreviewModule = ({ topicId }) => {
  // Receive topicId as a prop
  const navigate = useNavigate();
  const location = useLocation();
  const { courseTitle } = location.state || {};
  const dispatch = useDispatch();
  const [expandedModule, setExpandedModule] = useState(null);
  const [expandedLessons, setExpandedLessons] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedPreviewItem, setSelectedPreviewItem] = useState(null);
  const { modules, lessons } =
    useSelector((state) => state.addCourseDetails.courseDetails) || {};
  const { id, courseStructure } = useParams();
  console.log('abc topic iD', topicId);
  console.log('abc modules', modules);
  const [targetModule, setTargetModule] = useState(null);
  const [targetLesson, setTargetLesson] = useState(null);
  const [targetTopic, setTargetTopic] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentLesson, setCurrentLesson] = useState(0);
  const [currentModule, setCurrentModule] = useState(0);

  useEffect(() => {
    if (courseStructure==='CMLT'&&modules && modules.length > 0) {
      console.log('entered into the searching modules abc');

      // Find the module or lesson that matches the topicId
      setTargetModule(modules.find((module) => module.id === topicId));
      console.log('done with the search abc');
      console.log('abc  targetModule', targetModule);

      if (!targetModule) {
        // If topicId is a lessonId or topicId, search inside the modules
        modules.some((module) => {
          return module.lessons?.some((lesson) => {
            const topic = lesson.items?.find((topic) => topic.id === topicId);
            if (topic) {
              setTargetModule(module);
              setTargetLesson(lesson);
              setTargetTopic(topic);

              console.log('abc target lesson', targetLesson);
              console.log('abc target topic', targetTopic);
              return true; // Stops the loop once found
            }
            return false; // Continue the loop if not found
          });
        });
        setExpandedModule(modules[0].id);
        setSelectedPreviewItem(modules[0]);
      }
    }

    
  }, [modules, topicId]); // Update when modules or topicId changes

  useEffect(() => {
    console.log('abc  targetModule outside', targetModule);
    console.log('abc target lesson outside', targetLesson);
    console.log('abc target topic outside', targetTopic);
    if (targetModule) {
      setExpandedModule(targetModule.id);
      setSelectedPreviewItem(targetModule);
    }

    if (targetLesson) {
      setExpandedLessons({ ...expandedLessons, [targetLesson.id]: true });
      setSelectedPreviewItem(targetLesson);
    }

    if (targetTopic) {
      setSelectedPreviewItem(targetTopic);
      setSelectedItem(targetTopic);
    }
  }, [targetLesson, targetModule, targetTopic]);

  const handleModuleOnClick = (moduleId, index) => {
    setCurrentModule(index);
    setExpandedModule(expandedModule === moduleId ? null : moduleId);
  };

  const handleNext = () => {
    const currentLessonIndex = currentLesson; // Index of the current lesson
    const currentModuleIndex = currentModule; // Index of the current module

    // Get the current lesson's items
    const currentLessonItems =
      modules[currentModuleIndex]?.lessons[currentLessonIndex]?.items || [];

    // Check if there's a next item in the current lesson
    if (currentIndex + 1 < currentLessonItems.length) {
      // Move to the next item in the same lesson
      const nextItem = currentLessonItems[currentIndex + 1];
      handleItemClick(nextItem, currentIndex + 1, currentLesson);
    } else {
      // Reset to the first item of the current lesson
      setCurrentIndex(0);

      // Move to the next lesson
      if (currentLessonIndex + 1 < modules[currentModuleIndex].lessons.length) {
        // Set to the first item of the next lesson
        setCurrentLesson(currentLessonIndex + 1);
        const nextLessonItems =
          modules[currentModuleIndex].lessons[currentLessonIndex + 1]?.items ||
          [];
        if (nextLessonItems.length > 0) {
          handleItemClick(nextLessonItems[0], 0, currentLessonIndex + 1); // Select the first item of the next lesson
        }
      } else {
        // If at the last lesson, move to the next module
        if (currentModuleIndex + 1 < modules.length) {
          setCurrentModule(currentModuleIndex + 1); // Move to the next module
          setCurrentLesson(0); // Reset to the first lesson of the new module
          const firstLessonItems =
            modules[currentModuleIndex + 1]?.lessons[0]?.items || [];
          if (firstLessonItems.length > 0) {
            handleItemClick(firstLessonItems[0], 0, 0); // Select the first item of the first lesson of the new module
          }
        }
      }
    }
  };

  const handlePrevious = () => {
    const currentLessonIndex = currentLesson; // Index of the current lesson
    const currentModuleIndex = currentModule; // Index of the current module

    // Get the current lesson's items
    const currentLessonItems =
      modules[currentModuleIndex]?.lessons[currentLessonIndex]?.items || [];

    // If the current index is greater than 0, navigate to the previous item in the current lesson
    if (currentIndex > 0) {
      const prevItem = currentLessonItems[currentIndex - 1];
      handleItemClick(prevItem, currentIndex - 1, currentLesson);
    } else {
      // If at the first item of the current lesson, move to the last item of the previous lesson
      if (currentLessonIndex > 0) {
        const prevLessonIndex = currentLessonIndex - 1;
        const prevLessonItems =
          modules[currentModuleIndex]?.lessons[prevLessonIndex]?.items || [];

        setCurrentLesson(prevLessonIndex); // Move to the previous lesson
        setCurrentIndex(prevLessonItems.length - 1); // Set to the last item of the previous lesson

        if (prevLessonItems.length > 0) {
          const lastPrevItem = prevLessonItems[prevLessonItems.length - 1];
          handleItemClick(
            lastPrevItem,
            prevLessonItems.length - 1,
            prevLessonIndex,
          ); // Select the last item of the previous lesson
        }
      } else {
        // If at the first lesson of the first module, check if there’s a previous module
        if (currentModuleIndex > 0) {
          const prevModuleIndex = currentModuleIndex - 1;
          const lastLessonIndex = modules[prevModuleIndex].lessons.length - 1;
          const lastLessonItems =
            modules[prevModuleIndex]?.lessons[lastLessonIndex]?.items || [];

          setCurrentModule(prevModuleIndex); // Move to the previous module
          setCurrentLesson(lastLessonIndex); // Move to the last lesson of the previous module
          setCurrentIndex(lastLessonItems.length - 1); // Set to the last item of that lesson

          if (lastLessonItems.length > 0) {
            const lastItemOfLastLesson =
              lastLessonItems[lastLessonItems.length - 1];
            handleItemClick(
              lastItemOfLastLesson,
              lastLessonItems.length - 1,
              lastLessonIndex,
            ); // Select the last item
          }
        }
      }
    }
  };

  const handleLessonClick = (lessonId, index) => {
    setCurrentLesson(index);
    setExpandedLessons({
      ...expandedLessons,
      [lessonId]: !expandedLessons[lessonId],
    });
  };

  const handleModuleTextClick = (module, index) => {
    setSelectedPreviewItem(module);
    setSelectedItem(null);
    setCurrentModule(index);
  };

  const handleLessonTextClick = (lesson) => {
    setSelectedPreviewItem(lesson);
    setSelectedItem(null);
    setCurrentLesson(lesson); // Update the currentLesson to the new lesson
    setCurrentIndex(0);
  };

  const handleSaveCourse = async () => {
    const result = await dispatch(
      saveDetails({ modules, courseId: id, lessons }),
    );
    alert(result.payload?.data?.message);
  };

  const handleItemClick = (item, index) => {
    setSelectedItem(item);
    setCurrentIndex(index);
    console.log('abc modulenumber', currentModule);
    console.log('abc lessonnumber', currentLesson);
  };

  const getContentIcon = (contentType) => {
    switch (contentType) {
      // Add the case statements for different content types
      case 'Video':
        return <VideocamOutlinedIcon fontSize="small" />;
      case 'Audio':
        return <VolumeMuteOutlinedIcon fontSize="small" />;
      case 'Text':
        return <TextSnippetOutlinedIcon fontSize="small" />;
      case 'PDF':
        return <PictureAsPdfOutlinedIcon fontSize="small" />;
      case 'Download':
        return <CloudDownloadOutlinedIcon fontSize="small" />;
      case 'Presentation':
        return <MovieOutlinedIcon fontSize="small" />;
      case 'Multimedia':
        return <MmsOutlinedIcon fontSize="small" />;
      case 'Live':
      case 'Scorm':
        return <SettingsInputAntennaOutlinedIcon fontSize="small" />;
      case 'Quiz':
        return <QuizOutlinedIcon fontSize="small" />;
      case 'Assignment':
        return <AssignmentOutlinedIcon fontSize="small" />;
      case 'Exam':
        return <AssessmentOutlinedIcon fontSize="small" />;
      case 'Survey':
      case 'Evaluate':
        return <PollOutlinedIcon fontSize="small" />;
      default:
        return <LibraryBooksOutlinedIcon fontSize="small" />;
    }
  };

  return (
    <Container>
      <Sidebar>
        <Header>
          <Tooltip title="Go Back">
            <IconButton
              size="small"
              onClick={() => navigate('/CourseBuilder')}
              sx={{ color: `${palette.THEME_THREE}` }}
            >
              <ArrowBackIosIcon />
            </IconButton>
          </Tooltip>
          <h4>{courseTitle}</h4>
        </Header>
        <Divider />
        <StyledList>
          {courseStructure === 'CMLT' ? (
            modules?.map((module, index) => (
              <div key={module?.id}>
                <StyledListItem>
                  <ListItemIcon>
                    <ViewModuleIcon style={{ color: `#4f5052` }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={module?.title}
                    onClick={() => handleModuleTextClick(module, index)}
                  />
                  <Box sx={{ marginRight: '8px' }} />
                  {expandedModule === module?.id ? (
                    <ExpandLessIcon
                      onClick={() => handleModuleOnClick(module.id, index)}
                    />
                  ) : (
                    <ExpandMoreIcon
                      onClick={() => handleModuleOnClick(module.id, index)}
                    />
                  )}
                </StyledListItem>
                <Collapse
                  in={expandedModule === module?.id}
                  timeout="auto"
                  unmountOnExit
                >
                  <List
                    component={Paper}
                    style={{
                      padding: '20px',
                      marginBottom: '10px',
                      background: `${palette.lightGray2}`,
                    }}
                  >
                    {module?.lessons?.map((lesson, index) => (
                      <div key={lesson.id}>
                        <NestedListItem>
                          <ListItemIcon>
                            <TopicIcon style={{ color: `#4f5052` }} />
                          </ListItemIcon>
                          <ListItemText
                            sx={{ color: `${palette.secondary}` }}
                            primary={lesson.title}
                            onClick={() => handleLessonTextClick(lesson)}
                          />
                          <Box sx={{ marginRight: '8px' }} />
                          {expandedLessons[lesson.id] ? (
                            <ExpandLessIcon
                              onClick={() =>
                                handleLessonClick(lesson.id, index)
                              }
                            />
                          ) : (
                            <ExpandMoreIcon
                              onClick={() =>
                                handleLessonClick(lesson.id, index)
                              }
                            />
                          )}
                        </NestedListItem>
                        <Collapse
                          in={expandedLessons[lesson.id]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List>
                            {lesson?.items?.map(
                              (item, index) =>
                                item.preview && (
                                  <ListItem
                                    key={index}
                                    style={{
                                      background:
                                        selectedItem === item
                                          ? '#d0eaff'
                                          : '#f9f9f9', // Highlight if selected
                                      marginBottom: '5px',
                                      paddingLeft: '20px',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                      handleItemClick(item, index, lesson)
                                    }
                                  >
                                    <ListItemIcon>
                                      {getContentIcon(item.contentType)}
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={item.title || item.content || ''}
                                    />
                                  </ListItem>
                                ),
                            )}
                          </List>
                        </Collapse>
                      </div>
                    ))}
                  </List>
                </Collapse>
              </div>
            ))
          ) : (
            <CollapseList component={Paper}>
              {lessons?.map((lesson,index) => (
                <div key={lesson.id}>
                  <NestedListItem>
                    <ListItemIcon>
                      <TopicIcon style={{ color: `#4f5052` }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={lesson.title}
                      onClick={() => handleLessonTextClick(lesson)}
                    />
                   
                    <Box sx={{ marginRight: '8px' }} />
                    {expandedLessons[lesson.id] ? (
                      <ExpandLessIcon
                        onClick={() => handleLessonClick(lesson.id,index)}
                      />
                    ) : (
                      <ExpandMoreIcon
                        onClick={() => handleLessonClick(lesson.id,index)}
                      />
                    )}
                  </NestedListItem>
                  <Collapse
                    in={expandedLessons[lesson.id]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List>
                    {lesson?.items?.map(
                              (item, index) =>
                                item.preview && (
                                  <ListItem
                                    key={index}
                                    style={{
                                      background:
                                        selectedItem === item
                                          ? '#d0eaff'
                                          : '#f9f9f9', // Highlight if selected
                                      marginBottom: '5px',
                                      paddingLeft: '20px',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                      handleItemClick(item, index, lesson)
                                    }
                                  >
                                    <ListItemIcon>
                                      {getContentIcon(item.contentType)}
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={item.title || item.content || ''}
                                    />
                                  </ListItem>
                                ),
                            )}
                    </List>
                  </Collapse>
                </div>
              ))}
            </CollapseList>
          )}
        </StyledList>
      </Sidebar>
      <ContentWrapper>
        {!selectedPreviewItem && !selectedItem && (
          <EmptyStateBox>
            <VisibilityIcon
              style={{ fontSize: '56px', marginBottom: '16px' }}
            />
            <Typography variant="h5">
              Explore this course to see what you'll be learning
            </Typography>
          </EmptyStateBox>
        )}
        {/* {selectedPreviewItem ? (
        <PreviewModuleOrLesson
          item={selectedPreviewItem}
          onClose={() => setSelectedPreviewItem(null)}
        />
      ):''}
      {selectedItem && (
        <ContentPlayer item={selectedItem} onClose={() => setSelectedItem(null)} />
      )} */}
        {selectedItem && selectedPreviewItem ? (
          <ContentPlayer
            item={selectedItem}
            onClose={() => setSelectedItem(null)}
            onNext={handleNext}
            onPrevious={handlePrevious}
          />
        ) : selectedPreviewItem ? (
          <PreviewModuleOrLesson
            item={selectedPreviewItem}
            onClose={() => setSelectedPreviewItem(null)}
          />
        ) : (
          selectedItem && (
            <ContentPlayer
              item={selectedItem}
              onClose={() => setSelectedItem(null)}
              onNext={handleNext}
              onPrevious={handlePrevious}
            />
          )
        )}
      </ContentWrapper>
    </Container>
  );
};

export default PreviewModule;
