import { createTheme } from '@mui/material/styles';

// Define your palette
export const palette = {
  primary: '#0684FB',
  secondary: '#036ACB',
  tertiary: '#389DFC',
  footer: '#F7F7F7',
  heading: '#0A0959',
  formBg: '#F6F8FF',
  bgColor1: '#ffffff',
  bgColor2: '#FFFFF0',
  bgColor3: '#F9F8F8',
  newColor: '#f2f6f9',
  gray: '#CCCCCC',
  lightGray2: '#e0e0e0',
  lightGray: '#E6E6E6',
  white: '#ffffff',
  black: '#000000',
  buttonHover: '#6AB6FD',
  success: '#007E33',
  failure: '#CC0000',
  sidebarBg: '#F1F1F1',
  moderate: '#FF8800',
  cardBgColor: '#f3f4f8',
  timeColor: '#979DA3',
  playerButtonBorder: '#E6E6E6',
  shade1: '#0576E1',
  shade2: '#0469C8',
  shade3: '#045CAF',
  shade4: '#034F96',
  shade5: '#03417D',
  shade6: '#023464',
  shade7: '#01274B',
  shade8: '#011A32',
  shade9: '#000D19',
  shade10: '#000000',
  tint1: '#1E90FB',
  tint2: '#379CFB',
  tint3: '#50A8FC',
  tint4: '#69B5FC',
  tint5: '#82C1FD',
  tint6: '#9BCDFD',
  tint7: '#B4DAFD',
  tint8: '#CDE6FE',
  tint9: '#E6F2FE',
  tint10: '#FFFFFF',
};

// Define your theme object (including palette and borderRadius)
export const theme = createTheme({
  palette: {
    primary: {
      main: palette.primary,
    },
    secondary: {
      main: palette.secondary,
    },
    success: {
      main: palette.success,
    },
    error: {
      main: palette.failure,
    },
    background: {
      default: palette.bgColor1,
      paper: palette.bgColor2,
    },
    text: {
      primary: palette.heading,
      secondary: palette.timeColor,
    },
  },
  shape: {
    borderRadius: 25, // Using your themeBorderRadius
  },
});
