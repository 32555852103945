import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import grapesjs from 'grapesjs';
import '../../../styles/Sitebuilder/Sitebuilder.css';
import blocks from '../../../images/blocks.png';
import layer from '../../../images/layers.png';
import edit from '../../../images/edit.png';
import back from '../../../images/back.png';
import settings from '../../../images/settings.png';
import editorConfig from '../../SiteBuilder/GrapesjsConfig';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { palette } from '../../../styles/palette';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Modal, Box, Button, TextField } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import htmlTemplate from './HtmlTemplateCoursePage';

import {
  MainContainer,
  InnerContainer,
  LeftPanel,
  BackButtonCont,
  SubContainer1,
  Container1,
  Container2,
  EditorCont,
  SaveBtn,
  SaveBtnCont,
  BlockTabs,
  HoverText,
} from '../../../styles/Sitebuilder/SitebuilderStyle';
import CoursePageBuilderModal from './CoursePageBuilderModal';

const CoursePageBuilder = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const editorRef = useRef(null);
  const [sidebarButtons, setSidebarButtons] = useState([]);
  const [visibleDiv, setVisibleDiv] = useState('blocks');
  const [saveStatus, setSaveStatus] = useState('');
  const [pages, setPages] = useState([
    { id: 'page-1', name: 'Page 1', content: '' },
  ]);
  const [currentPage, setCurrentPage] = useState('page-1');
  const [editingPageId, setEditingPageId] = useState(null);
  const [newPageName, setNewPageName] = useState('');
  const [templateExists, setTemplateExists] = useState(false);
  const [templateTitle, setTemplateTitle] = useState('');
  const [userName, setUserName] = useState('');

  const { courseId } = useParams();
  console.log('courseid', courseId);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalPageName, setModalPageName] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [categoryId, setCategoryId] = useState('');

  const [isUserSavedPages, setIsUserSavedPages] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  useEffect(() => {
    if (!editorRef.current) {
      editorRef.current = grapesjs.init(editorConfig);

      editorRef.current.on('load', () => {
        const iframe = editorRef.current.Canvas.getFrameEl();
        const iframeDoc = iframe.contentDocument;

        // Example: Add Tailwind CSS to iframe
        const link = iframeDoc.createElement('link');
        link.rel = 'stylesheet';
        link.href =
          'https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css';
        iframeDoc.head.appendChild(link);

        // Example: Add custom style to iframe
        const style = iframeDoc.createElement('style');
        style.innerHTML = `h1 { color: red; }`; // Example style
        iframeDoc.head.appendChild(style);

        // Example: Handle click events inside the iframe
        iframeDoc.addEventListener('click', handleIframeClick);
      });

      // Initialize block manager if not already initialized
      const blockManager = editorRef.current.BlockManager;
      if (!blockManager.get('image')) {
        blockManager.add('image', {
          label: 'Image',
          content:
            '<img src="https://via.placeholder.com/150" class="w-full object-contain mx-1 my-1" alt="Image">',
          category: 'Basic',
        });
      }

      if (!blockManager.get('Enroll')) {
        blockManager.add('Enroll', {
          label: 'Enroll',
          content: `<button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
  Button
</button>`,
          category: 'Enroll',
        });
      }

      if (!blockManager.get('video')) {
        blockManager.add('video', {
          label: 'Video',
          content: `
            <div class="video-container">
              <video controls>
                <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            </div>`,
          category: 'Media',
          type: 'video',
        });
      }

      // Get default sidebar and manage buttons
      const defaultSidebar = editorRef.current.Panels.getPanel('views');
      if (defaultSidebar) {
        const buttons =
          defaultSidebar.view.el.getElementsByClassName('gjs-pn-btn');
        setSidebarButtons(Array.from(buttons));
      }
    }
  }, []);

  // Function to handle iframe click events
  const handleIframeClick = (event) => {
    const iframe = editorRef.current.Canvas.getFrameEl();
    const videoOverlay = event.target.closest('.video-overlay');
    if (videoOverlay) {
      videoOverlay.classList.toggle('hidden');
    }
    // Add other iframe interactions as needed
  };

  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        const response = await fetch(
          `http://localhost:8000/api/course/getTemplatesByCourseId/${courseId}`,
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const template = await response.json();

        // Ensure template data is correctly formatted
        if (template && template.data && template.data.length > 0) {
          const fetchedTemplate = template.data[0];

          // Set the template components and styles
          editorRef.current.setComponents(fetchedTemplate.pageTemplate);
          editorRef.current.setStyle(fetchedTemplate.cssTemplate || '');

          setTemplateExists(true);
          setTemplateTitle(fetchedTemplate.title);
          setUserName(fetchedTemplate.userName);
          console.log('Fetched template:', fetchedTemplate.pageTemplate);
        }
      } catch (error) {
        console.error('Error fetching template:', error);
      }
    };

    fetchTemplate();
  }, [courseId]);

  const handleImageClick = (divId) => {
    setVisibleDiv(divId);

    const iframe = editorRef.current.Canvas.getFrameEl();
    iframe.onload = () => {
      const iframeDoc = iframe.contentDocument;
      const overlay = iframeDoc.querySelector('.video-overlay');
      if (overlay) {
        overlay.classList.remove('hidden');
      }
    };
  };

  const updateTemplate = async () => {
    const pageContent = editorRef.current.getHtml();
    const payload = {
      pageTemplate: pageContent,
      htmlTemplate: pageContent,
    };

    try {
      const response = await fetch(
        `http://localhost:8000/api/updateTemplateById/${id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        },
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      console.log('Update successful:', responseData);
      setSaveStatus('Template updated successfully!');
    } catch (error) {
      console.error('Error updating template:', error);
      setSaveStatus('Error updating template.');
    }
  };

  const handleModalSubmit = async () => {
    const pageContent = editorRef.current.getHtml();
    const payload = {
      courseId: courseId,
      pageName: modalPageName,
      title: modalTitle,
      pageTemplate: pageContent,
      htmlTemplate: htmlTemplate,
      cssTemplate: 'This is css',
      userName: 'currentUser',
      // categoryId: 'UserSavedPages',
    };

    try {
      const response = await fetch(
        'http://localhost:8000/api/course/addcoursepage',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        },
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      console.log('Save successful:', responseData);
      // setSaveStatus('Page saved successfully!');
      toast.success('Page saved successfully!');
      handleCloseModal(); // Close the modal on success

      // Add the new template to the canvas
      editorRef.current.setComponents(pageContent);
    } catch (error) {
      console.error('Error saving page:', error);
      // setSaveStatus('Error saving page.');
      toast.error('Error saving page.');
    }
  };

  const getTabStyle = (tabName) => {
    return visibleDiv === tabName
      ? { color: 'black', backgroundColor: `${palette.tertiary}` }
      : { color: 'black', backgroundColor: 'white' };
  };

  return (
    <MainContainer>
      <InnerContainer>
        <CoursePageBuilderModal
          isModalOpen={isModalOpen}
          handleCloseModal={handleCloseModal}
          modalPageName={modalPageName}
          setModalPageName={setModalPageName}
          modalTitle={modalTitle}
          setModalTitle={setModalTitle}
          handleModalSubmit={handleModalSubmit}
        />
        <LeftPanel>
          <BackButtonCont>
            <ArrowBackIosIcon onClick={() => navigate(-1)} />
            <SaveBtnCont>
              {isUserSavedPages === 'UserSavedPages' && (
                <SaveBtn onClick={updateTemplate}>Save</SaveBtn>
              )}
              <SaveBtn onClick={handleOpenModal}>New Page</SaveBtn>
            </SaveBtnCont>
          </BackButtonCont>
          <div>
            <SubContainer1>
              <Container1>
                <BlockTabs
                  onClick={() => handleImageClick('blocks')}
                  style={getTabStyle('blocks')}
                >
                  Blocks
                </BlockTabs>
                <HoverText>blocks</HoverText>
              </Container1>
              <Container1>
                <BlockTabs
                  onClick={() => handleImageClick('layer')}
                  style={getTabStyle('layer')}
                >
                  Layer
                </BlockTabs>
                <HoverText>layers</HoverText>
              </Container1>
              <Container1>
                <BlockTabs
                  onClick={() => handleImageClick('edit')}
                  style={getTabStyle('edit')}
                >
                  Style
                </BlockTabs>
                <HoverText>style</HoverText>
              </Container1>
            </SubContainer1>
            <Container2>
              <div
                id="blocks"
                style={{ display: visibleDiv === 'blocks' ? 'block' : 'none' }}
              ></div>
              <div
                id="layer"
                style={{ display: visibleDiv === 'layer' ? 'block' : 'none' }}
              ></div>
              <div
                id="stylemng"
                style={{ display: visibleDiv === 'edit' ? 'block' : 'none' }}
              ></div>
            </Container2>
          </div>
        </LeftPanel>
        <EditorCont>
          <div id="editor" ref={editorRef.current}></div>
        </EditorCont>
      </InnerContainer>
      <ToastContainer />
    </MainContainer>
  );
};

export default CoursePageBuilder;
