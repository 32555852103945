// import apiClient from './path/to/apiClient';  // Adjust the path as needed

import apiClient from './apiClient';
import { getHeader } from './localStorageUtils';

export const updateUserApi = async (data) => {
  try {
    const customHeaders = getHeader();
    // const response = await apiClient.post('/api/userProfile', data);
    const response = await apiClient.post('/api/userProfile', data, {
      headers: customHeaders,
    });
    console.log('update api testing are ', response.data);
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data : 'An error occurred');
  }
};
