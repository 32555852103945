import axios from 'axios';

//Users
export const createUserApi = async (UserData) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URL}/api/eventManager/users`,
      UserData,
    );
    console.log(response, 'create API Response');
    return response.data;
  } catch (error) {
    throw new Error(error.response.data);
  }
};
//Create user by CSV
export const createUserByCSVApi = async (UserData) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URL}/api/eventManager/usersCsv`,
      UserData,
    );
    console.log(response, 'create API Response');
    return response.data;
  } catch (error) {
    throw new Error(error.response.data);
  }
};

export const fetchUserApi = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_URL}/api/eventManager/getUsers`,
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateUserApi = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URL}/api/userProfile`,
      data,
    );
    console.log('update api', response.data);

    return response.data;
  } catch (error) {
    throw new Error(error.response.data);
  }
};

export const fetchUserDetailsApi = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_URL}/api/eventManager/getUser/${id}`,
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteUserApi = async (id) => {
  try {
    await axios.put(
      `${process.env.REACT_APP_URL}/api/eventManager/deleteUser/${id}`,
    );
    return id; // Return the eventId if deletion is successful
  } catch (error) {
    throw new Error(error.response.data);
  }
};

export const fetchUserRoleApi = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_URL}/api/fetchRole`,
    );
    console.log('from Administration API');
    console.log(response);

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
