import { toast } from 'react-toastify';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { data } from 'autoprefixer';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const API_URL = process.env.REACT_APP_URL;

// Async thunk to fetch all templates
export const getAllTemplates = createAsyncThunk(
  'userTemplates/fetchAllTemplates',
  async () => {
    try {
      const response = await axios.get(`${API_URL}/api/getTemplate`);
      const alltemplates = response.data.filter(
        (template) =>
          (template.categoryId === 'CoursePages' ||
            'DefaultPages' ||
            'UserSavedPages') &&
          template.isDeleted === false,
      );

      console.log(' Get check  all allTemplates from slice', alltemplates);
      console.log(' Get check  all allTemplates from slice', alltemplates);
      return alltemplates;
    } catch (error) {
      throw new Error(error.response.data || 'Error fetching all templates');
    }
  },
);

export const createTemplate = createAsyncThunk(
  'userTemplates/createTemplate',
  async (newTemplate) => {
    try {
      const response = await axios.post(`${API_URL}/api/addPages`, newTemplate);
      console.log(' new new template from slice ', response.data.template);
      toast.success('Page added successfully');
      return response.data.template;
    } catch (error) {
      return error.response.data || 'Error creating new template';
    }
  },
);

export const deleteTemplate = createAsyncThunk(
  'userTemplates/deleteTemplate',
  async (id) => {
    try {
      const res = await axios.put(`${API_URL}/api/deleteTemplate/${id}`);
      toast.success('Template deleted successfully! ');

      return id;
    } catch (error) {
      return error.response.data || 'Error deleting template';
    }
  },
);

// Async thunk to fetch user saved templates
export const fetchUserSavedTemplates = createAsyncThunk(
  'userTemplates/fetchUserSavedTemplates',
  async () => {
    try {
      const response = await axios.get(`${API_URL}/api/getUserSavedTemplates`);
      return response.data;
    } catch (error) {
      throw new Error(
        error.response.data || 'Error fetching user saved templates',
      );
    }
  },
);

// Async thunk to fetch course pages
export const getCoursePages = createAsyncThunk(
  'userTemplates/getCoursePages',
  async () => {
    try {
      const response = await axios.get(`${API_URL}/api/getTemplate`);
      console.log('response from slice for the course pages ', response);
      const coursePages = response.data.filter(
        (template) =>
          (template.pageType === 'Course page' ||
            template.pageType === 'Course page *' ||
            template.pageType === 'Bundle page *' ||
            template.pageType === 'Custom page *' ||
            template.pageType === 'Custom page' ||
            template.pageType === 'Bundle page' ||
            template.pageType === ' Homepage' ||
            template.pageType === ' Homepage *' ||
            template.categoryId === 'Published Pages') &&
          template.isDeleted === false,
      );
      console.log('This is coursePages', coursePages);
      return coursePages;
    } catch (error) {
      throw new Error(error.response.data || 'Error fetching course pages');
    }
  },
);

export const getDefaultPages = createAsyncThunk(
  'userTemplates/getDefaultPages',
  async () => {
    try {
      const response = await axios.get(`${API_URL}/api/getTemplate`);
      const defaultPages = response.data.filter(
        (template) =>
          (template.categoryId === 'DefaultPages' ||
            template.pageType === 'Default page') &&
          template.isDeleted === false,
      );
      console.log('This is defaultPages', defaultPages);
      return defaultPages;
    } catch (error) {
      throw new Error(error.response.data || 'Error fetching Default pages');
    }
  },
);

export const updatePageById = createAsyncThunk(
  'template/updatePageById', // Name of the action
  async ({ id, payload }, { rejectWithValue }) => {
    // Receive `id` and `payload` from the calling function
    try {
      console.log('abc from slic payload,', payload);
      console.log('abc from slic id,', id);

      const response = await axios.put(
        `${API_URL}/api/updateTemplateById/${id}`,
        payload,
      );
      console.log('resposnse from slice abc', response);

      // Return the response data
      return response.data;
    } catch (error) {
      // Handle errors and return them with rejectWithValue
      return rejectWithValue(error.response.data);
    }
  },
);

export const getPageById = createAsyncThunk(
  'template/getPageById',
  async ({ id }) => {
    try {
      const response = await axios.get(`${API_URL}/api/getTemplateById/${id}`);
      console.log('abc fetched 1 from slice', response);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data || 'Error fetching page by id');
    }
  },
);

export const publishingPage = createAsyncThunk(
  'template/publishingPage', // Name of the action
  async ({ pageContent }, { rejectWithValue }) => {
    try {
      // Send the POST request to publish the page
      const response = await fetch(`${API_URL}/api/publishPage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ content: pageContent }),
      });

      // Handle errors if the response is not ok
      if (!response.ok) {
        throw new Error(`Failed to publish the page: ${response.statusText}`);
      }

      // Parse the response JSON
      const result = await response.json();

      // Return the response result
      return result;
    } catch (error) {
      // Return error details with rejectWithValue
      return rejectWithValue(error.message);
    }
  },
);

const sitebuilderSlice = createSlice({
  name: 'userTemplates',
  initialState: {
    isPublished: false,
    resultUrl: '',
    templates: [],
    coursePages: [],
    defaultPages: [],
    alltemplates: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    // Add reducers for other actions if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserSavedTemplates.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserSavedTemplates.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.templates = action.payload;
      })
      .addCase(fetchUserSavedTemplates.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(getCoursePages.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCoursePages.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.coursePages = action.payload; // Update coursePages state
      })
      .addCase(getCoursePages.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(getDefaultPages.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getDefaultPages.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.defaultPages = action.payload;
      })
      .addCase(getDefaultPages.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(getAllTemplates.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllTemplates.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.alltemplates = action.payload;
      })
      .addCase(getAllTemplates.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createTemplate.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createTemplate.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Add the newly created template to the alltemplates array
        state.alltemplates = action.payload;
        console.log('newly created template', action.payload);
        // state.coursePages.push(action.payload);
      })
      .addCase(createTemplate.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deleteTemplate.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteTemplate.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Remove the deleted template from the state by filtering out the matching ID
        state.alltemplates = state.alltemplates.filter(
          (template) => template._id !== action.payload,
        );
        state.coursePages = state.coursePages.filter(
          (template) => template._id !== action.payload,
        );
        state.defaultPages = state.defaultPages.filter(
          (template) => template._id !== action.payload,
        );
      })
      .addCase(deleteTemplate.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(publishingPage.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(publishingPage.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isPublished = true;
        state.resultUrl = action.payload.url;
      })
      .addCase(publishingPage.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Failed to publish the page';
      })
      .addCase(updatePageById.pending, (state) => {
        state.updatingStatus = 'loading'; // Track updating status
      })
      .addCase(updatePageById.fulfilled, (state, action) => {
        state.updatingStatus = 'succeeded'; // Update status on success
        console.log('payload new template', action.payload);
        // Update the template in the state
        state.templates = state.templates.map((template) =>
          template._id === action.payload._id ? action.payload : template,
        );
        console.log('action', action.payload);
        console.log('action id', action.payload._id);
        state.alltemplates = state.alltemplates.map((template) =>
          template._id === action.payload._id ? action.payload : template,
        );
        console.log('after change ', state.alltemplates);
      })
      .addCase(updatePageById.rejected, (state, action) => {
        state.updatingStatus = 'failed'; // Track failed update
        state.error = action.payload || 'Failed to update the page';
      });
  },
});

export default sitebuilderSlice.reducer;
