import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchCertificateTemplateApi,
  deleteCertificateTemplateApi,
  createCertificateTemplateApi,
  fetchIssueCertificate,
  fetchBlacklistedCertificateApi,
  createCertificateApi,
  fetchAllCertificates,
  editCertificateApi,
  deleteCertificateApi,
  blacklistApi,
  fetchCertificateSettings,
  fetchEventApi,
  fetchSingleCertificateApi,
  createCertificateSettingsApi,
  issueSingleCertificateApi,
  fetchSingleSettingsApi,
  updateCertificateSettingsApi,
  issueBulkCertificateApi,
  deleteSettingsApi,
  updateBlacklistedUserApi,
  deleteBlacklistedUserApi,
  fetchIssuedCertificateUserApi,
  downloadCertificateApi,
  fetchEligibleCertificateUserApi,
} from '../../api/CertificateApi';

export const fetchCertificateTemplate = createAsyncThunk(
  'certificate/fetchTemplate',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchCertificateTemplateApi();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

export const deleteCertificateTemplate = createAsyncThunk(
  'certificate/deleteTemplate',
  async (templateId, { rejectWithValue, dispatch }) => {
    try {
      await deleteCertificateTemplateApi(templateId);
      dispatch(fetchCertificateTemplate());
      return templateId;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

export const deleteCertificate = createAsyncThunk(
  'certificate/deleteTemplate',
  async (certificateId, { rejectWithValue }) => {
    try {
      await deleteCertificateApi(certificateId);
      return certificateId;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

export const addCertificateTemplate = createAsyncThunk(
  'certificate/addTemplate',
  async (templateData, { rejectWithValue }) => {
    try {
      const response = await createCertificateTemplateApi(templateData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

export const addCertificate = createAsyncThunk(
  'certificate/addCertificate',
  async (templateData, { rejectWithValue }) => {
    try {
      const response = await createCertificateApi(templateData);
      console.log('checking res', response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

export const addCertificateSettings = createAsyncThunk(
  'certificate/addCertificate',
  async (Data, { rejectWithValue }) => {
    try {
      const response = await createCertificateSettingsApi(Data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

export const fetchAllCertificateApi = createAsyncThunk(
  'certificate/fetchAllCertificateApi',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchAllCertificates();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

export const fetchSingleCertificate = createAsyncThunk(
  'certificate/fetchSingleCertificateApi',
  async (certificateId, { rejectWithValue }) => {
    try {
      const response = await fetchSingleCertificateApi(certificateId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

export const updateCertificateSetting = createAsyncThunk(
  'certificate/updateCertificateSetting',
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateCertificateSettingsApi(data);
      console.log(response, 'updateCertificateSetting');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

export const fetchAllSettingsApi = createAsyncThunk(
  'certificate/fetchAllSettingsApi',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchCertificateSettings();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

export const fetchIssuedCertificate = createAsyncThunk(
  'certificate/fetchIssueCertificate',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchIssueCertificate();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

export const blacklistUser = createAsyncThunk(
  'certificate/blacklistUser',
  async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState(); // Access the Redux state
      console.log('Checking State:', state); // Log the entire state

      const disqualifiedBy =
        state.auth?.users?.user.userid?.firstname || 'Unknown';
      console.log('Checking Disqualified By:', disqualifiedBy); // Log the disqualifiedBy value

      const payload = { ...data, disqualifiedBy };
      console.log('Checking Data before sending to API:', payload); // Log the data being sent to the API

      const response = await blacklistApi(data.email, payload);

      console.log('Checking API Response:', response); // Log the API response
      return response.data;
    } catch (error) {
      console.log('Error:', error); // Log the error if any occurs
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

export const issueCertificate = createAsyncThunk(
  'certificate/issueCertificate',
  async (data, { rejectWithValue }) => {
    try {
      const response = await issueSingleCertificateApi(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

export const issueBulkCertificate = createAsyncThunk(
  '/certificate/bulkIssue',
  async (data, { rejectWithValue }) => {
    try {
      const response = await issueBulkCertificateApi(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

export const fetchBlacklistedCertificate = createAsyncThunk(
  '/certificate/getBlacklistedUsers',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchBlacklistedCertificateApi();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

export const editCertificate = createAsyncThunk(
  'certificate/editCertificate',
  async ({ certificateId, updatedData }, { rejectWithValue }) => {
    try {
      const response = await editCertificateApi(certificateId, updatedData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

export const fetchSingleSetting = createAsyncThunk(
  'certificate/fetchSingleSetting',
  async (settingsId, { rejectWithValue }) => {
    try {
      const response = await fetchSingleSettingsApi(settingsId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

export const fetchEvent = createAsyncThunk(
  'fetchEvent',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchEventApi();
      console.log('Fetch event slice', response);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteSetting = createAsyncThunk(
  'certificate/deleteTemplate',
  async (settingId, { rejectWithValue, dispatch }) => {
    try {
      await deleteSettingsApi(settingId);
      dispatch(fetchAllSettingsApi());
      return settingId;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);
export const updateBlacklistedUser = createAsyncThunk(
  'certificate/updateBlacklistedUser',
  async ({ email, updatedData }, { rejectWithValue }) => {
    try {
      // Log the payload to check what is being sent
      console.log('Payload for updateBlacklistedUser:', { email, updatedData });

      const response = await updateBlacklistedUserApi(email, updatedData);

      console.log('API Response:', response);
      return response.data;
    } catch (error) {
      console.log('Error in updateBlacklistedUser thunk:', error);
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

export const deleteBlacklistedUser = createAsyncThunk(
  'certificate/deleteBlacklistedUser',
  async (email, { rejectWithValue }) => {
    try {
      await deleteBlacklistedUserApi(email);
      return email;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);
//learner fetch
export const fetchIssuedCertificateUser = createAsyncThunk(
  'certificates/fetchIssuedCertificates',
  async (email, thunkAPI) => {
    try {
      console.log(email, 'checking in slice');

      const response = await fetchIssuedCertificateUserApi(email);

      console.log('checking Thunk API Response:', response); // Log the API response

      return response.data;
    } catch (error) {
      console.log('Thunk API Error:', error); // Log the error if any occurs
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  },
);
export const fetchEligibleCertificateUser = createAsyncThunk(
  'certificates/fetchIssuedCertificates',
  async (email, thunkAPI) => {
    try {
      console.log(email, 'checking in slice');

      const response = await fetchEligibleCertificateUserApi(email);

      console.log('checking Thunk API Response:', response); // Log the API response

      return response.data;
    } catch (error) {
      console.log('Thunk API Error:', error); // Log the error if any occurs
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  },
);
export const downloadCertificate = createAsyncThunk(
  'certificates/downloadCertificate',
  async ({ email, serialNumber }, { rejectWithValue }) => {
    try {
      console.log('Checking in slice:', serialNumber, email);

      // Make API call to download the certificate
      const response = await downloadCertificateApi(email, serialNumber);

      if (!response.ok) {
        throw new Error(
          `Network response was not ok: Status Code ${response.status}`,
        );
      }

      // Convert the response to a Blob (for binary data)
      const blob = await response.blob();
      console.log('Blob received:', blob); // Ensure blob data is received

      return blob; // Return the Blob to be handled by the component
    } catch (error) {
      console.error('Error in downloadCertificate:', error);
      return rejectWithValue(error.message);
    }
  },
);

const initialState = {
  data: [],
  events: [],
  allcertificate: [],
  settings: [],
  issued: [],
  blacklist: [],
  updatedUser: [],
  allsettings: [],
  templatecertificate: [],
  loading: false,
  error: null,
};

const certificateSlice = createSlice({
  name: 'certificate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCertificateTemplate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCertificateTemplate.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchCertificateTemplate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.data = null;
      })
      .addCase(addCertificateTemplate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addCertificateTemplate.fulfilled, (state, action) => {
        state.loading = false;
        state.templatecertificate.push(action.payload);
        state.error = null;
      })
      .addCase(addCertificateTemplate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchEvent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEvent.fulfilled, (state, action) => {
        state.loading = false;
        state.events = action.payload;
        state.error = null;
      })
      .addCase(fetchEvent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.events = null;
      })
      .addCase(fetchAllCertificateApi.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllCertificateApi.fulfilled, (state, action) => {
        state.loading = false;
        state.allcertificate = action.payload;
        state.error = null;
      })
      .addCase(fetchAllCertificateApi.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.allcertificate = null;
      })
      .addCase(fetchSingleSetting.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSingleSetting.fulfilled, (state, action) => {
        state.loading = false;
        state.settings = action.payload;
        state.error = null;
      })
      .addCase(fetchSingleSetting.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.settings = null;
      })
      .addCase(fetchIssuedCertificate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchIssuedCertificate.fulfilled, (state, action) => {
        state.loading = false;
        state.issued = action.payload;
        state.error = null;
      })
      .addCase(fetchIssuedCertificate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.issued = null;
      })
      .addCase(fetchBlacklistedCertificate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBlacklistedCertificate.fulfilled, (state, action) => {
        state.loading = false;
        state.blacklist = action.payload;
        state.error = null;
      })
      .addCase(fetchBlacklistedCertificate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.blacklist = null;
      })
      .addCase(fetchAllSettingsApi.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllSettingsApi.fulfilled, (state, action) => {
        state.loading = false;
        state.allsettings = action.payload;
        state.error = null;
      })
      .addCase(fetchAllSettingsApi.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.allsettings = null;
      })
      .addCase(updateBlacklistedUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateBlacklistedUser.fulfilled, (state, action) => {
        state.loading = false;
        console.log('checking Update fulfilled payload:', action.payload);
        state.updatedUser.push(action.payload);
        state.error = null;
      })

      .addCase(updateBlacklistedUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteBlacklistedUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteBlacklistedUser.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteBlacklistedUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchIssuedCertificateUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchIssuedCertificateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.issued = action.payload;
        state.error = null;
      })
      .addCase(fetchIssuedCertificateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(downloadCertificate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(downloadCertificate.fulfilled, (state, action) => {
        state.loading = false;
        state.issued = action.payload;
        state.error = null;
      })
      .addCase(downloadCertificate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default certificateSlice.reducer;
