import React, { useEffect } from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchEventsByStartDate } from '../../features/calendar/CalendarSlice';
import { useTranslation } from 'react-i18next';
import { palette } from '../../styles/palette';

const EventPopup = ({ date }) => {
  const dispatch = useDispatch();
  const events = useSelector((state) => state.calendar.events);
  const { users } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(fetchEventsByStartDate(date));
  }, [dispatch, date]);

  const navigate = useNavigate();

  const handleNavigate = (eventName) => {
    if (users.user.userid?.role === 'Admin') {
      navigate(`/EventsNavigator/eventdetails/${eventName}`);
    } else {
      navigate(`/LearnersEventsNavigator/eventdetails/${eventName}`);
    }
  };

  const { t } = useTranslation();
  const dummyStartTime = '10:00 AM';

  const getStartTime = (event) => {
    let startTime = dummyStartTime;

    if (event.sessionType === 'multiSession') {
      startTime =
        event.SingleDateEvents.length > 0
          ? event.SingleDateEvents[0].sessions.length > 0
            ? event.SingleDateEvents[0].sessions[0].startTime || dummyStartTime
            : dummyStartTime
          : dummyStartTime;
    } else if (event.sessionType === 'multiDay' && event.MultipleStartDate) {
      const allStartTimes = event.MultipleStartDate.map(
        ({ startTime }) => startTime,
      );
      startTime = allStartTimes.length > 0 ? allStartTimes[0] : dummyStartTime;
    } else {
      startTime =
        event.STime.length > 0
          ? event.STime[0] || dummyStartTime
          : dummyStartTime;
    }

    return `${startTime} AM`; // Add the hardcoded AM
  };

  return (
    <>
      {events.length > 0 ? (
        <Box width="500px" maxWidth="1500px" margin="0 auto">
          <Box display="flex" flexDirection="column" gap={2}>
            {events.map((event, index) => (
              <Tooltip
                key={index}
                title={`Click for more info about ${event.EventName}`}
                arrow
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  padding="15px"
                  boxSizing="border-box"
                  sx={{
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease',
                    borderBottom: '1px solid #eee',
                    '&:hover': {
                      backgroundColor: '#f5f5f5',
                    },
                    '&:last-of-type': {
                      borderBottom: 'none',
                    },
                  }}
                  onClick={() => handleNavigate(event.EventName)}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    width="70%"
                    sx={{
                      overflow: 'hidden',
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      marginRight="10px"
                      sx={{
                        minWidth: '75px',
                        textAlign: 'right',
                      }}
                    >
                      <Typography
                        variant="body1"
                        color="#0684FB"
                        fontWeight="bold"
                        marginBottom="5px"
                      >
                        {getStartTime(event)}
                      </Typography>
                    </Box>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color={palette.primary.main}
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: 'calc(100% - 85px)',
                        paddingBottom: '6px',
                      }}
                    >
                      {event.EventName}
                    </Typography>
                  </Box>

                  <Box
                    sx={{}}
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    width="30%"
                  >
                    <Typography variant="caption" color="#333">
                      {20} Registered
                    </Typography>
                  </Box>
                </Box>
              </Tooltip>
            ))}
          </Box>
        </Box>
      ) : (
        <Typography variant="body1" align="center" color="textSecondary">
          {t('calender.noEventLabel')}
        </Typography>
      )}
    </>
  );
};

export default EventPopup;
