import React, { useState, useEffect } from 'react';
import EventsTabs from './EventsTabs';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip'; // Import Tooltip
import { UserTable, CardImg } from '../../styles/Calendar/CalendarStyle';
import { useParams } from 'react-router-dom';
import TableUtilityLib from '../Utilities/TableUtilityLib';
import {
  fetchEnrolledUsersData,
  fetchUserStatisticsData,
} from '../../features/calendar/CalendarSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { palette } from '../../styles/palette';
import Footer from '../Landing/Footer';
import { Box } from '@mui/material';

const UserInfo = () => {
  const dispatch = useDispatch();
  const { eventname } = useParams();
  const [tableData, setTableData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('all'); // Default to 'total'

  const { userstats, userdata, error, eventDetails } =
    useSelector((state) => state.calendar) || {};
  const { t } = useTranslation();

  useEffect(() => {
    if (eventname) {
      dispatch(fetchEnrolledUsersData(eventname));
      dispatch(fetchUserStatisticsData(eventname));
    }
  }, [dispatch, eventname]);

  useEffect(() => {
    if (userstats) {
      // Filter data based on selectedFilter
      const filteredData =
        selectedFilter === 'all'
          ? userdata
          : selectedFilter === 'active'
            ? userstats.users.active
            : userstats.users.pending;

      // Set filtered data to tableData
      setTableData(filteredData);
    }
  }, [userstats, selectedFilter]);

  const handleCardClick = (status) => {
    console.log(status);
    setSelectedFilter(status);
  };

  const columns = [
    {
      name: t('calender.firstName'),
      selector: (row) => row.firstname,
      sortable: true,
    },
    {
      name: t('calender.lastName'),
      selector: (row) => row.lastname,
      sortable: true,
    },
    { name: t('calender.Email'), selector: (row) => row.email, sortable: true },
    {
      name: t('calender.joiningDate'),
      selector: (row) => formatDate(row.createdAt),
      sortable: true,
    },
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <EventsTabs />
          <Grid container spacing={4} sx={{ padding: 6 }}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Tooltip
                title={t('Count of total registrations', {
                  count: userstats?.totalRegistrations,
                })}
              >
                <Card
                  sx={{
                    display: 'flex',
                    width: '100%',
                    height: 110,
                    backgroundColor: `${palette.cardBgColor}`,
                    cursor: 'pointer',
                    '&:hover': {
                      boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  onClick={() => handleCardClick('all')}
                >
                  <CardContent sx={{ flex: '0 0 100px', padding: 1 }}>
                    <CardImg src={require('../../images/student.jpg')} />
                  </CardContent>
                  <CardContent sx={{ padding: 1 }}>
                    <Typography sx={{ color: 'blue' }} variant="h5">
                      {userstats ? userstats.totalRegistrations : '00'}
                    </Typography>
                    <Typography variant="body1">
                      {t('calender.totalRegistrationLabel')}
                    </Typography>
                  </CardContent>
                </Card>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Tooltip
                title={t('Count of paid and registered', {
                  count: userstats?.activeRegistrations,
                })}
              >
                <Card
                  sx={{
                    display: 'flex',
                    width: '100%',
                    height: 110,
                    backgroundColor: `${palette.cardBgColor}`,
                    cursor: 'pointer',
                    '&:hover': {
                      boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  onClick={() => handleCardClick('active')}
                >
                  <CardContent sx={{ flex: '0 0 100px', padding: 1 }}>
                    <CardImg src={require('../../images/registered.png')} />
                  </CardContent>
                  <CardContent sx={{ padding: 1 }}>
                    <Typography sx={{ color: 'blue' }} variant="h5">
                      {userstats ? userstats.activeRegistrations : '00'}
                    </Typography>
                    <Typography variant="body1">
                      {t('calender.activeRegistrationLabel')}
                    </Typography>
                  </CardContent>
                </Card>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Tooltip
                title={t('Awaiting payment completion', {
                  count: userstats?.pendingRegistrations,
                })}
              >
                <Card
                  sx={{
                    display: 'flex',
                    width: '100%',
                    height: 110,
                    backgroundColor: `${palette.cardBgColor}`,
                    cursor: 'pointer',
                    '&:hover': {
                      boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  onClick={() => handleCardClick('pending')}
                >
                  <CardContent sx={{ flex: '0 0 100px', padding: 1 }}>
                    <CardImg src={require('../../images/paymentpending.png')} />
                  </CardContent>
                  <CardContent sx={{ padding: 1 }}>
                    <Typography sx={{ color: 'blue' }} variant="h5">
                      {userstats ? userstats.pendingRegistrations : '00'}
                    </Typography>
                    <Typography variant="body1">
                      {t('calender.pendingRegistrationLabel')}
                    </Typography>
                  </CardContent>
                </Card>
              </Tooltip>
            </Grid>
          </Grid>

          <UserTable>
            <TableUtilityLib
              title={`${t('calender.enrolleventdescriptionLabel')} ${eventDetails?.EventName}:`}
              columns={columns}
              data={tableData}
              selectableRows
              selectableRowsHighlight
              customRowStyles={{
                // Custom styles to hide checkboxes
                rows: {
                  style: {
                    backgroundColor: 'transparent',
                    cursor: 'pointer',
                  },
                },
                cells: {
                  style: {
                    // Hide checkboxes in cells
                    display: 'none',
                  },
                },
              }}
              onRowClicked={(row) => console.log('Row clicked:', row)}
            />
          </UserTable>
        </Box>

        <Footer />
      </Box>
    </>
  );
};

export default UserInfo;
