import React, { useState, useMemo } from 'react';
import { toast } from 'react-toastify';
import ProgressModal from '../../Utilities/ProgressModal';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Container,
  Typography,
  Tooltip,
  IconButton,
  Grid,
  InputAdornment,
  Button,
} from '@mui/material';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { palette } from '../../../styles/palette';
import JoditReact from 'jodit-react';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch } from 'react-redux';
import { addItem } from '../../../features/courses/addCourseDetailsSlice';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import '../../Utilities/Sample.css';
import { styled } from '@mui/system';
import {
  EditorContainer,
  DeleteBox,
  StyledTextField,
  ButtonContainer,
  BackButtonContainer,
  HeaderContainer,
} from '../../../styles/Courses/CourseModuleStyles';

const DiscardButton = styled(Button)`
  color: ${palette.primary};
  border-color: ${palette.gray};
  margin-right: 10px;
  width: 180px;
`;

const SaveButton = styled(Button)`
  background-color: ${palette.primary};
  color: ${palette.white};
`;

const PdfUpload = ({ onClose, selectedItem, lessonId, moduleId }) => {
  const [saved, setSaved] = useState(true);
  const { id, courseStructure } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [completed, setCompleted] = useState(false);

  const [selectedValue, setSelectedValue] = useState('upload');
  const [key, setKey] = useState(0);
  const [videoShow, setVideoShow] = useState('');
  const reloadViewer = () => {
    setKey((prevKey) => prevKey + 1);
  };
  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleOnClose = () => {
    const data = { ...formData, preview: false };
    console.log('abc data from go back', data);
    if (saved && (formData.uploadUrl || formData.link)) {
      onClose();
    } else {
      dispatch(addItem(data));
      onClose();
    }
  };

  const [formData, setFormData] = useState({
    selectedValue: 'upload',
    link: selectedItem?.link || '',
    uploadUrl: '',
    moduleId: selectedItem?.moduleId || '',
    lessonId: selectedItem?.lessonId || '',
    title: selectedItem?.title || '',
    textDescription: selectedItem?.textDescription || '',
    id: selectedItem?.id || '',
    courseStructure: selectedItem?.courseStructure || '',
    contentType: selectedItem?.contentType || '',
    preview: selectedItem?.preview || false,
  });

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      lessonId: lessonId,
      moduleId: moduleId,
      id: selectedItem?.id || generateRandomNumber(),
      contentType: 'PDF',
      courseStructure: courseStructure,
      link: formData?.uploadUrl?.split('?')[0],
    }));
    console.log(formData);
  };
  const config = useMemo(
    () => ({
      placeholder: 'Enter PDF description',
      readonly: false,
    }),
    [],
  );

  const handleDelete = () => {
    handleDeleteTopic(
      selectedItem?.moduleId,
      selectedItem?.lessonId,
      selectedItem?.id,
    );
  };

  const generateRandomNumber = () => {
    const min = 100000; // Minimum 6-digit number
    const max = 999999; // Maximum 6-digit number
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile.name);
    // const localUrl = URL.createObjectURL(selectedFile);
    setFormData((prevData) => ({
      ...prevData,
      fileName: selectedFile?.name,
    }));
    // dispatch(setPdf({ pdfUrl: localUrl, title: formData.title }));
    await requestUploadUrl(selectedFile);
  };

  const requestUploadUrl = async (file) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/course/uploadVideo`,
        {
          fileName: file.name,
          fileType: file.type,
        },
      );
      setFormData((prevData) => ({
        ...prevData,
        link: response.data.url?.split('?')[0],
        uploadUrl: response.data.url,
      }));
    } catch (error) {
      console.error('Error requesting upload URL:', error);
    }
  };

  const uploadToS3 = async () => {
    setLoading(true);
    setProgress(0);
    setCompleted(false);
    try {
      await axios.put(formData.uploadUrl, file, {
        headers: {
          'Content-Type': file?.type,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          setProgress(percentCompleted);
        },
      });

      setCompleted(true);
      setLoading(false);

      // dispatch(setPdf({ pdfUrl: formData.link, title: formData.title }));
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleFileRemove = () => {
    setFile(null);
    setFileName('');
    setFormData((prevData) => ({
      ...prevData,
      uploadUrl: '',
    }));
  };

  const handleUpload = async () => {
    if (file) {
      await uploadToS3();
      toast.success('File Upload successfully!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        // transition: Bounce,
      });

      setVideoShow(true);
    }
  };

  const handleSubmit = async () => {
    if (formData.uploadUrl || formData.link || formData.fileName) {
      const data = { ...formData, preview: true };
      console.log('abc from  the submit', data);

      await dispatch(addItem(data));
    } else {
      const data = { ...formData, preview: false };
      console.log('abc data from the save for the draft', data);
      await dispatch(addItem(data));
    }

    setSaved(true);
    onClose();
  };

  console.log('PDF', formData.link);

  return (
    <Container>
      <HeaderContainer>
        {/* Grid container aligned to the left */}
        <BackButtonContainer
          container
          alignItems="center"
          spacing={1}
          sx={{ mt: 1, mb: 2 }}
        >
          <Grid item>
            <Tooltip title="Go Back">
              <IconButton
                size="small"
                onClick={handleOnClose}
                sx={{ color: `${palette.THEME_THREE}` }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item sx={{ mt: 1 }}>
            <Typography variant="h5" gutterBottom>
              PDF
            </Typography>
          </Grid>
        </BackButtonContainer>

        {/* Buttons div aligned to the right */}
        <ButtonContainer>
          <DiscardButton variant="outlined">Discard Changes</DiscardButton>

          {formData.title ? (
            <SaveButton variant="contained" onClick={handleSubmit}>
              Save
            </SaveButton>
          ) : (
            <Button disabled>Save</Button>
          )}
        </ButtonContainer>
      </HeaderContainer>

      <EditorContainer>
        <StyledTextField
          variant="outlined"
          label="Title"
          style={{ width: '100%', marginBottom: '10px' }}
          name="title"
          value={formData.title}
          onChange={handleInputChange}
        />
        <JoditReact
          value={formData.textDescription}
          config={config}
          onChange={(newContent) =>
            setFormData((prevData) => ({
              ...prevData,
              textDescription: newContent,
            }))
          }
        />
      </EditorContainer>

      <RadioGroup
        row
        style={{ marginLeft: '20px', marginBottom: '2%', marginTop: '12px' }}
        defaultValue="upload"
        value={selectedValue}
        onChange={handleRadioChange}
      >
        <FormControlLabel
          value="upload"
          control={<Radio />}
          label="Upload PDF"
        />
        <FormControlLabel
          value="publicUrl"
          control={<Radio />}
          label="Public Url"
        />
      </RadioGroup>

      {/* Render different content based on selected radio button */}
      {selectedValue === 'upload' && (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={10}>
            <TextField
              variant="outlined"
              label="Upload PDF File"
              value={fileName}
              helperText="Max pdf size 2GB"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end"></InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {fileName && (
                      <IconButton
                        onClick={handleFileRemove}
                        style={{ color: 'red' }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                    {!fileName && (
                      <Button
                        variant="contained"
                        component="label"
                        style={{
                          borderRadius: 4,
                          backgroundColor: ` ${palette.primary}`,
                          color: `${palette.white}`,
                        }}
                      >
                        Choose File
                        <input
                          type="file"
                          style={{ display: 'none' }}
                          onChange={handleFileChange}
                        />
                      </Button>
                    )}
                  </InputAdornment>
                ),
              }}
              style={{ width: '100%', marginBottom: '10px' }}
            />
          </Grid>
          <Grid
            item
            xs={2}
            style={{ display: 'flex', alignItems: 'center', padding: '4px' }}
          >
            {fileName && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpload}
                fullWidth
              >
                Upload
              </Button>
            )}
          </Grid>
        </Grid>
      )}
      {selectedValue === 'publicUrl' && (
        <Grid item xs={12}>
          <TextField label="Link" sx={{ width: '100%' }} />
        </Grid>
      )}

      {videoShow && (
        <div style={{ marginTop: '20px' }}>
          <Typography variant="h6">Uploaded PDF</Typography>
          <iframe
            src={formData.link}
            width="100%"
            height="600px"
            style={{ border: 'none' }}
          />
        </div>
      )}

      {selectedItem?.fileName && (
        <div style={{ marginTop: '20px' }}>
          <Typography variant="h6">Uploaded PDF</Typography>
          <iframe
            src={selectedItem.link}
            width="100%"
            height="600px"
            style={{ border: 'none' }}
          />
        </div>
      )}
      <ProgressModal
        open={loading}
        progress={progress}
        completed={completed}
        onClose={() => setLoading(false)}
      />

      <DeleteBox>
        <h4 style={{ marginRight: '10px' }}>
          Are you sure you want to delete this topic? This action is
          irreversible.
        </h4>
        <br />
        <Button variant="contained" color="error" onClick={handleDelete}>
          Delete Topic
        </Button>
      </DeleteBox>
    </Container>
  );
};

export default PdfUpload;
