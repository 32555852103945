import React from 'react-redux';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import NavbarMain from './NavbarMain';
import Services from './Services';
import Footer from './Footer';
import Login from './Login';
import Landing from './Landing';
import Dashboard from '../Dashboard/Dashboard';
import { useEffect, useState } from 'react';
import Sidebar from '../Sidebar/Sidebar';
// import CourseBuilder from '../Courses/CourseBuilder';
import Quizzes from '../Courses/LearnerCourseBuilder/Quizzes';
import MyProfile from '../Profile/MyProfile';
import { Container1 } from '../../styles/Landing/LandingHomeStyles';
import CreateCourse from '../Courses/CreateCourse/CreateCourse';
import ScrollToTop from '../Utilities/ScrollToTop';
import EventsNavigator from '../Calendar/Calendar';
// import CalendarNew from "../Calendar/CalendarNew";
import Certificate from '../Certificates/Certificate';
import LearnerCertificate from '../Certificates/LearnerCertificate';
import Notification from '../Notifications/Notifications';
import LearnerNotification from '../Notifications/LearnerNotification';
import LearnersEventsNavigator from '../Calendar/LearnersCalendar';
import Modules from '../Modules';
import CourseBuilder from '../Courses/CourseBuilder';
import CourseDetails from '../Courses/CourseDetails';
import CourseModules from '../Courses/CourseModule/CourseModules';
import CourseSettings from '../Courses/CourseSettings/CourseSettings';
import LandingPageDetails from '../Courses/LandingPage/LandingPageDesign';
import LearnersList from '../Courses/LearnersList';
import CourseDiscussion from '../Courses/CourseDiscussion/CourseDiscussion';
import CoursePreview from '../Courses/CoursePreview/CoursePreview';
import DisplayScrom from '../Courses/CourseModule/DisplayScrom';
import SelectCourseCard from '../Courses/CreateCourse/SelectCourseCard';
import CopyExistingCourse from '../Courses/CreateCourse/CopyExistingCourse';
import ScromImport from '../Courses/CreateCourse/ScromImport';
import LearnerCoursePreview from '../Courses/LearnerCourseBuilder/LearnerCoursePreview';
import LearnerCourses from '../Courses/LearnerCourses';
import LearnerCourseDetails from '../Courses/LearnerCourseDetails';
import CoursePlayerAppearenceSetting from '../Courses/CourseSettings/CoursePlayerAppearanceSetting';
import CourseProgressCompletionSetting from '../Courses/CourseSettings/CourseProgressCompletionSetting';
import Payment from '../Payment/Payment';
import Users from '../Users/Users';
import Aboutus from './Aboutus';
import { useDispatch, useSelector } from 'react-redux';
import Contactus from './Contactus';
import ResetPassword from '../Users/ResetPassword';
import ForgotPassword from '../Users/ForgotPassword';
import VerificationSuccess from '../Users/VerificationSuccess';
import Sitebuilder from '../SiteBuilder/Sitebuilder';
import SitebuilderRoutes from '../SiteBuilder/SitebuilderRoutes';
import Signup_new from './Signup_new';
import Loader from '../Utilities/Loader';
import firebase from 'firebase/compat/app';
import {
  logInUser,
  logInUserWithEmil,
} from '../../features/authentication/AuthenticationSlice';
// import { useDispatch } from "react-redux";
import CoursePageBuilder from '../Courses/LandingPage/CoursePageBuilder';
import VerifyOtp from './VerifyOtp';
import SelectTemplte from '../Courses/CreateCourse/SelectTemplte';
import CreateTemplte from '../Courses/CreateCourse/CreateTemplte';
import CourseCertificateSettings from '../Courses/CourseSettings/CourseCertificateSettings';
import CourseNotificationSettings from '../Courses/CourseSettings/CourseNotificationSettings';
import CourseIDVerificationSettings from '../Courses/CourseSettings/CourseIDVerificationSettings';
import Preview from '../Courses/preview';

//Being called from App.js for mapping all the routes
function LandingHome({ userProfile }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { users, loading } = useSelector((state) => state.auth);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [initializing, setInitializing] = useState(true);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((userCred) => {
      if (userCred) {
        console.log('userCred');
        userCred.getIdToken().then((token) => {
          console.log('userCred');

          const userData = dispatch(logInUser(token));
          console.log('userCred');

          if (userData?.payload?.statusCode === 200) {
            setIsLoggedIn(true);
            console.log('userCred');

            // Ensure we only navigate to the dashboard if we are not already on an authenticated route
            if (
              location.pathname === '/' ||
              location.pathname === '/Login' ||
              location.pathname === '/Signup'
            ) {
              navigate('/Dashboard');
            }
          } else {
            console.log('userCred dfdsg');

            setIsLoggedIn(false);
            if (
              location.pathname !== '/Login' &&
              location.pathname !== '/Signup'
            ) {
              navigate('/Login');
            }
          }
        });
      } else {
        setIsLoggedIn(false);
        console.log(' out userCred');

        if (location.pathname !== '/Login' && location.pathname !== '/Signup') {
          navigate('/Login');
        }
      }
      setInitializing(false);
    });

    return () => unsubscribe();
  }, [dispatch]);

  const [collapsed, setCollapsed] = useState(false);

  const handleSidebar = () => {
    setCollapsed(!collapsed);
  };
  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <ScrollToTop />

      {!users?.user ? (
        <>
          <NavbarMain />
          <Routes>
            <Route path="/" element={<Landing />} />

            <Route path="/Aboutus" element={<Aboutus />} />
            <Route path="/Services" element={<Services />} />
            {/* <Route path="/Contactus" element={<Contactus />} /> */}
            <Route path="/ContactUs" element={<Contactus />} />
            <Route path="/Login" element={<Login />} />
            {/* <Route path="/Signup" element={<Signup />} /> */}
            <Route path="/Payment" element={<Payment />} />
            <Route path="/ResetPassword" element={<ResetPassword />} />
            <Route path="/ForgotPassword" element={<ForgotPassword />} />
            <Route
              path="/VerificationSuccess"
              element={<VerificationSuccess />}
            />
            <Route path="/Signup" element={<Signup_new />} />
            <Route path="/verify-otp" element={<VerifyOtp />} />
          </Routes>
        </>
      ) : (
        <>
          <Container1>
            {/* <Sidebar handleSidebar={handleSidebar} /> */}
            {location.pathname !== '/CoursePreview' && (
              <Sidebar handleSidebar={handleSidebar} />
            )}
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                flexDirection: 'column',
              }}
            >
              <Navbar collapsed={collapsed} />
              <Routes>
                {/* paths available for all users after logging in */}
                <Route path="/" element={<LandingHome />} />
                <Route path="/MyProfile" element={<MyProfile />} />
                <Route path="/Dashboard" element={<Dashboard />} />
                {/* <Route path="/CourseDetails" element={<CourseDetails />} /> */}
                {/* <Route path="/Sitebuilder" element={<Sitebuilder />} /> */}
                {/* <Route path="/ListofCourses" element={<ListofCourses />} /> */}
                {/* <Route path="/AllTemplates" element={<AllTemplates />} /> */}

                {
                  // users?.user?.userid?.role === 'Learner' ||
                  users?.user?.userid?.role === 'Admin' ||
                  users?.user?.userid?.role === 'Instructor' ||
                  users?.user?.userid?.role === 'Course Reviewer' ? (
                    <>
                      <Route
                        path="/EventsNavigator/*"
                        element={<EventsNavigator />}
                      />
                      <Route
                        path="/EventsNavigator"
                        element={<EventsNavigator />}
                      />
                      <Route path="/Modules" element={<Modules />} />
                      <Route
                        path="/CourseBuilder"
                        element={<CourseBuilder />}
                      />
                      <Route
                        path="/CourseBuilder/CourseSettings/:courseId"
                        element={<CourseSettings />}
                      />
                      <Route
                        path="/CourseBuilder/CourseDetails/:courseId"
                        element={<CourseDetails />}
                      />
                    
                      <Route
                        path="/CourseSettings/:courseId"
                        element={<CourseSettings />}
                      />
                      <Route
                        path="/CourseBuilder/CoursePlayerAppearenceSetting/:courseId"
                        element={<CoursePlayerAppearenceSetting />}
                      />
                      <Route
                        path="/CourseBuilder/CourseCertificateSettings/:courseId"
                        element={<CourseCertificateSettings />}
                      />
                      <Route
                        path="/CourseBuilder/CourseNotificationSettings/:courseId"
                        element={<CourseNotificationSettings />}
                      />
                      <Route
                        path="/CourseBuilder/CourseIDVerificationSettings/:courseId"
                        element={<CourseIDVerificationSettings />}
                      />
                      <Route
                        path="/CourseProgressCompletionSetting"
                        element={<CourseIDVerificationSettings />}
                      />
                      <Route
                        path="/CourseBuilder/CreateCourse"
                        element={<CreateCourse />}
                      />

                      <Route
                        path="/CourseBuilder/SelectCourseCard"
                        element={<SelectCourseCard />}
                      />

                      <Route
                        path="/CourseBuilder/ScromImport"
                        element={<ScromImport />}
                      />

                      <Route
                        path="/CourseBuilder/CourseDiscussion/:courseId"
                        element={<CourseDiscussion />}
                      />

                      <Route
                        path="/CourseBuilder/LandingPageDetails/:id"
                        element={<LandingPageDetails />}
                      />
                      <Route
                        path="/LearnersList/:courseId"
                        element={<LearnersList />}
                      />
                      <Route
                        path="/CourseBuilder/CourseModules/preview/:id/:courseStructure"
                        element={<Preview />}
                      />
                      <Route
                        path="/CourseBuilder/CourseModules/:id/:courseStructure"
                        element={<CourseModules />}
                      />
                      <Route
                        path="/DisplayScrom/:id"
                        element={<DisplayScrom />}
                      />
                      <Route
                        path="/CourseBuilder/copyCourse"
                        element={<CopyExistingCourse />}
                      />
                      <Route
                        path="/CourseBuilder/SelectTemplte"
                        element={<SelectTemplte />}
                      />
                      <Route
                        path="/CourseBuilder/CreateTemplte"
                        element={<CreateTemplte />}
                      />
                      <Route
                        path="/Notification/*"
                        element={<Notification />}
                      />
                      <Route path="/Certificate/*" element={<Certificate />} />

                      <Route path="/Users/*" element={<Users />} />
                      <Route
                        path="/Sitebuilder/*"
                        element={<SitebuilderRoutes />}
                      />
                      <Route
                        path="/SitebuilderB/:id"
                        element={<Sitebuilder />}
                      />
                      <Route
                        path="/CoursePageBuilder/:courseId"
                        element={<CoursePageBuilder />}
                      />
                    </>
                  ) : (
                    <>
                      <Route
                        path="/LearnerNotification/*"
                        element={<LearnerNotification />}
                      />
                      <Route
                        path="/LearnerCertificate/*"
                        element={<LearnerCertificate />}
                      />
                      <Route
                        path="/LearnersEventsNavigator/*"
                        element={<LearnersEventsNavigator />}
                      />

                      <Route
                        path="/LearnerCourses"
                        element={<LearnerCourses />}
                      />
                      {/* Temp Added  Learner Course Details Route */}
                      <Route
                        path="/LearnerCourseDetails"
                        element={<LearnerCourseDetails />}
                      />
                      <Route
                        path="/CourseBuilder"
                        element={<CourseBuilder />}
                      />
                      <Route path="/Quizzes" element={<Quizzes />} />
                      <Route
                        path="/LearnerCoursePreview/:id/:courseStructure"
                        element={<LearnerCoursePreview />}
                      />
                    </>
                  )
                }
              </Routes>
              {/* <Footer /> */}
            </div>
          </Container1>
        </>
      )}
    </>
  );
}

export default LandingHome;
