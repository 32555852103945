import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  createUserApi,
  fetchUserApi,
  updateUserApi,
  deleteUserApi,
  fetchUserDetailsApi,
  createUserByCSVApi,
  fetchUserRoleApi,

} from '../../api/UserAdministrationApi';

import axios from 'axios';

// Define the initial state
const initialState = {
  users: [],
  roles: [],
  status: 'idle',
  error: null,
};

export const createUser = createAsyncThunk(
  'createUser',
  async (UserData, { rejectWithValue }) => {
    try {
      return await createUserApi(UserData);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

//Create User By CSV
export const createUserByCsv = createAsyncThunk(
  'createUserByCsv',
  async (UserData, { rejectWithValue }) => {
    try {
      return await createUserByCSVApi(UserData);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchUser = createAsyncThunk(
  'fetchUser',
  async (data, { rejectWithValue }) => {
    try {
      return await fetchUserApi();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

//update user
export const updateUser = createAsyncThunk(
  'updateUser',
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateUserApi(data);
      console.log('upadte in sli8ce', response.user);
      return response.user;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);


export const fetchUserDetails = createAsyncThunk(
  'fetchUserDetails',
  async (id, { rejectWithValue }) => {
    try {
      const data = await fetchUserDetailsApi(id);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);
//delete action
export const deleteUser = createAsyncThunk(
  'deleteUser',
  async (id, { rejectWithValue }) => {
    try {
      console.log(id, 'id for delete');
      await deleteUserApi(id);
      return id; // Return the eventId if deletion is successful
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchRole = createAsyncThunk(
  'fetchRole',
  async (_, { rejectWithValue }) => {
    try {
      return await fetchUserRoleApi();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const userAdmistrationSlice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.status = 'idle';
        state.users.push(action.payload);
      })
      .addCase(createUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      //fetch
      .addCase(fetchUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = 'idle';
        state.users = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      //fetch user details
      .addCase(fetchUserDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        state.status = 'idle';
        state.users = action.payload;
      })
      .addCase(fetchUserDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      //update
      .addCase(updateUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.status = 'idle';
        state.users = action.payload;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      //delete
      .addCase(deleteUser.pending, (state) => {
        state.status = 'loading';
      })
      // Add a case for successful deletion
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.status = 'idle';
        const { id } = action.payload;
        if (id) {
          state.users = state.users.filter((user) => user._id !== id);
        }
      })
      // Add a case for deletion failure
      .addCase(deleteUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      //FetchRole
      .addCase(fetchRole.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchRole.fulfilled, (state, action) => {
        state.status = 'idle';
        state.roles = action.payload;
        state.error = null;
      })
      .addCase(fetchRole.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.error = null;
      });
  },
});

export default userAdmistrationSlice.reducer;
