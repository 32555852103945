// import React, { useState, useEffect } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
// import InputAdornment from '@mui/material/InputAdornment';
// import SearchIcon from '@mui/icons-material/Search';
// import TextField from '@mui/material/TextField';
// import TableUtilityLib from '../Utilities/TableUtilityLib';
// import CommonButton from '../Utilities/CommonButton';
// import { UserTable } from '../../styles/Users/UsersStyles';
// import BackButton from '../Utilities/BackButton';
// import { EventTitle, Title } from '../../styles/Calendar/CalendarStyle';
// import {
//   WholePage,
//   TableButtonDiv,
//   SeparateTableButton,
//   SearchEmail,
// } from '../../styles/Certificate/CertificateStyle';
// import {
//   Tooltip,
//   CircularProgress,
//   Button,
//   Box,
//   Checkbox,
// } from '@mui/material';
// import {
//   fetchUser,
//   fetchRole,
// } from '../../features/userModule/userModuleSlice';
// import { useDispatch, useSelector } from 'react-redux';
// import { useTranslation } from 'react-i18next';
// import { enrollUsers } from '../../features/calendar/CalendarSlice';
// import { toast } from 'react-toastify';

// const EnrolledUsers = () => {
//   const { enrolluser, eventDetails } = useSelector((state) => state.calendar);
//   const { eventName } = useParams();
//   const [selectedUsers, setSelectedUsers] = useState([]);
//   const [searchQuery, setSearchQuery] = useState('');
// const [showEnrollAll, setEnrollAll] = useState(true);
// const { users, status: userStatus } = useSelector((state) => state.users);
//   const { roles } = useSelector((state) => state.users) || {};
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const { t } = useTranslation();

//   useEffect(() => {
//     dispatch(fetchUser());
//     if (!roles.data || roles.data.length === 0) {
//       dispatch(fetchRole());
//     }
//   }, [dispatch, roles.data]);

//   const handleBack = () => {
//     navigate('/EventsNavigator');
//   };

//   const handleCheckboxChange = (row) => {
//     const isSelected = selectedUsers.includes(row);
//     if (isSelected) {
//       setSelectedUsers(selectedUsers.filter((user) => user !== row));
//     } else {
//       setSelectedUsers([...selectedUsers, row]);
//     }
//   };

// const handleEnrollAll = () => {
//   if (selectedUsers.length === filteredData.length) {
//     setSelectedUsers([]);
//   } else {
//     setSelectedUsers(filteredData);
//   }
//   setEnrollAll(!showEnrollAll);
// };

//   const filteredData = users.filter((user) => {
//     const searchTerm = searchQuery.toLowerCase();
//     const lowerCaseFirstName = user.firstname?.toLowerCase() || '';
//     const lowerCaseLastName = user.lastname?.toLowerCase() || '';
//     const lowerCaseEmail = user.email?.toLowerCase() || '';
//     const lowerCaseRole = user.role?.toLowerCase() || '';

//     return (
//       (lowerCaseFirstName.includes(searchTerm) ||
//         lowerCaseLastName.includes(searchTerm) ||
//         lowerCaseEmail.includes(searchTerm) ||
//         lowerCaseRole.includes(searchTerm)) &&
//       user.role === 'Learner'
//     );
//   });

//   const handleEnrollClick = async () => {
//     if (selectedUsers.length === 0) {
//       toast.warning('Please select at least one user to enroll.');
//       return;
//     }

//     try {
//       const emails = selectedUsers.map((user) => user.email);
//       await dispatch(
//         enrollUsers({ eventName: eventDetails.EventName, emails }),
//       );
//       toast.success('Users enrolled successfully!');
//       setTimeout(() => {
//         navigate(`/EventsNavigator/userinfo/${eventDetails.EventName}`);
//       }, 2000);
//     } catch (error) {
//       console.error('Error enrolling users:', error.message);
//       toast.error('Error enrolling users. Please try again.');
//     }
//   };

//   const columns = [
//     {
//       name: (
//         <Checkbox
//           checked={selectedUsers.length === filteredData.length}
//           onChange={handleEnrollAll}
//           inputProps={{ 'aria-label': 'select all users' }}
//         />
//       ),
//       selector: (row) => row.id, // or any unique identifier
//       cell: (row) => (
//         <Checkbox
//           checked={selectedUsers.includes(row)}
//           onChange={() => handleCheckboxChange(row)}
//           inputProps={{ 'aria-label': `select user ${row.firstname}` }}
//         />
//       ),
//     },
//     {
//       name: t('calender.firstName'),
//       selector: (row) => row.firstname,
//       sortable: true,
//     },
//     {
//       name: t('calender.lastName'),
//       selector: (row) => row.lastname,
//       sortable: true,
//     },
//     {
//       name: t('calender.Email'),
//       selector: (row) => row.email,
//       sortable: true,
//     },
//   ];

//   const handleFormSubmit = (event) => {
//     event.preventDefault();
//   };

//   return (
//     <>
//       <EventTitle>
//         <BackButton onClick={handleBack} />
//         <Title>{eventDetails?.EventName}</Title>
//       </EventTitle>
//       <WholePage>
//         <SearchEmail onSubmit={handleFormSubmit}>
//           <TextField
//             label={t('calender.searchLabel')}
//             size="small"
//             variant="outlined"
//             value={searchQuery}
//             onChange={(e) => setSearchQuery(e.target.value)}
//             sx={{ width: '300px', marginBottom: '10px', marginLeft: '20px' }}
//             InputProps={{
//               startAdornment: (
//                 <InputAdornment position="start">
//                   <SearchIcon />
//                 </InputAdornment>
//               ),
//             }}
//           />
//           <TableButtonDiv>
//             <Tooltip title="Enroll User for the Event">
//               <SeparateTableButton>
//                 <CommonButton
//                   label={t('calender.enrollText')}
//                   onClick={handleEnrollClick}
//                   disabled={userStatus === 'loading'}
//                 />
//               </SeparateTableButton>
//             </Tooltip>
// <Tooltip title="Select/Deselect All Users">
//   <Box sx={{ marginLeft: '10px' }}>
//     {showEnrollAll ? (
//       <CommonButton
//         label={t('calender.enrollAllText')}
//         onClick={handleEnrollAll}
//         disabled={userStatus === 'loading'}
//       />
//     ) : (
//       <CommonButton
//         label={t('calender.unenrollText')}
//         onClick={handleEnrollAll}
//         disabled={userStatus === 'loading'}
//       />
//     )}
//   </Box>
// </Tooltip>
//           </TableButtonDiv>
//         </SearchEmail>

//         {userStatus === 'loading' ? (
//           <div style={{ textAlign: 'center', marginTop: '20px' }}>
//             <CircularProgress />
//           </div>
//         ) : (
//           <UserTable>
//             <TableUtilityLib
//               title={`${t('calender.enrolleventdescriptionLabel')} ${eventDetails?.EventName}:`}
//               columns={columns}
//               data={filteredData}
//               onRowClicked={handleCheckboxChange}
//               highlightOnHover
//               customStyles={{
//                 headRow: {
//                   style: {
//                     backgroundColor: '#f5f5f5',
//                     borderBottom: '2px solid #ddd',
//                     fontWeight: 'bold',
//                   },
//                 },
//                 rows: {
//                   style: {
//                     cursor: 'pointer',
//                     '&:nth-of-type(odd)': {
//                       backgroundColor: '#f9f9f9',
//                     },
//                     '&:nth-of-type(even)': {
//                       backgroundColor: '#ffffff',
//                     },
//                     '&:hover': {
//                       backgroundColor: '#f0f0f0',
//                     },
//                   },
//                 },
//               }}
//             />
//           </UserTable>
//         )}
//       </WholePage>
//     </>
//   );
// };

// export default EnrolledUsers;

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@mui/material/TextField';
import TableUtilityLib from '../Utilities/TableUtilityLib';
import CommonButton from '../Utilities/CommonButton';
import { UserTable } from '../../styles/Users/UsersStyles';
import BackButton from '../Utilities/BackButton';
import { EventTitle, Title } from '../../styles/Calendar/CalendarStyle';
import {
  WholePage,
  TableButtonDiv,
  SeparateTableButton,
  SearchEmail,
} from '../../styles/Certificate/CertificateStyle';
import { Box, Tooltip } from '@mui/material';
import {
  fetchUser,
  fetchRole,
} from '../../features/userModule/userModuleSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { enrollUsers } from '../../features/calendar/CalendarSlice';
import { toast } from 'react-toastify';
import Footer from '../Landing/Footer';

const EnrolledUsers = () => {
  const { enrolluser, eventDetails, userstats } = useSelector(
    (state) => state.calendar,
  );

  const [showEnrollAll, setEnrollAll] = useState(true);
  const { eventName } = useParams();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { users, status: userStatus } = useSelector((state) => state.users);
  const [searchQuery, setSearchQuery] = useState('');
  const { roles } = useSelector((state) => state.users) || {};

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  useEffect(() => {
    if (!roles.data || roles.data.length === 0) {
      dispatch(fetchRole());
    }
  }, [dispatch, roles.data]);

  const handleBack = () => {
    navigate('/EventsNavigator');
  };

  const filteredData = Array.from(users).filter((user) => {
    const searchTerm = searchQuery.toLowerCase();
    const lowerCaseFirstName = user.firstname
      ? user.firstname.toLowerCase()
      : '';
    const lowerCaseLastName = user.lastname ? user.lastname.toLowerCase() : '';
    const lowerCaseEmail = user.email ? user.email.toLowerCase() : '';
    const lowerCaseRole = user.role ? user.role.toLowerCase() : '';

    return (
      (lowerCaseFirstName.includes(searchTerm) ||
        lowerCaseLastName.includes(searchTerm) ||
        lowerCaseEmail.includes(searchTerm) ||
        lowerCaseRole.includes(searchTerm)) &&
      user.role === 'Learner'
    );
  });

  const handleEnrollClick = async () => {
    if (selectedUsers.length === 0) {
      toast.warning('Please select at least one user to enroll.');
      return;
    }

    try {
      const emails = selectedUsers.map((user) => user.email);
      await dispatch(
        enrollUsers({ eventName: eventDetails.EventName, emails }),
      );

      toast.success('Users enrolled successfully!');
      setTimeout(() => {
        navigate(`/EventsNavigator/userinfo/${eventDetails.EventName}`);
      }, 2000);
    } catch (error) {
      console.error('Error enrolling users:', error.message);
      toast.error('Error enrolling users. Please try again.');
    }
  };

  const handleRowSelected = ({ selectedRows }) => {
    setSelectedUsers(selectedRows);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
  };

  const handleEnrollAll = () => {
    if (selectedUsers.length === filteredData.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(filteredData);
    }
    setEnrollAll(!showEnrollAll);
  };

  const columns = [
    {
      name: t('calender.firstName'),
      selector: (row) => row.firstname,
      sortable: true,
    },
    {
      name: t('calender.lastName'),
      selector: (row) => row.lastname,
      sortable: true,
    },
    {
      name: t('calender.Email'),
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: 'Enrollment',
      selector: (row) => {
        // Check if the user is already enrolled in the event
        return row.events.includes(eventDetails.EventName)
          ? 'Registered'
          : 'Unregistered';
      },
      sortable: true,
    },
  ];

  return (
    <>
      <EventTitle>
        <BackButton onClick={handleBack} />
        <Title>{eventDetails?.EventName}</Title>
      </EventTitle>
      <WholePage>
        <br />
        <SearchEmail onSubmit={handleFormSubmit}>
          <TextField
            label={t('calender.searchLabel')}
            size="small"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ width: '300px', marginBottom: '3px', marginLeft: '20px' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          <TableButtonDiv>
            <Tooltip title="Enroll User for the Event">
              <SeparateTableButton>
                <CommonButton
                  label={t('calender.enrollText')}
                  onClick={handleEnrollClick}
                />
              </SeparateTableButton>
            </Tooltip>
            <Tooltip title="Select/Deselect All Users">
              <Box sx={{ marginLeft: '10px' }}>
                {showEnrollAll ? (
                  <CommonButton
                    label={t('calender.enrollAllText')}
                    onClick={handleEnrollAll}
                    disabled={userStatus === 'loading'}
                  />
                ) : (
                  <CommonButton
                    label={t('calender.unenrollText')}
                    onClick={handleEnrollAll}
                    disabled={userStatus === 'loading'}
                  />
                )}
              </Box>
            </Tooltip>
          </TableButtonDiv>
        </SearchEmail>

        <UserTable>
          <TableUtilityLib
            title={`${t('calender.enrolleventdescriptionLabel')} ${eventDetails.EventName}:`}
            columns={columns}
            data={filteredData}
            selectableRows={true}
            selectableRowsHighlight={true}
            onSelectedRowsChange={handleRowSelected}
          />
        </UserTable>
      </WholePage>
      <Footer />
    </>
  );
};

export default EnrolledUsers;
