

import React, { useMemo } from "react";
import { TextField,Tooltip,IconButton, Grid, Typography, InputAdornment, Button} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { palette } from "../../../styles/palette";
import TableUtilityLib from "../../Utilities/TableUtilityLib";

const UploadExam = ({ onClose }) => {
  const handleOnClose = () => {
    onClose();
  };
  const columns = useMemo(() => [

    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
      center: true, // Center-align text
      cellStyle: { fontSize: '16px' },    },
    
  ], []);
    const data = [
      // {name: ""}
    ];
  return (
    <div>
<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: '10px' }}>
  {/* Grid container aligned to the left */}
  <Grid container alignItems="center" spacing={1} sx={{mt:1, mb:2}}>
    <Grid item>
      <Tooltip title="Go Back">
        <IconButton
          size="small"
          onClick={handleOnClose}
          sx={{ color: `${palette.THEME_THREE}` }}
        >
          <ArrowBackIosIcon />
        </IconButton>
      </Tooltip>
    </Grid>
    <Grid item sx={{mt:1}}>
      <Typography variant="h5" gutterBottom>
        Exam
      </Typography>
    </Grid>
  </Grid>

  {/* Buttons div aligned to the right */}
  <div style={{ display: "flex", alignItems: "center" }}>
    <Button variant="outlined" style={{ color: "#0684FB", borderColor: "#ccc", marginRight: "10px", width: "180px" }}>Discard Changes</Button>
    <Button variant="contained" style={{ backgroundColor: "#0684FB", color: "white", borderColor: "#ccc" }}>Save</Button>
  </div>
</div>
<div style={{ marginRight: "10px", marginLeft: "10px" }}>
        <TextField
          variant="outlined"
          placeholder="Search"
          style={{ width: "100%", marginBottom: "10px"}}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <TableUtilityLib columns={columns} data={data} />
    
          <div style={{ position: "fixed", bottom: "30px", right: "30px" }}>
          <Tooltip title="Create new exam">
          <IconButton
        color="primary"
        style={{ backgroundColor: "#0684FB", borderRadius: "50%", color:'white'}}
      >
        <AddIcon style={{ fontSize: 30 }}  />
      </IconButton>
      </Tooltip>
      </div>

    </div>
  );
};

export default UploadExam;


