import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  TextField,
  Chip,
  Button,
  Box,
  Tooltip,
  DialogActions,
} from '@mui/material';
import { Autocomplete } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

import {
  addLesson,
  fetchCourseInSlice,
  fetchCourseDetailsForCopyInSlice,
} from '../../../features/courses/addCourseDetailsSlice';
import { MainContainer } from '../../../styles/Courses/CourseModuleStyles';

import JoditReact from 'jodit-react';

const AddChapterDialog = ({ moduleId, onClose }) => {
  const [selectedValue, setSelectedValue] = useState('');
  const [copyLesson, setCopyLesson] = useState([]);
  const [selectedLessons, setSelectedLessons] = useState([]);
  const [selectedOption, setSelectedOption] = useState('create');
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedModules, setSelectedModules] = useState([]);

  const [LessonsDetail, setLessonsDetail] = useState({
    moduleId: '',
    id: '',
    title: '',
    courseStructure: '',
    description: '',
    number: '',
    EstTime: '',
    items: [],
    preview: true,
  });

  const [isTitleValid, setIsTitleValid] = useState(false);

  const refs = {
    title: useRef(),
  };

  const dispatch = useDispatch();
  const { id, courseStructure } = useParams();
  const courses = useSelector((state) => state.addCourseDetails.coursesList);
  const courseDetails = useSelector(
    (state) => state.addCourseDetails.courseDetails,
  );
  const courseDetailsForCopy = useSelector(
    (state) => state.addCourseDetails.courseDetailsForCopy,
  );
  useEffect(() => {
    dispatch(fetchCourseInSlice());
  }, [dispatch]);

  const handleModuleNameChange = (e) => {
    const { name, value } = e.target;

    if (name === 'title') {
      setIsTitleValid(!!value.trim()); // Check if title is valid (non-empty)
    }

    if (courseStructure == 'CMLT') {
      setLessonsDetail((prevState) => ({
        ...prevState,
        id: generateRandomNumber(),
        moduleId: moduleId,
        courseStructure: courseStructure,
        [name]: value,
      }));
    } else if (courseStructure == 'CLT') {
      setLessonsDetail((prevState) => ({
        ...prevState,
        id: generateRandomNumber(),
        courseStructure: courseStructure,
        [name]: value,
      }));
    } else {
      console.log('Please Select Correct Course Structure');
      alert('Please Select Correct Course Structure');
      onClose();
    }
  };

  const generateRandomNumber = () => {
    const min = 100000;
    const max = 999999;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const handleSave = async () => {
    console.log(selectedLessons, 'copy model');

    if (selectedOption === 'copy') {
      await dispatch(addLesson(selectedLessons));
    } else {
      await dispatch(addLesson(LessonsDetail));
    }
    onClose();
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setSelectedLessons([]);
    setCopyLesson([]);
  };

  const handleCourseSelect = (event, value) => {
    console.log(value, 'handleCourseSelect');
    setSelectedCourse(value);
    if (value) {
      dispatch(fetchCourseDetailsForCopyInSlice(value.id));
    }
  };

  const handleModuleSelect = (value) => {
    console.log(value, 'handleModuleSelect');

    const modifiedValue = value.map((moduleObj) => ({
      ...moduleObj,
      module: {
        ...moduleObj.module,
        id: generateRandomNumber(),
        title: 'Copied ' + moduleObj.module.title,
      },
    }));

    setSelectedModules(value);
  };

  const handleCopyLessonChange = (event, value) => {
    console.log(value, 'handleCopyLessonChange');
    setCopyLesson(value);
    const modifiedValue = value.map((lessonObj) => ({
      ...lessonObj,
      title: 'Copied ' + lessonObj.title,
      id: generateRandomNumber(),
      courseStructure: courseStructure,
    }));
    setSelectedLessons(modifiedValue);
  };

  const handleRemoveLesson = (lesson) => {
    setSelectedLessons((prevSelectedLessons) =>
      prevSelectedLessons.filter((item) => item !== lesson),
    );
    setCopyLesson((prevCopyLesson) =>
      prevCopyLesson.filter((item) => item !== lesson),
    );
  };

  return (
    <MainContainer>
      {/* Radio buttons and close icon in the same row */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}
      >
        {/* Radio Buttons on the Left */}
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="moduleOption"
            name="moduleOption"
            value={selectedOption}
            onChange={handleOptionChange}
            sx={{ flexDirection: 'row' }}
          >
            <FormControlLabel
              value="create"
              control={<Radio />}
              label="Create Lesson"
            />
            <FormControlLabel
              value="copy"
              control={<Radio />}
              label="Copy Lesson"
            />
          </RadioGroup>
        </FormControl>

        {/* Close and Save Icons on the Right */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <SaveIcon
            onClick={handleSave}
            sx={{
              cursor: 'pointer',
              backgroundColor: '#0684FB',
              color: 'white',
              borderRadius: '50%',
              padding: '8px',
              '&:hover': { backgroundColor: '#045cbf' },
            }}
          />

          <Tooltip title="Close">
            <CloseIcon
              onClick={onClose}
              sx={{ cursor: 'pointer', ':hover': { color: 'red' } }}
            />
          </Tooltip>
        </Box>
      </Box>

      <div
        style={{ borderBottom: '1px solid grey', marginBottom: '16px' }}
      ></div>

      {/* Create Lesson Section */}
      {selectedOption === 'create' && (
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12}>
            <Typography style={{ marginBottom: '8px', marginTop: '22px' }}>
              Lesson Title
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Enter Lesson Name"
              variant="outlined"
              fullWidth
              name="title"
              value={LessonsDetail.title}
              onChange={handleModuleNameChange}
              required
              inputRef={refs.title}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography>Learning Objectives</Typography>
          </Grid>
          <Grid item xs={12}>
            <JoditReact value={LessonsDetail.objectives} config={{}} />
          </Grid>
        </Grid>
      )}

      {/* Copy Lesson Section */}
      {selectedOption === 'copy' && (
        <>
          <FormControl fullWidth style={{ marginTop: '16px' }}>
            <Autocomplete
              options={courses.map((course) => ({
                title: course.generalInformation.title,
                id: course._id,
              }))}
              value={selectedCourse}
              onChange={handleCourseSelect}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Course"
                  variant="outlined"
                />
              )}
            />
          </FormControl>

          {selectedCourse && (
            <>
              <FormControl fullWidth style={{ marginTop: '16px' }}>
                <Autocomplete
                  multiple
                  options={courseDetailsForCopy?.modules?.map((module) => ({
                    module,
                  }))}
                  value={selectedModules.module}
                  onChange={(event, value) => handleModuleSelect(value)}
                  getOptionLabel={(option) => option?.module?.title}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Module"
                      variant="outlined"
                    />
                  )}
                />
              </FormControl>

              <div
                style={{ marginTop: '16px', display: 'flex', flexWrap: 'wrap' }}
              >
                {selectedModules?.module?.map((module) => (
                  <Chip
                    key={module?.title}
                    label={module?.title}
                    onDelete={() => handleRemoveModule(module)}
                    style={{ marginRight: '8px', marginBottom: '8px' }}
                  />
                ))}
              </div>

              {selectedModules?.length > 0 && (
                <FormControl fullWidth style={{ marginTop: '16px' }}>
                  <Autocomplete
                    multiple
                    options={selectedModules
                      ?.map((module) => module?.module.lessons || [])
                      .flat()}
                    getOptionLabel={(option) => option.title}
                    value={copyLesson}
                    onChange={handleCopyLessonChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Lesson"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              )}

              <div
                style={{ marginTop: '16px', display: 'flex', flexWrap: 'wrap' }}
              >
                {copyLesson.map((lesson) => (
                  <Chip
                    key={lesson.title}
                    label={lesson.title}
                    onDelete={() => handleRemoveLesson(lesson)}
                    style={{ marginRight: '8px', marginBottom: '8px' }}
                  />
                ))}
              </div>
            </>
          )}
        </>
      )}

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          disabled={!isTitleValid}
        >
          Submit
        </Button>
      </DialogActions>
    </MainContainer>
  );
};

export default AddChapterDialog;
