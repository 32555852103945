import styled from 'styled-components';
import tw from 'twin.macro';
import { palette } from '../palette';

export const SearchCont = styled.div`
  ${tw`flex justify-center items-center m-8 w-[60%] `}
`;

export const CreatePageBtnCont = styled.div`
  ${tw`flex items-center justify-end m-8 `}
`;

export const Container1 = styled.div`
  ${tw`flex  items-center justify-between mt-[1%]`}
  background: none;
`;

export const NotFoundMatchCont = styled.div`
  ${tw`flex justify-center items-center w-[70vw] h-[50vh] text-gray-400 font-medium`}
`;

export const FilterBtn = styled.button`
  ${tw` flex justify-center items-center gap-4  h-[3.5rem]  rounded-b rounded-t text-center cursor-pointer`}
  border: 1px solid ${palette.gray};
  color: ${palette.lightGray};
  border-radius: 4px;
  padding: 10px 14px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
  min-width: 400px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  &:hover {
    border-color: black;
  }
  &:focus {
    outline: none;
  }
  .icon {
    color: ${palette.primary};

    border-radius: 4px;
    width: 2.5rem;
    height: 2.5rem;
  }
`;

export const TextFieldStyle = {
  marginRight: '20px',
  minWidth: '40%',
};
