import { createTemplate, deleteTemplate, getDefaultPages } from '../../features/sitebuilder/SitebuilderSlice';
// code 2
import bg from '../../images/heroBG.jpg'
import { truncateText } from '../Utilities/TextAppendEllipsis';
import React, { useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
import SiteBuilderTabs from './SiteBuilderTabs';
import { useDispatch, useSelector } from 'react-redux';
import SitebuilderRoutes from './SitebuilderRoutes';
import Loader from '../Utilities/Loader';
import Sitebuilder from './Sitebuilder';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import FilterListIcon from '@mui/icons-material/FilterList';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CloseIcon from '@mui/icons-material/Close';
import bgImage from '../../images/SiteHeroBG.png';
import {
  Menu,
  Tooltip,
  Container,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  CardActions,
  CardContent,
  Typography,
  Grid,
  CircularProgress,
  Button,
  Modal,
  Box,
  TextField,
  Card,
  IconButton,
} from '@mui/material';
import {
  Edit,
  Delete,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import { palette } from '../../styles/palette';
import { useNavigate } from 'react-router-dom';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import {
  SearchCont,
  CreatePageBtnCont,
  Container1,
  NotFoundMatchCont,
  FilterBtn,
  text1,
} from '../../styles/Sitebuilder/UserSavedTemplatesStyle';
import { getAllTemplates } from '../../features/sitebuilder/SitebuilderSlice';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import CommonButton from '../Utilities/CommonButton';

const DefaultPageTemplate = () => {
  const [loading, setLoading] = useState(false);
  const [searchToggle, setSearchToggle] = useState(false);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [pageName, setPageName] = useState('');
  const [pageType, setPageType] = useState('');
  const [title, setTitle] = useState('');
  const [templates, setTemplates] = useState('');
  const [previewImage, setPreviewImage] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const navigate = useNavigate();
  const [rendered, setRendered] = useState(0); //for redering filter data
  const API_URL = process.env.REACT_APP_URL;
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const dispatch = useDispatch();

  //------------------------------------------------------------------copying
  const [copyModalIsOpen, setCopyModalIsOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState([]);
  const [newPageName, setNewPageName] = useState('');
  const [newPageType, setNewPageType] = useState('');
  const [changedTemplateID, setChangedTemplateID] = useState('');

  const { defaultPages, status, error } = useSelector(
    (state) => state.userTemplates,
  );

  const truncatedTitleFunction = (title) => {
    return truncateText(title, 20);
  };

  const [searchInput, setSearchInput] = useState('');
  const [dropdownSelection, setDropdownSelection] = useState('');

  console.log('all temp 1:', defaultPages);

  // this a value container
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchor, setAnchor] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [selectedPage, setSelectedPage] = useState([]);
  const [pageInputValue, setPageInputValue] = useState('');
  const [inputValue, setInputValue] = useState([]);
  const [selectedType, setSelectedType] = useState(''); // New state for selected type
  const isOpen = Boolean(anchorEl);
  const isPageTypeOpen = Boolean(anchor);

  const filterOptions = [

  


    // t('sitebuilder.Coursepage'),
    // t('sitebuilder.Coursepage'),
    // t('sitebuilder.Coursepage'),
    {value:"Custom page",label:t('sitebuilder.Custom page')},
    {value:"Bundle page",label:t('sitebuilder.Bundle page')},
    {value:"Default page",label:t('sitebuilder.Default page')},
    {value:"Published Pages",label:t('sitebuilder.Published Pages')},
    {value:"Homepage",label:t('sitebuilder.Homepage')},
    {value:"Course page",label:t('sitebuilder.Course page')},
    



  ];

  const pageOptions = [
    {value:"Custom page",label:t('sitebuilder.Custom page')},
    {value:"Bundle page",label:t('sitebuilder.Bundle page')},
    {value:"Default page",label:t('sitebuilder.Default page')},
    {value:"Homepage",label:t('sitebuilder.Homepage')},
    {value:"Course page",label:t('sitebuilder.Course page')},
  ];

  //------------------------------------------------copying--------------------------------------------------------
  const openModal = (template) => {
    setCopyModalIsOpen(true);

    setSelectedTemplate(template);
    console.log(
      'hello from close',
      selectedTemplate,
      'hello xusssdsdsds',
      template,
    );
    console.log('hello template abc', template);

    setSelectedTemplate(template);
  };

  const closeModal = () => {
    setCopyModalIsOpen(false);
  };

  const handleCreate = async () => {
    const username = 'current user'; //remind to change this further
    const pageTemplate = selectedTemplate.pageTemplate;
    const htmlTemplate = selectedTemplate.htmlTemplate;
    const cssTemplate = selectedTemplate.cssTemplate;
    const pageName = newPageName;
    const pageType = newPageType;
    const copyTemplate = {
      pageName,
      pageType,
      categoryId: 'UserSavedPages',
      pageTemplate: pageTemplate,
      htmlTemplate: htmlTemplate,
      cssTemplate: cssTemplate,
      userName: username,
      previewImage: 'image_url', // Add this line if previewImage is coming from the backend
    };
    try {
     const newTemplate = await dispatch(createTemplate(copyTemplate));
closeModal();
      navigate(`/SiteBuilderB/${newTemplate.payload._id}`);
    } catch (error) {
      console.error('Error adding template:', error);
    }
  };

  // -------------------------------------------copying-----------------------
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePageTypeClick = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleClosed = () => {
    setAnchorEl(null);
  };
  const handleMenuClosed = () => {
    setAnchor(null);
  };

  const handlePageChange = (event) => {
    setSelectedPage(event.target.value);
    setPageType(event.target.value);
    console.log(event.target.value);
  };

  const handleFilterChange = (event) => {
    const value = event.target.value;
    console.log('checking ', value);

    const newFilters = selectedFilter.includes(value)
      ? selectedFilter.filter((item) => item !== value)
      : [...selectedFilter, value];

    setSelectedFilter(newFilters);

    console.log(newFilters);

    // Set the selected value to selectedType
  };
  // til this

  // Example selected filters

  const filteredExample = defaultPages.filter((template) =>
    selectedFilter.includes(template.pageType),
  );

  const filteredTemplates = defaultPages.filter((template) => {
    const matchesSearchInput =
      template.categoryId.toLowerCase().includes(searchInput.toLowerCase()) ||
      template.pageName.toLowerCase().includes(searchInput.toLowerCase()) ||
      template.pageType?.toLowerCase().includes(searchInput.toLowerCase());
    const matchesDropdownSelection =
      dropdownSelection === '' || template.title === dropdownSelection;

    return matchesSearchInput && matchesDropdownSelection;
  });
  const sortedTemplates = filteredTemplates.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
  );

  const handleEditClick = (id) => {
    navigate(`/SiteBuilderB/${id}`);
  };
  const totalTemplates =
  filteredExample.length > 0
    ? filteredExample.length
    : sortedTemplates.length;
const templatesToDisplay =
  filteredExample.length > 0 ? filteredExample : sortedTemplates;
  useEffect(() => {
    dispatch(getDefaultPages());

    console.log('after dispatch:', defaultPages);
  }, [dispatch, selectedFilter, selectedTemplate]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Delete Templates:
  const handleDeleteTemplate = async (id) => {
   dispatch(deleteTemplate(id));
  };

  const handlePreviewOpen = (template) => {
    setPreviewImage(template.previewImage);
    setPreviewOpen(true);
  };
  const handlePreviewClose = () => {
    setPreviewOpen(false);
    setPreviewImage('');
  };
  const handlePaginationPageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1); // Reset to the first page
  };
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTemplates = templatesToDisplay.slice(
    indexOfFirstItem,
    indexOfLastItem,
  );
  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${bg})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover', // Ensures the image covers the entire box
          backgroundPosition: 'center', // Centers the image
          width: '100%', // Adjust as needed
          height: 'auto', // Full viewport height, adjust as needed
        }}
      >
        <SiteBuilderTabs />
        {/* -----------------------new---------------------------------- */}
        <section className="course-builder">
          <Container fluid>
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12} md={5} sm={12}>
                <TextField
                  label="Search Templates"
                  variant="outlined"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  style={{
                    marginRight: '20px',
                    minWidth: 400,
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                  }}
                />
              </Grid>
              <Grid item xs={12} md={5} sm={12} sx={{ display: 'flex' }}>
                <FilterBtn onClick={handleClick}>
                  {`Filter ${selectedFilter.length > 0 ? `(${selectedFilter.length})` : ''}`}
                  <FilterListIcon className="icon" />
                </FilterBtn>
                <Menu
                  anchorEl={anchorEl}
                  open={isOpen}
                  onClose={handleClosed}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                >
                 <FormGroup>
                    {filterOptions.map((option) => (
                      <MenuItem key={option}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={option.value}
                              checked={selectedFilter.includes(option.value)}
                              onChange={handleFilterChange}
                            />
                          }
                          label={option.label}
                        />
                      </MenuItem>
                    ))}
                  </FormGroup>
                </Menu>
              </Grid>
              <Grid
                item
                xs={12}
                md={2}
                sm={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                
              </Grid>

              {filteredExample.length > 0 ? (
                filteredExample.map((template) => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5}
                    lg={4}
                    xl={3}
                    key={template._id}
                  >
                    <Box
                      className="course-card"
                      sx={{
                        width: '100%', // Use a string for percentage values
                        height: '350px', // Fixed height, use a string for pixel values
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', // camelCase for boxShadow
                        transition: '0.3s', // Use a string for transition duration
                        cursor: 'pointer', // Use a string for cursor value
                        display: 'flex',
                        background:'#f3f8f1',
                        flexDirection: 'column', // camelCase for flexDirection
                        justifyContent: 'space-between', // camelCase for justifyContent
                        '&:hover': {
                          transform: 'scale(1.05)', // Use a string for transform values
                        },
                      }}
                    >
                      <Tooltip title={template.pageName}>
                        
                        <div className="image-container">
                          <div
                            className="blurbg"
                            style={{
                              background: template?.previewImage
                                ? `url(${template.previewImage})`
                                : 'skyblue',
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              color: `${palette.white}`,
                              fontWeight: 'bold',
                              fontSize: '1.5em',
                            }}
                          >
</div>
                        </div>
                        
                      </Tooltip>
                      <CardContent>
                        <Grid item xs={10}>
                         
                          <Tooltip title={template.pageName}>
                            <Typography variant="body1" fontWeight="bold">
                              {truncatedTitleFunction(template.pageName)}
                            </Typography>
                          </Tooltip>
                          
                        </Grid>

                        <Grid item xs={12}>
                          <Typography
                            variant="subtitle1"
                            color="textSecondary"
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Tooltip title="Page Type"></Tooltip>
                            {template.pageType
                              ? template.pageType
                              : template.title}
                          </Typography>
                        </Grid>
                      </CardContent>
                      <Divider />
                      <CardActions
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Grid
                          item
                          xs={3}
                          sx={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Tooltip title="Details">
                            <IconButton
                              onClick={() => handlePreviewOpen(template)}
                              sx={{
                                color: 'gray', // Default color (shade of gray)
                                transition: 'color 0.3s ease', // Smooth transition for color change
                                '&:hover': {
                                  color: palette.primary, // Color from your palette on hover
                                },
                              }}
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sx={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Tooltip title="Course Settings">
                            <IconButton
                              color="primary"
                              onClick={() => handleEditClick(template._id)}
                            >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sx={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Tooltip title="Landing Page Design">
                            <IconButton
                              color="primary"
                              onClick={() => handleDeleteTemplate(template._id)}
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        </Grid>

                        <Grid
                          item
                          xs={3}
                          sx={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Tooltip title="Landing Page Design">
                          {template.isPublished && (
    <IconButton color="primary" onClick={() => handleShare(template)}>
      <ShareIcon />
    </IconButton>
  )}
                          </Tooltip>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sx={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Tooltip title="Landing Page Design">
                            <IconButton
                              color="primary"
                              onClick={() => openModal(template)}
                            >
                              <FileCopyIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </CardActions>
                    </Box>
                  </Grid>
                ))
              ) : filteredTemplates.length === 0 ? (
                <Typography variant="h6" style={{ margin: '20px auto' }}>
                  <NotFoundMatchCont>Match not found!</NotFoundMatchCont>
                </Typography>
              ) : (
                sortedTemplates.map((template) => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5}
                    lg={4}
                    xl={3}
                    key={template._id}
                  >
                    <Box
                      className="course-card"
                      sx={{
                        width: '100%', // Use a string for percentage values
                        height: '350px', // Fixed height, use a string for pixel values
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', // camelCase for boxShadow
                        transition: '0.3s', // Use a string for transition duration
                        cursor: 'pointer', // Use a string for cursor value
                        display: 'flex',
                        background:'#f3f8f1',
                        flexDirection: 'column', // camelCase for flexDirection
                        justifyContent: 'space-between', // camelCase for justifyContent
                        '&:hover': {
                          transform: 'scale(1.05)', // Use a string for transform values
                        },
                      }}
                    >
                      <Tooltip title={template.pageName}>
                        {/* <Link onClick={() => handleNavigate(course)}> */}
                        <div className="image-container">
                          <div
                            className="blurbg"
                            style={{
                              background: template?.previewImage
                                ? `url(${template.previewImage})`
                                : 'skyblue',
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              color: `${palette.white}`,
                              fontWeight: 'bold',
                              fontSize: '1.5em',
                            }}
                          >

                          </div>
                        </div>
                       </Tooltip>
                      <CardContent>
                        <Grid item xs={10}>
                          <Tooltip title={template.pageName}>
                            <Typography variant="body1" fontWeight="bold">
                              {truncatedTitleFunction(template.pageName)}
                            </Typography>
                          </Tooltip>
                         </Grid>

                        <Grid item xs={12}>
                          <Typography
                            variant="subtitle1"
                            color="textSecondary"
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Tooltip title="Page Type"></Tooltip>
                            {template.pageType
                              ? template.pageType
                              : template.title}
                          </Typography>
                        </Grid>
                      </CardContent>
                      <Divider />
                      <CardActions
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Grid
                          item
                          xs={3}
                          sx={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Tooltip title="Details">
                            <IconButton
                              onClick={() => handlePreviewOpen(template)}
                              sx={{
                                color: 'gray', // Default color (shade of gray)
                                transition: 'color 0.3s ease', // Smooth transition for color change
                                '&:hover': {
                                  color: palette.primary, // Color from your palette on hover
                                },
                              }}
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sx={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Tooltip title="Course Settings">
                            <IconButton
                              color="primary"
                              onClick={() => handleEditClick(template._id)}
                            >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sx={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Tooltip title="Landing Page Design">
                            <IconButton
                              color="primary"
                              onClick={() => handleDeleteTemplate(template._id)}
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sx={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Tooltip title="Landing Page Design">
                            <IconButton
                              color="primary"
                              onClick={() => openModal(template)}
                            >
                              <FileCopyIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </CardActions>
                    </Box>
                  </Grid>
                ))
              )}
            </Grid>
          </Container>
        </section>

        {/* -----------------------new---------------------------------- */}
{/* <----------------------copy model---------------------> */}
<Modal open={copyModalIsOpen} onClose={closeModal}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        <h2>Copy Template</h2>
        <TextField
          label="New Template Name"
          variant="outlined"
          fullWidth
          value={newPageName}
          onChange={(e) => setNewPageName(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          select
          label="Select Page Type"
          variant="outlined"
          fullWidth
          value={newPageType}
          onChange={(e) => setNewPageType(e.target.value)}
          sx={{ mb: 2 }}
        >
          {pageOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleCreate}
          sx={{ mt: 2 }}
        >
          Create
        </Button>
        <Button
          variant="outlined"
          fullWidth
          onClick={closeModal}
          sx={{ mt: 1 }}
        >
          Cancel
        </Button>
      </Box>
    </Modal>




{/* <----------------------copy model---------------------> */}
       
{/* <-----------------------preview model------------------------------> */}
        <Modal open={previewOpen} onClose={handlePreviewClose}>
          <Box
            sx={{
              height: '88vh',
              width: '90vw',
              position: 'absolute',
              top: '2%',
              left: '5%',
             bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography variant="h6" component="h2">
              Template Preview
            </Typography>
            <img
              src={previewImage}
              style={{ width: '90vw', height: '80vh', marginTop: '20px' }}
            />
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default DefaultPageTemplate;
