import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import tw from 'twin.macro';
import { palette } from '../palette';
import { Text } from '../../styles/Common/CommonStyles';

export const Page = styled.div`
  ${tw`mt-4 m-auto w-11/12`}
`;

export const List = styled.ul`
  padding: 0px;
`;

export const View = styled.div`
${tw`mb-2`}
`; 

export const ListItem = styled.li`
  ${tw`flex flow-root justify-between items-center px-4 rounded  mb-2`}
  border: 1px solid ${palette.gray};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow:
      rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
      rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  }
`;

export const Content = styled.div``;

export const Label = styled(Text)`
  ${tw`font-bold`}
`;

export const Subject = styled(Text)`
  ${``}
`;

export const EditButton = styled.button`

`;

export const SearchInput = styled(TextField)`
  && {
  
  }
`;

