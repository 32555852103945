import React, { useState, useEffect } from 'react';
import { PageContainer } from '../../styles/Dashboard/DashboardStyles';
import {
  Card,
  CardContent,
  Grid,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import DisplayTableLib from '../Utilities/TableUtilityLib';
import Chart from 'react-apexcharts';
import { ImageTag } from '../../styles/Calendar/CalendarStyle';
import memberDetails from '../../store/DashboardMemberDetails.json';
import sellingDetails from '../../store/DashboardSellingCourseData.json';
import instructorDetails from '../../store/DashboardInstructorDetials.json';
import { useTranslation } from 'react-i18next';
import UpcomingEventCard from './UpcomingEventCard';
import moment from 'moment';
import axios from 'axios';

const URL = process.env.REACT_APP_URL;

const AdminDashboard = () => {
  const [selectedValue, setSelectedValue] = useState('daily');
  const handleSelectChange = (e) => {
    setSelectedValue(e.target.value);
  };
  const [totalLearner, setTotalLearner] = useState(0);

  const [activeLearners, setActiveLearner] = useState(0);

  const [allCoursesCount, setAllCoursesCount] = useState(0);

  const [allEvents, setAllEvents] = useState([]);

  const [allNotification, setAllNotification] = useState([]);

  const teachersDetailsColumn = [
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Subject',
      selector: (row) => row.subject,
      sortable: true,
    },
    {
      name: 'Qualification',
      selector: (row) => row.qualification,
      sortable: true,
    },
    {
      name: 'Fees',
      selector: (row) => row.fees,
      sortable: true,
    },
    {
      name: 'Performance',
      selector: (row) => row.performance,
      sortable: true,
    },
  ];

  const memberDetailsColumns = [
    {
      name: 'Username',
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: 'Courses',
      selector: (row) => row.courses,
      sortable: true,
    },
    {
      name: 'Enrolled',
      selector: (row) => row.enrolled,
      sortable: true,
    },
    {
      name: 'Progress',
      selector: (row) => row.progress,
      sortable: true,
    },
    {
      name: 'Rating',
      selector: (row) => row.rating,
      sortable: true,
    },
  ];

  const topCoursesColumns = [
    {
      name: 'Event Name',
      selector: (row) => row.courseName,
      sortable: true,
    },
    {
      name: 'Enrolled Students',
      selector: (row) => row.enrollStudents,
      sortable: true,
    },
  ];
  const [state, setState] = useState({
    options: {
      plotOptions: {
        bar: {
          columnWidth: '50%',
        },
      },
      chart: {
        id: 'basic-bar',
      },
      xaxis: {
        categories: ['Sun', 'Mon', 'Tue', 'Thu', 'Fri', 'Sat'],
      },
    },
    series: [
      {
        name: 'Visitors',
        data: [100, 100, 100, 200, 100, 150],
      },
    ],
  });
  const [queryState, setQueryState] = useState({
    options: {
      labels: ['Resolved', 'Pending'],
    },
    series: [68, 32],
  });

  const [activeState, setActiveState] = useState({
    options: {
      chart: {
        id: 'basic-bar',
      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'sept',
          'Oct',
          'Nov',
          'Dec',
        ],
      },
    },
    series: [
      {
        name: 'Active Users',
        data: [120, 155, 180, 200, 100, 150, 200, 160, 150, 140, 250, 230],
      },
    ],
  });
  const [revenueState, setARevenueState] = useState({
    options: {
      chart: {
        id: 'basic-bar',
      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'sept',
          'Oct',
          'Nov',
          'Dec',
        ],
      },
    },
    series: [
      {
        name: 'New Member',
        data: [120, 155, 180, 200, 100, 150, 200, 160, 150, 140, 250, 230],
      },
      {
        name: 'Total Order',
        data: [110, 125, 165, 222, 130, 140, 190, 170, 140, 150, 260, 240],
      },
      {
        name: 'Total Profit',
        data: [140, 190, 150, 140, 165, 146, 245, 164, 172, 183, 142, 137],
      },
    ],
  });

  const sellingCourseColumn = [
    {
      name: 'CourseTitle',
      selector: (row) => row.courseTitle,
      sortable: true,
    },
    {
      name: 'Price',
      selector: (row) => row.price,
      sortable: true,
    },
    {
      name: 'Enrolled Students',
      selector: (row) => row.enrollStudents,
      sortable: true,
    },
    {
      name: 'Stock',
      selector: (row) => row.stock,
      sortable: true,
    },
  ];
  //use effect for fetchalllearner
  useEffect(() => {
    const response = axios.get(
      `${URL}/api/dashbaordmodule/fetchAllLearnerCount`,
    );
    response
      .then((data) => {
        setTotalLearner(data.data.allLearnerCount);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  //use effect for active learners
  // useEffect(() => {
  //   const response = axios.get(
  //     `${URL}/api/dashbaordmodule/fetchActiveLeaners`,
  //   );
  //   response.then(data => {
  //     setActiveLearner(data.data.activeLearners)
  //   }).catch(error => {
  //     console.log(error)
  //   })
  // },[])

  //use effect for no of courses
  useEffect(() => {
    const response = axios.get(
      `${URL}/api/dashbaordmodule/fetchAllCoursesCount`,
    );
    response
      .then((data) => {
        setAllCoursesCount(data.data.allCoursesCount);
      })
      .catch((error) => {
        console.log(error);
      });
    //Upcoming AllEvents
    const events = axios.get(`${URL}/api/calendar/fetchAllEvents`);
    events
      .then((data) => {
        const today = moment().startOf('day');
        const eventsUpcoming = data.data.filter(
          (event) => event && moment(event.SDate).isAfter(today, 'day'),
        );
        setAllEvents(eventsUpcoming);
      })
      .catch((error) => {
        console.log(error);
      });
    //AllNotification
    const allNotification = axios.get(
      `${URL}/api/dashbaordmodule/fetchAllNotification`,
    );
    allNotification
      .then((data) => {
        const notifications = data.data.filter(
          (notification) => notification.isDeleted === false,
        );
        console.log(notifications);
        setAllNotification(notifications);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    return `${month}/${day}`;
  };

  function getTopEvents() {
    return allEvents.map((event) => {
      console.log(event);
      const eventDay = new Date(event.SDate)
        .toLocaleDateString('en-US', { weekday: 'short' })
        .substring(0, 3);
      const eventDate = formatDate(event.SDate);
      const eventTime = event.STime;
      const eventName = event.EventName;
      const eventDesp = event.EventDesp;
      const eventDelivery = event.Delivery;
      return (
        <UpcomingEventCard
          key={event._id}
          eventDay={eventDay}
          eventDate={eventDate}
          eventTime={eventTime}
          eventName={eventName}
          eventDesp={eventDesp}
          eventDelivery={eventDelivery}
        />
      );
    });
  }

  const { t } = useTranslation();
  return (
    <PageContainer style={{ border: '0px solid blue' }}>
      {/*First Card Grid */}
      <Grid
        item
        display="flex"
        justifyContent="space-around"
        margin="10px"
        width="100%"
        style={{ border: '0px solid green' }}
      >
        <Card sx={{ width: '30%' }}>
          <CardContent>
            <Grid container>
              <Grid xs={9} style={{ border: '0px solid red' }}>
                <Typography color="text.secondary" variant="body1">
                  {t('dashboard.totalLearners')}
                </Typography>
              </Grid>
            </Grid>
            <Typography sx={{ mt: 2, mb: 2 }} variant="h4">
              {totalLearner}
            </Typography>
            {/* <Link>See all Learners</Link> */}
            <Link>{t('dashboard.seeAllLearners')}</Link>
          </CardContent>
        </Card>

        <Card sx={{ width: '30%' }}>
          <CardContent>
            <Grid container>
              <Grid xs={9} style={{ border: '0px solid red' }}>
                <Typography color="text.secondary" variant="body1">
                  {/* ACTIVE LEARNERS */}
                  {t('dashboard.activeLearner')}
                </Typography>
              </Grid>
            </Grid>
            <Typography sx={{ mt: 2, mb: 2 }} variant="h4">
              {activeLearners}
            </Typography>
            {/* <Link>View all Learners</Link> */}
            <Link>{t('dashboard.viewAllLearners')}</Link>
          </CardContent>
        </Card>

        <Card sx={{ width: '30%' }}>
          <CardContent>
            <Grid container>
              <Grid xs={9} style={{ border: '0px solid red' }}>
                <Typography color="text.secondary" variant="body1">
                  {/* NO. OF COURSES */}
                  {t('dashboard.nubOfCources')}
                </Typography>
              </Grid>
            </Grid>
            <Typography sx={{ mt: 2, mb: 2 }} variant="h4">
              {allCoursesCount}
            </Typography>
            {/* <Link>See Details</Link> */}
            <Link>{t('dashboard.seeDetails')}</Link>
          </CardContent>
        </Card>
      </Grid>

      {/* Notification list and Announcement Grid */}
      <Grid container sx={{ mt: 1 }}>
        <Grid item xs={6} style={{ border: '0px solid red' }}>
          <Card sx={{ mb: 1, ml: 2, mr: 1, mt: 1, minHeight: 440 }}>
            <CardContent>
              <Grid container style={{ border: '0px solid red' }}>
                <Grid item xs={10}>
                  {/* <Typography variant="h6">Notification</Typography> */}
                  <Typography variant="h6">
                    {t('dashboard.Notification')}
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  {/* <Link style={{ cursor: 'pointer' }}>View All</Link> */}
                  <Link style={{ cursor: 'pointer' }}>
                    {t('dashboard.viewAll')}
                  </Link>
                </Grid>
              </Grid>
              <hr />

              <Grid container>
                <div
                  style={{
                    maxHeight: '350px',
                    overflowY: 'auto',
                    width: '100%',
                  }}
                >
                  {allNotification.map((notification) => {
                    return (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: notification.notificationBody,
                        }}
                        style={{
                          backgroundColor: 'lightgray',
                          overflow: 'hidden',
                          objectFit: 'contain',
                        }}
                      ></div>
                    );
                  })}
                </div>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} style={{ border: '0px solid green' }}>
          <Card
            sx={{
              mr: 2,
              ml: 1,
              mb: 1,
              mt: 1,
              border: '0px solid green',
            }}
          >
            <CardContent>
              <Grid xs={12}>
                {/* <Typography variant="h6">Upcoming Courses</Typography> */}
                <Typography variant="h6">
                  {t('dashboard.upComingCources')}
                </Typography>
              </Grid>
              <hr />
              <Grid display="flex" justifyContent="center" alignItems="center">
                <ImageTag
                  style={{ border: '0px solid red' }}
                  src="https://i.pinimg.com/736x/73/a4/5e/73a45ea3b5548b23b44148ec3fd0a72c.jpg"
                ></ImageTag>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* User Activity Grid */}
      <Grid container sx={{ mt: 1 }} spacing={2}>
        <Grid item xs={6} style={{ border: '0px solid red' }}>
          <Card sx={{ ml: 2, mb: 1 }}>
            <CardContent>
              <Grid container>
                <Grid item xs={10} style={{ border: '0px solid red' }}>
                  {/* <Typography variant="h6">Visitors</Typography> */}
                  <Typography variant="h6">{t('dashboard.visitor')}</Typography>
                </Grid>
                <Grid item xs={2} style={{ border: '0px solid red' }}>
                  <Select
                    fullWidth
                    size="small"
                    value={selectedValue}
                    onChange={handleSelectChange}
                    defaultValue="daily"
                  >
                    {/* <MenuItem value="daily"> Daily</MenuItem> */}
                    <MenuItem value="daily">{t('dashboard.daily')}</MenuItem>
                    {/* <MenuItem value="monthly"> Monthly</MenuItem> */}
                    <MenuItem value="monthly">
                      {' '}
                      {t('dashboard.monthly')}
                    </MenuItem>
                    {/* <MenuItem value="yearly"> Yearly</MenuItem> */}
                    <MenuItem value="yearly"> {t('dashboard.yearly')}</MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <Chart
                options={state.options}
                series={state.series}
                type="bar"
                // width="700"
                height="300"
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} style={{ border: '0px solid red' }}>
          <Card sx={{ mr: 2, mb: 1 }}>
            <CardContent>
              <Grid container>
                <Grid item xs={10}>
                  {/* <Typography variant="h6">Student Queries</Typography> */}
                  <Typography variant="h6">
                    {t('dashboard.studentQueries')}
                  </Typography>
                </Grid>
                <Grid item xs={2}></Grid>
              </Grid>
              {/* Place the Percentage Circular graph */}
              <Chart
                options={queryState.options}
                series={queryState.series}
                type="donut"
                width="460"
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 2, mb: 1, border: '0px solid red' }}>
        <Grid item xs={5} style={{ border: '0px solid red' }}>
          <Card sx={{ mr: 1, ml: 2, mt: 1, mb: 1 }}>
            <CardContent>
              {/* <Typography variant="h6"> Active Users</Typography> */}
              <Typography variant="h6">
                {' '}
                {t('dashboard.activeUsers')}
              </Typography>
              <Chart
                options={activeState.options}
                series={activeState.series}
                type="bar"
                // width='500'
                height="310"
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={7} style={{ border: '0px solid red' }}>
          <Card sx={{ mt: 1, mb: 1, ml: 1, mr: 2 }}>
            <CardContent>
              <Grid container>
                <Grid item xs={4} style={{ border: '0px solid red' }}>
                  {/* <Typography variant="h6">Member Details</Typography> */}
                  <Typography variant="h6">
                    {t('dashboard.memberDetails')}
                  </Typography>
                </Grid>
                <Grid item xs={8} sx={{ border: '0px solid green', mb: 1 }}>
                  <TextField
                    fullWidth
                    size="small"
                    // placeholder="Search by name"
                    placeholder={t('dashboard.searchbyname')}
                  />
                </Grid>
              </Grid>
              <div style={{ maxHeight: '310px', overflowY: 'auto' }}>
                <DisplayTableLib
                  columns={memberDetailsColumns}
                  data={memberDetails}
                ></DisplayTableLib>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={6} style={{ border: '0px solid red' }}>
          <Card sx={{ mt: 2, mr: 1, ml: 2, mb: 1 }}>
            <CardContent>
              <Typography variant="h6">
                {t('dashboard.revenueReport')}
              </Typography>
              <Grid container style={{ border: '0px solid green' }}>
                <Grid container xs={4} style={{ border: '0px solid red' }}>
                  <Grid
                    item
                    xs={5}
                    style={{ border: '0px solid yellow' }}
                  ></Grid>
                  <Grid item xs={7} style={{ border: '0px solid green' }}>
                    {/* <Typography>New Student</Typography> */}
                    <Typography>{t('dashboard.newStudent')}</Typography>
                    <Typography>550</Typography>
                  </Grid>
                </Grid>
                <Grid container xs={4} style={{ border: '0px solid blue' }}>
                  <Grid
                    item
                    xs={5}
                    style={{ border: '0px solid yellow' }}
                  ></Grid>
                  <Grid item xs={7} style={{ border: '0px solid green' }}>
                    {/* <Typography>Total Order</Typography> */}
                    <Typography>{t('dashboard.totalOrder')}r</Typography>
                    <Typography>550</Typography>
                  </Grid>
                </Grid>
                <Grid container xs={4} style={{ border: '0px solid magenta' }}>
                  <Grid
                    item
                    xs={5}
                    style={{ border: '0px solid yellow' }}
                  ></Grid>
                  <Grid item xs={7} style={{ border: '0px solid green' }}>
                    {/* <Typography>Total Profit</Typography> */}
                    <Typography>{t('dashboard.totalProfit')}</Typography>
                    <Typography>$5263.55</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Chart
                options={revenueState.options}
                series={revenueState.series}
                type="bar"
                height="280"
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} style={{ border: '0px solid green' }}>
          <Card sx={{ mt: 2, mr: 2, ml: 1, mb: 1 }}>
            <CardContent>
              {/* <Typography variant="h6">Best Selling Courses</Typography> */}
              <Typography variant="h6">
                {t('dashboard.bestSellingCourses')}
              </Typography>
              <div style={{ maxHeight: '345px', overflowY: 'auto' }}>
                <DisplayTableLib
                  columns={sellingCourseColumn}
                  data={sellingDetails}
                ></DisplayTableLib>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* Instructor Details and Top Courses Grid */}
      <Grid container>
        <Grid item xs={7} style={{ border: '0px solid red' }}>
          <Card sx={{ ml: 2, mb: 1, mt: 1, mr: 1 }}>
            <CardContent>
              <div style={{ maxHeight: '310px', overflowY: 'auto' }}>
                <DisplayTableLib
                  // title="Instructors Details"
                  title={t('dashboard.instructorsDetails')}
                  columns={teachersDetailsColumn}
                  data={instructorDetails}
                ></DisplayTableLib>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={5} style={{ border: '0px solid green' }}>
          <Card sx={{ mt: 1, ml: 1, mr: 2, mb: 1 }}>
            <CardContent>
              <div style={{ maxHeight: '310px', overflowY: 'auto' }}>
                <div
                  style={{
                    fontWeight: 400,
                    fontSize: 'larger',
                    padding: '10px',
                  }}
                >
                  Top Events
                </div>
                {getTopEvents()}
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default AdminDashboard;
