import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import BackButton from '../../Utilities/BackButton';
import io from 'socket.io-client';
import axios from 'axios';
import { palette } from '../../../styles/palette';
import {
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Search,
  AttachFile,
  Cancel,
  ThumbUp,
  ThumbDown,
} from '@mui/icons-material';
import ReplyIcon from '@mui/icons-material/Reply';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Avatar from '@mui/material/Avatar';
import CommonButton from '../../Utilities/CommonButton';
import {
  postDiscussion,
  fetchDiscussion,
} from '../../../features/courses/courseDiscussionSlice';
import {
  ChatContainer,
  InputContainer,
  Header,
  UserInfo,
  UserName,
  HeaderIcons,
  Messages,
  MessageContainer,
  ImageContainer,
  Bubble,
  TimeStamp,
  ReplyContainer,
  IconGroup,
  CourseTitle,
  BubbleHead,
  Icons,
} from '../../../styles/Courses/CourseBuilderStyles';

// Initialize socket
const socket = io(`${process.env.REACT_APP_URL}`);

const CourseDiscussion = () => {
  const { courseId } = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [hoveredMessageId, setHoveredMessageId] = useState(null);
  const [replyingToMessage, setReplyingToMessage] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadUrls, setUploadUrls] = useState([]); // Handle multiple upload URLs
  const [activeThumbUp, setActiveThumbUp] = useState({});
  const [activeThumbDown, setActiveThumbDown] = useState({});
  const [localMessages, setLocalMessages] = useState([]); // Local state for real-time messages
  const dispatch = useDispatch();
  const messagesEndRef = useRef(null);
  const { users } = useSelector((state) => state.auth);
  const { messages } = useSelector((state) => state.chat) || [];
  const { coursesList } = useSelector((state) => state.addCourseDetails) || {};

  // Connect to socket and fetch initial discussion
  useEffect(() => {
    dispatch(fetchDiscussion(courseId)); // Fetch existing messages

    socket.on('connect', () => {
      console.log(' checking Connected to socket server with ID:', socket.id);
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from socket server');
    });

    // Listen for new messages
    socket.on('recieve-message', (newMessage) => {
      console.log(' checking New message received: ', newMessage);
      setLocalMessages((prevMessages) => [...prevMessages, newMessage]); // Update local messages directly
    });

    return () => {
      socket.disconnect();
      socket.close();
    };
  }, [dispatch, courseId]);

  // Merge local and fetched messages for display
  useEffect(() => {
    setLocalMessages(messages.data || []); // Sync fetched messages with local state
  }, [messages]);

  // Handle message input change
  const handleMessage = (event) => {
    setMessage(event.target.value);
  };

  // Handle file selection
  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles([...selectedFiles, ...files]);
    files.forEach(async (file) => {
      await requestUploadUrl(file);
    });
  };

  // Cancel selected file
  const handleCancelFile = (fileIndex) => {
    const updatedFiles = selectedFiles.filter(
      (_, index) => index !== fileIndex,
    );
    setSelectedFiles(updatedFiles);
    // Remove corresponding URL from the list
    setUploadUrls(uploadUrls.filter((_, index) => index !== fileIndex));
  };

  // Request S3 upload URL for files
  const requestUploadUrl = async (file) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/uploadAttachments`,
        {
          fileName: file.name,
          fileType: file.type,
        },
      );
      console.log(response.data.url);
      setUploadUrls((prevUrls) => [...prevUrls, response.data.url]);
    } catch (error) {
      console.error('Error requesting upload URL:', error);
    }
  };

  // Upload files to S3
  const uploadToS3 = async () => {
    try {
      await Promise.all(
        uploadUrls.map((url, index) =>
          axios.put(url, selectedFiles[index], {
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': selectedFiles[index].type,
            },
          }),
        ),
      );
      console.log('Files uploaded successfully');
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  // Handle sending message
  const handleSendMessage = async () => {
    await uploadToS3();

    const newMessage = {
      course: courseId,
      sender: users.user._id,
      text: message,
      replyTo: replyingToMessage ? replyingToMessage._id : null,
      attachments: uploadUrls.map((url) => url.split('?')[0]), // Attachments URLs
    };

    // Optimistically update local state with the new message
    setLocalMessages((prevMessages) => [...prevMessages, newMessage]);

    // Emit message via socket
    socket.emit('message', newMessage);

    // Save message to Redux store
    dispatch(postDiscussion(newMessage)).then(() => {
      setMessage('');
      setSelectedFiles([]);
      setUploadUrls([]); // Clear the URLs list
      setReplyingToMessage(null);
      scrollToBottom();
    });
  };

  // Scroll to the bottom of the chat on new message

  useEffect(() => {
    scrollToBottom();
  }, [localMessages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
  };

  // Search functionality
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    console.log('hi');
  };

  const filteredMessages = localMessages.filter((message) =>
    message.text?.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const course = coursesList.find((course) => course._id === courseId);
  const courseTitle = course ? course.title : 'Course Discussion';

  const messageMap = {};
  localMessages.forEach((message) => {
    messageMap[message._id] = message;
  });

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  // Update thumb up/down handling
  const handleThumbUp = async (messageId) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_URL}api/course/messages/${messageId}/thumbUp`,
        { userId: users.user._id },
      );
      dispatch(fetchDiscussion(courseId));
    } catch (error) {
      console.error('Error updating thumb up:', error);
    }
  };

  const handleThumbDown = async (messageId) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_URL}/api/course/messages/${messageId}/thumbDown`,
        { userId: users.user._id },
      );
      dispatch(fetchDiscussion(courseId));
    } catch (error) {
      console.error('Error updating thumb down:', error);
    }
  };

  // Check if the user has already voted (thumb up or down) on a message
  useEffect(() => {
    if (localMessages && users.user) {
      const thumbUpStates = {};
      const thumbDownStates = {};

      localMessages.forEach((message) => {
        if (
          Array.isArray(message.usersVoted?.thumbUps) &&
          message.usersVoted.thumbUps.includes(users.user._id)
        ) {
          thumbUpStates[message._id] = true;
        }
        if (
          Array.isArray(message.usersVoted?.thumbDowns) &&
          message.usersVoted.thumbDowns.includes(users.user._id)
        ) {
          thumbDownStates[message._id] = true;
        }
      });

      setActiveThumbUp(thumbUpStates);
      setActiveThumbDown(thumbDownStates);
    }
  }, [localMessages, users.user]);

  return (
    <>
      <ChatContainer>
        <Header>
          <BackButton />
          <UserInfo>
            <ArrowBackIosIcon onClick={() => navigate(-1)} />
            <UserName>
              <Typography variant="body1">{courseTitle}</Typography>
            </UserName>
          </UserInfo>
          <HeaderIcons>
            <Tooltip title="Search">
              <IconButton>
                <Search />
              </IconButton>
            </Tooltip>
            <TextField
              value={searchTerm}
              onChange={handleSearch}
              placeholder="Search..."
            />
          </HeaderIcons>
        </Header>
        <Messages>
          {filteredMessages.map((msg) => (
            <MessageContainer
              key={msg._id}
              onMouseEnter={() => setHoveredMessageId(msg._id)}
              onMouseLeave={() => setHoveredMessageId(null)}
            >
              <Avatar />
              <Bubble>
                <BubbleHead>
                  <Typography variant="subtitle2">
                    {msg.sender?.name || 'Unknown'}
                  </Typography>
                  <Typography variant="caption">
                    {formatTime(msg.createdAt)}
                  </Typography>
                </BubbleHead>
                {msg.replyTo && (
                  <ReplyContainer>
                    <BubbleHead>
                      <Typography variant="body2">
                        {messageMap[msg.replyTo]?.text || 'Original message'}
                      </Typography>
                    </BubbleHead>
                  </ReplyContainer>
                )}
                <Typography variant="body1">{msg.text}</Typography>
                {msg.attachments && (
                  <ImageContainer>
                    {msg.attachments.map((attachment, index) => (
                      <img
                        key={index}
                        src={attachment}
                        alt="attachment"
                        width="100%"
                        height="auto"
                      />
                    ))}
                  </ImageContainer>
                )}
              </Bubble>
              <Icons>
                {hoveredMessageId === msg._id && (
                  <IconGroup>
                    <Tooltip title="Reply">
                      <IconButton onClick={() => setReplyingToMessage(msg)}>
                        <ReplyIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Thumb Up">
                      <IconButton onClick={() => handleThumbUp(msg._id)}>
                        <ThumbUp
                          color={activeThumbUp[msg._id] ? 'primary' : 'inherit'}
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Thumb Down">
                      <IconButton onClick={() => handleThumbDown(msg._id)}>
                        <ThumbDown
                          color={
                            activeThumbDown[msg._id] ? 'primary' : 'inherit'
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </IconGroup>
                )}
              </Icons>
            </MessageContainer>
          ))}
          <div ref={messagesEndRef} />
        </Messages>
        <InputContainer>
          <TextField
            placeholder="Type a message"
            value={message}
            onChange={handleMessage}
            fullWidth
            multiline
          />
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={handleFileChange}
            style={{ display: 'none' }}
            id="file-input"
          />
          <label htmlFor="file-input">
            <Tooltip title="Attach File">
              <IconButton component="span">
                <AttachFile />
              </IconButton>
            </Tooltip>
          </label>
          {selectedFiles.map((file, index) => (
            <div key={index}>
              {file.name}{' '}
              <Tooltip title="Cancel">
                <IconButton onClick={() => handleCancelFile(index)}>
                  <Cancel />
                </IconButton>
              </Tooltip>
            </div>
          ))}
          <CommonButton onClick={handleSendMessage}>Send</CommonButton>
        </InputContainer>
      </ChatContainer>
    </>
  );
};

export default CourseDiscussion;
