import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  Paper,
  Collapse,
  Box,
  Typography,
  ListItemIcon,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { palette } from '../../../styles/palette';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveDetails,
  fetchCourseDetailsInSlice,
} from '../../../features/courses/addCourseDetailsSlice';
import PreviewModuleOrLesson from './PreviewModuleOrLesson';
import ContentPlayer from '../ContentPlayer';
import CreateTopicText from './LearnerPreviewTopic/CreateTopicText';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import AddIcon from '@mui/icons-material/Add';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import VolumeMuteOutlinedIcon from '@mui/icons-material/VolumeMuteOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import MovieOutlinedIcon from '@mui/icons-material/MovieOutlined';
import MmsOutlinedIcon from '@mui/icons-material/MmsOutlined';
import SettingsInputAntennaOutlinedIcon from '@mui/icons-material/SettingsInputAntennaOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import PollOutlinedIcon from '@mui/icons-material/PollOutlined';
import { styled } from '@mui/system';
import {
  Container,
  Sidebar,
  Header,
  Divider,
  ContentWrapper,
  EmptyStateBox,
} from '../../../styles/Courses/CoursePreviewStyles';

const StyledList = styled(List)`
  height: 70%;
  overflow-y: auto;
`;

const StyledListItem = styled(ListItem)`
  background: ${palette.white};
  margin-top: 10px;
`;

const CollapseList = styled(List)`
  padding: 20px;
  margin-bottom: 10px;
`;

const NestedListItem = styled(ListItem)`
  background: ${palette.white};
  margin-bottom: 10px;
  border: 1px solid ${palette.gray};
  border-radius: 5px;
`;

const LearnerCoursePreview = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { courseTitle } = location.state || {};
  const dispatch = useDispatch();
  const [expandedModule, setExpandedModule] = useState(null);
  const [expandedLessons, setExpandedLessons] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedPreviewItem, setSelectedPreviewItem] = useState(null);
  const { modules, lessons } =
    useSelector((state) => state.addCourseDetails.courseDetails) || {};
  const { id, courseStructure } = useParams();

  // useEffect(() => {
  //   const fetchCourseDetails = async (id) => {
  //     await dispatch(fetchCourseDetailsInSlice(id));
  //   };
  //   fetchCourseDetails(id);
  // }, [id]);

  useEffect(() => {
    if (modules && modules.length > 0) {
      setExpandedModule(modules[0].id);
      setSelectedPreviewItem(modules[0]);
    }
  }, [modules]);

  const handleModuleOnClick = (moduleId) => {
    setExpandedModule(expandedModule === moduleId ? null : moduleId);
  };

  const handleLessonClick = (lessonId) => {
    setExpandedLessons({
      ...expandedLessons,
      [lessonId]: !expandedLessons[lessonId],
    });
  };

  const handleModuleTextClick = (module) => {
    setSelectedPreviewItem(module);
  };

  const handleLessonTextClick = (lesson) => {
    setSelectedPreviewItem(lesson);
  };

  const handleSaveCourse = async () => {
    const result = await dispatch(
      saveDetails({ modules, courseId: id, lessons }),
    );
    alert(result.payload?.data?.message);
  };

  const getContentIcon = (contentType) => {
    switch (contentType) {
      case 'Video':
        return <VideocamOutlinedIcon fontSize="small" />;
      case 'Audio':
        return <VolumeMuteOutlinedIcon fontSize="small" />;
      case 'Text':
        return <TextSnippetOutlinedIcon fontSize="small" />;
      case 'PDF':
        return <PictureAsPdfOutlinedIcon fontSize="small" />;
      case 'Download':
        return <CloudDownloadOutlinedIcon fontSize="small" />;
      case 'Presentation':
        return <MovieOutlinedIcon fontSize="small" />;
      case 'Multimedia':
        return <MmsOutlinedIcon fontSize="small" />;
      case 'Live':
      case 'Scorm':
        return <SettingsInputAntennaOutlinedIcon fontSize="small" />;
      case 'Quiz':
        return <QuizOutlinedIcon fontSize="small" />;
      case 'Assignment':
        return <AssignmentOutlinedIcon fontSize="small" />;
      case 'Exam':
        return <AssessmentOutlinedIcon fontSize="small" />;
      case 'Survey':
      case 'Evaluate':
        return <PollOutlinedIcon fontSize="small" />;
      default:
        return <LibraryBooksOutlinedIcon fontSize="small" />;
    }
  };

  return (
    <Container>
      <Sidebar>
        <Header>
          <Tooltip title="Go Back">
            <IconButton
              size="small"
              onClick={() => navigate('/CourseBuilder')}
              sx={{ color: `${palette.THEME_THREE}` }}
            >
              <ArrowBackIosIcon />
            </IconButton>
          </Tooltip>
          <h4>{courseTitle}</h4>
        </Header>
        <Divider />
        <StyledList>
          {courseStructure === 'CMLT' ? (
            modules?.map((module) => (
              <div key={module?.id}>
                <StyledListItem>
                  <ListItemText
                    sx={{ color: `${palette.primary}` }}
                    primary={module?.title}
                    onClick={() => handleModuleTextClick(module)}
                  />
                  <Box sx={{ marginRight: '8px' }} />
                  {expandedModule === module?.id ? (
                    <ExpandLessIcon
                      onClick={() => handleModuleOnClick(module.id)}
                    />
                  ) : (
                    <ExpandMoreIcon
                      onClick={() => handleModuleOnClick(module.id)}
                    />
                  )}
                </StyledListItem>
                <Collapse
                  in={expandedModule === module?.id}
                  timeout="auto"
                  unmountOnExit
                >
                  <List
                    component={Paper}
                    style={{ padding: '20px', marginBottom: '10px' }}
                  >
                    {module?.lessons?.map((lesson) => (
                      <div key={lesson.id}>
                        <NestedListItem>
                          <ListItemText
                            sx={{ color: `${palette.secondary}` }}
                            primary={lesson.title}
                            onClick={() => handleLessonTextClick(lesson)}
                          />
                          <Box sx={{ marginRight: '8px' }} />
                          {expandedLessons[lesson.id] ? (
                            <ExpandLessIcon
                              onClick={() => handleLessonClick(lesson.id)}
                            />
                          ) : (
                            <ExpandMoreIcon
                              onClick={() => handleLessonClick(lesson.id)}
                            />
                          )}
                        </NestedListItem>
                        <Collapse
                          in={expandedLessons[lesson.id]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List>
                            {lesson?.items?.map((item, index) => (
                              <ListItem
                                key={index}
                                style={{
                                  background:
                                    selectedItem === item
                                      ? '#d0eaff'
                                      : '#f9f9f9', // Highlight if selected
                                  marginBottom: '5px',
                                  paddingLeft: '20px',
                                  cursor: 'pointer',
                                }}
                                onClick={() => setSelectedItem(item)}
                              >
                                <ListItemIcon>
                                  {getContentIcon(item.contentType)}
                                </ListItemIcon>
                                <ListItemText
                                  primary={item.title || item.content || ''}
                                />
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      </div>
                    ))}
                  </List>
                </Collapse>
              </div>
            ))
          ) : (
            <CollapseList component={Paper}>
              {lessons?.map((lesson) => (
                <div key={lesson.id}>
                  <NestedListItem>
                    <ListItemText
                      sx={{ color: `${palette.secondary}` }}
                      primary={lesson.title}
                      onClick={() => handleLessonTextClick(lesson)}
                    />
                    <Tooltip title="Add Topic">
                      <AddIcon
                        onClick={() =>
                          handleAddTopic(lesson.moduleId, lesson.id)
                        }
                      />
                    </Tooltip>
                    <Box sx={{ marginRight: '8px' }} />
                    {expandedLessons[lesson.id] ? (
                      <ExpandLessIcon
                        onClick={() => handleLessonClick(lesson.id)}
                      />
                    ) : (
                      <ExpandMoreIcon
                        onClick={() => handleLessonClick(lesson.id)}
                      />
                    )}
                  </NestedListItem>
                  <Collapse
                    in={expandedLessons[lesson.id]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List>
                      {lesson?.items?.map((item, index) => (
                        <ListItem
                          key={index}
                          style={{
                            background:
                              selectedItem === item ? '#d0eaff' : '#f9f9f9', // Highlight if selected
                            marginBottom: '5px',
                            paddingLeft: '20px',
                            cursor: 'pointer',
                          }}
                          onClick={() => setSelectedItem(item)}
                        >
                          <ListItemIcon>
                            {getContentIcon(item.contentType)}
                          </ListItemIcon>
                          <ListItemText
                            primary={item.title || item.content || ''}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </div>
              ))}
            </CollapseList>
          )}
        </StyledList>
      </Sidebar>
      <ContentWrapper>
        {!selectedPreviewItem && !selectedItem && (
          <EmptyStateBox>
            <VisibilityIcon
              style={{ fontSize: '56px', marginBottom: '16px' }}
            />
            <Typography variant="h5">
              Explore this course to see what you'll be learning
            </Typography>
          </EmptyStateBox>
        )}
        {selectedPreviewItem && (
          <PreviewModuleOrLesson
            item={selectedPreviewItem}
            onClose={() => setSelectedPreviewItem(null)}
          />
        )}
        {selectedItem && (
          <ContentPlayer
            item={selectedItem}
            onClose={() => setSelectedItem(null)}
          />
        )}
      </ContentWrapper>
    </Container>
  );
};

export default LearnerCoursePreview;
