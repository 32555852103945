import React from 'react';
import { Modal, Box, Button, TextField } from '@mui/material';

const SaveModal = ({
  isModalOpen,
  handleCloseModal,
  modalPageName,
  setModalPageName,
  modalTitle,
  setModalTitle,
  handleModalSubmit,
}) => (
  <Modal
    open={isModalOpen}
    onClose={handleCloseModal}
    aria-labelledby="modal-title"
    aria-describedby="modal-description"
  >
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      }}
    >
      <h2 id="modal-title">Save as New Template</h2>
      <TextField
        label="Page Name"
        value={modalPageName}
        onChange={(e) => setModalPageName(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Title"
        value={modalTitle}
        onChange={(e) => setModalTitle(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleModalSubmit}
        sx={{ mt: 2 }}
      >
        Save
      </Button>
    </Box>
  </Modal>
);

export default SaveModal;
