import React, { useState } from 'react';
import {
  Grid,
  TextField,
  Tooltip,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
} from '@mui/material';
import {
  AddDiv,
  Description,
  CloseIconStyle,
  TextFieldStyle,
  TypographyStyle,
  SubmitButtonStyle,
} from '../../styles/Profile/ProfileStyle';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '../Utilities/Modal';
import CommonButton from '../Utilities/CommonButton';
import { useTranslation } from 'react-i18next';

const AdditionalField = (props) => {
  const [addFieldState, setAddFeildState] = useState({
    fieldName: '',
    // dataType: "",
    // mandatoryField: false,
  });
  const [addFieldErrorState, setAddFeildErrorState] = useState({
    fieldName: false,
    // dataType: false,
    // mandatoryField: false,
  });

  const handleChange = (event) => {
    if (event.target.value !== '' || null || undefined) {
      setAddFeildState({
        ...addFieldState,
        [event.target.name]: event.target.value,
      });
      setAddFeildErrorState({
        ...addFieldErrorState,
        [event.target.name]: true,
      });
    }
  };

  const handleClose = () => {
    console.log('in handle close function handle close');
    props.onClose();
  };

  const modalWidth = '550px';
  const modalHeight = 'max-content';

  const handleSubmitButton = () => {
    if (!addFieldErrorState.fieldName) alert('Please enter Field Name');
    else {
      console.log('In handle submit ', addFieldState);
      props.AddFields(addFieldState);
      props.onClose();
    }
  };

  const { t } = useTranslation();
  return (
    <Modal
      isOpen={props.isOpen}
      modalWidth={modalWidth}
      modalHeight={modalHeight}
      onClose={props.onClose}
      headingText="Additional Fields"
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            required
            label="Field Name"
            type="text"
            name="fieldName"
            id="fieldName"
            variant="outlined"
            sx={TextFieldStyle}
            value={addFieldState.fieldName}
            onChange={handleChange}
          />
        </Grid>

        <SubmitButtonStyle>
          <CommonButton onClick={handleSubmitButton} label="Submit" />
        </SubmitButtonStyle>
      </Grid>
    </Modal>
  );
};

export default AdditionalField;
