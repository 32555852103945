import axios from 'axios';
import { getHeader } from '../api/localStorageUtils';  // Adjust the path if needed

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_URL,  // Your API base URL
});

apiClient.interceptors.request.use((config) => {
  // Attach the token to every request
  config.headers = getHeader();
  return config;
}, (error) => {
  return Promise.reject(error);
});

apiClient.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  const originalRequest = error.config;

  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    try {
      const newToken = await refreshToken();
      // Assuming refreshToken sets a new token in local storage
      setHeader(newToken);  // Update the token in local storage
      apiClient.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;

      return apiClient(originalRequest);
    } catch (refreshError) {
      console.error('Token refresh failed:', refreshError);
      window.location.href = '/login';  // Redirect to login
    }
  }
  return Promise.reject(error);
});

const refreshToken = async () => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL}/api/refreshToken`, {
      // Pass the refresh token if needed
    });

    const newToken = response.data.token;
    return newToken;
  } catch (error) {
    console.error('Failed to refresh token:', error);
    throw error;
  }
};

export default apiClient;
