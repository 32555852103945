import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Grid,
  Typography,
  Switch,
  Tooltip,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  fetchCourseNotification,
  saveNotificationSettings,
} from '../../../features/courses/CourseUtilitySlice';
import { fetchNotificationTemplate } from '../../../features/notification/NotificationSlice';
import { toast } from 'react-toastify';
import DOMPurify from 'dompurify';
import CourseSettingTabs from './CourseSettingTabs';
import { PageContainer } from '../../../styles/Common/CommonStyles';
import CommonButton from '../../Utilities/CommonButton';
import { Step3ContainerStyle } from '../../../styles/Courses/CourseBuilderStyles';

const CourseNotificationSettings = () => {
  const { courseId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editor = useRef(null);
  const { courseSettings, startDate, endDate, endTime, startTime } =
    useSelector((state) => ({
      courseSettings:
        state.CourseUtility?.wholeCourseSetting?.data?.settings
          ?.notificationSettings,
      startDate:
        state.CourseUtility?.wholeCourseSetting?.data?.course
          ?.accessAndEnrollment?.startDate,
      endDate:
        state.CourseUtility?.wholeCourseSetting?.data?.course
          ?.accessAndEnrollment?.endDate,
      startTime:
        state.CourseUtility?.wholeCourseSetting?.data?.course
          ?.accessAndEnrollment?.startTime,
      endTime:
        state.CourseUtility?.wholeCourseSetting?.data?.course
          ?.accessAndEnrollment?.endTime,
    }));

  console.log('check start date', startDate);
  console.log('check courseSettings:', courseSettings);
  console.log('check end date:', endDate);
  console.log('check start time:', startTime);
  console.log('check end time:', endTime);

  const { templates: notificationTemplates } = useSelector(
    (state) => state.notification?.template,
  );

  const [formData, setFormData] = useState({
    publishNotification: true,
    endNotification: true,
  });

  const [selectedPublishTemplate, setSelectedPublishTemplate] = useState('');
  const [selectedEndTemplate, setSelectedEndTemplate] = useState('');
  const [notifications, setNotifications] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewContent, setPreviewContent] = useState('');

  // Fetch course notification settings and templates on mount
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await dispatch(fetchNotificationTemplate());
        if (response && response.payload) {
          setTemplates(response.payload || []);
        } else {
          toast.error('No templates found in the response');
        }
      } catch (error) {
        toast.error('Failed to fetch notification templates');
      }
    };
    fetchTemplates();
  }, [courseId, dispatch]);

  // Update formData and notifications when courseSettings changes
  useEffect(() => {
    if (courseSettings) {
      setFormData({
        publishNotification: courseSettings.publishNotification,
        endNotification: courseSettings.endNotification,
      });
      setNotifications(courseSettings.notifications || []);
      setSelectedPublishTemplate(
        courseSettings.notifications[0]?.template || '',
      );
      setSelectedEndTemplate(courseSettings.notifications[1]?.template || '');
    }
  }, [courseSettings]);

  const handleToggleChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleSaveTemplate = async () => {
    try {
      const publishTemplateBody =
        templates.find(
          (template) => template.notificationType === selectedPublishTemplate,
        )?.notificationBody || '';

      const endTemplateBody =
        templates.find(
          (template) => template.notificationType === selectedEndTemplate,
        )?.notificationBody || '';

      const payload = {
        courseId,
        notificationSettings: {
          ...formData,
          publishTemplate: publishTemplateBody,
          endTemplate: endTemplateBody,
          notifications,
          startDate,
          endDate,
          startTime,
          endTime,
        },
      };

      console.log('Payload to be sent:', payload); // Log the payload for debugging

      const response = await dispatch(saveNotificationSettings(payload));

      if (response && response.payload) {
        toast.success('Notification settings saved successfully!');
      } else {
        toast.error('Failed to save notification settings');
      }
    } catch (error) {
      toast.error('Failed to save notification settings');
      console.error('Error saving notification settings:', error);
    }
  };

  const handleTemplateSelect = (event, index) => {
    const selectedTemplateType = event.target.value;
    const selectedTemplate = templates.find(
      (template) => template.notificationType === selectedTemplateType,
    );

    if (selectedTemplate) {
      const { notificationType, notificationBody } = selectedTemplate;
      if (index === 0) {
        setSelectedPublishTemplate(notificationType);
        setNotifications((prevNotifications) =>
          prevNotifications.map((notification, i) =>
            i === 0
              ? {
                  ...notification,
                  template: notificationType,
                  body: notificationBody,
                }
              : notification,
          ),
        );
      } else if (index === 1) {
        setSelectedEndTemplate(notificationType);
        setNotifications((prevNotifications) =>
          prevNotifications.map((notification, i) =>
            i === 1
              ? {
                  ...notification,
                  template: notificationType,
                  body: notificationBody,
                }
              : notification,
          ),
        );
      }
    }
  };

  const handleAddNotification = () => {
    setNotifications((prevNotifications) => [
      ...prevNotifications,
      {
        type: 'Reminder',
        timeValue: 10,
        timeUnit: 'minutes',
        customDate: null,
        customType: '',
        template: '',
        templateBody: '',
      },
    ]);
  };

  const handleRemoveNotification = (index) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((_, i) => i !== index),
    );
  };

  const handleNotificationChange = (index, field, value) => {
    const updatedNotifications = notifications.map((notification, i) =>
      i === index ? { ...notification, [field]: value } : notification,
    );
    setNotifications(updatedNotifications);
  };

  const handlePreviewOpen = (index) => {
    const notification = notifications[index];
    const selectedTemplateContent = templates.find(
      (template) => template.notificationType === notification.template,
    );

    if (selectedTemplateContent) {
      const { notificationType, notificationBody } = selectedTemplateContent;
      const timeValue = notification.timeValue || 0;
      const timeUnit = notification.timeUnit || 'minutes';

      const body = `
      <div>
        <strong>Notification:</strong> ${notificationType}<br />
        <strong>Time:</strong> ${timeValue} ${timeUnit}<br />
        <strong>Body:</strong> ${notificationBody}
      </div>
    `;

      const sanitizedBody = DOMPurify.sanitize(body);
      setPreviewContent(sanitizedBody);
      setPreviewOpen(true);
    } else {
      toast.error('Template not found for preview');
    }
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
  };

  return (
    <PageContainer>
      <CourseSettingTabs />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '90%',
          margin: 'auto',
          marginTop: '45px',
        }}
      >
        <Tooltip title="Go Back">
          <IconButton size="small" onClick={() => navigate('/CourseBuilder')}>
            <ArrowBackIosIcon />
          </IconButton>
        </Tooltip>
        <CommonButton label="Save Settings" onClick={handleSaveTemplate} />
      </div>
      <Container fluid sx={Step3ContainerStyle}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Manage Course settings</Typography>
          </Grid>

          {/* Publish Notification */}
          <Grid item xs={4}>
            <Typography>Notify on Course Publication</Typography>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel>Notification Template</InputLabel>
              <Select
                value={selectedPublishTemplate}
                onChange={(e) => handleTemplateSelect(e, 0)}
              >
                {templates.map((template) => (
                  <MenuItem key={template.id} value={template.notificationType}>
                    {template.notificationType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              aria-label="preview"
              onClick={() => handlePreviewOpen(0)}
            >
              <VisibilityIcon />
            </IconButton>
          </Grid>
          <Grid item xs={3}>
            <Switch
              name="publishNotification"
              checked={formData.publishNotification}
              onChange={handleToggleChange}
            />
          </Grid>

          {/* End Notification */}
          <Grid item xs={4}>
            <Typography>Notify on Course End</Typography>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel>Notification Template</InputLabel>
              <Select
                value={selectedEndTemplate}
                onChange={(e) => handleTemplateSelect(e, 1)}
              >
                {templates.map((template) => (
                  <MenuItem key={template.id} value={template.notificationType}>
                    {template.notificationType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              aria-label="preview"
              onClick={() => handlePreviewOpen(1)}
            >
              <VisibilityIcon />
            </IconButton>
          </Grid>
          <Grid item xs={3}>
            <Switch
              name="endNotification"
              checked={formData.endNotification}
              onChange={handleToggleChange}
            />
          </Grid>

          {/* Notifications */}
          <Grid item xs={12}>
            <Typography variant="h6">Manage Notifications</Typography>
            {notifications.map((notification, index) => (
              <Grid container key={index} spacing={2}>
                {/* Notification Type */}
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel>Type</InputLabel>
                    <Select
                      value={notification.type}
                      onChange={(e) =>
                        handleNotificationChange(index, 'type', e.target.value)
                      }
                    >
                      <MenuItem value="Reminder">Reminder</MenuItem>
                      <MenuItem value="Custom">Custom</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {/* Time Value */}
                <Grid item xs={2}>
                  <TextField
                    type="number"
                    label="Time Value"
                    value={notification.timeValue}
                    onChange={(e) =>
                      handleNotificationChange(
                        index,
                        'timeValue',
                        e.target.value,
                      )
                    }
                    fullWidth
                  />
                </Grid>
                {/* Time Unit */}
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel>Unit</InputLabel>
                    <Select
                      value={notification.timeUnit}
                      onChange={(e) =>
                        handleNotificationChange(
                          index,
                          'timeUnit',
                          e.target.value,
                        )
                      }
                    >
                      <MenuItem value="minutes">Minutes</MenuItem>
                      <MenuItem value="hours">Hours</MenuItem>
                      <MenuItem value="days">Days</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {/* Template Selection */}
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel>Template</InputLabel>
                    <Select
                      value={notification.template}
                      onChange={(e) =>
                        handleNotificationChange(
                          index,
                          'template',
                          e.target.value,
                        )
                      }
                    >
                      {templates.map((template) => (
                        <MenuItem
                          key={template.id}
                          value={template.notificationType}
                        >
                          {template.notificationType}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* Remove Button */}
                <Grid item xs={1}>
                  <IconButton onClick={() => handleRemoveNotification(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <Button variant="contained" onClick={handleAddNotification}>
              Add Notification
            </Button>
          </Grid>
        </Grid>

        {/* Preview Dialog */}
        <Dialog open={previewOpen} onClose={handlePreviewClose}>
          <DialogTitle>Notification Preview</DialogTitle>
          <DialogContent>
            <div dangerouslySetInnerHTML={{ __html: previewContent }} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePreviewClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Container>

      {/* Preview Dialog */}
      <Dialog open={previewOpen} onClose={handlePreviewClose}>
        <DialogTitle>Notification Preview</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: previewContent }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePreviewClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </PageContainer>
  );
};

export default CourseNotificationSettings;
