import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CertificateTabs from './CertificateTabs';
import TextField from '@mui/material/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button, Tooltip, Grid, Box, Card, Typography } from '@mui/material';
import { palette } from '../../styles/palette';
import {
  fetchAllCertificateApi,
  deleteCertificate,
} from '../../features/certificate/CertificateSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '../../styles/Common/CommonStyles';
import {
  SearchEmail,
  WholePage,
} from '../../styles/Certificate/CertificateStyle';
import {
  Image,
  VerticalLine,
  ActionButton,
  CertificateInfo,
  CertificateWrapper,
} from '../../styles/Certificate/SeparateCertificateStyles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';

const SeparateCertificate = () => {
  const [certificateToDelete, setCertificateToDelete] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [highlightedCertificateId, setHighlightedCertificateId] =
    useState(null);
  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allcertificate } = useSelector((state) => state.certificate) || {};

  const handleDeleteDialogOpen = (certificate) => {
    setCertificateToDelete(certificate);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setCertificateToDelete(null);
  };

  const handleDelete = () => {
    if (!certificateToDelete) return;
    dispatch(deleteCertificate(certificateToDelete._id))
      .then(() => {
        setDeleteDialogOpen(false);
        setCertificateToDelete('delete');
      })
      .catch((error) => {
        console.error('Error deleting certificate:', error);
        setDeleteDialogOpen(false);
        setCertificateToDelete(null);
      });
    toast.success('Certificate Deleted');
  };

  const handleEdit = (certificateId) => {
    navigate(`/Certificate/EditTemplate/${certificateId}`);
  };

  const handleOpenModal = (certificate) => {
    console.log('check sort', sortedCertificates);
    setSelectedCertificate(certificate);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    dispatch(fetchAllCertificateApi());
  }, [certificateToDelete]);

  const isNewOrUpdated = (certificate) => {
    const fiveSecondsAgo = new Date(Date.now() - 5 * 1000);
    return (
      new Date(certificate.createdAt) > fiveSecondsAgo ||
      new Date(certificate.updatedAt) > fiveSecondsAgo
    );
  };

  useEffect(() => {
    const newOrUpdatedCertificate = allcertificate?.certificates?.find(
      (certificate) => isNewOrUpdated(certificate),
    );

    if (newOrUpdatedCertificate) {
      setHighlightedCertificateId(newOrUpdatedCertificate._id);
      const timer = setTimeout(() => {
        setHighlightedCertificateId(null);
      }, 5000); // Highlight for 5 seconds

      return () => clearTimeout(timer);
    }
  }, [allcertificate]);

  const filteredTemplates =
    allcertificate?.certificates?.filter((certificate) =>
      certificate?.certificateName
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase()),
    ) || [];

  const sortedCertificates = filteredTemplates?.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
  );

  const { t } = useTranslation();
  return (
    <>
      <CertificateTabs />
      <WholePage>
        <div style={{ width: '95%', margin: 'auto' }}>
          <Text>{t('certificate.seperateDisplay')}</Text>
          <SearchEmail>
            <TextField
              label={t('certificate.searchText')}
              size="small"
              variant="outlined"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </SearchEmail>

          <Grid container spacing={3}>
            {sortedCertificates.map((certificate) => (
              <Grid item xs={12} sm={4} key={certificate._id}>
                {/* <CertificateWrapper
                  style={
                    highlightedCertificateId === certificate._id
                      ? {
                          boxShadow: `rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,rgba(14, 30, 37, 0.32) 0px 2px 16px 0px`,
                        }
                      : {}
                  }
                > */}
                <Card
                  sx={{
                    maxWidth: 400,
                    border: '2px black solid',
                    borderRadius: '20px',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    backdropFilter: 'blur(100px)',
                    boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
                    border: '1px solid rgba(255, 255, 255, 0.18)',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignContent: 'center',
                    }}
                  >
                    <Typography
                      variant="h6"
                      component="div"
                      gutterBottom
                      style={{ textAlign: 'center', marginLeft: '10px' }}
                    >
                      {certificate.certificateName}
                    </Typography>
                  </div>
                  <div
                    style={{
                      overflow: 'hidden',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '300px',
                      border: '1px solid primary',
                      backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    }}
                  >
                    <img
                      style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        objectFit: 'contain',
                      }}
                      src={certificate?.certificateImage}
                      alt="certificate image"
                    />
                  </div>
                  <div
                    style={{
                      marginLeft: 'auto',
                      display: 'flex',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <Tooltip title={t('certificate.certificateSelectLabel')}>
                      <IconButton onClick={() => handleEdit(certificate._id)}>
                        <EditIcon
                          style={{
                            color: `${palette.primary}`,
                            fontSize: '1.5rem',
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('certificate.certificateViewLabel')}>
                      <IconButton onClick={() => handleOpenModal(certificate)}>
                        <VisibilityIcon
                          style={{
                            color: `${palette.primary}`,
                            fontSize: '1.5rem',
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('certificate.deleteLabel')}>
                      <IconButton
                        onClick={() => handleDeleteDialogOpen(certificate)}
                      >
                        <DeleteIcon
                          style={{
                            color: `${palette.primary}`,
                            fontSize: '1.5rem',
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Card>
                {/* </CertificateWrapper> */}
              </Grid>
            ))}
          </Grid>

          {/* Modal for displaying certificate details */}
          <Dialog
            open={isModalOpen}
            onClose={handleCloseModal}
            aria-labelledby="certificate-dialog-title"
            fullWidth
            maxWidth="md"
          >
            <DialogTitle id="certificate-dialog-title">
              {selectedCertificate?.Name}
              <IconButton
                aria-label={t('certificate.closeLabel')}
                onClick={handleCloseModal}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: `${palette.primary}`,
                }}
              >
                <CloseIcon />
              </IconButton>
              <br />
            </DialogTitle>
            <DialogContent
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 0, // Remove default padding
                maxHeight: '80vh', // Limit max height of the modal content
              }}
            >
              {selectedCertificate && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxHeight: '80vh', // Ensure image does not exceed 80% of viewport height
                    maxWidth: '100%', // Image container adapts to the full width
                    overflow: 'hidden', // Prevent overflow and scroll bars
                  }}
                >
                  <img
                    style={{
                      maxWidth: '100%',
                      maxHeight: '70vh', // Ensure image fits within the viewport height
                      objectFit: 'contain', // Maintain aspect ratio without cropping
                    }}
                    src={selectedCertificate?.certificateImage}
                    alt="certificate image"
                  />
                </Box>
              )}
            </DialogContent>

            <DialogActions>
              <Button onClick={handleCloseModal} color="primary">
                {t('certificate.closeLabel')}
              </Button>
            </DialogActions>
          </Dialog>

          {/* Delete Confirmation Dialog */}
          <Dialog
            open={deleteDialogOpen}
            onClose={handleDeleteDialogClose}
            aria-labelledby="delete-dialog-title"
          >
            <DialogTitle id="delete-dialog-title">
              {t('certificate.ConfirmDeleteLabel')}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t('certificate.deleteTemQue')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteDialogClose} color="primary">
                {t('certificate.cancelLabel')}
              </Button>
              <Button onClick={handleDelete} color="primary">
                {t('certificate.deleteLabel')}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </WholePage>
    </>
  );
};

export default SeparateCertificate;
