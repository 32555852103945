import React, { useEffect } from 'react';
import ImageSlider from './ImageSlider';
import CalendarCompSub from './CalendarCompSub';
import TagsCard from './TagsCard';
import HorizontalCard from './HorizontalCard';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CalendarTabs from './CalendarTabs';
import { fetchAnnouncement } from '../../features/calendar/CalendarSlice';
import { Box, Typography } from '@mui/material';
import { keyframes } from '@mui/system';
import Footer from '../Landing/Footer';

const CalendarMain = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { announcement } = useSelector((state) => state.calendar);

  useEffect(() => {
    dispatch(fetchAnnouncement());
  }, [dispatch]);

  const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

  return (
    <>
      <CalendarTabs />
      <Box
        sx={{
          width: '100%',
          maxWidth: '100vw',
          mx: 'auto',

          display: 'flex',
          flexDirection: 'column',

          boxSizing: 'border-box',
          // background:
          //   'linear-gradient(90deg, #F7EEDD,#a3daf7,#ACE2E1,  #F7EEDD)',
          // backgroundSize: '200% 200%',
          // animation: `${gradientAnimation} 10s ease infinite`,
        }}
      >
        <Box
          sx={{
            width: '100%', // Ensure the slider takes full width
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#fff',
            paddingTop: '20px',

            borderRadius: '8px',
            boxSizing: 'border-box',
          }}
        >
          <ImageSlider />
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
            boxSizing: 'border-box',
          }}
        >
          <CalendarCompSub />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
            <Typography
              sx={{
                pl: 3.5,
                mt: 2,

                color: '#0684FB',
              }}
            >
              {t('calender.highlightText')}
            </Typography>
          </Box>

          <TagsCard />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
            <HorizontalCard />
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default CalendarMain;
