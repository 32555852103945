import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
  Autocomplete,
  IconButton,
  Box,
  Tooltip,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import {
  addModule,
  fetchCourseInSlice,
  fetchCourseDetailsForCopyInSlice,
} from '../../../features/courses/addCourseDetailsSlice';
import JoditReact from 'jodit-react';
import { useParams } from 'react-router-dom';
import { MainContainer } from '../../../styles/Courses/CourseModuleStyles';

const AddModuleDialog = ({ onClose }) => {
  const [selectedOption, setSelectedOption] = useState('create');
  const [isCopy, setIsCopy] = useState(false);

  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedModules, setSelectedModules] = useState([]);
  const [moduleName, setModuleName] = useState({
    id: '',
    title: '',
    description: '',
    // number: '',
    // EstTime: '',
    lessons: [],
    preview: true,
  });

  const dispatch = useDispatch();
  const { courseDetailsForCopy, coursesList } = useSelector(
    (state) => state.addCourseDetails,
  );
  const { id, courseStructure } = useParams();

  useEffect(() => {
    dispatch(fetchCourseInSlice());
  }, [dispatch]);

  const handleModuleNameChange = (e) => {
    const { name, value } = e.target;
    setModuleName({
      ...moduleName,
      id: generateRandomNumber(),
      courseStructure: courseStructure,
      [name]: value,
    });
  };

  const generateRandomNumber = () => {
    const min = 100000;
    const max = 999999;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const handleSave = async () => {
    console.log(moduleName, 'moduleName');
    console.log(selectedModules, 'copy model');

    if (selectedOption == 'copy') {
      await dispatch(addModule(selectedModules));
    } else {
      await dispatch(addModule(moduleName));
    }
    onClose();
    scrollToTop();
  };
  const handleDraftSave = async () => {
    console.log(moduleName, 'moduleName');
    console.log(selectedModules, 'copy model');

    const draftModuleName = { ...moduleName, preview: false }; // Create a new object with preview set to false
    console.log('checking draft module', draftModuleName);
    if (selectedOption === 'copy') {
      await dispatch(addModule(selectedModules));
    } else {
      await dispatch(addModule(draftModuleName)); // Dispatch the updated draft module name
    }

    onClose();
    scrollToTop();
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleCourseSelect = (event, value) => {
    setSelectedCourse(value);
    if (value) {
      dispatch(fetchCourseDetailsForCopyInSlice(value.id));
    }
  };

  const handleModuleSelect = (value) => {
    console.log(value, 'handleModuleSelect');

    const modifiedValue = value.map((moduleObj) => ({
      ...moduleObj,
      module: {
        ...moduleObj.module,
        id: generateRandomNumber(), // Replace generateNewId with your id generation logic
        title: 'Copied ' + moduleObj.module.title,
      },
    }));

    console.log(modifiedValue, 'Modified handleModuleSelect');
    setSelectedModules(modifiedValue);
  };

  const handleRemoveModule = (module) => {
    setSelectedModules((prevSelectedModules) =>
      prevSelectedModules?.filter((item) => item !== module),
    );
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // This will make the scroll smooth
    });
  };

  return (
    <MainContainer>
      <FormControl
        component="fieldset"
        style={{ marginBottom: '16px', width: '97%' }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between', // Ensures radio buttons on the left and icons on the right
          }}
        >
          {/* Radio Buttons on the Left */}
          <RadioGroup
            aria-label="moduleOption"
            name="moduleOption"
            value={selectedOption}
            onChange={handleOptionChange}
            sx={{ flexDirection: 'row', alignItems: 'center' }}
          >
            <FormControlLabel
              value="create"
              control={<Radio />}
              label="Create Module"
            />
            <FormControlLabel
              value="copy"
              control={<Radio />}
              label="Copy Module"
            />
          </RadioGroup>

          {/* Save and Close Icons on the Right */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Tooltip title="Save">
              <SaveIcon
                sx={{
                  cursor: 'pointer',
                  backgroundColor: '#0684FB',
                  color: 'white',
                  borderRadius: '50%',
                  padding: '8px',
                  '&:hover': { backgroundColor: '#045cbf' },
                }}
                onClick={handleSave}
              />
            </Tooltip>
            <Tooltip title="Close">
              <CloseIcon
                sx={{
                  cursor: 'pointer',
                  ':hover': { color: 'red' },
                }}
                onClick={onClose}
              />
            </Tooltip>
          </Box>
        </Box>
      </FormControl>

      <div
        style={{ borderBottom: '1px solid grey', marginBottom: '16px' }}
      ></div>

      {selectedOption === 'create' && (
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12}>
            <Typography style={{ marginBottom: '8px', marginTop: '22px' }}>
              Module Title
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Enter Module Name"
              variant="outlined"
              fullWidth
              name="title"
              value={moduleName.title}
              onChange={handleModuleNameChange}
              required
            />
          </Grid>
          {/* <Grid item xs={12}>
            <Typography>Description</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              placeholder="Enter Description"
              variant="outlined"
              rows="5"
              multiline
              fullWidth
              name="description"
              value={moduleName.description}
              onChange={handleModuleNameChange}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>Module Number</Typography>
            <TextField
              id="outlined-basic"
              placeholder="Enter Module Number"
              variant="outlined"
              fullWidth
              name="number"
              type="number"
              value={moduleName.number}
              onChange={handleModuleNameChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>Estimated Time</Typography>
            <TextField
              id="outlined-basic"
              placeholder="Enter Estimated Time"
              variant="outlined"
              fullWidth
              name="EstTime"
              value={moduleName.EstTime}
              onChange={handleModuleNameChange}
              required
            />
          </Grid> */}
          <Grid item xs={12}>
            <Typography>Learning Objectives</Typography>
          </Grid>
          <Grid item xs={12}>
            <JoditReact
              value={moduleName.objectives}
              config={{
                style: {},
              }}
            />
          </Grid>
        </Grid>
      )}

      {selectedOption === 'copy' && (
        <>
          <FormControl fullWidth style={{ marginTop: '16px' }}>
            <Autocomplete
              options={coursesList.map((course) => ({
                title: course.generalInformation.title,
                id: course._id,
              }))}
              value={selectedCourse}
              onChange={handleCourseSelect}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Course"
                  variant="outlined"
                />
              )}
            />
          </FormControl>
          {selectedCourse && (
            <>
              <FormControl fullWidth style={{ marginTop: '16px' }}>
                <Autocomplete
                  multiple
                  options={courseDetailsForCopy?.modules?.map((module) => ({
                    module,
                  }))}
                  value={selectedModules.module}
                  onChange={(event, value) => handleModuleSelect(value)}
                  getOptionLabel={(option) => option?.module?.title}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Module"
                      variant="outlined"
                    />
                  )}
                />
              </FormControl>
              <div
                style={{ marginTop: '16px', display: 'flex', flexWrap: 'wrap' }}
              >
                {selectedModules?.module?.map((module) => (
                  <Chip
                    key={module?.title}
                    label={module?.title}
                    onDelete={() => handleRemoveModule(module)}
                    style={{ marginRight: '8px', marginBottom: '8px' }}
                  />
                ))}
              </div>
            </>
          )}
        </>
      )}

      <div
        style={{
          marginTop: '16px',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '1rem',
        }}
      >
        {/* <Button
          variant="contained"
          onClick={handleSave}
          style={{ backgroundColor: '#0684FB', color: 'white', textTransform: 'none'  }}
        >
          Submit
        </Button> */}
        <Button
          variant="contained"
          onClick={handleDraftSave}
          style={{
            backgroundColor: '#0684FB',
            color: 'white',
            textTransform: 'none',
          }}
        >
          Save as Draft
        </Button>
      </div>
    </MainContainer>
  );
};

export default AddModuleDialog;
