import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Switch,
  FormControlLabel,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  Box,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import NotificationTabs from './NotificationTabs';
import CommonButton from '../Utilities/CommonButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchNotificationSettings,
  toggleNotificationSetting,
  deleteNotificationSetting,
  updateNotificationSettings,
} from '../../features/notification/NotificationSlice';
import { Page } from '../../styles/Notification/NotificationListStyles';
import { HeadingH3 } from '../../styles/Common/CommonStyles';
import {
  WholePage,
} from '../../styles/Certificate/CertificateStyle';
import {
  SettingHeading,
  SettingDiv,
  SingleSetting,
} from '../../styles/Notification/NotificationStyles';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const NotificationSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, settings } = useSelector((state) => state.notification);
  const [editMode, setEditMode] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteSettingId, setDeleteSettingId] = useState(null);
  const [editedSettings, setEditedSettings] = useState({});

  useEffect(() => {
    if (!settings || settings.length === 0) {
      dispatch(fetchNotificationSettings());
    }
  }, [dispatch, settings]);

  const adminSettings = settings?.filter((s) =>
    s.roles.some((r) => r.role === 'Admin'),
  );
  const instructorSettings = settings?.filter((s) =>
    s.roles.some((r) => r.role === 'Instructor'),
  );
  const learnerSettings = settings?.filter((s) =>
    s.roles.some((r) => r.role === 'Learner'),
  );

  const handleToggle = async (setting, role) => {
    const roleObject = setting.roles.find((r) => r.role === role);

    if (roleObject) {
      const newIsEnabled = !roleObject.isEnabled;

      try {
        await dispatch(
          toggleNotificationSetting({
            settingId: setting._id,
            role,
            isEnabled: newIsEnabled,
          }),
        );
        dispatch(fetchNotificationSettings());
      } catch (error) {
        console.error('Error toggling notification setting:', error);
      }
    }
  };

  const handleEditClick = () => {
    setEditMode(!editMode);
    if (!editMode) {
      // Initialize editedSettings when entering edit mode
      const initialEditedSettings = settings.reduce((acc, setting) => {
        acc[setting._id] = {
          name: setting.settingsName || '',
          description: setting.description || '',
        };
        return acc;
      }, {});
      setEditedSettings(initialEditedSettings);
    }
  };

  const handleSaveClick = async () => {
    // Prepare the updates by filtering unchanged settings
    const updatedSettings = Object.entries(editedSettings)
      .filter(([id, { name, description }]) => {
        const originalSetting = settings.find((s) => s._id === id);
        return (
          name !== originalSetting.settingsName ||
          description !== originalSetting.description
        );
      })
      .map(([id, updates]) => ({ id, data: updates }));

    // Update each setting individually
    try {
      await Promise.all(
        updatedSettings.map(({ id, data }) =>
          dispatch(updateNotificationSettings({ id, data })),
        ),
      );
      setEditMode(false);
      toast.success('Notification settings updated successfully!');
      dispatch(fetchNotificationSettings());
    } catch (error) {
      console.error('Error in handleSaveClick:', error);
      toast.error('Failed to save notification settings.');
    }
  };

  const handleDeleteClick = (settingId) => {
    setDeleteSettingId(settingId);
    setOpenDialog(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await dispatch(deleteNotificationSetting(deleteSettingId));
      setOpenDialog(false);
      dispatch(fetchNotificationSettings());
      setEditMode(false);

      toast.success('Notification setting deleted successfully!');
    } catch (error) {
      console.error('Error in handleConfirmDelete:', error);
      toast.error('Failed to delete notification setting.');
    }
  };

  const handleSettingChange = (id, field, value) => {
    setEditedSettings((prevSettings) => ({
      ...prevSettings,
      [id]: {
        ...prevSettings[id],
        [field]: value,
      },
    }));
  };

  const { t } = useTranslation();

  return (
    <>
      <NotificationTabs />
      <WholePage style={{ marginTop: '35px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '26px',
            marginRight: '42px',
            gap: '1rem',
          }}
        >
          <Tooltip title="Edit Settings" placement="bottom">
            <span>
              <CommonButton onClick={handleEditClick} label="Edit" />
            </span>
          </Tooltip>

          {editMode && (
            <Tooltip title="Save Changes" placement="bottom">
              <span>
                <CommonButton onClick={handleSaveClick} label="Save" />
              </span>
            </Tooltip>
          )}
          <Tooltip title="Create New Setting" placement="bottom">
            <span>
              <CommonButton
                onClick={() => navigate('/Notification/createsettings')}
                label={t('notification.certificateCreateLabel')}
              />
            </span>
          </Tooltip>
        </div>

        <Page>
          {adminSettings?.length > 0 && (
            <HeadingH3>{t('notification.adminLabel')}</HeadingH3>
          )}

          <SettingDiv>
            <Grid container spacing={2}>
              {adminSettings?.map((setting, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <SingleSetting>
                    {!editMode && (
                      <FormControlLabel
                        control={
                          <Switch
                            checked={
                              setting.roles.find((r) => r.role === 'Admin')
                                ?.isEnabled
                            }
                            color="primary"
                            onChange={() => handleToggle(setting, 'Admin')}
                          />
                        }
                      />
                    )}
                    <div>
                      {!editMode ? (
                        <>
                          <SettingHeading>
                            {setting.settingsName}
                          </SettingHeading>
                          <p>{setting.description}</p>
                        </>
                      ) : (
                        <>
                          <TextField
                            label="Name"
                            value={editedSettings[setting._id]?.name || ''}
                            onChange={(e) =>
                              handleSettingChange(
                                setting._id,
                                'name',
                                e.target.value,
                              )
                            }
                            fullWidth
                            size="small"
                            margin="dense"
                          />
                          <TextField
                            label="Description"
                            value={
                              editedSettings[setting._id]?.description || ''
                            }
                            onChange={(e) =>
                              handleSettingChange(
                                setting._id,
                                'description',
                                e.target.value,
                              )
                            }
                            fullWidth
                            multiline
                            size="small"
                            margin="dense"
                          />
                        </>
                      )}
                    </div>
                    {editMode && (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          p: 4,
                        }}
                      >
                        <Box
                          sx={{ cursor: 'pointer', ':hover': { color: 'red' } }}
                        >
                          <DeleteIcon
                            sx={{
                              color: '#0684FB',
                              ':hover': { color: 'red' },
                              cursor: 'pointer',
                            }}
                          />
                        </Box>
                      </Box>
                    )}
                  </SingleSetting>
                </Grid>
              ))}
            </Grid>
          </SettingDiv>

          {instructorSettings?.length > 0 && (
            <HeadingH3>{t('notification.instructorLabel')}</HeadingH3>
          )}

          <SettingDiv>
            <Grid container spacing={2}>
              {instructorSettings?.map((setting, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <SingleSetting>
                    {!editMode && (
                      <FormControlLabel
                        control={
                          <Switch
                            checked={
                              setting.roles.find((r) => r.role === 'Instructor')
                                ?.isEnabled
                            }
                            color="primary"
                            onChange={() => handleToggle(setting, 'Instructor')}
                          />
                        }
                      />
                    )}
                    <div>
                      {!editMode ? (
                        <>
                          <SettingHeading>
                            {setting.settingsName}
                          </SettingHeading>
                          <p>{setting.description}</p>
                        </>
                      ) : (
                        <>
                          <TextField
                            label="Name"
                            value={editedSettings[setting._id]?.name || ''}
                            onChange={(e) =>
                              handleSettingChange(
                                setting._id,
                                'name',
                                e.target.value,
                              )
                            }
                            fullWidth
                            size="small"
                            margin="dense"
                          />
                          <TextField
                            label="Description"
                            value={
                              editedSettings[setting._id]?.description || ''
                            }
                            onChange={(e) =>
                              handleSettingChange(
                                setting._id,
                                'description',
                                e.target.value,
                              )
                            }
                            fullWidth
                            multiline
                            size="small"
                            margin="dense"
                          />
                        </>
                      )}
                    </div>
                    {editMode && (
                      <Box 
                      sx={{
                        display:'flex',
                        justifyContent:'flex-end',
                        p:4,
                      }}
                      >
                          <Box
                          sx={{cursor:'pointer',':hover':{color:'red'}}}
                          >
                              <DeleteIcon sx={{
                                color:'#0684FB',
                                ':hover':{color:'red'},
                                cursor:'pointer',
                              }} />
                          </Box>
                      </Box>
                    )}
                  </SingleSetting>
                </Grid>
              ))}
            </Grid>
          </SettingDiv>

          {learnerSettings?.length > 0 && (
            <HeadingH3>{t('notification.learnerLabel')}</HeadingH3>
          )}

          <SettingDiv>
            <Grid container spacing={2}>
              {learnerSettings?.map((setting, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <SingleSetting>
                    {!editMode && (
                      <FormControlLabel
                        control={
                          <Switch
                            checked={
                              setting.roles.find((r) => r.role === 'Learner')
                                ?.isEnabled
                            }
                            color="primary"
                            onChange={() => handleToggle(setting, 'Learner')}
                          />
                        }
                      />
                    )}
                    <div>
                      {!editMode ? (
                        <>
                          <SettingHeading>
                            {setting.settingsName}
                          </SettingHeading>
                          <p>{setting.description}</p>
                        </>
                      ) : (
                        <>
                          <TextField
                            label="Name"
                            value={editedSettings[setting._id]?.name || ''}
                            onChange={(e) =>
                              handleSettingChange(
                                setting._id,
                                'name',
                                e.target.value,
                              )
                            }
                            fullWidth
                            size="small"
                            margin="dense"
                          />
                          <TextField
                            label="Description"
                            value={
                              editedSettings[setting._id]?.description || ''
                            }
                            onChange={(e) =>
                              handleSettingChange(
                                setting._id,
                                'description',
                                e.target.value,
                              )
                            }
                            fullWidth
                            multiline
                            size="small"
                            margin="dense"
                          />
                        </>
                      )}
                    </div>
                    {editMode && (
                      <Box 
                      sx={{
                        display:'flex',
                        justifyContent:'flex-end',
                        p:4,
                      }}
                      >
                        <Box sx={{cursor:'pointer',':hover':{color:'red'}}}>
                           <DeleteIcon 
                           sx={{
                            color:'#0684FB',
                            ':hover':{color:'red'},
                            cursor:'pointer'
                           }}/>
                        </Box>
                      </Box>
                    )}
                  </SingleSetting>
                </Grid>
              ))}
            </Grid>
          </SettingDiv>
        </Page>

        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this notification setting?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </WholePage>
    </>
  );
};

export default NotificationSettings;
