import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import JoditEditor from 'jodit-react';
import { TextField, MenuItem, Box, Tooltip } from '@mui/material';
import NotificationTabs from './NotificationTabs';
import CommonButton from '../Utilities/CommonButton';
import {
  createNotificationTemplate,
  fetchNotificationSettings,
} from '../../features/notification/NotificationSlice';
import { fetchRole } from '../../features/userModule/userModuleSlice';

import {
  CertificateForm,
  JoditEditorDiv,
  WholePage,
} from '../../styles/Certificate/CertificateStyle';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const CreateNotification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedRole, setSelectedRole] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});
  const { roles } = useSelector((state) => state.users) || {};
  const { settings: notificationSettings } = useSelector(
    (state) => state.notification,
  );

  useEffect(() => {
    if (!roles.data || roles.data.length === 0) {
      dispatch(fetchRole());
    }
    dispatch(fetchNotificationSettings());
  }, [dispatch, roles.data]);

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
    setTemplateName('');
    setFieldErrors((prevErrors) => ({ ...prevErrors, selectedRole: '' }));
  };

  const handleSubmit = () => {
    const errors = {};

    if (selectedRole.trim() === '') {
      errors.selectedRole = 'Select Role.';
    }

    if (templateName.trim() === '') {
      errors.templateName = 'Select Template.';
    }

    if (subject.trim() === '') {
      errors.subject = 'Subject is required.';
    }

    if (content.trim() === '') {
      alert('Enter Notification body');
      return;
    }

    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }

    const templateData = {
      role: selectedRole,
      notificationType: templateName,
      subject: subject,
      notificationBody: content,
    };

    dispatch(createNotificationTemplate(templateData))
      .unwrap()
      .then(() => {
        setTemplateName('');
        setSubject('');
        setContent('');
        toast.success('Notification template created successfully!');
        navigate('/Notification/listnotification');
      })
      .catch(() => {
        toast.success(
          'Notification template created successfully. Please try again.',
        );
      });
  };

  const filteredSettings = useMemo(() => {
    return notificationSettings.filter((setting) =>
      setting.roles.some((role) => role.role === selectedRole),
    );
  }, [notificationSettings, selectedRole]);

  const config = useMemo(
    () => ({
      height: 400,
      readonly: false,
    }),
    [],
  );

  const { t } = useTranslation();
  return (
    <>
      <NotificationTabs />
      <WholePage>
        <CertificateForm>
          <div>
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'start',
                marginTop: '5px',
              }}
            >
              <TextField
                label="Role"
                select
                value={selectedRole}
                onChange={handleRoleChange}
                error={Boolean(fieldErrors.selectedRole)}
                helperText={fieldErrors.selectedRole}
                fullWidth
              >
                {Array.isArray(roles?.data) &&
                  roles?.data.map((role) => (
                    <MenuItem key={role._id} value={role.name}>
                      {role.name}
                    </MenuItem>
                  ))}
              </TextField>

              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  alignItems: 'start',
                  width: '100%',
                }}
              >
                <TextField
                  label={t('notification.typeNotificationLabel')}
                  select
                  value={templateName}
                  onChange={(e) => setTemplateName(e.target.value)}
                  fullWidth
                  error={Boolean(fieldErrors.templateName)}
                  helperText={
                    selectedRole
                      ? fieldErrors.templateName
                      : 'Select the role first.'
                  }
                  InputLabelProps={{ shrink: true }}
                  disabled={!selectedRole}
                >
                  {filteredSettings.map((setting) => (
                    <MenuItem key={setting._id} value={setting.settingsName}>
                      {setting.settingsName}
                    </MenuItem>
                  ))}
                </TextField>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '8px',
                  }}
                >
                  <Tooltip title="Create New Notification">
                    <span>
                      {' '}
                      <CommonButton
                        onClick={handleSubmit}
                        label={t('notification.createLabel')}
                      />
                    </span>
                  </Tooltip>
                </div>
              </Box>
            </Box>
          </div>
          <TextField
            label={t('notification.subjectLabel')}
            variant="outlined"
            margin="normal"
            value={subject}
            error={Boolean(fieldErrors.subject)}
            helperText={fieldErrors.subject}
            onChange={(e) => setSubject(e.target.value)}
          />
          <JoditEditorDiv>
            <JoditEditor
              value={content}
              onChange={(newContent) => setContent(newContent)}
              config={config}
            />
          </JoditEditorDiv>
        </CertificateForm>
      </WholePage>
    </>
  );
};

export default CreateNotification;
