// code 2
import bg from '../../images/heroBG.jpg'
import { useTranslation } from 'react-i18next';
import { truncateText } from '../Utilities/TextAppendEllipsis';
import React, { useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
import SiteBuilderTabs from './SiteBuilderTabs';
import { useDispatch, useSelector } from 'react-redux';
import SitebuilderRoutes from './SitebuilderRoutes';
import Loader from '../Utilities/Loader';
import Sitebuilder from './Sitebuilder';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


import ShareIcon from '@mui/icons-material/Share'
import FileCopyIcon from '@mui/icons-material/FileCopy';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Menu,
  Pagination,
  Tooltip,
  Container,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  CardActions,
  CardContent,
  Typography,
  Grid,
  CircularProgress,
  Button,
  Modal,
  Box,
  TextField,
  Card,
  IconButton,
} from '@mui/material';
import {
  Edit,
  Delete,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import { palette } from '../../styles/palette';
import { useNavigate } from 'react-router-dom';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import {
  SearchCont,
  CreatePageBtnCont,
  Container1,
  NotFoundMatchCont,
  FilterBtn,
  text1,
} from '../../styles/Sitebuilder/UserSavedTemplatesStyle';
import { createTemplate, deleteTemplate, getAllTemplates, getDefaultPages } from '../../features/sitebuilder/SitebuilderSlice';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import CommonButton from '../Utilities/CommonButton';
import CloseIcon from '@mui/icons-material/Close';

const AllTemplates = () => {
  const [shareModalOpen,setShareModalOpen]=useState(false);
  const [loading, setLoading] = useState(false);
  const [searchToggle, setSearchToggle] = useState(false);
  const [htmlContent, setHtmlContent] = useState('');
  const [pageTemplate, setPageTemplate] = useState('');
  const [cssTemplate, setCssTemplate] = useState('');
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [pageName, setPageName] = useState('');
  const [pageType, setPageType] = useState('');
  const [title, setTitle] = useState('');
  const [templates, setTemplates] = useState('');
  const [previewImage, setPreviewImage] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [rendered, setRendered] = useState(0); //for redering filter data
  const API_URL = process.env.REACT_APP_URL;
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const dispatch = useDispatch();
  const [copySuccess, setCopySuccess] = useState(false);
  const { alltemplates, status, error } = useSelector(
    (state) => state.userTemplates,
  );

  const [searchInput, setSearchInput] = useState('');
  const [dropdownSelection, setDropdownSelection] = useState('');

  console.log('all temp 1:', alltemplates);

  // this a value container
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchor, setAnchor] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [selectedPage, setSelectedPage] = useState([]);
  const [pageInputValue, setPageInputValue] = useState('');
  const [inputValue, setInputValue] = useState([]);
  const [selectedType, setSelectedType] = useState(''); // New state for selected type
  const isOpen = Boolean(anchorEl);
  const isPageTypeOpen = Boolean(anchor);
  const [template, setTemplate] = useState('');
  const [defaultTemplates, setDefaultTemplates] = useState([]);

  const filterOptions = [

  


    // t('sitebuilder.Coursepage'),
    // t('sitebuilder.Coursepage'),
    // t('sitebuilder.Coursepage'),
    {value:"Custom page",label:t('sitebuilder.Custom page')},
    {value:"Bundle page",label:t('sitebuilder.Bundle page')},
    {value:"Default page",label:t('sitebuilder.Default page')},
    {value:"Published Pages",label:t('sitebuilder.Published Pages')},
    {value:"Homepage",label:t('sitebuilder.Homepage')},
    {value:"Course page",label:t('sitebuilder.Course page')},
    



  ];

  const pageOptions = [
    {value:"Custom page",label:t('sitebuilder.Custom page')},
    {value:"Bundle page",label:t('sitebuilder.Bundle page')},
    {value:"Default page",label:t('sitebuilder.Default page')},
    {value:"Homepage",label:t('sitebuilder.Homepage')},
    {value:"Course page",label:t('sitebuilder.Course page')},
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePageTypeClick = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleShare=(template)=>{
    setShareModalOpen(true);
    setTemplate(template);
    
    }

  const handleClosed = () => {
    setAnchorEl(null);
  };
  const handleMenuClosed = () => {
    setAnchor(null);
  };

  const handlePageChange = (event) => {
    setSelectedPage(event.target.value);
    setPageType(event.target.value);
    console.log(event.target.value);
  };

  const handleFilterChange = (event) => {
    const value = event.target.value;
    console.log('checking ', value);

    const newFilters = selectedFilter.includes(value)
      ? selectedFilter.filter((item) => item !== value)
      : [...selectedFilter, value];

    setSelectedFilter(newFilters);
    if (selectedFilter) {
      setRendered(true);
    }

    

    if (!selectedFilter) {
      setRendered(false);
    }
    console.log(newFilters);

    // Set the selected value to selectedType
  };
  // til this
  const handleCopyLink = () => {
    navigator.clipboard.writeText(template.netlifyLink);
    setCopySuccess(true);
    toast.success('Link copied to clipboard!');
  };
  // Example selected filters

  const filteredExample = alltemplates.filter((template) =>
    selectedFilter.includes(template.pageType),
  );

  const filteredTemplates = alltemplates.filter((template) => {
    const matchesSearchInput =
      template.categoryId.toLowerCase().includes(searchInput.toLowerCase()) ||
      template.pageName.toLowerCase().includes(searchInput.toLowerCase()) ||
      template.pageType?.toLowerCase().includes(searchInput.toLowerCase());
    const matchesDropdownSelection =
      dropdownSelection === '' || template.title === dropdownSelection;

    return matchesSearchInput && matchesDropdownSelection;
  });

  const handleEditClick = (id) => {
    navigate(`/SiteBuilderB/${id}`);
  };
  const sortedTemplates = filteredTemplates.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
  );
  const truncatedTitleFunction = (title) => {
    return truncateText(title, 20);
  };

  const totalTemplates =
  filteredExample.length > 0
    ? filteredExample.length
    : sortedTemplates.length;

    
const templatesToDisplay =
  filteredExample.length > 0 ? filteredExample : sortedTemplates;

  useEffect(() => {
    dispatch(getAllTemplates());

    console.log('after dispatch:', alltemplates);
  }, [dispatch, selectedFilter]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    // Fetch the default pages
    dispatch(getDefaultPages())
      .then((response) => {
        // Assuming the response contains an array of templates
        setDefaultTemplates(response.payload);
      })
      .catch((error) => {
        console.error('Error fetching default pages:', error);
      });
  }, [dispatch]);


  // Add and Delete Templates:
  const handleAddTemplate = async () => {
    const userName = 'current user'; //why in string

    const newTemplate = {
      pageName,
      pageType,
      categoryId: 'UserSavedPages',
      pageTemplate: pageTemplate? pageTemplate : `<body id="itng">
      <header class="text-gray-600 body-font">
        <div class="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
          <a class="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0"><svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="w-10 h-10 text-white p-2 bg-indigo-500 rounded-full">
            <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5">
            </path>
            </svg><span class="ml-3 text-xl">Tailblocks</span></a>
          <nav class="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400-flex flex-wrap items-center text-base justify-center">
            <a class="mr-5 hover:text-gray-900">First Link</a>
            <a class="mr-5 hover:text-gray-900">Second Link</a>
            <a class="mr-5 hover:text-gray-900">Third Link</a>
            <a class="mr-5 hover:text-gray-900">Fourth Link</a>
          </nav>
          <button class="inline-flex items-center bg-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0">Button<svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="w-4 h-4 ml-1">
            <path d="M5 12h14M12 5l7 7-7 7">
            </path>
            </svg></button>
        </div>
      </header>
      <section class="text-gray-600 body-font">
        <div class="container flex flex-wrap px-5 py-24 mx-auto items-center">
          <div class="md:w-1/2 md:pr-12 md:py-8 md:border-r md:border-b-0 mb-10 md:mb-0 pb-10 border-b border-gray-200">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">Pitchfork Kickstarter Taxidermy
            </h1>
            <p class="leading-relaxed text-base">Locavore cardigan small batch roof party blue bottle blog meggings sartorial jean shorts kickstarter migas sriracha church-key synth succulents. Actually taiyaki neutra, distillery gastropub pok pok ugh.
            </p>
            <a class="text-indigo-500 inline-flex items-center mt-4">Learn More<svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="w-4 h-4 ml-2">
              <path d="M5 12h14M12 5l7 7-7 7">
              </path>
              </svg></a>
          </div>
          <div class="flex flex-col md:w-1/2 md:pl-12">
            <h2 class="title-font font-semibold text-gray-800 tracking-wider text-sm mb-3">CATEGORIES
            </h2>
            <nav class="flex flex-wrap list-none -mb-1">
              <li class="lg:w-1/3 mb-1 w-1/2">
                <a class="text-gray-600 hover:text-gray-800">First Link</a>
              </li>
              <li class="lg:w-1/3 mb-1 w-1/2">
                <a class="text-gray-600 hover:text-gray-800">Second Link</a>
              </li>
              <li class="lg:w-1/3 mb-1 w-1/2">
                <a class="text-gray-600 hover:text-gray-800">Third Link</a>
              </li>
              <li class="lg:w-1/3 mb-1 w-1/2">
                <a class="text-gray-600 hover:text-gray-800">Fourth Link</a>
              </li>
              <li class="lg:w-1/3 mb-1 w-1/2">
                <a class="text-gray-600 hover:text-gray-800">Fifth Link</a>
              </li>
              <li class="lg:w-1/3 mb-1 w-1/2">
                <a class="text-gray-600 hover:text-gray-800">Sixth Link</a>
              </li>
              <li class="lg:w-1/3 mb-1 w-1/2">
                <a class="text-gray-600 hover:text-gray-800">Seventh Link</a>
              </li>
              <li class="lg:w-1/3 mb-1 w-1/2">
                <a class="text-gray-600 hover:text-gray-800">Eighth Link</a>
              </li>
            </nav>
          </div>
        </div>
      </section>
    </body>`,
          htmlTemplate:htmlContent? htmlContent: `<body id="itng">
      <header class="text-gray-600 body-font">
        <div class="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
          <a class="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0"><svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="w-10 h-10 text-white p-2 bg-indigo-500 rounded-full">
            <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5">
            </path>
            </svg><span class="ml-3 text-xl">Tailblocks</span></a>
          <nav class="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400-flex flex-wrap items-center text-base justify-center">
            <a class="mr-5 hover:text-gray-900">First Link</a>
            <a class="mr-5 hover:text-gray-900">Second Link</a>
            <a class="mr-5 hover:text-gray-900">Third Link</a>
            <a class="mr-5 hover:text-gray-900">Fourth Link</a>
          </nav>
          <button class="inline-flex items-center bg-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0">Button<svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="w-4 h-4 ml-1">
            <path d="M5 12h14M12 5l7 7-7 7">
            </path>
            </svg></button>
        </div>
      </header>
      <section class="text-gray-600 body-font">
        <div class="container flex flex-wrap px-5 py-24 mx-auto items-center">
          <div class="md:w-1/2 md:pr-12 md:py-8 md:border-r md:border-b-0 mb-10 md:mb-0 pb-10 border-b border-gray-200">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">Pitchfork Kickstarter Taxidermy
            </h1>
            <p class="leading-relaxed text-base">Locavore cardigan small batch roof party blue bottle blog meggings sartorial jean shorts kickstarter migas sriracha church-key synth succulents. Actually taiyaki neutra, distillery gastropub pok pok ugh.
            </p>
            <a class="text-indigo-500 inline-flex items-center mt-4">Learn More<svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="w-4 h-4 ml-2">
              <path d="M5 12h14M12 5l7 7-7 7">
              </path>
              </svg></a>
          </div>
          <div class="flex flex-col md:w-1/2 md:pl-12">
            <h2 class="title-font font-semibold text-gray-800 tracking-wider text-sm mb-3">CATEGORIES
            </h2>
            <nav class="flex flex-wrap list-none -mb-1">
              <li class="lg:w-1/3 mb-1 w-1/2">
                <a class="text-gray-600 hover:text-gray-800">First Link</a>
              </li>
              <li class="lg:w-1/3 mb-1 w-1/2">
                <a class="text-gray-600 hover:text-gray-800">Second Link</a>
              </li>
              <li class="lg:w-1/3 mb-1 w-1/2">
                <a class="text-gray-600 hover:text-gray-800">Third Link</a>
              </li>
              <li class="lg:w-1/3 mb-1 w-1/2">
                <a class="text-gray-600 hover:text-gray-800">Fourth Link</a>
              </li>
              <li class="lg:w-1/3 mb-1 w-1/2">
                <a class="text-gray-600 hover:text-gray-800">Fifth Link</a>
              </li>
              <li class="lg:w-1/3 mb-1 w-1/2">
                <a class="text-gray-600 hover:text-gray-800">Sixth Link</a>
              </li>
              <li class="lg:w-1/3 mb-1 w-1/2">
                <a class="text-gray-600 hover:text-gray-800">Seventh Link</a>
              </li>
              <li class="lg:w-1/3 mb-1 w-1/2">
                <a class="text-gray-600 hover:text-gray-800">Eighth Link</a>
              </li>
            </nav>
          </div>
        </div>
      </section>
    </body>`,
          cssTemplate:cssTemplate? cssTemplate: `
    body {
      margin: 0;
      padding:0;
    }
    `,
      userName: userName,
      previewImage: 'image_url', // Add this line if previewImage is coming from the backend
    };
dispatch(createTemplate(newTemplate))
      setPageName('');
      setSelectedPage();
      handleClose();
      navigate('/Sitebuilder/UserSavedTemplates');
    
  };

  const handleDeleteTemplate = async (id) => {
    dispatch(deleteTemplate(id));
  };

  const handlePreviewOpen = (template) => {
    setPreviewImage(template.previewImage);
    setPreviewOpen(true);
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
    setPreviewImage('');
  };
  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1); // Reset to the first page
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTemplates = templatesToDisplay.slice(
    indexOfFirstItem,
    indexOfLastItem,
  );

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${bg})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover', // Ensures the image covers the entire box
          backgroundPosition: 'center', // Centers the image
          width: '100%', // Adjust as needed
          height: 'auto', // Full viewport height, adjust as needed
        }}
      >
        <SiteBuilderTabs />
<section className="course-builder">
          <Container fluid>
            <Grid container spacing={2} sx={{ mb: 2 }}>
              {/* Search Field */}
              <Grid item xs={12} md={5} sm={12}>
                <TextField
                  label={t('sitebuilder.searchText')}
                  variant="outlined"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  style={{
                    marginRight: '20px',
                    minWidth: 400,
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                  }}
                />
              </Grid>
              <Grid item xs={12} md={5} sm={12} sx={{ display: 'flex' }}>
                <FilterBtn onClick={handleClick}>
                  {`${t('sitebuilder.filter')} ${selectedFilter.length > 0 ? `(${selectedFilter.length})` : ''}`}
                  <FilterListIcon className="icon" />
                </FilterBtn>
                <Menu
                  anchorEl={anchorEl}
                  open={isOpen}
                  onClose={handleClosed}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                >
                  <FormGroup>
                    {filterOptions.map((option) => (
                      <MenuItem key={option}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={option.value}
                              checked={selectedFilter.includes(option.value)}
                              onChange={handleFilterChange}
                            />
                          }
                          label={option.label}
                        />
                      </MenuItem>
                    ))}
                  </FormGroup>
                </Menu>
              </Grid>
              <Grid
                item
                xs={12}
                md={2}
                sm={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CommonButton
                  variant="contained"
                  style={{
                    backgroundColor: `${palette.primary}`,
                    width: '3rem',
                  }}
                  onClick={handleOpen}
                  label={t('sitebuilder.createPage')}
                />
              </Grid>
              {filteredExample.length > 0 ? (
                filteredExample.map((template) => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5}
                    lg={4}
                    xl={3}
                    key={template._id}
                  >
                    <Box
                      className="course-card"
                      sx={{
                        width: '100%', // Use a string for percentage values
                        height: '350px', // Fixed height, use a string for pixel values
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', // camelCase for boxShadow
                        transition: '0.3s', // Use a string for transition duration
                        cursor: 'pointer', // Use a string for cursor value
                        display: 'flex',
                        background:'#f3f8f1',
                        flexDirection: 'column', // camelCase for flexDirection
                        justifyContent: 'space-between', // camelCase for justifyContent
                        '&:hover': {
                          transform: 'scale(1.05)', // Use a string for transform values
                        },
                      }}
                    >
                      <Tooltip title={template.pageName}>
                        {/* <Link onClick={() => handleNavigate(course)}> */}
                        <div className="image-container">
                          <div
                            className="blurbg"
                            style={{
                              background: template?.previewImage
                                ? `url(${template.previewImage})`
                                : 'skyblue',
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              color: `${palette.white}`,
                              fontWeight: 'bold',
                              fontSize: '1.5em',
                            }}
                          >
                            {/* {!course?.generalInformation.bannerImage &&
                  truncatedTitleFunction(
                    course?.generalInformation.title,
                  )} */}
                          </div>
                        </div>
                        {/* </Link> */}
                      </Tooltip>
                      <CardContent>
                        <Grid item xs={10}>
                          {/* <Link underline="hover" onClick={() => handleNavigate(course)}> */}
                          <Tooltip title={template.pageName}>
                            <Typography variant="body1" fontWeight="bold">
                              {truncatedTitleFunction(template.pageName)}
                            </Typography>
                          </Tooltip>
                          {/* </Link> */}
                        </Grid>

                        <Grid item xs={12}>
                          <Typography
                            variant="subtitle1"
                            color="textSecondary"
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Tooltip title="Page Type"></Tooltip>
                            {template.pageType
                              ? template.pageType
                              : template.title}
                          </Typography>

                          {/* <Grid
              item
              xs={6}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            ></Grid> */}
                        </Grid>
                      </CardContent>
                      <Divider />
                      <CardActions
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Grid
                          item
                          xs={3}
                          sx={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Tooltip title="Details">
                            <IconButton
                              onClick={() => handlePreviewOpen(template)}
                              sx={{
                                color: 'gray', // Default color (shade of gray)
                                transition: 'color 0.3s ease', // Smooth transition for color change
                                '&:hover': {
                                  color: palette.primary, // Color from your palette on hover
                                },
                              }}
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sx={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Tooltip title="Course Settings">
                            <IconButton
                              color="primary"
                              onClick={() => handleEditClick(template._id)}
                            >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sx={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Tooltip title="Landing Page Design">
                            <IconButton
                              color="primary"
                              onClick={() => handleDeleteTemplate(template._id)}
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        </Grid>

                        <Grid
                          item
                          xs={3}
                          sx={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Tooltip title="Landing Page Design">
                          {template.isPublished && (
    <IconButton color="primary" onClick={() => handleShare(template)}>
      <ShareIcon />
    </IconButton>
  )}
                          </Tooltip>
                        </Grid>
                        {/* <Grid
                          item
                          xs={3}
                          sx={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Tooltip title="Landing Page Design">
                            <IconButton
                              color="primary"
                              onClick={() => openModal(template)}
                            >
                              <FileCopyIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid> */}
                      </CardActions>
                    </Box>
                  </Grid>
                ))
              ) : filteredTemplates.length === 0 ? (
                <Typography variant="h6" style={{ margin: '20px auto' }}>
                  <NotFoundMatchCont>Match not found!</NotFoundMatchCont>
                </Typography>
              ) : (
                sortedTemplates.map((template) => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5}
                    lg={4}
                    xl={3}
                    key={template._id}
                  >
                    <Box
                      className="course-card"
                      sx={{
                        width: '100%', // Use a string for percentage values
                        height: '350px', // Fixed height, use a string for pixel values
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', // camelCase for boxShadow
                        transition: '0.3s', // Use a string for transition duration
                        cursor: 'pointer', // Use a string for cursor value
                        display: 'flex',
                        background:'#f3f8f1',
                        flexDirection: 'column', // camelCase for flexDirection
                        justifyContent: 'space-between', // camelCase for justifyContent
                        '&:hover': {
                          transform: 'scale(1.05)', // Use a string for transform values
                        },
                      }}
                    >
                      <Tooltip title={template.pageName}>
                       
                        <div className="image-container">
                          <div
                            className="blurbg"
                            style={{
                              background: template?.previewImage
                                ? `url(${template.previewImage})`
                                : 'skyblue',
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              color: `${palette.white}`,
                              fontWeight: 'bold',
                              fontSize: '1.5em',
                            }}
                          >
                    
                          </div>
                        </div>
                       
                      </Tooltip>
                      <CardContent>
                        <Grid item xs={10}>
                         
                          <Tooltip title={template.pageName}>
                            <Typography variant="body1" fontWeight="bold">
                              {truncatedTitleFunction(template.pageName)}
                            </Typography>
                          </Tooltip>
                         
                        </Grid>

                        <Grid item xs={12}>
                          <Typography
                            variant="subtitle1"
                            color="textSecondary"
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Tooltip title="Page Type"></Tooltip>
                            {template.pageType
                              ? template.pageType
                              : template.title}
                          </Typography>
                        </Grid>
                      </CardContent>
                      <Divider />
                      <CardActions
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Grid
                          item
                          xs={3}
                          sx={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Tooltip title="Details">
                            <IconButton
                              onClick={() => handlePreviewOpen(template)}
                              sx={{
                                color: 'gray', // Default color (shade of gray)
                                transition: 'color 0.3s ease', // Smooth transition for color change
                                '&:hover': {
                                  color: palette.primary, // Color from your palette on hover
                                },
                              }}
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sx={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Tooltip title="Course Settings">
                            <IconButton
                              color="primary"
                              onClick={() => handleEditClick(template._id)}
                            >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sx={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Tooltip title="Delete">
                            <IconButton
                              color="primary"
                              onClick={() => handleDeleteTemplate(template._id)}
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        {template.isPublished && (
                        <Grid
                          item
                          xs={3}
                          sx={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Tooltip title="Landing Page Design">
                          
    <IconButton color="primary" onClick={() => handleShare(template)}>
      <ShareIcon />
    </IconButton>
  
                          </Tooltip>
                        </Grid>
                        )}
                       
                      </CardActions>
                    </Box>
                  </Grid>
                ))
              )}

             
              
            </Grid>
          </Container>
        </section>
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '25rem',
              height: '16rem',

              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography variant="h6" component="h2" sx={{ margin: '2rem ,0' }}>
              Add New Template
            </Typography>
            <TextField
              fullWidth
              label="Page Name"
              value={pageName}
              onChange={(e) => setPageName(e.target.value)}
              sx={{
                marginTop: '2rem',
                marginBottom: '0.5rem',
              }}
            />

            <div style={{ marginTop: '0.5rem', marginBottom: '1rem' }}>
              <FormControl
                variant="outlined"
                sx={{
                  width: '400px', // Custom width
                  backgroundColor: `${palette.white}`, // Light gray background
                  borderRadius: '4px', // Rounded corners
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'gray', // Custom border color
                    },
                    '&:hover fieldset': {
                      borderColor: '#1565c0', // Darker border on hover
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#1565c0', // Darker border when focused
                    },
                  },
                }}
              >
                  <TextField
          select
          label="Select Page Type"
          variant="outlined"
          fullWidth
          value={pageType}
          onChange={(e)=>setPageType(e.target.value)}
          sx={{ mb: 2 }}
        >
          {pageOptions.map((option) => (
            <MenuItem key={option.label} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
              </FormControl>
              <FormControl fullWidth sx={{ mb: 2 }}>
  <InputLabel id="select-page-template-label">Select Page Template</InputLabel>
  <Select
    labelId="select-page-template-label"
    value={template.pageName} // Assuming this is the state variable holding the selected template name
    label="Select Page Template"
    onChange={(e) => {
      const selectedTemplate = defaultTemplates.find(template => template.pageName === e.target.value);
      if (selectedTemplate) {
        setHtmlContent(selectedTemplate.htmlTemplate);
        setPageTemplate(selectedTemplate.pageTemplate);
        setCssTemplate(selectedTemplate.cssTemplate);
      }
    }}
  >
    {Array.isArray(defaultTemplates) && defaultTemplates.length > 0 ? (
      defaultTemplates.map((template) => (
        <MenuItem key={template._id} value={template.pageName}>
          {template.pageName}
        </MenuItem>
      ))
    ) : (
      <MenuItem disabled>No templates available</MenuItem>
    )}
  </Select>
</FormControl>
              

              {/* Display the selected type */}
            </div>
            {/* -------------------------------------------------------------------------- */}
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CommonButton
                variant="contained"
                color="primary"
                onClick={handleAddTemplate}
                style={{ marginTop: '20px', width: '3rem' }}
                label={'Add'}
              ></CommonButton>
            </div>
          </Box>
        </Modal>
        <Modal open={previewOpen} onClose={handlePreviewClose}>
          <Box
            sx={{
              height: '88vh',
              width: '90vw',
              position: 'absolute',
              top: '2%',
              left: '5%',
              // transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography variant="h6" component="h2">
              Template Preview
            </Typography>
            <img
              src={previewImage}
              style={{ width: '90vw', height: '80vh', marginTop: '20px' }}
            />
          </Box>
        </Modal>
        <Modal open={shareModalOpen} onClose={() => setShareModalOpen(false)}>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '400px',
      bgcolor: 'background.paper',
      boxShadow: 24,
      borderRadius: '12px',
      p: 4,
      animation: 'fadeIn 0.3s ease-in-out',
    }}
  >
    {/* Cross Button */}
    <IconButton
      sx={{ position: 'absolute', top: 8, right: 8 }}
      onClick={() => setShareModalOpen(false)}
    >
      <CloseIcon />
    </IconButton>

    {/* Title */}
    <Typography variant="h6" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
      Share Template Link
    </Typography>

    {/* Display Link in TextField */}
    <TextField
      value={template.netlifyLink}
      fullWidth
      InputProps={{
        readOnly: true,
        endAdornment: (
          <Tooltip title="Copy Link">
            <IconButton onClick={handleCopyLink}>
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        ),
      }}
      sx={{
        mb: 2,
        borderRadius: '4px',
        '& .MuiOutlinedInput-root': {
          transition: '0.3s ease',
        },
        '& .MuiOutlinedInput-root:hover': {
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
        },
      }}
    />

   
  </Box>
</Modal>
      </Box>
    </>
  );
};

export default AllTemplates;
