import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CommonButton from '../Utilities/CommonButton';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { PasswordContainer } from '../../styles/Users/UsersStyles';

const ResetPassword = () => {
  const [pass, setPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [passMatch, setPassMatch] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClick = () => {
    if (pass === confirmPass) {
      setPassMatch(true);
    } else {
      setPassMatch(false);
    }
  };

  const validatePassword = (pwd) => {
    const regex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}$/;
    return regex.test(pwd);
  };
  const handlePassChange = (event) => {
    const password = event.target.value;
    setPass(password);
    setIsValid(validatePassword(password));
  };
  const handleConfirmPassChange = (event) => {
    const confirmPassword = event.target.value;
    setConfirmPass(confirmPassword);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((preShowPassword) => !preShowPassword);
  };
  return (
    <PasswordContainer>
      <Card sx={{ border: '0px solid black', width: '40%' }}>
        <CardContent>
          <Grid>
            <Typography sx={{ mb: 1 }} textAlign="center" variant="h5">
              Reset Your Password
            </Typography>
          </Grid>
          <Grid>
            <Typography sx={{ mb: 1 }} textAlign="center" variant="body2">
              Enter a new password to reset the password on your account
            </Typography>
          </Grid>
          <Grid>
            <TextField
              label="Password"
              variant="outlined"
              fullWidth
              type={showPassword ? 'text' : 'password'}
              margin="normal"
              value={pass}
              onChange={handlePassChange}
              sx={{ marginBottom: '10px' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={handleTogglePasswordVisibility}
                    />
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid>
            <TextField
              label="Confirm Password"
              variant="outlined"
              fullWidth
              type="password"
              margin="normal"
              value={confirmPass}
              onChange={handleConfirmPassChange}
              sx={{ marginBottom: '20px' }}
            />
            {!passMatch && (
              <span style={{ color: 'red' }}>Password do not match</span>
            )}
            {!isValid && (
              <span style={{ color: 'red' }}>
                Password must contain at least one uppercase letter, one symbol,
                one number, and be at least 8 characters long.
              </span>
            )}
          </Grid>
          <Grid display="flex" justifyContent="center" alignItems="center">
            <CommonButton label="Reset Password" onClick={handleClick} />
          </Grid>
        </CardContent>
      </Card>
    </PasswordContainer>
  );
};
export default ResetPassword;
