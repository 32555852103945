import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCourseDetailsForCopyInSlice,
  addItem,
} from '../../../features/courses/addCourseDetailsSlice';
import {
  FormControl,
  TextField,
  Chip,
  Autocomplete,
  Button,
} from '@mui/material';
import { useParams } from 'react-router-dom';
const CopyTopicContainer = ({ onClose }) => {
  const [copyTopic, setCopyTopic] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [copyLesson, setCopyLesson] = useState([]);
  const [selectedLessons, setSelectedLessons] = useState([]);
  const [selectedOption, setSelectedOption] = useState('create');
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedModules, setSelectedModules] = useState([]);

  const courses = useSelector((state) => state.addCourseDetails.coursesList);
  const courseDetailsForCopy = useSelector(
    (state) => state.addCourseDetails.courseDetailsForCopy,
  );
  const { id, courseStructure } = useParams();

  const dispatch = useDispatch();

  const handleCourseSelect = (event, value) => {
    console.log(value, 'handleCourseSelect');
    setSelectedCourse(value);
    if (value) {
      dispatch(fetchCourseDetailsForCopyInSlice(value.id));
    }
  };

 const handleModuleSelect = (value) => {
  console.log(value ,'handleModuleSelect ')
  setSelectedModules(value);
};

  const generateRandomNumber = () => {
    const min = 100000;
    const max = 999999;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };


const handleLessonChange = (event, value) => {
  console.log(value , "handleCopyLessonChange")
  setCopyLesson(value);
  setSelectedLessons(value);
};

  const handleOptionSelect = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleTopicChange = (event, value) => {
    setCopyTopic(event.target.value);
    console.log(value, 'handleTopicChange');
    const modifiedValue = value.map((lessonObj) => ({
      ...lessonObj,
      // Replace generateNewId with your id generation logic
      title: 'Copied ' + lessonObj.title,
      id: generateRandomNumber(),
      courseStructure: courseStructure,
    }));
    console.log(modifiedValue, 'modifiedValue');
    setSelectedTopics(modifiedValue);
  };

  const handleTopicSelect = (event) => {
    const selectedTopic = event.target.value;
    setSelectedTopics((prevSelectedTopics) => [
      ...prevSelectedTopics,
      selectedTopic,
    ]);
    setCopyTopic([]);
  };

  const handleRemoveTopic = (topic) => {
    setSelectedTopics((prevSelectedTopics) =>
      prevSelectedTopics.filter((item) => item !== topic),
    );
  };

  const handleSave = async () => {
    console.log(selectedTopics, 'copy selectedTopics');

    await dispatch(addItem(selectedTopics));

    onClose();
  };

  return (
    <>
      <FormControl fullWidth style={{ marginTop: '16px' }}>
        <Autocomplete
          options={courses.map((course) => ({
            title: course.generalInformation.title,
            id: course._id,
          }))}
          value={selectedCourse}
          onChange={handleCourseSelect}
          getOptionLabel={(option) => option.title}
          renderInput={(params) => (
            <TextField {...params} label="Select Course" variant="outlined" />
          )}
        />
      </FormControl>

      {selectedCourse && (
        <>
          <FormControl fullWidth style={{ marginTop: '16px' }}>
            <Autocomplete
              multiple
              options={courseDetailsForCopy?.modules?.map((module) => ({
                title: module.title,
                lessons: module.lessons,
              }))}
              value={selectedModules}
              onChange={(event, value) => handleModuleSelect(value)}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Module"
                  variant="outlined"
                />
              )}
            />
          </FormControl>

          <div style={{ marginTop: '16px', display: 'flex', flexWrap: 'wrap' }}>
            {selectedModules.map((module) => (
              <Chip
                key={module.title}
                label={module.title}
                onDelete={() => handleRemoveModule(module)}
                style={{ marginRight: '8px', marginBottom: '8px' }}
              />
            ))}
          </div>
        </>
      )}

      {selectedModules.length > 0 && (
        <FormControl fullWidth style={{ marginTop: '16px' }}>
          <Autocomplete
            multiple
            options={selectedModules.map((module) => module.lessons).flat()}
            getOptionLabel={(option) => option.title}
            value={selectedLessons}
            // onChange={(event, value) => handleLessonChange(value)}
            onChange={handleLessonChange}
            renderInput={(params) => (
              <TextField {...params} label="Select Lesson" variant="outlined" />
            )}
          />
        </FormControl>
      )}

      <div style={{ marginTop: '16px', display: 'flex', flexWrap: 'wrap' }}>
        {selectedLessons.map((lesson) => (
          <Chip
            key={lesson.id}
            label={lesson.title}
            onDelete={() => handleRemoveLesson(lesson)}
            style={{ marginRight: '8px', marginBottom: '8px' }}
          />
        ))}
      </div>

      {/* for copy lesson  */}

      {selectedLessons.length > 0 && (
        <FormControl fullWidth style={{ marginTop: '16px' }}>
          <Autocomplete
            multiple
            options={selectedModules
              .map((module) => module.lessons)
              .flat()
              .map((lesson) => lesson.items)
              .flat()}
            getOptionLabel={(option) => option?.title}
            value={selectedTopics}
            // onChange={(event, value) => handleLessonChange(value)}
            onChange={handleTopicChange}
            renderInput={(params) => (
              <TextField {...params} label="Select topic" variant="outlined" />
            )}
          />
        </FormControl>
      )}

      <div style={{ marginTop: '16px', display: 'flex', flexWrap: 'wrap' }}>
        {selectedTopics?.map((lesson) => (
          <Chip
            key={lesson.id}
            label={lesson.title}
            onDelete={() => handleRemoveLesson(lesson)}
            style={{ marginRight: '8px', marginBottom: '8px' }}
          />
        ))}
      </div>

      <div
        style={{
          marginTop: '16px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button onClick={onClose} style={{ marginRight: '10px' }}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSave}
          style={{ backgroundColor: '#0684FB', color: 'white' }}
        >
          Submit
        </Button>
      </div>
    </>
  );
};

export default CopyTopicContainer;
