import React, { useEffect, useState } from 'react';
import { Title } from '../../styles/Calendar/CalendarStyle';
import { useNavigate } from 'react-router-dom';
import TableUtilityLib from '../Utilities/TableUtilityLib';
import {
  UserTable,
  HistoryPage,
  UserHistoryTitle,
  HeaderContain,
} from '../../styles/Users/UsersStyles';
import BackButton from '../Utilities/BackButton';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserHistory } from '../../features/userModule/userModuleSlice';
import Papa from 'papaparse';

const HistoryofImports = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { history } = useSelector((state) => state.users) || {};
  const [fileStatus, setFileStatus] = useState({});

  const handleBack = () => {
    navigate('/users');
  };

  useEffect(() => {
    dispatch(fetchUserHistory());
  }, [dispatch]);

  useEffect(() => {
    if (history) {
      history.forEach((record) => {
        if (record.SuccessFilePath) {
          checkCsvFile(record.SuccessFilePath, 'SuccessFilePath');
        }
        if (record.FailureFilePath) {
          checkCsvFile(record.FailureFilePath, 'FailureFilePath');
        }
      });
    }
  }, [history]);

  const checkCsvFile = (url, fileType) => {
    fetch(url)
      .then((response) => response.text())
      .then((csvText) => {
        const result = Papa.parse(csvText, { header: true });
        console.log(`Checking ${fileType} for URL: ${url}`);
        console.log('Parsed CSV Data:', result.data);

        if (
          result.data.length === 0 ||
          result.data.every((row) =>
            Object.values(row).every((value) => value === ''),
          )
        ) {
          setFileStatus((prev) => ({ ...prev, [url]: 'NA' }));
        } else {
          setFileStatus((prev) => ({ ...prev, [url]: 'Download' }));
        }
      })
      .catch((error) => {
        console.error(`Error fetching or parsing ${fileType} CSV:`, error);
      });
  };

  const data = history
    ? [...history].sort(
        (a, b) => new Date(b.TimeofAction) - new Date(a.TimeofAction),
      )
    : [];
  const extractFileName = (url) => {
    const parsedUrl = new URL(url);
    const pathname = parsedUrl.pathname;
    return pathname.substring(pathname.lastIndexOf('/') + 1);
  };

  const columns = [
    { name: 'Record No.', selector: (row) => row.SrNo, sortable: true },
    {
      name: 'Successful Records',
      selector: (row) => row.SuccessfulRecords,
      sortable: true,
    },
    {
      name: 'Failed Records',
      selector: (row) => row.FailedRecords,
      sortable: true,
    },
    {
      name: 'Total Records',
      selector: (row) => row.TotalRecords,
      sortable: true,
    },

    {
      name: 'Time of Action',
      selector: (row) =>
        format(new Date(row.TimeofAction), 'MM/dd/yyyy, hh:mm a'),
      // selector: (row) => row.TimeofAction,

      sortable: true,
    },
    {
      name: 'SuccessFiles',
      cell: (row) =>
        fileStatus[row.SuccessFilePath] === 'Download' ? (
          <a href={row.SuccessFilePath} download>
            Download
          </a>
        ) : (
          'NA'
        ),
    },
    {
      name: 'FailedFiles',
      cell: (row) =>
        fileStatus[row.FailureFilePath] === 'Download' ? (
          <a href={row.FailureFilePath} download>
            Download
          </a>
        ) : (
          'NA'
        ),
    },
  ];

  const downloadFile = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', ''); // Set the download attribute
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <HistoryPage>
      <UserHistoryTitle>
        <HeaderContain>
          <BackButton
            onClick={handleBack}
            style={{ marginTop: '10px', marginLeft: '30px' }}
          />
        </HeaderContain>
        <Title>History of Imports</Title>
      </UserHistoryTitle>

      <UserTable>
        <TableUtilityLib columns={columns} data={data} />
      </UserTable>
    </HistoryPage>
  );
};

export default HistoryofImports;
