import axios from 'axios';

// const URL = 'https://leapot-lms-backend.onrender.com/api';
// const URL = 'http://localhost:8000/api';

const URL = process.env.REACT_APP_URL;

export const createQuizzes = async (quizData) => {
  try {
    //   return await axios.post(`${URL}/api/course/quizzes`);
    const response = await axios.post(`${URL}/api/course/quizzes`, quizData);
    return response;
  } catch (error) {
    console.log('Error occurs while creating quizz ', error);
    throw error;
  }
};
