import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ArchieveTable,
  AnnouncementHeader,
  Header,
  ArchievedCont,
} from '../../styles/Calendar/CalendarStyle';
import { Typography } from '@mui/material';
import TableUtilityLib from '../Utilities/TableUtilityLib';
import BackButton from '../Utilities/BackButton';
import { fetchAnnouncement } from '../../features/calendar/CalendarSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ArchivedAnnouncements = () => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  const { archived } = useSelector((state) => state.calendar);
  const { users, loading } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(fetchAnnouncement());
  }, [dispatch]);

  // Function to format date as MM-DD-YYYY
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };

  const handleRowClick = (row) => {
    if (users.user.userid?.role === 'Admin') {
      navigate(`/EventsNavigator/announcedetails/${row._id}`);
    } else {
      navigate(`/LearnersEventsNavigator/announcedetails/${row._id}`);
    }
  };

  const handleBack = () => {
    navigate('/EventsNavigator');
  };
  const { t } = useTranslation();

  const columns = [
    {
      name: t('calender.announcementNumb'),
      selector: (row) => row.announcementNo,
      sortable: true,
    },
    {
      name: t('calender.eventNameLabel'),
      selector: (row) => row.eventName,
      sortable: true,
    },
    {
      name: t('calender.startdateLabel'),
      selector: (row) => formatDate(row.eventDate),
      sortable: true,
    },
    {
      name: t('calender.starttimeLabel'),
      selector: (row) => row.startTime,
      sortable: true,
    },
    {
      name: t('calender.enddateLabel'),
      selector: (row) => formatDate(row.eventEndDate),
      sortable: true,
    },
    {
      name: t('calender.endtimeLabel'),
      selector: (row) => row.endTime,
      sortable: true,
    },
  ];

  return (
    <>
      <ArchievedCont>
        <AnnouncementHeader>
          <Header style={{ display: 'flex', alignItems: 'center' }}>
            <BackButton onClick={handleBack} />
            <Typography variant="h6" sx={{ marginLeft: '10px' }}>
              {t('calender.archieveannouncementsLabel')}
            </Typography>
          </Header>
        </AnnouncementHeader>

        <ArchieveTable>
          <TableUtilityLib
            columns={columns}
            data={archived}
            selectableRows={false}
            selectableRowsHighlight={false}
          />
        </ArchieveTable>
      </ArchievedCont>
    </>
  );
};

export default ArchivedAnnouncements;
