import React, { useEffect, useState } from 'react';
import {
  ProfileContainer,
  ProfileImage,
  ProfileImageCont,
  ImageContainerSmall,
  SubmitBtnStyle,
  BoxStyling,
} from '../../styles/Profile/ProfileStyle';
import { HeaderContainer } from '../../styles/Common/CommonStyles';
import { palette } from '../../styles/palette';
import { Link, useLocation } from 'react-router-dom';
import {
  Tooltip,
  TextField,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  FormHelperText,
  Select,
  MenuItem,
  FormControl,
  Grid,
} from '@mui/material';
import { AddFieldContainer } from '../../styles/Profile/ProfileStyle';
import { useNavigate } from 'react-router-dom';
import {
  TextFieldStyle,
  TypographyStyle,
} from '../../styles/Profile/ProfileStyle';
import AdditionalField from './AdditionalField';
import CommonButton from '../Utilities/CommonButton';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../features/authentication/AuthenticationSlice';
import { countryCode } from '../Utilities/countryCodes';
import { toast } from 'react-toastify';
import i18next from 'i18next';

const ProfileContainerComp = ({}) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.auth?.users);
  const profileState1 = useSelector((state) => state.users);

  console.log(userData, 'User Data from Parent TO CHILD checking '); //FIXME:

  // const { user } = useSelector((state) => state?.auth);//:FIXME:THIS IS SLICE FOR FIRST TIME RESPONSE FROM GOOGLE SIGNIN

  // console.log(user, 'user in profule');
  console.log(userData, 'User Data from Parent checking');
  console.log(userData.user.userid._id, 'checking');
  const [isHovered, setIsHovered] = useState(false);

  const [addedFields, setAddedFields] = useState([]);
  const [displayAddFields, setDisplayAddFields] = useState(false);
  const [open, setOpen] = useState(false);
  const [render, setRender] = useState(0);
  const [userProfile, setUserProfile] = useState([]);
  const languages = [
    { code: 'en', name: 'English' },
    { code: 'sn', name: 'Spanish' },
  ];

  const [profileState, setProfileState] = useState({
    firstname: userData?.user?.userid?.firstname,
    picture: userData?.user?.userid?.picture,
    lastname: userData?.user?.userid?.lastname,
    email: userData?.user?.userid?.email,
    phoneNo: userData?.user?.userid?.phoneNo,
    address: userData?.user?.address,
    userState: userData?.user?.userState,
    city: userData?.user?.city,
    companyName: userData?.user?.username,
    countryCode: userData?.user?.userid?.countryCode,
    langCode: userData?.user?.langCode || 'en',
  });

  useEffect(() => {
    setProfileState({
      firstname: userData?.user?.userid?.firstname,
      picture: userData?.user?.userid?.picture,
      lastname: userData?.user?.userid?.lastname,
      email: userData?.user?.userid?.email,
      phoneNo: userData?.user?.userid?.phoneNo,
      address: userData?.user?.address,
      userState: userData?.user?.userState,
      city: userData?.user?.city,
      companyName: userData?.user?.username,
      countryCode: userData?.user?.userid?.countryCode,
      langCode: userData?.user?.langCode,
    });
    console.log(profileState.countryCode, 'checking countryCode');
    console.log(profileState?.langCode, 'checking langCode');
    console.log(userData, 'checking useEffect');
    // console.log(langCode, 'checking langCode');
  }, [userData]);

  const [additionalFieldsData, setAdditionalFieldsData] = useState([]);
  const [additionalFieldsErrorState, setAdditionalFieldsErrorState] = useState(
    {},
  );

  const [profileErrorState, setProfileErrorState] = useState({
    // userName: false,
    firstname: false,
    lastname: false,
    userEmailId: false,
    userPhoneNo: false,
    addressLine1: false,
    addressLine2: false,
    addressLine3: false,
    userState: false,
    stateLicenseNo: false,
    countryName: false,
    userImgUrl: false,
    file: false,
  });

  const [displayModal, setDisplayModal] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const navigate = useNavigate();

  const handleBlur = (e) => {
    if (e.target.value === '' || null || undefined)
      setProfileErrorState({ ...profileErrorState, [e.target.name]: true });
  };

  // Below 3 functions are for adding additional fields in profile page
  const handleAddTextField = () => {
    console.log('handle display text');
    setDisplayModal(true);
  };

  const handleAddFields = (fieldProps) => {
    console.log('Field to be added is ', fieldProps);
    const newFields = [...addedFields, fieldProps];
    setAddedFields(newFields);
    setProfileState({
      ...profileState,
      additionalFields: newFields,
    });
    setDisplayAddFields(true);
  };

  const handleAddFieldBlur = (fieldName) => {
    if (!profileState.additionalFields[fieldName]) {
      setAdditionalFieldsErrorState((prevState) => ({
        ...prevState,
        [fieldName]: true,
      }));
    }
  };

  //Changing data to the additional fields

  const handleAddFieldChange = (event, fieldName) => {
    const fieldValue = event.target.value;

    // Find the index of the field in additionalFieldsData
    const fieldIndex = additionalFieldsData.findIndex(
      (field) => field.fieldName === fieldName,
    );

    // If the field is found, update its data
    if (fieldIndex !== -1) {
      const updatedAdditionalFieldsData = [...additionalFieldsData];
      updatedAdditionalFieldsData[fieldIndex] = {
        ...updatedAdditionalFieldsData[fieldIndex],
        data: fieldValue,
      };
      setAdditionalFieldsData(updatedAdditionalFieldsData);
    }
  };

  // To update the profile image
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePictureChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setProfileState({ ...profileState, picture: e.target.files[0] });
      console.log('Set image : ', e.target.files[0]);
      const reader = new FileReader();
      reader.onload = (event) => {
        setPreviewImage(event.target.result);
        console.log('image  ', event.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
    console.log(profileState, 'checking checking cheking'); //TODO:
    handleClose();
  };
  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;

    setProfileState((prevState) => ({
      ...prevState,
      langCode: newLanguage,
    }));
  };

  //On submit after change in the data int he profile page.
  //TODO:
  const handleSubmit = () => {
    console.log(
      'In submit profile checking for new name checking part 2 ',
      profileState,
    );
    if (profileState.langCode) {
      i18next.changeLanguage(profileState.langCode);
    }

    dispatch(updateUser(profileState));
    setRender((prev) => prev + 1);

    // Optionally check the updated state
    console.log('Updated profileState:', profileState);

    toast.success('User details updated successfully');
  };

  // Validation functions for USA and India phone numbers
  const handleChange = (event, fieldName) => {
    const { name, value } = event.target;
    console.log(name, value, 'checking checking checking part 1 ');
    setProfileState({ ...profileState, [name]: value }); //TODO: check it first

    // Handle country code separately
    if (name === 'countryCode') {
      setProfileState({
        ...profileState,
        [name]: value,
        userPhoneNo: '', // Reset phone number when country code changes
      });
    } else {
      // For other fields, update profile state and error state as before
      setProfileState({
        ...profileState,
        [name]: value,
      });

      setProfileErrorState({
        ...profileErrorState,
        [name]: value === '' || value === null || value === undefined,
      });
    }

    // Validate phone number based on the country code
    if (fieldName === 'userPhoneNo') {
      const countryCode = profileState.countryCode;
      let isValid = true;

      if (countryCode === '+1') {
        // USA phone number validation
        isValid = validateUsaPhoneNo(value);
      } else if (countryCode === '+91') {
        // India phone number validation
        isValid = validateIndiaPhoneNo(value);
      }

      setProfileErrorState({
        ...profileErrorState,
        [name]: !isValid,
      });
    }
  };

  const validateUsaPhoneNo = (phoneNumber) => {
    const usaPhoneRegex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    return usaPhoneRegex.test(phoneNumber);
  };

  const validateIndiaPhoneNo = (phoneNumber) => {
    const indiaPhoneRegex = /^\+?91?\d{9}$/;
    return indiaPhoneRegex.test(phoneNumber);
  };

  return (
    <ProfileContainer style={{ marginBlockStart: '40px', marginLeft: '40px' }}>
      <Box sx={BoxStyling}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6} sx={ImageContainerSmall}>
            <Tooltip title="Upload Image">
              <ProfileImageCont
                style={{
                  position: 'relative',
                  overflow: 'hidden',
                  cursor: 'pointer',
                  backgroundColor: '#f5f5f5',
                  fontFamily: 'Roboto, sans-serif',
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handlePictureChange}
                />
                {profileState.picture ? (
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundImage: `url(${profileState.picture})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  >
                    {isHovered && (
                      <div
                        style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          color: 'white',
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                        }}
                      >
                        <Button
                          onClick={() =>
                            document
                              .getElementById('contained-button-file')
                              .click()
                          }
                          variant="contained"
                          color="primary"
                        >
                          Change Image
                        </Button>
                      </div>
                    )}
                  </div>
                ) : previewImage ? (
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundImage: `url(${previewImage})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  >
                    {isHovered && (
                      <div
                        style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          color: 'white',
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                        }}
                      >
                        <Button
                          onClick={() =>
                            document
                              .getElementById('contained-button-file')
                              .click()
                          }
                          variant="contained"
                          color="primary"
                        >
                          Change Image
                        </Button>
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      height: '100%',
                      borderRadius: '3px',
                      backgroundColor: '#0684FB', // Adjust the background color as needed
                      fontSize: '6rem', // Adjust the font size as needed
                      color: '#fff', // Adjust the text color as needed
                    }}
                  >
                    {profileState.firstname.charAt(0).toUpperCase()}
                  </div>
                )}
              </ProfileImageCont>
            </Tooltip>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Typography style={TypographyStyle}> Email Id </Typography>
            <TextField
              fullWidth
              // required
              type="email"
              name="userEmailId"
              id="userEmailId"
              variant="outlined"
              sx={TextFieldStyle}
              error={profileErrorState.userEmailId}
              value={profileState.email}
              onBlur={handleBlur}
              onChange={handleChange}
              // InputProps={{ minLength: 0 }}
            />
            <FormHelperText
              error={profileErrorState.userEmailId}
              sx={{
                color: profileErrorState.userEmailId ? 'inherit' : 'red', // Change color to red when there is an error
              }}
            >
              {/* Combine regular expression and "@" symbol check */}
              {profileState.email && // Check if any value is entered
                (!/^[^@]+@[^@]+\.[^@]+$/.test(profileState.email) ||
                !profileState.email.includes('@')
                  ? 'Please enter a valid email format. (e.g., leapot@example.com)'
                  : '')}
            </FormHelperText>
            <Grid item xs={12} lg={6}>
              <Typography style={TypographyStyle}> Firstname </Typography>
              <TextField
                fullWidth
                required
                type="text"
                name="firstname"
                id="firstname"
                variant="outlined"
                sx={TextFieldStyle}
                error={profileErrorState.firstname}
                value={profileState.firstname} //:TODO: Add validation for firstname
                onBlur={handleBlur}
                onChange={handleChange}
                InputProps={{ minLength: 0 }}
              ></TextField>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Typography style={TypographyStyle}> Lastname </Typography>
              <TextField
                fullWidth
                required
                type="text"
                name="lastname"
                id="lastname"
                variant="outlined"
                sx={TextFieldStyle}
                error={profileErrorState.lastname}
                value={profileState.lastname}
                onBlur={handleBlur}
                onChange={handleChange}
                InputProps={{ minLength: 0 }}
              ></TextField>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography style={TypographyStyle}>Phone No</Typography>
            <FormControl>
              <TextField
                sx={{
                  background: `${palette.white}`,
                  width: '500px',
                  marginRight: '10px',
                  borderColor: '#0684FB',
                }}
                required
                type="phoneno"
                name="userPhoneNo"
                id="userPhoneNo"
                variant="outlined"
                value={profileState.phoneNo}
                onChange={(e) => handleChange(e, 'userPhoneNo')}
                error={profileErrorState.userPhoneNo}
                InputProps={{
                  startAdornment: (
                    <Select
                      value={profileState.countryCode}
                      onChange={(e) => handleChange(e, 'countryCode')}
                      variant="outlined"
                      style={{ width: 140, marginLeft: '-15px' }}
                      name="countryCode"
                    >
                      {countryCode.map((country) => (
                        <MenuItem key={country.code} value={country.code}>
                          {` ${country.name} (${country.code}) ${country.flag}`}
                        </MenuItem>
                      ))}
                    </Select>
                  ),
                  inputProps: {
                    style: { paddingLeft: '20px' }, // Add space between dropdown and input area
                  },
                }}
              />
              <FormHelperText error={profileErrorState.userPhoneNo}>
                {profileErrorState.userPhoneNo && (
                  <>
                    {/* Error messages based on selected country code */}
                    {profileState.countryCode === '+1' ? (
                      <span>Please enter a valid USA phone number.</span>
                    ) : (
                      <span>Please enter a valid India phone number.</span>
                    )}
                  </>
                )}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography style={TypographyStyle}>Language</Typography>
            <FormControl fullWidth variant="outlined">
              <Select
                value={profileState?.langCode} // Use the langCode from profileState
                onChange={handleLanguageChange} // Handle language change
                displayEmpty
                inputProps={{ 'aria-label': 'Select Language' }}
                sx={{
                  background: `${palette.white}`,
                  width: '500px',
                  marginRight: '10px',
                }} // Apply your existing styles here
              >
                {languages.map((language) => (
                  <MenuItem key={language.code} value={language.code}>
                    {language.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Typography style={TypographyStyle}>Address Line 1 </Typography>
            <TextField
              fullWidth
              required
              type="text"
              name="userAddressL1"
              id="userAddressL1"
              variant="outlined"
              sx={TextFieldStyle}
              // error={profileErrorState.userAddressL1}
              value={profileState.address}
              onBlur={handleBlur}
              onChange={handleChange}
            ></TextField>
            <FormHelperText error={profileErrorState.userAddressL1}>
              {profileErrorState.userAddressL1
                ? 'Please enter a valid Address'
                : ''}
            </FormHelperText>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Typography style={TypographyStyle}>Address Line 2 </Typography>
            <TextField
              fullWidth
              required
              type="text"
              name="userAddressL2"
              id="userAddressL2"
              variant="outlined"
              sx={TextFieldStyle}
              error={profileErrorState.userAddressL2}
              value={profileState.userAddressL2}
              onBlur={handleBlur}
              onChange={handleChange}
            ></TextField>
            <FormHelperText error={profileErrorState.userAddressL2}>
              {profileErrorState.userAddressL2
                ? 'Please enter a valid Address'
                : ''}
            </FormHelperText>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Typography style={TypographyStyle}>State </Typography>
            <TextField
              fullWidth
              required
              type="text"
              name="userState"
              id="userState"
              variant="outlined"
              sx={TextFieldStyle}
              error={profileErrorState.userState}
              value={profileState.userState}
              onBlur={handleBlur}
              onChange={handleChange}
            ></TextField>
            <FormHelperText error={profileErrorState.userState}>
              {profileErrorState.userState ? 'Please enter a valid State' : ''}
            </FormHelperText>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography style={TypographyStyle}>State License No </Typography>
            <TextField
              fullWidth
              required
              type="text"
              name="userStateLNo"
              id="userStateLNo"
              variant="outlined"
              sx={TextFieldStyle}
              error={profileErrorState.userStateLNo}
              value={profileState.userStateLNo}
              onBlur={handleBlur}
              onChange={handleChange}
            ></TextField>
            <FormHelperText error={profileErrorState.userStateLNo}>
              {profileErrorState.userStateLNo
                ? 'Please enter a valid State License No'
                : ''}
            </FormHelperText>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography style={TypographyStyle}>Country </Typography>
            <TextField
              fullWidth
              required
              type="text"
              name="userCountry"
              id="userCountry"
              variant="outlined"
              sx={TextFieldStyle}
              error={profileErrorState.userCountry}
              value={profileState.userCountry}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <FormHelperText error={profileErrorState.userCountry}>
              {profileErrorState.userCountry
                ? 'Please enter a valid country name'
                : ''}
            </FormHelperText>
          </Grid>
          {displayAddFields &&
            profileState?.hasOwnProperty('additionalFields') &&
            profileState?.additionalFields.length > 0 &&
            addedFields?.map((item, index) => (
              <Grid item xs={12} lg={6} key={index}>
                <Typography style={TypographyStyle}>
                  {item.fieldName}
                </Typography>
                <TextField
                  required
                  type="text"
                  name={item.fieldName.replace(' ', '-')}
                  id={item.fieldName.replace(' ', '-')}
                  value={profileState.additionalFields[index].data || ''}
                  variant="outlined"
                  sx={TextFieldStyle}
                  onBlur={() =>
                    handleAddFieldBlur(item.fieldName.replace(' ', '-'))
                  }
                  onChange={(event) =>
                    handleAddFieldChange(event, item.fieldName)
                  }
                  error={
                    additionalFieldsErrorState[item.fieldName.replace(' ', '-')]
                  }
                />
                <FormHelperText
                  error={
                    additionalFieldsErrorState[item.fieldName.replace(' ', '-')]
                  }
                >
                  {additionalFieldsErrorState[
                    item.fieldName.replace(' ', '-')
                  ] && `Please enter ${item.fieldName}`}
                </FormHelperText>
              </Grid>
            ))}
          {profileState?.additionalFields?.length === 0 ||
          profileState?.additionalFields?.length % 2 === 0 ? (
            <>
              <Grid item xs={0} md={6}></Grid>
              <AddFieldContainer>
                <Grid item xs={12} sm={12} lg={6} onClick={handleAddTextField}>
                  Add Field
                </Grid>
              </AddFieldContainer>
            </>
          ) : (
            <AddFieldContainer>
              <Grid item xs={12} sm={12} lg={6} onClick={handleAddTextField}>
                Add Field
              </Grid>
            </AddFieldContainer>
          )}
        </Grid>
      </Box>
      {displayModal && (
        <AdditionalField
          isOpen={displayModal}
          onClose={() => {
            console.log('Before close function');
            setDisplayModal(false);
            console.log('After close function');
          }}
          AddFields={(fieldProps) => handleAddFields(fieldProps)}
        />
      )}
      {!displayModal && (
        <SubmitBtnStyle>
          <CommonButton onClick={handleSubmit} label="Submit" />
        </SubmitBtnStyle>
      )}
    </ProfileContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    userProfile: state.userProfile,
  };
};

export default ProfileContainerComp;
