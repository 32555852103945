import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CertificateTabs from './CertificateTabs';
import {
  CommonButtonCancle,
  SearchEmail,
  SeparateTableButton,
  WholePage,
} from '../../styles/Certificate/CertificateStyle';
import TableUtilityLib from '../Utilities/TableUtilityLib';
import { useDispatch } from 'react-redux';
import { fetchRequests } from '../../features/RequestSlice';
import { issueCertificate } from '../../features/certificate/CertificateSlice';
import Loader from '../Utilities/Loader';
import { InputAdornment, TextField, Tooltip } from '@mui/material';
import SearchIcon from '@material-ui/icons/Search';

import { t } from 'i18next';
import { toast } from 'react-toastify';
import { Text } from '../../styles/Common/CommonStyles';

const CertificateRequest = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterValue, setFilterValue] = useState('');
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(fetchRequests());
        const result = response?.payload?.data || [];

        console.log('Checking fetch result:', result);

        setData(result);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch requests:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  const handleIssueCertificate = async (row) => {
    try {
      const issuedData = {
        email: row.email,
        eventid: row.eventid,
        issueDate: new Date().toISOString(),
      };

      const response = await dispatch(issueCertificate(issuedData)).unwrap();
      console.log('Response from issueCertificate:', response);

      if (response && response.payload && response.payload.message) {
        toast.success(response.payload.message);
      } else if (response && response.message) {
        toast.success(response.message);
      } else {
        toast.error('Unexpected response from server.');
      }

      dispatch(fetchRequests());
    } catch (error) {
      console.error('Error issuing certificate:', error);
      toast.error(
        `Error issuing certificate: ${error.message || 'Unknown error'}`,
      );
    }
  };

  const filteredData = data
    .filter((row) =>
      row.email?.toLowerCase().includes(filterValue.toLowerCase()),
    )
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const filteredRequestsData = filteredData.filter(
    (row) => row.request === true,
  );

  const columns = [
    { name: 'Event Name', selector: (row) => row.eventname, sortable: true },
    { name: 'Event ID', selector: (row) => row.eventid, sortable: true },
    { name: 'Email ID', selector: (row) => row.email, sortable: true },
    { name: 'Request For', selector: (row) => row.action, sortable: true },
    {
      name: 'Action',
      cell: (row) => (
        <>
          <Tooltip
            title={
              row.request ? 'Issue Certificate' : 'Cannot Issue Certificate'
            }
          >
            <div>
              <CommonButtonCancle
                label={t('certificate.issueLabel')}
                onClick={() => row.request && handleIssueCertificate(row)}
                disabled={!row.request}
                style={{
                  backgroundColor: !row.request ? 'grey' : undefined,
                  color: !row.request ? 'white' : undefined,
                  cursor: !row.request ? 'not-allowed' : undefined,
                }}
              />
            </div>
          </Tooltip>
        </>
      ),
    },
  ];

  const handleFormSubmit = (event) => {
    event.preventDefault();
  };

  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
  };

  const handleToggleView = () => {
    setShowAll(!showAll);
  };

  return (
    <>
      <CertificateTabs />

      <WholePage>
        <Text>
          {showAll ? t('certificate.historyRequest') : t('certificate.request')}
        </Text>

        <SearchEmail onSubmit={handleFormSubmit}>
          <TextField
            id="outlined-basic"
            label={t('certificate.searchText')}
            size="small"
            variant="outlined"
            value={filterValue}
            onChange={handleFilterChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Tooltip title={showAll ? 'Show Filtered Requests' : 'Show history'}>
            <SeparateTableButton>
              <CommonButtonCancle
                label={
                  showAll
                    ? t('certificate.request')
                    : t('certificate.historyLabel')
                }
                onClick={handleToggleView}
              />
            </SeparateTableButton>
          </Tooltip>
        </SearchEmail>
        {loading ? (
          <Loader />
        ) : (
          <TableUtilityLib
            columns={columns}
            data={showAll ? filteredData : filteredRequestsData}
          />
        )}
      </WholePage>
    </>
  );
};

export default CertificateRequest;
