import { Routes, Route } from 'react-router-dom';
import Admins from './Admins';
import HistoryofImports from './HistoryofImports';
import Instructors from './Instructors';
import Learners from './Learners';
import UserDetails from './UserDetails';
import Blacklist from './BlackList';

import { useDispatch } from 'react-redux';
import { closeSidebar, openSidebar } from '../../features/sidebar/sidebar';

const Users = () => {
  const dispatch = useDispatch();
  dispatch(closeSidebar());
  return (
    <Routes>
      <Route index path="/*" element={<Admins />} />
      <Route path="/Blacklist" element={<Blacklist />} />
      <Route path="/HistoryofImports" element={<HistoryofImports />} />
      <Route path="/Instructors" element={<Instructors />} />
      <Route path="/Learners" element={<Learners />} />
      <Route path="/UserDetails/:userId" element={<UserDetails />} />
    </Routes>
  );
};

export default Users;
