import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AllTemplates from './AllTemplates';
import { PageContainer } from '../../styles/Common/CommonStyles';
import DefaultPageTemplate from './DefaultPageTemplate';
import CoursePageTemplate from './CoursePageTemplate';
import Sitebuilder from './Sitebuilder';
import UserSavedTemplates from './UserSavedTemplates';

const SitebuilderRoutes = () => {
  return (
    <>
      <PageContainer>
        <Routes>
          <Route path="/" element={<AllTemplates />} />
          <Route path="/DefaultPage" element={<DefaultPageTemplate />} />
          <Route path="/CoursePageTemp" element={<CoursePageTemplate />} />
          <Route path="/UserSavedTemplates" element={<UserSavedTemplates />} />
          {/* <Route path="/SiteBuilderB/:id" element={<Sitebuilder />} /> */}
        </Routes>
      </PageContainer>
    </>
  );
};

export default SitebuilderRoutes;
