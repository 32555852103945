import React, { useState } from "react";
import {
  TextField,
  Tooltip,
  IconButton,
  Grid,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DeleteIcon from "@mui/icons-material/Delete";
import JoditReact from "jodit-react";
import axios from "axios";
import { palette } from "../../../styles/palette";
import { useDispatch } from 'react-redux';
import { addItem } from '../../../features/courses/addCourseDetailsSlice';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import {EditorContainer,DeleteBox,StyledTextField,ButtonContainer,BackButtonContainer,HeaderContainer} from '../../../styles/Courses/CourseModuleStyles';

const DiscardButton = styled(Button)`
  color: ${palette.primary};
  border-color:  ${palette.gray};
  margin-right: 10px;
  width: 180px;
`;

const SaveButton = styled(Button)`
  background-color: ${palette.primary};
  color: ${palette.white};
`;

const UploadAssignment = ({
  onClose,
  selectedItem,
  lessonId,
  moduleId,
}) => {
  const [assignmentData, setAssignmentData] = useState({
    moduleId: selectedItem?.moduleId || lessonId,
    lessonId: selectedItem?.lessonId || moduleId,
    id: selectedItem?.id || '',
    courseStructure: selectedItem?.courseStructure || '',
    contentType: selectedItem?.contentType || '',
    title: selectedItem?.title || "",
    textDescription: selectedItem?.textDescription || "",
    questions: selectedItem?.questions || [],
  });

  const { id, courseStructure } = useParams();
  const dispatch = useDispatch();

  const handleOnClose = () => {
    onClose();
  };

  const handleAddQuestion = () => {
    setAssignmentData((prevState) => ({
      ...prevState,
      questions: [
        ...prevState.questions,
        {
          questionText: "",
          enableFileUpload: false,
          textBoxRequired: false,
          minimumWordsRequired: 0,
          requiredKeywords: "",
        },
      ],
    }));
  };

  const handleDeleteQuestion = (index) => {
    setAssignmentData((prevState) => {
      const updatedQuestions = [...prevState.questions];
      updatedQuestions.splice(index, 1);
      return { ...prevState, questions: updatedQuestions };
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAssignmentData((prevState) => ({
      ...prevState,
      [name]: value,
      lessonId: lessonId,
      moduleId: moduleId,
      id: generateRandomNumber(),
      contentType: 'Assignment',
      courseStructure: courseStructure,
    }));
  };

  const generateRandomNumber = () => {
    const min = 100000; // Minimum 6-digit number
    const max = 999999; // Maximum 6-digit number
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const handleQuestionChange = (index, field, value) => {
    setAssignmentData((prevState) => {
      const updatedQuestions = prevState.questions.map((question, i) =>
        i === index ? { ...question, [field]: value } : question
      );
      return { ...prevState, questions: updatedQuestions };
    });
  };

  const handleSave = async () => {
    await dispatch(addItem(assignmentData));
    console.log(assignmentData, "assignmentData");

    axios
      .post("/api/assignments", assignmentData)
      .then((response) => {
        console.log("Assignment saved successfully:", response.data);
      })
      .catch((error) => {
        console.error("Error saving assignment:", error);
      });
  };

  return (
    <div>
      <HeaderContainer>
        <BackButtonContainer container alignItems="center" spacing={1} sx={{ mt: 1, mb: 2 }}>
          <Grid item>
            <Tooltip title="Go Back">
              <IconButton
                size="small"
                onClick={handleOnClose}
                sx={{ color: `${palette.THEME_THREE}` }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item sx={{ mt: 1 }}>
            <Typography variant="h5" gutterBottom>
              Assignment
            </Typography>
          </Grid>
        </BackButtonContainer>
        <ButtonContainer>
        <DiscardButton variant="outlined">Discard Changes</DiscardButton>
        <SaveButton variant="contained" onClick={handleSave}>Save</SaveButton>
        </ButtonContainer>
      </HeaderContainer>
      <EditorContainer>
        <StyledTextField
          variant="outlined"
          label="Title"
          name="title"
          value={assignmentData.title}
          onChange={handleInputChange}
          style={{ width: "100%", marginBottom: "10px" }}
        />
        <TextField
          variant="outlined"
          label="Description"
          name="textDescription"
          value={assignmentData.textDescription}
          onChange={handleInputChange}
          style={{ width: "100%", marginBottom: "10px" }}
        />

        {assignmentData.questions.map((question, index) => (
          <div key={index}>
            <Typography variant="subtitle1" gutterBottom>
              Question {index + 1}
            </Typography>
            <JoditReact
              value={question.questionText}
              onChange={(newContent) => handleQuestionChange(index, "questionText", newContent)}
              config={{
                placeholder: "Enter the question here",
                autofocus: true,
              }}
            />
            <Grid item xs={12} container justifyContent="flex-end">
              <IconButton color="error" onClick={() => handleDeleteQuestion(index)}>
                <DeleteIcon />
              </IconButton>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={question.enableFileUpload}
                    onChange={(e) => handleQuestionChange(index, "enableFileUpload", e.target.checked)}
                  />
                }
                label="Enable File Upload"
              />
              <Typography variant="body2" color="error" sx={{ marginLeft: '31px', fontSize: '0.75rem', marginTop: "-6px" }}>
                (*Check this box if the question requires learners to upload a file (e.g., PDF, DOCX))
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={question.textBoxRequired}
                    onChange={(e) => handleQuestionChange(index, "textBoxRequired", e.target.checked)}
                  />
                }
                label="Text Box Required"
              />
              <Typography variant="body2" color="error" sx={{ marginLeft: '31px', fontSize: '0.75rem', marginTop: "-6px" }}>
                (*Check this box if a text box is required for the answer to this question.)
              </Typography>
            </Grid>

            {question.textBoxRequired && (
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={question.minimumWordsRequired > 0}
                      onChange={(e) => handleQuestionChange(index, "minimumWordsRequired", e.target.checked ? 1 : 0)}
                    />
                  }
                  label="Minimum Length Required"
                />
                {question.minimumWordsRequired > 0 && (
                  <TextField
                    variant="outlined"
                    label="Minimum Length"
                    value={question.minimumWordsRequired}
                    onChange={(e) => handleQuestionChange(index, "minimumWordsRequired", e.target.value)}
                    sx={{ marginBottom: "8px" }}
                  />
                )}
                <Typography variant="body2" color="error" sx={{ marginLeft: '31px', fontSize: '0.75rem', marginTop: "-6px" }}>
                  (*Check this box if there is a specific minimum length required for the answer to this question.)
                </Typography>
              </Grid>
            )}

          </div>
        ))}
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddQuestion}
          style={{ marginTop: "10px" }}
        >
          Add Assignment Question
        </Button>
      </EditorContainer>
    </div>
  );
};

export default UploadAssignment;
