import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import ReactPlayer from 'react-player';
import ControlSection from './ControlSection';
import screenfull from 'screenfull';
// import Thumbnail from '../../Audios/Thumbnail.jpg';
import Thumbnail from '../../images/audioThumbnail.jpg';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import PreviewTopicPresentation from './LearnerCourseBuilder/LearnerPreviewTopic/PreviewTopicPresentation';
import PreviewTopicDownload from './LearnerCourseBuilder/LearnerPreviewTopic/PreviewTopicDownload';
import PreviewTopicText from './LearnerCourseBuilder/LearnerPreviewTopic/PreviewTopicText';
import PreviewTopicVideo from './LearnerCourseBuilder/LearnerPreviewTopic/PreviewTopicVideo';
import PreviewTopicAudio from './LearnerCourseBuilder/LearnerPreviewTopic/PreviewTopicAudio';
import PreviewTopicPDF from './LearnerCourseBuilder/LearnerPreviewTopic/PreviewTopicPDF';

const ContentPlayer = forwardRef(
  ({ item, onClose, onNext, onPrevious }, ref) => {
    const [readingTime, setReadingTime] = useState(0);
    const [currentReadingTime, setCurrentReadingTime] = useState(0);
    const [readingInterval, setReadingInterval] = useState(null);

    console.log('abc ref', ref);

    const [playerState, setPlayerState] = useState({
      url: null,
      pip: false,
      playing: false,
      controls: false,
      light: false,
      volume: 0.8,
      muted: true,
      played: 0,
      loaded: 0,
      duration: 0,
      playbackRate: 1.0,
      loop: false,
      volumeOpen: false,
      dropdownOpen: false,
      fullscreen: false,
      replay: false,
      seeking: false,
    });
    // const defaultLayoutPluginInstance = defaultLayoutPlugin();

    const handleReadingTimeCalculated = (time) => {
      setReadingTime(time);
      setCurrentReadingTime(0);

      if (readingInterval) {
        clearInterval(readingInterval);
      }

      const interval = setInterval(() => {
        setCurrentReadingTime((prevTime) => {
          if (prevTime >= time) {
            clearInterval(interval);
            return time;
          }
          return prevTime + 1;
        });
      }, 1000);

      setReadingInterval(interval);
      alert('completed');
    };

    useEffect(() => {
      return () => {
        if (readingInterval) {
          clearInterval(readingInterval);
        }
      };
    }, [readingInterval]);

    ////
    useImperativeHandle(ref, () => ({
      load: (url) => {
        setPlayerState((prevState) => ({
          ...prevState,
          url,
          played: 0,
          loaded: 0,
          pip: false,
          muted: true,
          playbackRate: 1.0,
        }));
      },
    }));

    const player = useRef(null);
    const playerWrapper = useRef(null);

    const handlePlayPause = () => {
      setPlayerState({ ...playerState, playing: !playerState.playing });
    };

    const handleReady = () => {
      console.log('Media is ready');
    };

    const handleStart = () => {
      console.log('Media starts playing');
    };

    const handlePlaybackRateChange = (value) => {
      setPlayerState({
        ...playerState,
        playbackRate: value,
        dropdownOpen: !playerState.dropdownOpen,
      });
    };

    const handleSetPlaybackRate = (e) => {
      const rate = parseFloat(e.currentTarget.getAttribute('value'));
      setPlayerState({ ...playerState, playbackRate: rate });
    };

    const handleVolumeSeekDown = (e, newValue) => {
      setPlayerState({
        ...playerState,
        seeking: false,
        volume: parseFloat(newValue / 100),
      });
    };

    const handleVolumeChange = (e, newValue) => {
      setPlayerState({
        ...playerState,
        volume: parseFloat(newValue / 100),
        muted: newValue === 0 ? true : false,
      });
    };

    const handlePlay = () => {
      console.log('onPlay');
      if (!playerState.playing) {
        setPlayerState({ ...playerState, playing: true });
      }
    };

    const handlePause = () => {
      console.log('onPause');
      setPlayerState({ ...playerState, playing: false });
    };

    const handleSeekMouseDown = () => {
      setPlayerState({ ...playerState, seeking: true });
    };

    const handleSeekChange = (e) => {
      setPlayerState({
        ...playerState,
        played: parseFloat(e.target.value / 100),
      });
    };

    const handleSeekMouseUp = (e) => {
      const fraction = parseFloat(e.target.value / 100);
      setPlayerState({ ...playerState, seeking: false, played: fraction });
      if (player.current) {
        player.current.seekTo(fraction);
      }
    };

    // const handleProgress = (state) => {
    //   if (!playerState.seeking) {
    //     setPlayerState({ ...playerState, ...state });
    //   }
    // };
    const handleProgress = (state) => {
      // Update currentTime here
      setPlayerState((prevState) => ({
        ...prevState,
        ...state,
        currentTime: state.playedSeconds, // Update currentTime with playedSeconds
      }));
    };

    // const handleEnded = () => {
    //   console.log('onEnded');
    //   console.log(playerState);
    //   setPlayerState({
    //     ...playerState,
    //     playing: false,
    //     seeking: false,
    //     played: 1,
    //   });
    //   setEnableNext(true);
    // };

    const onReplay = () => {
      if (!playerState.playing) {
        setPlayerState({ ...playerState, playing: true });
      }
      setEnableNext(false);
    };

    // const handleDuration = (duration) => {
    //   setPlayerState({ ...playerState, duration });
    // };
    const handleDuration = (duration) => {
      // Update duration here
      setPlayerState((prevState) => ({
        ...prevState,
        duration,
      }));
    };

    const handleMute = () => {
      setPlayerState({ ...playerState, muted: !playerState.muted });
    };

    const toggleVolume = () => {
      setPlayerState({ ...playerState, volumeOpen: !playerState.volumeOpen });
    };

    const toggleDropdown = () => {
      setPlayerState({
        ...playerState,
        dropdownOpen: !playerState.dropdownOpen,
      });
    };
    // const toggleDropdown = () => {
    //   setPlayerState((prevState) => ({
    //     ...prevState,
    //     dropdownOpen: !prevState.dropdownOpen
    //   }));
    // };

    const handleClickFullscreen = () => {
      if (playerWrapper?.current) {
        if (screenfull.isFullscreen) {
          screenfull.exit(playerWrapper.current);
        } else {
          screenfull.request(playerWrapper.current);
        }
      }
      setPlayerState({ ...playerState, fullscreen: !playerState.fullscreen });
    };

    const currentTime =
      player && player.current ? player.current.getCurrentTime() : '00:00';

    const duration =
      player && player.current ? player.current.getDuration() : '00:00';

    const SliderBar = ({ direction, value, style }) => (
      <div
        style={Object.assign(
          {},
          {
            position: 'absolute',
            background: 'GRAY',
            borderRadius: 4,
          },
          direction === Direction.HORIZONTAL
            ? {
                top: 0,
                bottom: 0,
                left: 0,
                width: `${value * 100}%`,
              }
            : {
                right: 0,
                bottom: 0,
                left: 0,
                height: `${value * 100}%`,
              },
          style,
        )}
      />
    );

    const handleNext = () => {
      if (onNext) {
        onNext();
      }
    };

    const handlePrevious = () => {
      if (onPrevious) {
        onPrevious();
      }
    };
    const handleEnded = () => {
      console.log('onEnded');
      setPlayerState({
        ...playerState,
        playing: false,
        played: 1,
        duration: 0,
      });
      if (onNext) {
        onNext(); // Call the onNext prop when the content ends
      }
    };

    const isPlaying = () => {
      console.log('is playing');
    };

    const handleToggleCC = () => {
      console.log('on toggle cc ');
    };

    const textScrollPercentage = () => {
      console.log('text scroll percentage');
    };

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          justifyContent: 'center',
        }}
        ref={playerWrapper}
      >
        {item?.contentType == 'Video' && (
          <div>
            <PreviewTopicVideo item={item} />
            <div
              style={{
                position: 'relative',
                width: '100%',
                height: '420px',
                backgroundColor: 'black',
              }}
            >
              <ReactPlayer
                // url="https://leapot-lms.s3.ap-south-1.amazonaws.com/lms/courseBuilder/videos/s3VideoTesting.mp4"
                url={item.link}
                // light={videoThumbnail}
                ref={player}
                playing={playerState.playing}
                loop={playerState.loop}
                playbackRate={playerState.playbackRate}
                muted={playerState.muted}
                volume={playerState.volume}
                controls={false}
                // Callbacks
                onStart={() =>
                  setPlayerState({
                    ...playerState,
                    visible_button_refresh: true,
                  })
                }
                onPlay={handlePlay}
                onPause={handlePause}
                onBuffer={() => console.log('onBuffer')}
                onSeek={(e) => console.log('onSeek', e)}
                onEnded={handleEnded}
                onError={(e) => console.log('onError', e)}
                onProgress={handleProgress}
                onDuration={handleDuration}
                onReady={handlePlayPause}
                onPlaybackRateChange={handlePlaybackRateChange}
                // Prop
                config={{
                  file: {
                    attributes: {
                      controlsList: 'nodownload',
                    },
                  },
                }}
                width="100%"
                height="100%"
              />
            </div>
          </div>
        )}

        {item?.contentType === 'Text' && (
          <div>
            <PreviewTopicText
              item={item}
              onReadingTimeCalculated={handleReadingTimeCalculated}
            />
          </div>
        )}

        {item?.contentType == 'Audio' && (
          <div>
            <PreviewTopicAudio item={item} />
            <img
              // src={require('../../audios/thumbnail.jpg')}
              src={Thumbnail}
              alt="Thumbnail"
              width="100%"
              height="415px"
            />

            <ReactPlayer
              // url="https://leapot-lms.s3.ap-south-1.amazonaws.com/course/videos/Purple.mp3"
              // light={Thumbnail}
              url={item.link}
              ref={player}
              playing={playerState.playing}
              loop={playerState.loop}
              playbackRate={playerState.playbackRate}
              muted={playerState.muted}
              volume={playerState.volume}
              controls={false}
              // Callbacks
              onStart={() =>
                setPlayerState({ ...playerState, visible_button_refresh: true })
              }
              onPlay={handlePlay}
              onPause={handlePause}
              onBuffer={() => console.log('onBuffer')}
              onSeek={(e) => console.log('onSeek', e)}
              onEnded={handleEnded}
              onError={(e) => console.log('onError', e)}
              onProgress={handleProgress}
              onDuration={handleDuration}
              onReady={handlePlayPause}
              onPlaybackRateChange={handlePlaybackRateChange}
              // Prop
              config={{
                file: {
                  attributes: {
                    controlsList: 'nodownload',
                  },
                },
              }}
              width="100%"
              height="70%"
            />
          </div>
        )}

        {item?.contentType == 'Presentation' && (
          <div>
            <PreviewTopicPresentation item={item} />
          </div>
        )}
        {item?.contentType == 'PDF' && (
          <div>
            <PreviewTopicPDF item={item} />
          </div>
        )}

        {item?.contentType == 'Download' && (
          <div>
            <PreviewTopicDownload item={item} />
          </div>
        )}

        <>
          {item?.contentType === 'Text' ? (
            <ControlSection
              contentType="text"
              data={item?.contentType}
              playbackRate={1.0}
              played={currentReadingTime / readingTime}
              duration={readingTime}
              currentTime={currentReadingTime}
              onNext={handleNext}
              onPrevious={handlePrevious}
            />
          ) : item?.contentType !== 'video' || 'audio' ? (
            <ControlSection
              contentType={item?.contentType.toLowerCase()}
              data={item?.contentType}
              muted={playerState.muted}
              onMute={handleMute}
              volume={playerState.volume}
              onStart={() =>
                setPlayerState({ ...playerState, visible_button_refresh: true })
              }
              onPlay={handlePlay}
              onPause={handlePause}
              onBuffer={() => console.log('onBuffer')}
              onSeek={handleSeekChange}
              onEnded={handleEnded}
              onError={(e) => console.log('onError', e)}
              played={playerState.played}
              onProgress={handleProgress}
              onDuration={handleDuration}
              onReady={() => handlePlayPause()}
              onPlaybackRateChange={handlePlaybackRateChange}
              playbackRate={playerState.playbackRate}
              dropdownOpen={playerState.dropdownOpen}
              onNext={handleNext}
              onPrevious={handlePrevious}
              onToggleCC={handleToggleCC}
              onToggleFitToScreen={handleClickFullscreen}
              isPlaying={playerState.playing}
              duration={playerState.duration}
              currentTime={playerState.currentTime}
              textScrollPercentage={item.textScrollPercentage}
              toggleDropdown={toggleDropdown}
              handleSeekMouseDown={handleSeekMouseDown}
              handleSeekChange={handleSeekChange}
              handleSeekMouseUp={handleSeekMouseUp}
              onReplay={item.onReplay}
              onVolumeChange={handleVolumeChange}
              onVolumeSeekDown={handleVolumeSeekDown}
            />
          ) : (
            <ControlSection
              contentType={item?.contentType.toLowerCase()}
              data={item?.contentType}
              muted={playerState.muted}
              onMute={handleMute}
              volume={playerState.volume}
              onStart={() =>
                setPlayerState({ ...playerState, visible_button_refresh: true })
              }
              onPlay={handlePlay}
              onPause={handlePause}
              onBuffer={() => console.log('onBuffer')}
              onSeek={handleSeekChange}
              onEnded={handleEnded}
              onError={(e) => console.log('onError', e)}
              played={playerState.played}
              onProgress={handleProgress}
              onDuration={handleDuration}
              onReady={() => handlePlayPause()}
              onPlaybackRateChange={handlePlaybackRateChange}
              playbackRate={playerState.playbackRate}
              dropdownOpen={playerState.dropdownOpen}
              onNext={handleNext}
              onPrevious={handlePrevious}
              onToggleCC={handleToggleCC}
              onToggleFitToScreen={handleClickFullscreen}
              isPlaying={playerState.playing}
              duration={playerState.duration}
              currentTime={playerState.currentTime}
              textScrollPercentage={item.textScrollPercentage}
              toggleDropdown={toggleDropdown}
              handleSeekMouseDown={handleSeekMouseDown}
              handleSeekChange={handleSeekChange}
              handleSeekMouseUp={handleSeekMouseUp}
              onReplay={item.onReplay}
              onVolumeChange={handleVolumeChange}
              onVolumeSeekDown={handleVolumeSeekDown}
            />
          )}
        </>
      </div>
    );
  },
);

export default ContentPlayer;
