import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CommonButton from '../Utilities/CommonButton';
import { palette } from '../../styles/palette';
import { useNavigate } from 'react-router-dom';
import { VerifyContainer } from '../../styles/Users/UsersStyles';

const VerificationSuccess = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    alert("Sign Up Successfull TRY Loggin in");
    navigate('/Login');
  };
  return (
    <VerifyContainer>
      <Card
        sx={{
          border: '0px solid black',
          width: '40%',
          background: `${palette.primary}`,
        }}
      >
        <CardContent>
          <Grid sx={{ border: '0px solid red', mb: 2 }}>
            <Typography textAlign="center" variant="h4">
              Verify email address
            </Typography>
          </Grid>
          <Grid
            sx={{
              border: '0px solid green',
              height: '180px',
              pt: 3,
              background: 'white',
            }}
          >
            <Grid sx={{ border: '0px solid blue' }}>
              <Typography textAlign="center" fontSize="17px" variant="body2">
                Thanks ! Your email address has been verified
              </Typography>
            </Grid>
            <Grid
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ border: '0px solid blue', mt: 4 }}
            >
              <CommonButton label="Go to My Account" onClick={handleClick} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </VerifyContainer>
  );
};
export default VerificationSuccess;
