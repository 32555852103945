import React, { useState } from 'react';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Container,
  Typography,
  Tooltip,
  IconButton,
  Grid,
  Button,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { palette } from '../../../styles/palette';
import JoditReact from 'jodit-react';
import { useDispatch} from 'react-redux';
import { addItem } from '../../../features/courses/addCourseDetailsSlice';
import { useParams } from 'react-router-dom';
import '../../Utilities/Sample.css';
import { Box } from '@mui/system';

const InsertLiveLesson = ({
  onClose,
  selectedItem,
  lessonId,
  moduleId,
}) => {
  const { id, courseStructure } = useParams();
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState('upload');
  const [key, setKey] = useState(0); // State to manage key prop
 
  const reloadViewer = () => {
    // Increment key to force re-rendering of PDFViewer with a new instance
    setKey((prevKey) => prevKey + 1);
  };
  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const[saved ,setSaved] = useState(true);
  const handleOnClose = () => {
    const data = { ...formData, preview: false}
    console.log("abc data from go back",data)
    if(saved && (formData.uploadUrl  || formData.link )){
      onClose();

    }
    else{
      dispatch(addItem(data));
      onClose()
    }

   

  };

  const handleDelete = () => {
    handleDeleteTopic(
      selectedItem?.moduleId,
      selectedItem?.lessonId,
      selectedItem?.id,
    );
  };

  const [formData, setFormData] = useState({
    selectedValue: 'upload',
    link: selectedItem?.link || '',
    uploadUrl: '',
    moduleId: selectedItem?.moduleId || '',
    lessonId: selectedItem?.lessonId || '',
    title: selectedItem?.title || '',
    textDescription: selectedItem?.textDescription || '',
    id: selectedItem?.id || '',
    courseStructure: selectedItem?.courseStructure || '',
    contentType: selectedItem?.contentType || '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      lessonId: lessonId,
      moduleId: moduleId,
      id: selectedItem?.id||generateRandomNumber(),
      contentType: 'Live',
      courseStructure: courseStructure,
    }));
    console.log(formData);
  };

  const generateRandomNumber = () => {
    const min = 100000; // Minimum 6-digit number
    const max = 999999; // Maximum 6-digit number
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const handleSubmit = async () => {
    if(formData.uploadUrl || formData.link)
      {
        
        
        const data = {...formData, preview:true}
        console.log('abc from  the submit',data);
        
        await dispatch(addItem(data));
      } else{
        
        const data = { ...formData, preview: false}
        console.log("abc data from the save for the draft",data)
    await dispatch(addItem(data));
      } 
    
      setSaved(true);
      onClose();
      };

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '10px',
          marginTop: '65px',
        }}
      >
        {/* Grid container aligned to the left */}
        <Grid container alignItems="center" spacing={1} sx={{ mt: 1, mb: 2 }}>
          <Grid item>
            <Tooltip title="Go Back">
              <IconButton
                size="small"
                onClick={handleOnClose}
                sx={{ color: `${palette.THEME_THREE}` }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item sx={{ mt: 1 }}>
            <Typography variant="h5" gutterBottom>
              Live
            </Typography>
          </Grid>
        </Grid>

        {/* Buttons div aligned to the right */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant="outlined"
            style={{
              color: '#0684FB',
              borderColor: '#ccc',
              marginRight: '10px',
              width: '180px',
            }}
          >
            Discard Changes
          </Button>
         {formData.title? <Button
            variant="contained"
            style={{
              backgroundColor: '#0684FB',
              color: 'white',
              borderColor: '#ccc',
            }}
            onClick={handleSubmit}
          >
            Save
          </Button>:<Button disabled>Save</Button>}
        </div>
      </div>

      <div style={{ marginRight: '10px', marginLeft: '10px' }}>
        <TextField
          variant="outlined"
          label="Title"
          style={{ width: '100%', marginBottom: '10px' }}
          name="title"
          value={formData.title}
          onChange={handleInputChange}
        />
        <JoditReact
          value={formData.textDescription}
          config={{ placeholder: 'Enter the live topic description' ,height: 300}}
          onChange={(newContent) =>
            setFormData((prevData) => ({
              ...prevData,
              textDescription: newContent,
            }))
          }
        />
      </div>
<Box sx={{display:'flex',alignItems:'center', justifyContent:'space-between',gap:'0.5rem', marginTop: '1rem'}}><RadioGroup
        row
        style={{display:'flex', marginLeft: '20px', marginBottom: '2%', marginTop: '12px' }}
        defaultValue="upload"
        value={selectedValue}
        onChange={handleRadioChange}
      >
        <FormControlLabel
          value="publicUrl"
          control={<Radio />}
          label="Public Url"
        />
       
      </RadioGroup>
      {selectedValue === 'publicUrl' && (
       
       <TextField
         label="Link"
         name="link"
         value={formData.link}
         onChange={handleInputChange}
         sx={{ width: '80%' }}
       />
     
   )}
      </Box>
      

      {/* Render different content based on selected radio button */}

      

      {formData.link && (
        <div style={{ marginTop: '20px' }}>
          <Typography variant="h6">Live Link</Typography>
          <iframe
            src={formData.link}
            width="100%"
            height="600px"
            style={{ border: 'none' }}
          />
        </div>
      )}
    </Container>
  );
};

export default InsertLiveLesson;
