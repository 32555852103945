// Announcements.js
import React, { useState, useEffect } from 'react';
import CalendarTabs from './CalendarTabs';
import LearnersCalendarTabs from './LearnersCalendarTabs';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import CommonButton from '../Utilities/CommonButton';
import { useTranslation } from 'react-i18next';
import {
  AnnouncementTable,
  AnnouncementCont,
  AnnouncementButton,
  AnnouncementHeader,
  Header,
  ArchieveLink,
  ModalContainer,
  ArchieveBttn,
} from '../../styles/Calendar/CalendarStyle';
import { Typography, Button, Modal } from '@mui/material';
import TableUtilityLib from '../Utilities/TableUtilityLib';
import BackButton from '../Utilities/BackButton';
import {
  fetchAnnouncement,
  cancelAnnouncements,
} from '../../features/calendar/CalendarSlice';
import { useDispatch, useSelector } from 'react-redux';

const Announcements = () => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAnnouncementId, setSelectedAnnouncementId] = useState(null);

  const navigate = useNavigate();
  const { announcement } = useSelector((state) => state.calendar);
  const { users } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(fetchAnnouncement());
  }, [dispatch]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };

  const formatTime = (timeObj) => {
    try {
      const date = new Date(timeObj);
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    } catch (error) {
      console.error('Error formatting time:', error);
      return 'Invalid time';
    }
  };

  const handleRowClick = (row) => {
    if (users.user.userid?.role === 'Admin') {
      navigate(`/EventsNavigator/announcedetails/${row._id}`);
    } else {
      navigate(`/LearnersEventsNavigator/announcedetails/${row._id}`);
    }
  };

  const handleAnnouncement = () => {
    navigate('/EventsNavigator/addannouncements');
  };

  const handleBack = () => {
    navigate('/EventsNavigator');
  };

  const handleOpenModal = (id) => {
    setSelectedAnnouncementId(id);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedAnnouncementId(null);
  };

  const handleConfirmCancel = async () => {
    if (selectedAnnouncementId) {
      await dispatch(cancelAnnouncements(selectedAnnouncementId));
      console.log('okay clicked ');
      navigate('/EventsNavigator/archivedAnnouncements');
      handleCloseModal();
    }
  };

  const handleNavigateToArchived = () => {
    navigate('/EventsNavigator/archivedAnnouncements');
  };

  const { t } = useTranslation();

  const columns = [
    {
      name: t('calender.announcementNumb'),
      selector: (row) => row.announcementNo,
      sortable: true,
    },
    {
      name: t('calender.eventNameLabel'),
      selector: (row) => row.eventName,
      sortable: true,
    },
    {
      name: t('calender.startdateLabel'),
      selector: (row) => formatDate(row.eventDate),
      sortable: true,
    },
    {
      name: t('calender.starttimeLabel'),
      selector: (row) => formatTime(row.startTime),
      sortable: true,
    },
    {
      name: t('calender.enddateLabel'),
      selector: (row) => formatDate(row.endTime),
      sortable: true,
    },
    {
      name: t('calender.endtimeLabel'),
      selector: (row) => formatTime(row.endTime),
      sortable: true,
    },
    {
      name: t('calender.actionLabel'),
      cell: (row) => (
        <ArchieveBttn onClick={() => handleOpenModal(row._id)}>
          {t('calender.archieveLabel')}
        </ArchieveBttn>
      ),
      sortable: true,
    },
  ];

  return (
    <>
      {users.user.userid?.role === 'Admin' ? (
        <CalendarTabs />
      ) : (
        <LearnersCalendarTabs />
      )}
      <AnnouncementCont>
        <AnnouncementHeader>
          <Header
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '2rem',
            }}
          >
            <BackButton onClick={handleBack} />
            <Typography variant="h6" sx={{ marginTop: '10px' }}>
              {t('calender.allannouncementsLabel')}
            </Typography>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
                padding: '10px',
              }}
            >
              {users.user.userid?.role === 'Admin' && (
                <CommonButton
                  label="Add Announcement"
                  onClick={handleAnnouncement}
                  icon={<AddIcon />}
                />
              )}
              {/* <ArchieveLink onClick={handleNavigateToArchived}>
                {t('calender.archieveannouncementsLabel')}
              </ArchieveLink> */}
              <CommonButton
                label={t('calender.archieveannouncementsLabel')}
                onClick={handleNavigateToArchived}
              />
            </div>
          </Header>
        </AnnouncementHeader>

        <AnnouncementTable>
          <TableUtilityLib
            columns={columns}
            data={announcement}
            onRowClicked={handleRowClick}
            selectableRows={false}
            selectableRowsHighlight={false}
          />
        </AnnouncementTable>
        <Modal open={modalOpen} onClose={handleCloseModal}>
          <ModalContainer>
            <Typography variant="h6" gutterBottom>
              Do you want to archive this announcement?
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleConfirmCancel}
            >
              OK
            </Button>
            <Button
              variant="contained"
              onClick={handleCloseModal}
              sx={{ marginLeft: '8px' }}
            >
              Cancel
            </Button>
          </ModalContainer>
        </Modal>
      </AnnouncementCont>
    </>
  );
};

export default Announcements;
