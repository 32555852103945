import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import ShareIcon from '@mui/icons-material/Share';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import { CloudDownload, Visibility } from '@mui/icons-material';
import TableUtilityLib from '../Utilities/TableUtilityLib';
import {
  WholePage,
  SearchEmail,
  TableDiv,
} from '../../styles/Certificate/CertificateStyle';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchIssuedCertificateUser,
  downloadCertificate,
} from '../../features/certificate/CertificateSlice';
import CertificateTabs from './LearnerTabs';
import { toast } from 'react-toastify';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import { palette } from '../../styles/palette';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
};

const LearnerCertificate = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [filterValue, setFilterValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [selectedCertificate, setSelectedCertificate] = useState(null);

  const { data: issuedData } =
    useSelector((state) => state.certificate?.issued) || {};
  const userData = useSelector((state) => state?.auth?.users);

  useEffect(() => {
    if (userData?.user?.userid?.email) {
      dispatch(fetchIssuedCertificateUser(userData.user.userid.email));
    }
  }, [dispatch, userData]);

  useEffect(() => {
    if (issuedData) {
      setFilteredData(issuedData);
    }
  }, [issuedData]);

  const handleDownload = (row) => {
    const serialNumber = row.serialNumber;
    const email = userData.user.userid.email;

    dispatch(downloadCertificate({ email, serialNumber })).then((response) => {
      if (response.error) {
        toast.error('Download failed: ' + response.error.message);
      } else {
        const blob = response.payload;
        if (blob instanceof Blob) {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `certificate_${serialNumber}.png`;
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
          toast.success('Certificate downloaded successfully!');
        } else {
          toast.error('Failed to download: Invalid file format');
        }
      }
    });
  };

  const handleOpenModal = (row) => {
    setSelectedCertificate(row);
    setOpenModal(true);
  };

  const handleOpenShareModal = (row) => {
    setSelectedCertificate(row);
    setOpenShareModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedCertificate(null);
  };

  const handleCloseShareModal = () => {
    setOpenShareModal(false);
    setSelectedCertificate(null);
  };

  const columns = [
    { name: 'Event Name', selector: (row) => row.eventName, sortable: true },
    { name: 'Event Date', selector: (row) => row.eventDate, sortable: true },
    { name: 'Issued Date', selector: (row) => row.issueDate, sortable: true },
    {
      name: 'Serial Number',
      selector: (row) => row.serialNumber,
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row) => (
        <>
          <Tooltip title="Download">
            <CloudDownload
              fontSize="small"
              style={{ marginRight: 14, color: `${palette.primary}` }}
              onClick={() => handleDownload(row)}
            />
          </Tooltip>
          <Tooltip title="View">
            <Visibility
              fontSize="small"
              style={{ marginRight: 14, color: `${palette.primary}` }}
              onClick={() => handleOpenModal(row)}
            />
          </Tooltip>
          <Tooltip title="Share">
            <ShareIcon
              fontSize="small"
              style={{ marginRight: 14, color: `${palette.primary}` }}
              onClick={() => handleOpenShareModal(row)}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
  };

  const { t } = useTranslation();

  return (
    <>
      <CertificateTabs />
      <WholePage>
        <SearchEmail>
          <TextField
            id="outlined-basic"
            label={t('certificate.searchText')}
            size="small"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleFilterChange}
          />
        </SearchEmail>
        <TableDiv>
          <TableUtilityLib columns={columns} data={filteredData} />
        </TableDiv>
      </WholePage>

      {/* Modal to view the certificate */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={{ ...modalStyle, width: isMobile ? '95%' : '50%' }}>
          <IconButton
            aria-label={t('certificate.closeLabel')}
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: `${palette.primary}`,
            }}
          >
            <CloseIcon />
          </IconButton>
          {selectedCertificate ? (
            <img
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              src={
                selectedCertificate?.certificateImage ||
                'path/to/placeholder-image.jpg'
              }
              alt="certificate image"
            />
          ) : (
            <p>No certificate selected</p>
          )}
        </Box>
      </Modal>

      {/* Share Modal */}
      <Modal open={openShareModal} onClose={handleCloseShareModal}>
        <Box sx={modalStyle}>
          <IconButton
            aria-label="Close"
            onClick={handleCloseShareModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: `${palette.primary}`,
            }}
          >
            <CloseIcon />
          </IconButton>
          <br />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 2,
            }}
          >
            <IconButton
              color="primary"
              href={`https://www.linkedin.com/shareArticle?mini=true&url=${selectedCertificate?.certificateImage}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedInIcon />
            </IconButton>
            <IconButton
              color="primary"
              href={`https://www.instagram.com/?url=${selectedCertificate?.certificateImage}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon />
            </IconButton>
            <IconButton
              color="primary"
              href={`https://wa.me/?text=${selectedCertificate?.certificateImage}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <WhatsAppIcon />
            </IconButton>
            <IconButton
              color="primary"
              href={`https://www.facebook.com/sharer/sharer.php?u=${selectedCertificate?.certificateImage}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon />
            </IconButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default LearnerCertificate;
