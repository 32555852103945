import React, { useState, useMemo } from 'react';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Container,
  Typography,
  Tooltip,
  IconButton,
  Grid,
  Button,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { palette } from '../../../styles/palette';
import JoditReact from 'jodit-react';
import { useDispatch } from 'react-redux';
import { addItem } from '../../../features/courses/addCourseDetailsSlice';
import { useParams } from 'react-router-dom';
import '../../Utilities/Sample.css';
import { styled } from '@mui/system';
import {
  EditorContainer,
  DeleteBox,
  StyledTextField,
  ButtonContainer,
  BackButtonContainer,
  HeaderContainer,
} from '../../../styles/Courses/CourseModuleStyles';

const DiscardButton = styled(Button)`
  color: ${palette.primary};
  border-color: ${palette.gray};
  margin-right: 10px;
  width: 180px;
`;

const SaveButton = styled(Button)`
  background-color: ${palette.primary};
  color: ${palette.white};
`;

const UploadMultimedia = ({
  onClose,
  selectedItem,
  lessonId,
  moduleId,
  handleDeleteTopic
}) => {
  const { id, courseStructure } = useParams();
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState('upload');
  const [key, setKey] = useState(0); // State to manage key prop
  const reloadViewer = () => {
    // Increment key to force re-rendering of PDFViewer with a new instance
    setKey((prevKey) => prevKey + 1);
  };
  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };
  

  const[saved ,setSaved] = useState(true);
  const handleOnClose = () => {
    const data = { ...formData, preview: false}
    console.log("abc data from go back",data)
    if(saved && (formData.uploadUrl  || formData.link )){
      onClose();

    }
    else{
      dispatch(addItem(data));
      onClose()
    }

   

  };

  const config = useMemo(
    () => ({
      placeholder: 'Enter the description',
      readonly: false,
    }),
    [],
  );

  const handleDelete = () => {
    handleDeleteTopic(
      selectedItem?.moduleId,
      selectedItem?.lessonId,
      selectedItem?.id,
    );
  };

  const [formData, setFormData] = useState({
    selectedValue: 'upload',
    link: selectedItem?.link || '',
    uploadUrl: '',
    moduleId: selectedItem?.moduleId || '',
    lessonId: selectedItem?.lessonId || '',
    title: selectedItem?.title || '',
    textDescription: selectedItem?.textDescription || '',
    id: selectedItem?.id || '',
    courseStructure: selectedItem?.courseStructure || '',
    contentType: selectedItem?.contentType || '',
    preview:selectedItem?.preview ||false,
  });

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      lessonId: lessonId,
      moduleId: moduleId,
      id: selectedItem?.id||generateRandomNumber(),
      contentType: 'Multimedia',
      courseStructure: courseStructure,
    }));
    console.log(formData);
  };

  const generateRandomNumber = () => {
    const min = 100000; // Minimum 6-digit number
    const max = 999999; // Maximum 6-digit number
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const handleSubmit = async () => {
    if(formData.uploadUrl || formData.link|| formData.fileName)
      {
        
        
        const data = {...formData, preview:true}
        console.log('abc from  the submit',data);
        
        await dispatch(addItem(data));
      } else{
        
        const data = { ...formData, preview: false}
        console.log("abc data from the save for the draft",data)
    await dispatch(addItem(data));
      } 
    
      setSaved(true);
      onClose();
      };

  console.log('multimidia link', formData.link);

  return (
    <Container>
      <HeaderContainer>
        {/* Grid container aligned to the left */}
        <BackButtonContainer
          container
          alignItems="center"
          spacing={1}
          sx={{ mt: 1, mb: 2 }}
        >
          <Grid item>
            <Tooltip title="Go Back">
              <IconButton
                size="small"
                onClick={handleOnClose}
                sx={{ color: `${palette.THEME_THREE}` }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item sx={{ mt: 1 }}>
            <Typography variant="h5" gutterBottom>
              Multimedia
            </Typography>
          </Grid>
        </BackButtonContainer>

        {/* Buttons div aligned to the right */}
        <ButtonContainer>
          <DiscardButton variant="outlined">Discard Changes</DiscardButton>
          {formData.title?<SaveButton variant="contained" onClick={handleSubmit}>
            Save
          </SaveButton>:<Button disabled>Save</Button>}
        </ButtonContainer>
      </HeaderContainer>

      <EditorContainer>
        <StyledTextField
          variant="outlined"
          label="Title"
          style={{ width: '100%', marginBottom: '10px' }}
          name="title"
          value={formData.title}
          onChange={handleInputChange}
        />
        <JoditReact
          value={formData.textDescription}
          config={config}
          onChange={(newContent) =>
            setFormData((prevData) => ({
              ...prevData,
              textDescription: newContent,
            }))
          }
        />
      </EditorContainer>

      <RadioGroup
        row
        style={{ marginLeft: '20px', marginBottom: '2%', marginTop: '12px' }}
        defaultValue="upload"
        value={selectedValue}
        onChange={handleRadioChange}
      >
        <FormControlLabel
          value="publicUrl"
          control={<Radio />}
          label="Public Url"
        />
      </RadioGroup>

      {/* Render different content based on selected radio button */}

      {selectedValue === 'publicUrl' && (
        <Grid item xs={12}>
          <TextField
            label="Link"
            name="link"
            value={formData.link}
            onChange={handleInputChange}
            sx={{ width: '100%' }}
          />
        </Grid>
      )}

      {formData.link && (
        <div style={{ marginTop: '20px' }}>
          <Typography variant="h6">Uploaded MultiMedia</Typography>
          <iframe
            src={formData.link}
            width="100%"
            height="600px"
            style={{ border: 'none' }}
          />
        </div>
      )}

      <DeleteBox>
        <h4 style={{ marginRight: '10px' }}>
          Are you sure you want to delete this topic? This action is
          irreversible.
        </h4>
        <br />
        <Button variant="contained" color="error" onClick={handleDelete}>
          Delete Topic
        </Button>
      </DeleteBox>
    </Container>
  );
};

export default UploadMultimedia;
