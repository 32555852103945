import React, { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Container,
  Typography,
  Tooltip,
  IconButton,
  Grid,
  InputAdornment,
  Button,
  Box,
} from '@mui/material';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import JoditReact from 'jodit-react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { addItem } from '../../../features/courses/addCourseDetailsSlice';
import { useParams } from 'react-router-dom';
import ProgressModal from '../../Utilities/ProgressModal';


import {
  DeleteBox,
  StyledTextField,
  ButtonContainer,
  BackButtonContainer,
  HeaderContainer,
} from '../../../styles/Courses/CourseModuleStyles';
import styled from 'styled-components';
import { palette } from '../../../styles/palette';

const StyledRadioGroup = styled(RadioGroup)`
  margin-left: 20px;
  margin-bottom: 2%;
  margin-top: 12px;
`;

const VideoUploadContainer = styled(Container)`
  margin-right: 10px;
  margin-left: 10px;
`;

const StyledInputAdornment = styled(InputAdornment)`
  display: flex;
  align-items: center;
`;

const VideoUpload = ({
  isOpen,
  onClose,
  selectedItem,
  onBackButtonClick,
  lessonId,
  moduleId,
  handleDeleteTopic,
}) => {

  console.log("abc selected item",selectedItem);
  const { id, courseStructure } = useParams();
  const dispatch = useDispatch();
  const [videoKey, setVideoKey] = useState(Date.now());
const [saved,setSaved]=useState(false)
  const [formData, setFormData] = useState({
    selectedValue: 'upload',
    link: selectedItem?.link || '',
    uploadUrl: '',
    moduleId: selectedItem?.moduleId || '',
    lessonId: selectedItem?.lessonId || '',
    title: selectedItem?.title || '',
    textDescription: selectedItem?.textDescription || '',
    id: selectedItem?.id || '',
    courseStructure: selectedItem?.courseStructure || '',
    contentType: selectedItem?.contentType || '',
    fileName: selectedItem?.fileName || '',
    preview:selectedItem?.preview ||true,
  });
console.log('abc form data from the selected item',formData)
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [file, setFile] = useState(null);
  const [videoShow, setVideoShow] = useState(false);

  useEffect(() => {
    setFormData({
      selectedValue: 'upload',
      link: selectedItem?.link || '',
      uploadUrl: '',
      moduleId: selectedItem?.moduleId || '',
      lessonId: selectedItem?.lessonId || '',
      title: selectedItem?.title || '',
      textDescription: selectedItem?.textDescription || '',
      id: selectedItem?.id || '',
      courseStructure: selectedItem?.courseStructure || '',
      contentType: selectedItem?.contentType || '',
      fileName: selectedItem?.fileName || '',
      preview:selectedItem?.preview||true
    });
  }, [selectedItem]);
  console.log("form data abc after useeffect",formData);

  const config = useMemo(
    () => ({
      placeholder: 'Enter the video description',
      readonly: false,
    }),
    [],
  );

  useEffect(() => {
    setVideoKey(Date.now()); // Update video key whenever formData.link changes
  }, [formData.link]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Update form data, but handle `link` field differently based on selected value
    setFormData((prevData) => ({
      ...prevData,
      [name]: value, // Only update the field being edited (e.g., link, title)
      lessonId: selectedItem?.lessonId || lessonId,
      moduleId: selectedItem?.moduleId || moduleId,
      id: selectedItem?.id ||generateRandomNumber(),
      contentType: 'Video',
      courseStructure: courseStructure,
      // If selectedValue is "upload", set the link to the uploadUrl, otherwise use the value from the input field.
      link:
        formData.selectedValue !== 'upload'
          ? value
          : formData.uploadUrl?.split('?')[0],
    }));
  };

  
  const generateRandomNumber = () => {
    const min = 100000; // Minimum 6-digit number
    const max = 999999; // Maximum 6-digit number
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    setFormData((prevData) => ({
      ...prevData,
      selectedValue: value,
      link: '', // Reset the link field when changing video type
    }));
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFormData((prevData) => ({
      ...prevData,
      fileName: selectedFile?.name,
    }));
    await requestUploadUrl(selectedFile);
  };

  const requestUploadUrl = async (file) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/course/uploadVideo`,
        {
          fileName: file?.name,
          fileType: file?.type,
        },
      );
      setFormData((prevData) => ({
        ...prevData,
        link: response.data.url?.split('?')[0],
        uploadUrl: response.data.url,
      }));
    } catch (error) {
      console.error('Error requesting upload URL:', error);
    }
  };

  const uploadToS3 = async () => {
    console.log(uploadToS3);
    setLoading(true);
    setProgress(0);
    setCompleted(false);
    try {
      await axios.put(formData.uploadUrl, file, {
        headers: {
          'Content-Type': file?.type,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          setProgress(percentCompleted);
        },
      });

      setCompleted(true);
      setLoading(false);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleFileRemove = () => {
    setFile(null);
    setFormData((prevData) => ({
      ...prevData,
      fileName: '',
      uploadUrl: '',
      link: '',
    }));
  };

  const handleUpload = async () => {
    console.log('handleUpload');
    if (file) {
      await uploadToS3();
      toast.success('File Upload successfully!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });

      setVideoShow(true);
    }
  };

  const handleSubmit = async () => {
if(formData.uploadUrl || formData.link|| formData.fileName)
  {
    const data = { ...formData, preview: true}
    
    console.log('abc from  the submit',data)
    
    await dispatch(addItem(data));
  } else{
    
    const data = { ...formData, preview: false}
    console.log("abc data from the save for the draft",data)
await dispatch(addItem(data));
  } 

  setSaved(true);
  onClose();
  };

  const handleOnClose = () => {
    const data = { ...formData, preview: false}
    console.log("abc data from go back",data)
    if(saved && (formData.uploadUrl  || formData.link || formData.fileName)){
      onClose();

    }
    else{
      dispatch(addItem(data));
      onClose()
    }

   

  };

  const handleDelete = () => {
    handleDeleteTopic(
      selectedItem?.moduleId,
      selectedItem?.lessonId,
      selectedItem?.id,
    );
  };
  console.log("abc formdata video",FormData);
  return (
    <VideoUploadContainer>
      <HeaderContainer>
        <BackButtonContainer
          container
          alignItems="center"
          spacing={1}
          sx={{ mt: 1, mb: 2 }}
        >
          <Grid item>
            <Tooltip title="Go Back">
              <IconButton
                size="small"
                onClick={handleOnClose}
                sx={{ color: `${palette.THEME_THREE}` }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item sx={{ mt: 1 }}>
            <Typography variant="h5" gutterBottom>
              Video Upload
            </Typography>
          </Grid>
        </BackButtonContainer>
        <ButtonContainer>
          <Button
            variant="outlined"
            style={{
              color: `${palette.primary}`,
              borderColor: `${palette.gray}`,
              marginRight: '10px',
              width: '180px',
            }}
          >
            Discard Changes
          </Button>
          {formData.title.trim()?<Button variant="contained"
  style={{
    backgroundColor: `${palette.primary}`,
    color: `${palette.white}`,
  }}
  onClick={handleSubmit}>Save</Button>:<Button disabled>Save</Button>}
          {/* <Button
  variant="contained"
  style={{
    backgroundColor: `${palette.primary}`,
    color: `${palette.white}`,
  }}
  onClick={handleSubmit}
  disabled={!formData.title.trim()} // Disable if title is empty or whitespace
>
  {formData.title.trim()?"Save":"Add title first"}
</Button> */}
        </ButtonContainer>
      </HeaderContainer>

      <StyledTextField
        variant="outlined"
        label="Title"
        name="title"
        value={formData.title}
        style={{ width: '100%', marginBottom: '10px' }}
        onChange={handleInputChange}
      />
      <JoditReact
        value={formData.textDescription}
        config={{ ...config, height: 400 }}
        onChange={(newContent) =>
          setFormData((prevData) => ({
            ...prevData,
            textDescription: newContent,
          }))
        }
      />

      <StyledRadioGroup
        row
        value={formData.selectedValue}
        onChange={handleRadioChange}
      >
        <FormControlLabel value="upload" control={<Radio />} label="Upload" />
        <FormControlLabel value="youtube" control={<Radio />} label="YouTube" />
        <FormControlLabel value="vimeo" control={<Radio />} label="Vimeo" />
        <FormControlLabel value="link" control={<Radio />} label="Link" />
        <FormControlLabel
          value="embedcode"
          control={<Radio />}
          label="Embed Code"
        />
      </StyledRadioGroup>

      {formData.selectedValue === 'upload' && (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={10}>
            <StyledTextField
              variant="outlined"
              label="Upload Video File"
              value={formData.fileName}
              helperText="Max video size 2GB"
              InputProps={{
                startAdornment: (
                  <StyledInputAdornment position="end"></StyledInputAdornment>
                ),
                endAdornment: (
                  <StyledInputAdornment position="end">
                    {formData.fileName && (
                      <IconButton
                        onClick={handleFileRemove}
                        style={{ color: 'red' }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                    {!formData.fileName && (
                      <Button
                        variant="contained"
                        component="label"
                        style={{
                          borderRadius: 4,
                          backgroundColor: ` ${palette.primary}`,
                          color: `${palette.white}`,
                        }}
                      >
                        Choose File
                        <input
                          type="file"
                          style={{ display: 'none' }}
                          onChange={handleFileChange}
                        />
                      </Button>
                    )}
                  </StyledInputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              onClick={handleUpload}
              style={{ backgroundColor: '#0684FB', color: 'white', bottom: 15 }}
            >
              Upload
            </Button>
          </Grid>
          <ProgressModal
            open={loading}
            progress={progress}
            completed={completed}
            onClose={() => setLoading(false)}
          />
        </Grid>
      )}

      {formData.selectedValue === 'youtube' && (
        <StyledTextField
          variant="outlined"
          label="YouTube Link"
          name="link"
          value={formData.link}
          onChange={handleInputChange}
        />
      )}

      {formData.selectedValue === 'vimeo' && (
        <StyledTextField
          variant="outlined"
          label="Vimeo Link"
          name="link"
          value={formData.link}
          onChange={handleInputChange}
        />
      )}

      {formData.selectedValue === 'link' && (
        <StyledTextField
          variant="outlined"
          label="Link"
          name="link"
          value={formData.link}
          onChange={handleInputChange}
        />
      )}

      {formData.selectedValue === 'embedcode' && (
        <StyledTextField
          variant="outlined"
          label="Embed Code"
          name="link"
          value={formData.link}
          onChange={handleInputChange}
          multiline
          rows={3}
        />
      )}

      {videoShow && (
        <video key={videoKey} width="600" controls>
          <source src={formData?.link} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}

      {selectedItem?.fileName && (
        <video key={videoKey} width="600" controls>
          <source src={selectedItem?.link} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}

      <DeleteBox>
        <h4 style={{ marginRight: '10px' }}>
          Are you sure you want to delete this topic? This action is
          irreversible.
        </h4>
        <Button variant="contained" color="error" onClick={handleDelete}>
          Delete Topic
        </Button>
      </DeleteBox>
    </VideoUploadContainer>
  );
};

export default VideoUpload;
