import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  Typography,
  Box,
  Stack,
  Grid,
  Container,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  fetchedAllEvents,
  fetchAllTags,
} from '../../features/calendar/CalendarSlice';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';

// Styled components
const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: '12px',
  boxShadow: theme.shadows[4],
  overflow: 'hidden',
  transition: 'transform 0.3s ease',
  display: 'flex',
  height: '200px',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: theme.shadows[8],
  },
}));

const TimeSection = styled(Box)(({ color }) => ({
  backgroundColor: color,
  color: 'white',
  width: '30%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '16px',
  textAlign: 'center',
}));

const ContentSection = styled(Box)(({ theme }) => ({
  width: '70%',
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const ModernTypography = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontWeight: 500,
}));

const DeliveryTypeBox = styled(Box)(({ color }) => ({
  backgroundColor: color,
  color: 'white',
  padding: '4px 8px',
  borderRadius: '12px',
  display: 'inline-block',
  fontSize: '12px',
  marginTop: '8px',
}));

const FilterBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '20px',
  gap: '20px',
  flexWrap: 'wrap',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: '10px',
  },
}));

const HorizontalCard = () => {
  const [tagsArray, setTagsArray] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('Today');
  const [selectedTag, setSelectedTag] = useState('All'); // Set default value to 'All'

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const events = useSelector((state) =>
    state.calendar.data.filter((event) => event && !event.isDeleted),
  );
  const { users } = useSelector((state) => state.auth);
  const { tags, loading, error } = useSelector((state) => state.calendar);

  useEffect(() => {
    dispatch(fetchedAllEvents());
    const fetchTags = async () => {
      const result = await dispatch(fetchAllTags());
      setTagsArray(['All', ...(result.payload || [])]);
    };
    fetchTags();
  }, [dispatch]);

  const handleRegister = (eventName) => {
    if (users.user.userid?.role === 'Admin') {
      navigate(`/EventsNavigator/eventdetails/${eventName}`);
    } else {
      navigate(`/LearnersEventsNavigator/eventdetails/${eventName}`);
    }
  };

  const today = moment().startOf('day');
  const eventsToday = events.filter(
    (event) => event && moment(event.SDate).isSame(today, 'day'),
  );
  const eventsUpcoming = events.filter(
    (event) => event && moment(event.SDate).isAfter(today, 'day'),
  );

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    return `${month}/${day}`;
  };

  const getColorByDelivery = (deliveryType) => {
    switch (deliveryType) {
      case 'In-Person':
        return '#35be57';
      case 'Live Online':
        return '#f47d89';
      case 'Hybrid':
        return '#ed93cd';
      case 'Self-paced Online':
        return '#f47d89';
      case 'Interactive Webinar':
        return '#68c5df';
      case 'Bootcamp':
        return '#947fe4';
      case 'Video-based Course':
        return '#e1956c';
      default:
        return '#a1d9b9';
    }
  };

  const { t } = useTranslation();

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };

  const handleTagChange = (event) => {
    setSelectedTag(event.target.value);
  };

  const filteredEvents =
    selectedTag === 'All'
      ? selectedFilter === 'Today'
        ? eventsToday
        : eventsUpcoming
      : selectedFilter === 'Today'
        ? eventsToday.filter((event) => event.tags.includes(selectedTag))
        : eventsUpcoming.filter((event) => event.tags.includes(selectedTag));

  return (
    <Container>
      <FilterBox>
        <Button
          variant={selectedFilter === 'Today' ? 'contained' : 'outlined'}
          onClick={() => handleFilterChange('Today')}
          sx={{ minWidth: '150px', height: '50px' }}
        >
          Today
        </Button>
        <Button
          variant={selectedFilter === 'Upcoming' ? 'contained' : 'outlined'}
          onClick={() => handleFilterChange('Upcoming')}
          sx={{ minWidth: '150px', height: '50px' }}
        >
          Upcoming
        </Button>

        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="tag-label">Select Tag</InputLabel>
          <Select
            labelId="tag-label"
            id="tag-select"
            value={selectedTag}
            label="Select Tag"
            onChange={handleTagChange}
            sx={{ height: '50px' }}
          >
            {tagsArray.map((tag, index) => (
              <MenuItem key={index} value={tag}>
                {tag}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FilterBox>

      <Grid
        container
        spacing={2}
        sx={{ marginBottom: '30px', marginTop: '40px', padding: '5px' }}
      >
        {filteredEvents.map(
          (event, index) =>
            event.EventName && (
              <Grid item xs={12} lg={6} key={index}>
                <StyledCard>
                  <TimeSection color={getColorByDelivery(event.Delivery)}>
                    <Typography variant="h6" sx={{ fontSize: '18px' }}>
                      {new Date(event.SDate)
                        .toLocaleDateString('en-US', { weekday: 'short' })
                        .substring(0, 3)}
                    </Typography>
                    <Typography variant="h4" sx={{ fontWeight: 600 }}>
                      {formatDate(event.SDate)}
                    </Typography>
                    <Typography variant="body2">{event.STime}</Typography>
                  </TimeSection>
                  <ContentSection>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Box sx={{ marginLeft: 2 }}>
                        <ModernTypography variant="h6">
                          {event.EventName}
                        </ModernTypography>
                        <Typography variant="body2" sx={{ marginTop: 1 }}>
                          {event.EventDesp && (
                            <>
                              {event.EventDesp.split(' ').slice(0, 7).join(' ')}
                              {event.EventDesp.split(' ').length > 7
                                ? '...'
                                : ''}
                            </>
                          )}
                        </Typography>
                        <DeliveryTypeBox
                          color={getColorByDelivery(event.Delivery)}
                        >
                          {event.Delivery}
                        </DeliveryTypeBox>
                      </Box>
                    </Stack>
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: '12px',
                        marginTop: 2,
                        backgroundColor: getColorByDelivery(event.Delivery),
                        '&:hover': {
                          backgroundColor: getColorByDelivery(event.Delivery),
                          opacity: 0.8,
                        },
                      }}
                      endIcon={<ArrowForwardIosIcon />}
                      onClick={() => handleRegister(event.EventName)}
                    >
                      {t('register')}
                    </Button>
                  </ContentSection>
                </StyledCard>
              </Grid>
            ),
        )}
      </Grid>
    </Container>
  );
};

export default HorizontalCard;
