import React, { useState } from 'react';
import { Link } from 'react-router-dom';
const Quizzes = () => {
  return (
    <>
      <p>fdvdvvf</p>{' '}
    </>
  );
};

export default Quizzes;
