import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  ButtonDiv,
  SearchEmail,
} from '../../../styles/Certificate/CertificateStyle';
import {
  InputAdornment,
  TextField,
  Tooltip,
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CommonButton from '../../Utilities/CommonButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { gettemplate } from '../../../features/courses/addCourseDetailsSlice';
import { palette } from '../../../styles/palette';
import BackButton from '../../Utilities/BackButton';

const SelectTemplte = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { templates, loading, error } =
    useSelector((state) => state.addCourseDetails) || {};

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState(null); // State to hold the selected template

  useEffect(() => {
    console.log('Dispatching gettemplate action');
    dispatch(gettemplate());
  }, [dispatch]);

  useEffect(() => {
    console.log('Templates state updated:', templates);
  }, [templates]);

  useEffect(() => {
    console.log('Search query updated:', searchQuery);
  }, [searchQuery]);

  // Filter templates based on the search query
  const filteredTemplates = templates.filter((template) =>
    template.templateName.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  console.log('Filtered templates:', filteredTemplates);

  // Handle template selection
  const handleSelectTemplate = (template) => {
    setSelectedTemplate(template);
    console.log('Selected Template:', template);
    // You can add additional actions, like redirecting or showing details
  };

  return (
    <>
      <SearchEmail style={{ padding: 15 }}>
        <BackButton />
        <TextField
          label={t('certificate.searchText')}
          size="small"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Tooltip title="Create New Template">
          <ButtonDiv style={{ marginRight: '30px' }}>
            <CommonButton
              onClick={() => {
                console.log('Navigating to Create Template page');
                navigate('/CourseBuilder/CreateTemplte');
              }}
              label={t('certificate.certificateCreateLabel')}
            />
          </ButtonDiv>
        </Tooltip>
      </SearchEmail>

      {/* Display Loading or Error */}
      {loading && <p>Loading templates...</p>}
      {error && <p>Error: {error}</p>}

      {/* Display Filtered Templates in Cards */}
      <Grid container spacing={3} style={{ padding: '15px' }}>
        {filteredTemplates.length > 0 ? (
          filteredTemplates.map((template) => (
            <Grid item xs={12} sm={6} md={4} key={template._id}>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="div">
                    {template.templateName}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {t('modelType')}: {template.modelType}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {t('templateType')}: {template.templateType}
                  </Typography>
                  {/* <Typography variant="body2" color="text.secondary">
                    {t('modules')}: {template.moduleCount}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {t('chapters')}: {template.chapterCount}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {t('lessons')}: {template.lessonCount}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {t('topics')}: {template.topicCount}
                  </Typography> */}
                </CardContent>
                <CardActions>
                  <Tooltip title={t('certificate.certificateSelectLabel')}>
                    <IconButton onClick={() => handleSelectTemplate(template)}>
                      <CheckCircleIcon
                        style={{
                          color: `${palette.primary}`,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip>
                    <IconButton
                      onClick={() => {
                        console.log('View details of', template.templateName);
                        // Handle view action
                      }}
                      aria-label="view"
                    >
                      <VisibilityIcon
                        style={{
                          color: `${palette.primary}`,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <IconButton
                    onClick={() => {
                      console.log('Edit', template.templateName);
                      // Handle edit action (e.g., navigate to an edit page)
                    }}
                    aria-label="edit"
                  >
                    <EditIcon
                      style={{
                        color: `${palette.primary}`,
                      }}
                    />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      console.log('Delete', template.templateName);
                      // Handle delete action (e.g., dispatch a delete action)
                    }}
                    aria-label="delete"
                  >
                    <DeleteIcon
                      style={{
                        color: `${palette.primary}`,
                      }}
                    />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          ))
        ) : (
          <p>No templates found</p>
        )}
      </Grid>

      {/* Display selected template information */}
      {selectedTemplate && (
        <div>
          <h3>{t('Selected Template')}</h3>
          <p>
            {t('Template Name')}: {selectedTemplate.templateName}
          </p>
          <p>
            {t('Template Type')}: {selectedTemplate.templateType}
          </p>
          {/* Add more template details if needed */}
        </div>
      )}
    </>
  );
};

export default SelectTemplte;
