import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEventDetails } from '../../features/calendar/CalendarSlice';

import { Typography, Box, Button, Paper, IconButton } from '@mui/material';
import ContactUsImg from '../../images/course-bg.png';
import ContactUsTwo from '../../images/session_details.png';
import backgroundImageforBox1 from '../../images/2539_Large.jpg';

import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import axios from 'axios';
import EventsTabs from './EventsTabs';
import { Toast } from 'reactstrap';
import { toast } from 'react-toastify';
import { keyframes } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Footer from '../Landing/Footer';

const EventDetails = () => {
  const navigate = useNavigate();

  const { eventname } = useParams();
  const dispatch = useDispatch();
  const { eventDetails } = useSelector((state) => state.calendar);
  const { users } = useSelector((state) => state.auth);
  const [showPayment, setShowpayment] = useState(false);

  useEffect(() => {
    dispatch(fetchEventDetails(eventname));
    console.log('checking', users.user.userid.events);
    console.log('checking filterEvent', filterEvent);
  }, [dispatch, eventname]);

  const handleUsers = () => {
    navigate(`/EventsNavigator/enrolledusers/${eventname}`);
  };

  const handleBack = () => {
    navigate('/EventsNavigator');
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };

  // Function to load the Razorpay script
  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  // Function to handle payment
  const handlePaymentButtonClick = async () => {
    const scriptLoaded = await loadRazorpayScript();

    if (!scriptLoaded) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }

    try {
      // Create an order on the server
      const {
        data: { order },
      } = await axios.post('http://localhost:8000/api/create/orderId', {
        amount: 500, // You can make this dynamic if needed
      });

      const options = {
        key: 'rzp_live_d9YCA3GYKxEajW',
        amount: order.amount,
        currency: order.currency,
        name: 'Leapot Technologies',
        description: 'Test Transaction',
        image:
          'https://leapot-lms.s3.ap-south-1.amazonaws.com/lms/assets/Blue_logo.png',
        order_id: order.id,
        handler: (response) => {
          // On payment success
          axios
            .post('http://localhost:8000/api/payment/success', {
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
              userId: users.user.userid,
              eventId: eventDetails._id,
              amount: order.amount / 100,
            })
            .then(() => {
              alert('Payment successful!');
            })
            .catch((error) => {
              console.error('Error sending payment details:', error);
              alert('Payment failed!');
            });
        },
        prefill: {
          name: users.user.name || '',
          email: users.user.email || '',
        },
        theme: {
          color: '#007bff',
        },

        modal: {
          ondismiss: () => {
            axios
              .post('http://localhost:8000/api/payment/failure', {
                razorpay_order_id: order.id,
                userId: users.user.userid,
                eventId: eventDetails._id,
                amount: order.amount / 100,
              })
              .then(() => {
                toast.error('Payment failed and recorded.');
              })
              .catch((error) => {
                console.error('Error recording payment failure:', error);
                alert('Failed to record payment failure.');
              });
          },
        },
      };

      // Create a Razorpay instance and open the payment modal
      const razor = new window.Razorpay(options);
      razor.open();
    } catch (error) {
      console.error('Error creating order:', error);
      alert('Error initiating payment.');
    }
  };

  let filterEvent;
  const palette = {
    newColor: '#e0f7fa',
    primary: '#00796b',
    white: '#ffffff',
    gray: '#b0bec5',
    heading: '#004d40',
  };

  const theme = {
    themeBorderRadius: '15px',
  };

  const upperCase = eventname
    ?.split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  useEffect(() => {
    if (users.user.userid?.role === 'Learner') {
      filterEvent = users.user.userid.events.map((event) => event);

      if (filterEvent.includes(eventname) && !showPayment) {
        setShowpayment(true);
        console.log('checking', true);
      } else if (!filterEvent.includes(eventname) && showPayment) {
        setShowpayment(false);
      }
    }
  }, [users, eventname, showPayment, setShowpayment]);

  const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

  return (
    <>
      {users.user.userid?.role === 'Admin' ? (
        <EventsTabs />
      ) : (
        <Box sx={{ display: 'background:"pink' }}></Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: 2,
          backgroundImage: `url(${backgroundImageforBox1})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <Box sx={{ display: 'flex', px: 1 }}>
          <Box
            sx={{
              display: 'flex',
              width: eventDetails.image ? '45%' : '100%',

              flexDirection: 'column',
            }}
          >
            <IconButton
              sx={{ alignSelf: 'flex-start', mb: 2 }}
              onClick={() => window.history.back()} // Navigate back in browser history
            >
              <ArrowBackIcon />
            </IconButton>
            <Box
              sx={{
                display: 'flex',
                height: '100%',
                flexDirection: 'column',
                padding: 4,
                gap: 3,
              }}
            >
              <Typography variant="h2" sx={{ fontWeight: 'bold' }}>
                {upperCase}
              </Typography>
              <Typography variant="h6" fontFamily={'Poppins, sans-serif'}>
                {' '}
                {eventDetails.EventDesp}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  height: '3rem',
                }}
              >
                {users.user.userid?.role === 'Learner' ? (
                  showPayment ? (
                    <Button
                      variant="contained"
                      onClick={handlePaymentButtonClick}
                    >
                      Pay Now
                    </Button>
                  ) : (
                    <Button variant="contained" onClick={handleUsers}>
                      Register Now
                    </Button>
                  )
                ) : users.user.userid?.role === 'Admin' ? (
                  <Button variant="contained" onClick={handleUsers}>
                    Manually Register Learners
                  </Button>
                ) : null}

                <Box my={-1}>
                  <IconButton href="https://www.instagram.com" target="_blank">
                    <InstagramIcon
                      color="primary"
                      sx={{ color: '#E4405F', fontSize: '3rem' }}
                    />
                  </IconButton>
                  <IconButton href="https://www.linkedin.com" target="_blank">
                    <LinkedInIcon
                      color="primary"
                      sx={{ color: '#0A66C2', fontSize: '3rem' }}
                    />
                  </IconButton>
                  <IconButton href="https://www.twitter.com" target="_blank">
                    <TwitterIcon
                      color="primary"
                      sx={{ color: '#1DA1F2', fontSize: '3rem' }}
                    />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Box>
          {eventDetails.image && (
            <Box sx={{ width: '55%', position: 'relative' }}>
              <Paper
                sx={{
                  backgroundImage: eventDetails.image
                    ? `url(${eventDetails.image})`
                    : 'none',
                  backgroundSize: 'cover',
                  height: '100%',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',

                  borderRadius: theme.themeBorderRadius,
                  mb: 4,
                }}
              />
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',

            p: 6,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              px: 2,

              background:
                'linear-gradient(135deg, rgba(255, 255, 255, 0.3), rgba(6, 132, 251, 0.2))',
              borderRadius: '20px',
              boxShadow: '0 10px 40px 0 rgba(0, 0, 0, 0.2)',
              backdropFilter: 'blur(5px)',
              WebkitBackdropFilter: 'blur(5px)',
              border: '1px solid rgba(255, 255, 255, 0.2)', // Softer border for glass effect
              transition: 'transform 0.6s ease-in-out',

              '&:hover': {
                transform: 'scale(1.02)', // Slight zoom on hover
                boxShadow: '0 12px 48px 0 rgba(0, 0, 0, 0.4)', // Slightly stronger shadow on hover
              },
            }}
          >
            <Box
              sx={{
                width: '20%',
                display: 'flex',
                paddingLeft: 6,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                component="img"
                sx={{
                  height: '75%',
                  width: '91%',
                  borderRadius: '10px',
                }}
                alt="Description of the image."
                src={ContactUsImg}
              />
            </Box>
            <Box
              width={'55%'}
              sx={{
                p: 2,
                borderRadius: '8px',
                mx: '3rem',
                color: '#333', // Text color matching the theme
              }}
            >
              <Typography
                sx={{
                  fontWeight: 'bold',
                  position: 'relative',
                  pb: 1,
                  fontSize: '1rem',
                  color: '#333',
                }}
              >
                Course Details
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 2,
                    left: '12%',
                    transform: 'translateX(-50%)',
                    width: '24%',
                    height: '3px',
                    backgroundColor: '#000',
                  }}
                />
              </Typography>

              <Box sx={{ mt: 2, fontSize: '1rem', lineHeight: '1.5' }}>
                <Typography sx={{ fontSize: '1rem', color: 'text.primary' }}>
                  "Whether you're looking to gain essential skills or deepen
                  your expertise, this course is designed with your success in
                  mind.{' '}
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    Affiliated with {eventDetails.AffiliationCategory || 'N/A'},
                  </Box>{' '}
                  you'll receive top-tier instruction and access to
                  comprehensive materials that fully support your learning
                  journey.{' '}
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    A certification will be awarded upon successful completion,
                  </Box>{' '}
                  and your progress will be{' '}
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    thoughtfully assessed based on{' '}
                    {eventDetails.evaluationCriteria || 'N/A'} / evaluated
                    through practical assignments.
                  </Box>{' '}
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    An exam is required to showcase your understanding.
                  </Box>{' '}
                  Each module is carefully crafted to ensure you master the key
                  concepts, with resources curated to enhance your experience.{' '}
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    To complete the course, simply meet the{' '}
                    {eventDetails.completionCriteria || 'N/A'}.
                  </Box>{' '}
                  Don't miss out on this opportunity—secure your spot today and
                  take a significant step forward in your learning journey!"
                </Typography>
              </Box>
            </Box>
          </Box>

          {/*Div2*/}
          <Box
            sx={{
              display: 'flex',
              px: 2,
              background:
                'linear-gradient(135deg, rgba(255, 255, 255, 0.3), rgba(6, 132, 251, 0.2))', // Lighter pink gradient for glass effect
              borderRadius: '20px', // Rounded corners for glass effect
              boxShadow: '0 10px 40px 0 rgba(31, 38, 135, 0.5)',
              backdropFilter: 'blur(5px)',
              WebkitBackdropFilter: 'blur(5px)',
              border: '1px solid rgba(255, 255, 255, 0.25)',
              mt: '1rem',
              transition: 'transform 0.6s ease-in-out',

              // Hover effect for zoom
              '&:hover': {
                transform: 'scale(1.02)', // Slight zoom on hover
                boxShadow: '0 12px 48px 0 rgba(0, 0, 0, 0.3)', // Slightly stronger shadow on hover
              },
            }}
          >
            <Box
              sx={{
                width: '20%',
                display: 'flex',
                paddingLeft: 6,
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                component="img"
                sx={{
                  height: '80%',
                  width: '90%',
                  borderRadius: '10px',
                }}
                alt="Description of the image."
                src={ContactUsTwo}
              />
            </Box>
            <Box
              width={'55%'}
              sx={{
                p: 2,
                borderRadius: '8px',
                mx: '3rem',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 'bold',
                  position: 'relative',
                  pb: 1,
                  fontSize: '1rem',
                  color: '#333',
                }}
              >
                Session Details
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 2,
                    left: '12%',
                    transform: 'translateX(-50%)', // Center the underline
                    width: '24%', // Shorter underline
                    height: '3px', // Thinner underline
                    backgroundColor: '#000',
                  }}
                />
              </Typography>

              <Box sx={{ mt: 2, fontSize: '1rem', lineHeight: '1.5' }}>
                <Typography sx={{ fontSize: '1rem', color: 'text.primary' }}>
                  Mark your calendars for a transformative learning experience
                  from{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {formatDate(eventDetails?.SDate)}
                  </span>
                  ,{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {eventDetails?.STime}
                  </span>{' '}
                  to{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {formatDate(eventDetails?.EDate)}
                  </span>
                  ,{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {eventDetails?.ETime}
                  </span>
                  . Led by{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {eventDetails?.InstName}
                  </span>{' '}
                  at{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {eventDetails?.Location || 'N/A'}
                  </span>
                  , this session offers{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {eventDetails?.Duration}
                  </span>{' '}
                  Hours. Don't miss this opportunity to enhance your expertise—{' '}
                  <a
                    href={eventDetails?.EnrollmentLink || '#'}
                    style={{
                      color: 'primary.main',
                      textDecoration: 'underline',
                    }}
                  >
                    Enroll now
                  </a>{' '}
                  and secure your spot!
                </Typography>
              </Box>
            </Box>
          </Box>

          {eventDetails.Dynamic?.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                mt: '1rem',
                px: 2,
                background:
                  'linear-gradient(135deg, rgba(255, 255, 255, 0.3), rgba(6, 132, 251, 0.2))', // Adjusted gradient with your theme colors
                borderRadius: '20px',
                boxShadow: '0 10px 40px 0 rgba(0, 0, 0, 0.2)',
                backdropFilter: 'blur(5px)',
                WebkitBackdropFilter: 'blur(5px)',
                border: '1px solid rgba(255, 255, 255, 0.2)',
                transition: 'transform 0.6s ease-in-out',

                // Hover effect for zoom
                '&:hover': {
                  transform: 'scale(1.02)', // Slight zoom on hover
                  boxShadow: '0 12px 48px 0 rgba(0, 0, 0, 0.3)', // Slightly stronger shadow on hover
                },
              }}
            >
              <Box
                sx={{
                  width: '20%',
                  display: 'flex',
                  paddingLeft: 6,
                  justifyContent: 'flex-end',
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: '60%',
                    width: '80%',
                    borderRadius: '10px',
                  }}
                  alt="Description of the image."
                  // src={loginbgD}
                />
              </Box>
              <Box
                width={'55%'}
                sx={{
                  p: 2,
                  borderRadius: '8px',
                  mx: '3rem',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    position: 'relative',
                    pb: 1,
                    fontSize: '1rem',
                    color: '#333',
                  }}
                >
                  Additional Fields
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 2,
                      left: '12%',
                      transform: 'translateX(-50%)',
                      width: '24%',
                      height: '3px',
                      backgroundColor: '#000',
                    }}
                  />
                </Typography>

                {eventDetails.Dynamic.map((field, index) => (
                  <Typography
                    key={index}
                    sx={{ mt: 2, color: '#333', fontSize: '0.9rem' }}
                  >
                    {index === 0 && `Enhance your experience with `}
                    {index === 0 && (
                      <strong>{`${field.label}: ${field.value}`}</strong>
                    )}
                    {index === 0 &&
                      `, offering [brief explanation or benefit].`}

                    {index === 1 && `Also included is `}
                    {index === 1 && (
                      <strong>{`${field.label}: ${field.value}`}</strong>
                    )}
                    {index === 1 && `, which provides [benefit or feature].`}

                    {index === 2 && `You’ll also gain access to `}
                    {index === 2 && (
                      <strong>{`${field.label}: ${field.value}`}</strong>
                    )}
                    {index === 2 &&
                      `, designed to [explain the value or purpose].`}
                  </Typography>
                ))}

                {eventDetails.Dynamic.length > 3 && (
                  <Typography sx={{ mt: 2, color: '#333', fontSize: '0.9rem' }}>
                    These additional features ensure you get the most out of
                    this course, making your learning journey even more
                    comprehensive.
                  </Typography>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default EventDetails;
