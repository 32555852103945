import React, { useState } from 'react';
import {
  TextField,
  Tooltip,
  IconButton,
  Grid,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  Paper,
  Tabs,
  Tab,
  Box,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DeleteIcon from '@mui/icons-material/Delete'; // Import the delete icon
import { palette } from '../../../styles/palette';
import QuestionUtility from '../CourseModule/QuestionUtility'; // Import the QuestionUtility component
import { useDispatch, } from 'react-redux';
import { createQuiz } from '../../../features/courses/quizSlice';
import { useParams } from 'react-router-dom';
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const InsertQuiz = ({ onClose }) => {
  const dispatch = useDispatch();
  const [quizType, setQuizType] = useState('');
  const [questionConfigurations, setQuestionConfigurations] = useState([]); // Array to store question configurations
  const [isMandatory, setIsMandatory] = useState(false);
  const [areAllQuestionsRequired, setAreAllQuestionsRequired] = useState(false);
  const [shuffleQuestions, setShuffleQuestions] = useState(false);
  const [shuffleAnswers, setShuffleAnswers] = useState(false);
  const [passingPercentage, setPassingPercentage] = useState('');
  const [isTimerEnabled, setIsTimerEnabled] = useState(false);
  const [questionTiming, setQuestionTiming] = useState('');
  const [showScore, setShowScore] = useState(false);
  const [allowReview, setAllowReview] = useState(false);
  const [showAnswers, setShowAnswers] = useState(false);
  const [maxAttempts, setMaxAttempts] = useState(1);
  const [isPassingPercentageRequired, setIsPassingPercentageRequired] =
    useState(false);
  const [quizTitle, setQuizTitle] = useState('');
  const [activeTab, setActiveTab] = useState(0);

  const { id } = useParams();
  console.log(`insert quiz`, id);

  const initialValues = {
    quizType: 'select',
    questionText: '',
    choices: [],
    // isOptional: false,
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleAddQuestionClick = () => {
    const newQuestionConfiguration = {
      id: questionConfigurations.length,
      ...initialValues,
    };
    setQuestionConfigurations([
      ...questionConfigurations,
      newQuestionConfiguration,
    ]);
  };

  const handleRemoveQuestionUtility = (id) => {
    const updatedConfigurations = questionConfigurations.filter(
      (config) => config.id !== id,
    );
    setQuestionConfigurations(updatedConfigurations);
  };

  const handleAllQuestionsRequiredToggle = () =>
    setAreAllQuestionsRequired(!areAllQuestionsRequired);

  const handleShuffleQuestions = () => setShuffleQuestions(!shuffleQuestions);
  const handleShuffleAnswers = () => setShuffleAnswers(!shuffleAnswers);

  const handlePassingPercentageRequiredToggle = () => {
    setIsPassingPercentageRequired(!isPassingPercentageRequired);
    if (!isPassingPercentageRequired) {
      setPassingPercentage(''); // Reset passing percentage when toggled off
    }
  };

  const handleTimerToggle = () => {
    setIsTimerEnabled(!isTimerEnabled);
    if (!isTimerEnabled) {
      setQuestionTiming(''); // Reset timing when toggled off
    }
  };
  const [quizData, setQuizData] = useState({
    courseId: id,
    title: quizTitle,
    quizType, // Default to one type, update based on user input
    description: '',
    questions: [], // Collect questions using QuestionUtility
    settings: {
      isQuizMandatory: false,
      areAllQuestionsRequired: false,
      shuffleQuestions: false,
      shuffleAnswers: false,
      passingPercentage: 50,
      enableQuizTimer: 60,
      showScoreOnCompletion: false,
      allowReviewOfAnswers: false,
      showCorrectAnswersPostCompletion: false,
      maximumAttempts: 1,
    },
    dueDate: null,
  });
  const handleQuizSubmit = () => {
    dispatch(createQuiz(quizData));
  };
  // Handle changes for quiz data
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setQuizData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '10px',
        }}
      >
        {/* Grid container aligned to the left */}
        <Grid container alignItems="center" spacing={1} sx={{ mt: 1, mb: 2 }}>
          <Grid item>
            <Tooltip title="Go Back">
              <IconButton
                size="small"
                onClick={handleOnClose}
                sx={{ color: `${palette.THEME_THREE}` }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item sx={{ mt: 1 }}>
            <Typography variant="h5" gutterBottom>
              Quiz
            </Typography>
          </Grid>
        </Grid>
        {/* Buttons div aligned to the right */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant="outlined"
            style={{
              color: '#0684FB',
              borderColor: '#ccc',
              marginRight: '10px',
              width: '180px',
            }}
          >
            Discard Changes
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: '#0684FB',
              color: 'white',
              borderColor: '#ccc',
            }}
            // onClick={handleSaveQuiz}
            onClick={handleQuizSubmit}
          >
            Save
          </Button>
        </div>
      </div>
      {/* Tabs for Questions and Settings */}
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="Questions" />
          <Tab label="Settings" />
        </Tabs>
        <TabPanel value={activeTab} index={0}>
          <div style={{ marginRight: '10px', marginLeft: '10px' }}>
            <TextField
              variant="outlined"
              label="Title"
              value={quizData.quizTitle}
              // onChange={(e) => setQuizTitle(e.target.value)}
              onChange={handleChange}
              style={{ width: '100%', marginBottom: '24px' }}
            />
            {/* {questionUtilities.map((utility, index) => (
              <div key={index}>{utility}</div>
            ))} */}
            {questionConfigurations.map((config, index) => (
              <div key={config.id}>
                <QuestionUtility
                  questions={quizData.questions}
                  setQuestions={(questions) =>
                    setQuizData((prev) => ({ ...prev, questions }))
                  }
                  initialValues={initialValues}
                  quizType={quizType}
                  setQuizType={setQuizType}
                  showOptional={false}
                  onClose={() => handleRemoveQuestionUtility(config.id)}
                />
                <Grid item xs={12} container justifyContent="flex-end">
                  <IconButton
                    color="error"
                    onClick={() => handleRemoveQuestionUtility(config.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </div>
            ))}
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Button
                variant="contained"
                component="label"
                color="primary"
                style={{ borderRadius: 4 }}
                onClick={handleAddQuestionClick}
              >
                Add Question
              </Button>
            </Grid>
          </div>
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <Paper elevation={3} sx={{ mt: 4 }}>
            <Grid container spacing={2} sx={{ ml: 2 }}>
              <Typography variant="h6" sx={{ ml: 2, mt: 2 }}>
                Quiz Settings
              </Typography>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isMandatory}
                      onChange={() => setIsMandatory(!isMandatory)}
                    />
                  }
                  label="Is Quiz Mandatory"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={areAllQuestionsRequired}
                      onChange={handleAllQuestionsRequiredToggle}
                    />
                  }
                  label="Are all questions required"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={shuffleQuestions}
                      onChange={handleShuffleQuestions}
                    />
                  }
                  label="Shuffle Questions"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={shuffleAnswers}
                      onChange={handleShuffleAnswers}
                    />
                  }
                  label="Shuffle Answers"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isPassingPercentageRequired}
                      onChange={handlePassingPercentageRequiredToggle}
                    />
                  }
                  label="Is Passing Percentage Required"
                />
                {isPassingPercentageRequired && (
                  <TextField
                    type="number"
                    value={passingPercentage}
                    onChange={(e) => setPassingPercentage(e.target.value)}
                    placeholder="Passing Percentage"
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isTimerEnabled}
                      onChange={handleTimerToggle}
                    />
                  }
                  label="Enable Quiz Timer"
                />
                {isTimerEnabled && (
                  <TextField
                    type="number"
                    value={questionTiming}
                    onChange={(e) => setQuestionTiming(e.target.value)}
                    placeholder="Question Timing (seconds)"
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showScore}
                      onChange={() => setShowScore(!showScore)}
                    />
                  }
                  label="Show Score on Completion"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allowReview}
                      onChange={() => setAllowReview(!allowReview)}
                    />
                  }
                  label="Allow Review of Answers"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showAnswers}
                      onChange={() => setShowAnswers(!showAnswers)}
                    />
                  }
                  label="Show Correct Answers Post Completion"
                />
              </Grid>
              <Grid item xs={12} sx={{ mb: 2 }}>
                <TextField
                  type="number"
                  value={maxAttempts}
                  onChange={(e) => setMaxAttempts(e.target.value)}
                  placeholder="Maximum Attempts"
                  label="Maximum Attempts"
                />
              </Grid>
            </Grid>
          </Paper>
        </TabPanel>
      </Box>
    </div>
  );
};

export default InsertQuiz;
