import React from 'react';

import {
  Card,
  Typography,
  Box,
  Stack,
  Button,
} from '@mui/material';
// import {
//   fetchedAllEvents,
//   fetchAllTags,
// } from '../../features/calendar/CalendarSlice';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';

// Styled components
const StyledCard = styled(Card)(({ theme }) => ({
    borderRadius: '8px',
    boxShadow: theme.shadows[4],
    overflow: 'hidden',
    transition: 'transform 0.3s ease',
    display: 'flex',
    height: '100px',
    '&:hover': {
      transform: 'translateY(-8px)',
      boxShadow: theme.shadows[8],
    },
  }));
  
  const TimeSection = styled(Box)(({ color }) => ({
    backgroundColor: color,
    color: 'white',
    width: '20%',
    height: '70%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px',
    textAlign: 'center',
    margin:'4%',
  }));
  

const UpcomingEventCard = ({
  eventDay,
  eventDate,
  eventTime,
  eventName,
  eventDesp,
  eventDelivery
}) => {
  const getColorByDelivery = (deliveryType) => {
    switch (deliveryType) {
      case 'In-Person':
        return '#35be57';
      case 'Live Online':
        return '#f47d89';
      case 'Hybrid':
        return '#ed93cd';
      case 'Self-paced Online':
        return '#f47d89';
      case 'Interactive Webinar':
        return '#68c5df';
      case 'Bootcamp':
        return '#947fe4';
      case 'Video-based Course':
        return '#e1956c';
      default:
        return '#a1d9b9';
    }
  };

  const ContentSection = styled(Box)(({ theme }) => ({
    width: '70%',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }));

  const ModernTypography = styled(Typography)(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
  }));

  const DeliveryTypeBox = styled(Box)(({ color }) => ({
    backgroundColor: color,
    color: 'white',
    padding: '2px 4px',
    borderRadius: '6px',
    display: 'inline-block',
    fontSize: '10px',
    marginTop: '4px',
  }));
  
  const { t } = useTranslation();

  return (
    <StyledCard>
      <TimeSection color={getColorByDelivery(eventDelivery)}>
        <Typography variant="h6" sx={{ fontSize: '10px' }}>
          {eventDay}
        </Typography>
        <Typography variant="h2" sx={{ fontSize: '1.75rem' }}>
          {eventDate}
        </Typography>
        <Typography variant="body2">{eventTime}</Typography>
      </TimeSection>
      <ContentSection>
        <Stack direction="row" spacing={2} alignItems="center">
          <Box sx={{ marginLeft: 2 }}>
            <ModernTypography variant="h6">{eventName}</ModernTypography>
            <Typography variant="body2" sx={{ marginTop: 1 }}>
              {eventDesp && (
                <>
                  {eventDesp.split(' ').slice(0, 7).join(' ')}
                  {eventDesp.split(' ').length > 7 ? '...' : ''}
                </>
              )}
            </Typography>
            <DeliveryTypeBox color={getColorByDelivery(eventDelivery)}>
              {eventDelivery}
            </DeliveryTypeBox>
          </Box>
        </Stack>
      </ContentSection>
    </StyledCard>
  );
};

export default UpcomingEventCard;
