import React, { useState } from 'react';
import VideoUpload from '../ChapterItems/VideoUpload';
import AudioUpload from '../ChapterItems/AudioUpload';
import InsertText from '../ChapterItems/InsertText';
import PdfUpload from '../ChapterItems/PdfUpload';
import InsertLiveLesson from '../ChapterItems/InsertLiveLesson';
import UploadPresentation from '../ChapterItems/UploadPresentation';
import UploadDownload from '../ChapterItems/UploadDownload';
import UploadMultimedia from '../ChapterItems/UploadMultimedia';
import InsertQuiz from '../ChapterItems/InsertQuiz';
import UploadAssignment from '../ChapterItems/UploadAssignment';
import UploadExam from '../ChapterItems/UploadExam';
import InsertSurvey from '../ChapterItems/InsertSurvey';
import InsertEvaluate from '../ChapterItems/InsertSurvey';
import UploadCodingTest from '../ChapterItems/UploadCodingTest';
import UploadScorm from '../ChapterItems/UploadScorm';

import AddOrCopyTopic from './AddOrCopyTopic';
import Attendify from '../ChapterItems/Attendify';
// import Attendify from '../ChapterItems/Attendify';

const LessonTopics = ({
  data,
  onClose,
  lessonId,
  selectedItem,
  moduleId,
  handleDeleteTopic,
}) => {
  const [state, setState] = useState(data);
  const [isComponentOpen, setIsComponentOpen] = useState(false);

  console.log(data, lessonId, moduleId, 'data');

  const handleCloseComponent = () => {
    setIsComponentOpen(false);
    onClose();
  };

  return (
    <>
      {data === 'Video' && (
        <VideoUpload
          onClose={onClose}
          lessonId={lessonId}
          selectedItem={selectedItem}
          moduleId={moduleId}
          handleDeleteTopic={handleDeleteTopic}
        />
      )}
      {data === 'Audio' && (
        <AudioUpload
          onClose={onClose}
          lessonId={lessonId}
          selectedItem={selectedItem}
          moduleId={moduleId}
          handleDeleteTopic={handleDeleteTopic}
        />
      )}
      {data === 'Text' && (
        <InsertText
          onClose={onClose}
          lessonId={lessonId}
          selectedItem={selectedItem}
          moduleId={moduleId}
          handleDeleteTopic={handleDeleteTopic}
        />
      )}
      {data === 'PDF' && (
        <PdfUpload
          onClose={onClose}
          lessonId={lessonId}
          selectedItem={selectedItem}
          moduleId={moduleId}
          handleDeleteTopic={handleDeleteTopic}
        />
      )}
      {data === 'Download' && (
        <UploadDownload
          onClose={onClose}
          lessonId={lessonId}
          selectedItem={selectedItem}
          moduleId={moduleId}
          handleDeleteTopic={handleDeleteTopic}
        />
      )}
      {data === 'Presentation' && (
        <UploadPresentation
          onClose={onClose}
          lessonId={lessonId}
          selectedItem={selectedItem}
          moduleId={moduleId}
          handleDeleteTopic={handleDeleteTopic}
        />
      )}
      {data === 'Multimedia' && (
        <UploadMultimedia
          onClose={onClose}
          lessonId={lessonId}
          selectedItem={selectedItem}
          moduleId={moduleId}
          handleDeleteTopic={handleDeleteTopic}
        />
      )}
      {data === 'Live' && (
        <InsertLiveLesson
          onClose={onClose}
          lessonId={lessonId}
          selectedItem={selectedItem}
          moduleId={moduleId}
          handleDeleteTopic={handleDeleteTopic}
        />
      )}
      {data === 'Scorm' && (
        <UploadScorm
          onClose={onClose}
          lessonId={lessonId}
          selectedItem={selectedItem}
          moduleId={moduleId}
          handleDeleteTopic={handleDeleteTopic}
        />
      )}
      {data === 'Quiz' && (
        <InsertQuiz
          onClose={onClose}
          lessonId={lessonId}
          selectedItem={selectedItem}
          moduleId={moduleId}
          handleDeleteTopic={handleDeleteTopic}
        />
      )}
      {data === 'Assignment' && (
        <UploadAssignment
          onClose={onClose}
          lessonId={lessonId}
          selectedItem={selectedItem}
          moduleId={moduleId}
          handleDeleteTopic={handleDeleteTopic}
        />
      )}
      {data === 'Exam' && (
        <UploadExam
          onClose={onClose}
          lessonId={lessonId}
          selectedItem={selectedItem}
          moduleId={moduleId}
          handleDeleteTopic={handleDeleteTopic}
        />
      )}
      {data === 'Survey' && (
        <InsertSurvey
          onClose={onClose}
          lessonId={lessonId}
          selectedItem={selectedItem}
          moduleId={moduleId}
          handleDeleteTopic={handleDeleteTopic}
        />
      )}
      {data === 'Evalute' && (
        <InsertEvaluate
          onClose={onClose}
          lessonId={lessonId}
          selectedItem={selectedItem}
          moduleId={moduleId}
          handleDeleteTopic={handleDeleteTopic}
        />
      )}
      {data === 'Attendify' && (
        <Attendify
          onClose={onClose}
          lessonId={lessonId}
          selectedItem={selectedItem}
          moduleId={moduleId}
          handleDeleteTopic={handleDeleteTopic}
        />
      )}
    </>
  );
};

export default LessonTopics;
