import React from 'react';
import { PageContainer } from '../../styles/Dashboard/DashboardStyles';
import AdminDashboard from './AdminDashboard';
import LearnerDashboard from './LearnerDashboard';
import { connect, useSelector } from 'react-redux';
import { Heading } from '../../styles/Common/CommonStyles';
import InstructorDashboard from './InstructorDashboard';
import { Grid, Typography } from '@mui/material';
import { ProfileHead, ProfilePic } from '../../styles/Landing/NavbarElements';
import StudentDashboard from './StudentDashboard';
import { useTranslation } from 'react-i18next';
import Footer from '../Landing/Footer';

const Dashboard = () => {
  const { t } = useTranslation();
  const { users, loading } = useSelector((state) => state.auth);
  const firstName = users?.user?.userid?.firstname || 'User';
  const lastname = users?.user?.userid?.lastname || 'User';

  return (
    <>
      <PageContainer>
        <Grid
          container
          style={{
            // border: '0px solid black',
            height: '50px',
            borderBottom: '1px solid lightgray',
          }}
        >
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            style={{ border: '0px solid black', paddingLeft: '20px' }}
          >
            <Typography variant="h6">
              {t('dashboard.WelcomeLabel')}, {firstName} {lastname}
            </Typography>
          </Grid>
        </Grid>
        {users?.user?.userid?.role === 'Admin' && <AdminDashboard />}
        {users?.user?.userid?.role === 'Learner' && (
          <LearnerDashboard users={users} />
        )}
        {/* {userProfile?.userRole === 'Instructor' && (
        <>
          <Heading> This is Displayed for Instructor Login Page</Heading>
        </>
      )}
      {userProfile?.userRole === 'Learner' && (
        <>
          <Heading> This is Displayed for Learner Login Page</Heading>
        </>
      )} */}
        {users?.user?.userid?.role === 'Instructor' && <InstructorDashboard />}
      </PageContainer>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userProfile: state.userProfile,
  };
};

export default connect(mapStateToProps)(Dashboard);
