import React, { useState } from 'react';
import Papa from 'papaparse';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Modal from '@mui/material/Modal';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Tooltip } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Text, AddButton } from '../../styles/Users/UsersStyles';
import { useDispatch } from 'react-redux';
import {
  createUserByCsv,
  fetchUser,
} from '../../features/userModule/userModuleSlice';

import { toast } from 'react-toastify';

const VALID_ROLES = [
  'Admin',
  'Learner',
  'Instructor',
  'Course Developer',
  'Course Reviewer',
];

// ProgressModal Component
const ProgressModal = ({ open, progress, completed, onClose }) => (
  <Modal open={open} onClose={completed ? onClose : () => {}}>
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="h6" component="h2">
        Please wait a few moment till the users are added.....
      </Typography>
      <Box sx={{ width: '100%', mt: 2 }}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
      <Typography sx={{ mt: 2 }}>{`${Math.round(progress)}%`}</Typography>
      <Button
        variant="contained"
        onClick={() => {
          toast.success('CSV imported!');
          onClose();
        }}
        disabled={!completed}
        sx={{ mt: 2 }}
      >
        OK
      </Button>
    </Box>
  </Modal>
);

const ExcelTable = ({ onChildValueChange }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const URL = process.env.REACT_APP_URL;
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [fileName, setFileName] = useState('');

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile ? selectedFile.name : '');
  };

  const handleUpload = () => {
    if (file) {
      setOpenDialog(false);
      setIsLoading(true);
      setProgress(0);
      setCompleted(false);

      Papa.parse(file, {
        header: true,
        complete: (result) => {
          console.log('Raw result from Papa.parse:', result);
          const parsedData = result.data.filter((row) =>
            Object.values(row).some((value) => value),
          ); // Filter out empty rows

          console.log('Parsed CSV data:', parsedData);

          if (parsedData.length === 0) {
            toast.warning('The CSV file is empty or contains only headers.');
            setIsLoading(false);
            return;
          }

          const validRecords = [];
          const invalidRecords = [];
          const VALID_ROLES = [
            'Admin',
            'Learner',
            'Instructor',
            'Course Developer',
            'Course Reviewer',
          ];

          parsedData.forEach((row, index) => {
            const { firstname, lastname, email, role } = row;
            if (!(firstname && lastname && email && role)) {
              const missingFields = [];
              if (!firstname) missingFields.push('firstname');
              if (!lastname) missingFields.push('lastname');
              if (!email) missingFields.push('email');
              if (!role) missingFields.push('role');
              invalidRecords.push({
                ...row,
                error: `Missing required fields : ${missingFields.join(', ')}`,
              });
            } else if (!VALID_ROLES.includes(role)) {
              invalidRecords.push({
                ...row,
                error: `Invalid role at row : ${role}`,
              });
            } else {
              validRecords.push(row);
            }
          });

          const allRecords = {
            validRecords,
            invalidRecords,
          };

          dispatch(createUserByCsv(allRecords))
            .unwrap()
            .then(() => {
              setProgress(100);
              setCompleted(true);
              dispatch(fetchUser());
              if (onChildValueChange) {
                onChildValueChange(validRecords);
              }
            })
            .catch((error) => {
              setIsLoading(false);
              toast.error(`Failed to import CSV: ${error.message}`);
            });
        },
        error: (parseError) => {
          console.error('Error parsing CSV:', parseError);
          setIsLoading(false);

          toast.error(
            'Failed to parse CSV. Please check the file format and try again.',
          );
        },
      });
    } else {
      toast.warning('Please select a file to upload.');
    }
  };

  const handleImportFileClick = () => {
    document.getElementById('csv-input').click();
  };
  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const handleDownloadTemplate = () => {
    const headers = ['firstname', 'lastname', 'email', 'role'];

    const csvContent =
      'data:text/csv;charset=utf-8,' + headers.join(',') + '\n';
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'template.csv');
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
      <Tooltip title="Click here to import csv file">
        <AddButton onClick={handleOpenDialog} disabled={isLoading}>
          Import
        </AddButton>
      </Tooltip>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minWidth: '500px',
          }}
        >
          <Text>Generate spreadsheet template for adding data</Text>
          <Button
            variant="contained"
            startIcon={<CloudDownloadIcon />}
            onClick={handleDownloadTemplate}
          >
            Download Template
          </Button>
          <hr style={{ width: '90%', margin: '10px 0' }} />
          <Text>Import spreadsheet template form your device</Text>

          <Button
            variant="contained"
            startIcon={<CloudUploadIcon />}
            onClick={handleImportFileClick}
          >
            Import File
          </Button>
          {fileName && <Typography sx={{ ml: 2 }}>{fileName}</Typography>}
          <input
            className="hidden"
            id="csv-input"
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpload}>Upload</Button>
          <Button onClick={handleCloseDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>
      {/* <NonBlockingLoading open={isLoading} /> */}
      <ProgressModal
        open={isLoading}
        progress={progress}
        completed={completed}
        onClose={() => setIsLoading(false)}
      />
    </>
  );
};

export default ExcelTable;
