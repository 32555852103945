import styled from "styled-components";
import tw from "twin.macro";
import { palette } from "../palette";
import { NavLink as Link } from "react-router-dom";

export const LoginContainerMain = styled.div`
  ${tw`flex justify-center`}
`;

export const LoginContainer = styled.div`
  ${tw`flex justify-start items-center flex-col w-auto p-10 h-full mb-[-40px]`}
 
  top: 120px;
`;

export const LoginImg = styled.div`
  ${tw`w-full lg:mt-10 xl:mt-0  hidden lg:block xl:block 2xl:block`}
`;


export const LoginImgSub = styled.img`
  ${tw`w-full h-[75vh]  aspect-[3/2] object-contain `}
`;

export const Header = styled.h1`
  ${tw`2xl:text-3xl lg:text-xl font-black leading-5 tracking-wide my-10 mt-20`}
`;

export const LoginForm = styled.div`
  ${tw`2xl:text-5xl flex flex-col w-[400px] h-full p-10 mb-20`}
`;

export const Text = styled.p`
  ${tw`2xl:text-base text-sm mb-10 m-auto`}
`;

export const SignUpText = styled(Link)`
  ${tw`text-sm mb-10 ml-2 m-auto`}
  color: ${palette.tertiary};
  text-decoration: none;
  &.active {
    color: ${palette.tertiary};
    text-decoration: underline;
  }
`;

export const TextFieldStyle = {
  "& .MuiInputBase-root ": {
    fontSize: "0.75rem",
    background: `${palette.white}`,
    width: "100%",
    marginBottom: "25px",
  },
};

export const LoginButton = {
  color: palette.white,
  background: palette.tertiary,
  borderRadius: "25px",
  margin: "auto",
  fontWeight: 600,
  width: "15rem",
  height: "2.5rem",
  textAlign: "center",
  marginTop: "1.5rem",
  marginBottom: "1.5rem",
  border: 'none'
};

export const Sepbtn = styled.p`
  ${tw` flex justify-center items-center text-gray-500 text-sm font-bold`}
`;

export const LoginButtonGoogle = {
  borderRadius: "25px",
  margin: "auto",
  fontWeight: 600,
  width: "15rem",
  height: "2.5rem",
  textAlign: "center",
  marginTop: "2rem",
  marginBottom: "1rem",
  border: "1px solid gray",
  color: "black",
};

export const ImgLogin = styled.img`
  ${tw`h-4 w-4 p-2`}
`;

export const SignupContainer = styled.div`
  ${tw`flex flex-row mt-4 mb-2`}
`;
