import styled from "styled-components";
import tw from "twin.macro";
import { palette } from "../../styles/palette";


export const SliderContainer = styled.div`
  ${tw``}
`;

export const SlideWrapper = styled.div`
  ${tw`flex transition-transform ease-in-out`}
  transition: transform 1s ease;
  transform: translateX(-${(props) => props.currentSlide * 100}vw);
`;

export const Slide = styled.div`
  ${tw`flex-shrink-0 w-full bg-cover bg-center`}
  background-color: ${(props) => props.color};
  ${tw`text-white p-20 text-center overflow-hidden`}
`;

export const ContentContainer = styled.div`
  ${tw`flex justify-center items-center`}
`;

export const Image = styled.img`
  ${tw`w-[100%] h-[600px] mr-72`}
`;

export const CarousalTextHead = styled.h1`
  ${tw`text-5xl`}
`;

export const CarousalTextHeadSub = styled.h2`
  ${tw`text-2xl`}
`;

export const CarousalTextDesc = styled.p`
  ${tw`w-[70%] text-lg`}
`;

export const ContentSub = styled.div`
  ${tw`flex justify-start items-start flex-col text-justify ml-40`}
`;






// new header 

export const MainHeadCont = styled.div`
  ${tw`flex justify-around items-center h-[40vh]
    sm:h-[50vh]
  `}
  background:${palette.primary}
`;

export const Title = styled.h1`
  ${tw`text-white text-lg font-medium
  2xl:text-4xl
  xl:text-3xl
  lg:text-2xl
  md:text-lg
  `}
`;

export const Desc = styled.p`
  ${tw`w-[85vw] text-white text-sm sm:leading-6 text-justify
  2xl:text-lg 2xl:leading-8
  xl:text-lg xl:leading-8
  lg:text-base lg:leading-8
  md:text-sm  md:leading-7 md:w-[50vw]
  sm:text-base sm:leading-6
  tracking-wide
  `}
`;


export const MainDesc = styled.div`
  ${tw`mt-[-20px] 
  2xl:mt-24
  lg:mt-5`}
`;

export const HeaderImage = styled.img`
  ${tw`hidden w-[30%] h-auto mt-10
  md:block
  `}
`;
