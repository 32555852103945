import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NotificationTabs from './NotificationTabs';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  Button,
  Tooltip,
  TextField,
  Grid,
  CircularProgress,
} from '@mui/material';
import { palette } from '../../styles/palette';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';
import { WholePage } from '../../styles/Certificate/CertificateStyle';

import {
  ListItem,
  Content,
  Label,
  Subject,
  Page,
  View,
} from '../../styles/Notification/NotificationListStyles';

import {
  fetchNotificationTemplate,
  deleteNotification,
} from '../../features/notification/NotificationSlice';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const NotificationList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [notificationToView, setNotificationToView] = useState(null);
  const [NotificationToDelete, setNotificationToDelete] = useState('notDelete');
  const [searchQuery, setSearchQuery] = useState('');
  const { template, loading } =
    useSelector((state) => state.notification) || {};
  const [highlightedNotificationId, setHighlightedNotificationId] =
    useState(null);

  useEffect(() => {
    dispatch(fetchNotificationTemplate());
  }, [dispatch]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleEdit = (notificationId) => {
    navigate(`/Notification/editnotification/${notificationId}`);
  };

  const handleDeleteDialogOpen = (notification) => {
    setNotificationToDelete(notification);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setNotificationToDelete(null);
  };

  const handleDelete = () => {
    if (!NotificationToDelete) return;
    dispatch(deleteNotification(NotificationToDelete._id))
      .then(() => {
        setDeleteDialogOpen(false);
        setNotificationToDelete('delete');
        toast.success('Deleted Successfully!');
      })
      .catch((error) => {
        console.error('Error deleting Notification:', error);
        setDeleteDialogOpen(false);
        setNotificationToDelete(null);
        toast.error(`Something went wrong. 
Please try again after some time.`);
      });
  };

  const handleOpenModal = (notification) => {
    setNotificationToView(notification);
    setViewDialogOpen(true);
  };

  const handleCloseModal = () => {
    setViewDialogOpen(false);
    setNotificationToView(null);
  };

  const isNewOrUpdated = (notification) => {
    const oneMinuteAgo = new Date(Date.now() - 60 * 1000);
    return (
      new Date(notification.createdAt) > oneMinuteAgo ||
      new Date(notification.updatedAt) > oneMinuteAgo
    );
  };

  const filteredNotifications = template?.filter(
    (notification) =>
      notification &&
      !notification.isDeleted &&
      notification.notificationType
        .toLowerCase()
        .includes(searchQuery.toLowerCase()),
  );

  const sortedNotifications = filteredNotifications?.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
  );

  useEffect(() => {
    const newOrUpdatedNotification = template?.find((notification) =>
      isNewOrUpdated(notification),
    );

    setHighlightedNotificationId(
      newOrUpdatedNotification ? newOrUpdatedNotification._id : null,
    );
    if (newOrUpdatedNotification) {
      const timeout = setTimeout(() => {
        setHighlightedNotificationId(null);
      }, 5000);

      return () => clearTimeout(timeout);
    }
  }, [template]);

  const { t } = useTranslation();

  return (
    <>
      <NotificationTabs />
      <WholePage>
        <Page>
          <TextField
            label={t('notification.searchText')}
            size="small"
            variant="outlined"
            value={searchQuery}
            onChange={handleSearchChange}
            style={{ marginBottom: '15px', marginTop: '15px' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          {loading ? (
            <div style={{ textAlign: 'center', padding: '20px' }}>
              <CircularProgress />
            </div>
          ) : (
            <Grid container spacing={3}>
              {sortedNotifications?.map((notification) => (
                <Grid item xs={12} sm={6} key={notification._id}>
                  <ListItem
                    style={
                      highlightedNotificationId === notification._id
                        ? {
                            boxShadow: `rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,rgba(14, 30, 37, 0.32) 0px 2px 16px 0px`,
                          }
                        : {}
                    }
                  >
                    <Content>
                      <Label>{notification.notificationType}</Label>
                      <Subject>{notification.subject}</Subject>
                    </Content>
                    <Grid container justifyContent="flex-end" spacing={1}>
                      <Grid item>
                        <Tooltip title={t('notification.certificateViewLabel')}>
                          <IconButton
                            onClick={() => handleOpenModal(notification)}
                          >
                            <VisibilityIcon
                              style={{ color: palette.primary }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <Tooltip title={t('notification.edit')}>
                          <IconButton
                            onClick={() => handleEdit(notification._id)}
                          >
                            <EditIcon style={{ color: palette.primary }} />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <Tooltip title={t('notification.deleteLabel')}>
                          <IconButton
                            onClick={() => handleDeleteDialogOpen(notification)}
                          >
                            <DeleteIcon style={{ color: palette.primary }} />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </ListItem>
                </Grid>
              ))}
            </Grid>
          )}
        </Page>
      </WholePage>
      <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
        <DialogTitle>{t('notification.confirmDeleteLabel')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('notification.confirmQueText')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose}>
            {t('notification.cancelLabel')}
          </Button>
          <Button onClick={handleDelete} color="error">
            {t('notification.deleteLabel')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={viewDialogOpen}
        onClose={handleCloseModal}
        sx={{ '& .MuiDialog-paper': { width: '800px', height: '800px' } }}
      >
        <DialogContent>
          {notificationToView && (
            <>
              <View>
                <h2>{notificationToView.notificationType}</h2>
              </View>
              <br />
              <View>
                <strong>Subject:</strong> {notificationToView.subject}
              </View>
              <br />
              <View>
                <div
                  dangerouslySetInnerHTML={{
                    __html: notificationToView.notificationBody,
                  }}
                />
              </View>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>
            {t('notification.cancelLabel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NotificationList;
