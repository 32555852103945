import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { StyledHeading } from '../../styles/Common/CommonStyles';

const Modal = ({ isOpen, onClose, children, modalWidth, headingText }) => {
  if (!isOpen) return null;

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        background: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 100,
      }}
    >
      <div
        style={{
          position: 'relative',
          background: 'white',
          width: modalWidth ? modalWidth : '50vw',
          maxHeight: '80vh', // Set a max height to prevent it from becoming too large
          margin: 'auto',
          padding: '2%',
          border: '2px solid #000',
          borderRadius: '10px',
          boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.2)',
          overflowY: 'auto', // Ensure scrolling if content overflows
          scrollPadding: '12px',
          overscrollBehavior: 'contain',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          {headingText ? <StyledHeading>{headingText}</StyledHeading> : <></>}
          <CloseIcon
            onClick={() => {
              onClose();
            }}
            style={{ cursor: 'pointer' }}
          />
        </div>

        {children}
      </div>
    </div>
  );
};

export default Modal;
