import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  loginUserApi,
  logInUserWithEmilApi,
  logoutApi,
} from '../../api/AuthenticationApi';
import { updateUserApi } from '../../api/MyProfileApi';

export const logInUser = createAsyncThunk(
  'logInUser',
  async (data, { rejectWithValue }) => {
    const result = await loginUserApi(data);
    console.log('new user xyzdsf : ', result);
    console.log('hii from redux');
    return result.data;
  },
);

export const logInUserWithEmil = createAsyncThunk(
  'logInUserWithEmil',
  async (data, { rejectWithValue }) => {
    console.log(' checking logInUserWithEmil slice');
    try {
      console.log('logInUserWithEmil slice');

      const result = await logInUserWithEmilApi(data);
      console.log('checking from slice', result);

      return result;
    } catch (error) {
      console.log('redux errpor', error);
      return rejectWithValue(error);
    }
  },
);

export const logOutApi = createAsyncThunk(
  'auth/logout',
  async (_, { getState, rejectWithValue }) => {
    const { auth } = getState(); // Correctly access the auth slice
    const token = auth.users.user.token; // Adjust the path if needed
    const email = auth.users.user.email; // Get the email from the Redux state

    try {
      const response = await logoutApi(token, email);
      return response.data;
    } catch (error) {
      console.log('Error in logOutApi thunk:', error);

      // Check if error.response exists
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          message: 'Something went wrong. Please try again later.',
        });
      }
    }
  },
);
//FIXME:
//update user
export const updateUser = createAsyncThunk(
  'updateUser',
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateUserApi(data);
      console.log(' checking upadte in sli8ce', response.user);
      return response.user;
    } catch (error) {
      console.log('Error in updateUser thunk:', error);
      return rejectWithValue(error.response.data);
    }
  },
);

export const Authentication = createSlice({
  name: 'auth',
  initialState: {
    users: {
      token: null,
      email: null,
    },
    loading: false,
    error: null,
    searchData: [],
  },
  reducers: {},

  extraReducers: (builder) => {
    builder
      // Authentication reducers
      .addCase(logInUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(logInUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(logInUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(logInUserWithEmil.pending, (state) => {
        state.loading = true;
      })
      .addCase(logInUserWithEmil.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(logInUserWithEmil.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(logOutApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(logOutApi.fulfilled, (state, action) => {
        state.loading = false;
        state.users = {};
      })
      .addCase(logOutApi.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
        // state.error = action.payload ? action.payload.message : 'Logout failed';
      })
      // User profile update reducer
      .addCase(updateUser.pending, (state) => {
        state.loading = false;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.loading = false;
        console.log('checking reducer', action.payload);
        state.users = {
          ...state.users, // Spread existing user data
          ...action.payload, // Update specific fields from payload
        };
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default Authentication.reducer;

//   export const { searchUser } = userDetail.actions;
