import React, { useState } from 'react';
import { Typography, TextField, Grid, MenuItem } from '@mui/material';
import axios from 'axios';
import { AddAnnoucementCont } from '../../styles/Calendar/CalendarStyle';
import { Head } from '../../styles/Calendar/CalendarStyle';
import BackButton from '../Utilities/BackButton';
import { useDispatch } from 'react-redux';
import { addAnnouncementdetails } from '../../features/calendar/CalendarSlice';
import { HeadingH2 } from '../../styles/Common/CommonStyles';
import CommonButton from '../Utilities/CommonButton';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { toast } from 'react-toastify';
import { Button } from '@mui/material';
const AddAnnouncements = () => {
  const URL = process.env.REACT_APP_URL;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [fieldErrors, setFieldErrors] = useState({});
  const [imageUrl, setImageUrl] = useState(null);

  const [formData, setFormData] = useState({
    announcementNo: '',
    eventName: '',
    eventDate: '',
    eventEndDate: '',
    type: '',
    image: '',
    startTime: null,
    endTime: null,
  });
  const requiredFields = [
    'announcementNo',
    'eventName',
    'eventDate',
    'eventEndDate',
    'type',
    'image',
    'startTime',
    'endTime',
  ];
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const [file, setFile] = useState(null);
  const [uploadUrl, setUploadUrl] = useState('');
  const [encoadUrl, setencodingUrl] = useState('');

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      console.log('Selected file:', selectedFile);
      setFile(selectedFile);

      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);

      await requestUploadUrl(selectedFile);
      // Update formData.image with the file name
      setFormData((prevFormData) => ({
        ...prevFormData,
        image: selectedFile.name,
      }));
    } else {
      console.error('No file selected');
    }
  };

  const requestUploadUrl = async (file) => {
    try {
      const response = await axios.post(
        `${URL}/api/calendar/addAnnouncementImage`,
        {
          fileName: file.name,
          fileType: file.type,
        },
      );
      console.log(response.data.url);
      setUploadUrl(response.data.url);
    } catch (error) {
      console.error('Error requesting upload URL:', error);
    }
  };

  const uploadToS3 = async () => {
    try {
      const response = await axios.put(uploadUrl, file, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': file.type,
          'Content-Type': 'multipart/form-data',
        },
      });

      const encodedUrl = encodeURIComponent(uploadUrl?.split('?')[0]);
      setencodingUrl(encodedUrl);
      // Update formData.image with the uploaded file URL
      setFormData((prevFormData) => ({
        ...prevFormData,
        image: encodedUrl,
      }));
      console.log('File uploaded successfully', response);
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  };

  const validateRequiredFields = (data) => {
    const errors = {};
    const requiredFields = [
      'announcementNo',
      'eventName',
      'eventDate',
      'eventEndDate',
      'type',
      'image',
      'startTime',
      'endTime',
    ];
    requiredFields.forEach((field) => {
      if (!data[field]) {
        errors[field] = `The ${field} field is required.`;
      }
    });
    // Validate event dates
    if (
      data.eventDate &&
      data.eventEndDate &&
      data.eventEndDate < data.eventDate
    ) {
      errors.eventEndDate =
        'Event end date cannot be earlier than event start date.';
    }
    // Validate event times
    if (data.startTime && data.endTime) {
      if (data.endTime <= data.startTime) {
        errors.endTime = 'End time cannot be less than or equal to start time.';
      }
    }
    return errors;
  };
  const handleTimeChange = (time, field) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: time,
    }));
    if (field === 'startTime' && formData.endTime && time >= formData.endTime) {
      setFieldErrors((prev) => ({
        ...prev,
        startTime: 'Start time cannot be greater than or equal to end time.',
      }));
    } else if (
      field === 'endTime' &&
      formData.startTime &&
      time <= formData.startTime
    ) {
      setFieldErrors((prev) => ({
        ...prev,
        endTime: 'End time cannot be less than or equal to start time.',
      }));
    } else {
      setFieldErrors((prev) => ({
        ...prev,
        [field]: '',
      }));
    }
  };

  const today = new Date().toISOString().split('T')[0];

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (!value.trim()) {
      setFieldErrors((prev) => ({
        ...prev,
        [name]: `The ${name} field is required.`,
      }));
    } else {
      setFieldErrors((prev) => ({
        ...prev,
        [name]: '',
      }));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('handleSubmit called');

    const errors = validateRequiredFields(formData) || {};
    console.log('Errors:', errors);
    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      const firstEmptyField = document.querySelector(
        `[name="${Object.keys(errors)[0]}"]`,
      );
      if (firstEmptyField) {
        firstEmptyField.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      Object.keys(errors).forEach((key) => {
        toast.error(`${key}: ${errors[key]}`);
      });

      return;
    }
    if (
      formData.eventDate &&
      formData.eventEndDate &&
      formData.eventEndDate < formData.eventDate
    ) {
      toast.error('Event end date cannot be earlier than event start date.');
      return;
    }
    if (
      formData.startTime &&
      formData.endTime &&
      formData.endTime <= formData.startTime
    ) {
      toast.error('End time cannot be less than or equal to start time.');
      return;
    }
    try {
      setLoading(true);
      console.log('Form data before submission:', formData);

      // Upload image if selected
      if (file) {
        await uploadToS3();
        console.log('Image uploaded:', uploadUrl);
      }
      const announcementData = {
        ...formData,
        image: uploadUrl?.split('?')[0],
      };
      console.log('Announcement data:', announcementData);

      const result = await dispatch(
        addAnnouncementdetails(announcementData),
      ).unwrap();
      console.log('API response:', result);

      setLoading(false);
      setOpenSnackbar(true);
      setSnackbarMessage('Announcement added successfully!');
      // Clear form fields after successful submission
      setFormData({
        announcementNo: '',
        eventName: '',
        eventDate: '',
        eventEndDate: '',
        type: '',
        image: '',
        startTime: null,
        endTime: null,
      });
      navigate(`/EventsNavigator/Announcements`);
    } catch (error) {
      setLoading(false);
      toast.error('Failed to add announcement. Please try again.');
    }
  };

  return (
    <>
      <AddAnnoucementCont>
        <Head>
          <BackButton />
          <HeadingH2>{t('calender.announcementLabel')}</HeadingH2>
        </Head>
        <Grid item xs={12}>
          <Typography sx={{ marginBottom: '-10px' }}>
            Announcement No.
          </Typography>
          <TextField
            name="announcementNo"
            placeholder={t('calender.announcementNumb')}
            value={formData.announcementNo}
            onChange={handleInputChange}
            fullWidth
            required
            onBlur={handleBlur}
            margin="normal"
            error={Boolean(fieldErrors.announcementNo)}
            helperText={fieldErrors.announcementNo}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ marginBottom: '-10px' }}>Event Name</Typography>
          <TextField
            name="eventName"
            placeholder={t('calender.eventNameLabel')}
            value={formData.eventName}
            onChange={handleInputChange}
            fullWidth
            required
            onBlur={handleBlur}
            margin="normal"
            error={Boolean(fieldErrors.eventName)}
            helperText={fieldErrors.eventName}
          />
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Typography sx={{ marginBottom: '-5px' }}>Start Date</Typography>
            <TextField
              name="eventDate"
              placeholder={t('calender.startdateLabel')}
              type="date"
              value={formData.eventDate}
              onChange={handleInputChange}
              fullWidth
              required
              onBlur={handleBlur}
              error={Boolean(fieldErrors.eventDate)}
              helperText={fieldErrors.eventDate}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                inputMode: 'numeric',
                min: today,
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ marginBottom: '-5px' }}>End Date</Typography>
            <TextField
              name="eventEndDate"
              type="date"
              value={formData.eventEndDate}
              onChange={handleInputChange}
              fullWidth
              required
              onBlur={handleBlur}
              error={Boolean(fieldErrors.eventEndDate)}
              helperText={fieldErrors.eventEndDate}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={3} sx={{ marginTop: '17px' }}>
            <Typography sx={{ marginTop: '-15px', marginBottom: '9px' }}>
              Start Time
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                value={formData.startTime}
                onChange={(time) => handleTimeChange(time, 'startTime')}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="startTime"
                    variant="outlined"
                    fullWidth
                    onBlur={handleBlur}
                    required
                    error={Boolean(fieldErrors.startTime)}
                    helperText={fieldErrors.startTime}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={3} sx={{ marginTop: '17px' }}>
            <Typography sx={{ marginTop: '-15px', marginBottom: '9px' }}>
              End Time
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                value={formData.endTime}
                onChange={(time) => handleTimeChange(time, 'endTime')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="endTime"
                    variant="outlined"
                    fullWidth
                    required
                    onBlur={handleBlur}
                    error={Boolean(fieldErrors.endTime)}
                    helperText={fieldErrors.endTime}
                    margin="normal"
                  />
                )}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography sx={{ marginBottom: '-10px' }}>Event Type</Typography>
          <TextField
            name="type"
            select
            placeholder={t('calender.eventTypeLabel')}
            value={formData.type}
            onChange={handleInputChange}
            fullWidth
            onBlur={handleBlur}
            displayEmpty
            required
            margin="normal"
            error={Boolean(fieldErrors.CourseType)}
            helperText={fieldErrors.CourseType}
          >
            <MenuItem value="none" disabled>
              Select Event Type
            </MenuItem>
            <MenuItem value="Workshop">Workshop</MenuItem>
            <MenuItem value="Seminar">Seminar</MenuItem>
            <MenuItem value="Lecture">Lecture</MenuItem>
            <MenuItem value="Hands-on Lab">Hands-on Lab</MenuItem>
            <MenuItem value="Webinar">Webinar</MenuItem>
            <MenuItem value="Bootcamp">Bootcamp</MenuItem>
            <MenuItem value="Training Program">Training Program</MenuItem>
          </TextField>
        </Grid>
        <Typography variant="body2" color="#2563EB" gutterBottom>
          <Typography variant="body2" color="#2563EB" gutterBottom>
            {t('calender.imagedescriptionLabel1')}
          </Typography>
          {t('calender.imagedescriptionLabel2')}
        </Typography>
        <Grid
          container
          sx={{
            border: '1px solid #c7c7c7',
            padding: '20px',
            borderRadius: '3px',
          }}
        >
          <Grid
            container
            spacing={2}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}
          >
            <Grid item>
              <input
                style={{ display: 'none' }}
                id="file-input"
                type="file"
                name="image"
                accept="image/*"
                onChange={handleFileChange}
              />
              <label htmlFor="file-input">
                <Button variant="contained" component="span">
                  Choose a file
                </Button>
              </label>
            </Grid>
            {imageUrl && (
              <Grid item>
                <div className="h-20 w-20">
                  <img
                    src={imageUrl}
                    alt="Selected"
                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                  />
                </div>
              </Grid>
            )}
          </Grid>
          <Grid item></Grid>
        </Grid>
        <br />
        <Grid>
          <Grid>
            <CommonButton
              label={
                loading ? t('calender.addingLabel') : t('calender.addLabel')
              }
              onClick={handleSubmit}
              disabled={loading}
            ></CommonButton>
          </Grid>
        </Grid>
      </AddAnnoucementCont>
    </>
  );
};

export default AddAnnouncements;
