
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import ProgressModal from '../../Utilities/ProgressModal';
import {
  TextField,
  Tooltip,
  IconButton,
  Grid,
  Typography,
  InputAdornment,
  Button,
  Box
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { palette } from '../../../styles/palette';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch } from 'react-redux';
import { addItem } from '../../../features/courses/addCourseDetailsSlice';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import '../../Utilities/Sample.css';
import { styled } from '@mui/system';
import {EditorContainer, DeleteBox,StyledTextField,ButtonContainer,BackButtonContainer,HeaderContainer} from '../../../styles/Courses/CourseModuleStyles';

const DiscardButton = styled(Button)`
  color: ${palette.primary};
  border-color:  ${palette.gray};
  margin-right: 10px;
  width: 180px;
`;

const SaveButton = styled(Button)`
  background-color: ${palette.primary};
  color: ${palette.white};
`;


const UploadPresentation = ({
  onClose,
  selectedItem,
  lessonId,
  moduleId,
  handleDeleteTopic
}) => {
  const[saved ,setSaved] = useState(true);
  const handleOnClose = () => {
    const data = { ...formData, preview: false}
    console.log("abc data from go back",data)
    if(saved && (formData.uploadUrl  || formData.link || formData.fileName)){
      onClose();

    }
    else{
      dispatch(addItem(data));
      onClose()
    }

   

  };

  const { id, courseStructure } = useParams();
  const dispatch = useDispatch();
  const [videoShow, setVideoShow] = useState('');

  const [formData, setFormData] = useState({
    selectedValue: 'upload',
    link: selectedItem?.link || '',
    uploadUrl: '',
    moduleId: selectedItem?.moduleId || lessonId,
    lessonId: selectedItem?.lessonId || moduleId,
    title: selectedItem?.title || '',
    textDescription: selectedItem?.textDescription || '',
    id: selectedItem?.id || '',
    courseStructure: selectedItem?.courseStructure || '',
    contentType: selectedItem?.contentType || '',
    fileName: selectedItem?.fileName || "" ,
    preview:selectedItem?.preview||false,
 

  });
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [completed, setCompleted] = useState(false);

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      lessonId: lessonId,
      moduleId: moduleId,
      id: selectedItem?.id ||generateRandomNumber(),
      contentType: 'Presentation',
      courseStructure: courseStructure,
      link: formData?.uploadUrl?.split('?')[0],
    }));
    console.log(formData);
  };

  const generateRandomNumber = () => {
    const min = 100000; // Minimum 6-digit number
    const max = 999999; // Maximum 6-digit number
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile.name);
    // const localUrl = URL.createObjectURL(selectedFile);
    setFormData((prevData) => ({
      ...prevData,
      fileName: selectedFile?.name
    }));
    
    // dispatch(setPdf({ pdfUrl: localUrl, title: formData.title }));

    await requestUploadUrl(selectedFile);
  };

  const requestUploadUrl = async (file) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/course/uploadVideo`,
        {
          fileName: file.name,
          fileType: file.type,
        },
      );
      setFormData((prevData) => ({
        ...prevData,
        link: response.data.url?.split('?')[0],
        uploadUrl: response.data.url,
      }));
    } catch (error) {
      console.error('Error requesting upload URL:', error);
    }
  };

  const uploadToS3 = async () => {
    
  setLoading(true);
  setProgress(0);
  setCompleted(false);


    try {
     
await axios.put(formData.uploadUrl, file, {
  headers: {
    "Content-Type": file?.type,
  },
  onUploadProgress: (progressEvent) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    setProgress(percentCompleted);
  },
});

setCompleted(true);
setLoading(false);


    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };
  const handleDelete =()=>{
    handleDeleteTopic(selectedItem?.moduleId , selectedItem?.lessonId , selectedItem?.id)
  }

  const handleFileRemove = () => {
    setFile(null);
    setFileName('');
    setFormData((prevData) => ({
      ...prevData,
      uploadUrl: '',
    }));
  };

  const handleUpload = async () => {
    if (file) {
      await uploadToS3();
      toast.success('File Upload successfully!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        // transition: Bounce,
        });

      setVideoShow(true);
    }
  };

  const handleSubmit = async () => {
    if(formData.uploadUrl || formData.link|| formData.fileName)
      {
        
        
        const data = {...formData, preview:true}
        console.log('abc from  the submit',data);
        
        await dispatch(addItem(data));
      } else{
        
        const data = { ...formData, preview: false}
        console.log("abc data from the save for the draft",data)
    await dispatch(addItem(data));
      } 
    
      setSaved(true);
      onClose();
      };


  console.log("Presentation",formData.link.replace('blob:',''));
  console.log(`https://view.officeapps.live.com/op/embed.aspx?src=${formData.link.replace('blob:','')}`);

  return (
    <div>
      <HeaderContainer
      >
        {/* Grid container aligned to the left */}
        <BackButtonContainer container alignItems="center" spacing={1} sx={{ mt: 1, mb: 2 }}>
          <Grid item>
            <Tooltip title="Go Back">
              <IconButton
                size="small"
                onClick={handleOnClose}
                sx={{ color: `${palette.THEME_THREE}` }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item sx={{ mt: 1 }}>
            <Typography variant="h5" gutterBottom>
              Presentation
            </Typography>
          </Grid>
        </BackButtonContainer>

        {/* Buttons div aligned to the right */}
        <ButtonContainer style={{ display: 'flex', alignItems: 'center' }}>
        <DiscardButton variant="outlined">Discard Changes</DiscardButton>
{     formData.title?     <SaveButton variant="contained" onClick={handleSubmit}>Save</SaveButton>
:<Button disabled>Save</Button>}        </ButtonContainer>
      </HeaderContainer>

      <EditorContainer>
        <StyledTextField
          variant="outlined"
          label="Title"
          style={{ width: '100%', marginBottom: '10px' }}
          name="title"
          value={formData.title}
          onChange={handleInputChange}
        />
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sx={{mt:1}}>
            <TextField
              variant="outlined"
              label="Upload PPT File"
              value={fileName}
              helperText="Max ppt size 2GB"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end"></InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {fileName && (
                      <IconButton
                        onClick={handleFileRemove}
                        style={{ color: 'red' }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                    {!fileName && (
                      <Button
                        variant="contained"
                        component="label"
                        style={{ borderRadius: 4, backgroundColor:` ${palette.primary}`, color:`${palette.white}` }}
                      >
                        Choose File
                        <input
                          type="file"
                          style={{ display: 'none' }}
                          onChange={handleFileChange}
                        />
                      </Button>
                    )}
                  </InputAdornment>
                ),
              }}
              style={{ width: '100%', marginBottom: '10px' }}
            />
          </Grid>
          <Grid
            item
            xs={2}
            style={{ display: 'flex', alignItems: 'center', padding: '4px' }}
          >
            {fileName && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpload}
                fullWidth
              >
                Upload
              </Button>
            )}
          </Grid>

          

  <ProgressModal
        open={loading}
        progress={progress}
        completed={completed}
        onClose={() => setLoading(false)}
      />
        </Grid>
{(videoShow) && (
    <div style={{ marginTop: '20px' }}>
    <Typography variant="h6">Uploaded ppt</Typography>
    <iframe
     src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(formData.link)}`}
      width="100%"
      height="600px"
      style={{ border: 'none' }}
    />
  </div>
  )}

  {(selectedItem?.fileName) && (
     <div style={{ marginTop: '20px' }}>
     <Typography variant="h6">Uploaded PDF</Typography>
     <iframe
      //  src={selectedItem.link}
     src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(selectedItem.link)}`}

       width="100%"
       height="600px"
       style={{ border: 'none' }}
     />
   </div>
  )}
       </EditorContainer>

       <DeleteBox>
      <h4 style={{ marginRight: '10px' }}>
      Are you sure you want to delete this topic? This action is irreversible.
      </h4><br/>
      <Button
        variant="contained"
        color="error"
        onClick={handleDelete}     
         >
        Delete Topic
      </Button>
         </DeleteBox>
    </div>
  );
};

export default UploadPresentation;
