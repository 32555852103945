import axios from 'axios';
import { postData } from './CustomApiCalling';
const URL = process.env.REACT_APP_URL;

export const fetchCertificateTemplateApi = async () => {
  try {
    return await axios.get(`${URL}/api/certificate/fetchTemplate`);
  } catch (err) {
    throw err;
  }
};

export const deleteCertificateTemplateApi = async (templateId) => {
  try {
    return await axios.put(
      `${URL}/api/certificate/deleteTemplate/${templateId}`,
    );
  } catch (err) {
    throw err;
  }
};

export const deleteCertificateApi = async (certificateId) => {
  try {
    return await axios.put(
      `${URL}/api/certificate/deleteCertificate/${certificateId}`,
    );
  } catch (err) {
    throw err;
  }
};

export const createCertificateTemplateApi = async (templateData) => {
  try {
    // const customHeaders = {};
    // return await postData(
    //   '/api/certificate/addTemplate',
    //   templateData,
    //   customHeaders,
    // );
    return await axios.post(`${URL}/api/certificate/addTemplate`, templateData);
  } catch (err) {
    throw err;
  }
};

export const createCertificateApi = async (templateData) => {
  try {
    return await axios.post(`${URL}/api/certificate/useTemplate`, templateData);
  } catch (err) {
    throw err;
  }
};

export const createCertificateSettingsApi = async (templateData) => {
  try {
    return await axios.post(
      `${URL}/api/certificate/certificateSettings`,
      templateData,
    );
  } catch (err) {
    throw err;
  }
};

export const updateCertificateSettingsApi = async (templateData) => {
  try {
    return await axios.put(
      `${URL}/api/certificate/updateCertificateSetting/${templateData.settingId}`,
      templateData,
    );
  } catch (err) {
    throw err;
  }
};

export const blacklistApi = async (email, data) => {
  try {
    const response = await axios.post(`${URL}/api/certificate/blacklist`, data);
    return response;
  } catch (err) {
    console.log('Error in API call:', err); // Log any error that occurs
    throw err;
  }
};

export const issueSingleCertificateApi = async (data) => {
  try {
    return await axios.post(`${URL}/api/certificate/singleIssue`, data);
  } catch (err) {
    throw err;
  }
};

export const issueBulkCertificateApi = async (data) => {
  try {
    return await axios.post(`${URL}/api/certificate/bulkIssue`, data);
  } catch (err) {
    throw err;
  }
};

export const fetchAllCertificates = async () => {
  try {
    return await axios.get(`${URL}/api/certificate/fetchCertificates`);
  } catch (err) {
    throw err;
  }
};

export const fetchIssueCertificate = async () => {
  try {
    return await axios.get(`${URL}/api/certificate/fetchIssueCertificate`);
  } catch (err) {
    throw err;
  }
};

export const fetchBlacklistedCertificateApi = async () => {
  try {
    return await axios.get(`${URL}/api/certificate/GetBlacklistedCertificate`);
  } catch (err) {
    throw err;
  }
};

export const fetchCertificateSettings = async () => {
  try {
    return await axios.get(`${URL}/api/certificate/fetchSetting`);
  } catch (err) {
    throw err;
  }
};

export const editCertificateApi = async (certificateId, updatedData) => {
  try {
    return await axios.put(
      `${URL}/api/certificate/editCertificate/${certificateId}`,
      updatedData,
    );
  } catch (err) {
    throw err;
  }
};

export const fetchSingleSettingsApi = async (settingId) => {
  try {
    return await axios.get(
      `${URL}/api/certificate/fetchSingleSetting/${settingId}`,
    );
  } catch (err) {
    throw err;
  }
};

export const fetchSingleCertificateApi = async (certificateId) => {
  try {
    return await axios.get(
      `${URL}/api/certificate/fetchSingleCertificate/${certificateId}`,
    );
  } catch (err) {
    throw err;
  }
};

export const fetchEventApi = async () => {
  try {
    const response = await axios.get(`${URL}/api/eventManager/getEvents`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteSettingsApi = async (settingId) => {
  try {
    return await axios.put(
      `${URL}/api/certificate/DeleteEventSetting/${settingId}`,
    );
  } catch (err) {
    throw err;
  }
};
export const updateBlacklistedUserApi = async (email, updatedData) => {
  try {
    console.log(
      'API Request URL:',
      `${URL}/api/certificate/updateBlacklist/${email}`,
    );
    console.log('Data being sent to API:', updatedData);

    const response = await axios.put(
      `${URL}/api/certificate/updateBlacklist/${email}`,
      updatedData,
    );

    console.log('API Response:', response);
    return response;
  } catch (err) {
    console.log('Error in API call:', err);
    throw err;
  }
};

export const deleteBlacklistedUserApi = async (email) => {
  try {
    return await axios.put(`${URL}/api/certificate/deleteBlacklist/${email}`);
  } catch (err) {
    throw err;
  }
};
export const fetchIssuedCertificateUserApi = async (email) => {
  try {
    console.log(email, 'checking in Api');

    const response = await axios.get(`${URL}/api/certificate/issued/${email}`);
    console.log('checking API Response:', response); // Log the API response
    return response;
  } catch (err) {
    console.log('API Error:', err); // Log the error if any occurs
    throw err;
  }
};
// downloadCertificateApi;
export const downloadCertificateApi = async (email, serialNumber) => {
  const response = await fetch(`${URL}/api/certificate/downloadCertificate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, serialNumber }),
  });

  // Log the response to check the format and headers
  console.log('API Response:', response);

  return response; // This should return a response with binary data (e.g., image file)
};

export const fetchEligibleCertificateUserApi = async (email) => {
  try {
    console.log(email, 'checking in Api');

    const response = await axios.get(
      `${URL}/api/certificate/eligible/${email}`,
    );
    console.log('checking API Response:', response); // Log the API response
    return response;
  } catch (err) {
    console.log('API Error:', err); // Log the error if any occurs
    throw err;
  }
};
