import React, { useState, useEffect } from 'react';
import { createCourseInSlice } from '../../../features/courses/addCourseDetailsSlice';
import axios from 'axios';

import {
  Container,
  Grid,
  TextField,
  Typography,
  Switch,
  IconButton,
  Tooltip,
  MenuItem,
  FormControl,
  Select,
  Autocomplete,
  CircularProgress,
  Modal,
  Button,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  CreateContent,
  ContainerStyle,
  ButtonCont,
} from '../../../styles/Courses/CourseBuilderStyles';
import { useNavigate, useLocation } from 'react-router-dom';
import { PageContainer } from '../../../styles/Common/CommonStyles';
import CommonButton from '../../Utilities/CommonButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { palette } from '../../../styles/palette';
import JoditReact from 'jodit-react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import dayjs from 'dayjs';

import { useDispatch } from 'react-redux';

import { ScormUploader } from './ScromImport';
import { Box } from '@mui/system';
import { toast } from 'react-toastify';

const CreateCourse = ({ isImport, scormUrl, setScormUrl }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  // const [courseDets, setCourseDets] = useState(location.state);
  const [openModal, setOpenModal] = useState(false);
  const [courseDets, setCourseDets] = useState(
    location.state ?? {
      generalInformation: {
        title: '',
        description: '',
        courseCode: '',
        courseDuration: '',
        courseLevel: '',
        courseCategory: '',
        prerequisites: '',
        language: '',
        instructorName: [],
        bannerImage: '',
        courseStructure: '',
        scormUrl: '',
        isPublished: false,
        eventName: {},
      },
      isPaidCourse: false,
      pricingDetails: {
        actualAmount: 0,
        discount: 0,
        discountedAmount: 0,
        taxes: 0,
        totalAmount: 0,
        coupons: [
          {
            couponString: '',
            discount: 0,
            startDate: '',
            expiryDate: '',
          },
        ],
      },
      contentManagement: {
        learningObjectives: '',
        estimatedTimeToComplete: '',
      },
      accessAndEnrollment: {
        enrollmentType: '',
        startDate: '',
        endDate: '',
        startTime: dayjs() || null,
        endTime: dayjs() || null,
        maximumEnrollment: 0,
        accessPermissions: '',
        certificateAvailability: false,
        certificateRequirements: '',
      },
      progressTracking: {
        progressIndicators: false,
        enforceSequentialLearningPath: false,
        assessmentMethods: false,
        gradingPolicy: false,
        completionCriteria: false,
      },
    },
  );

  const [instructors, setInstructors] = useState([]);
  const [events, setEvents] = useState([]);

  const [loading, setLoading] = useState(false);
  const [selectedInstructors, setSelectedInstructors] = useState([]);
  const [open, setOpen] = useState(false);
  const [openFetchEvent, setOpenFetchEvent] = useState(false);
  const [pricingModalOpen, setPricingModalOpen] = useState(false);
  const [coupons, setCoupons] = useState(
    courseDets.pricingDetails.coupons || [],
  );

  const openPricingModal = () => {
    setPricingModalOpen(true);
  };

  const closePricingModal = () => {
    setPricingModalOpen(false);
  };

  const handleFetchInstructors = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/getInstructors`,
      ); // Adjust the endpoint as needed

      setInstructors(response.data.data);
    } catch (error) {
      console.error('Error fetching instructors:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFetchEvent = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/eventManager/getEventsName`,
      ); // Adjust the endpoint as needed
      console.log(response?.data);
      setEvents(response?.data);
    } catch (error) {
      console.error('Error fetching instructors:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
    if (instructors.length === 0) {
      handleFetchInstructors();
    }
  };

  const handleOpenFetchEvent = () => {
    setOpenFetchEvent(true);
    if (events.length === 0) {
      handleFetchEvent();
    }
  };
  const handleCouponChange = (index, event) => {
    const { name, value } = event.target;
    const updatedCoupons = [...courseDets.pricingDetails.coupons];
    updatedCoupons[index] = {
      ...updatedCoupons[index],
      [name]: value,
    };
    setCourseDets({
      ...courseDets,
      pricingDetails: {
        ...courseDets.pricingDetails,
        coupons: updatedCoupons,
      },
    });
  };
  const addCoupon = () => {
    setCourseDets({
      ...courseDets,
      pricingDetails: {
        ...courseDets.pricingDetails,
        coupons: [
          ...courseDets.pricingDetails.coupons,
          { couponString: '', discount: 0, startDate: '', expiryDate: '' },
        ],
      },
    });
  };
  const removeCoupon = (index) => {
    const updatedCoupons = courseDets.pricingDetails.coupons.filter(
      (_, i) => i !== index,
    );
    setCourseDets({
      ...courseDets,
      pricingDetails: {
        ...courseDets.pricingDetails,
        coupons: updatedCoupons,
      },
    });
  };

  const handleCloseFetchEvent = () => {
    setOpenFetchEvent(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInstructorChange = (event, value) => {
    // Ensure no duplicate instructors by mapping and filtering
    const uniqueInstructors = value.filter(
      (instructor, index, self) =>
        index === self.findIndex((i) => i._id === instructor._id),
    );

    setCourseDets((prevCourseDets) => ({
      ...prevCourseDets,
      generalInformation: {
        ...prevCourseDets.generalInformation,
        // Set unique instructorName based on value, allowing removal and preventing duplicates
        instructorName: uniqueInstructors.map((instructor) => ({
          id: instructor._id,
          firstname: instructor.firstname,
          lastname: instructor.lastname,
        })),
      },
    }));
  };

  const handleEventChange = (event, value) => {
    console.log(value, 'handleEventChange');
    setCourseDets((prevCourseDets) => ({
      ...prevCourseDets,
      generalInformation: {
        ...prevCourseDets.generalInformation,
        eventName: {
          _id: value[0]._id,
          eventName: value[0].EventName,
        },
      },
    }));
  };

  const [checked, setChecked] = useState(courseDets.paid);
  const [productPrice, setProductPrice] = useState(courseDets.productPrice);
  const [discount, setDiscount] = useState(courseDets.discount);
  const [finalPrice, setFinalPrice] = useState(courseDets.finalPrice);
  // const [image, setImage] = useState(null);
  const [isPaidCourse, setIsPaidCourse] = useState(false);
  const [actualAmount, setActualAmount] = useState('');
  const [discountAmount, setDiscountAmount] = useState('');
  const [discountedAmount, setDiscountedAmount] = useState('');
  const [taxes, setTaxes] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [link, setLink] = useState('');
  const [fileData, setFileData] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [errors, setErrors] = useState({
    startDateError: '',
    endDateError: '',
    startTimeError: '',
    endTimeError: '',
    title: '',
    description: '',
    courseCode: '',
    courseDuration: '',
    courseLevel: '',
    courseCategory: '',
    language: '',
    instructorName: '',
    actualAmount: '',
    taxes: '',
    enrollmentType: '',
    courseStructure: '',
    maxEnrollment: '',
    // startDate: '',
    // endDate: '',
    // startTime: '',
    // endTime: '',
    accessPermissions: '',
    certificateAvailability: '',
  });

  const handleNext = () => {
    // handleSaveCourse();
    setCurrentStep(1);
  };

  const handlePriceSwitchChange = () => {
    setCourseDets((prevState) => ({
      ...prevState,
      isPaidCourse: !prevState.isPaidCourse,
    }));
    setOpen(true);
  };

  const handleSwitchChange = () => {
    // setChecked(!checked);

    const { name, checked } = event.target;
    setCourseDets((prevCourseDets) => ({
      ...prevCourseDets,
      progressTracking: {
        ...prevCourseDets.progressTracking,
        [name]: checked,
      },
    }));
  };

  const requestUploadUrl = async (file) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/course/bannerImage`,
        {
          fileName: file?.name,
          fileType: file?.type,
        },
      );
      console.log(response.data.url, 'requestUploadUrl');
      setLink(response.data.url);
    } catch (error) {
      console.error('Error requesting upload URL:', error);
    }
  };

  const uploadToS3 = async () => {
    console.log('uploadToS3');
    try {
      await axios.put(link, fileData, {
        headers: {
          'Content-Type': fileData?.type,
        },
      });
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  useEffect(() => {
    const actualAmountValue = parseFloat(actualAmount) || 0;
    const discountValue = parseFloat(discountAmount) || 0;
    const taxesValue = parseFloat(taxes) || 0;
    const calculatedDiscountedAmount =
      actualAmountValue - (actualAmountValue * discountValue) / 100;
    const calculatedTotalAmount =
      calculatedDiscountedAmount +
      (calculatedDiscountedAmount * taxesValue) / 100;

    setDiscountedAmount(calculatedDiscountedAmount.toFixed(2));
    setTotalAmount(calculatedTotalAmount.toFixed(2));
  }, [actualAmount, discountAmount, taxes]);

  const handleSaveCourse = async () => {
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
    await uploadToS3();
    console.log('handle saveee ', courseDets);
    const updatedCourseDets = {
      ...courseDets,
      generalInformation: {
        ...courseDets.generalInformation,
        bannerImage: link?.split('?')[0],
      },
    };
    const result = await dispatch(createCourseInSlice(updatedCourseDets));
    console.log(result);
    navigate('/CourseBuilder');
  };

  const validateForm = () => {
    const errors = {};

    if (!courseDets.generalInformation.title) {
      errors.title = 'Title is required';
    }

    if (!courseDets.generalInformation.courseCode) {
      errors.courseCode = 'Course code is required';
    }

    if (!courseDets.generalInformation.courseDuration) {
      errors.courseDuration = 'Course duration is required';
    }

    if (!courseDets.generalInformation.courseLevel) {
      errors.courseLevel = 'Course level is required';
    }

    if (!courseDets.generalInformation.courseCategory) {
      errors.courseCategory = 'Course category is required';
    }

    if (!courseDets.generalInformation.language) {
      errors.language = 'Language is required';
    }

    if (courseDets.generalInformation.instructorName.length === 0) {
      errors.instructorName = 'At least one instructor is required';
    }

    if (!courseDets.generalInformation.courseStructure) {
      errors.courseStructure = 'Course structure is required';
    }

    if (courseDets.isPaidCourse && !courseDets.pricingDetails.actualAmount) {
      errors.actualAmount = 'Actual amount is required for paid courses';
    }

    if (courseDets.isPaidCourse && !courseDets.pricingDetails.taxes) {
      errors.taxes = 'Taxes are required for paid courses';
    }

    if (!courseDets.accessAndEnrollment.enrollmentType) {
      errors.enrollmentType = 'Enrollment type is required';
    }

    if (!courseDets.accessAndEnrollment.maxEnrollment) {
      errors.maxEnrollment = 'Allotment number is required';
    }

    if (!courseDets.accessAndEnrollment.startDate) {
      errors.startDate = 'Start date is required';
    }

    if (!courseDets.accessAndEnrollment.endDate) {
      errors.endDate = 'End date is required';
    }

    if (!courseDets.accessAndEnrollment.startTime) {
      errors.startTime = 'Start time is required';
    }

    if (!courseDets.accessAndEnrollment.endTime) {
      errors.endTime = 'End time is required';
    }

    if (!courseDets.accessAndEnrollment.accessPermissions) {
      errors.accessPermissions = 'Access permissions are required';
    }

    if (!courseDets.accessAndEnrollment.certificateAvailability) {
      errors.certificateAvailability = 'Certificate availability is required';
    }

    // Check if there are any errors
    if (Object.keys(errors).length > 0) {
      toast.error('Please fill all required fields', {
        autoClose: 3000,
      });
    }

    return errors;
  };
  useEffect(() => {
    if (isImport) {
      setCourseDets((prevState) => ({
        ...prevState,
        generalInformation: {
          ...prevState.generalInformation,
          courseStructure: 'SCORM',
        },
      }));
    }
  }, []);

  useEffect(() => {
    if (isImport) {
      setCourseDets((prevState) => ({
        ...prevState,
        generalInformation: {
          ...prevState.generalInformation,
          scormUrl: scormUrl,
        },
      }));
    }
  }, [scormUrl]);

  const config = {
    placeholder: 'Start typing....',
    height: 300,
    readonly: false,
    observer: false,
    shouldWork: true,
  };

  useEffect(() => {
    console.log('courseDets ', courseDets);
  }, []);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFileData(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCourseDets((prevState) => ({
          ...prevState,
          generalInformation: {
            ...prevState.generalInformation,
            bannerImage: reader.result,
          },
        }));
      };
      reader.readAsDataURL(file);
      requestUploadUrl(file);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false); // Close the modal
  };
  const handleRemoveImage = () => {
    setCourseDets((prevState) => ({
      ...prevState,
      generalInformation: {
        ...prevState.generalInformation,
        bannerImage: null,
      },
    }));
  };

  const image = courseDets.generalInformation.bannerImage;

  const [certificate, setCertificate] = useState('');
  const [isExam, setIsExam] = useState('');
  const [materials, setMaterials] = useState('');

  const handleCertificateChange = (event) => {
    setCertificate(event.target.value);
  };

  const handleIsExamChange = (event) => {
    setIsExam(event.target.value);
  };
  const handleMaterialChange = (event) => {
    setMaterials(event.target.value);
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    const [category, key] = name.split('.');

    setCourseDets((prevState) => ({
      ...prevState,
      [category]: {
        ...prevState[category],
        [key]: value,
      },
    }));

    // Date validation
    if (
      name === 'accessAndEnrollment.startDate' &&
      courseDets.accessAndEnrollment.endDate
    ) {
      if (new Date(value) > new Date(courseDets.accessAndEnrollment.endDate)) {
        setErrors((prev) => ({
          ...prev,
          startDateError: 'Start date cannot be later than end date',
          endDateError: '',
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          startDateError: '',
          endDateError: '',
        }));
      }
    }

    if (
      name === 'accessAndEnrollment.endDate' &&
      courseDets.accessAndEnrollment.startDate
    ) {
      if (
        new Date(value) < new Date(courseDets.accessAndEnrollment.startDate)
      ) {
        setErrors((prev) => ({
          ...prev,
          endDateError: 'End date cannot be earlier than start date',
          startDateError: '',
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          endDateError: '',
          startDateError: '',
        }));
      }
    }
  };
  const handleCoupon = () => {
    setOpenModal(true);
    const { name, checked } = event.target;
    setCourseDets((prevCourseDets) => ({
      ...prevCourseDets,
      progressTracking: {
        ...prevCourseDets.progressTracking,
        [name]: checked,
      },
    }));
  };

  const handleTimeChange = (time, field) => {
    setCourseDets((prev) => ({
      ...prev,
      accessAndEnrollment: {
        ...prev.accessAndEnrollment,
        [field.split('.').pop()]: time,
      },
    }));

    const { startDate, endDate, startTime, endTime } =
      courseDets.accessAndEnrollment;

    if (field === 'accessAndEnrollment.startTime' && endTime) {
      if (startDate === endDate && time > endTime) {
        setErrors((prev) => ({
          ...prev,
          startTimeError: 'Start time cannot be later than end time',
          endTimeError: '',
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          startTimeError: '',
          endTimeError: '',
        }));
      }
    }

    if (field === 'accessAndEnrollment.endTime' && startTime) {
      if (startDate === endDate && time < startTime) {
        setErrors((prev) => ({
          ...prev,
          endTimeError: 'End time cannot be earlier than start time',
          startTimeError: '',
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          endTimeError: '',
          startTimeError: '',
        }));
      }
    }
  };
  const calculateDiscountedAmount = (actualAmount, discount) => {
    const discountAmount = (actualAmount * discount) / 100;
    const totalAmount = actualAmount - discountAmount;
    return totalAmount;
  };
  const calculateCouponTotalAmount = (actualAmount, discount, taxes) => {
    const discountAmount = (actualAmount * discount) / 100;
    const discountedAmount = actualAmount - discountAmount;

    // Calculate total after applying taxes
    const totalAfterTax = discountedAmount + (discountedAmount * taxes) / 100;
    return totalAfterTax.toFixed(2); // Return as a fixed decimal number
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const [category, field] = name.split('.');

    setCourseDets((prevState) => {
      const updatedPricingDetails = {
        ...prevState.pricingDetails,
        [field]: value,
      };

      // Calculate discounted amount
      if (field === 'actualAmount' || field === 'discount') {
        const actualAmount =
          parseFloat(updatedPricingDetails.actualAmount) || 0;
        const discount = parseFloat(updatedPricingDetails.discount) || 0;
        const discountedAmount = actualAmount - actualAmount * (discount / 100);
        updatedPricingDetails.discountedAmount = discountedAmount.toFixed(2);
      }

      // Calculate total amount
      if (
        field === 'actualAmount' ||
        field === 'discount' ||
        field === 'taxes'
      ) {
        const actualAmount =
          parseFloat(updatedPricingDetails.actualAmount) || 0;
        const discount = parseFloat(updatedPricingDetails.discount) || 0;
        const taxes = parseFloat(updatedPricingDetails.taxes) || 0;
        const discountedAmount = actualAmount - actualAmount * (discount / 100);
        const totalAmount = discountedAmount + discountedAmount * (taxes / 100);
        updatedPricingDetails.totalAmount = totalAmount.toFixed(2);
      }

      return {
        ...prevState,
        pricingDetails: updatedPricingDetails,
      };
    });
  };
  const handleFocus = (fieldName) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: null,
    }));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <PageContainer>
        <CreateContent style={{ justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title="Go Back">
              <IconButton
                size="small"
                onClick={() => navigate(-1)}
                sx={{ color: `${palette.THEME_THREE}`, marginleft: '33px' }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Tooltip>

            <h3>Specifications</h3>
          </div>

          <ButtonCont
            style={{
              position: 'fixed',
              zIndex: 1000,
              right: 5,
            }}
          >
            {isImport ? (
              currentStep === 0 ? (
                <CommonButton onClick={handleNext} label="Next" />
              ) : (
                <div>
                  <CommonButton onClick={handleSaveCourse} label="Submit" />
                </div>
              )
            ) : (
              <div>
                <CommonButton onClick={handleSaveCourse} label="Submit" />
              </div>
            )}
          </ButtonCont>
        </CreateContent>
        {currentStep === 0 ? (
          <Container fluid sx={ContainerStyle}>
            <Grid container sx={{ mb: 2 }}></Grid>
            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-basic"
                      placeholder="Enter Course Title"
                      variant="outlined"
                      fullWidth
                      label="Title"
                      name="generalInformation.title"
                      value={courseDets.generalInformation.title}
                      onChange={handleSelectChange}
                      onFocus={() => handleFocus('title')}
                      required
                      error={!!errors.title}
                      helperText={errors.title}
                    />
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-basic"
                      placeholder="Enter Description"
                      variant="outlined"
                      rows="5.5"
                      multiline
                      fullWidth
                      label="Description"
                      name="generalInformation.description"
                      value={courseDets.generalInformation.description}
                      onChange={handleSelectChange}
                      onFocus={() => handleFocus('description')}
                      required
                      error={!!errors.description}
                      helperText={errors.description}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={6}
                container
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <div
                    style={{
                      border: '1px solid #ccc',
                      width: '100%',
                      paddingTop: '43%',
                      position: 'relative',
                      overflow: 'hidden',
                      cursor: 'pointer',
                    }}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                  >
                    <input
                      accept="image/*"
                      id="contained-button-file"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleImageChange}
                    />
                    {image ? (
                      <div
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          backgroundImage: `url(${image})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {isHovered && (
                          <div
                            style={{
                              justifyContent: 'center',
                              alignItems: 'center',
                              backgroundColor: 'rgba(0, 0, 0, 0.5)',
                              color: '#fff',
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '10px',
                            }}
                          >
                            <CommonButton
                              onClick={() =>
                                document
                                  .getElementById('contained-button-file')
                                  .click()
                              }
                              label="Change Image"
                            />
                            <CommonButton
                              onClick={handleRemoveImage}
                              label="Remove Image"
                              style={{
                                backgroundColor: 'gray',
                                color: 'white',
                              }}
                              size="small"
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      <div
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                        }}
                      >
                        <CommonButton
                          onClick={() =>
                            document
                              .getElementById('contained-button-file')
                              .click()
                          }
                          label="Upload Image"
                        />
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={6}>
                <TextField
                  id="outlined-basic"
                  label="Course Code"
                  placeholder="Enter Course Code"
                  variant="outlined"
                  fullWidth
                  name="generalInformation.courseCode"
                  value={courseDets.generalInformation.courseCode}
                  onChange={handleSelectChange}
                  onFocus={() => handleFocus('courseCode')}
                  required
                  error={!!errors.courseCode}
                  helperText={errors.courseCode}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-basic"
                  label="Course Duration in hrs"
                  placeholder="Enter Course Duration"
                  variant="outlined"
                  fullWidth
                  name="generalInformation.courseDuration"
                  value={courseDets.generalInformation.courseDuration}
                  onChange={handleSelectChange}
                  onFocus={() => handleFocus('courseDuration')}
                  required
                  error={!!errors.courseDuration}
                  helperText={errors.courseDuration}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    id="courseLevel"
                    name="generalInformation.courseLevel"
                    value={courseDets.generalInformation.courseLevel}
                    onChange={handleSelectChange}
                    onFocus={() => handleFocus('courseLevel')}
                    displayEmpty // Shows the placeholder when no option is selected
                    renderValue={(selected) =>
                      selected ? selected : 'Select Course Level'
                    }
                    required
                    error={!!errors.courseLevel}
                  >
                    <MenuItem value="" disabled>
                      Select Course Level *
                    </MenuItem>
                    <MenuItem value="Beginner">Beginner</MenuItem>
                    <MenuItem value="Intermediate">Intermediate</MenuItem>
                    <MenuItem value="Advanced">Advanced</MenuItem>
                  </Select>
                  {errors.courseLevel && (
                    <Typography color="error" variant="caption">
                      {errors.courseLevel}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    id="course-category"
                    name="generalInformation.courseCategory"
                    value={courseDets.generalInformation.courseCategory}
                    onChange={handleSelectChange}
                    onFocus={() => handleFocus('courseCategory')}
                    displayEmpty // Shows the placeholder when no option is selected
                    renderValue={(selected) =>
                      selected ? selected : 'Select Course Category'
                    }
                    required
                    error={!!errors.courseCategory}
                  >
                    <MenuItem value="" disabled>
                      Select Course Category
                    </MenuItem>
                    <MenuItem value="Beginner">Science</MenuItem>
                    <MenuItem value="Intermediate">Management</MenuItem>
                    <MenuItem value="Advanced">Arts</MenuItem>
                    {/* <MenuItem value="Add">Add Course Category</MenuItem> */}
                  </Select>
                  {errors.courseCategory && (
                    <Typography color="error" variant="caption">
                      {errors.courseCategory}
                    </Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    id="language"
                    name="generalInformation.language"
                    value={courseDets.generalInformation.language}
                    onChange={handleSelectChange}
                    onFocus={() => handleFocus('language')}
                    displayEmpty // Shows the placeholder when no option is selected
                    renderValue={(selected) =>
                      selected ? selected : 'Select Language'
                    }
                    required
                    error={!!errors.language}
                  >
                    <MenuItem value="" disabled>
                      Select Language
                    </MenuItem>
                    <MenuItem value="English">English</MenuItem>
                    <MenuItem value="Spanish">Spanish</MenuItem>
                  </Select>
                  {errors.language && (
                    <Typography color="error" variant="caption">
                      {errors.language}
                    </Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={6} sx={{ mb: 2 }}>
                <FormControl fullWidth>
                  <Autocomplete
                    multiple
                    open={open}
                    onOpen={handleOpen}
                    onClose={handleClose}
                    options={instructors}
                    getOptionLabel={(option) =>
                      `${option?.firstname} ${option?.lastname}`
                    }
                    value={courseDets.generalInformation.instructorName.map(
                      (name) => ({
                        _id: name.id,
                        firstname: name.firstname,
                        lastname: name.lastname,
                      }),
                    )}
                    onChange={handleInstructorChange}
                    onFocus={() => handleFocus('instructorName')}
                    loading={loading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Instructor"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                        error={!!errors.instructorName}
                        helperText={errors.instructorName}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              {!isImport && (
                <>
                  <Grid item xs={6}>
                    <Typography>Select Course Structure</Typography>

                    <FormControl variant="outlined" fullWidth>
                      <Select
                        id="structure"
                        onChange={handleSelectChange}
                        onFocus={() => handleFocus('courseStructure')}
                        name="generalInformation.courseStructure"
                        value={courseDets.generalInformation.courseStructure}
                        required
                        error={!!errors.courseStructure}
                      >
                        <MenuItem value="CMLT">CMLT </MenuItem>
                        <MenuItem value="CLT">CLT</MenuItem>
                      </Select>
                      {errors.courseStructure && (
                        <Typography color="error" variant="caption">
                          {errors.courseStructure}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                </>
              )}

              <Grid item xs={6} sx={{ mb: 2 }}>
                <Typography>Link Course To Event *</Typography>
                <FormControl fullWidth>
                  <Autocomplete
                    multiple
                    open={openFetchEvent}
                    onOpen={handleOpenFetchEvent}
                    onClose={handleCloseFetchEvent}
                    options={events}
                    getOptionLabel={(option) => option?.EventName}
                    value={courseDets.generalInformation?.eventName?.eventName}
                    onChange={handleEventChange}
                    loading={loading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Event"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Grid container>
                  <h3>Pricing Detail *</h3>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="pricing-select-label">
                      Course Type
                    </InputLabel>
                    <Select
                      labelId="pricing-select-label"
                      value={courseDets.isPaidCourse ? 'paid' : 'free'}
                      onChange={handlePriceSwitchChange}
                      label="Course Type"
                    >
                      <MenuItem value="free">This is a free course</MenuItem>
                      <MenuItem value="paid" onClick={openPricingModal}>
                        This is a paid course
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <Typography display="inline">
                    {' '}
                    {courseDets.isPaidCourse
                      ? 'This is a paid course'
                      : 'This is a free course'}
                  </Typography>
                </Grid>
                <Dialog open={pricingModalOpen} onClose={closePricingModal}>
                  <DialogTitle>Pricing Details</DialogTitle>
                  <DialogContent>
                    {courseDets.isPaidCourse && (
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            name="pricingDetails.actualAmount"
                            placeholder="Enter Actual Amount"
                            variant="outlined"
                            label="Actual Amount"
                            fullWidth
                            value={courseDets.pricingDetails.actualAmount}
                            onChange={handleInputChange}
                            required
                            type="number"
                            error={!!errors.actualAmount}
                            helperText={errors.actualAmount}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            name="pricingDetails.taxes"
                            label="Taxes (%)"
                            placeholder="Enter Taxes Percentage"
                            variant="outlined"
                            fullWidth
                            value={courseDets.pricingDetails.taxes}
                            onChange={handleInputChange}
                            required
                            type="number"
                          />
                        </Grid>

                        {/* Map over the array of coupons */}
                        {courseDets.pricingDetails.coupons.map(
                          (coupon, index) => (
                            <React.Fragment key={index}>
                              <Grid item xs={6}>
                                <TextField
                                  name={`pricingDetails.coupons[${index}].couponString`}
                                  label="Coupon Code"
                                  placeholder="Coupon Code"
                                  variant="outlined"
                                  fullWidth
                                  value={coupon.couponString}
                                  onChange={(e) => {
                                    const updatedCoupons = [
                                      ...courseDets.pricingDetails.coupons,
                                    ];
                                    updatedCoupons[index].couponString =
                                      e.target.value;
                                    setCourseDets({
                                      ...courseDets,
                                      pricingDetails: {
                                        ...courseDets.pricingDetails,
                                        coupons: updatedCoupons,
                                      },
                                    });
                                  }}
                                />
                              </Grid>

                              <Grid item xs={6}>
                                <TextField
                                  name={`pricingDetails.coupons[${index}].discount`}
                                  label="Coupon Discount (%)"
                                  placeholder="Enter Discount Percentage"
                                  variant="outlined"
                                  fullWidth
                                  value={coupon.discount}
                                  onChange={(e) => {
                                    const updatedCoupons = [
                                      ...courseDets.pricingDetails.coupons,
                                    ];
                                    updatedCoupons[index].discount =
                                      e.target.value;

                                    const totalAmountForCoupon =
                                      calculateCouponTotalAmount(
                                        courseDets.pricingDetails.actualAmount,
                                        e.target.value,
                                        courseDets.pricingDetails.taxes,
                                      );

                                    // Update coupon with the calculated total amount
                                    updatedCoupons[index].totalAmount =
                                      totalAmountForCoupon;

                                    setCourseDets({
                                      ...courseDets,
                                      pricingDetails: {
                                        ...courseDets.pricingDetails,
                                        coupons: updatedCoupons,
                                      },
                                    });
                                  }}
                                  required
                                  type="number"
                                />
                              </Grid>

                              <Grid item xs={6}>
                                <Typography>Expiry Date</Typography>
                                <TextField
                                  name={`pricingDetails.coupons[${index}].expiryDate`}
                                  placeholder="Expiry Date"
                                  variant="outlined"
                                  fullWidth
                                  value={coupon.expiryDate}
                                  onChange={(e) => {
                                    const updatedCoupons = [
                                      ...courseDets.pricingDetails.coupons,
                                    ];
                                    updatedCoupons[index].expiryDate =
                                      e.target.value;
                                    setCourseDets({
                                      ...courseDets,
                                      pricingDetails: {
                                        ...courseDets.pricingDetails,
                                        coupons: updatedCoupons,
                                      },
                                    });
                                  }}
                                  required
                                  type="date"
                                />
                              </Grid>

                              <Grid item xs={6}>
                                <Typography>
                                  Total Amount for Coupon {index + 1}
                                </Typography>
                                <TextField
                                  name={`pricingDetails.coupons[${index}].totalAmount`}
                                  placeholder="Total Amount for This Coupon"
                                  variant="outlined"
                                  fullWidth
                                  value={coupon.totalAmount || ''}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Grid>
                            </React.Fragment>
                          ),
                        )}
                      </Grid>
                    )}

                    <Button
                      onClick={() => {
                        setCourseDets({
                          ...courseDets,
                          pricingDetails: {
                            ...courseDets.pricingDetails,
                            coupons: [
                              ...courseDets.pricingDetails.coupons,
                              {
                                couponString: '',
                                discount: '',
                                expiryDate: '',
                                totalAmount: '',
                              }, // Add new coupon object
                            ],
                          },
                        });
                      }}
                      color="primary"
                    >
                      Add Coupon
                    </Button>
                  </DialogContent>

                  <DialogActions>
                    <Button onClick={closePricingModal} color="primary">
                      Cancel
                    </Button>
                    <Button color="primary">Save</Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Grid>
            <Grid>
              <h3>Content Management</h3>
            </Grid>
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}>
                <Grid>
                  <Typography>Course Prerequisite *</Typography>
                  <JoditReact
                    value={courseDets.generalInformation.prerequisite}
                    // onChange={handleSelectChange}
                  />
                </Grid>
                <br />
                <TextField
                  id="outlined-basic"
                  label="Learning Objectives"
                  placeholder="Enter Learning Objectives"
                  // label="Learning Objectives"
                  variant="outlined"
                  rows="5"
                  multiline
                  fullWidth
                  name="contentManagement.learningObjectives"
                  value={courseDets.contentManagement.learningObjectives}
                  onChange={handleSelectChange}
                  // required
                  // error={!!errors.description}
                  // helperText={errors.description}
                />
                {/* <JoditReact
                  value={courseDets.contentManagement.learningObjectives}
                  // onChange={handleSelectChange}
                  config={{
                    style: {},
                  }}
                /> */}
              </Grid>

              {/* <Grid item xs={6}>
                <Typography>Estimated Time </Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  name="contentManagement.estimatedTimeToComplete"
                  value={courseDets.contentManagement.estimatedTimeToComplete}
                  onChange={handleSelectChange}
                  required
                />
              </Grid> */}
            </Grid>
            <Grid container sx={{ mb: 2 }}>
              <h3>Access and Enrollment</h3>
            </Grid>
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={6}>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    id="enrollType"
                    name="accessAndEnrollment.enrollmentType"
                    value={courseDets.accessAndEnrollment.enrollmentType}
                    onChange={handleSelectChange}
                    displayEmpty // Shows the placeholder when no option is selected
                    renderValue={(selected) =>
                      selected ? selected : 'Select Enrollment Type'
                    }
                    required
                    error={!!errors.enrollmentType}
                  >
                    <MenuItem value="" disabled>
                      Enrollment Type
                    </MenuItem>
                    <MenuItem value="Open">Open</MenuItem>
                    <MenuItem value="Restricted">Restricted</MenuItem>
                    <MenuItem value="By Invitation">By Invitation</MenuItem>
                  </Select>
                  {errors.enrollmentType && (
                    <Typography color="error" variant="caption">
                      {errors.enrollmentType}
                    </Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="allotment-number"
                  placeholder="Enter Allotment Number"
                  variant="outlined"
                  type="number"
                  fullWidth
                  name="accessAndEnrollment.maxEnrollment"
                  value={courseDets.accessAndEnrollment.maxEnrollment}
                  onFocus={() => handleFocus('maxEnrollment')}
                  onChange={handleSelectChange}
                  required
                  error={!!errors.maxEnrollment}
                  helperText={errors.maxEnrollment}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography>Start Date *</Typography>
                <TextField
                  id="outlined-basic"
                  placeholder="Enter Course Title"
                  variant="outlined"
                  type="date"
                  fullWidth
                  inputProps={{ min: new Date().toISOString().split('T')[0] }}
                  name="accessAndEnrollment.startDate"
                  value={courseDets.accessAndEnrollment.startDate}
                  onChange={handleSelectChange}
                  onFocus={() => handleFocus('startDate')}
                  required
                  error={!!errors.startDate}
                  helperText={errors.startDate}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography>Start Time *</Typography>
                <TimePicker
                  value={courseDets.accessAndEnrollment.startTime}
                  onChange={(time) =>
                    handleTimeChange(time, 'accessAndEnrollment.startTime')
                  }
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  onFocus={() => handleFocus('startTime')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      fullWidth
                      required
                      error={!!errors.startTime}
                      helperText={errors.startTime}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography>End Date *</Typography>
                <TextField
                  id="outlined-basic"
                  placeholder="Enter Course Title"
                  variant="outlined"
                  type="date"
                  fullWidth
                  name="accessAndEnrollment.endDate"
                  value={courseDets.accessAndEnrollment.endDate}
                  onChange={handleSelectChange}
                  onFocus={() => handleFocus('endDate')}
                  inputProps={{ min: new Date().toISOString().split('T')[0] }}
                  required
                  error={!!errors.endDate}
                  helperText={errors.endDate}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography>End Time *</Typography>
                <TimePicker
                  value={courseDets.accessAndEnrollment.endTime}
                  onChange={(time) =>
                    handleTimeChange(time, 'accessAndEnrollment.endTime')
                  }
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      fullWidth
                      required
                      error={!!errors.endTimeError}
                      helperText={errors.endTimeError}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} sx={{ mb: 2 }}>
                <Typography>Access Permission *</Typography>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    id="Permission"
                    name="accessAndEnrollment.accessPermissions"
                    value={courseDets.accessAndEnrollment.accessPermissions}
                    onChange={handleSelectChange}
                    onFocus={() => handleFocus('accessPermissions')}
                    displayEmpty // Shows the placeholder when no option is selected
                    renderValue={(selected) =>
                      selected ? selected : 'Select Access Permission'
                    }
                    required
                    error={!!errors.accessPermissions}
                  >
                    <MenuItem value="" disabled>
                      Select Access Permission
                    </MenuItem>
                    <MenuItem value="Public">Public</MenuItem>
                    <MenuItem value="Private">Private</MenuItem>
                    <MenuItem value="Institution-specific">
                      Institution-specific
                    </MenuItem>
                  </Select>
                  {errors.accessPermissions && (
                    <Typography color="error" variant="caption">
                      {errors.accessPermissions}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Typography>Certificate *</Typography>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    id="Certificate"
                    name="accessAndEnrollment.certificateAvailability"
                    value={
                      courseDets.accessAndEnrollment.certificateAvailability
                    }
                    onChange={handleSelectChange}
                    onFocus={() => handleFocus('certificateAvailability')}
                    displayEmpty // Shows the placeholder when no option is selected
                    // renderValue={(selected) =>
                    //   selected ? selected : 'Is Certificate Available'
                    // }
                    required
                    error={!!errors.certificateAvailability}
                  >
                    <MenuItem value="true">Available</MenuItem>
                    <MenuItem value="false">Not Available</MenuItem>
                  </Select>
                  {errors.certificateAvailability && (
                    <Typography color="error" variant="caption">
                      {errors.certificateAvailability}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Container>
        ) : (
          <ScormUploader scormUrl={scormUrl} setScormUrl={setScormUrl} />
        )}
      </PageContainer>
    </LocalizationProvider>
  );
};

export default CreateCourse;
