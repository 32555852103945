import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Button, Divider } from '@mui/material';
import JoditReact from 'jodit-react';

const PreviewModuleOrLesson = ({ item, type, onClose }) => {
  const [details, setDetails] = useState({ ...item });
  console.log("deatils abc ", details)

  useEffect(() => {
    setDetails({ ...item });
  }, [item]);
  

  return (
    <Box
      sx={{
        padding: '20px',
        backgroundColor: '#fff',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        marginTop: '14px',
        maxHeight: '80vh',
        overflow: 'auto',
        borderRadius: '8px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          alignItems: 'center',
        }}
      >
       
      </div>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            sx={{ mb: 2, fontWeight: 'bold', color: '#333' }}
          >
            {details.title}
          </Typography>
          <Divider></Divider>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{ mb: 1, fontWeight: 'bold', color: '#333' }}
          >
            Description
          </Typography>

          <Typography variant="body1" sx={{ mb: 3, color: '#555' }}>
            {details.textDescription}
            {/* <span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce at libero vulputate, pellentesque metus ac, molestie diam. Nunc vehicula leo vitae lectus accumsan placerat. Aliquam porta maximus convallis. Donec sit amet magna ante. Sed porta rutrum tortor. Nam at felis ac augue gravida blandit at nec est. Praesent non ornare quam. Proin et egestas purus, in ornare sem. Sed ut lacus nulla. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</span> */}
          </Typography>
        </Grid>
       
      </Grid>
    </Box>
  );
};

export default PreviewModuleOrLesson;
