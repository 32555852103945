import React, { useState, useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { keyframes } from '@mui/system';

const ImageSlider = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const announcement = useSelector((state) => state.calendar.announcement);
  const { users } = useSelector((state) => state.auth);

  const images = announcement
    .filter((announcement) => announcement.image)
    .map((announcement) => announcement.image)
    .slice(0, 9);

  // If there's only one image, display it without slider controls
  if (images.length === 1) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '90%',
          overflow: 'hidden',
          maxWidth: '1300px',
          margin: 'auto',
          marginTop: '25px',
        }}
      >
        <img
          src={images[0]}
          alt="Announcement"
          onClick={() => handleAnnDetails(announcement[0]._id)}
          style={{
            cursor: 'pointer',
            borderRadius: '8px',
            width: '100%',
            height: '250px',
            objectFit: 'contain',
          }}
        />
      </Box>
    );
  }

  const allImages = [...images, ...images, ...images];

  const [currentIndex, setCurrentIndex] = useState(images.length);
  const [selectedSlide, setSelectedSlide] = useState(currentIndex);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => prevIndex - 1);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      nextSlide();
    }, 4000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (currentIndex >= allImages.length - images.length) {
      setTimeout(() => {
        setCurrentIndex(images.length);
        setSelectedSlide(images.length); // Reset the selected slide
      }, 500);
    } else if (currentIndex <= 0) {
      setTimeout(() => {
        setCurrentIndex(allImages.length - 2 * images.length);
        setSelectedSlide(allImages.length - 2 * images.length); // Reset the selected slide
      }, 500);
    } else {
      setSelectedSlide(currentIndex);
    }
  }, [currentIndex, allImages.length, images.length]);

  const handleAnnDetails = (id) => {
    if (users.user.userid?.role === 'Admin') {
      navigate(`/EventsNavigator/announcedetails/${id}`);
    } else {
      navigate(`/LearnersEventsNavigator/announcedetails/${id}`);
    }
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index + images.length);
    setSelectedSlide(index + images.length);
  };
  const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        overflow: 'hidden',
        maxWidth: '1300px',
        margin: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <IconButton
          onClick={prevSlide}
          sx={{
            position: 'absolute',
            left: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 1,
          }}
        >
          <ArrowLeft />
        </IconButton>

        <Box
          sx={{
            display: 'flex',
            transform: `translateX(-${(currentIndex - images.length) * (100 / 3)}%)`,
            transition: 'transform 0.5s ease-in-out',
            width: `${allImages.length * (100 / 3)}%`,
          }}
        >
          {allImages.map((image, index) => {
            const relativeIndex =
              (index - currentIndex + images.length) % images.length;
            const isHighlighted = relativeIndex === 1; // Highlight the image after the active one

            return (
              <Box
                key={index}
                sx={{
                  flex: '0 0 calc(100% / 3)',
                  padding: '8px',
                  boxSizing: 'border-box',
                  transform: isHighlighted ? 'scale(1.05)' : 'scale(0.85)', // Adjust scaling here
                  opacity: 1,
                  transition:
                    'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
                }}
              >
                <img
                  src={image}
                  alt={`Slide ${index + 1}`}
                  onClick={() =>
                    handleAnnDetails(announcement[index % images.length]._id)
                  }
                  style={{
                    cursor: 'pointer',
                    borderRadius: '8px',
                    width: '100%',
                    height: '250px',
                    objectFit: 'cover',
                  }}
                />
              </Box>
            );
          })}
        </Box>

        <IconButton
          onClick={nextSlide}
          sx={{
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 1,
          }}
        >
          <ArrowRight />
        </IconButton>
      </Box>

      <Box
        sx={{
          display: 'flex',
          paddingTop: '20px',
        }}
      >
        {images.map((_, index) => (
          <Box
            key={index}
            onClick={() => handleDotClick(index)}
            sx={{
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              backgroundColor:
                selectedSlide === (index + images.length + 1) % images.length
                  ? 'black'
                  : 'grey',
              margin: '0 4px',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease',
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ImageSlider;
