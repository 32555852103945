import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Typography,
  Switch,
  Tooltip,
  IconButton,
  TextField,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {
  Step3ContainerStyle,
  HeadingClass,
  DescriptionClass,
} from '../../../styles/Courses/CourseBuilderStyles';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { PageContainer } from '../../../styles/Common/CommonStyles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CommonButton from '../../Utilities/CommonButton';
import { useDispatch, useSelector } from 'react-redux';
import { basicSetting } from '../../../features/courses/CourseUtilitySlice';
import { toast } from 'react-toastify';
import axios from 'axios';

const BasicSettings = () => {
  const { courseId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Extract course title from location state
  const { courseTitle } = location.state || {};

  // Get course settings from Redux state
  const courseSettings = useSelector(
    (state) =>
      state.CourseUtility?.wholeCourseSetting?.data?.settings?.basicSettings,
  );

  // Initial local form state
  const [formData, setFormData] = useState({
    contentScheduling: false,
    sequentialLearningPath: false,
    enforceCompleteVideoViewing: false,
    security: {
      disableTextCopying: false,
      showCourseInfo: false,
      allowBookmarking: false,
    },
    completionPercentage: 100,
    progressIndicators: false,
    discussionForums: false,
    langCode: 'en',
  });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (courseSettings) {
      console.log('in useEffect', courseSettings);
      setFormData((prev) => ({
        ...prev,
        ...courseSettings,
        security: {
          ...prev.security,
          ...courseSettings.security,
        },
      }));
    }
  }, [courseSettings]);

  // Handle change events for form inputs
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (name in formData) {
      setFormData({
        ...formData,
        [name]: type === 'checkbox' ? checked : value,
      });
    } else if (name in formData.security) {
      setFormData({
        ...formData,
        security: {
          ...formData.security,
          [name]: checked,
        },
      });
    }
  };

  // Handle saving course settings
  const handleSaveCourseSetting = async () => {
    console.log(formData, 'checking data');
    try {
      await dispatch(basicSetting({ courseId, formData }));
      toast.success('Settings saved successfully');
    } catch (error) {
      console.error('Error saving course settings:', error);
      toast.error('Failed to save settings');
    }
  };
  const handleDeleteCourse = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_URL}/api/course/logicalDeleteCourse/${courseId}`,
      );
      toast.success('Course deleted successfully');
      setOpen(false); // Close modal after deletion
      navigate('/CourseBuilder'); // Redirect after deletion
    } catch (error) {
      console.error('Error deleting course:', error);
      toast.error('Failed to delete course');
    }
  };

  // Open modal
  const handleOpen = () => {
    setOpen(true);
  };

  // Close modal
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <PageContainer>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '90%',
          margin: 'auto',
        }}
      >
        <Tooltip title="Go Back">
          <IconButton size="small" onClick={() => navigate('/CourseBuilder')}>
            <ArrowBackIosIcon />
          </IconButton>
        </Tooltip>
        <h4 style={{ color: '#555454' }}>{courseTitle}</h4>
        <div>
          <CommonButton
            label="Save setting"
            onClick={handleSaveCourseSetting}
          />
        </div>
      </div>
      <Container fluid sx={Step3ContainerStyle}>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={8}>
            <Typography sx={HeadingClass}>Enable Content Scheduling</Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              checked={formData.contentScheduling}
              onChange={handleChange}
              name="contentScheduling"
            />
          </Grid>
          <Grid item xs={8}>
            <Typography sx={DescriptionClass}>
              Course Items will be released gradually over time (e.g. every few
              days or once a week)
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography sx={HeadingClass}>
              Enforce Sequential Learning Path
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              checked={formData.sequentialLearningPath}
              onChange={handleChange}
              name="sequentialLearningPath"
            />
          </Grid>
          <Grid item xs={8}>
            <Typography sx={DescriptionClass}>
              Course items can only be accessed in the sequential order.
              Learners need to mark course items as completed to move to the
              next item.
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography sx={HeadingClass}>Progress Indicators</Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              checked={formData.progressIndicators}
              onChange={handleChange}
              name="progressIndicators"
            />
          </Grid>
          <Grid item xs={8}>
            <Typography sx={DescriptionClass}>
              Enable visual indicators to show learners their progress through
              the course.
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography sx={HeadingClass}>Discussion Forums</Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              checked={formData.discussionForums}
              onChange={handleChange}
              name="discussionForums"
            />
          </Grid>
          <Grid item xs={8}>
            <Typography sx={DescriptionClass}>
              Allow learners to participate in discussions related to course
              content.
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography sx={HeadingClass}>Completion Percentage</Typography>
            <TextField
              name="completionPercentage"
              value={formData.completionPercentage}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={8}>
            <Typography sx={HeadingClass}>Security Settings</Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography sx={DescriptionClass}>Disable Text Copying</Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              checked={formData.security.disableTextCopying}
              onChange={handleChange}
              name="disableTextCopying"
            />
          </Grid>

          <Grid item xs={8}>
            <Typography sx={HeadingClass}>
              Show Additional Course Info
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              checked={formData.security.showCourseInfo}
              onChange={handleChange}
              name="showCourseInfo"
            />
          </Grid>

          <Grid item xs={8}>
            <Typography sx={HeadingClass}>
              Allow Bookmarking Course Items
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              checked={formData.security.allowBookmarking}
              onChange={handleChange}
              name="allowBookmarking"
            />
          </Grid>
        </Grid>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '10px',
            backgroundColor: 'rgba(249, 249, 249, 0.4)',
            border: '1px solid #ccc',
            borderRadius: '5px',
            marginTop: '20px',
          }}
        >
          <h4 style={{ marginRight: '10px' }}>
            Are you sure you want to delete this course? This action is
            irreversible.
          </h4>
          <Button variant="contained" color="error" onClick={handleOpen}>
            Delete Course
          </Button>
        </Box>
      </Container>

      {/* Confirmation Dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Delete Course Confirmation'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this course? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteCourse} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </PageContainer>
  );
};

export default BasicSettings;
