import { configureStore } from '@reduxjs/toolkit';
import Authentication from '../features/authentication/AuthenticationSlice';
import CertificateReducer from '../features/certificate/CertificateSlice';
import NotificationReducer from '../features/notification/NotificationSlice';
import CalendarReducer from '../features/calendar/CalendarSlice';
import userDetails from '../features/user/userSlice';
import userModule from '../features/userModule/userModuleSlice';
import addCourseDetails from '../features/courses/addCourseDetailsSlice';
import chatReducer from '../features/courses/courseDiscussionSlice';
import pdf from '../features/courses/pdfSlice';
import quizReducer from '../features/courses/quizSlice';
import registrationsReducer from '../features/courses/LearnersRegistrationSlice';
import CourseUtilitySlice from '../features/courses/CourseUtilitySlice';
import SitebuilderSlice from '../features/sitebuilder/SitebuilderSlice';
import RequestSlice from '../features/RequestSlice';
import courseModulesReducer from '../features/courses/courseModuleSlice';
import sidebarSlice from '../features/sidebar/sidebar.js';

export const store = configureStore({
  reducer: {
    auth: Authentication,
    certificate: CertificateReducer,
    notification: NotificationReducer,
    user: userDetails,
    users: userModule,
    calendar: CalendarReducer,
    addCourseDetails: addCourseDetails,
    chat: chatReducer,
    pdf: pdf,
    quiz: quizReducer,
    learners: registrationsReducer,
    userTemplates: SitebuilderSlice,
    CourseUtility: CourseUtilitySlice,
    request: RequestSlice,
    courseModules: courseModulesReducer,
    sidebarSlice: sidebarSlice,
  },
});
