import React from 'react';
import {
  Grid,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Box,
  Stack,
  Tooltip,
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import { palette } from '../../../styles/palette';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
`;

const StyledIconButton = styled(IconButton)`
  color: ${palette.THEME_THREE};
  position: absolute;
  top: 8px;
  left: 8px;
`;

const StyledCard = styled(Card)`
  background-color: ${palette.white};
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.8);
  transition: box-shadow 0.3s ease-in-out;
  &:hover {
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.3);
  }
  margin: auto;
  text-align: center;
  padding: 20px;
  height: 170px; // Fixed height
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SelectCourseCard = () => {
  const navigate = useNavigate();

  const handleCardClick = (path) => {
    navigate(path);
  };

  return (
    <Container style={{ minHeight: '74vh' }}>
      <Tooltip title="Go Back">
        <StyledIconButton size="small" onClick={() => navigate(-1)}>
          <ArrowBackIosIcon />
        </StyledIconButton>
      </Tooltip>
      <Grid container spacing={3} justifyContent="center" margin="15px">
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <CardActionArea
              onClick={() => handleCardClick('/CourseBuilder/CreateCourse')}
            >
              <CardContent>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <AddIcon sx={{ fontSize: 40, color: `${palette.primary}` }} />
                  <Typography gutterBottom variant="h6" component="div">
                    Blank Course
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Create a new course from scratch.
                  </Typography>
                </Stack>
              </CardContent>
            </CardActionArea>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <CardActionArea
              onClick={() => handleCardClick('/CourseBuilder/SelectTemplte')}
            >
              <CardContent>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <LibraryAddIcon
                    sx={{ fontSize: 40, color: `${palette.primary}` }}
                  />
                  <Typography gutterBottom variant="h6" component="div">
                    Create course from Template
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Create a new course from Template.
                  </Typography>
                </Stack>
              </CardContent>
            </CardActionArea>
          </StyledCard>
        </Grid>
      </Grid>
      <Grid container spacing={3} justifyContent="center" margin="15px">
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <CardActionArea
              onClick={() => handleCardClick('/CourseBuilder/copyCourse')}
            >
              <CardContent>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <FileCopyIcon
                    sx={{ fontSize: 40, color: `${palette.primary}` }}
                  />
                  <Typography gutterBottom variant="h6" component="div">
                    Copy Existing Course
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Duplicate course to create new version.
                  </Typography>
                </Stack>
              </CardContent>
            </CardActionArea>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <CardActionArea
              onClick={() => handleCardClick('/CourseBuilder/ScromImport')}
            >
              <CardContent>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <FolderZipIcon
                    sx={{ fontSize: 40, color: `${palette.primary}` }}
                  />
                  <Typography gutterBottom variant="h6" component="div">
                    Import SCORM file
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Import course using SCORM files.
                  </Typography>
                </Stack>
              </CardContent>
            </CardActionArea>
          </StyledCard>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SelectCourseCard;
