import {
  Container,
  Grid,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { PageContainer } from '../../../styles/Common/CommonStyles';
import {
  DescriptionClass,
  HeadingClass,
  Step3ContainerStyle,
} from '../../../styles/Courses/CourseBuilderStyles';
import CourseSettingTabs from './CourseSettingTabs';
import { ArrowBackIos } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { palette } from '../../../styles/palette';
import { useState } from 'react';
import CommonButton from '../../Utilities/CommonButton';

const CourseProgressCompletionSetting = () => {
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const [autoplay, enableAutoplay] = useState(false);
  const [issueCertificate, enableIssueCertificate] = useState(false);
  const [customCertificate, enableCustomCertificate] = useState(false);
  const [chapterCompletion, enablechapterCompletion] = useState(false);
  const [chapterSharing, enableChapterSharing] = useState(false);
  const [courseSharing, enableCourseSharing] = useState(false);

  const autoplayToggle = () => {
    enableAutoplay(!autoplay);
  };
  const issueCertificateToggle = () => {
    enableIssueCertificate(!issueCertificate);
  };
  const customCertificateToggle = () => {
    enableCustomCertificate(!customCertificate);
  };
  const chapterCompletionToggle = () => {
    enablechapterCompletion(!chapterCompletion);
  };
  const chapterSharingToggle = () => {
    enableChapterSharing(!chapterSharing);
  };
  const courseSharingToggle = () => {
    enableCourseSharing(!courseSharing);
  };
  const navigate = useNavigate();
  const handleSaveCourse = () => {
    console.log('handle saveee');
    navigate('/CourseBuilder');
  };

  return (
    <PageContainer>
      <CourseSettingTabs />
      {/* <HeaderContainer> </HeaderContainer> */}
      {/* <Grid container spacing={0}>
        <Grid item xs={1} sx={{background:`${palette.lightGray}` , pt:1 , pl:2}}>
          <Tooltip title="Go Back">
              <IconButton
                size="small"
                onClick={() => navigate("/CourseBuilder")}
                sx={{ color: `${palette.THEME_THREE}` }}
              >
                <ArrowBackIos/>
              </IconButton>
            </Tooltip>
        </Grid>
        <Grid item xs={10}>
          <CourseSettingTabs />
        </Grid>
        <Grid item xs={1} sx={{background:`${palette.lightGray}` , pt:0.5}} >
          <CommonButton label="Save" onClick={handleSaveCourse} />

        </Grid>
      </Grid> */}
      <Container fluid sx={Step3ContainerStyle}>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={8}>
            <Typography sx={HeadingClass} style={{ marginBottom: '10px' }}>
              Video Lesson Progress
            </Typography>
            <Typography sx={DescriptionClass}>
              Video Lesson Autoplay <br />
              Video Lesson will be auto play and Automatically progress to the
              following lesson upon completion
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Switch checked={autoplay} onChange={autoplayToggle} />
          </Grid>

          <Grid item xs={8}>
            <Typography sx={HeadingClass} style={{ marginBottom: '10px' }}>
              Course Completion
            </Typography>
            <Typography sx={DescriptionClass}>
              Completion Certificate <br />
              Issue a certificate when your student reach 100% completion of
              your course
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Switch
              checked={issueCertificate}
              onChange={issueCertificateToggle}
            />
          </Grid>

          <Grid item xs={8}>
            <Typography sx={DescriptionClass}>
              Custom Completion Page <br />
              Issue a certificate when your student reach 100% completion of
              your course
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Switch
              checked={customCertificate}
              onChange={customCertificateToggle}
            />
          </Grid>

          <Grid item xs={8}>
            <Typography sx={HeadingClass} style={{ marginBottom: '10px' }}>
              Chapter Completion
            </Typography>
            <Typography sx={DescriptionClass}>
              Disable Chapter Completion Message <br />
              When student complete a chapter student will not see the chapter
              completion message including a prompt to review the course if
              reviews are enable
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Switch
              checked={chapterCompletion}
              onChange={chapterCompletionToggle}
            />
          </Grid>

          <Grid item xs={8}>
            <Typography sx={HeadingClass} style={{ marginBottom: '10px' }}>
              Social Sharing
            </Typography>
            <Typography sx={DescriptionClass}>
              Enable social sharing at Chapter Completion <br />
              When student complete a chapter student will not see the chapter
              completion message including a prompt to review the course if
              reviews are enable
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Switch checked={chapterSharing} onChange={chapterSharingToggle} />
          </Grid>

          <Grid item xs={8}>
            <Typography sx={DescriptionClass}>
              Enable social sharing at Course Completion <br />
              Issue a certificate when your student reach 100% completion of
              your course
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Switch checked={courseSharing} onChange={courseSharingToggle} />
          </Grid>
        </Grid>
      </Container>
    </PageContainer>
  );
};

export default CourseProgressCompletionSetting;

// import React, { useState } from "react";
// import {
//   Container,
//   Grid,
//   IconButton,
//   Switch,
//   Tooltip,
//   Typography,
// } from "@mui/material";
// import { PageContainer } from "../../../styles/Common/CommonStyles";
// import {
//   DescriptionClass,
//   HeadingClass,
//   Step3ContainerStyle,
// } from "../../../styles/Courses/CourseBuilderStyles";
// import { ArrowBackIos } from "@mui/icons-material";
// import { useNavigate } from "react-router-dom";
// import { palette } from "../../../styles/palette";
// import CourseSettingTabs from "./CourseSettingTabs";
// import CommonButton from "../../Utilities/CommonButton";

// const CourseProgressCompletionSetting = () => {
//   const navigate = useNavigate();

//   const [selectedTab, setSelectedTab] = useState("/CourseProgressCompletionSetting");

//   const handleTabChange = (newValue) => {
//     setSelectedTab(newValue);
//   };

//   const handleSaveCourse = () => {
//     console.log("handle saveee");
//     navigate("/CourseBuilder");
//   };

//   // Define state variables for each switch
//   const [autoplay, setAutoplay] = useState(false);
//   const [issueCertificate, setIssueCertificate] = useState(false);
//   const [customCertificate, setCustomCertificate] = useState(false);
//   const [chapterCompletion, setChapterCompletion] = useState(false);
//   const [chapterSharing, setChapterSharing] = useState(false);
//   const [courseSharing, setCourseSharing] = useState(false);

//   return (
//     <PageContainer>
//       <Grid container spacing={0}>
//         <Grid item xs={1} sx={{ background: `${palette.lightGray}`, pt: 1, pl: 2 }}>
//           <Tooltip title="Go Back">
//             <IconButton
//               size="small"
//               onClick={() => navigate("/CourseBuilder")}
//               sx={{ color: `${palette.THEME_THREE}` }}
//             >
//               <ArrowBackIos />
//             </IconButton>
//           </Tooltip>
//         </Grid>
//         <Grid item xs={10}>
//           {/* Pass the handleTabChange function to CourseSettingTabs */}
//           <CourseSettingTabs onChange={handleTabChange} />
//         </Grid>
//         <Grid item xs={1} sx={{ background: `${palette.lightGray}`, pt: 0.5 }}>
//           <CommonButton label="Save" onClick={handleSaveCourse} />
//         </Grid>
//       </Grid>
//       <Container fluid sx={Step3ContainerStyle}>
//         <Grid container spacing={2} sx={{ mb: 2 }}>
//           {selectedTab === "/CourseProgressCompletionSetting" && (
//             <>
//               <Grid item xs={8}>
//                 <Typography sx={HeadingClass} style={{ marginBottom: "10px" }}>
//                   Video Lesson Progress
//                 </Typography>
//                 <Typography sx={DescriptionClass}>
//                   Video Lesson Autoplay <br />
//                   Video Lesson will be auto play and Automatically progress to the
//                   following lesson upon completion
//                 </Typography>
//               </Grid>
//               <Grid item xs={3}>
//                 <Switch checked={autoplay} onChange={() => setAutoplay(!autoplay)} />
//               </Grid>

//               {/* Repeat the same pattern for other settings */}
//             </>
//           )}
//         </Grid>
//       </Container>
//     </PageContainer>
//   );
// };

// export default CourseProgressCompletionSetting;
