import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  StyledTabs,
  StyledTab,
} from '../../styles/Certificate/CertificateStyle';
import { PageContainer } from '../../styles/Common/CommonStyles';
import { useTranslation } from 'react-i18next';

const NotificationTabs = () => {
  const location = useLocation();
  const getBasePath = (path) => {
    const segments = path.split('/');
    return segments.length > 2 ? `/${segments[1]}/${segments[2]}` : path;
  };
  const baseLocation = getBasePath(location.pathname);
  const validPaths = ['/Users', '/Users/Blacklist'];
  const defaultTabValue = validPaths.includes(baseLocation)
    ? baseLocation
    : '/Users';

  const { t } = useTranslation();
  return (
    <PageContainer>
      <StyledTabs value={defaultTabValue}>
        <StyledTab label="User" value="/Users" component={Link} to="/Users" />
        <StyledTab
          label="Black Listed"
          value="/Users/Blacklist"
          component={Link}
          to="/Users/Blacklist"
        />
      </StyledTabs>
    </PageContainer>
  );
};

export default NotificationTabs;
