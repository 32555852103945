import React from 'react';
import {
  FooterContainer,
  FooterText,
  Text,
  Container,
} from '../../styles/Landing/FooterStyles';
import CopyrightIcon from '@mui/icons-material/Copyright';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const Footer = () => {
  const navigate = useNavigate();
  const handleDisplayPrivacyPolicy = () => {};
  const handleDisplayTerm = () => {};
  const handleDisplayContactUs = () => {
    navigate('/Contact');
  };
  const handleDisplayRefundPolicy = () => {};
  const { t } = useTranslation();
  return (
    <FooterContainer style={{ position: 'relative' }}>
      <Container>
        <Text>{t('footer.footerLeapotLabel')}</Text>
        <CopyrightIcon></CopyrightIcon>
        <Text>2023</Text>
      </Container>
      <FooterText onClick={handleDisplayPrivacyPolicy}>
        {t('footer.footerPrivacyLabel')}
      </FooterText>
      <FooterText onClick={handleDisplayTerm}>
        {t('footer.footerTermsLabel')}
      </FooterText>
      <FooterText onClick={handleDisplayContactUs}>
        {t('footer.footerContactLabel')}
      </FooterText>
      <FooterText onClick={handleDisplayRefundPolicy}>
        {t('footer.footerRefundLabel')}
      </FooterText>
    </FooterContainer>
  );
};

export default Footer;
