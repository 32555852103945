import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './config/Firebase-config';
import { store } from './store/store';
import { Provider } from 'react-redux';
// for the calendar
import { BaseProvider, LightTheme } from 'baseui';
import { Provider as StyletronProvider } from 'styletron-react';
import { Client as Styletron } from 'styletron-engine-atomic';
import './i18n.js';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const theme = createTheme();
const engine = new Styletron();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.Suspense fallback="loading...">
      <StyletronProvider value={engine}>
        <BaseProvider theme={LightTheme}>
          <Provider store={store}>
            <BrowserRouter>
              <ThemeProvider theme={theme}>
                <App />
                <ToastContainer position="top-right" theme="colored" />
              </ThemeProvider>
            </BrowserRouter>
          </Provider>
        </BaseProvider>
      </StyletronProvider>
    </React.Suspense> ,
);
