import React, { useRef, useState, useEffect } from 'react';
import {
  ContactContMain,
  ContactFormCont,
  TextFieldStyle,
  Desc,
  ContactFormMain,
  ContactImg,
  SubmitButton,
  ContactFormCont2,
  ContactFormMainsub,
  Title3,
  Desc2,
  ContSub2,
  ContSub2Main,
  ContactFormCont3,
  SocialMediaIcon,
  Desc3,
  TitleCont,
  TitleLogo,
  TitleDash2,
  TitleContB,
  ContSub2MainB,
} from '../../styles/Landing/ContactusStyle';
import MinHeightTextarea from './MinHeightTextarea';
import { TitleB, TitleDash } from '../../styles/Common/CommonStyles';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import ContactUsImg from '../../images/Contactus.jpg';
import linkedin from '../../images/linkedin.png';
import ph from '../../images/telephone.png';
import Em from '../../images/mail.png';
import Ad from '../../images/location.png';
import social from '../../images/socialMedia.png';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { checkvalidateData } from '../utils/checkvalidateData';

const Contactus = () => {
  const { t } = useTranslation();

  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const mobileRef = useRef(null);

  const [nameError, setNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [mobileError, setMobileError] = useState(null);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data = await response.json();

        const countriesData = data.map(country => {
          const countryData = {
            name: country.name.common,
            code: country.cca2,
            flag: country.flags?.svg, // Assuming flag URL is under 'flags.svg'
          };

          if (country.idd && country.idd.root) {
            let mobileInitial = country.idd.root.replace('+', '');
            if (country.idd.suffixes && country.idd.suffixes.length > 0) {
              mobileInitial += country.idd.suffixes[0];
            }
            countryData.id = mobileInitial;
          }

          return countryData;
        });

        setCountries(countriesData);
      } catch (error) {
        console.error('Error fetching countries:', error);
        toast.error('Failed to fetch countries. Please try again later.');
      }
    };

    fetchCountries();
  }, []);

  const handleSelectCountry = (country) => {
    setSelectedCountry(country);
    setShowDropdown(false); // Close dropdown after selection
  };

  const handleKeyPress = (event) => {
    // Key code 91 for '91'
    if (event.keyCode === 91) {
      const india = countries.find(country => country.name.toLowerCase() === 'india');
      if (india) {
        setSelectedCountry(india);
      }
    }
  };

  const handleSubmit = async () => {
    const name = nameRef.current.value;
    const email = emailRef.current.value;
    const mobile = mobileRef.current.value;
    const country = selectedCountry ? selectedCountry.name : null;

    const errors = checkvalidateData(email, mobile, name, country);

    setEmailError(errors.email || null);
    setMobileError(errors.mobile || null);
    setNameError(name ? null : 'Please enter your name.');

    if (errors.email || errors.mobile || !name || !country) {
      return;
    }

    try {
      const response = await fetch('https://leapot-lms-backend.onrender.com/api/addContactUs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          email,
          mobile,
          subject: '',
          message: '',
        }),
      });

      if (response.ok) {
        toast.info('Your details have been forwarded to the concerned team.');
        nameRef.current.value = '';
        emailRef.current.value = '';
        mobileRef.current.value = '';
        setNameError(null);
        setEmailError(null);
        setMobileError(null);
        setSelectedCountry(null); // Reset selected country after submission
      } else {
        toast.error('Failed to submit the form. Please try again later.');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to submit the form. Please try again later.');
    }
  };

  return (
    <ContactContMain>
      <TitleContB>
        <TitleB>{t("landing.ContactUsLabel")}</TitleB>
        <TitleDash />
      </TitleContB>
      <Desc>{t("landing.questionText")}</Desc>
      <ContactFormMain>
        <ContactFormMainsub>
          <ContactImg src={ContactUsImg} />
          <ContactFormCont>
            <TextField
              required
              label={t("landing.nameTextLabel")}
              type="text"
              name="name"
              id="name"
              variant="outlined"
              placeholder={t("landing.nameTextLabel")}
              sx={TextFieldStyle}
              inputRef={nameRef}
              error={!!nameError}
              helperText={nameError ? nameError : 'Enter your Name'}
            />
            <TextField
              required
              label={t("landing.emailTextLabel")}
              type="email"
              name="email"
              id="email"
              variant="outlined"
              placeholder={t("landing.emailTextLabel")}
              sx={TextFieldStyle}
              inputRef={emailRef}
              error={!!emailError}
              helperText={emailError ? emailError : 'Enter your Email'}
            />
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6}>
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                    minWidth: '200px', // Adjust width as needed
                    marginBottom: '10px', // Add spacing between fields
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      cursor: 'pointer',
                      padding: '10px',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                    }}
                    onClick={() => setShowDropdown(!showDropdown)}
                  >
                    {selectedCountry ? (
                      <>
                        <img src={selectedCountry.flag} alt="flag" style={{ width: '30px', height: 'auto', marginRight: '10px' }} />
                        <span>{`${selectedCountry.name} (${selectedCountry.id})`}</span>
                      </>
                    ) : (
                      <span style={{ color: '#757575' }}>Select Country</span>
                    )}
                    <span style={{ fontSize: '20px', lineHeight: '1', marginLeft: 'auto' }}>▼</span>
                  </div>
                  {showDropdown && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                        width: '100%',
                        maxHeight: '150px', // Adjust height as needed
                        overflowY: 'auto', // Add scrollbar when content overflows
                        backgroundColor: '#fff',
                        boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
                        borderRadius: '4px',
                        zIndex: 10,
                      }}
                    >
                      {countries.map(country => (
                        <div
                          key={country.code}
                          style={{
                            padding: '10px',
                            cursor: 'pointer',
                            borderBottom: '1px solid #ccc',
                          }}
                          onClick={() => handleSelectCountry(country)}
                        >
                          <img src={country.flag} alt="flag" style={{ width: '30px', height: 'auto', marginRight: '10px' }} />
                          {`${country.name} (${country.id})`}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  label={t("landing.mobileLable")}
                  type="tel"
                  name="mobile"
                  id="mobile"
                  variant="outlined"
                  placeholder={t("landing.mobileLable")}
                  inputRef={mobileRef}
                  error={!!mobileError}
                  helperText={mobileError ? mobileError : 'Enter your Phone number'}
                  fullWidth
                />
              </Grid>
            </Grid>
            <MinHeightTextarea value={''} onChange={() => {}} />
            <SubmitButton onClick={handleSubmit}>{t("landing.submitLabel")}</SubmitButton>
          </ContactFormCont>
        </ContactFormMainsub>
      </ContactFormMain>
      <ContactFormCont2 style={{ marginTop: '5rem' }}>
        <TitleB>{t("landing.ConnectLabel")}</TitleB>
        <TitleDash2 />
        <ContactFormCont3 style={{ marginTop: '2rem' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ContSub2Main>
              <ContSub2>
                <TitleCont>
                  <TitleLogo src={ph} />
                  <Title3>{t("landing.phoneTextLabel")}</Title3>
                </TitleCont>
                <Desc2>+917038585222</Desc2>
              </ContSub2>
              <ContSub2>
                <TitleCont>
                  <TitleLogo src={Em} />
                  <Title3>{t("landing.emailTextLabel")}</Title3>
                </TitleCont>
                <Desc2>contact@leapot.in</Desc2>
              </ContSub2>
            </ContSub2Main>
            <ContSub2MainB>
              <ContSub2>
                <TitleCont>
                  <TitleLogo src={social} />
                  <Title3>{t("landing.socialMedia")}</Title3>
                </TitleCont>
                <Desc3>
                  <a href="https://in.linkedin.com/company/leapot-technologies" target="_blank" rel="noopener noreferrer">
                    <SocialMediaIcon src={linkedin} />
                  </a>
                </Desc3>
              </ContSub2>
              <ContSub2>
                <TitleCont>
                  <TitleLogo src={Ad} />
                  <Title3>{t("landing.addressTextLabel")}</Title3>
                </TitleCont>
                <Desc2>{/* Add your address content here */}</Desc2>
              </ContSub2>
            </ContSub2MainB>
          </div>
        </ContactFormCont3>
      </ContactFormCont2>
    </ContactContMain>
  );
};

export default Contactus;
