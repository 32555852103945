import styled from 'styled-components';
import { TextField, Grid, Box } from '@mui/material';
import { palette } from '../palette';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 75px;
`;
export const Header = styled.div`
  flex-grow: 1;
  height: 55px;
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 0 10px;
  gap: 10px;

  position: fixed;
  top: 72px;
  right: 2px;
  width: 40rem;
  z-index: 1000;
  background-color: ${palette.white};
`;

export const BackButtonContainer = styled(Grid)`
  display: flex;
  align-items: center;
  margin-top: 1px;
  margin-bottom: 2px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
  margin-bottom: 10px;
`;

export const DeleteBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: rgba(249, 249, 249, 0.9);
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 20px;
`;

export const EditorContainer = styled('div')`
  margin-right: 10px;
  margin-left: 10px;
`;

export const MainContainer = styled('div')({
  padding: '20px',
  paddingTop: '50px',
  backgroundColor: `${palette.white}`,
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  marginTop: '15px',
  maxHeight: '80vh',
  overflow: 'auto',
  backgroundColor: 'white',
  width: '100%',
});
export const HeaderLeft = styled.div`
  height: 55px;
  width: 28%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  gap: 1rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  background-color: ${palette.white};
`;
export const HeaderRight = styled.div`
  height: 55px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 10px;
  gap: 10px;

  position: fixed;
  top: 72px;
  right: 2px;
  width: 30rem;
  z-index: 1000;
  background-color: ${palette.white};
`;

export const Sidebar = styled('div')({
  width: 400,
  display: 'flex',

  flexDirection: 'column',
  justifyContent: 'space-between',

  height: '100vh',
  backgroundColor: ` ${palette.sidebarBg}`,
  padding: '20px',
});

export const SidebarHeader = styled('div')({
  display: 'flex',
});

export const AddModuleButton = styled('button')({
  padding: '15px',
  backgroundColor: '#0684FB',
  color: 'white',
  border: 'none',
  width: 380,
  fontSize: '16px',
  borderRadius: '8px',
  cursor: 'pointer',
});

export const FixedBottom = styled('div')({
  // textAlign: 'center',
  // position: 'fixed',
  // bottom: 15,
  // marginLeft: '-24px',
  // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.7)',
  // zIndex: 1000,
});

export const FixedBottomInner = styled('div')({
  padding: '12px',
  backgroundColor: '#F1F1F1',
  color: 'white',
  border: 'none',
  width: 405,
  fontSize: '16px',
});

export const DefaultMessageBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'calc(100% - 385px)', // Adjust the width according to your left pane width
  height: '100vh',
  textAlign: 'center',
  color: '#888',
});

export const StyledBox = styled(Box)`
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 65px;
  max-height: 80vh;
  overflow: auto;
  width: 100%;
`;

export const FlexDiv = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`;
